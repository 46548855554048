import { Box, css } from '@mui/material';
import { Fragment } from 'react';
import ReactPanZoom from 'react-image-pan-zoom-rotate';

const containerStyle = css({
    display: 'flex',
    backgroundColor: 'rgb(127, 127, 127)',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '& div > div': {
        top: '50px !important',
    }
});

export function SeismicImageContent({ title, iframe }: Props) {
    return (
        <Fragment>
            <Box css={containerStyle}>
                <ReactPanZoom
                    image={`https://filemanager.geopostenergy.com/filemanager/downloadFile?fileToken=${iframe}&userToken=5a2ee02e-4f9c-4ae6-8a11-7743369443f7&v=1687350741016`}
                    alt={title}
                />
            </Box>
        </Fragment>
    );
}

interface Props {
    title: string;
    iframe: string;
}