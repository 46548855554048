import { useCallback, useEffect, useMemo } from 'react';
import { AxiosResponse } from 'axios';

import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useAPI } from 'hooks/useAPI';
import { FetchType } from 'models/enums/FetchType';
import { ApiType } from 'models/enums/ApiType';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { useFeatureFlags } from 'features/seismic/hooks/useFeatureFlags';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { usePerformanceMonitoringStore } from 'features/seismic/stores/usePerformanceMonitoringStore';
import { getLineGeometryPerformanceDataFromAxiosResponse, getPerformanceDataFromAxiosResponse } from 'features/seismic/utils/performanceUtils';
import { useGetLineString } from '../api/useSeismicController';
import { LineType } from 'features/seismic/models/enums/LineType';

export function useGetGeometryProjections(srid = 4326, forceEnabled = false) {
    const { setHasErrorOnGeometry, geometryLine, setGeometryLine, volumeToken, surveyMetadataError, volumeType, setIsReLoadingLineGeoemtry, dimensionNames } = useSeismicStore(state => ({
        surveyMetadataError: state.surveyMetadata?.Error,
        geometryLine: state.geometryLine,
        setGeometryLine: state.setGeometryLine,
        setHasErrorOnGeometry: state.setHasErrorOnGeometry,
        volumeToken: state.surveyMetadata?.VolumeToken,
        volumeType: state.surveyMetadata?.Type,
        setIsReLoadingLineGeoemtry: state.setIsReLoadingLineGeoemtry,
        dimensionNames: state.surveyMetadata?.DimensionNames,
    }));

    const urlParams = getParamsFromUrl();

    const performanceMonitoringEnabled = useFeatureFlags().featureFlags?.seismic2D.PerformanceMonitor;

    const { lineType, lineNumber, } = useLine3DNavigationStore(state => ({
        lineType: state.lineType,
        lineNumber: state.lineNumber
    }));

    const dimensionNameForLineGeometry = useMemo(() => {
        if (!dimensionNames) {
            return null;
        }
        switch (lineType) {
        case LineType.Inline:
            return dimensionNames.Inline;
        case LineType.Xline:
            return dimensionNames.Xline;
        case LineType.Line2D:
            return dimensionNames.Cdp;
        }
    }, [dimensionNames, lineType]);

    const { isLoading, data, isError} = useGetLineString((lineType === LineType.ZSlice ? undefined : volumeToken), lineType, lineNumber, volumeType, srid, !!(surveyMetadataError), dimensionNameForLineGeometry, forceEnabled);

    const addLineGeometryPerformanceData = usePerformanceMonitoringStore(state => state.addLineGeometryPerformanceData);

    const handleLineGeometryResponseHeaders = useCallback((response: AxiosResponse<IResponseAPI<ILineString>, unknown>, requestLeaveAt: Date) => {
        if (urlParams.performanceMonitoring) {
            const performanceData = getLineGeometryPerformanceDataFromAxiosResponse(response, requestLeaveAt, lineNumber, lineType);
            if (!!performanceData) {
                addLineGeometryPerformanceData(performanceData);
            }

        }

    }, [addLineGeometryPerformanceData, lineNumber, lineType, performanceMonitoringEnabled, urlParams.performanceMonitoring]);

    useEffect(() => {
        if (lineType === LineType.ZSlice) {
            setIsReLoadingLineGeoemtry(false);
            return;
        }
        setIsReLoadingLineGeoemtry(true);
        if (data) {
            setGeometryLine(data);
            setIsReLoadingLineGeoemtry(false);
            setHasErrorOnGeometry(false);
        }

        if (surveyMetadataError || isError){
            setGeometryLine(null);
            setIsReLoadingLineGeoemtry(false);
            if (lineType !== LineType.Line2D) {
                setHasErrorOnGeometry(true);
            }
        }
    }, [data, surveyMetadataError, isError, lineType]);

    return {isLoading: isLoading, data: geometryLine};
}