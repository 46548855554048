import { Collection, Map, View } from 'ol';
import { Control } from 'ol/control';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import { MouseWheelZoom, defaults as defaultInteractions } from 'ol/interaction.js';
import { defaults as defaultControls} from 'ol/control';

export class GeopostMap extends Map {
    private Params: IGeopostMapParams = {};
    geopostKey = 'geopostMap';

    constructor(
        {pixelRatio, maxTilesLoading, resolutions, extent, center, zoom, zoomFactor, maxZoom = 0}
        : GeopostMapBuildParams){
        super({
            target: 'map',
            pixelRatio: pixelRatio,
            maxTilesLoading: 80,
            interactions: defaultInteractions({ mouseWheelZoom: false, altShiftDragRotate:false, pinchRotate:false }).extend( [new MouseWheelZoom({
                constrainResolution: true
            })]),
            controls: defaultControls({ rotate: false })
        });

        this.Params = {
            center: center,
            extent: extent,
            resolutions: resolutions,
            zoom: zoom,
            zoomFactor: zoomFactor,
            maxZoom: maxZoom
        };

        this.buildView();
    }

    getOriginalExtent() {
        return this.Params?.extent;
    }

    private buildView(){
        let maxZoom = 28; //default do openlayers
        let resolutions = this.Params.resolutions;

        if (this.Params.maxZoom && this.Params.maxZoom !== 0){
            console.log('setando max zoom', this.Params.maxZoom);

            if (!this.Params.resolutions || this.Params.resolutions.length === 0){
                console.error('resolutions nulo ou vazio, aplicando o maxZoom direto');
                maxZoom = this.Params.maxZoom;
            }
            else if (this.Params.maxZoom <= this.Params.resolutions.length){
                resolutions = this.Params.resolutions.slice(0, this.Params.maxZoom);
            }
            else {
                console.error('max zoom maior que o length do resolutions ' + this.Params.resolutions?.length);
            }
        }
        console.log('resolutions on view creation ====>', resolutions);
        const view = new View({
            center: this.Params.center,
            constrainOnlyCenter: true,
            constrainResolution: true,
            enableRotation: true,
            extent: this.Params.extent,
            maxZoom: maxZoom,
            multiWorld: false,
            resolutions: resolutions,
            showFullExtent: true,
            smoothExtentConstraint: false,
            smoothResolutionConstraint: false,
            zoom: this.Params.zoom,
            zoomFactor: this.Params.zoomFactor,
            rotation: 0,
        });

        this.setView(view);
    }

    rebuildView(){
        this.buildView();
    }

    updateParams(params: IGeopostMapParams){
        this.Params = Object.assign(this.Params, params);

        return this;
    }
    onMouseMove(callback:(x:number,y:number)=>void){
        this.on('pointermove',(event)=>callback(event.coordinate[0],event.coordinate[1]));
    }
}

export interface IGeopostMapParams {
    center?: Coordinate;
    extent?: Extent;
    resolutions?: number[];
    zoom?: number;
    zoomFactor?: number;
    maxZoom?: number;
}

export type GeopostMapBuildParams = {
    pixelRatio: number,
    maxTilesLoading:number,
    resolutions: number[] | undefined,
    extent: Extent | undefined,
    center: Coordinate,
    zoom: number,
    zoomFactor: number,
    maxZoom: number};