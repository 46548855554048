import { AppBar, Box, Button, css, IconButton, ThemeProvider, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Fragment, useRef } from 'react';
import { MousePosition } from 'ol/control';
import { useGeopostThemeAppBar } from 'theme';
import { useTheme } from '@emotion/react';

import { axios } from 'configurations/axios';
import { SeismicLogoNavbar } from 'features/seismic/components/SeismicLogoNavbar';
import { CrossSectionChangeVerticalScale } from './CrossSectionChangeVerticalScale';
import { ICrossSection } from '../models/interfaces/ICrossSection';
import { CrossSectionConnectionChangeWidth } from './CrossSectionConnectionChangeWidth';
import { Metadata } from 'components/icons/components';
import { useCrossSectionAnnotationsStore } from '../stores/useCrossSectionAnnotationsStore';
import { CrossSectionLaneWidth } from './CrossSectionLaneWidth';
import { useCrossSectionStore } from '../stores/useCrossSectionStore';
import { useCrossSectionConnectionStore } from '../stores/useCrossSectionConnectionStore';
import { useAPI } from 'hooks/useAPI';
import { ISettings } from '../models/interfaces/ISettings';
import { LaneType } from '../models/enums/LaneType';
import { WellCurveCatalog } from 'features/well-viewer/components/WellCurveCatalog';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { IWellGenericCut } from '../models/interfaces/IWellGenericCut';
import { ICrossSectionItem } from '../models/interfaces/ICrossSectionItem';
import { IWellCatalog } from '../models/interfaces/IWellCatalog';
import { useSessionStore } from 'session/useSessionStore';

const styles = css({
    '& .toolbar > *': {
        marginRight: 15
    },
    '& .title': {
        marginLeft: 'auto'
    },
    '& fieldset': {
        padding: '0px 7px',
        color: '#fff',
        border: 'none',
        background: 'rgb(255 255 255 / 17%)',
        borderRadius: 3
    },
    '& fieldset legend': {
        fontSize: 12
    },
    '& fieldset input': {
        paddingTop: 3,
        paddingBottom: 3
    },
    '& fieldset .MuiInputBase-root': {
        background: 'none'
    },
    '& fieldset .MuiInputBase-root fieldset': {
        background: 'none'
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight: 0
    },
    '& .MuiInputBase-adornedStart': {
        paddingLeft: 0
    },
    '& .add-lane':{
        marginTop:'8%'
    }
});

export function WellCrossSectionNavbar({ crossSection }: IProps) {
    const { t } = useTranslation('crossSection');
    const {popupOpened: popupAnnotationOpened, setPopupOpened: setPopupAnnotationOpened } = useCrossSectionAnnotationsStore(state => ({
        popupOpened: state.popupOpened,
        setPopupOpened: state.setPopupOpened
    }));
    const themeAppBar = useGeopostThemeAppBar();
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    const theme = useTheme();

    const mousePosition = useRef<MousePosition | null>(null);
    // buildMousePosition(crossSection?.Token?.toString(),CoordinateFormat);
    const { factorVertical,width,jwtToken,setCrossSectionData} = useCrossSectionStore(state => ({
        factorVertical: state.factorVertical,
        width: state.laneWidth,
        jwtToken: state.jwtToken,
        setCrossSectionData : state.setCrossSectionData
    }));
    const {connectionWidth} = useCrossSectionConnectionStore(state =>({
        connectionWidth: state.connectionWidth,
    }));
    const createSettings =  () =>{
        let settings : ISettings = {Width : width,VerticalScale: factorVertical, ConnectionSize: connectionWidth, WellCrossSectionToken: crossSection?.Token};
        console.log(settings);
        return settings;
    };

    const saveSettings = () =>
    {
        let settings = createSettings();
        //execute({type:FetchType.Post,params:settings,apiType:ApiType.WebApi,jwtToken: token});
        axios.post(`${tenantConfig?.endpoints.wellCrossSection}/Wells/WellCrossSection/SaveSettings`,settings,{headers: {Authorization: `Bearer ${jwtToken}`}});
    };
    const { isLoading, execute, data } = useAPI<ISettings>('/Wells/WellCrossSection/SaveSettings');
    const addItem = (cut?:IWellGenericCut,curves?:IWellCatalog[])=>{
        if (crossSection){
            let size = crossSection.Itens?.length ?? 0;
            console.log(size);
            let wellName = 0 ;
            if (size > 0 && crossSection.Itens){
                wellName = Number(crossSection.Itens[size - 1].WellName?.split(' ')[1]);
            }
            console.log(wellName);
            if (wellName>= 0){
                let item:ICrossSectionItem = {WellName:`Track ${wellName + 1}`,WellStart: crossSection.WellStart,Token:`Track ${wellName + 1}`,
                    WellEnd:crossSection.WellEnd};
                if (cut){
                    item.GenericCut = cut;
                }
                if (curves){
                    item.SelectedCurves = curves;
                }
                crossSection.Itens?.push(item);
                setCrossSectionData(crossSection);
                console.log(crossSection);
            }
        }
    };
    return <AppBar position='static' css={styles}>
        <Toolbar className='toolbar'  style={{paddingLeft: '0px', paddingRight: '0px'}}>
            <ThemeProvider theme={themeAppBar}>
                <SeismicLogoNavbar />
                <CrossSectionLaneWidth />
                <CrossSectionChangeVerticalScale crossSection={crossSection} />
                {crossSection?.LaneType === LaneType.CrossSection ? <Fragment>

                    <CrossSectionConnectionChangeWidth />
                    <IconButton title={t('formationTop.open')} onClick={() => setPopupAnnotationOpened(!popupAnnotationOpened)} color='primary'>
                        <Metadata />
                    </IconButton>
                    <IconButton title={t('settings.save')} onClick={() => saveSettings()} color='primary'>
                        <Metadata />
                    </IconButton>
                </Fragment>:null
                }
                {crossSection?.LaneType === LaneType.LogCurve &&
                    <Box>
                        <Button className='add-lane' style={{color:'white', backgroundColor: tenantConfig?.main.palette.secondary.contrastText}} variant="contained" onClick={()=> addItem()}>Add Track</Button>
                        <span id={`mouse${crossSection?.Token}`}></span>
                    </Box>
                }

                {crossSection?.LaneType === LaneType.LogCurve &&

                    <Box>
                        <ThemeProvider theme={theme}>
                            <WellCurveCatalog isJustTextButton={true} wellId={getParamsFromUrl().wellId} crossSection={crossSection} globalSelect={true}  onChange={(curves,cut) => {console.log(curves);console.log(cut);addItem(cut,curves)}}/>
                        </ThemeProvider>
                    </Box>

                }

                <div className='title'>
                    {/* <span><strong>{crossSection?.LaneType === LaneType.CrossSection ? `Cross Section:` : `Log Viewer:`}</strong> {crossSection?.Name} - ({crossSection?.Itens?.length} {t('wells')})</span> */}
                    <span><strong>{crossSection?.LaneType === LaneType.CrossSection ? `Cross Section:` + crossSection?.Name + '-' : `Log Viewer:`}</strong>  ({crossSection?.Itens?.length} {'Tracks'})</span>
                </div>
            </ThemeProvider>
        </Toolbar>
    </AppBar>;
}

interface IProps {
    crossSection: ICrossSection | null;
}