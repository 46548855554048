import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IGetAllHiddenResponse } from '../models/interfaces/IGetAllHiddenResponse';
import { useChangeVisibility } from '../api/useInterpretationController';

export function SeismicInterpretationsHiddenItem({item, onRemove}: Props){
    const { t } = useTranslation();
    const { mutateAsync, isLoading } = useChangeVisibility();
    const [visible, setVisible] = useState(false);

    const handleClick = () => {
        mutateAsync({
            token: item.Token,
            visibility: !visible
        }).then(() => {
            if (onRemove){
                onRemove(item.Token);
            }
        });

        setVisible(!visible);
    };

    useEffect(() => {
        setVisible(item.Visible);
    }, [item.Visible]);

    const title = (visible)?t('interpretation.clickToHide'):t('interpretation.clickToShow');

    return (<Box className='container-item'>
        <IconButton style={{width: 20, height: 20}} size='small' onClick={handleClick} disabled={isLoading} title={title}>
            {visible ? <RemoveRedEyeIcon />:<CloseIcon />}
        </IconButton >
        <CircularProgress style={{marginLeft: 7, opacity: isLoading ? 1:0}} size={16}/>
        <Typography className='label'>{ item.Name }</Typography>
    </Box>);
}

interface Props {
    item: IGetAllHiddenResponse;
    onRemove?: (token:string) => void;
}

/*disabled={visibilityLoading} onClick={() => handleChangeVisibility(item.Token, false)} */