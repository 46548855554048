import { useGetAllInstitutions } from 'fileIndexing/institutions/useInstitution';
import { useEffect, useState } from 'react';

import { GeopostDropdown } from './GeopostDropdown';

export function GeopostDropdownInstitutions({ fieldName, label, required = true }: Props) {
    const [institutionsItems, setInstitutionsItems] = useState<{ id: string; label: string; }[]>([]);

    const { data: institutionsData, isLoading } = useGetAllInstitutions();

    useEffect(() => {
        if (institutionsData) {
            setInstitutionsItems(institutionsData.map(institution => ({
                id: institution.Token,
                label: institution.Name
            })));
        }
    }, [institutionsData]);

    return (
        <GeopostDropdown fieldName={fieldName} label={label} items={institutionsItems} loading={isLoading} required={required} />
    );
}

interface Props {
    fieldName: string;
    label: string;
    required?: boolean;
}