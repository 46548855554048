import { useEffect, useRef } from 'react';
import { Box, css, Typography } from '@mui/material';

import { IWellHoverInfo } from 'features/seismic/stores/useSeismicStore';

const containerStyle = css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    zIndex: '1',
    padding: '8px',
    width: '250px',
    left: '50000px',
    top: '50000px',
    backgroundColor: 'rgb(0, 30, 58)',
    borderRadius: '3px',
    opacity: 0.85,
    '& .MuiTypography-root': {
        color: 'rgb(255, 255, 255) !important',
        textAlign: 'left',
        fontSize: '12px',
        fontFamily: 'Arial, Verdana, sans-serif',
    }
});

export function SeismicWellHoverInfo({ data }: Props) {
    const mouseRef = useRef<{x: number; y: number;}>({ x: 0, y: 0 });
    const displayDelay = useRef<string>('none');

    useEffect(() => {
        const handleMousePosition = (event: MouseEvent) => {
            mouseRef.current = { x: event.clientX, y: event.clientY + 20 };
            displayDelay.current = 'flex';
        };

        window.addEventListener('mousemove', handleMousePosition);

        return () => {
            window.removeEventListener('mousemove', handleMousePosition);
        };
    }, [data.type]);

    return (
        <Box>
            {(data.type === 'crossingPoint') &&
                <Box css={containerStyle} style={{position: 'absolute', left: mouseRef.current.x, top: mouseRef.current.y, display: displayDelay.current}} >
                    <Typography>Fundo MD: {data.md[0]}m ({data.md[0] - (data.rotativeDesk ? data.rotativeDesk : 0)}m)</Typography>
                </Box>
            }
            {(data.type === 'timeDepth') &&
                <Box css={containerStyle} style={{position: 'absolute', left: mouseRef.current.x, top: mouseRef.current.y, display: displayDelay.current}} >
                    <Typography>MD: {data.md[0]}m ({data.md[0] - (data.rotativeDesk ? data.rotativeDesk : 0)}m)</Typography>
                    <Typography>Time: {data.timeDepth}</Typography>
                    <Typography>{data.date}</Typography>
                </Box>
            }
            {(data.type === 'formationTop') &&
                <Box css={containerStyle} style={{position: 'absolute', left: mouseRef.current.x, top: mouseRef.current.y, display: displayDelay.current}} >
                    <Typography>MD: {data.md[0]}m ({data.md[0] - (data.rotativeDesk ? data.rotativeDesk : 0)}m)</Typography>
                    <Typography>{data.date}</Typography>
                </Box>
            }
            {(data.type === 'hydrocarbon' || data.type === 'formationTest') &&
                <Box css={containerStyle} style={{position: 'absolute', left: mouseRef.current.x, top: mouseRef.current.y, display: displayDelay.current}} >
                    <Typography>Início MD: {data.md[0]}m ({data.md[0] - (data.rotativeDesk ? data.rotativeDesk : 0)}m)</Typography>
                    <Typography>Início TVD: {data.tvd![0]}ms ({data.tvd![0] - (data.rotativeDesk ? data.rotativeDesk : 0)}ms)</Typography>
                    <Typography>Final MD: {data.md[1]}m ({data.md[1] - (data.rotativeDesk ? data.rotativeDesk : 0)}m)</Typography>
                    <Typography>Final TVD: {data.tvd![1]}ms ({data.tvd![1] - (data.rotativeDesk ? data.rotativeDesk : 0)}ms)</Typography>
                    <Typography>{data.date}</Typography>
                </Box>
            }
        </Box>
    );
}

interface Props {
    data: IWellHoverInfo;
}