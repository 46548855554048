import { css, IconButton, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeEventHandler, FocusEventHandler, useEffect, useState } from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { FitPage, Plus, Reset, Subtract } from 'components/icons/components';
import { ICrossSection } from '../models/interfaces/ICrossSection';
import { useCrossSectionStore } from '../stores/useCrossSectionStore';
import { calculateVerticalFitByDepths } from '../utils/wellLaneMapUtils';

const buttonWidth = 40;

const styles = css({
    '& .MuiButton-root': {
        width: buttonWidth,
        display: 'inline-block',
        maxWidth: buttonWidth,
        minWidth: buttonWidth
    },
    '& input': {
        width: 30
    }
});

export function CrossSectionChangeVerticalScale({ crossSection }:IProps){
    const { factorVertical, setFactorVertical, heightMap } = useCrossSectionStore(state => ({
        factorVertical: state.factorVertical,
        heightMap: state.heightMap,
        setFactorVertical: state.setFactorVertical
    }));
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));

    const { t } = useTranslation('crossSection');
    const [value, setValue] = useState(factorVertical.toString());
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        setValue(factorVertical.toString());
    }, [factorVertical]);

    useEffect(() => {
        if (!initialized) {
            if (crossSection && heightMap){
                fit();
                setInitialized(true);
            }
        }
    }, [crossSection, heightMap]);

    const fit = () => {
        if (!crossSection || !heightMap){
            return;
        }

        const factorVertical = calculateVerticalFitByDepths(heightMap, crossSection.WellStart, crossSection.WellEnd);
        setFactorVertical(factorVertical);
    };

    const onChangeInput:ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue(event.target.value);
    };

    const onBlurInput:FocusEventHandler<HTMLInputElement> = (event) => {
        let factorVertical = 1;
        if (crossSection && heightMap){
            factorVertical = calculateVerticalFitByDepths(heightMap, crossSection.WellStart, crossSection.WellEnd);
        }
        let newFactor = factorVertical;
        if (parseFloat(event.target.value) > newFactor){
            newFactor = parseFloat(event.target.value);
        }
        setFactorVertical(newFactor);
    };

    const reset = () => {
        setFactorVertical(1);
    };

    return <div css={styles}>
        <fieldset>
            <legend style={{color: tenantConfig?.main.palette.primary.contrastText}}>{t('vertticalScale')}</legend>
            <TextField
                value={value}
                onBlur={onBlurInput}
                onChange={onChangeInput}
                InputProps={{
                    startAdornment: <InputAdornment position="end">
                        <IconButton size='small'  onClick={fit} style={{color: tenantConfig?.main.palette.primary.contrastText}} title={t('fit')}>
                            <FitPage />
                        </IconButton>
                    </InputAdornment>,
                    endAdornment: <InputAdornment position="end">
                        <IconButton size='small' onClick={reset} style={{color: tenantConfig?.main.palette.primary.contrastText}}>
                            <Reset />
                        </IconButton>
                    </InputAdornment>
                }}
            />
        </fieldset>
    </div>;
}

export interface IProps {
    crossSection: ICrossSection | null
}