import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { useSessionStore } from 'session/useSessionStore';
import { setOktaAuth } from 'configurations/okta';

export const ViewerSecureRoute = () => {

    const tenantConfig = useSessionStore(state => state.tenantConfiguration);

    const oktaAuth = useMemo(() => {
        const oktaAuthConfig = tenantConfig?.oktaAuthConfiguration;
        if (!!oktaAuthConfig) {
            return new OktaAuth({
                issuer: oktaAuthConfig.issuerUri,
                clientId: oktaAuthConfig.clientId,
                redirectUri: window.location.origin + '/logincallback',
                scopes: ['openid', 'profile', 'email', 'offline_access', 'TgsSeismic.Services'],
                pkce: true,
                responseType: ['id_token', 'token'],
            });
        } else {
            return null;
        }
    }, [tenantConfig]);

    useEffect(() => {
        setOktaAuth(oktaAuth);
    }, [oktaAuth]);

    /*
    const oktaAuth = useMemo(() => new OktaAuth({
        issuer: 'https://trial-6285149.okta.com/oauth2/default',
        clientId: '0oa9l8sk61nHV1Cua697',
        redirectUri: window.location.origin + '/login/callback',
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        responseType: ['id_token', 'token']
    }), []);
    */

    const navigate = useNavigate();

    const restoreOriginalUri = (_oktaAuth: object, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    const [ showLogoutModal, setShowLogoutModal ] = useState(false);

    const oktaAuthHandler = (oktaAuth: OktaAuth) => {
        const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();

        if (!previousAuthState) {
            oktaAuth.signInWithRedirect();
        } else {
            setShowLogoutModal(true);
        }
    };

    const handleLoginRedirect = useCallback(() => {
        oktaAuth?.signInWithRedirect();
    }, [oktaAuth]);

    return (
        !!tenantConfig?.oktaAuthConfiguration ?
            <React.Fragment>
                <Dialog
                    open={showLogoutModal}
                >
                    <DialogTitle>
                        You're not authenticated!
                    </DialogTitle>
                    <DialogContent>
                        You need to login in order to proceed.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleLoginRedirect}>Login</Button>
                    </DialogActions>
                </Dialog>
                <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={oktaAuthHandler}>
                    <Outlet/>
                </Security>
            </React.Fragment>
            :
            <Outlet/>
    );
};