import { useEffect } from 'react';

import { useInterpretation2DSeismicViewer } from '../hooks/useInterpretation2DSeismicViewer';
import { SeismicInterpretationPopup } from './SeismicInterpretationPopup';

export function SeismicInterpretation2DViewer(props: Props){
    const interpretationSettings = useInterpretation2DSeismicViewer();

    return <SeismicInterpretationPopup {...props} interpretationSettings={interpretationSettings}/>;
}

interface Props {
    opened: boolean;
    setOpened: (value: boolean) => void;
}