import { Fragment, useEffect, useState } from 'react';
import { Box, Select, SelectChangeEvent, Typography } from '@mui/material';

import { Survey2DLines } from 'features/seismic/models/interfaces/I2DLines';
import { useGetAll2DLines } from './api/useSeismicLineController';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';

export function SeismicLineSelect({ attributes, selected }: Props) {
    const [selectedLine, setSelectedLine] = useState<string>('');

    const { volumeToken } = useSeismicStore(state => ({
        volumeToken: state.volumeToken
    }));

    const handleChange = ( lineName: string) => {
        if (attributes) {
            const selectedLine = attributes.find(el => el.LineName === lineName);
            if (selectedLine) {
                setSelectedLine(selectedLine.LineName);
                selected(selectedLine);
            }
        }
    };

    useEffect(() => {
        if (attributes && volumeToken) {
            let lines = attributes.sort((a, b) => {
                const nameA = a.LineName.toUpperCase();
                const nameB = b.LineName.toUpperCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });

            let selectedLine = lines.find(el => el.SeismicIds.includes(Number(volumeToken)));

            if (selectedLine) {
                setSelectedLine(selectedLine.LineName);
                selected(selectedLine);
            }
            else {
                setSelectedLine(lines[0].LineName);
                selected(lines[0]);
            }
        }
    }, [attributes, volumeToken]);

    return  (
        <Fragment>
            <Typography style={{color: '#ffffff'}}>Lines</Typography>
            <Box style={{display: 'flex', flexDirection: 'row'}}>
                {
                    !!attributes && attributes.length > 0 ?
                        <Select style={{fontSize: '14px', color:'#ffffff', padding:'3px', marginRight: '5px'}} value={selectedLine} native onChange={(event) => handleChange(event.target.value)}>
                            {
                                attributes.map(el => <option style={{padding: '10px', color: '#000000'}} key={'product-' + el.SurveyId} value={el.LineName}>{el.LineName}</option>)
                            }
                        </Select>
                        :
                        <Typography variant='caption' padding='10px' color='white'>No attributes available</Typography>
                }
            </Box>
        </Fragment>
    );
}

interface Props {
    attributes: Survey2DLines[] | undefined;
    selected: (value: Survey2DLines | undefined) => void;
}