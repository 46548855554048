import { useCallback, useMemo } from 'react';

export const useGeneralStats = (attributeValues: number[]) => {
    const getAverage = (list: number[]) => list.reduce((current, next) => current + next, 0) / list.length;

    const getStandardDeviation = useCallback((array: number[]) => {
        //console.time('getStandardDeviation');
        const n = array.length;
        const mean = array.reduce((a, b) => a + b, 0) / n;
        const value = Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / n);
        //console.timeEnd('getStandardDeviation');
        return value;
    }, []);

    //console.time('useGeneralStats');

    const average = useMemo(() => getAverage(attributeValues), [attributeValues]);
    const max = useMemo(() => Math.max(...attributeValues), [attributeValues]);
    const min = useMemo(() => Math.min(...attributeValues), [attributeValues]);
    const standardDeviation = useMemo(() => getStandardDeviation(attributeValues), [attributeValues, getStandardDeviation]);
    //console.timeEnd('useGeneralStats');

    return {
        average,
        max,
        min,
        standardDeviation
    };
};