import { useQuery } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { axios } from 'configurations/axios';
import { IBasicModel } from '../models/interfaces/IBasicModel';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { PermissionType } from 'features/seismic/models/enums/PermissionType';

export function useGetInfo(permission: PermissionType){
    const { data: dataClients, isLoading: isLoadingClients } = useGetClients(permission);
    const { data: dataUsers, isLoading: isLoadingUsers } = useGetUsers(permission);
    const { data: dataUserGroups, isLoading: isLoadingUserGroups } = useGetUserGroups(permission);

    let data: IBasicModel[] | undefined;
    let isLoading = false;

    if (permission === PermissionType.SharedCompanies){
        data = dataClients;
        isLoading = isLoadingClients;
    }
    else if (permission === PermissionType.SharedUsers){
        data = dataUsers;
        isLoading = isLoadingUsers;
    }
    else if (permission === PermissionType.SharedUserGroups){
        data = dataUserGroups;
        isLoading = isLoadingUserGroups;
    }

    return {
        data: data,
        isLoading: isLoading
    };
}

export function useGetClients(permission: PermissionType){
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('permissionGetClients', async () => {
        const response = await axios.request<IResponseAPI<IBasicModel[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.interpretation.CompaniesUrl,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    }, {
        refetchOnMount: false,
        enabled: permission === PermissionType.SharedCompanies,
        staleTime: Infinity
    });
}

export function useGetUsers(permission: PermissionType){
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('permissionGetUsers', async () => {
        const response = await axios.request<IResponseAPI<IBasicModel[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.interpretation.UsersUrl,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    }, {
        refetchOnMount: false,
        enabled: permission === PermissionType.SharedUsers,
        staleTime: Infinity
    });
}

export function useGetUserGroups(permission: PermissionType){
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('permissionGetUserGroups', async () => {
        const response = await axios.request<IResponseAPI<IBasicModel[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.interpretation.UserGroupsUrl,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    }, {
        refetchOnMount: false,
        enabled: permission === PermissionType.SharedUserGroups,
        staleTime: Infinity
    });
}