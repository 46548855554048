import { create } from 'zustand';

interface InterpretationStore {
    addInterpretationEnabled: (token: string) => void,
    interpretationsEnabled: { [token: string]:boolean },
    removeInterpretationsEbaled: (token: string) => void,

    canShareToPublic: boolean;
    canShareToCompanies: boolean;
    canShareToUserGroups: boolean;
    setSharePermissions: (sharePublic: boolean, companies: boolean, userGroups: boolean) => void;
}

export const useInterpretationStore = create<InterpretationStore>((set) => ({
    addInterpretationEnabled: (token: string) => set((state) => ({ interpretationsEnabled: {
        ...state.interpretationsEnabled,

        [token]: true
    } })),
    interpretationsEnabled: {},
    removeInterpretationsEbaled: (token: string) => set((state) => ({ interpretationsEnabled: {
        ...state.interpretationsEnabled,

        [token]: true
    } })),

    canShareToPublic: false,
    canShareToCompanies: false,
    canShareToUserGroups: false,
    setSharePermissions: (sharePublic, companies, userGroups) => set((state) => ({canShareToCompanies: companies, canShareToPublic: sharePublic, canShareToUserGroups: userGroups}))
}));