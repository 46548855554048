import { Box, Button, css, FormControl, Grid, InputLabel, keyframes, MenuItem, Popper, Select, TextField, Tooltip, Typography, Zoom } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useDumpCSVFile, useGetDataSourceByToken, useGetFileDataSources } from 'fileIndexing/dataSources/useDataSource';
import { SignalR } from 'fileIndexing/signalR/signalR';
import { GeopostLoading } from 'fileIndexing/otherComponents/GeopostLoading';
import { ePathType } from 'fileIndexing/dataSources/interfaces/IDataSource';
import { useDropzone } from 'react-dropzone';
import PublishIcon from '@mui/icons-material/Publish';
import alertify from 'alertifyjs';
//import { urlEnvVar } from 'fileIndexing/envVar/envVar';

import { useSessionStore } from 'session/useSessionStore';
import { useMapObjectsFromBucketByExtensions } from '../useFiles';
import { BucketInfo, IMapObjectsFromBucketByExtensions, IImportByDump } from '../interfaces/IFiles';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const style = css({
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    width: '100%',
    // '& .data-source-select': {
    //     height: '50px',
    // },
    '& .data-source-info': {
        height: '170px',
    },
    '& .data-source-import-info': {
        height: '120px',
    },
    '& .data-source-import-info-check': {
        marginBottom: '16px',
    },
    '& .fade-text': {
        animation: `${blink} 4s infinite`,
    },
    '& .dropzone': {
        marginRight: '20px',
    },
    '& .publish-button': {
        height: '35px',
        backgroundColor: '#041F3C',
        color: '#ffffff',
        width: '64px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        // padding: '6px 16px',
        borderRadius: '4px',
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
    },
    '& .dropzone-box': {
        marginTop: '16px',
        display: 'flex',
        alignItems: 'center',
    },
    '& .import-files': {
        width: '180px',
        alignSelf: 'flex-end'
    }
});

export const ScanDataSource = () => {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    const [fileDataSources, setFileDataSources] = useState<string>('');

    const [bucketInfoToSendAWS, setBucketInfoToSendAWS] = useState<IMapObjectsFromBucketByExtensions | null>(null);

    const [bucketInfoToSendDump, setBucketInfoToSendDump] = useState<IImportByDump | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [delimiter, setDelimiter] = useState<string>('');

    // const [bucketInfoToSend, setBucketInfoToSend] = useState<IMapObjectsFromBucketByExtensions | null>(null);
    // const [bucketInfoToSend, setBucketInfoToSend] = useState<IMapObjectsFromBucketByExtensions | null>(null);
    const [bucketInfo, setBucketInfo] = useState<BucketInfo | null>(null);
    const [importingProcess, setImportingProcess] = useState<boolean>(false);

    const signalR = new SignalR(tenantConfig?.endpoints.signalR + '/ConnectionHub', jwtToken);

    const { data: dataSources, isLoading: dataSourcesLoading } = useGetFileDataSources();
    const { data: dataByToken, isLoading: dataLoading } = useGetDataSourceByToken(fileDataSources);
    const { data: mapObjectData, isLoading: mapObjectLoading } = useMapObjectsFromBucketByExtensions(bucketInfoToSendAWS);
    const { data: importToDump, isLoading: importToDumpLoading } = useDumpCSVFile(bucketInfoToSendDump);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ maxFiles: 1, accept: { 'text/csv': ['.csv'] } });

    const pathType = (type: ePathType) => {
        switch (type) {
        case ePathType.InternalNetwork:
            return 'Internal Network';
        case ePathType.ExternalMidia:
            return 'External Midia';
        case ePathType.AzureStorage:
            return 'Azure Storage';
        case ePathType.AWSS3Bucket:
            return 'AWSS3 Bucket';
        case ePathType.Dump:
            return 'Dump File';
        default:
            return '';
        }
    };

    const importFiles = async () => {
        if (dataByToken) {
            if (dataByToken.PathType === ePathType.AWSS3Bucket) {
                setBucketInfoToSendAWS({
                    dataSourceToken: dataByToken.Token,
                });
            }
            else if (dataByToken.PathType === ePathType.Dump) {
                if (file) {
                    setBucketInfoToSendDump({
                        file: file,
                        delimiter: delimiter !== '' ? delimiter : ',',
                        withChecksum: false,
                        dataSourceToken: dataByToken.Token,
                    });
                }
                else {
                    alertify.alert('File not selected');
                }
            }
            else {
                alertify.alert('PathType not implemented');
            }
        }
        else {
            alertify.alert('Data Source not selected');
        }
    };

    const fileName = acceptedFiles.map(acceptedFile => (
        <Typography>
            {acceptedFile.name}
        </Typography>
    ));

    signalR.listen('importStatus', (object: { skipped: number, inserted: number, loadedFiles: number, finishedImporting: boolean, total: number }) => {
        console.log('acessado listen');
        setBucketInfo({
            loadedFiles: object.loadedFiles,
            insertedFiles: object.inserted,
            skippedFiles: object.skipped,
            total: object.total
        });

        if (object.finishedImporting) {
            setImportingProcess(false);
        }
    });

    useEffect(() => {
        if (mapObjectLoading || importToDumpLoading) {
            setImportingProcess(true);
        }
    }, [mapObjectLoading, importToDumpLoading]);

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (importToDump !== 'Finished' && importToDump !== undefined) {
            alertify.alert(importToDump);
            setImportingProcess(false);
        }
    }, [importToDump]);

    useEffect(() => {
        if (mapObjectData !== 'Finished' && mapObjectData !== undefined) {
            alertify.alert(mapObjectData);
            setImportingProcess(false);
        }
    }, [mapObjectData]);

    return (
        <Fragment>
            <Box css={style}>
                <FormControl fullWidth className='data-source-select' size='small' >
                    <GeopostLoading loading={dataSourcesLoading} />
                    <InputLabel color='primary'>Data Source</InputLabel>
                    <Select
                        value={fileDataSources}
                        onChange={(event) => { setFileDataSources(event.target.value) }}
                        label='Location Type'
                        color='primary'
                        disabled={importingProcess}
                    >
                        <MenuItem value=''>None</MenuItem>
                        {dataSources?.map((item) => (
                            <MenuItem value={item.Token}>{item.Name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box className='data-source-info'>
                    <GeopostLoading loading={dataLoading} />
                    <Typography>Base Path: {dataByToken ? pathType(dataByToken.PathType) : ''}</Typography>
                    <Typography>Filter Path: {dataByToken ? dataByToken.FilterPath : ''}</Typography>
                    {(dataByToken?.PathType !== ePathType.Dump) &&
                        <Fragment>
                            <Typography>File Size Limit: {dataByToken ? dataByToken.FilterFileSizeMaximum : ''}</Typography>
                            <Typography>File Extensions: {dataByToken ? (dataByToken.FilterExtensions === '' ? 'All' : dataByToken.FilterExtensions) : ''}</Typography>
                        </Fragment>
                    }
                    {(dataByToken?.PathType === ePathType.Dump) &&
                        <Fragment>
                            <TextField fullWidth label={'File Delimiter'} value={delimiter} onChange={(event) => setDelimiter(event.target.value)} />
                            <Box className='dropzone-box'>
                                <Box {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <Tooltip
                                        title="Drag and drop files here, or click to select files"
                                        placement='top'
                                        arrow
                                        TransitionComponent={Zoom}
                                        slots={{
                                            popper: (props) => <Popper {...props} disablePortal={true} sx={{ zIndex: 2, width: '100px', textAlign: 'justify' }} />
                                        }}
                                    >
                                        <Box /* variant='contained' color='primary' */ className='publish-button'>
                                            <PublishIcon />
                                        </Box>
                                    </Tooltip>
                                </Box>
                                {fileName}
                            </Box>
                        </Fragment>
                    }
                </Box>
                <Box className='data-source-import-info'>
                    {bucketInfo !== null &&
                        <Fragment>
                            <GeopostLoading loading={dataLoading} />
                            <Box className='data-source-import-info-check'>
                                <Typography>Files Checked: {bucketInfo ? bucketInfo.loadedFiles : 0}{bucketInfo.total > 0 ? `/${bucketInfo.total}` : ''}</Typography>
                                {importingProcess ?
                                    (<Typography className='fade-text'>The import process keep going, do not close or reload this screen</Typography>)
                                    :
                                    (<Typography>The import process finished</Typography>)
                                }
                            </Box>

                            <Typography>Inserted Files: {bucketInfo ? bucketInfo.insertedFiles : 0}</Typography>
                            <Typography>Skipped Files: {bucketInfo ? bucketInfo.skippedFiles : 0}</Typography>
                        </Fragment>
                    }
                </Box>
                <Button disabled={importingProcess} className='import-files' variant='contained' color='primary' onClick={() => importFiles()}>
                    Import Files
                </Button>
            </Box>
        </Fragment>
    );
};;