import { useQuery } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { IMneumonic } from 'features/cross-section/models/interfaces/INeumonic';
import { getParamsFromUrl } from '../utils/authUtils';
import { ICrossSection } from 'features/cross-section/models/interfaces/ICrossSection';
import { IWellGenericCut } from 'features/cross-section/models/interfaces/IWellGenericCut';
import { IWellCatalog } from 'features/cross-section/models/interfaces/IWellCatalog';
import { axios } from 'configurations/axios';

export function useGetAllMneumonics(){
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    return useQuery('getAllMneumonics',async ()=>{
        const params = getParamsFromUrl();
        const response = await axios.request<IResponseAPI<IMneumonic[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.wellRender + '/well/GetAllNeumonicsFromFile',
            headers: {
                Authorization: `Bearer ${params.jwtToken}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data.Result;

    });
}
export function GetCrossSection(token:string){
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    return useQuery('getForViewer',async ()=>{
        const params = getParamsFromUrl();
        const response = await axios.request<IResponseAPI<ICrossSection>>({
            method: 'get',
            url: tenantConfig?.endpoints.wellLogs + '/Wells/WellCrossSection/getForViewer',
            headers: {
                Authorization: `Bearer ${params.jwtToken}`,
                'Content-Type': 'application/json'
            },params:{
                wellCrossSectionToken:token
            }
        });
        return response.data.Result;

    });
}
export function GetAllTracking(token:string){
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    return useQuery('getAllTracking',async ()=>{
        const params = getParamsFromUrl();
        const response = await axios.request<IResponseAPI<IWellGenericCut[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.wellLogs + '/Wells/WellCrossSection/GetAllTracking',
            headers: {
                Authorization: `Bearer ${params.jwtToken}`,
                'Content-Type': 'application/json'
            },params:{
                wellCrossSectionItemToken:token
            }
        });
        return response.data.Result;

    },{enabled: false});
}
export function GetUnits(){
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    return useQuery('GetUnits',async ()=>{
        const params = getParamsFromUrl();
        const response = await axios.request<IResponseAPI<string[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.wellLogs + '/WellLog/GetUnits',
            headers: {
                Authorization: `Bearer ${params.jwtToken}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data.Result;
    });
}
export function GetCatalog(wellId:string ,pathS3:string,projectToken = '77fb6644-96fc-45d5-a73f-bcf504c73a81'    ){
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    return useQuery('GetCatalog',async ()=>{
        const params = getParamsFromUrl();
        const response = await axios.request<IResponseAPI<IWellCatalog[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.wellLogs + '/WellLog/GetCatalog',
            headers: {
                Authorization: `Bearer ${params.jwtToken}`,
                'Content-Type': 'application/json'
            },params:{
                wellId:wellId,
                pathS3:pathS3,
                projectToken:projectToken
            }
        });
        return response.data.Result;

    });
}
export function GetCurveInfo(neumonic:string ,frame:string,LogicalFile:string,paths:string[]  ){
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    console.log(neumonic,
        frame,
        LogicalFile,
        paths);
    return useQuery('GetCatalog',async ()=>{
        const params = getParamsFromUrl();
        const response = await axios.request<IResponseAPI<IWellCatalog[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.render + '/Well/GetCruveInfo',
            headers: {
                Authorization: `Bearer ${params.jwtToken}`,
                'Content-Type': 'application/json'
            },params:{
                neumonic:neumonic,
                frame:frame,
                LogicalFile:LogicalFile,
                paths:paths
            }
        });
        return response.data.Result;

    });
}