import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { LineString, MultiPoint } from 'ol/geom';
import { Feature } from 'ol';

import { InterpretationMode } from '../models/enums/InterpretationMode';
import { IGetCoordinatesDataResponse } from '../models/interfaces/IGetCoordinatesDataResponse';
import { IInterpretationDictionary } from '../hooks/useInterpretation2DSeismicViewer';
import { GeopostMap } from 'features/seismic/models/classes/GeopostMap';
import { ICoordinateString } from 'features/seismic/models/interfaces/ICoordinate';
import { getOriginalXByScale, getOriginalYByScale } from 'features/seismic/utils/calculateScreenValuesUtils';

export const buildLayerStyle = (layer: VectorLayer<VectorSource>, data: IGetCoordinatesDataResponse, mode: InterpretationMode) => {
    const styles: Array<Style> = [];

    if (data) {
        styles.push(buildLayerStyleByData(data));
    }

    if (mode === InterpretationMode.edit) {
        styles.push(buildLayerModifyStyle());
    }

    layer.setStyle(styles);
};

export const buildCrossLayerStyle = (color: string) => {
    return new Style({
        image: new Circle({
            radius: 5,
            fill: new Fill({
                color: color
            }),
            stroke: new Stroke({
                color: '#fff',
                width: 2
            })
        })
    });
};

export const removeInteractions = (item?: IInterpretationDictionary, map?: GeopostMap) => {
    if (map && item) {
        if (item.modify) {
            map.removeInteraction(item.modify);
            item.modify = undefined;
        }

        if (item.draw) {
            map.removeInteraction(item.draw);
            item.draw = undefined;
        }

        if (item.dragbox) {
            map.removeInteraction(item.dragbox);
            item.dragbox = undefined;
        }
    }
};

export const buildLayer = (visible = true, valueToAddToZIndex = 0) => new VectorLayer({
    source: new VectorSource(),
    zIndex: 9999999999,
    visible: visible,
});

export const buildCoordinatesToSave = (features: Feature[], scaleX: number, scaleY: number, minTrace: number) => {
    const coordinates: Array<Array<ICoordinateString>> = [];

    for (let feature of features) {
        const geometry = feature.getGeometry() as LineString | undefined;

        if (geometry) {
            const geomCoordinates = geometry.getCoordinates();
            const featureCoordinates: Array<ICoordinateString> = [];

            for (let i = 0; i < geomCoordinates.length; i++) {
                const coordinate = geomCoordinates[i];

                const x = getOriginalXByScale(coordinate[0], scaleX, minTrace);
                const y = getOriginalYByScale(coordinate[1], scaleY);

                if (isNaN(x) || isNaN(y)) {
                    console.error('Erro ao gerar coordenada');
                    continue;
                }

                featureCoordinates.push({
                    X: x.toString(),
                    Y: y.toString()
                });

            }

            if (featureCoordinates.length > 0){
                coordinates.push(featureCoordinates);
            }
        }
    }

    return coordinates;
};

const buildLayerStyleByData = (data: IGetCoordinatesDataResponse) =>
    new Style({
        stroke: new Stroke({
            color: data.Color,
            width: data.Width
        })
    });

const buildLayerModifyStyle = () => new Style({
    stroke: new Stroke({
        color: 'rgb(0,255,255)',
        width: 3
    }),
    fill: new Fill({
        color: 'rgba(0, 255, 255, 0.5)'
    }),
    image: new Circle({
        radius: 5,
        fill: new Fill({
            color: 'orange'
        })
    }),
    geometry: (feature) => {
        const geometry = feature.getGeometry();

        if (geometry) {
            const lineString = geometry as LineString;
            const coordinates = lineString.getCoordinates();
            return new MultiPoint(coordinates);
        }
    }
});