import { AxiosResponse } from 'axios';

import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { LineType } from '../models/enums/LineType';
import { LineGeometryPerformanceData } from '../models/types/LineGeometryPerformanceData';
import { MetadataPerformanceData } from '../models/types/MetadataPerformanceData';
import { PerformanceData } from '../models/types/PerformanceData';
import { SurveyGeometryPerformanceData } from '../models/types/SurveyGeometryPerformanceData';
import { TilePerformanceData } from '../models/types/TilePerformanceData';
import { IErrorResponse } from '../models/interfaces/IErrorResponse';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isErrorResponse(item: any): item is IErrorResponse {
    return 'Error' in item && 'ErrorName' in item;
}

export const getPerformanceDataFromAxiosResponse = <T, >(response: AxiosResponse<IResponseAPI<T>, unknown>, requestLeaveAt: Date) : PerformanceData | null => {
    const requestCameOnServerAtHeader: string | undefined = response.headers['x-gp-debug-request-came-at'];
    const responseLeaveServerAtHeader: string | undefined = response.headers['x-gp-debug-response-leave-at'];

    /*if (!requestCameOnServerAtHeader  || !responseLeaveServerAtHeader) {
        console.warn('Response does not have the required headers for performance monitoring, or they are not accessible.');
        return null;
    }*/
    const requestCameOnServerAt = requestCameOnServerAtHeader ? new Date(requestCameOnServerAtHeader):null;
    const responseLeaveServerAt = responseLeaveServerAtHeader ? new Date(responseLeaveServerAtHeader):null;

    const currentTime = new Date().getTime();
    const requestNetworkTime = requestCameOnServerAt ? (requestCameOnServerAt.getTime() - requestLeaveAt.getTime()) / 1000: 0;
    const responseNetworkTime = responseLeaveServerAt ? (currentTime - responseLeaveServerAt.getTime()) / 1000: 0;
    const totalTime = (currentTime - requestLeaveAt.getTime()) / 1000;

    const processingTime = responseLeaveServerAt && requestCameOnServerAt ? (responseLeaveServerAt.getTime() - requestCameOnServerAt.getTime()) / 1000:0;

    let error = '';

    if (response.data.Result && isErrorResponse(response.data.Result)){
        const responseError = response.data.Result as IErrorResponse;

        error = `${responseError['ErrorName']} - ${responseError.Error}`;
    }

    return {
        requestNetworkTime: parseFloat(requestNetworkTime.toFixed(3)),
        responseNetworkTime: parseFloat(responseNetworkTime.toFixed(3)),
        totalTime: parseFloat(totalTime.toFixed(3)),
        serverProcessingTime: parseFloat(processingTime.toFixed(3)),
        error: error
    };
};

export const getTilePerformanceDataFromAxiosResponse = <T, >(response: AxiosResponse<T, unknown>, requestSendAt: Date, lineNumber: number, lineType: LineType, tileWidth: number) : TilePerformanceData | null => {
    const queryApiTimeHeader = response.headers['x-gp-debug-api-query-time'];
    const requestCameOnServerAtHeader = response.headers['x-gp-debug-request-came-at'];
    const responseLeaveServerAtHeader = response.headers['x-gp-debug-response-leave-at'];
    const plotTimeHeader = response.headers['x-gp-debug-tile-plot-time'];

    const startSample = response.headers['start-sample'];
    const endSample = response.headers['end-sample'];
    const startTrace = response.headers['start-trace'];
    const endTrace = response.headers['end-trace'];

    if (!queryApiTimeHeader || !requestCameOnServerAtHeader || !responseLeaveServerAtHeader || !plotTimeHeader || !startSample || !endSample || !startTrace || !endTrace) {
        console.warn('A tile request doesn\'t have the required debug headers');
        return null;
    }

    const plotTime = parseFloat(plotTimeHeader);
    const queryApiTime = parseFloat(queryApiTimeHeader);
    const requestCameOnServerAt = new Date(requestCameOnServerAtHeader);
    const responseLeaveServerAt = new Date(responseLeaveServerAtHeader);
    const currentTime = new Date().getTime();
    const processingTime = ((responseLeaveServerAt.getTime() - requestCameOnServerAt.getTime()) / 1000 - queryApiTime);
    const requestNetworkTime = (requestCameOnServerAt.getTime() - requestSendAt.getTime()) / 1000;
    const responseNetworkTime = (currentTime - responseLeaveServerAt.getTime()) / 1000;
    const totalTime = (currentTime - requestSendAt.getTime()) / 1000;
    const geopostSource = response.headers['Geopost-Source'];
    const traceId = response.headers['Geopost-Trace-Id'];

    console.log(geopostSource);

    return ({
        traceId: traceId,
        source: geopostSource,
        apiTime: parseFloat(queryApiTime.toFixed(3)),
        lineNumber: lineNumber,
        lineType: lineType,
        serverProcessingTime: parseFloat(processingTime.toFixed(3)),
        requestNetworkTime: parseFloat(requestNetworkTime.toFixed(3)),
        responseNetworkTime: parseFloat(responseNetworkTime.toFixed(3)),
        plotTime: plotTime,
        totalTime: parseFloat(totalTime.toFixed(3)),
        tileWidth: tileWidth,
        startSample: parseFloat(startSample),
        endSample: parseFloat(endSample),
        startTrace: parseFloat(startTrace),
        endTrace: parseFloat(endTrace)
    });
};

export const getMetadataPerformanceDataFromAxiosResponse = <T, >(response: AxiosResponse<IResponseAPI<T>, unknown>, requestLeaveAt: Date, volumeToken: string) : MetadataPerformanceData | null => {
    const performanceData = getPerformanceDataFromAxiosResponse(response, requestLeaveAt);
    const apiQueryTimeHeader = response.headers['x-gp-debug-api-query-time'];

    if (!performanceData) {
        console.warn('Metadata response does not have the required headers for performance monitoring');
        return null;
    }
    else {
        const apiTime = parseFloat(parseFloat(apiQueryTimeHeader ?? 0).toFixed(3));

        return ({
            ...performanceData,
            apiTime,
            volumeToken: volumeToken,
        });
    }
};

export const getSurveyGeometryPerformanceDataFromAxiosResponse = <T, >(response: AxiosResponse<IResponseAPI<T>, unknown>, requestLeaveAt: Date, volumeToken: string) : SurveyGeometryPerformanceData | null => {
    const performanceData = getPerformanceDataFromAxiosResponse(response, requestLeaveAt);
    const apiQueryTimeHeader = response.headers['x-gp-debug-api-query-time'];
    const geomProcessingTimeHeader = response.headers['x-gp-debug-geom-processing-time'];

    if (!apiQueryTimeHeader || !performanceData || !geomProcessingTimeHeader) {
        console.warn('Metadata response does not have the required headers for performance monitoring');
        return null;
    }
    else {
        const apiQueryTime = parseFloat(parseFloat(apiQueryTimeHeader).toFixed(3));
        const geomProcessingTime = parseFloat(parseFloat(geomProcessingTimeHeader).toFixed(3));

        return ({
            ...performanceData,
            apiQueryTime,
            volumeToken,
            geomProcessingTime

        });
    }
};

export const getLineGeometryPerformanceDataFromAxiosResponse = <T, >(response: AxiosResponse<IResponseAPI<T>, unknown>, requestLeaveAt: Date, lineNumber: number, lineType: LineType) : LineGeometryPerformanceData | null  => {
    const lineQueryTimeHeader = response.headers['x-gp-debug-line-query-time'];
    const metadataQueryTimeHeader = response.headers['x-gp-debug-metadata-query-time'] ?? '0';
    const lineProcessingTimeHeader = response.headers['x-gp-debug-line-processing-time'];
    const performanceData = getPerformanceDataFromAxiosResponse(response, requestLeaveAt);

    if (!lineQueryTimeHeader || !metadataQueryTimeHeader || !performanceData) {
        console.warn('Line geometry response does not have the required headers for perf monitoring');
        return null;
    }

    const lineQueryTime = parseFloat(parseFloat(lineQueryTimeHeader).toFixed(3));
    const metadataQueryTime = parseFloat(parseFloat(metadataQueryTimeHeader).toFixed(3));
    const lineProcessingTime = parseFloat(parseFloat(lineProcessingTimeHeader).toFixed(3));

    return ({
        ...performanceData,
        lineQueryTime,
        metadataQueryTime,
        lineNumber,
        lineType,
        lineProcessingTime,
        serverProcessingTime: performanceData.serverProcessingTime - lineProcessingTime
    });
};