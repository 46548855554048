import { Box, Button, css, Typography } from '@mui/material';
import { Fragment } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';

import { useSessionStore } from 'session/useSessionStore';

export function Navbar() {
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }));

    const primaryColor = tenantConfig ? tenantConfig.main.palette.primary.main : '';
    const primaryTextColor = tenantConfig ? tenantConfig.main.palette.primary.contrastText : '';
    const logo = tenantConfig ? tenantConfig.layout.logoPath : '';

    const style = css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 12px',
        backgroundColor: primaryColor,
        height: '80px',
        '& .content': {
            marginTop: '0px',
            color: primaryTextColor,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        '& .imageLogo': {
            width: '150px',
            height: 'auto',
        },
        '& .label-title': {
            marginLeft: '16px',
            fontSize: '22px',
            fontWeight: 'bold',
            fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
        },
        '& .label-username': {
            marginRight: '16px',
            fontSize: '20px',
            color: primaryTextColor,
            fontWeight: 'bold',
            fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
        },
        '& strong': {
            marginLeft: '10rem',
            fontSize: '21px',
            fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
        }
    });

    return (
        <Fragment>
            <Box css={style}>
                <Box className='content'>
                    <Box
                        component='img'
                        alt='The house from the offer.'
                        className='imageLogo'
                        src={logo}
                    />
                    {/* <img src={logo} /> */}
                    <Typography className='label-title'>File Indexing</Typography>
                </Box>
                {/* <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button variant='contained' color='secondary'>
                        <LogoutIcon />
                    </Button>
                </Box> */}
            </Box>
        </Fragment>
    );
}