import { useMutation, useQuery } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { FeatureGeom } from '../../../models/classes/FeatureGeom';
import { getUrlParams } from 'features/seismic-3d/utils/Seismic3DUrlUtils';
import { IntersectingLayer } from 'features/seismic-3d/models/types/IntersectingLayer';
import { axios } from 'configurations/axios';
import { IUseGetIntersectingFeaturesShapeByLayerAndGeomParams } from 'features/seismic-3d/models/interfaces/requests/IUseGetIntersectingFeaturesShapeByLayerAndGeomParams';

export function useGetGeomByLayerAndFeature(layerId: number, featureId: number, type: string, resultSrid: number){
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    return useQuery(
        `getGeomByLayerAndFeature?layerId=${layerId}&featureId=${featureId}`,
        async () => {
            const response = await axios.get<IResponseAPI<FeatureGeom>>(tenantConfig?.endpoints.layers + '/Features3DInfo/GetGeomByLayerAndFeature', {
                params: {
                    layerId: layerId,
                    featureId: featureId,
                    resultSrid: resultSrid
                },
                headers: {
                    Authorization: `Bearer ${getUrlParams().jwtToken}` ,
                    'Content-Type': 'application/json'
                }
            });
            return response.data.Result;
        },
        {
            enabled: !!layerId
        }
    );
}

export function useGetLayersIntersectingInGeom(geomWkt : string | undefined, geomSrid : number){
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    return useMutation(
        ['getLayersIntersectingInGeom', geomWkt],
        async () => {
            const response = await axios.post<IResponseAPI<IntersectingLayer[]>>(
                tenantConfig?.endpoints.layers + '/Features3DInfo/GetIntersectingLayersInGeom',
                {
                    geomWKT: geomWkt,
                    geomSrid: geomSrid
                },
                {
                    headers: {
                        Authorization:  `Bearer ${getUrlParams().jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            return response.data.Result;
        }
    );
}

/*export async function getIntersectingFeaturesShapeByLayerAndGeom(layerId: number, geomWKT: string, geomSrid: number, resultSrid: number){
    // const { tenantConfig } = useSessionStore(state => ({
    //     tenantConfig: state.tenantConfiguration
    // }));
    const response = await axios.post<IResponseAPI<FeatureGeom>>(
        tenantConfig?.endpoints.layers + '/Features3DInfo/GetIntersectingFeaturesShapeByLayerAndGeom',
        {
            layerId: layerId,
            geomWKT: geomWKT,
            geomSrid: geomSrid,
            resultSrid: resultSrid
        },
        {
            headers:{
                Authorization:  `Bearer ${getUrlParams().jwtToken}`,
                'Content-Type': 'application/json'
            }
        }
    );
    return response.data.Result;
}*/

export function useGetIntersectingFeaturesShapeByLayerAndGeom(params : IUseGetIntersectingFeaturesShapeByLayerAndGeomParams | undefined) {
    const apiUrl = useSessionStore(state => state.tenantConfiguration?.endpoints.layers);

    return useQuery(
        ['GetIntersectingFeaturesShapeByLayerAndGeom', params],
        async () => {
            const response = await axios.post(
                apiUrl + '/Features3DInfo/GetIntersectingFeaturesShapeByLayerAndGeom',
                {
                    layerId: params?.layerId,
                    geomWKT: params?.geomWkt,
                    geomSrid: params?.geomSrid,
                    resultSrid: params?.resultSrid
                },
                {
                    headers:{
                        Authorization:  `Bearer ${getUrlParams().jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data.Result;
        },
        {
            enabled: !!params
        }
    );
}