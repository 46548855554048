import { useQuery } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { axios } from 'configurations/axios';

export function useWebTourCheckUserRead(webTourType: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));
    return useQuery('checkUserRead' + webTourType, async () => {
        const response = await axios.request<IResponseAPI<boolean>>({
            method: 'get',
            params: {
                webTourType: webTourType
            },
            url: tenantConfig?.endpoints?.webTour + '/Users/WebTour/checkUserRead',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    },{
        enabled: false,//tenantConfig?.endpoints?.webTour !== undefined,
        refetchOnWindowFocus: false
    }
    );
}

export function useWebTourRegisterUserRead(webTourType: string, register: boolean) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('registerUserRead' + webTourType, async () => {
        const paramsViewer = getParamsFromUrl();
        const accessToken = paramsViewer.jwtToken;

        const response = await axios.request<IResponseAPI<boolean>>({
            method: 'get',
            params: {
                webTourType: webTourType
            },
            url: tenantConfig?.endpoints?.webTour + '/Users/WebTour/RegisterUserRead',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    },{
        enabled: register,
        refetchOnWindowFocus: false
    }
    );
}

