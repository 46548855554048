import { DoubleSide, Material, MeshBasicMaterial, MeshPhongMaterial, MeshPhysicalMaterial, Texture, TextureLoader } from 'three';

import { ISeismicViewer3DMap } from 'features/seismic-3d/models/interfaces/ISeismicViewer3DMap';
import { getColoredImageData, getGridShaddingData, getShadedImageData } from './GeopostGridMaterialUtils';
import { Metadata3D } from 'features/seismic-3d/models/types/Metadata3D';

const pictureTextureWidth = 1920;
const pictureTextureHeight = 968;

export class GeopostGridMaterial extends MeshPhongMaterial {

    public static buildFromWms = async (grid: ISeismicViewer3DMap, wmsMainGrayScale: File, wmsAlpha: File, colored: boolean, displacementScale: number) => {
        let material : GeopostGridMaterial | null = null;
        const mainGrayScaleFileReader = new FileReader();
        const promise = new Promise<GeopostGridMaterial>(resolve => {
            mainGrayScaleFileReader.onload = async () => {
                if (mainGrayScaleFileReader.result) {
                    const mapTextureGrayScale = new TextureLoader().load(mainGrayScaleFileReader.result.toString());
                    const alphaFileReader = new FileReader();
                    alphaFileReader.readAsDataURL(wmsAlpha);
                    alphaFileReader.onloadend = async () => {
                        if (alphaFileReader.result) {
                            const mapTextureAlpha = new TextureLoader().load(alphaFileReader.result.toString());
                            const canvas = document.createElement('canvas');
                            const canvasContext = canvas.getContext('2d');
                            canvas.width = mapTextureGrayScale.image.width;
                            canvas.height = mapTextureGrayScale.image.height;

                            if (!canvasContext) {
                                throw new Error('canvas context doesn\'t exist');
                            }

                            canvasContext.drawImage(mapTextureGrayScale.image, 0, 0);
                            const imageData = canvasContext?.getImageData(0, 0, mapTextureGrayScale.image.width, mapTextureGrayScale.image.height);

                            const itemShaddingData = getGridShaddingData(grid);
                            const transformedImageData = colored ? getColoredImageData(imageData, itemShaddingData) : getShadedImageData(imageData, itemShaddingData);
                            canvasContext.putImageData(transformedImageData, 0, 0);

                            const image = new Image();
                            image.src = canvas.toDataURL();
                            const imageDataTexture = new Texture(image);
                            imageDataTexture.needsUpdate = true;

                            //const alphaTest = 0.5;

                            resolve(new MeshPhongMaterial({
                                map: imageDataTexture,
                                alphaMap: mapTextureAlpha,
                                displacementMap: mapTextureGrayScale,
                                displacementScale: displacementScale,
                                side: DoubleSide,
                                shadowSide: DoubleSide,
                                transparent: true
                            }));
                        }
                    };
                }
            };
        });
        mainGrayScaleFileReader.readAsDataURL(wmsMainGrayScale);
        return (await promise);
    };
}