import { css, IconButton, InputAdornment, TextField } from '@mui/material';
import { useState, useEffect, FocusEventHandler, ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from 'session/useSessionStore';
import { Reset } from 'components/icons/components';
import { WIDTH_LANE } from '../consts/layotsConsts';
import { useCrossSectionStore } from '../stores/useCrossSectionStore';

const style = css({
    '& input': {
        width: 40
    }
});

export function CrossSectionLaneWidth(){
    const { t } = useTranslation('crossSection');
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));

    const { laneWidth, setLaneWidth } = useCrossSectionStore(state => ({
        laneWidth: state.laneWidth,
        setLaneWidth: state.setLaneWidth
    }));

    const [value, setValue] = useState(laneWidth);

    useEffect(() => {
        setValue(laneWidth);
    }, [laneWidth]);

    const onBlurInput:FocusEventHandler<HTMLInputElement> = (event) => {
        setLaneWidth(parseFloat(event.target.value));
    };

    const onChangeInput:ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue(parseFloat(event.target.value));
    };

    const reset = () =>{
        setLaneWidth(WIDTH_LANE);
    };

    return  (<fieldset css={style}>
        <legend style={{color: tenantConfig?.main.palette.primary.contrastText}}>{t('width')}</legend>
        <TextField
            value={value}
            onBlur={onBlurInput}
            onChange={onChangeInput}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton size='small' onClick={reset} style={{color: tenantConfig?.main.palette.primary.contrastText}}>
                        <Reset />
                    </IconButton>
                </InputAdornment>
            }
            }
        />
    </fieldset>);

    //return <TextField label='Width' css={styles} value={value} onChange={(event) => changeValue(parseInt(event.target.value))}/>;
}