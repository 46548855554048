import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Button } from '@mui/material';
import { Component } from 'react';

interface A {
    hasError: boolean;
    messageError: string;
}

interface Props {
    children: EmotionJSX.Element;
}

export class ErrorBoundary extends Component<Props, A> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false, messageError: '' };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error:Error, errorInfo:object) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);

        console.log('Componente de erro');

        this.setState({
            messageError: error.message
        });
    }

    reload(){
        window.location.reload();
    }

    render() {
        if (this.state.hasError) {
        // You can render any custom fallback UI
            return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column'}}>
                <h1>Sorry, something went wrong.</h1>
                <h3>{this.state.messageError}</h3>
                <Button onClick={this.reload} variant='contained'>Reload</Button>
            </div>;
        }

        return this.props.children;
    }
}