import React, { Fragment } from 'react';

import { useSessionStore } from 'session/useSessionStore';

export type GeopostLogoProps = {
    maxWidth?: number,
    maxHeight?: number,
};

export const GeopostLogo = ({ maxWidth = 100, maxHeight = 32 } : GeopostLogoProps) => {
    const logoPath = useSessionStore(state => state.tenantConfiguration?.layout.logoPath);
    const logoClickLink = useSessionStore(state => state.tenantConfiguration?.logoClickLink);
    return (
        <Fragment>
            {
                logoPath ? <a title={logoClickLink ? 'Click to open the home page in another tab' : ''} href={logoClickLink ? logoClickLink : '#'} target='_blank' rel="noreferrer"><img src={logoPath} style={{maxWidth, maxHeight}}/></a> : null
            }
        </Fragment>
    );
};