import { MutableRefObject, useEffect, useRef } from 'react';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';

import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { IRelatedData } from 'features/seismic/models/interfaces/IRelatedData';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { useMapStore } from 'features/seismic/stores/useMapStore';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { getXValue, getYValue, createCircleFeatureRelatedData, createLineFeatureRelatedData } from 'features/seismic/utils/wellsUtils';
import { useAPI } from 'hooks/useAPI';
import { FetchType } from 'models/enums/FetchType';
import { ApiType } from 'models/enums/ApiType';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { LineType } from 'features/seismic/models/enums/LineType';
import { getXByTrace } from 'features/seismic/utils/calculateScreenValuesUtils';

export function useRelatedData(data: IWell, domain: AmplitudeDomain, datum: number, dataGeometryLine: ILineString) {
    const { calculator, surveyMetadata, scale, setWellsLayers, wellRelatedData, setWellRelatedData, wellRelatedDataTextLayers } = useSeismicStore(state => ({
        calculator: state.calculator,
        scale: state.scale,
        surveyMetadata: state.surveyMetadata,
        setWellsLayers: state.setWellsLayers,
        wellRelatedData: state.wellRelatedData,
        setWellRelatedData: state.setWellRelatedData,
        wellRelatedDataTextLayers: state.wellRelatedDataTextLayers
    }));

    const { map } = useMapStore(state => ({
        map: state.map
    }));

    const { lineType } = useLine3DNavigationStore(state => ({
        lineType: state.lineType
    }));

    const { execute: executeRelatedData, data: dataRelatedData } = useAPI<IRelatedData>('Wells/RelatedInfo/GetRelatedData');

    const verifyScale = useRef<{ x: number, y: number }>({x: scale.x, y: scale.y});

    useEffect(() => {
        verifyScale.current = { x: scale.x, y: scale.y };
    }, [scale]);

    const getRelatedData = (checked: boolean, loading: MutableRefObject<boolean>) => {
        if ((domain !== null || domain !== undefined) && surveyMetadata) {
            if (!wellRelatedData[(data.Id).toString()]) {
                const fetch = async () => {
                    loading.current = true;
                    const relatedDataResponse = await executeRelatedData({
                        type: FetchType.Post,
                        apiType: ApiType.WebApi,
                        body: {
                            wellId: data.Id,
                            trace: data.Trace,
                            domain: domain,
                            datum: datum
                        }
                    });

                    if (relatedDataResponse && verifyScale.current.x === scale.x && verifyScale.current.y === scale.y) {
                        let relatedDataFeatures: Feature[] = [];

                        for (let i = 0; i < relatedDataResponse.pickers.length; i++) {
                            if (relatedDataResponse.pickers[i].YPosition[0] > 0 || relatedDataResponse.pickers[i].YPosition[1] > 0) {
                                relatedDataResponse.pickers[i].YPosition[0] *= -1;
                                relatedDataResponse.pickers[i].YPosition[1] *= -1;
                            }
                            const increment = lineType === LineType.Inline ? surveyMetadata!.Survey3DInfo.XlineIncrement : surveyMetadata!.Survey3DInfo.InlineIncrement;
                            const start = calculator!.getMinTrace(surveyMetadata!);

                            const relatedDataX =  getXByTrace(dataGeometryLine.Min + data.Trace * increment, scale.x, start, increment) + 1;

                            //const relatedDataX = getXValue(relatedDataResponse.pickers[i].XPosition, scale.x, dataGeometryLine.Min, start, increment);
                            const relatedDataY = [getYValue(relatedDataResponse.pickers[i].YPosition[0], scale.y, calculator!.calculateSampleInterval(surveyMetadata)), getYValue(relatedDataResponse.pickers[i].YPosition[1], scale.y, calculator!.calculateSampleInterval(surveyMetadata))];

                            let relatedDataLayer: Feature;

                            if (relatedDataResponse.pickers[i].YPosition[0] === relatedDataResponse.pickers[i].YPosition[1]) {
                                const relatedDataLabel = relatedDataResponse.pickers[i].Labels ?? `${relatedDataResponse.pickers[i].Start}m`;
                                relatedDataLayer = createCircleFeatureRelatedData(relatedDataX, relatedDataY[0], relatedDataLabel, 'relatedData', relatedDataResponse.pickers[i].FileManager, relatedDataResponse.pickers[i].FileName, data.Name);
                            }
                            else {
                                const relatedDataLabel = relatedDataResponse.pickers[i].Labels ?? `${relatedDataResponse.pickers[i].Start}m - ${relatedDataResponse.pickers[i].End}m`;
                                relatedDataLayer = createLineFeatureRelatedData(relatedDataX, relatedDataY, relatedDataLabel, 'relatedData', relatedDataResponse.pickers[i].FileManager, relatedDataResponse.pickers[i].FileName, data.Name);
                            }

                            relatedDataFeatures.push(relatedDataLayer);
                        }

                        let newLayer = new VectorLayer({ source: new VectorSource({ features: relatedDataFeatures }) });
                        newLayer.setZIndex(99999995);
                        map?.addLayer(newLayer);

                        storeImages(relatedDataResponse, newLayer, checked);
                    }

                    loading.current = false;

                };
                fetch();
            }
            else {
                addOrRemoveLayers(checked);
            }
        }

    };

    const storeImages = (relatedData: IRelatedData | null | undefined, layer:  VectorLayer<VectorSource>, checked: boolean) => {
        if (relatedData) {
            let images = wellRelatedData[(data.Id).toString()]?.images;
            if (!images) {
                images = {};
                for (let i = 0; i < relatedData.pickers.length; i++) {
                    const increment = lineType === LineType.Inline ? surveyMetadata!.Survey3DInfo.XlineIncrement : surveyMetadata!.Survey3DInfo.InlineIncrement;
                    const start = calculator!.getMinTrace(surveyMetadata!);
                    const XPosition = getXValue(relatedData.pickers[i].XPosition, scale.x, dataGeometryLine.Min, start, increment);
                    const YPosition = getYValue(relatedData.pickers[i].YPosition[0], scale.y, calculator!.calculateSampleInterval(surveyMetadata!));
                    let image = {
                        open: false,
                        activated: false,
                        title: `${relatedData.groups[relatedData.pickers[i].Group]?.Title} - ${relatedData.pickers[i].Start}m`,
                        file: relatedData.pickers[i].FileManager,
                        coordinate: [XPosition, YPosition]
                    };
                    images = { ...images, [`${relatedData.pickers[i].Id}`]: image };
                }
            }

            setWellRelatedData((data.Id).toString(), {layer: layer, relatedData: relatedData, opened: checked, images: images});
            setWellsLayers((data.Id).toString(), { relatedData: { layer: layer, checked: true }});
        }
    };

    const addOrRemoveLayers = (checked: boolean) => {
        if (checked) {
            map?.addLayer(wellRelatedData[(data.Id).toString()].layer);
        }
        else {
            map?.removeLayer(wellRelatedData[(data.Id).toString()].layer);

            for (let key in wellRelatedDataTextLayers[data.Id]) {
                map?.removeLayer(wellRelatedDataTextLayers[data.Id][key]);
            }
        }

        let well = wellRelatedData[(data.Id).toString()];
        setWellRelatedData((data.Id).toString(), {layer: well.layer, relatedData: well.relatedData, opened: checked, images: well.images});
    };

    return { getRelatedData: getRelatedData };
}