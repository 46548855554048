import { Fragment } from 'react';

import { FeatureViewer3DPanel } from 'features/seismic-3d/components/FeatureViewer3DPanel';

export function FeatureViewer3D(){
    return (
        <Fragment>
            <FeatureViewer3DPanel/>
        </Fragment>
    );
}