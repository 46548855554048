import { ChangeEvent, useState } from 'react';
import { Button, css, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSeismicStore } from '../stores/useSeismicStore';

const widthInput = 100;

const styles = css({
    '& .MuiInputBase-root': {
        maxWidth: widthInput,
        minWidth: widthInput,
        margin: '0 4px'
    }
});

export function TraceRangeSelector(){
    const { t } = useTranslation('seismic');
    const { surveyMetadata, setRangeTrace, rangeTrace } = useSeismicStore(state => ({
        rangeTrace: state.rangeTrace,
        setRangeTrace: state.setRangeTrace,
        surveyMetadata: state.surveyMetadata,
    }));
    const [start, setStart] = useState(rangeTrace.initial);
    const [end, setEnd] = useState(rangeTrace.end);

    const handleChange =  (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: Type) => {
        let value = parseInt(evt.target.value);

        if (isNaN(value)){
            value = 0;
        }

        if (value < 0){
            value = Math.abs(value);
        }

        if (surveyMetadata && value > surveyMetadata.Header.NumberOfTraces){
            value = surveyMetadata.Header.NumberOfTraces;
        }

        if (type === Type.start){
            setStart(value);
        }
        else {
            setEnd(value);
        }
    };

    const changeRange = () => {
        setRangeTrace(start, end);
    };

    return (<div css={styles}>
        <TextField  label={'Trace Start'} variant='outlined' value={start} onChange={(event) => handleChange(event, Type.start)}/>
        <TextField  label={'Trace End'} variant='outlined' value={end} onChange={(event) => handleChange(event, Type.end)}/>
        <Button variant='outlined' color='inherit' onClick={changeRange}>Ok</Button>
    </div>);
}

enum Type {
    start = 0,
    end = 1
}