import { useFormContext } from 'react-hook-form';
import { TextFieldElement, TextFieldElementProps } from 'react-hook-form-mui';

export const GeopostTextField = ({ fieldName, label, required = true, textFieldProps }: Props) => {
    const { control } = useFormContext();

    const { type, ...restTextFieldProps } = textFieldProps || {};

    return (
        <TextFieldElement
            fullWidth
            label={label}
            name={fieldName}
            variant='outlined'
            color='primary'
            required={required}
            control={control}
            type={type}
            {...restTextFieldProps}
        />
    );
};

interface Props {
    fieldName: string;
    label: string;
    required?: boolean;
    textFieldProps?: Partial<TextFieldElementProps>;
}