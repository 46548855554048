import { Box, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { Fragment, useEffect } from 'react';

import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { GridsListRow } from './GridsListRow';
import { useGetGridsIntersectingInGeom } from 'features/seismic-3d/api/useSeismic3DGridController';

export type GridsListProps = {
    volumeToken: number
};

export const GridsList = () => {
    const { geomWkt, srid } = use3DViewerStore(state => ({
        geomWkt: state.mainFeatureGeomData?.GeomWKT,
        srid: state.mainFeatureSrid
    }));

    const { data: grids, isLoading } = useGetGridsIntersectingInGeom(geomWkt, srid, srid);

    return (
        <Fragment>
            <Box>
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: '50px'}}>
                                    Select
                                </TableCell>
                                <TableCell sx={{width: '50px'}}>
                                    Options
                                </TableCell>
                                <TableCell sx={{width: '300px'}}>
                                    Name
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                isLoading
                                &&
                                <TableCell>
                                    <Stack direction='row' gap={1} sx={{alignItems: 'center'}}>
                                        <h3>Loading...</h3> <CircularProgress size={25}/>
                                    </Stack>
                                </TableCell>
                            }
                            {
                                grids
                                &&
                                grids.map(grid => (
                                    <GridsListRow grid={grid}/>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Fragment>
    );
};
