import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';

import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { WellAnnotationType } from 'features/seismic/models/enums/WellAnnotationType';
import { useGetAllAnnotationForSeismic } from '../api/useWellAnnotationController';
import { GeopostWellGroup } from 'features/seismic-3d/threejs/group/GeopostWellGroup';
import { GeopostAnnotationGroup } from 'features/seismic-3d/threejs/group/GeopostAnnotationGroup';
import { IAnnotation } from 'features/seismic/models/interfaces/IAnnotation';
import { RaycasterEventFactory } from 'features/seismic-3d/threejs/utils/RaycasterEventFactory';
import { GeopostThreeJsConstants } from 'features/seismic-3d/threejs/utils/GeopostThreeJsConstants';
import { getScaled2DCoordinate } from 'features/seismic-3d/threejs/utils/ScaleUtils';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { getPointInWellPathByDepth } from 'features/seismic-3d/components/utils/AnnotationUtils';

export const use3DAnnotations = (
    wellsWithVisibleAnnotation: IWell[],
    annotationType: WellAnnotationType,
    trace: number,
    domain: AmplitudeDomain,
    onAddWellGroup: (well: IWell) => void,
    datum: number,
    color: string,
    selectedColor: string,
    onError: (well: IWell, errorType: string, errorMessage: string) => void,
    onAddAnnotations?: (annotationsData: IAnnotation, wellId: number) => void,
) =>  {
    const {current: lastWellsWithVisibleAnnotation } = useRef<IWell[]>([]);

    const [ loadingAnnotationWells, setLoadingAnnotationWells ] = useState<IWell[]>([]);

    const wellsToAddAnnotation = useMemo(() => {
        return wellsWithVisibleAnnotation.filter(well => !lastWellsWithVisibleAnnotation.includes(well));
    }, [wellsWithVisibleAnnotation]);

    const wellsToRemoveAnnotation = useMemo(() => {
        return lastWellsWithVisibleAnnotation.filter(well => !wellsWithVisibleAnnotation.includes(well));
    }, [wellsWithVisibleAnnotation]);

    const mainFeatureCentroidX = use3DViewerStore(state => state.featureCentroidX);
    const mainFeatureCentroidY = use3DViewerStore(state => state.featureCentroidY);

    const scene = use3DSceneStore(state => state.scene);

    const { mutateAsync: getAnnotations } = useGetAllAnnotationForSeismic();

    const surveyHeightInPixels = use3DViewerStore(state => state.heightPixelFactor);

    const getAnnotationGroupName = useCallback((wellId: number) => 'well-' + wellId + '-annotation-' + annotationType + '-group', [annotationType]);

    const addAnnotation = useCallback((well: IWell) => {
        if (!scene) {
            return;
        }
        getAnnotations({
            annotationType: annotationType,
            datum: datum,
            domain: domain,
            trace: trace,
            wellId: well.Id
        }).then((annotationsData) => {
            if (!!annotationsData.errorType) {
                onError(well, annotationsData.errorType, annotationsData.errorMessage);
                return;
            }
            if (onAddAnnotations) {
                onAddAnnotations(annotationsData, well.Id);
            };
            let wellGroup : GeopostWellGroup;
            do {
                wellGroup = scene.getObjectByName(GeopostWellGroup.getWellGroupName(well.Id)) as GeopostWellGroup;
                if (!wellGroup) {
                    onAddWellGroup(well);
                }
            } while (!wellGroup);
            const wellPoints = wellGroup.wellPoints;
            const groupName = getAnnotationGroupName(well.Id);
            const annotationsGroup = new THREE.Group();
            annotationsGroup.name = groupName;
            const annotationMeshes : GeopostAnnotationGroup[] = [];
            const raycasterEventFactory = new RaycasterEventFactory<THREE.Mesh>();

            annotationsData?.items.forEach(annotationsAuthorData => {
                const authorSubGroup = new THREE.Group();
                authorSubGroup.name = 'author-' + annotationsAuthorData.User;
                annotationsGroup.add(authorSubGroup);
                authorSubGroup.visible = true;
                annotationsAuthorData.Items.forEach(item => {
                    const itemDepth = item.YPosition[0];
                    const depthInPixels = Math.abs(itemDepth) / wellGroup.heightFactor;
                    const scaledDepth = -(depthInPixels / GeopostThreeJsConstants.yDivisionFactor);
                    const scaledAnnotationPos = getScaled2DCoordinate([item.XPosition, item.YPosition[0]], mainFeatureCentroidX, mainFeatureCentroidY);

                    let rotationReference : THREE.Vector3;

                    const annotationPoint = getPointInWellPathByDepth(itemDepth, wellGroup.wellPoints, wellGroup.heightFactor);

                    const annotation = new GeopostAnnotationGroup(item.Labels[0], scaledDepth, annotationPoint.x, annotationPoint.z, wellPoints.length > 2 ? rotationReference! : null, wellGroup.heightFactor, color);
                    authorSubGroup.add(annotation);
                    annotationMeshes.push(annotation);
                });
            });
            const intersectionEvent = raycasterEventFactory.addIntersectionEventOnOneOfGroup(
                annotationMeshes.map(x => x.ring),
                (annotationPoint) => {
                    if (!annotationPoint.parent?.visible) {
                        return;
                    }
                    (annotationPoint.material as THREE.MeshBasicMaterial).color = new THREE.Color(selectedColor);
                    const annotation = (annotationPoint.parent! as GeopostAnnotationGroup);
                    annotation.label.visible = true;
                },
                (annotationPoint) => {
                    (annotationPoint.parent! as GeopostAnnotationGroup).label.visible = false;
                    (annotationPoint.material as THREE.MeshBasicMaterial).color = new THREE.Color(color);
                }
            );
            scene.intersectionEvents.push(intersectionEvent);
            annotationsGroup.userData.intersectionEvent = intersectionEvent;
            wellGroup.add(annotationsGroup);
        })
            .catch(() => onError(well, 'server', ''))
            .finally(() => setLoadingAnnotationWells(current => {
                const newArray = [...current];
                newArray.splice(newArray.indexOf(well), 1);
                return newArray;
            }));
    }, [annotationType, color, datum, domain, getAnnotationGroupName, getAnnotations, mainFeatureCentroidX, mainFeatureCentroidY, onAddAnnotations, onAddWellGroup, onError, scene, selectedColor, trace]);

    const removeAnnotation = useCallback((well: IWell) => {
        if (!scene) {
            return;
        }
        const wellGroup = scene.getObjectByName(GeopostWellGroup.getWellGroupName(well.Id)) as GeopostWellGroup;
        const annotationGroup = wellGroup?.getObjectByName(getAnnotationGroupName(well.Id));
        if (!annotationGroup) {
            return;
        }
        const intersectionEvent = annotationGroup.userData.intersectionEvent as (raycaster: THREE.Raycaster) => void;
        scene.intersectionEvents.splice(scene.intersectionEvents.indexOf(intersectionEvent), 1);
        wellGroup.remove(annotationGroup);
    }, [getAnnotationGroupName, scene]);

    useEffect(() => {
        wellsToAddAnnotation.forEach(well => {
            setLoadingAnnotationWells(current => [...current, well]);
            lastWellsWithVisibleAnnotation.push(well);
            addAnnotation(well);
        });
    }, [wellsToAddAnnotation]);

    useEffect(() => {
        wellsToRemoveAnnotation.forEach(well => {
            lastWellsWithVisibleAnnotation.splice(lastWellsWithVisibleAnnotation.indexOf(well), 1);
            removeAnnotation(well);
        });
    }, [wellsToRemoveAnnotation]);

    const changeAuthorVisibility = useCallback((authorName: string, wellId: number) => {
        if (!scene) {
            return;
        }
        const wellGroup = scene.getObjectByName(GeopostWellGroup.getWellGroupName(wellId)) as GeopostWellGroup;
        const annotationGroup = wellGroup?.getObjectByName(getAnnotationGroupName(wellId));
        const authorGroup = annotationGroup?.getObjectByName('author-' + authorName);
        if (!authorGroup) {
            return;
        }
        authorGroup.visible = !authorGroup.visible;
    }, [getAnnotationGroupName, scene]);

    useEffect(() => {
        if (surveyHeightInPixels !== 0) {
            lastWellsWithVisibleAnnotation.forEach(well => {
                removeAnnotation(well);
                addAnnotation(well);
            });
        }

    }, [surveyHeightInPixels]);

    return { changeAuthorVisibility, loadingAnnotationWells };
};