import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { useGetImagesFromAWSS3 } from '../api/useProcessing';
import { useGetGenericCutsForSeismic2 } from '../api/useSeismicWellController';
import { GeopostScene } from 'features/seismic-3d/threejs/scene/GeopostScene';
import { GeopostWellGroup } from 'features/seismic-3d/threejs/group/GeopostWellGroup';
import { IWellGenericCuts } from 'features/seismic/models/interfaces/IWellGenericCuts';
import { GeopostLithologyMesh } from 'features/seismic-3d/threejs/mesh/GeopostLithologyMesh';
import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { GenericCut } from 'features/seismic/models/types/GenericCut';

export const use3DGenericCuts = (
    wellsWithVisibleLithology: GenericCut[],
    datum: number,
    trace: number,
    domain: AmplitudeDomain,
    onAddWellGroup: (well: IWell) => void,
) => {
    const { mutateAsync: getGenericCutsForSeismic } = useGetGenericCutsForSeismic2();

    const setErrorMessage = use3DViewerStore(state => state.setErrorMessage);

    const [ genericCutsLoading, setGenericCutsLoading ] = useState<GenericCut[]>([]);
    const [ genericCutsWithError, setGenericCutsWithError] = useState<GenericCut[]>([]);
    //const [ imageUrls, setImageUrls ] = useState<string[]>([]);

    const {mutateAsync: getGenericCutImage} = useGetImagesFromAWSS3();

    const {current: lastWellsWithVisibleLithology } = useRef<GenericCut[]>([]);

    const scene = use3DSceneStore(state => state.scene);

    const surveyHeightInPixels = use3DViewerStore(state => state.heightPixelFactor);

    const wellsToAddLithology = useMemo(() => wellsWithVisibleLithology.filter(data => !lastWellsWithVisibleLithology.includes(data)), [wellsWithVisibleLithology]);

    const wellsToRemoveLithology = useMemo(() => lastWellsWithVisibleLithology.filter(data => !wellsWithVisibleLithology.includes(data)), [wellsWithVisibleLithology]);

    //const isLoading = useMemo(() => isGenericCutsLoading || isImageLoading, [isGenericCutsLoading, isImageLoading]);

    const addGenericCut = useCallback((wellGenericCut: GenericCut, timesTried: number = 1) => {
        const well = wellGenericCut.well;
        const genericCutText = wellGenericCut.genericCutText; //well.GenericCuts.split(';').find(genericCut => genericCut.split('|')[2] === '1');
        if (genericCutText) {
            const genericCutSplittedText = genericCutText.split('|');
            const genericCutToken = genericCutSplittedText[1];
            const genericCutType = genericCutSplittedText[2];

            if (timesTried === 1) {
                setGenericCutsLoading(current => [...current, wellGenericCut]);
            }

            getGenericCutsForSeismic({
                wellId: well.Id,
                elementToken: genericCutToken,
                datum: datum,
                trace: trace,
                domain: domain
            }).then(genericCutData => {
                if (!!genericCutData.UrlPartActionTracking && !!scene) {
                    getGenericCutImage(genericCutData.UrlPartActionTracking).then(image => {
                        let wellGroup : GeopostWellGroup;
                        do {
                            wellGroup = scene.getObjectByName(GeopostWellGroup.getWellGroupName(well.Id)) as GeopostWellGroup;
                            if (!wellGroup) {
                                onAddWellGroup(well);
                            }
                        } while (!wellGroup);
                        if (!!wellGroup) {
                            const lithologyMesh = new GeopostLithologyMesh(wellGroup.wellPoints, genericCutData.YPosition[1], genericCutData.YPosition[0], wellGroup.heightFactor);
                            lithologyMesh.addTexture(image);
                            lithologyMesh.name = 'lithology-' + genericCutText;
                            wellGroup.add(lithologyMesh);
                        }
                        setGenericCutsLoading(current => {
                            const newArray = [...current];
                            newArray.splice(newArray.indexOf(wellGenericCut), 1);
                            return newArray;
                        });
                    });
                } else {
                    if (genericCutData.errorType === 'generating_tracking_not_associeated_time_depth_active' && timesTried < 3) {
                        timesTried++;
                        setTimeout(() => addGenericCut(wellGenericCut, timesTried), 3000);
                        return;
                    }
                    setGenericCutsWithError(current => [...current, wellGenericCut]);
                    const errorMessage = genericCutData.errorType === 'permission' ?
                        `This ${getGenericCutTypeLabel(genericCutType)} belows to ${genericCutData.errorMessage} and it is not shared with you`
                        :
                        `It is not possible to show the selected ${getGenericCutTypeLabel(genericCutType)}`;
                    setErrorMessage(errorMessage);
                }
            })
                .catch(() => setErrorMessage(`It is not possible to show the selected ${getGenericCutTypeLabel(genericCutType)}`))
                .finally(() => setGenericCutsLoading(current => {
                    const newArray = [...current];
                    newArray.splice(newArray.indexOf(wellGenericCut), 1);
                    return newArray;
                }));
        }
    }, [datum, domain, getGenericCutsForSeismic, getGenericCutImage, onAddWellGroup, scene, trace]);

    const removeGenericCut = useCallback((wellGenericCut: GenericCut) => {
        const wellGroup = scene?.getObjectByName(GeopostWellGroup.getWellGroupName(wellGenericCut.well.Id)) as GeopostWellGroup;
        if (!!wellGroup) {
            const lithologyMesh = wellGroup.getObjectByName('lithology-' + wellGenericCut.genericCutText);
            if (!!lithologyMesh) {
                wellGroup.remove(lithologyMesh);
            }
        }
    }, [scene]);

    const getGenericCutTypeLabel = (genericCutTypeId: string) => {
        let type = genericCutTypeId;

        if (genericCutTypeId === '1') {
            type = 'Lithology';
        }
        else if (genericCutTypeId === '2') {
            type = 'Log Curve';
        }
        else if (genericCutTypeId === '3') {
            type = 'Other';
        }

        return type;
    };

    useEffect(() => {
        wellsToAddLithology.forEach(wellGenericCut => {
            const well = wellGenericCut.well;
            lastWellsWithVisibleLithology.push(wellGenericCut);
            addGenericCut(wellGenericCut);
        });
    }, [wellsToAddLithology]);

    useEffect(() => {
        wellsToRemoveLithology.forEach(well => {
            lastWellsWithVisibleLithology.splice(lastWellsWithVisibleLithology.indexOf(well), 1);
            removeGenericCut(well);
        });
    }, [wellsToRemoveLithology]);

    useEffect(() => {
        if (surveyHeightInPixels !== 0) {
            lastWellsWithVisibleLithology.forEach(well => {
                removeGenericCut(well);
                addGenericCut(well);
            });
        }
    }, [surveyHeightInPixels]);

    return { genericCutsLoading, genericCutsWithError };
};