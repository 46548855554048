import { Box, Stack, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useEffect } from 'react';

import { GeopostLogo } from 'components/geopostLogo/GeopostLogo';

export const LogoutPanel = () => {

    useEffect(() => {
        setTimeout(() => {
            localStorage.removeItem('okta-token-storage');
        }, 2500);

    }, []);

    return (
        <Box sx={{backgroundColor:'black'}} height={'100%'} width={'100%'} display='flex' alignItems='center' justifyContent='center'>
            <Stack spacing={2} alignItems='center'>
                <LogoutIcon sx={{height: '100px', width: '100px'}} color='secondary'/>
                <Stack spacing={1} direction='row' maxWidth='600px'>
                    <Typography variant='h4' color='white'>You are now logged out, access the application again in order to log in.</Typography>
                </Stack>
            </Stack>
        </Box>
    );
};