import Feature from 'ol/Feature';
import { LineString, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Text, Stroke, Style, Fill, Circle as CircleStyle } from 'ol/style';

import { colorWithAlpha } from './colorWithAlphaUtils';

// Espaço utilizado para afastar o texto da linha
const xSpace = 75;

const color: {[key: string]: string} = {
    crossingPoint: '#0511F2',
    formationTop: '#338A83',
    hydrocarbon: '#9C27B0',
    formationTest: '#FF9800',
    timeDepth: '#4497C0',
    relatedData: '#FF0000',
    white: '#FFFFFF',
    black: '#000000'
};

const colorText: {[key: string]: string} = {
    crossingPoint: '#FFFFFF',
    formationTop: '#338A83',
    hydrocarbon: '#9C27B0',
    formationTest: '#FF9800',
    timeDepth: '#4497C0',
    relatedData: '#FFFFFF',
    white: '#FFFFFF'
};

const fill: {[key: string]: string} = {
    formationTop: colorWithAlpha('#FFFFFF', 0.25),
    timeDepth: colorWithAlpha('#FFFFFF', 0.25),
    relatedData: colorWithAlpha('#FF0000', 1)
};

const zIndex: {[key: string]: number} = {
    crossingPoint: 99999990,
    timeDepth: 99999991,
    formationTop: 99999992,
    hydrocarbon: 99999993,
    formationTest: 99999994,
    relatedData: 99999995,
};

const createText = (label: string, textColor: string, textAlign: CanvasTextAlign | undefined = 'left', offsetX: number = 0, offsetY: number = 0) => {
    let textPointStyle = new Text({
        text: label,
        font: 'bold 15px Courier New',
        offsetY: offsetY,
        offsetX: offsetX,
        textAlign: textAlign,
        fill: new Fill({
            color: textColor,
        }),
        stroke: new Stroke({
            color: '#000000',
            width: 3,
        })
    });;

    return textPointStyle;
};

export const createTextFeature = (xPoint: number, yPoint: number, label: string, textColor: string, textAlign: CanvasTextAlign | undefined = 'left', offsetX: number = 0, offsetY: number = 0) => {
    const textPointFeature = new Feature({
        geometry: new Point([xPoint + xSpace, yPoint])
    });

    let textPointStyle = new Style({
        text: createText(label, textColor, textAlign, offsetX, offsetY)
    });

    textPointFeature.setStyle(textPointStyle);
    textPointFeature.set('featureType', 'text');
    textPointFeature.set('featureToken', 'text');

    return textPointFeature;
};

export const createPointFeature = (xPoint: number, yPoint: number, label: string, type: string, token: string, mdValue: number, rotativeDeskValue: number, id: string, dateValue?: string) => {
    let circleGeometry = new Point([xPoint, yPoint]);

    let circleFeature = new Feature({
        geometry: circleGeometry
    });

    let circleStyle = new Style({
        image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
                color: color[type],
                width: 2
            }),
            fill: new Fill({
                color: fill[type]
            }),
        }),
        text: createText(label, colorText[type], 'left', 25),
    });

    circleFeature.setStyle(circleStyle);

    circleFeature.set('id', id);
    circleFeature.set('featureType', type);
    circleFeature.set('featureLabel', label);
    circleFeature.set('featureToken', token);
    circleFeature.set('featureHoverInfo', {md: [mdValue], rotativeDesk: rotativeDeskValue, timeDepth: label, date: dateValue});
    circleFeature.set('featureGeometry', circleGeometry);

    return circleFeature;
};

export const createLineFeature = (xPoint: number, yPoint: number[], label: string, type: string, token: string, mdValue: number[], tvdValue: number[], rotativeDeskValue: number, dateValue?: string) => {
    let lineGeometry = new LineString([[xPoint, yPoint[0]], [xPoint, yPoint[1]]]);

    let lineFeature = new Feature({
        geometry: lineGeometry
    });

    let lineStyle = new Style({
        stroke: new Stroke({
            color: color[type],
            width: 2,
        }),
        text: createText(label, colorText[type], 'left', 25)
    });

    lineFeature.setStyle(lineStyle);

    lineFeature.set('featureType', type);
    lineFeature.set('featureLabel', label);
    lineFeature.set('featureToken', token);
    lineFeature.set('featureHoverInfo', {md: mdValue, rotativeDesk: rotativeDeskValue, tvd: tvdValue, date: dateValue});
    lineFeature.set('featureGeometry', lineGeometry);

    return lineFeature;
};

// export const createPointWithoutText = (xPoint: number, yPoint: number, label: string, layer: VectorLayer<VectorSource>, type: string, image: string, archiveName: string, wellName: string) => {
//     let circleGeometry = new Circle([xPoint, yPoint]);

//     let circleFeature = new Feature({
//         geometry: circleGeometry
//     });

//     let circleStyle = new Style({
//         stroke: new Stroke({
//             color: color[type],
//             width: 5,
//         }),
//         fill: new Fill({
//             color: color[type],
//         })
//     });

//     circleFeature.setStyle(circleStyle);

//     circleFeature.set('featureType', type);
//     circleFeature.set('featureLabel', label);
//     circleFeature.set('featureToken', type);
//     circleFeature.set('featureHoverInfo', {md: yPoint, image: image, imageArchiveName: archiveName});
//     circleFeature.set('featureGeometry', circleGeometry);
//     circleFeature.set('featureGeometryType', 'point');
//     circleFeature.set('featureWellName', wellName);

//     layer.getSource()?.addFeature(circleFeature);

//     layer.setZIndex(zIndex[type]);

//     return layer;
// };

// export const createLineWithoutText = (xPoint: number, yPoint: number[], label: string, layer: VectorLayer<VectorSource>, type: string, image: string, archiveName: string, wellName: string) => {
//     let lineGeometry = new LineString([[xPoint, yPoint[0]], [xPoint, yPoint[1]]]);

//     let lineFeature = new Feature({
//         geometry: lineGeometry
//     });

//     let lineStyle = new Style({
//         stroke: new Stroke({
//             color: color[type],
//             width: 5,
//         })
//     });

//     lineFeature.setStyle(lineStyle);

//     lineFeature.set('featureType', type);
//     lineFeature.set('featureLabel', label);
//     lineFeature.set('featureToken', type);
//     lineFeature.set('featureHoverInfo', {md: yPoint, image: image, imageArchiveName: archiveName});
//     lineFeature.set('featureGeometry', lineGeometry);
//     lineFeature.set('featureGeometryType', 'line');
//     lineFeature.set('featureWellName', wellName);

//     layer.getSource()?.addFeature(lineFeature);

//     layer.setZIndex(zIndex[type]);

//     return layer;
// };

export const createCircleFeatureRelatedData = (xPoint: number, yPoint: number, label: string, type: string, image: string, archiveName: string, wellName: string) => {
    let circleGeometry = new Point([xPoint, yPoint]);

    let circleFeature = new Feature({
        geometry: circleGeometry
    });

    let circleStyle = new Style({
        image: new CircleStyle({
            radius: 3,
            stroke: new Stroke({
                color: color[type],
                width: 3,
            }),
            fill: new Fill({
                color: color[type],
            })
        }),
    });

    circleFeature.setStyle(circleStyle);

    circleFeature.set('featureType', type);
    circleFeature.set('featureLabel', label);
    circleFeature.set('featureToken', type);
    circleFeature.set('featureHoverInfo', {md: yPoint, image: image, imageArchiveName: archiveName});
    circleFeature.set('featureGeometry', circleGeometry);
    circleFeature.set('featureGeometryType', 'point');
    circleFeature.set('featureWellName', wellName);

    return circleFeature;
};

export const createLineFeatureRelatedData = (xPoint: number, yPoint: number[], label: string, type: string, image: string, archiveName: string, wellName: string) => {
    let lineGeometry = new LineString([[xPoint, yPoint[0]], [xPoint, yPoint[1]]]);

    let lineFeature = new Feature({
        geometry: lineGeometry
    });

    let lineStyle = new Style({
        stroke: new Stroke({
            color: color[type],
            width: 5,
        })
    });

    lineFeature.setStyle(lineStyle);

    lineFeature.set('featureType', type);
    lineFeature.set('featureLabel', label);
    lineFeature.set('featureToken', type);
    lineFeature.set('featureHoverInfo', {md: yPoint, image: image, imageArchiveName: archiveName});
    lineFeature.set('featureGeometry', lineGeometry);
    lineFeature.set('featureGeometryType', 'line');
    lineFeature.set('featureWellName', wellName);

    return lineFeature;
};

export const createOnlyText = (xPoint: number, yPoint: number, label: string, layer: VectorLayer<VectorSource>, type: string) => {
    layer.getSource()?.addFeature(createTextFeature(xPoint, yPoint, label, color['white']));

    return layer;
};

export const createCrossingPoint = (xPoint: number, yPoint: number[], label: string[], mdValue: number, rotativeDeskValue: number, layer: VectorLayer<VectorSource>, token: string) => {
    let type = 'crossingPoint';
    let lineGeometry = new LineString([[xPoint, yPoint[0]], [xPoint, yPoint[1]]]);

    let lineFeature = new Feature({
        geometry: lineGeometry
    });

    let lineStyle = new Style({
        stroke: new Stroke({
            color: color[type],
            width: 2,
        })
    });

    lineFeature.setStyle(lineStyle);

    let startpoint = lineGeometry.getCoordinateAt(0);
    let midpoint = lineGeometry.getCoordinateAt(0.5);
    let endpoint = lineGeometry.getCoordinateAt(1);

    lineFeature.set('featureType', 'crossingPoint');
    lineFeature.set('featureLabel', label[0]);
    lineFeature.set('featureToken', token);
    lineFeature.set('featureHoverInfo', {md: [mdValue], rotativeDesk: rotativeDeskValue});
    lineFeature.set('featureGeometry', lineGeometry);

    layer.getSource()?.addFeature(lineFeature);

    layer.getSource()?.addFeature(createTextFeature(startpoint[0] - xSpace, startpoint[1], label[0], color['white'], 'center', 0, -15));
    layer.getSource()?.addFeature(createTextFeature(midpoint[0] - xSpace, midpoint[1], label[1], color['white'], 'left', 0, 0));
    layer.getSource()?.addFeature(createTextFeature(endpoint[0] - xSpace, endpoint[1], (Number((label[2]).replace(',', '.')) - rotativeDeskValue).toString(), color['white'], 'center', 0, 15));

    layer.setZIndex(zIndex[type]);

    return layer;
};

export function createStyleOveredWells(label: string, type: string, relatedType: string, overed: boolean = false) {
    let style = new Style({});
    switch (`${type}${relatedType}`) {
    case 'crossingPoint':
        style = new Style({
            stroke: new Stroke({
                color: overed ? color['white'] : color[type],
                width: 2,
            })
        });
        break;
    case 'timeDepth':
    case 'formationTop':
        style = new Style({
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: overed ? color['white'] : color[type],
                    width: 2
                }),
                fill: new Fill({
                    color: fill[type]
                }),
            }),
            text: createText(label, overed ? colorText['white'] : colorText[type], 'left', 25)
        });
        break;
    case 'hydrocarbon':
    case 'formationTest':
        style = new Style({
            stroke: new Stroke({
                color: overed ? color['white'] : color[type],
                width: 2,
            }),
            text: createText(label, overed ? colorText['white'] : colorText[type], 'left', 25)
        });
        break;
    case `relatedData-point`:
        style = new Style({
            stroke: new Stroke({
                color: overed ? color['black'] : color[type],
                width: 5,
            }),
            fill: new Fill({
                color: overed ? color['black'] : color[type],
            })
        });
        break;
    case 'relatedData-line':
        style = new Style({
            stroke: new Stroke({
                color: overed ? color['black'] : color[type],
                width: 5,
            })
        });
        break;
    }

    return style;
}
export function getXValue(x: number, scaleX: number, min: number, start: number, increment: number) {
    return start + Math.round((min - start + x) * scaleX * increment);
    // return (min * scaleX) + Math.round(x * increment* scaleX);
    // return min + Math.round(x * increment* scaleX);
}

export function getYValue(y: number, scaleY: number, sampleInterval: number) {
    return Math.round(y / sampleInterval * scaleY);
}