
import { useMutation, useQuery } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { IWellGenericCuts } from 'features/seismic/models/interfaces/IWellGenericCuts';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { IWellCrossingPointViewModel } from 'features/seismic/models/interfaces/IWellCrossingPointViewModel';
import { getUrlParams } from 'features/seismic-3d/utils/Seismic3DUrlUtils';
import { IGetWellsByPolygonRequest } from 'features/seismic/models/interfaces/IGetWellsByPolygonRequest';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { axios } from 'configurations/axios';

export function useGetAllByDistance(lineString: string, distance: number, domain: AmplitudeDomain, numberOfTraces: number, samplesPerTrace: number){
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));
    return useQuery('GetAllByDistance' + lineString + distance + domain + numberOfTraces + samplesPerTrace, async () => {
        const response = await axios.request<IResponseAPI<IWell[]>>({
            method: 'post',
            url: tenantConfig?.endpoints?.wells + '/Wells/SeismicWells/GetAllByDistance',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                lineString: lineString,
                distance: distance,
                domain: domain,
                numberOfTraces: numberOfTraces,
                datumSeismic: 0,
                srid: 3857,
                samplesPerTrace: samplesPerTrace
            }
        });

        return response.data.Result;
    }, {
        enabled: (lineString !== '')
    });
}

export function useGetCrossingPointForSeismic(checkbox: boolean, wellId: number, trace: number, domain: AmplitudeDomain, datum: number, samplesPerTrace: number){
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));
    return useQuery('GetCrossingPointForSeismic' + wellId + trace + domain + datum + samplesPerTrace, async () => {
        const response = await axios.request<IResponseAPI<IWellCrossingPointViewModel>>({
            method: 'post',
            url: tenantConfig?.endpoints?.wells + '/Wells/SeismicWells/NewGetCrossingPointForSeismic',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                wellId: wellId,
                trace: trace,
                domain: domain,
                datum: datum,
                SamplesPerTrace: samplesPerTrace
            }
        });

        return response.data.Result;
    }, {
        enabled: checkbox
    });
}

export function useGetGenericCutsForSeismic(checkbox: boolean, wellId: number, elementToken: string, trace: number, domain: AmplitudeDomain, datum: number, surveyType: SurveyType){
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));
    return useQuery('getGenericCutsForSeismic' + wellId + elementToken + trace + domain + datum, async () => {
        const response = await axios.request<IResponseAPI<IWellGenericCuts>>({
            method: 'post',
            url: tenantConfig?.endpoints?.wells + '/Wells/WellGenericCut/GetGenericCutsForSeismic',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                wellId: wellId,
                elementToken: elementToken,
                trace: trace,
                domain: domain,
                datum: datum
            }
        });

        return response.data.Result;
    }, {
        enabled: checkbox && surveyType === SurveyType.Seismic2D
    });
}

export function useGetGenericCutsForSeismic2() {
    const { url, jwtToken } = useSessionStore(state => ({
        url: state.tenantConfiguration?.endpoints.wells,
        jwtToken: state.jwtToken
    }));

    return useMutation(
        async ({wellId, elementToken, trace, domain, datum} : useGetGenericCutsForSeismic2Params) => {
            const response = await axios.post<IResponseAPI<IWellGenericCuts>>(
                url + '/Wells/WellGenericCut/GetGenericCutsForSeismic',
                {
                    wellId: wellId,
                    elementToken: elementToken,
                    trace: trace,
                    domain: domain,
                    datum: datum
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data.Result;
        }
    );
}

export type useGetGenericCutsForSeismic2Params = {
    wellId: number,
    elementToken: string,
    trace: number,
    domain: AmplitudeDomain,
    datum: number
};

export function useGetAllByPolygon(data: IGetWellsByPolygonRequest | undefined) {
    const endpoint = useSessionStore(state => state.tenantConfiguration?.endpoints.wells);
    return useQuery<IWell[]>(
        [data, 'wells.useGetAllByPolygon'],
        async () => {
            const response = await axios.post<IResponseAPI<IWell[]>>(
                endpoint + '/Wells/SeismicWells/GetAllByPolygon',
                {
                    ...data
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + getUrlParams().jwtToken,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data.Result;
        },
        {
            enabled: !!data
        }
    );
};