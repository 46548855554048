import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';

export const PerformanceGridToolbar = ({ fileName } : PerformanceGridToolbarProps) => (
    <GridToolbarContainer>
        <GridToolbarColumnsButton/>
        <GridToolbarFilterButton/>
        <GridToolbarDensitySelector/>
        <GridToolbarExport csvOptions={{delimiter: ';', fileName: fileName}} />
    </GridToolbarContainer>
);

export type PerformanceGridToolbarProps = {
    fileName: string
};