import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FlashAutoIcon from '@mui/icons-material/FlashAuto';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SeismicChangeScale } from './SeismicChangeScale';
import { useSeismicStore } from '../stores/useSeismicStore';

export function SeismicAntialiasNavbar () {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [ isPanelVisible, setIsPanelVisible ] = useState(false);
    const handleClose = () => setIsPanelVisible(false);
    const [isApplied, setIsApplied] = useSeismicStore(state => [state.isAntialiasApplied, state.setIsAntialiasApplied]);

    const { t } = useTranslation();
    const classNameApplied = (isApplied) ? 'active': '';

    return  <Fragment>
        <IconButton style={{marginRight: '0px'}} id='apply_antialias' title={'Click to apply the antialias'} size='medium' className={classNameApplied} onClick={() => setIsApplied(!isApplied)} >
            <FlashAutoIcon style={{color: 'white'}}></FlashAutoIcon>
        </IconButton>
    </Fragment>;
}