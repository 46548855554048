import { Fragment, useEffect } from 'react';
import { css } from '@emotion/react';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { ICrossSection } from '../models/interfaces/ICrossSection';
import { WellSectionLane } from './WellSectionLane';
import { useCrossSectionStore } from '../stores/useCrossSectionStore';
import { CrossSectionLaneConnection } from './CrossSectionLaneConnection';
import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { CrossSectionChangeVisibleAnnotations } from './CrossSectionChangeVisibleAnnotations';
import { useCrossSectionAnnotationsStore } from '../stores/useCrossSectionAnnotationsStore';
import { useMainViewCreation } from '../hooks/useMainViewCreation';
import { calculateDisplacementByAnnotation } from '../utils/wellCrossSectionAnnotationsUtils';
import { useAdjustFlatAnnotation } from '../hooks/useAdjustFlatAnnotation';
import { WellDepthTrack } from './WellDepthTrack';
import { LaneType } from '../models/enums/LaneType';

const style = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    flexGrow: 1,
    paddingLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    overflow: 'auto',
    position: 'relative',

    '& .ps__rail-x': {
        zIndex: 9999999999
    },
    '& > *': {
        flexShrink: 0
    }
});

export function CrossSectionPanel({ crossSection }: IProps){
    const { setPerfectScrollbar } = useCrossSectionStore(state => ({
        setPerfectScrollbar: state.setPerfectScrollbar
    }));

    const {popupOpened: popupAnnotationOpened, setPopupOpened: setPopupAnnotationOpened,flatAnnotation, annotations } = useCrossSectionAnnotationsStore(state => ({
        annotations: state.annotations,
        flatAnnotation: state.flatAnnotation,
        popupOpened: state.popupOpened,
        setPopupOpened: state.setPopupOpened
    }));

    const { t } = useTranslation('crossSection');

    useEffect(() => {
        const ps = new PerfectScrollbar('#cross-section-panel');

        setPerfectScrollbar(ps);
    }, []);
    // useEffect(()=>{

    // },[crossSection]);
    useAdjustFlatAnnotation(crossSection);
    useMainViewCreation(crossSection);
    const addItem = ()=>{
        console.log(crossSection);
        if (crossSection){
            let cs = crossSection;
            cs.Itens?.push({WellName:`Track ${cs.Itens.length+1}`,WellStart: crossSection.WellStart,
                WellEnd:crossSection.WellEnd});
            cs.LaneType = LaneType.LogCurve;
        }
    };
    return (
        <Fragment>
            <div css={style} id='cross-section-panel'>
                {crossSection?.LaneType === LaneType.LogCurve &&
                 <Fragment>
                     <WellDepthTrack crossSection={crossSection}></WellDepthTrack>
                 </Fragment>
                }

                {crossSection?.Itens?.map((item) => (
                    <Fragment key= {'panel-lane-' + item.Token}>
                        <WellSectionLane crossSectionItem={item} crossSection={crossSection}></WellSectionLane>
                        {item.nextWellConnection ? <CrossSectionLaneConnection key={'panel-connection-' + item.Token} connection={item.nextWellConnection}></CrossSectionLaneConnection> : null}
                    </Fragment>
                )
                )}
            </div>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={popupAnnotationOpened}
                onChangeOpen={(value) => setPopupAnnotationOpened(value)}
                width={600}
                height={300}
                title={t('formationTop.formations')}
                rebuildOnOpen={false}
            >
                <CrossSectionChangeVisibleAnnotations />
            </GeopostPopup>
        </Fragment>
    );
}

interface IProps {
    crossSection: ICrossSection | null;
}