import { Box, Button, css, Tooltip, Typography, Zoom } from '@mui/material';
import { MainTableCss } from 'fileIndexing/envVar/envVar';
import { Fragment, useEffect, useState } from 'react';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useLocation } from 'react-router-dom';
import alertify from 'alertifyjs';
import { useGetAllEndpoints } from 'fileIndexing/taxonomies/useTaxonomy';
import { IEndpoint } from 'fileIndexing/taxonomies/interfaces/ITaxonomy';

import { IFile, ISendFilter } from '../interfaces/IFiles';
import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { useDeleteFileByToken, useGetCountTable, useGetFileByToken, useGetFileList } from '../useFiles';
import { ScanDataSource } from './ScanDataSource';
import { IndexFiltered } from './IndexFiltered';
import { FilesEdit } from './FilesEdit';
import { FilesFilterHeader } from './FilesFilterHeader';
import { useSessionStore } from 'session/useSessionStore';

const style = css({
    '& .table-header-title': {
        marginBottom: '16px',
    },
    '& .table-header': {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'flex-start',
    },
    '& .search': {
        display: 'flex',
        padding: '0',
        width: '100%',
        height: '40px',
        marginTop: '16px',
        justifyContent: 'space-between',
    },
    '& .search-select': {
        width: '250px',
    },
    '& .search-field': {
        width: '210px',
        // width: '550px',
    },
});

export function FilesTable() {
    const { tenantToken } = useSessionStore(state => ({
        tenantToken: state.tenantConfiguration?.tenantToken,
    }));

    const location = useLocation();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<IFile | null>(null);
    const [cloudBucketModal, setCloudBucketModal] = useState<boolean>(false);
    const [indexFilteredModal, setIndexFilteredModal] = useState<boolean>(false);

    const [editToken, setEditToken] = useState<string>('');
    const [deleteToken, setDeleteToken] = useState<string>('');

    const [sendFilters, setSendFilters] = useState<ISendFilter>({
        Filter: '',
        LocationType: -1,
        DataGroupFilter: '',
        DataSubGroupFilter: '',
        EntityTypeFilter: {
            id: '',
            label: '',
        },
        DataSourceFilter: '',
        TransmittalFilter: '',
        TagFilter: '',
        MediaFilter: '',
        WithoutSubgroupFilter: false,
        WithoutEntitiesFilter: false,
    });

    const [pagination, setPagination] = useState<{ pageSize: number, page: number; }>({
        pageSize: 50,
        page: 1,
    });

    const [sort, setSort] = useState<GridSortModel>([
        {
            field: 'Name',
            sort: 'asc',
        },
    ]);

    const { data: resultsCount } = useGetCountTable(sendFilters);
    const { data: fileData, isLoading: fileDataLoading, refetch, isRefetching } = useGetFileList(sendFilters, pagination.pageSize, pagination.page, sort);
    const { data: fileGetByToken } = useGetFileByToken(editToken);
    const { data: deleteFile } = useDeleteFileByToken(deleteToken);
    const { data: dataAllEndpoints } = useGetAllEndpoints();

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleOpenCloudBucketModal = () => setCloudBucketModal(true);
    const handleCloseCloudBucketModal = () => setCloudBucketModal(false);

    const handleOpenIndexFilteredModal = () => setIndexFilteredModal(true);
    const handleCloseIndexFilteredModal = () => setIndexFilteredModal(false);

    const handleTable = (dataFilter: ISendFilter) => {
        const newFilters: ISendFilter = {
            Filter: dataFilter.Filter,
            LocationType: dataFilter.LocationType,
            DataGroupFilter: dataFilter.DataGroupFilter,
            DataSubGroupFilter: dataFilter.DataSubGroupFilter,
            EntityTypeFilter: {
                id: dataFilter.EntityTypeFilter?.id,
                label: dataFilter.EntityTypeFilter?.label,
            },
            DataSourceFilter: dataFilter.DataSourceFilter,
            TransmittalFilter: dataFilter.TransmittalFilter,
            TagFilter: dataFilter.TagFilter,
            MediaFilter: dataFilter.MediaFilter,
            WithoutSubgroupFilter: dataFilter.WithoutSubgroupFilter,
            WithoutEntitiesFilter: dataFilter.WithoutEntitiesFilter,
        };

        if (JSON.stringify(sendFilters) === JSON.stringify(dataFilter)) {
            refetch();
        } else {
            setSendFilters((prevState) => ({
                ...prevState,
                ...newFilters,
            }));
        }
    };

    const handleOpenFile = (endpoint: IEndpoint, file: IFile) => {
        if (tenantToken) {
            let url = endpoint.Url;
            if (url.includes('{file_token}')) {
                url = url.replace('{file_token}', file.Token);
            }

            if (url.includes('{file_original_path}')) {
                url = url.replace('{file_original_path}', file.OriginalPath);
            }

            if (url.includes('{file_internal_path}')) {
                url = url.replace('{file_internal_path}', file.InternalPath);
            }

            if (url.includes('{tenant_token}')) {
                url = url.replace('{tenant_token}', tenantToken);
            }
            else if (!url.includes('tenant_token')) {
                url += `&tenantToken=${tenantToken}`;
            }

            window.open(url, '_blank');
        }
    };

    const handleOpenEdit = (rowData: IFile) => {
        setEditToken(rowData.Token);
    };

    const handleDelete = (name: string, token: string) => {
        alertify.confirm(`Do you really wanna delete ${name}?`, async () => {
            setDeleteToken(token);
        });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const groupToken = searchParams.get('groupToken');
        const subGroupToken = searchParams.get('subGroupToken');
        const entityTerm = searchParams.get('entitiesTerm');
        const entityToken = searchParams.get('entitiesToken');
        const dataSourceToken = searchParams.get('dataSourceToken');
        const tagToken = searchParams.get('tagToken');

        if (groupToken || subGroupToken || entityTerm || entityToken || dataSourceToken || tagToken) {
            const newFilters: ISendFilter = {
                Filter: sendFilters.Filter,
                LocationType: sendFilters.LocationType,
                DataGroupFilter: groupToken ?? '',
                DataSubGroupFilter: subGroupToken ?? '',
                EntityTypeFilter: {
                    id: entityToken ?? '',
                    label: entityTerm ?? '',
                },
                DataSourceFilter: dataSourceToken ?? '',
                TransmittalFilter: sendFilters.TransmittalFilter,
                TagFilter: tagToken ?? '',
                MediaFilter: sendFilters.MediaFilter,
                WithoutSubgroupFilter: sendFilters.WithoutSubgroupFilter,
                WithoutEntitiesFilter: sendFilters.WithoutEntitiesFilter,
            };

            setSendFilters((prevState) => ({
                ...prevState,
                ...newFilters,
            }));
        }
    }, [location.search]);

    // reseta as informações preenchidas no modal
    useEffect(() => {
        if (!openModal) {
            setModalData(null);
        }
    }, [openModal]);

    // seta as informações para edit
    useEffect(() => {
        if (fileGetByToken) {
            let allData = fileGetByToken;
            allData.Size = parseFloat((allData.Size / (1024 ** 3)).toFixed(5));

            setModalData(allData);
            handleOpenModal();
            setEditToken('');
        }
    }, [fileGetByToken]);

    useEffect(() => {
        if (deleteFile === 'Success') {
            refetch();
        }
    }, [deleteFile]);

    const columns: GridColDef[] = [
        {
            field: 'Endpoints',
            headerName: 'Endpoints',
            headerAlign: 'center',
            sortable: false,
            width: 195,
            renderCell: (params) => (
                <Box className='options-buttons'>
                    {dataAllEndpoints?.map((item) => (
                        (item.SubGroupToken === params.row.SubgroupToken && item.RestrictedExtensions.includes(params.row.Extension)) ? (
                            <Tooltip title='Open File' placement='top' arrow TransitionComponent={Zoom}>
                                <Button variant='contained' color='primary' onClick={() => handleOpenFile(item, params.row)}>
                                    <InsertDriveFileIcon />
                                </Button>
                            </Tooltip>
                        ) : null
                    ))}
                </Box>
            ),
        },
        {
            field: 'Name',
            headerName: 'Name',
            headerAlign: 'left',
            width: 400,
        },
        {
            field: 'InternalPath',
            headerName: 'Internal Path',
            headerAlign: 'left',
            sortable: false,
            flex: 1,
            minWidth: 800,
        },
        {
            field: 'Size',
            headerName: 'Size (GB)',
            headerAlign: 'left',
            width: 100,
            valueFormatter: (params) => {
                const sizeInBytes = params.value;
                const sizeInGB = sizeInBytes / (1024 ** 3);
                return sizeInGB.toFixed(2);
            },
        },
        {
            field: 'SubGroupName',
            headerName: 'Subgroup',
            headerAlign: 'left',
            width: 100,
        },
        {
            field: 'DataEntityName',
            headerName: 'Entity',
            headerAlign: 'left',
            width: 100,
        },
        {
            field: 'Tags',
            headerName: 'Tags',
            headerAlign: 'left',
            width: 100,
        },
        {
            field: 'CreationTime',
            headerName: 'Creation Date',
            headerAlign: 'left',
            width: 100,
        },
        {
            field: 'ModificationTime',
            headerName: 'Modification Date',
            headerAlign: 'left',
            width: 100,
        },
        {
            field: 'Options',
            headerName: 'Options',
            headerAlign: 'center',
            sortable: false,
            width: 180,
            renderCell: (params) => (
                <Box className='options-buttons'>
                    <Tooltip title='Edit File' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' className='edit-icon' onClick={() => handleOpenEdit(params.row)}>
                            <EditNoteIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Delete File' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='error' className='delete-icon' onClick={() => handleDelete(params.row.Name, params.row.Token)}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </Box>
            ),
        },
        /* {
            field: 'TransmittalName',
            headerName: 'Transmittal',
            headerAlign: 'left',
            width: 100,
        },
        {
            field: 'MediaName',
            headerName: 'Media',
            headerAlign: 'left',
            width: 100,
        }, */
    ];

    const getRowId = (row: IFile) => row.Token;

    return (
        <Fragment>
            <Box css={[MainTableCss, style]}>
                <Box className='table-header'>
                    <Typography className='table-header-title'>Files</Typography>
                    <FilesFilterHeader formData={sendFilters} handleOpenCloudBucketModal={handleOpenCloudBucketModal} handleOpenIndexFilteredModal={handleOpenIndexFilteredModal} handleTable={handleTable} />
                </Box>
                <GeopostTable<IFile> filteredData={fileData} loading={fileDataLoading || isRefetching} columns={columns} getRowId={getRowId} resultsCount={resultsCount} pagination={pagination} setPagination={setPagination} setSort={setSort} height={415} />
            </Box>

            <GeopostPopup
                open={cloudBucketModal}
                onChangeOpen={handleCloseCloudBucketModal}
                resizable={false}
                width={700}
                height={480}
                title={'Scan Data Source'}
                padding={'32px'}
                temporaryIgnoreFlexInContainer
            >
                <ScanDataSource />
            </GeopostPopup>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={indexFilteredModal}
                onChangeOpen={handleCloseIndexFilteredModal}
                resizable={false}
                width={960}
                height={490}
                title={'Unindexed files'}
                padding={'16px'}
            >
                <IndexFiltered sendFilters={sendFilters} />
            </GeopostPopup>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openModal}
                onChangeOpen={handleCloseModal}
                resizable={false}
                width={860}
                height={375}
                title={'Edit File'}
                padding={'16px'}
            >
                <FilesEdit refetchTableData={refetch} setOpenModal={setOpenModal} formData={modalData} />
            </GeopostPopup>
        </Fragment>
    );
}