import { Slider, Stack, TextField, Typography } from '@mui/material';

import { use3DSceneStore } from '../stores/use3DSceneStore';
import { use3DViewerStore } from '../stores/use3DViewerStore';

export const HeightScaleSlider = () => {

    const handleSliderChange = (event: Event, value: number | number[]) => {
        setHeightScale(value as number);
    };

    const { heightScale, setHeightScale } = use3DSceneStore(state => ({
        heightScale: state.heightScale,
        setHeightScale: state.setHeightScale
    }));

    const formatLabel = (value: number) => {
        return (value * 100).toFixed().toString() + '%';
    };

    return (
        <Stack>
            <Stack justifyContent='end'>
                <Typography paragraph sx={{marginBottom: 0, fontSize: 14}}>Height Scale: {formatLabel(heightScale)}</Typography>
            </Stack>
            <Stack alignItems='start'>
                <Slider
                    aria-label='Light intensity'
                    min={0.1}
                    max={5}
                    step={0.01}
                    onChange={handleSliderChange}
                    valueLabelDisplay='off'
                    color='secondary'
                    size='small'
                    value={heightScale}
                    valueLabelFormat={formatLabel}
                />
            </Stack>
        </Stack>
    );
};