import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Circle, Fill, Style } from 'ol/style';
import { TileCoord } from 'ol/tilecoord';
import { Extent } from 'ol/extent';
import 'ol-ext/render/AnimExtent';
import Zoom from 'ol-ext/featureanimation/Zoom';
import { animationControler } from 'ol-ext/featureanimation/FeatureAnimation';

import { GeopostTileLoadingLayerParams } from './GeopostTileLoadingLayerParams';
import { getXByTrace } from 'features/seismic/utils/calculateScreenValuesUtils';

export class GeopostTileLoadingLayer extends VectorLayer<VectorSource> {
    private _source?: VectorSource;
    private _features: {[key: string]: {
        feature: Feature,
        animation: animationControler
    }} = {};

    Self?: GeopostTileLoadingLayer;

    constructor() {
        super({
            visible: true,
            zIndex: 9999,
            style: function (feature) {
                if (feature.get('visible') !== true){
                    return new Style();
                }
                return new Style({
                    image: new Circle({
                        radius: feature.get('radius'),
                        fill: new Fill({
                            color: feature.get('color'),
                        })
                    })
                });
            }
        });

        this.buildSource();
    }

    rebuildSource() {
        this.buildSource();
    }

    private buildSource() {
        const source = new VectorSource();

        this.setSource(source);
        this._source = source;
    }

    clearAllLoading(){
        for (let feature in this._features){
            let item = this._features[feature];

            this.removeFeature(item);
        }
    }

    showLoading(key: string, startTrace: number, endTrace: number, lineIncrement: number, surveyLineStart: number, scale: number, startAmplitude: number, endAmplitude: number, zoom: number, extent?: Extent, color: string = '#00000055', radius: number = 15, ignoreZoom: boolean = false){
        if (!extent){
            return;
        }

        this.hideLoadingByZoom(zoom);
        //const lineStart = this.extractUrlValue(key, 'lineStart');
        //const lineEnd = this.extractUrlValue(key, 'lineEnd');
        //const lineStartSample = this.extractUrlValue(key, 'lineStartSample');
        //const lineEndSample = this.extractUrlValue(key, 'lineEndSample');

        this.hideLoading(key);

        const extentMinY = extent[1];
        const extentMaxY = extent[3];
        startTrace = getXByTrace(startTrace, scale, surveyLineStart, lineIncrement);
        endTrace = getXByTrace(endTrace, scale, surveyLineStart, lineIncrement);
        const featureX = (startTrace + endTrace) / 2;
        let featureY = 0;

        const startTile = startAmplitude;
        const endTile = endAmplitude;
        const startMap = extentMaxY;
        const endMap = extentMinY;

        //quando o inicio do tile ta fora do extent, porém o final do tile tá dentro
        if (startMap < startTile && endMap < endTile){
            //console.log('primeiro caso');
            featureY = (startMap + endTile) / 2;
        }
        else if (startMap > startTile && endMap < endTile){ //todo dentro
            //console.log('segundo caso');
            featureY = (startTile + endTile) / 2;
        }
        else {
            //console.log('else');
            featureY = (startTile + endMap) / 2;
        }

        //console.log('showloading', key, featureX, featureY);

        const feature = new Feature({
            geometry: new Point([featureX, featureY]),
        });

        //((feature.getStyle()! as Style).getImage()! as Circle).setRadius(circleRadius);
        feature.set('color', color);
        feature.set('zoomLevel', zoom);
        feature.set('visible', true);
        feature.set('radius', radius);
        feature.set('ignoreZoomLevel', ignoreZoom);

        this._source?.addFeature(feature);

        const animation = new Zoom({
            duration: 500,
            repeat: 100000
        });

        const animationController = this.animateFeature(feature, [animation]);

        this._features[key] = {
            feature: feature,
            animation: animationController
        };
    }

    hideLoading(key: string){

        const feature = this._features[key];

        this.removeFeature(feature);
        //console.log('rodou hide loading --- ', tileCoordinate);
    }

    hideLoadingByZoom(exceptZoom = -1){
        const source = this.getSource();

        if (exceptZoom === -1){
            console.error('hideLoadingByZoom com valor -1');
            return;
        }

        if (!source){
            console.error('GeopostTileLoadingLayer sem source');
            return;
        }

        const features = source.getFeatures();

        if (!features || features.length === 0){
            return;
        }

        for (let feature in this._features){
            const item = this._features[feature];
            const featureOL = item.feature;
            const zoom = featureOL.get('zoomLevel');
            const featureIgnoreZoom = featureOL.get('ignoreZoomLevel');
            if (!featureIgnoreZoom && zoom !== exceptZoom){
                this.removeFeature(item);
            }
        }
    }

    private removeFeature(feature: {
        feature: Feature;
        animation: animationControler;
    }){
        if (feature){
            feature.animation.stop();
            this._source?.removeFeature(feature.feature);
        }
    }
}
