import { Group, Material } from 'three';
import { Point } from 'ol/geom';

import { buildMainMeshByGeomWKT, buildMeshByGeomWKT, getFirstAndLastCoordinatesFromGeomWkt, getOlGeometryFromWKT } from 'features/seismic-3d/components/projections/featureGeomPolygon';
import { lineMetadata } from 'features/seismic-3d/models/types/LineMetadata';
import { GeopostScene } from './GeopostScene';
import { MeshData } from 'features/seismic-3d/models/types/MeshData';
import { Survey3DInfo } from 'features/seismic-3d/models/types/Survey3DInfo';
import { getThreeJsYFactor, getTotalOfTilesFromLine } from 'features/seismic-3d/utils/TileUtils';
import { GeopostTileMesh } from '../mesh/GeopostTileMesh';

export class GeopostGeomBasedScene extends GeopostScene {
    public addGridSection = (linesData: lineMetadata[], volumeToken: string) => {
        const grid = this.grid;
        if (grid) {
            linesData.forEach((lineData) => {
                const lineGroup = new Group();
                lineGroup.name = this.getLineGroupName(lineData.lineNumber, lineData.lineType);
                //const lineMesh = new GeopostLineMesh(lineData.geometryMetadata.TotalTraces, lineData.geometryMetadata.Line, '#d9d9d9', lineData.lineType, lineData.lineNumber);//buildMeshByGeomWKT(lineData.geometryMetadata.Line, null, '#d9d9d9');
                //lineMesh.name = 'line-mesh';
                const lineMesh = buildMeshByGeomWKT(lineData.geometryMetadata.Line, null, '#d9d9d9');
                const lineMaterial = lineMesh.material;
                if (lineMaterial instanceof Material) {
                    lineMaterial.transparent = true;
                    lineMesh.name = 'line-mesh';
                    lineGroup.add(lineMesh);
                    grid.add(lineGroup);
                }
            });
        }
    };

    public addMainMesh = ({geomWKT, centroidWKT, meshColor, meshToken} : MeshData) => {
        const mesh = buildMainMeshByGeomWKT(geomWKT, centroidWKT!, meshColor);
        mesh.name = meshToken;

        const centroidGeometry = getOlGeometryFromWKT(centroidWKT!);
        const centroid = (centroidGeometry as Point).getCoordinates();
        this.mainFeatureCentroid = [(centroid[0]), (centroid[1])];
        mesh.geometry.computeBoundingBox();
        mesh.matrixAutoUpdate = false;
        mesh.name = 'main-mesh';
        this.mainMesh = mesh;
        (mesh.material as Material).transparent = true;
        return mesh;
    };

    public addLineTiles = (line: lineMetadata, samplesPerTrace: number, tileWidth: number) => {
        const { lineNumber, lineType, geometryMetadata: { Line: lineWkt, TotalTraces: totalTraces }} = line;
        const [ firstLineCoordinate, lastLineCoordinate ] = getFirstAndLastCoordinatesFromGeomWkt(line.geometryMetadata.Line);
        const totalTiles = getTotalOfTilesFromLine(line.geometryMetadata.TotalTraces, tileWidth);
        const yFactor = getThreeJsYFactor(samplesPerTrace);

        const lineGroup = this.getObjectByName(this.getLineGroupName(lineNumber, lineType));
        const tileGroup = new Group();
        tileGroup.name = this.getTileGroupName(lineNumber, lineType);
        tileGroup.visible = false;
        //AQUI CALCULA
        for (let tileIndex = 0; tileIndex < totalTiles; tileIndex++) {
            const tileObject = new GeopostTileMesh(firstLineCoordinate, lastLineCoordinate, totalTiles, tileIndex, yFactor, 0);
            tileObject.name = this.getTileObjectName(tileIndex, lineNumber, lineType, 0);
            tileObject.geometry.computeBoundingBox();
            tileObject.userData.isFilled = false;
            //lineType === LineType.Inline ? inlineTilesRenderOrder : xlineTilesRenderOrder;

            tileGroup.add(tileObject);
        }
        if (lineGroup) {
            lineGroup.add(tileGroup);
        }
    };

    public addZSliceTilesByDepth = (totalInlines: number, totalXlines: number, inlineStart: number, xlineStart: number, depth: number, sampleInterval: number, tileWidth: number, tileHeight: number) => {};
}