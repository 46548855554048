import { Button, Grid } from '@mui/material';
import { forwardRef, Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import alertify from 'alertifyjs';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { GeopostDropdown } from 'fileIndexing/otherComponents/GeopostDropdown';
import { FormContainer } from 'react-hook-form-mui';

import { ePathType, IDataSource } from '../interfaces/IDataSource';
import { useSaveDataSource } from '../useDataSource';
import { useSessionStore } from 'session/useSessionStore';
// import { useSaveInstitution } from '../useInstitution';

export const DataSourceForm = ({ setOpenModal, refetchTableData, setLoading, formData }: Props) => {
    const { control } = useForm<IDataSource>({ defaultValues: formData || undefined });

    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }));

    const [data, setData] = useState<IDataSource | null>(null);

    const { data: saveDataSource, isLoading } = useSaveDataSource(data);

    const handleData = (data: IDataSource) => {
        let newData = data;
        if (formData) {
            newData.Token = formData.Token;
        }
        else {
            newData.Token = '';
        }

        newData.ProjectToken = tenantConfig ? tenantConfig.tenantToken : '';

        setData(newData);
    };

    // AVISO: talvez seja retirada essa parte
    function wait(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function executeSave(): Promise<void> {
        setOpenModal(false);
        setLoading(true);
        await wait(2000);
        setLoading(false);
        refetchTableData();
    }

    useEffect(() => {
        if (saveDataSource) {
            if (saveDataSource === '200') {
                executeSave();
            }
            else {
                alertify.notify('Ocorreu um erro criando uma nova Instituição');
            }
        }
    }, [saveDataSource]);

    const pathTypeDropdownItems = [
        {
            id: ePathType.InternalNetwork,
            label: 'Internal Network'
        },
        {
            id: ePathType.ExternalMidia,
            label: 'External Midia'
        },
        {
            id: ePathType.AzureStorage,
            label: 'Azure Storage'
        },
        {
            id: ePathType.AWSS3Bucket,
            label: 'AWSS3 Bucket'
        },
        {
            id: ePathType.Dump,
            label: 'Dump File'
        },
    ];

    return (
        <Fragment>
            <FormContainer<IDataSource> defaultValues={formData ?? {}} onSuccess={(data) => handleData(data)}>
                <Grid spacing={2} container>
                    <Grid spacing={2} container item>
                        <Grid xs={6} item>
                            <GeopostTextField fieldName={'Name'} label={'Data Source Name'} />
                        </Grid>
                        <Grid xs={6} item>
                            <GeopostTextField fieldName={'RootAddress'} label={'Root Address'} />
                        </Grid>
                    </Grid>
                    <Grid spacing={2} container item>
                        <Grid xs={6} item>
                            <GeopostTextField fieldName={'FilterPath'} label={'Filter Path'} required={false} />
                        </Grid>
                        <Grid xs={6} item>
                            <GeopostTextField fieldName={'FilterExtensions'} label={'Filter Extensions'} required={false} />
                        </Grid>
                    </Grid>
                    <Grid spacing={2} container item>
                        <Grid xs={6} item>
                            <GeopostTextField fieldName={'FilterFileSizeMaximum'} label={'Filter File Size Maximum'} textFieldProps={{ type: 'number' }} />
                        </Grid>
                        <Grid xs={6} item>
                            <GeopostDropdown fieldName={'PathType'} label={'Path Type'} items={pathTypeDropdownItems} />
                        </Grid>
                    </Grid>
                    <Grid container item direction="row-reverse">
                        <Button variant='contained' color='primary' type='submit'>SAVE</Button>
                    </Grid>
                </Grid>
            </FormContainer>
        </Fragment>
    );
};

interface Props {
    setOpenModal: (value: boolean) => void;
    refetchTableData: () => void;
    setLoading: (value: boolean) => void;
    formData?: IDataSource | null;
}