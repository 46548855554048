import { useQuery } from 'react-query';

//import { config } from 'config';
import { IProjection } from 'features/seismic/models/interfaces/IProjection';
import { IIntersections } from 'features/seismic/models/interfaces/IIntersection';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { TenantConfig } from '../model/TenantConfig';
import { axios } from 'configurations/axios';

export const useTenantConfigurationApi = () => {
    return useQuery('TenantConfiguration', async () => {
        const paramsViewer = getParamsFromUrl();

        let hostTenant = '';
        if (window.location.host.indexOf('viewer-app') > -1) {
            hostTenant = window.location.protocol + '//' + window.location.host.replace('viewer-app', 'seismic-api') + '/Viewer/Tenant/Configuration?tenantToken=' + paramsViewer.tenantToken + '&sourceApi=' + paramsViewer.sourceApi;
        }
        else if (window.location.host.indexOf('localhost') > -1) {
            hostTenant = 'https://localhost:44321/Viewer/Tenant/Configuration';
        }
        else {
            let currentHost = 'prod-seismic-api.geopostenergy.com';

            if (window.location.host.includes('stagging-')) {
                currentHost = 'stagging-seismic-api.geopostenergy.com';
            }
            else if (window.location.host.includes('development-')) {
                currentHost = 'development-seismic-api.geopostenergy.com';
            }

            hostTenant = `https://${currentHost}/Viewer/Tenant/Configuration?tenantToken=` + paramsViewer.tenantToken + '&sourceApi=' + paramsViewer.sourceApi;
        }

        if ((window.location.href.indexOf('callback') > -1 || !paramsViewer.tenantToken) && window.location.host.indexOf('localhost') === -1) {
            hostTenant = window.location.protocol + '//' + window.location.host.replace('-app-', '-api-') + '/Viewer/Tenant/Configuration?tenantToken=d1b07f73-0c36-449e-bab7-756ed0c07640&sourceApi=tgs';
        }

        if (window.location.href.indexOf('viewer-dev.datalake.tgs.com') > -1) {
            hostTenant = 'https://viewer-seismic-api-dev.datalake.tgs.com/Viewer/Tenant/Configuration?tenantToken=d1b07f73-0c36-449e-bab7-756ed0c07640&sourceApi=tgs';
        }

        if (window.location.href.indexOf('viewer-test.datalake.tgs.com') > -1) {
            hostTenant = 'https://viewer-seismic-api-test.datalake.tgs.com/Viewer/Tenant/Configuration?tenantToken=d1b07f73-0c36-449e-bab7-756ed0c07640&sourceApi=tgs';
        }

        if (window.location.href.indexOf('viewer.datalake.tgs.com') > -1) {
            hostTenant = 'https://viewer-seismic-api.datalake.tgs.com/Viewer/Tenant/Configuration?tenantToken=d1b07f73-0c36-449e-bab7-756ed0c07640&sourceApi=tgs';
        }

        if (window.location.href.indexOf('gcp-gpost-seismic-app-dev.geopostenergy.com') > -1) {
            hostTenant = 'https://gcp-gpost-seismic-api-dev.geopostenergy.com/Viewer/Tenant/Configuration?tenantToken=d1b07f73-0c36-449e-bab7-756ed0c07640&sourceApi=tgs';
        }

        if (window.location.href.indexOf('vm-poc-yl7c-iglass-viewer') > -1) {
            hostTenant = 'http://vm-poc-yl7c-iglass-viewer:8500/Viewer/Tenant/Configuration?tenantToken='  + paramsViewer.tenantToken + '&sourceApi=' + paramsViewer.sourceApi;
        }

        //hostTenant = 'https://gcp-gpost-seismic-api-dev.geopostenergy.com/Viewer/Tenant/Configuration?tenantToken=d1b07f73-0c36-449e-bab7-756ed0c07640&sourceApi=tgs&tenantToken=d1b07f73-0c36-449e-bab7-756ed0c07640';
        //hostTenant = 'https://stagging-seismic-api.geopostenergy.com/Viewer/Tenant/Configuration';
        //hostTenant = 'https://stagging-seismic-api.geopostenergy.com/Viewer/Tenant/Configuration?tenantToken=77fb6644-96fc-45d5-a73f-bcf504c73a81';

        try {
            const response = await axios.request<TenantConfig>({
                method: 'get',
                url: hostTenant,
                params: {
                    tenantToken: paramsViewer.tenantToken
                }
            });

            return response.data as TenantConfig;
        }
        catch (exception) {
            return exception as Error;
        }
    }, {
        refetchOnWindowFocus: false,
    });
};

export const useTenantConfigurationApiAxios = async () => {
    const paramsViewer = getParamsFromUrl();
    let hostTenant = '';
    if (paramsViewer.tenantToken === 'd1b07f73-0c36-449e-bab7-756ed0c07640') {
        hostTenant = window.location.host.replace('-app-', '-api-') + '/Viewer/Tenant/Configuration?tenantToken=' + paramsViewer.tenantToken + '&sourceApi=' + paramsViewer.sourceApi;
    } else {
        hostTenant = window.location.host.indexOf('localhost') > -1 ? 'https://localhost:44321/Viewer/Tenant/Configuration' : 'https://development-seismic-api.geopostenergy.com/Viewer/Tenant/Configuration?tenantToken=' + paramsViewer.tenantToken + '&sourceApi=' + paramsViewer.sourceApi;
    }

    if (window.location.href.indexOf('callback') > -1 || !paramsViewer.tenantToken) {
        hostTenant = window.location.protocol + '//' + window.location.host.replace('-app-', '-api-') + '/Viewer/Tenant/Configuration?tenantToken=d1b07f73-0c36-449e-bab7-756ed0c07640&sourceApi=tgs';
    }

    const response = await axios.request<TenantConfig>({
        method: 'get',
        url: hostTenant,
        params: {
            tenantToken: paramsViewer.tenantToken
        }
    });

    return response.data;
};