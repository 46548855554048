import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { GeopostMuiPopup } from 'components/geopostMuiPopup/GeopostMuiPopup';
import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { SeismicsList } from '../SeismicsList';
import { SelectedSeismicsListRow } from './SelectedSeismicsListRow';

interface ISelectedSeismicPopupProps {
    height: number | string;
    width: number | string;
    top: number | string;
    left: number | string;
    open: boolean;
    onClose: () => void;
}

export const SelectedSeismicsPopup = ({open, onClose, height, width, top, left} : ISelectedSeismicPopupProps) => {
    const selectedSeismics = use3DViewerStore(state => state.selectedSeismics);
    const removeSelectedSeismic = use3DViewerStore(state => state.removeSelectedSeismic);

    const defaultSelectedSeismic = use3DGridStore(state => state.gridSelectedSeismic);

    return (
        <GeopostMuiPopup
            open={open}
            onClose={onClose}
            height={height}
            width={width}
            top={top}
            left={left}
            title='Selected seismics'
        >
            <TableContainer>
                <Table size='small' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Details
                            </TableCell>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell>
                                Display grid
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (!!defaultSelectedSeismic && !selectedSeismics.includes(defaultSelectedSeismic))
                            &&
                            <SelectedSeismicsListRow seismicData={defaultSelectedSeismic}/>
                        }
                        {
                            selectedSeismics.map(selectedSeismic => <SelectedSeismicsListRow seismicData={selectedSeismic}/>)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </GeopostMuiPopup>
    );
};