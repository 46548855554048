import { LineString } from 'ol/geom';
import { Fill, Stroke, Style, Text } from 'ol/style';

import { IProjection } from '../models/interfaces/IProjection';
import { colorWithAlpha } from './colorWithAlphaUtils';

export function createStyleProjections(item: IProjection, featureName: string, geometry: LineString, overed = false) {
    const fill = new Fill({
        color: colorWithAlpha(item.config.FillColor, item.config.FillOpacity),
    });

    const stroke = new Stroke({
        color: colorWithAlpha(item.config.StrokeColor, (item.config.StrokeOpacity === 0 ? 0.75 : item.config.StrokeOpacity)),
        width: overed ? item.config.StrokeWidth * 2.5 : item.config.StrokeWidth,
        lineDash: [6, 10]
    });

    const text = new Text({
        text: featureName,
        textBaseline: 'bottom',
        font: 'bold 15px Courier New',
        // placement: 'line',
        offsetY: item.config.OffsetYLabel,
        offsetX: item.config.OffsetXLabel,
        rotation: item.config.LabelAngle,
        overflow: true,
        stroke: new Stroke({
            color: colorWithAlpha(item.config.StrokeColor, (item.config.StrokeOpacity === 0 ? 0.75 : item.config.StrokeOpacity)),
            width: overed ? item.config.StrokeWidth * 2.5 : item.config.StrokeWidth,
        })
    });

    let style = new Style({
        fill: item.config.FillOpacity ? fill : undefined,
        stroke: stroke,
        text: text,
        geometry: geometry
    });

    return style;
}