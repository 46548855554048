import { useEffect, useState } from 'react';
import { Box, BoxProps, styled, OutlinedInput, OutlinedInputProps, Typography, Button, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, css } from '@mui/material';
import { useTranslation } from 'react-i18next';
import alertify from 'alertifyjs';

import { useInterpretationStore } from 'features/seismic/components/interpretation/stores/useInterpretationStore';
import { useGet, useSave } from './api/useInterpretationController';
import { PermissionType } from 'features/seismic/models/enums/PermissionType';
import { Permission } from 'components/permission/components/Permission';
import { InterpretationType } from './models/enums/InterpretationType';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { IPermissionModel } from 'components/permission/models/interfaces/IPermissionModel';

const styles = css({
    padding: 7,

    '& .input': {
        height: 40,
        marginBottom: 10,
    },
    '& .container-style': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10,

        '& .item': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 5
        }
    },
    '& .container-loading': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 100
    }
});

const footerStyle = css({
    '& .btn-cancel': {
        marginLeft: 10
    }
});

export function SeismicEditInterpretation({ token, onCancel, type, onReload, open, onChangeOpen }: Props) {
    const { t } = useTranslation(['common', 'seismic']);
    const { data: interpretationLoaded, isLoading: getIsLoading, refetch } = useGet(token);
    const [ lineOpacity, setLineOpacity] = useState<number>(1000);
    const [ name, setName] = useState<string>('');
    const [ lineWidth, setLineWidth] = useState<number>(4);
    const [ lineColor, setLineColor] = useState<string>(type === InterpretationType.Horizon ? '#FF0000' : '#000000');
    const {  isLoading: saveLoading, mutateAsync: saveMutate } = useSave();
    const [ permission, setPermission ] = useState<IPermissionModel>({
        permission: PermissionType.Private,
        tokens: []
    });

    const { canShareToUserGroups, canShareToPublic, canShareToCompanies } = useInterpretationStore(state => ({
        canShareToCompanies: state.canShareToCompanies,
        canShareToPublic: state.canShareToPublic,
        canShareToUserGroups: state.canShareToUserGroups
    }));

    const { surveyMetadata } = useSeismicStore(state => ({
        surveyMetadata: state.surveyMetadata
    }));

    useEffect(() => {
        cleanForm();
        if (open && token) {
            refetch();
        }
    }, [open]);

    useEffect(() => {
        if (interpretationLoaded) {
            const { Result: response } = interpretationLoaded.data;
            const { Style: style } = response;
            let opacity = 1000;

            if (style.Opacity) {
                opacity = parseFloat(style.Opacity) * 1000;
            }

            setName(response.Name);
            setLineColor(style.Color);
            setLineWidth(style.Width);
            setLineOpacity(opacity);
            setPermission({
                permission: response.Permission,
                tokens: response.SharedTokens
            });
        }
    }, [interpretationLoaded]);

    const save = async function() {
        if (!surveyMetadata){
            return;
        }

        if (!name || !name.trim()) {
            alertify.alert(t('seismic:interpretation.chooseName'));
        }
        else {
            await saveMutate({
                name,
                lineColor,
                lineWidth,
                lineOpacity: (lineOpacity / 1000).toString(),
                permission: permission.permission,
                type: type,
                token: interpretationLoaded?.data.Result.Token ? interpretationLoaded.data.Result.Token: '',
                domain: surveyMetadata.Domain,
                sharedTokens: permission.tokens
            });

            onCancel();
            onReload();
        }
    };

    const cleanForm = () => {
        setName('');
        setLineColor(type === InterpretationType.Horizon ? '#FF0000' : '#000000');
        setLineWidth(4);
        setLineOpacity(1000);
        setPermission({
            permission: PermissionType.Private,
            tokens: []
        });
    };

    return (
        <GeopostPopup
            temporaryIgnoreFlexInContainer
            open={open}
            title={t('seismic:interpretation.tools')}
            onChangeOpen={onChangeOpen}
            overlay={false}
            height={600}
            width={500}
            footer={<Box css={footerStyle}>
                <Button variant="outlined" color="success" onClick={() => save()} disabled={getIsLoading || saveLoading}>{t('common:save')}</Button>
                <Button variant="outlined" color="error" className='btn-cancel' onClickCapture={onCancel}>{t('common:cancel')}</Button>
            </Box>}
            resizable={false}>
            <Box css={styles}>
                { (getIsLoading || saveLoading) ?  <Box className='container-loading'>
                    <CircularProgress />
                </Box>:
                    <Box>
                        <Typography style={{ fontWeight: 'bold' }}>{t('common:name')}</Typography>
                        <OutlinedInput className='input' fullWidth type='text' required value={name} onChange={(e) => setName(e.target.value)} />
                        <Typography style={{ fontWeight: 'bold' }}>{t('seismic:interpretation.style')}</Typography>
                        <Box className='container-style'>
                            <Box className='item'>
                                <Typography>{t('seismic:interpretation.lineColor')}</Typography>
                                <input type="color" style={{ width: 50, height: 30 }} value={lineColor} onChange={(e) => setLineColor(e.target.value)} />
                            </Box>
                            <Box className='item'>
                                <Typography>{t('seismic:interpretation.widthLine')}</Typography>
                                <OutlinedInput type='number' style={{ width: 80, height: 30 }} value={lineWidth} onChange={(e) => setLineWidth(parseInt(e.target.value))} />
                            </Box>
                            <Box className='item'>
                                <Typography>{t('seismic:interpretation.opacity')}</Typography>
                                <input type="range" min={0} max={1000} style={{ width: 120, height: 30 }} value={lineOpacity} onChange={(e) => setLineOpacity(parseInt(e.target.value))} />
                            </Box>
                        </Box>
                        <Typography style={{ fontWeight: 'bold' }}>{t('common:permission.permission')}</Typography>
                        <Permission
                            canShareToCompanies={canShareToCompanies}
                            canShareToPublic={canShareToPublic}
                            canShareToUserGroup={canShareToUserGroups}
                            onChange={(permission) => setPermission(permission)}
                            value={permission}
                        />
                    </Box>}
            </Box>
        </GeopostPopup>
    );
}

interface Props {
    open: boolean;
    token: string;
    onCancel: () => void;
    onChangeOpen: (value: boolean) => void;
    onReload: () => void;
    type: InterpretationType;
}