import { Box, css } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import { Fragment, useEffect, useState } from 'react';

export const GeopostTable = <T extends GridValidRowModel>({ filteredData, loading, columns, getRowId, disableShadow, getRowClassName, resultsCount, pagination, setPagination, setSort, height = 280 }: Props<T>) => {
    const [paginationModel, setPaginationModel] = useState<{ pageSize: number, page: number; }>({
        pageSize: pagination ? pagination.pageSize : 5,
        page: 0,
    });

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: '',
            sort: 'asc',
        },
    ]);

    useEffect(() => {
        if (setPagination) {
            let page = {
                pageSize: paginationModel.pageSize,
                page: paginationModel.page + 1
            };

            setPagination(page);
        }
    }, [paginationModel]);

    useEffect(() => {
        if (setSort && sortModel.length > 0) {
            setSort(sortModel);
        }
    }, [sortModel]);

    const DataGridStyle = css({
        '& .datagrid-table': {
            maxHeight: `calc(100vh - ${height}px)`,
            overflowY: 'auto',
            fontSize: '12px',
            fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
        },
        '& .MuiDataGrid-root': {
            borderRadius: '0px !important',
            borderBottomLeftRadius: '4px !important',
            borderBottomRightRadius: '4px !important',
            boxShadow: `${!disableShadow ? '0 3px 5px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.14), 0 0px 1px -2px rgba(0, 0, 0, 0.12)' : 'none'}`,
        },
        '& .MuiDataGrid-columnHeaders': {
            borderRadius: '0px !important',
            backgroundColor: '#808080 !important',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle, .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle:hover, .MuiDataGrid-columnHeaderTitleContainer .MuiIconButton-root, .MuiDataGrid-columnHeaderTitleContainer .MuiIconButton-root:hover': {
            color: '#ffffff',
            fontWeight: '400'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important'
        },
        '& .MuiDataGrid-virtualScroller': {
            overflowY: 'auto',
            overflowX: 'auto',
        },
    });

    return (
        <Fragment>
            <Box css={DataGridStyle}>
                <DataGrid
                    style={{ overflowY: 'auto' }}
                    rows={filteredData ? filteredData : []}
                    loading={loading}
                    autoHeight={filteredData ? (filteredData.length > 0 ? false : true) : true}
                    columns={columns}
                    getRowClassName={getRowClassName}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: pagination ? pagination.pageSize : 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5, 7, 10, 15, 20, 25, 50, 100]}
                    disableRowSelectionOnClick
                    disableColumnSelector
                    getRowId={getRowId}
                    disableColumnMenu
                    className='datagrid-table'
                    rowCount={resultsCount ?? undefined}
                    paginationMode={resultsCount ? 'server' : 'client'}
                    onPaginationModelChange={setPaginationModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                />
            </Box>
        </Fragment>
    );
};

interface Props<T extends GridValidRowModel> {
    filteredData: T[] | undefined;
    loading: boolean;
    columns: GridColDef[];
    getRowId: (row: T) => string | number;
    disableShadow?: boolean;
    getRowClassName?: (params: GridRowParams<T>) => string;
    resultsCount?: number;
    pagination?: { page: number; pageSize: number };
    setPagination?: (pagination: { page: number; pageSize: number }) => void;
    setSort?: (value: GridSortModel) => void;
    height?: number;
}