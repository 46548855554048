import { create } from 'zustand';

interface ILoadingTilesStore {
    increaseLoaded: (url: string) => void;
    increaseLoading: (url: string) => void;
    loaded: number;
    loading: number;
    percentage: number;
    updatePercentage: (loaded: number, loading: number) => void;
}

const loadingTiles : {[key:string]:boolean} = {};

export const useLoadingTileStore = create<ILoadingTilesStore>()(
    (set, get) => ({
        increaseLoaded: (url: string) => {
            const key = url;
            if (loadingTiles[key] === false) {
                return;
            };

            loadingTiles[key] = false;
            let {loading, loaded, updatePercentage} = get();
            //console.log('increaseLoaded ', loaded, loading);
            //console.log(objectTest);
            updatePercentage(++loaded, loading);
        },
        increaseLoading: (url: string) => {
            const key = url;
            if (loadingTiles[key] === true) {
                return;
            };
            //console.log(objectTest);
            loadingTiles[key] = true;
            let {loading, loaded, updatePercentage} = get();
            //console.log('increaseLoading ', loaded, loading);

            updatePercentage(loaded, ++loading);
        },
        loaded: 0,
        loading: 0,
        percentage: 100,
        updatePercentage: (loaded: number, loading: number) => {
            let percentage = (loaded / loading) * 100;
            let currentPercentage = get().percentage;

            /*if (percentage < currentPercentage){
            }*/

            if (loaded === loading){
                percentage = 100;
                loaded = 0;
                loading = 0;
            }

            //console.log(percentage, loaded, loading);

            set({
                loaded: loaded,
                loading: loading,
                percentage: percentage
            });
        }
    })
);