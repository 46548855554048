import { RefObject, useEffect, useCallback, useState } from 'react';
import MousePosition from 'ol/control/MousePosition';
import { useTranslation } from 'react-i18next';

import { useMapStore} from '../stores/useMapStore';
import {useLine3DNavigationStore} from '../stores/useLine3DNavigationStore';
import {LineType} from '../models/enums/LineType';
import {useSeismicStore} from '../stores/useSeismicStore';
import {AmplitudeDomain} from '../models/enums/AmplitudeDomain';
import { getTrace, getAmplitude, getZSliceY } from '../utils/calculateScreenValuesUtils';
import { ILineString } from '../models/interfaces/ILineString';
import { SurveyType } from '../models/enums/SurveyType';

export function useMousePosition (ref: RefObject<HTMLDivElement>){
    const map = useMapStore(state => state.map);
    const { t } = useTranslation();
    const lineType = useLine3DNavigationStore(state => state.lineType);
    const [ control, setControl ] = useState<MousePosition | null>(null);
    const { calculator, surveyMetadata, scaleY, scaleX, restrict, isInverted, isVerticallyInverted } = useSeismicStore(state => ({
        calculator: state.calculator,
        restrict: state.restrict,
        scaleX: state.scale.x,
        scaleY: state.scale.y,
        surveyMetadata: state.surveyMetadata,
        isInverted: state.isInverted,
        isVerticallyInverted: state.isVerticallyInverted
    }));

    const coordinateFormat = useCallback((coordinate: number[] | undefined) => {
        //console.log('coord --->', coordinate);
        if (!coordinate || !surveyMetadata || !calculator || !restrict){
            return '';
        }

        const increment = calculator.getLineIncrement(surveyMetadata);
        const start = calculator.getMinTrace(surveyMetadata);
        const end = calculator.getMaxTrace(surveyMetadata);

        let trace = getTrace(coordinate[0], scaleX, start, increment);

        if (isInverted) {
            trace = end - trace + start;
        }

        let amplitude = lineType === LineType.ZSlice ? getZSliceY(coordinate[1], scaleY, surveyMetadata.Survey3DInfo.XlineIncrement, surveyMetadata.Survey3DInfo.XlineStart) : parseInt(getAmplitude(coordinate[1], scaleY, calculator!.calculateSampleInterval(surveyMetadata)));

        if (isVerticallyInverted) {
            amplitude = surveyMetadata.Survey3DInfo.XlineEnd - amplitude + surveyMetadata.Survey3DInfo.XlineStart;
        }

        let labelX = (lineType === LineType.Inline)?'Xline':'Inline';

        if (!surveyMetadata.IsIndexed || surveyMetadata.Type === SurveyType.Seismic2D){
            labelX = 'Trace';
        }

        const labelY = lineType === LineType.ZSlice ?
            'Xline'
            :
            (surveyMetadata.Domain === AmplitudeDomain.Depth) ?
                t('depth')
                :
                t('time');

        const finalX = (restrict.SeismicDisplayTraces)?`<p class="x">${labelX}: ${trace}</p>`: '';
        const finalY = (restrict.SeismicDisplayDepth)?`<p class="y">${labelY}: ${amplitude}</p>`: '';

        return `${finalX}
        ${finalY}`;
    }, [surveyMetadata, calculator, restrict, scaleX, isInverted, lineType, scaleY, t]);

    useEffect(() => {
        if (map && !control){
            const control = new MousePosition({
                coordinateFormat: coordinateFormat,
                target: ref.current as HTMLDivElement,
                className: 'geopost-seismic-mouse-position'
            });

            map.addControl(control);

            setControl(control);
        }
    }, [map?.geopostKey]);

    useEffect(() => {
        if (control){
            control.setCoordinateFormat(coordinateFormat);
        }
    }, [coordinateFormat]);
}