import { Slider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { use3DSceneStore } from '../stores/use3DSceneStore';

export const LightIntensitySlider = () => {
    const defaultLightPercentage = useSessionStore(state => state.tenantConfiguration?.viewer3DDefaults?.default_luminosity);

    const { lightIntensityPercentage, setLightIntensityPercentage } = use3DSceneStore(state => ({
        lightIntensityPercentage: state.lightIntensityPercentage,
        setLightIntensityPercentage: state.setLightIntensityPercentage
    }));

    const handleSliderChange = (event: Event, value: number | number[]) => {
        setLightIntensityPercentage(value as number);
    };

    const formatLabel = (value: number) => {
        return value + '%';
    };

    useEffect(() => {
        if (!!defaultLightPercentage) {
            setLightIntensityPercentage(parseFloat(defaultLightPercentage));
        }
    }, [defaultLightPercentage]);

    return (
        <Stack>
            <Stack justifyContent='end'>
                <Typography paragraph sx={{marginBottom: 0, fontSize: 14}}>Light intensity: {formatLabel(lightIntensityPercentage)}</Typography>
            </Stack>
            <Stack alignItems='start'>
                <Slider
                    aria-label='Light intensity'
                    min={0}
                    max={100}
                    step={1}
                    onChange={handleSliderChange}
                    valueLabelFormat={formatLabel}
                    valueLabelDisplay='off'
                    color='secondary'
                    size='small'
                    value={lightIntensityPercentage}
                />
            </Stack>
        </Stack>
    );
};