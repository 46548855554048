import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, css, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

import { ISurveyMetadata } from 'features/seismic/models/interfaces/ISurveyMetadata';
import { getBooleanLabel, getLabelSize } from 'utils/labelsUtils';
import { AmplitudeDomain } from '../../models/enums/AmplitudeDomain';
import { SampleIntervalUnity } from '../../models/enums/SampleIntervalUnity';
import { SurveyType } from '../../models/enums/SurveyType';
import { getDateLabel } from '../../utils/getLabelsUtils';

const styleItem = css({
    margin: '2px 7px',

    '& .label': {
        marginRight: 3
    }
});

const styleAccordion = css({
    margin: 0,
    paddingLeft: 10,
    backgroundColor: '#999999',
    borderRadius: '5px',
    marginBottom: '5px',

    '& .MuiAccordionSummary-content': {
        marginTop: 0,
        marginBottom: 0,
    },
    '& .MuiAccordionSummary-root': {
        minHeight: 0,
    },
    '& .MuiAccordion-region': {
        minHeight: 0,
        backgroundColor: 'red',
    }
});

const buildLine = (label: string, value: string | number, noWrap: boolean = true) => {
    if (value !== 'N/A') {
        return (
            <div css={styleItem} title={value?.toString()} key={label}>
                <Typography noWrap={noWrap} style={{width: '100%'}}><b className='label'>{label}{label ? ':' : ''}</b>{value}</Typography>
            </div>);
    } else {
        return null;
    };
};

const buildEBCDIC = (value: string[], noWrap: boolean = true) =>
    value.map(part =>
        <div style={{paddingLeft: '6px', paddingRight: '6px'}} css={styleItem} title={part} key={'label ' + part}>
            <Typography noWrap={noWrap} style={{fontSize:'12px', width: '100%'}}>{part}</Typography>
        </div>
    );

const buildAccordeon = (label: string, elements: EmotionJSX.Element[]) => <Accordion disableGutters={false}>
    <AccordionSummary
        css={styleAccordion}
        expandIcon={<ExpandMoreIcon/>}
    >
        <Typography><strong>{label}</strong></Typography>
    </AccordionSummary>
    <AccordionDetails>
        {elements}
    </AccordionDetails>
</Accordion>;

export function SeismicMetadataContent ({ surveyMetadata }:Props) {
    const { t } = useTranslation();

    if (!surveyMetadata) {
        return <CircularProgress />;
    }

    const displayName = (surveyMetadata.DisplayName) ? surveyMetadata.DisplayName : surveyMetadata.VolumePath.replace(/^.*[\\\/]/, '');
    const surveyType = (surveyMetadata.Type === SurveyType.Seismic2D) ? '2D' : '3D';
    const domain = (surveyMetadata.Domain === AmplitudeDomain.Depth) ? t('depth') : t('time');
    const sufixDomain = (surveyMetadata.Domain === AmplitudeDomain.Depth) ? 'm' : 'ms';
    let sampleIntervalUnit = 'N/A';
    if (surveyMetadata.Header.SampleIntervalUnit === SampleIntervalUnity.Microsecond) {
        sampleIntervalUnit = t('microsecond');
    }

    if (surveyMetadata.Header.SampleIntervalUnit === SampleIntervalUnity.Milisecond) {
        sampleIntervalUnit = t('milisecond');
    }

    const lengthLabel = (surveyMetadata.Type === SurveyType.Seismic2D) ? t('length') : t('area');
    const lengthUnit = (surveyMetadata.Type === SurveyType.Seismic2D) ? 'Km' : 'Km²';

    const buildMainContainer = () => <Stack sx={{marginBottom: '10px'}} spacing={1}>
        {buildLine(t('name'),displayName)}
        {surveyMetadata.IsIndexed && surveyMetadata.IndexDate ? buildLine(t('isIndexed'), t(getBooleanLabel(surveyMetadata.IsIndexed))):null}
        {surveyMetadata.IsIndexed && surveyMetadata.IndexDate ? buildLine(t('indexDate'), getDateLabel(surveyMetadata.IndexDate)):null}
        {surveyMetadata.Size > 0 ? buildLine(t('size'), getLabelSize(surveyMetadata.Size)):null}
        {buildLine(t('surveyType'), surveyType)}
        {buildLine(t('domain'), domain)}
    </Stack>;

    const componentFormatCode = buildLine(t('formatCode'), surveyMetadata.Header.FormatCode > -1 ? surveyMetadata.Header.FormatCode.toString() : 'N/A');
    const componentByteSize = buildLine(t('byte.size'), surveyMetadata.Header.ByteSize > -1 ? surveyMetadata.Header.FormatCode : 'N/A');
    const componentNumberTraces = buildLine(t('numberOfTraces'), surveyMetadata.Header.NumberOfTraces > 0 ? surveyMetadata.Header.NumberOfTraces : 'N/A');
    const componentSamplesPerTrace = buildLine(t('samplesPerTrace'), surveyMetadata.Header.SamplesPerTrace > 0 ? surveyMetadata.Header.SamplesPerTrace : 'N/A');
    const componentSampleInterval = buildLine(t('sampleInterval'), surveyMetadata.Header.SampleInterval > 0 ? surveyMetadata.Header.SampleInterval : 'N/A');
    const componentSampleUnit = buildLine(t('sampleIntervalUnit'), sampleIntervalUnit);
    const componentDomain = buildLine(`${t('total')} ${domain}`, surveyMetadata.Header.TotalY + ' ' + sufixDomain);
    const componentLength = buildLine(lengthLabel, surveyMetadata.Header.Length > 0 ? surveyMetadata.Header.Length.toFixed(2) + ' ' + lengthUnit : 'N/A');
    const componentAvgDistance = buildLine(t('avgTraceDistance'), surveyMetadata.Header.AverageTraceDistance > 0 ? surveyMetadata.Header.AverageTraceDistance.toFixed(2) + ' m' : 'N/A');

    const componentList:EmotionJSX.Element[] = [];
    if (componentFormatCode){
        componentList.push(componentFormatCode);
    }

    if (componentByteSize){
        componentList.push(componentByteSize);
    }

    if (componentNumberTraces){
        componentList.push(componentNumberTraces);
    }

    if (componentSamplesPerTrace){
        componentList.push(componentSamplesPerTrace);
    }

    if (componentSampleInterval){
        componentList.push(componentSampleInterval);
    }

    if (componentSampleUnit){
        componentList.push(componentSampleUnit);
    }

    if (componentDomain){
        componentList.push(componentDomain);
    }

    if (componentLength){
        componentList.push(componentLength);
    }

    if (componentAvgDistance){
        componentList.push(componentAvgDistance);
    }

    const buildHeaderContainer = () => buildAccordeon(t('headerInformation'), componentList);

    const insertLineBreaks = (rawString:string) : string[] => {
        //rawString['header']
        if (rawString !== null) {
            const maxLength = 80;
            const regex = new RegExp(`.{1,${maxLength}}`, 'g');
            if (regex) {
                if (rawString) {
                    const matches = JSON.stringify(rawString).match(regex);
                    if (matches && matches.length > 0) {
                        //return matches.join('\r\n');
                        return matches;
                    } else {
                        return [rawString];
                    }
                } else {
                    return [rawString];
                }
            } else {
                return [rawString];
            }
        } else {
            return [rawString];
        }
    };

    const buildEBCDICContainer = () => buildAccordeon('EBCDIC',
        buildEBCDIC(surveyMetadata?.EBCDIC ? insertLineBreaks(surveyMetadata.EBCDIC):['N/A'], true)
    );

    const buildLoadingParamsContainer = () => {
        if (!surveyMetadata.LoadingParams){
            return null;
        }

        const componentScalarX = buildLine(t('byte.coordinateScalarX'), surveyMetadata.LoadingParams.ScalarX > 0 ? surveyMetadata.LoadingParams.ScalarX : 'N/A');
        const componentScalarY = buildLine(t('byte.coordinateScalarY'), surveyMetadata.LoadingParams.ScalarY > 0 ? surveyMetadata.LoadingParams.ScalarY : 'N/A');
        const componentCoordinateX = buildLine(t('byte.coordinateX'), surveyMetadata.LoadingParams.CoordinateX > 0 ? surveyMetadata.LoadingParams.CoordinateX : 'N/A');
        const componentCoordinateY = buildLine(t('byte.coordinateY'), surveyMetadata.LoadingParams.CoordinateY > 0 ? surveyMetadata.LoadingParams.CoordinateY : 'N/A');
        const componentInline = buildLine(t('byte.inline'), surveyMetadata.LoadingParams.Inline > 0 ? surveyMetadata.LoadingParams.Inline : 'N/A');
        const componentXline = buildLine(t('byte.xline'), surveyMetadata.LoadingParams.Xline > 0 ? surveyMetadata.LoadingParams.Xline : 'N/A');
        const componentCoordinateXScalar = buildLine(t('scalarCoordinate.x'), surveyMetadata.LoadingParams.ScalarCoordinateX > 0 ? surveyMetadata.LoadingParams.ScalarCoordinateX : 'N/A');
        const componentCoordinateYScalar = buildLine(t('scalarCoordinate.y'), surveyMetadata.LoadingParams.ScalarCoordinateY > 0 ? surveyMetadata.LoadingParams.ScalarCoordinateY : 'N/A');
        const componentSrid = buildLine('SRID', surveyMetadata.LoadingParams.SRID > 0 ? surveyMetadata.LoadingParams.SRID : 'N/A');

        const componentList:EmotionJSX.Element[] = [];
        if (componentFormatCode){
            componentList.push(componentFormatCode);
        }

        if (componentScalarX) {
            componentList.push(componentScalarX);
        }

        if (componentScalarY) {
            componentList.push(componentScalarY);
        }

        if (componentCoordinateX) {
            componentList.push(componentCoordinateX);
        }

        if (componentCoordinateY) {
            componentList.push(componentCoordinateY);
        }

        if (componentInline) {
            componentList.push(componentInline);
        }

        if (componentXline) {
            componentList.push(componentXline);
        }

        if (componentCoordinateXScalar) {
            componentList.push(componentCoordinateXScalar);
        }

        if (componentCoordinateYScalar) {
            componentList.push(componentCoordinateYScalar);
        }

        if (componentSrid) {
            componentList.push(componentSrid);
        }

        return buildAccordeon(t('loadingParams'), componentList);
    };

    const build3DInfo = () => {
        if (surveyMetadata.Type === SurveyType.Seismic2D || !surveyMetadata.Survey3DInfo){
            return null;
        }

        const componentInlineStart = buildLine(t('inline.start'), surveyMetadata.Survey3DInfo.InlineStart > 0 ? surveyMetadata.Survey3DInfo.InlineStart : 'N/A');
        const componentInlineEnd = buildLine(t('inline.end'), surveyMetadata.Survey3DInfo.InlineEnd > 0 ? surveyMetadata.Survey3DInfo.InlineEnd : 'N/A');
        const componentInlineIncrement = buildLine(t('inline.increment'), surveyMetadata.Survey3DInfo.InlineIncrement > 0 ? surveyMetadata.Survey3DInfo.InlineIncrement : 'N/A');
        const componentInlineTotal = buildLine(t('inline.total'), surveyMetadata.Survey3DInfo.TotalInlines > 0 ? surveyMetadata.Survey3DInfo.TotalInlines : 'N/A');

        const componentXlineStart = buildLine(t('xline.start'), surveyMetadata.Survey3DInfo.XlineStart > 0 ? surveyMetadata.Survey3DInfo.XlineStart : 'N/A');
        const componentXlineEnd = buildLine(t('xline.end'), surveyMetadata.Survey3DInfo.XlineEnd > 0 ? surveyMetadata.Survey3DInfo.XlineEnd : 'N/A');
        const componentXlineIncrement = buildLine(t('xline.increment'), surveyMetadata.Survey3DInfo.XlineIncrement > 0 ? surveyMetadata.Survey3DInfo.XlineIncrement : 'N/A');
        const componentXlineTotal = buildLine(t('xline.total'), surveyMetadata.Survey3DInfo.TotalXlines > 0 ? surveyMetadata.Survey3DInfo.TotalXlines : 'N/A');

        const componentList:EmotionJSX.Element[] = [];
        if (componentInlineStart){
            componentList.push(componentInlineStart);
        }

        if (componentInlineEnd){
            componentList.push(componentInlineEnd);
        }

        if (componentInlineIncrement){
            componentList.push(componentInlineIncrement);
        }

        if (componentInlineTotal){
            componentList.push(componentInlineTotal);
        }

        if (componentXlineStart){
            componentList.push(componentXlineStart);
        }

        if (componentXlineEnd){
            componentList.push(componentXlineEnd);
        }

        if (componentXlineIncrement){
            componentList.push(componentXlineIncrement);
        }

        if (componentXlineTotal){
            componentList.push(componentXlineTotal);
        }

        return buildAccordeon(t('3dInfo'), componentList);
    };

    return (
        <Box width='100%'>
            {buildMainContainer()}
            {buildHeaderContainer()}
            {buildLoadingParamsContainer()}
            {build3DInfo()}
            {buildEBCDICContainer()}
        </Box>
    );
}

interface Props {
    surveyMetadata: ISurveyMetadata | null
}