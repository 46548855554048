import { Box, Button, Modal, TextField, Tooltip, Typography, Zoom } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { createRef, Fragment, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import alertify from 'alertifyjs';
import { MainTableCss } from 'fileIndexing/envVar/envVar';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';
import dayjs from 'dayjs';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { ContractsForm } from './ContractsForm';
import { IContract } from '../interfaces/IContract';
import { useDeleteContract, useGetAllContracts, useGetContractByToken } from '../useContracts';
import { ContractsFiles } from './ContractsFiles';

export function ContractsTable() {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<IContract | null>(null);
    const [openContractsModal, setOpenContractsModal] = useState<boolean>(false);

    const [editToken, setEditToken] = useState<string>('');
    const [deleteToken, setDeleteToken] = useState<string>('');
    const [contractToken, setContractToken] = useState<string>('');

    const [filteredData, setFilteredData] = useState<IContract[] | undefined>([]);
    const [filter, setFilter] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    const { data: contractsData, isLoading: contractsDataLoading, refetch } = useGetAllContracts();
    const { data: contractsGetByToken } = useGetContractByToken(editToken);
    const { data: deleteContractData } = useDeleteContract(deleteToken);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleOpenEdit = (rowData: IContract) => {
        setEditToken(rowData.Token);
    };

    const handleDelete = (name: string, token: string) => {
        alertify.confirm(`Do you really wanna delete ${name}?`, async () => {
            setDeleteToken(token);
        });
    };

    const handleOpenContracts = (token: string) => {
        setContractToken(token);
        setOpenContractsModal(true);
    };

    const handleCloseContracts = () => {
        setOpenContractsModal(false);
    };

    // reseta as informações preenchidas no modal
    useEffect(() => {
        if (!openModal) {
            setModalData(null);
        }
    }, [openModal]);

    // seta as informações para edit
    useEffect(() => {
        if (contractsGetByToken) {
            const contractsData = contractsGetByToken;
            contractsData.SigningDate = dayjs(contractsData.SigningDate, 'DD/MM/YYYY');
            contractsData.Year = dayjs(contractsData.Year.toString(), 'YYYY');

            setModalData(contractsData);
            handleOpenModal();
            setEditToken('');
        }
    }, [contractsGetByToken]);

    // salva as informações de todas as linhas recebidas
    useEffect(() => {
        if (contractsData) {
            setFilteredData(contractsData);
        }
    }, [contractsData]);

    // realiza o filtro
    useEffect(() => {
        if (contractsData) {
            if (filter === '') {
                setFilteredData(contractsData);
            }
            else {
                setFilteredData(contractsData.filter(x => x.CompanyName?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.SurveyType?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.ContractedKm?.toString().toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.EquivalentKm?.toString().toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.ContractObject?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.SapCode?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.SigningDate?.toString().toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.ContractValue?.toString().toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Year?.toString().toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.GeneralNotes?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.ContractGoal?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Block?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.StorageSummary?.toLowerCase().startsWith(filter.toLowerCase())));
            }
        }
    }, [filter]);

    // deleta o contract
    useEffect(() => {
        if (deleteContractData === 'Success') {
            refetch();
        }
    }, [deleteContractData]);

    // conteúdo da tabela
    const columns: GridColDef[] = [
        {
            field: 'CompanyName',
            headerAlign: 'left',
            headerName: 'Company',
            width: 120,
        },
        {
            field: 'SurveyType',
            headerAlign: 'left',
            headerName: 'Survey Type',
            width: 120,
        },
        {
            field: 'ContractedKm',
            headerAlign: 'left',
            headerName: 'Contracted Km',
            width: 120,
        },
        {
            field: 'EquivalentKm',
            headerAlign: 'left',
            headerName: 'Equivalent Km',
            width: 120,
        },
        {
            field: 'ContractObject',
            headerAlign: 'left',
            headerName: 'Contract Object',
            width: 120,
        },
        {
            field: 'SapCode',
            headerAlign: 'left',
            headerName: 'Sap Code',
            width: 120,
        },
        {
            field: 'SigningDate',
            headerAlign: 'left',
            headerName: 'Signing Date',
            width: 120,
        },
        {
            field: 'ContractValue',
            headerAlign: 'left',
            headerName: 'Contract Value',
            width: 120,
        },
        {
            field: 'Year',
            headerAlign: 'left',
            headerName: 'Year',
            width: 120,
        },
        {
            field: 'GeneralNotes',
            headerAlign: 'left',
            headerName: 'General Notes',
            width: 120,
        },
        {
            field: 'ContractGoal',
            headerAlign: 'left',
            headerName: 'Contract Goal',
            width: 120,
        },
        {
            field: 'Block',
            headerAlign: 'left',
            headerName: 'Block',
            width: 120,
        },
        {
            field: 'StorageSummary',
            headerAlign: 'left',
            headerName: 'Storage Summary',
            width: 120,
        },
        {
            field: 'Options',
            headerName: 'Options',
            headerAlign: 'center',
            sortable: false,
            minWidth: 300,
            flex: 1,
            renderCell: (params) => (
                <Box className='options-buttons'>
                    <Tooltip title='View and/or send contracts' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' onClick={() => handleOpenContracts(params.row.Token)}>
                            <InsertDriveFileIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Edit contract metadata' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' className='edit-icon' onClick={() => handleOpenEdit(params.row)}>
                            <EditNoteIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Delete contract' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='error' className='delete-icon' onClick={() => handleDelete(params.row.ContractObject, params.row.Token)}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    const getRowId = (row: IContract) => row.Token;

    return (
        <Fragment>
            <Box id='table-contract' css={MainTableCss}>
                <Box className='table-header'>
                    <Typography className='table-header-title'>Contracts</Typography>
                    <Box className='search'>
                        <TextField label='Search contract' variant='outlined' size='small' color='primary' value={filter} onChange={(event) => setFilter(event.target.value)} />
                        <Button variant='contained' color='primary' className='add-icon' onClick={() => handleOpenModal()}>
                            <AddIcon />
                        </Button>
                    </Box>
                </Box>
                <GeopostTable<IContract> filteredData={filteredData} loading={contractsDataLoading || loading} columns={columns} getRowId={getRowId} />
            </Box>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openModal}
                onChangeOpen={handleCloseModal}
                resizable={false}
                width={590}
                height={700}
                maxHeight={1100}
                title={modalData ? `Edit Contract` : 'Add Contract'}
                padding={'16px'}
            >
                <ContractsForm setOpenModal={setOpenModal} refetchTableData={refetch} setLoading={setLoading} formData={modalData} />
            </GeopostPopup>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openContractsModal}
                onChangeOpen={handleCloseContracts}
                resizable={false}
                width={590}
                height={500}
                maxHeight={1100}
                title={'Contracts'}
                padding={'16px'}
            >
                <ContractsFiles token={contractToken} />
            </GeopostPopup>
        </Fragment>
    );
}