import { FormControlLabel, FormGroup, Checkbox, css, Radio } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnnotationsSettingsVisibleType } from '../models/enums/AnnotationsSettingsVisibleType';
import { useCrossSectionAnnotationsStore } from '../stores/useCrossSectionAnnotationsStore';
import { createGroupKey } from '../utils/wellCrossSectionAnnotationsUtils';

const styles = css({
    padding: 10,

    '& table': {
        width: '100%'
    }
});

export function CrossSectionChangeVisibleAnnotations(){
    const { annotations, visibleSettings, changeVisibleSettings, setVisibleSettings, flatAnnotation, setFlatAnnotation } = useCrossSectionAnnotationsStore(state => ({
        annotations: state.annotations,
        visibleSettings: state.visibleSettings,
        changeVisibleSettings: state.changeVisibleSettings,
        flatAnnotation: state.flatAnnotation,
        setVisibleSettings: state.setVisibleSettings,
        setFlatAnnotation: state.setFlatAnnotation
    }));

    const [ annotationsName, setAnnotationsName ] = useState<{[key:string]:string}>({});

    const { t } = useTranslation('crossSection');

    useEffect(() => {
        if (!annotations){
            return;
        }

        const objAnnotations:{[key:string]:string} = {};

        for (let wellId in annotations){
            const allAnnotations = annotations[wellId];

            for (let annotation of allAnnotations){
                const groupKey = createGroupKey(annotation);
                objAnnotations[groupKey] = `${annotation.Name} (${annotation.Owner})`;
            }
        }

        //const arrayAnnotations:string[] = [];
        const objEnabledAnnotations = Object.assign({}, visibleSettings);

        for (let key in objAnnotations){
            //arrayAnnotations.push(key);

            if (!objEnabledAnnotations[key]){
                objEnabledAnnotations[key] = {
                    depth: true,
                    name: true,
                    visible: true
                };
            }
        }

        setAnnotationsName(objAnnotations);
        setVisibleSettings(objEnabledAnnotations);
    }, [annotations]);

    const handleChange = (annotationName: string, type: AnnotationsSettingsVisibleType, event:ChangeEvent, checked: boolean) => {
        changeVisibleSettings(annotationName, checked, type);
    };

    const handleClickButton = (annotation: string, flatAnnotation: string) => {
        if (annotation === flatAnnotation){
            setFlatAnnotation('');
        }
    };

    return <div css={styles}>
        <table>
            <thead>
                <tr>
                    <td>{t('annotations.label')}</td>
                    <td>{t('name')}</td>
                    <td>{t('depth')}</td>
                    <td>{t('annotations.flat')}</td>
                </tr>
            </thead>
            <tbody>
                {
                    Object.keys(annotationsName).sort().map(annotation =>
                        <tr key={annotation}>
                            <td>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={visibleSettings[annotation].visible}
                                        onChange={handleChange.bind(null, annotation, AnnotationsSettingsVisibleType.visible)}/>}
                                label={annotationsName[annotation]} />
                            </td>
                            <td>
                                <Checkbox
                                    checked={visibleSettings[annotation].name}
                                    onChange={handleChange.bind(null, annotation, AnnotationsSettingsVisibleType.name)}/>
                            </td>
                            <td>
                                <Checkbox
                                    checked={visibleSettings[annotation].depth}
                                    onChange={handleChange.bind(null, annotation, AnnotationsSettingsVisibleType.depth)}/>
                            </td>

                            <td>
                                <Radio
                                    className='radio-flat'
                                    value={annotation}
                                    checked={annotation === flatAnnotation}
                                    onClick={() => handleClickButton(annotation, flatAnnotation)}
                                    name='rad-crosssection-formations'
                                    onChange={(event) => setFlatAnnotation(event.target.value)}
                                />
                            </td>
                        </tr>)
                }
            </tbody>
        </table>
    </div>;
}