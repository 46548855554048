import PublicIcon from '@mui/icons-material/Public';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { LineType } from 'features/seismic/models/enums/LineType';

export function SeismicMiniMap({ opened, setOpened, dataGeometryLine, hasErrorOnGeometry}: Props) {
    const { t } = useTranslation();

    const lineType = useLine3DNavigationStore(state => state.lineType);

    if (!dataGeometryLine && !hasErrorOnGeometry && lineType !== LineType.Line2D && lineType !== LineType.ZSlice) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Fragment>
                <IconButton
                    id='miniMap'
                    color={!hasErrorOnGeometry ? 'primary' : 'error'}
                    disableRipple
                    style={{fontSize: '22px'}}
                    onClick={() => setOpened(!opened)}
                    title={!hasErrorOnGeometry ? t('miniMapClosed') : 'Error on load geometry'}>
                    <PublicIcon />
                </IconButton>
            </Fragment>
        </Box>
    );
}

interface Props {
    opened: boolean;
    setOpened: (value: boolean) => void;
    dataGeometryLine: ILineString | null;
    hasErrorOnGeometry: boolean;
}