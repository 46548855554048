import { Alert, Snackbar } from '@mui/material';

import { useSnackbarStore } from './store/useSnackbarStore';

export function GeopostSnackbar(){
    const { hideSnackbar, message, type } = useSnackbarStore(state => ({
        hideSnackbar: state.hideSnackbar,
        message: state.message,
        type: state.type
    }));

    return <Snackbar autoHideDuration={5000} open={!!message} onClose={() => hideSnackbar()}  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
        <Alert variant='filled' severity={type}>{message}</Alert>
    </Snackbar>;
}