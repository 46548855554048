import { Box, css, FormControl, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import {ChangeEventHandler, useEffect, useRef, useState} from 'react';
import ImageLayer from 'ol/layer/Image';
import Map from 'ol/Map';
import MousePosition from 'ol/control/MousePosition';
import {defaults as defaultControls} from 'ol/control';
import { useTranslation } from 'react-i18next';
import { scale } from 'ol/size';
import { useDebouncedCallback } from 'use-debounce';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useSessionStore } from 'session/useSessionStore';
import { ICrossSectionItem } from '../models/interfaces/ICrossSectionItem';
import { IWellGenericCut } from '../models/interfaces/IWellGenericCut';
import { useCrossSectionStore } from '../stores/useCrossSectionStore';
import { WellTrackingSelect } from './WellTrackingSelect';
import { LoadingBlock } from 'components/LoadingBlock';
import { buildExtent, buildMousePosition, buildStaticSource, calculateVerticalFitByDepths } from '../utils/wellLaneMapUtils';
import { useAnnotations } from './WellSectionLane/hooks/useAnnotations';
import { HEIGHT_HEADER_LANE, HEIGHT_HEADER_TITLE_LANE } from 'features/cross-section/consts/layotsConsts';
import { FitPage, Metadata } from 'components/icons/components';
import { useUpdatedSizeMap } from './WellSectionLane/hooks/useUpdatedSizeMap';
import { ICrossSection } from 'features/cross-section/models/interfaces/ICrossSection';
import { useUpdatedMap } from './WellSectionLane/hooks/useUpdatedMap';
import { IDictionaryWellLayers } from 'features/cross-section/models/interfaces/IDictionaryWellLayers';
import { ScaleTypes } from 'features/cross-section/models/interfaces/INeumonic';
import { LaneType } from 'features/cross-section/models/enums/LaneType';
import { NeumonicSelect } from './NeumonicSelect';
import { CrossSectionColorPicker } from './CrossSectionColorPicker';
import { WellScaleSelect } from './WellScaleSelect';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { WellCurveCatalog } from 'features/well-viewer/components/WellCurveCatalog';
import { IWellCatalog } from '../models/interfaces/IWellCatalog';
import { InputNumberValueSelector } from 'components/inputs/InputNumberValueSelector';
import { WellMinMaxCurve } from 'features/well-viewer/components/WellMinMaxCurve';

const style = css({
    marginRight: '0px',
    borderStyle:'solid',
    borderWidth: 1,
    paddingLeft: 0,
    borderColor: 'rgb(219 225 231)',
    borderRadius: 3,
    paddingTop: 10,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'column',

    '& .well-container': {
        position: 'relative',
        flexGrow: 1,
    },
    '& .well-name-header':{
        height: HEIGHT_HEADER_LANE,
        overflowY:'auto',
        scrollbarWidth:'thin'
    },
    '& .scroller':{
        width: '100px',
        height: '100px',
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
    },
    '& .well-lanes':{
        display: 'flex',
        flexDirection: 'row',
    },
    '& .well-name': {
        fontSize:'18px',
        width: '100%',
        backgroundColor: 'rgb(4, 31, 60)',
        color: 'white',
        borderRadius: 4,
        textAlign: 'center',
        height: HEIGHT_HEADER_TITLE_LANE,
        display:'flex',
        justifyContent: 'space-between'
    },
    '& .well-info': {
        fontSize:'16px',
        padding: '3px',
    },
    '& .mapa': {
        height: '100%',
    },
    '& .label-depth': {
        fontSize: 14
    },'& .close-lane':{
        float: 'right',
        height: '100%',
    },'& .makeStyles-root-1':{
        width:'15%'
    },'& .color-unit-div':{
        display:'flex',
        alignItems:'center',
        flexDirection:'column'
    },'& .curve-selection-div':{
        marginTop:'3%'
    },'& .curveInterval':{
        display:'flex'
    },'& .lane-controls':{
        display: 'inline-flex',
        marginLeft: '-2%',
        alignItems: 'center',
        padding: '0%'
    }
});

export function WellSectionLane ({ crossSectionItem, crossSection}: IProps){
    let initialCut = null;
    let initialCurve= null;
    if (crossSectionItem?.GenericCut){
        initialCut = crossSectionItem?.GenericCut;
    }
    if (crossSectionItem?.SelectedCurves){
        initialCurve = crossSectionItem?.SelectedCurves;
    }
    const [map, setMap] = useState<Map | null>(null);
    const containerMap = useRef<HTMLDivElement>(null);
    const [dictionaryWellLayers, setDictionaryWellLayers] = useState<IDictionaryWellLayers>({});
    const [selectedGenericCut, setSelectedGenericCut] = useState<IWellGenericCut | null>(initialCut);
    const [selectedCurve, setSelectedCurve] = useState<IWellCatalog[] | null>(initialCurve);
    const [selectedScaleType , setSelectedScaleType] = useState<ScaleTypes>({name:'normal',value:0});
    const [curveColor, setCurveColor] = useState<string[]>([]);
    const { t } = useTranslation('crossSection');
    console.log(crossSectionItem);
    console.log(crossSectionItem?.Curves?.reduce((prev, curr) => prev.MaxCurve > curr.MaxCurve ? prev : curr).MinCurve );

    const [minCurve , setMinCurve] = useState<number>(crossSectionItem?.SelectedCurves?.reduce((prev, curr) => prev.MaxCurve > curr.MaxCurve ? prev : curr).MinCurve ?? 0);
    const [maxCurve , setMaxCurve] = useState<number>(crossSectionItem?.SelectedCurves?.reduce((prev, curr) => prev.MaxCurve < curr.MaxCurve ? prev : curr).MaxCurve ?? 0);
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    const { view, heightMap, setFactorVertical, depthRange, setDepthRange, dataDepthRange, factorVertical, setDataDepthRange, laneWidth,
        displacementVertical,jwtToken,setCrossSectionData,setLaneWidth } = useCrossSectionStore(state => ({
        displacementVertical:state.displacementVertical,
        view: state.view,
        depthRange: state.depthRange,
        setDepthRange: state.setDepthRange,
        setDataDepthRange: state.setDataDepthRange,
        dataDepthRange: state.dataDepthRange,
        laneWidth: state.laneWidth,
        factorVertical: state.factorVertical,
        heightMap: state.heightMap,
        setFactorVertical: state.setFactorVertical,
        jwtToken: state.jwtToken,
        setCrossSectionData:state.setCrossSectionData,
        setLaneWidth:state.setLaneWidth
    }));

    const mousePosition = useRef<MousePosition | null>(null);
    const [isLayerLoading, setIsLayerLoading] = useState(false);
    useUpdatedSizeMap(containerMap, map);

    const mousePositionHandler = (coordinate: number[] | undefined) => {
        if (!coordinate || !crossSectionItem){
            return '';
        }

        let displacementVerticalValue = 0;

        if (displacementVertical && displacementVertical[crossSectionItem.WellId!]){
            displacementVerticalValue = displacementVertical[crossSectionItem.WellId!];
        }

        // return ((coordinate[1] + displacementVerticalValue) * factorVertical).toFixed() + 'm';
        return ((coordinate[1] + displacementVerticalValue) ).toFixed() + 'm';
    };

    useUpdatedMap(map, selectedGenericCut, dictionaryWellLayers, mousePosition, crossSectionItem, mousePositionHandler, setIsLayerLoading);
    useEffect(()=>{
        if (crossSectionItem){
            if (crossSectionItem.Curves){setSelectedCurve(crossSectionItem.Curves)};
            if (crossSectionItem.Scale){setSelectedScaleType(crossSectionItem.Scale)}
            //if (crossSectionItem.Colors) {setCurveColor(crossSectionItem.Colors)};
            if (crossSectionItem.CurvesMax){setMaxCurve(crossSectionItem.CurvesMax)}
            if (crossSectionItem.CurvesMin){setMinCurve(crossSectionItem.CurvesMin)}
            if (crossSection.LaneWidth) {setLaneWidth(crossSection.LaneWidth)}
            if (crossSection.FactorVertical) {setFactorVertical(crossSection.FactorVertical)}
            if (crossSectionItem.GenericCut){setSelectedGenericCut(crossSectionItem.GenericCut)}
        }
    },[]);
    useEffect(() => {
        // if (crossSectionItem?.GenericCut){
        //     setSelectedGenericCut(crossSectionItem?.GenericCut);
        // }
        if (!selectedGenericCut){
            return;
        }

        const extent = buildExtent(laneWidth, selectedGenericCut, factorVertical);
        let newWellLayers:IDictionaryWellLayers = {};
        for (let key in dictionaryWellLayers){
            newWellLayers[key] = dictionaryWellLayers[key];
        }
        if (!dictionaryWellLayers[selectedGenericCut.GenericCutPathToken] || selectedGenericCut.GenericCutType === 'log'){
            let url = '';
            let neumonics:string[] = [];
            let logicalFiles:string[] = [];
            let frames:string[] = [];
            let paths:string[] = [];
            if (selectedGenericCut.GenericCutType === 'log'){
                selectedCurve?.forEach(curve =>{
                    let frame = curve.FrameName;
                    neumonics.push(curve.Curve);
                    paths.push(curve.PathViewer);

                    if (curve.LogicalFile.toLowerCase().indexOf('logicalfile(') === -1 ){
                        curve.LogicalFile = `LogicalFile(${curve.LogicalFile})`;
                    }

                    logicalFiles.push(curve.LogicalFile);
                    if (curve.FrameName.indexOf('\\[') === -1){
                        if (curve.FrameName.indexOf('[') > -1) {
                            frame = frame.replace('[','\\[');
                        }
                    }

                    if (curve.FrameName.indexOf('\\]') === -1) {
                        if (curve.FrameName.indexOf(']') > -1) {
                            frame = frame.replace(']','\\]');
                        }
                    }
                    frames.push(frame);
                });

                // if (checkIfWellIdOrPathS3 && selectedCurve){
                //     let catalogCurve = selectedCurve[0];
                //     neumonic = neumonics.join('|');
                //     logicalFile = catalogCurve.LogicalFile;
                //     frames = catalogCurve.FrameName;

                // }
                console.log(selectedScaleType);
                url = `${tenantConfig?.endpoints.wellRender}/well/PlotCurvesByNeumonic?token=${jwtToken}&neumonic=${neumonics.join('|')}&logical_file=${logicalFiles.join('|')}&frame=${frames.join('|')}&color=${curveColor.join('|')}&width=${extent[2] - extent[0]}&height=${(extent[1] * -1 - extent[3] * -1) }&start_depth=${selectedGenericCut.StartDepth}&end_depth=${selectedGenericCut.EndDepth}&scale_type=${selectedScaleType.value}&paths=${paths.join('|')}&min_curve=${minCurve}&max_curve=${maxCurve}`;
                if (selectedCurve){
                    if (crossSectionItem){
                        crossSectionItem.Curves = [...selectedCurve];
                        crossSectionItem.Scale = selectedScaleType;
                        crossSectionItem.Colors = curveColor;
                        crossSectionItem.GenericCut = selectedGenericCut;
                        crossSectionItem.CurvesMin = minCurve;
                        crossSectionItem.CurvesMax = maxCurve;
                    }

                    crossSection.LaneWidth = laneWidth;
                    crossSection.FactorVertical = factorVertical;
                }
                window.localStorage.setItem(getParamsFromUrl().wellId,JSON.stringify(crossSection));
            }

            let displacementVerticalValue = 0;
            if (crossSectionItem) {
                if (displacementVertical && displacementVertical[crossSectionItem.WellId!]){
                    displacementVerticalValue = displacementVertical[crossSectionItem.WellId!];
                }
                extent[1] = extent[1] + displacementVerticalValue;
                extent[3] = extent[3] + displacementVerticalValue;
            }
            newWellLayers[selectedGenericCut.GenericCutPathToken] = new ImageLayer({
                visible: true,
                source: buildStaticSource(selectedGenericCut, extent, setIsLayerLoading,url),
            });

            setDictionaryWellLayers(newWellLayers);
        }

        if (!map){
            mousePosition.current = buildMousePosition(crossSectionItem?.Token, mousePositionHandler);
            console.log(`criando mapa no well section lane`);
            const newMap = new Map({
                controls: defaultControls({attribution: false, zoom:false}),
                target: `map${crossSectionItem?.Token}`
            });

            if (crossSection.LaneType === LaneType.CrossSection )
            {
                newMap.addControl(mousePosition.current);
            }
            console.log(selectedGenericCut);
            setMap(newMap);
            newMap.addLayer(newWellLayers[selectedGenericCut.GenericCutPathToken]);
        } else {
            map.getLayers().removeAt(0);
            map.getLayers().insertAt(0, newWellLayers[selectedGenericCut.GenericCutPathToken]);
        }

        if (view && map){
            // Listen for changes to the center coordinate of the map view
            console.log(`entrando no if do well sectionlane`);
            map.setView(view);
        }

        if (selectedGenericCut.StartDepth < dataDepthRange.minDepth || selectedGenericCut.EndDepth > dataDepthRange.maxDepth)
        {
            setDataDepthRange({
                minDepth: selectedGenericCut.StartDepth,
                maxDepth: selectedGenericCut.EndDepth
            });
        };

        if (selectedGenericCut.WellStartDepth < depthRange.minDepth || selectedGenericCut.WellEndDepth > depthRange.maxDepth)
        {
            setDepthRange({
                minDepth: selectedGenericCut.WellStartDepth,
                maxDepth: selectedGenericCut.WellEndDepth
            });
        };

    }, [selectedGenericCut,laneWidth,factorVertical,curveColor,selectedScaleType,selectedCurve,minCurve,maxCurve]);

    const { isLoading: isLoadingAnnotations } = useAnnotations(selectedGenericCut, map);

    useEffect(() => {
        if (view && map){
            view.on('change:center', function(event) {
                // Get the current center coordinate of the map view
                let center = view.getCenter();
                // Adjust the center coordinate to only allow vertical movement
                if (center){
                    let constrainedCenter = [laneWidth/2, center[1]];
                    // Set the adjusted center coordinate on the map view
                    if (center[0] !== constrainedCenter[0]){
                        view.setCenter(constrainedCenter);
                    }
                }
            });
            console.log(`entrando no 2 if do well section lane`);
            map.setView(view);
        }
    }, [view, map]);

    useEffect(() => {
        map?.updateSize();
    }, [laneWidth]);

    const fit = () => {
        if (!selectedGenericCut){
            return;
        }

        const factorVertical = calculateVerticalFitByDepths(heightMap, selectedGenericCut.StartDepth, selectedGenericCut.EndDepth);
        setFactorVertical(factorVertical);
    };

    const depthLabel = selectedGenericCut?.StartDepth ? `${selectedGenericCut?.StartDepth.toFixed(0)} ${selectedGenericCut?.Unit ? selectedGenericCut?.Unit : `m`} | ${selectedGenericCut?.EndDepth.toFixed(0)} ${selectedGenericCut?.Unit ? selectedGenericCut?.Unit : `m`}` : '|';
    const closeLane = ()=>{
        if (crossSectionItem){
            const idx = crossSection.Itens?.indexOf(crossSectionItem) ?? 0;

            crossSection.Itens?.splice(idx,1);
            setCrossSectionData(crossSection);
        }
    };
    const handleMinCurveChange = (value : number)=>{
        console.log(value);
        if (crossSectionItem?.CurvesMin){
            crossSectionItem.CurvesMin = value;
        }
    };
    const handleMaxCurveChange = (value : number)=>{
        console.log(value);
        if (crossSectionItem?.CurvesMax){
            crossSectionItem.CurvesMax = value;
        }
    };
    const checkIfWellIdOrPathS3 = getParamsFromUrl().wellId || getParamsFromUrl().pathS3 ? true : false;
    const changeCurveColor = (color:string,idx:number)=>{
        let colors = curveColor;
        colors[idx] = color;
        setCurveColor([...colors]);
    };
    return (
        <div css={style} key={crossSectionItem?.Token} style={{width: laneWidth}}>
            <div className='well-name-header'>
                <div className='well-name'>
                    <div className='lane-controls'>
                        <WellCurveCatalog wellId={getParamsFromUrl().wellId} crossSection={crossSection} crossSectionItem={crossSectionItem}  onChange={(curve,cut) => {console.log(curve);console.log(cut);setSelectedCurve(curve);setSelectedGenericCut(cut); if (curve.length>0) {setMinCurve(curve[0].MinCurve ?? 0);setMaxCurve(curve[0].MaxCurve ?? 0)} else {setMinCurve(0);setMaxCurve(0)} }}/>
                        <WellScaleSelect value={selectedScaleType}onChange={setSelectedScaleType}/>
                    </div>

                    <div>
                        <span>{crossSectionItem?.WellName}</span>
                    </div>
                    {crossSection.LaneType === LaneType.LogCurve &&
                        <Tooltip title="Click to remove this panel">
                            <IconButton sx={{color:'white'}} className='close-lane' onClick={closeLane}>
                                <DeleteForeverIcon></DeleteForeverIcon>
                            </IconButton>
                        </Tooltip>
                    }
                </div>

                {crossSection.LaneType === LaneType.CrossSection &&
                    <div className='well-info'>
                        <span title={depthLabel}>
                            <Typography noWrap className='label-depth'>
                                <strong className='label'>Depth </strong>
                                {depthLabel}
                            </Typography>

                        </span>
                        <div>
                            <WellTrackingSelect
                                onChange={(tracking) => setSelectedGenericCut(tracking)}
                                crossSectionItemToken={crossSectionItem?.Token }/>
                        </div>
                    </div>}

                <div>
                    {crossSection.LaneType === LaneType.LogCurve &&
                        <div className='curve-selection-div'>
                            {/* <WellScaleSelect onChange={(scaleType) => setSelectedScaleType(scaleType)}/> */}
                            <div className='curveInterval'>
                                <FormControl>
                                    <TextField type="number" id='minCurveInput' size='small' label={'Min Curve'} variant="outlined"  value={minCurve} onChange={(val)=> setMinCurve(Number(val.target.value))}></TextField>
                                </FormControl>

                                {/* <WellMinMaxCurve isMin={true} value={minCurve} ></WellMinMaxCurve> */}
                                {/* <WellMinMaxCurve isMin={false} value={maxCurve} ></WellMinMaxCurve> */}
                                <FormControl>
                                    <TextField type="number" id='maxCurveInput' size='small' label={'Max Curve'} value={maxCurve} onChange={(val)=> setMaxCurve(Number(val.target.value))}></TextField>
                                </FormControl>

                            </div>
                            {/* {checkIfWellIdOrPathS3
                                ? <WellCurveCatalog wellId={getParamsFromUrl().wellId} crossSection={crossSection} crossSectionItem={crossSectionItem}  onChange={(curve,cut) => {setSelectedCurve(curve);setSelectedGenericCut(cut);setMinCurve(curve[0].MinCurve);setMaxCurve(curve[0].MaxCurve)} }/>
                                : <NeumonicSelect
                                    crossSection={crossSection}
                                    onChange={(curve,cut) => {setSelectedCurve(curve);setSelectedGenericCut(cut)}}/>
                            } */}

                            <div className='color-unit-div'>
                                {selectedCurve && selectedCurve.map((curve,idx) =>
                                    <Stack key={curve.Curve+curve.FrameName+curve.LogicalFile} style={{width:'100%'}} direction='row' spacing={3} justifyContent="space-between" alignItems="center">
                                        <Box component="span">{Number(curve.MinCurve).toFixed(2)}</Box>
                                        <Box>
                                            {curve.Curve}
                                            <CrossSectionColorPicker color={crossSectionItem?.Colors ? crossSectionItem?.Colors[idx] : ''} onChange={(color)=>changeCurveColor(color.replace('#',''),idx)}/>
                                        </Box>
                                        <Box component="span">{Number(curve.MaxCurve).toFixed(2)}</Box>
                                    </Stack>
                                )}
                            </div>
                        </div>
                    }

                </div>

                {crossSection.LaneType === LaneType.CrossSection &&
                <div>
                    <div>
                        <span id={`mouse${crossSectionItem?.Token}`}></span>
                    </div>
                    <div>
                        <a target="_blank"
                            href={`${crossSection.ProjectUrl}/Well/Viewer?token=${selectedGenericCut?.CompositeProfileToken}`}
                            rel="noreferrer"
                            title={t('compositeProfile.open')}
                        >
                            <IconButton >
                                <Metadata />
                            </IconButton>
                        </a>

                        <IconButton title={t('fit')} onClick={fit}>
                            <FitPage />
                        </IconButton>

                    </div>
                </div>
                }

            </div>
            <div className='well-container'>
                <LoadingBlock open={isLoadingAnnotations || isLayerLoading}/>

                <div id={`map${crossSectionItem?.Token}`} className='mapa' style={{width: laneWidth}} ref={containerMap}></div>
            </div>
        </div>
    );
}

interface IProps {
    crossSection: ICrossSection,
    crossSectionItem?: ICrossSectionItem,
    initialCurve? : IWellCatalog[]|null
}