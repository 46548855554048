import { create } from 'zustand';

import { LineType } from '../models/enums/LineType';
import { getLineIdentifier } from '../utils/line3DNavigationUtils';
import { getParamsFromUrl } from '../utils/seismicUrlUtils';

export interface ILine3DNavigation {
    currentIncrement: number;
    lineIdentifier: string;
    lineNumber: number;
    lineType: LineType;
    loading: boolean;
    setCurrentIncrement: (value: number) => void;
    setLineIdentifier:(lineType: LineType, lineNumber: number) => void;
    setLineNumber: (value: number) => void;
    setLineType: (type: LineType) => void;
    setLoading: (value: boolean) => void;
}

export const useLine3DNavigationStore = create<ILine3DNavigation>()(
    (set, get) => ({
        currentIncrement: 5,
        lineIdentifier: '',
        lineType: getParamsFromUrl().lineType,
        lineNumber: 1,
        loading: false,
        setCurrentIncrement: (value: number) => set({ currentIncrement: value  }),
        setLineIdentifier: (lineType: LineType, lineNumber: number) => set({ lineIdentifier: getLineIdentifier(lineType, lineNumber)}),
        setLineNumber: (lineNumber: number) => set({ lineNumber: lineNumber }),
        setLineType: (type: LineType) => set({ lineType: type, lineNumber: 0 }),
        setLoading: (value: boolean) => set({ loading: value})
    })
);
