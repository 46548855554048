import { Fragment, useContext, useEffect, useRef, useState, useMemo } from 'react';
import { AppBar, Box, css, IconButton, Stack, ThemeProvider, Toolbar, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGeopostThemeAppBar } from 'theme';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useTheme } from '@emotion/react';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LogoutIcon from '@mui/icons-material/Logout';
import { shallow } from 'zustand/shallow';

import { useSessionStore } from 'session/useSessionStore';
import { SeismicShowCoordinates } from './SeismicShowCoordinates';
import { SeismicLayerLoadingProgress } from './SeismicLayerLoadingProgress';
import { SeismicNavbarTraceHeader } from './SeismicNavbarTraceHeader';
import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { ColorBarLegend } from './ColorBarLegend';
import { useMapStore } from '../stores/useMapStore';
import { SeismicGraticule } from './SeismicGraticule';
import { SeismicSelectorLine } from './SeismicSelectorLine';
import { SeismicLogoNavbar } from './SeismicLogoNavbar';
import { IRelatedDataImages, useSeismicStore } from '../stores/useSeismicStore';
import { SeismicMetadataNavbar } from './metadata/SeismicMetadataNavbar';
import { SeismicMetadataPopup } from './metadata/SeismicMetadataPopup';
import { SeismicColorbarNavbar } from './SeismicColorbarNavbar';
import { SeismicScaleNavbar } from './SeismicScaleNavbar';
import { SeismicProjections } from './projections/SeismicProjections';
import { SeismicMiniMap } from './worldMap/SeismicMiniMap';
import { SeismicMiniMapPopup } from './worldMap/SeismicMiniMapPopup';
import { SeismicAttributes } from './attributes/SeismicAttributes';
import { SeismicWell } from './wells/SeismicWell';
import { SeismicWellPopup } from './wells/SeismicWellPopup';
import { SeismicWellRelatedDataPopup } from './wells/SeismicWellRelatedDataPopup';
import { SeismicImagePopup } from './wells/SeismicImagePopup';
import { SeismicWellHoverInfo } from './wells/SeismicWellHoverInfo';
import { IRelatedData } from '../models/interfaces/IRelatedData';
import { SeismicInterpretationNavbar } from './interpretation/SeismicInterpretationNavbar';
import { SeismicWebTour } from './webTour/SeismicWebTour';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { SurveyType } from '../models/enums/SurveyType';
import { SeismicInterpretation2DViewer } from './interpretation/components/SeismicInterpretation2DViewer';
import { AlinePopup } from 'features/tenant-customized/components/AlinePopup';
import { GainMenu2D } from './GainMenu2D';
import { SeismicAttributesPopup } from './attributes/SeismicAttributesPopup';
import { SeismicAttribute } from '../models/interfaces/ISurveyMetadata';
import { GeopostMuiPopup } from 'components/geopostMuiPopup/GeopostMuiPopup';
import { PerformancePanel } from './performancePanel/PerformancePanel';
import { getParamsFromUrl } from '../utils/seismicUrlUtils';
import { SeismicLine2DSelector } from './line2dSelector/SeismicLine2DSelector';
import { useLoadingTileStore } from '../stores/useLoadingTilesStore';
import { SeismicKeyboard } from './keyboard/components/SeismicKeyboard';
import { LineType } from '../models/enums/LineType';
import { useLine3DNavigationStore } from '../stores/useLine3DNavigationStore';
import { SeismicNavbarLoadingIcon } from './SeismicNavbarLoadingIcon';
import { LogoutButton } from './LogoutButton';
import { SeismicAntialiasNavbar } from './SeismicAntialiasNavbar';
import { Position } from 'components/geopostPopup/models/enums/Position';

const styles = css({
    '.container-loading': {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: '#00000099',
        zIndex: 9,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& > *': {
            animation: 'fade 2s linear',
            animationIterationCount: 'infinite'
        }
    },
    '.MuiToolbar-root': {
        paddingTop: '5px',
        minHeight: '56px'
    },
    '& .MuiToolbar-root > *': {
        margin: 0,
    },
    '.link-button': {
        cursor: 'pointer',
        textDecoration: 'none',
        marginLeft: '10px',
        marginRight: '10px'
    },
    '.link-button: visited': {
        color: 'inherit'
    },
    '@keyframes fade': {
        '0%,100%': { opacity: 0},
        '50%': { opacity: 1}
    }
});

export function SeismicNavbar() {
    const { t } = useTranslation();
    const themeAppBar = useGeopostThemeAppBar();
    const { mapSize, swipeSeismic, geopostMap } = useMapStore(state => ({
        mapSize: state.mapSize,
        swipeSeismic: state.swipeSeismic,
        geopostMap: state.map
    }), shallow);
    const theme = useTheme();
    const { hasErrorOnGeometry, surveyMetadata, volumeTokenSwipe, storeWellRelatedData, wellHoverInfo,
        geometryLine: dataGeometryLine, restrict, navbarLoading} = useSeismicStore(state => ({
        restrict: state.restrict,
        hasErrorOnGeometry: state.hasErrorOnGeometry,
        geometryLine: state.geometryLine,
        surveyMetadata: state.surveyMetadata,
        storeWellRelatedData: state.wellRelatedData,
        wellHoverInfo: state.wellHoverInfo,
        volumeTokenSwipe: state.volumeTokenSwipe,
        navbarLoading: state.navbarLoading
    }), shallow);

    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }), shallow);

    const [openedMetadata, setOpenedMetadata] = useState(false);
    const [openedLabelColorbar, setOpenedLabelColorbar] = useState(false);
    const [openedMiniMap, setOpenedMiniMap] = useState(false);
    const [openedInterpretation, setOpenedInterpretation] = useState(false);
    const [openedWell, setOpenedWell] = useState(false);
    const [openedAttributes, setOpenedAttributes] = useState(false);
    const [openPerformanceMonitor, setOpenPerformanceMonitor] = useState(false);

    const selectedColorbar = useSeismicStore(state => state.colorbar);

    const [seismicAttributes, setSeismicAttributes] = useState<{
        baseProductName: string,
        all: SeismicAttribute[]
    } | undefined>(undefined);

    const updateSwipe = (value: number) => {
        if (geopostMap && swipeSeismic && volumeTokenSwipe !== '-') {
            swipeSeismic.updateParams({
                swipeValue: value
            });
            geopostMap.render();
        }
    };

    const wellRelatedData = useRef<{
        [key: string]: {
            layer: VectorLayer<VectorSource>;
            relatedData: IRelatedData;
            opened: boolean;
            images: IRelatedDataImages;
        }
    } | null>(null);

    useEffect(() => {
        if (storeWellRelatedData) {
            wellRelatedData.current = storeWellRelatedData;
        }
    }, [storeWellRelatedData]);

    // useFeatureHoverWells(wellRelatedData);

    const { featureFlags } = useFeatureFlags();

    useEffect(() => {
        if (featureFlags) {
            if (featureFlags.seismic2D.DefaultMiniMapOn) {
                setOpenedMiniMap(true);
            }
        };
    }, [featureFlags]);

    //useMapSignalR(dataGeometryLine);

    let surveyName = '';

    if (surveyMetadata && surveyMetadata.DisplayName && surveyMetadata.DisplayName.trim()) {
        surveyName = surveyMetadata.DisplayName;

        if (surveyName.length > 45) {
            surveyName = surveyName.substring(0, 45) + '...';
        }
    }

    const { current: urlParams } = useRef(getParamsFromUrl());

    const lineType = useLine3DNavigationStore(state => state.lineType);

    const hasErrorInMetadata = surveyMetadata?.Error;

    const defaultColorbarLegendOn = useMemo(() => featureFlags?.seismic2D.DefaultColorbarLegendOn, [featureFlags]);
    useEffect(() => {
        if (defaultColorbarLegendOn) {
            setOpenedLabelColorbar(defaultColorbarLegendOn);
            console.log('caiu no fluxo default colorbar on');
        }
    }, [defaultColorbarLegendOn]);

    return (
        <Fragment>
            <AppBar position='static' css={styles}>
                <Toolbar style={{ paddingLeft: '15px', paddingRight: '20px' }}>
                    <SeismicNavbarLoadingIcon show={navbarLoading}/>
                    <ThemeProvider theme={themeAppBar}>
                        <Stack direction='row' justifyContent={'space-between'} alignItems='center' width='100%'>
                            <Stack direction='row'>
                                <Stack alignItems='center' justifyContent='center' sx={{marginRight: '15px'}}>
                                    <SeismicLogoNavbar />
                                </Stack>
                                {featureFlags?.seismic2D.WebTour && !hasErrorInMetadata && <SeismicWebTour />}
                                {tenantConfig?.appBar.displayNamePosition === 'left' ?
                                    <Stack sx={{maxWidth:'285px'}} alignItems='center' justifyContent='center'>
                                        <Typography title={surveyMetadata?.DisplayName ?? ''} style={{ marginLeft: 10, marginRight: 25, fontSize: 12, width:'285px', maxWidth: '285px', whiteSpace: 'nowrap' }}>
                                            {surveyName}
                                        </Typography>
                                    </Stack>
                                    : null}
                                {featureFlags?.seismic2D.EnableParentAppLink && tenantConfig &&
                                    <Stack justifyContent='center'>
                                        <a className='link-button' target='_blank' href={tenantConfig.parentApplicationHost} title={tenantConfig.parentApplicationName}>{tenantConfig.parentApplicationName}</a>
                                    </Stack>
                                }

                                { !hasErrorInMetadata && <SeismicMetadataNavbar opened={openedMetadata} setOpened={setOpenedMetadata} />}
                                {featureFlags?.seismic2D.ColorBar && !hasErrorInMetadata && <SeismicColorbarNavbar openedLegendColorbar={openedLabelColorbar} setOpenedLegendColorbar={setOpenedLabelColorbar} />}
                                {!hasErrorInMetadata && <SeismicScaleNavbar />}
                                {featureFlags?.seismic2D.Antialias && <SeismicAntialiasNavbar/>}
                                {featureFlags?.seismic2D.LineType && !hasErrorInMetadata && <Stack alignItems='center' justifyContent='center'><SeismicSelectorLine featureFlags={featureFlags?.seismic2D} /></Stack>}
                                <ThemeProvider theme={theme}>
                                    {featureFlags?.seismic2D.Gain && !hasErrorInMetadata && <GainMenu2D/>}
                                </ThemeProvider>
                                {featureFlags?.seismic2D.TraceHeader && !hasErrorInMetadata && <SeismicNavbarTraceHeader />}
                                {featureFlags?.seismic2D.Graticule && !hasErrorInMetadata && <SeismicGraticule dataGeometryLine={dataGeometryLine} />}
                                {featureFlags?.seismic2D.Projections && !hasErrorInMetadata && restrict?.SeismicCanDisplayProjections && <SeismicProjections />}
                                {featureFlags?.seismic2D.Attributes && !hasErrorInMetadata && restrict?.SeismicDisplayPhysicalAttributes && <SeismicAttributes setOpenedPopup={setOpenedAttributes} setSelectedAttributes={setSeismicAttributes}/>}
                                {featureFlags?.seismic2D.LineSelector2D &&  !hasErrorInMetadata && surveyMetadata?.Type === SurveyType.Seismic2D && <SeismicLine2DSelector />}
                                {featureFlags?.seismic2D.EnableWells && !hasErrorInMetadata && <SeismicWell opened={openedWell} setOpened={setOpenedWell} dataGeometryLine={dataGeometryLine} />}
                                {featureFlags?.seismic2D.Interpretation && !hasErrorInMetadata && <SeismicInterpretationNavbar opened={openedInterpretation} setOpened={setOpenedInterpretation} />}
                                {featureFlags?.seismic2D.Minimap && restrict?.SeismicCanDisplayNavigationMap && !hasErrorInMetadata && <SeismicMiniMap hasErrorOnGeometry={hasErrorOnGeometry} opened={openedMiniMap} setOpened={setOpenedMiniMap} dataGeometryLine={dataGeometryLine} />}
                                <ThemeProvider theme={theme}>
                                    {featureFlags?.seismic2D.AlineWorkflow && <AlinePopup></AlinePopup>}
                                </ThemeProvider>
                                {
                                    featureFlags?.seismic2D.PerformanceMonitor
                                    &&
                                    urlParams.performanceMonitoring
                                    &&
                                    <IconButton sx={{color: 'white'}} onClick={() => setOpenPerformanceMonitor(current => !current)} title='Performance monitor'>
                                        <QueryStatsIcon/>
                                    </IconButton>
                                }
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                {
                                    (!!featureFlags?.seismic2D.Logout)
                                    &&
                                    <LogoutButton/>
                                }
                                {!tenantConfig?.appBar.displayNamePosition || tenantConfig?.appBar.displayNamePosition === 'right' ?
                                    <Stack sx={{maxWidth:'800px'}} alignItems='center' justifyContent='center'>
                                        <Typography title={surveyMetadata?.DisplayName ?? ''} style={{ fontSize: 12, maxWidth: '800px', width:'auto', textAlign:'center'}}>
                                            {surveyName}
                                        </Typography>
                                    </Stack>
                                    : null}
                                {featureFlags?.seismic2D.Depth &&
                                    <Box component='div' style={{ float: 'right', alignItems: 'center', justifyContent:'center', display:'flex' }} >
                                        <SeismicShowCoordinates />
                                    </Box>
                                }
                            </Stack>
                        </Stack>
                    </ThemeProvider>
                </Toolbar>
            </AppBar>
            <SeismicLayerLoadingProgress />

            <SeismicMetadataPopup opened={openedMetadata} setOpened={setOpenedMetadata} surveyMetadata={surveyMetadata} />
            <SeismicMiniMapPopup opened={openedMiniMap} setOpened={setOpenedMiniMap} dataGeometryLine={dataGeometryLine} />
            <SeismicInterpretation2DViewer opened={openedInterpretation} setOpened={setOpenedInterpretation} />
            <SeismicWellPopup opened={openedWell} surveyType={SurveyType.Seismic2D} setOpened={setOpenedWell} dataGeometryLine={dataGeometryLine ? dataGeometryLine : { Line: '', Max: 100, Min: 0, TotalTraces: 100 }} />
            {Object.keys(storeWellRelatedData).map((key) => (
                <Fragment key={key}>
                    <SeismicWellRelatedDataPopup key={key} myKey={key} data={storeWellRelatedData[key]} />
                    {Object.keys(storeWellRelatedData[key].images).map((imageKey) => (
                        <SeismicImagePopup key={imageKey} parentKey={key} parentData={storeWellRelatedData[key]} imageKey={imageKey} />
                    ))}
                </Fragment>
            ))}
            {wellHoverInfo.created &&
                <SeismicWellHoverInfo data={wellHoverInfo} />
            }
            <SeismicAttributesPopup opened={openedAttributes} setOpened={setOpenedAttributes} seismicAttributes={seismicAttributes} />

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openedLabelColorbar}
                title={selectedColorbar.Label}
                onChangeOpen={value => setOpenedLabelColorbar(value)}
                overlay={false}
                height={500}
                width={105}
                minWidth={100}
                position={Position.middleLeft}
            >
                <ColorBarLegend />
            </GeopostPopup>
            {
                volumeTokenSwipe !== '-' ?
                    <Box component='div'>
                        <input id="swipe" onChange={(evt) => updateSwipe(parseInt(evt.target.value))} type="range" style={{ width: '100%' }} />
                    </Box>
                    : null
            }

            {
                featureFlags?.seismic2D.PerformanceMonitor
                &&
                urlParams.performanceMonitoring
                &&
                <GeopostMuiPopup
                    open={openPerformanceMonitor}
                    onClose={() => setOpenPerformanceMonitor(false)}
                    height={600}
                    width={1450}
                    left={0}
                    top={64}
                    title="Performance monitor"
                >
                    <PerformancePanel surveyType={SurveyType.Seismic2D}/>
                </GeopostMuiPopup>
            }
            {featureFlags?.seismic2D.Shortcuts && <SeismicKeyboard />}

        </Fragment>
    );
}