import { Box, Button, TextField, Tooltip, Typography, Zoom } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Fragment, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import alertify from 'alertifyjs';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';
import { MainTableCss } from 'fileIndexing/envVar/envVar';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { InstitutionsForm } from './InstitutionsForm';
import { IInstitution } from '../interfaces/IInstitution';
import { useDeleteInstitution, useGetAllInstitutions, useGetInstitutionByToken } from '../useInstitution';

export function InstituitionsTable() {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<IInstitution | null>(null);

    const [editToken, setEditToken] = useState<string>('');
    const [deleteToken, setDeleteToken] = useState<string>('');

    const [filteredData, setFilteredData] = useState<IInstitution[] | undefined>([]);
    const [filter, setFilter] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    const { data: institutionsData, isLoading: institutionsDataLoading, refetch } = useGetAllInstitutions();
    const { data: institutionGetByToken } = useGetInstitutionByToken(editToken);
    const { data: deleteInstituitionData } = useDeleteInstitution(deleteToken);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleOpenEdit = (rowData: IInstitution) => {
        setEditToken(rowData.Token);
    };

    const handleDelete = (name: string, token: string) => {
        alertify.confirm(`Do you really wanna delete ${name}?`, async () => {
            setDeleteToken(token);
        });
    };

    // reseta as informações preenchidas no modal
    useEffect(() => {
        if (!openModal) {
            setModalData(null);
        }
    }, [openModal]);

    // seta as informações para edit
    useEffect(() => {
        if (institutionGetByToken) {
            setModalData(institutionGetByToken);
            handleOpenModal();
            setEditToken('');
        }
    }, [institutionGetByToken]);

    // salva as informações de todas as linhas recebidas
    useEffect(() => {
        if (institutionsData) {
            setFilteredData(institutionsData);
        }
    }, [institutionsData]);

    // realiza o filtro
    useEffect(() => {
        if (institutionsData) {
            if (filter === '') {
                setFilteredData(institutionsData);
            }
            else {
                setFilteredData(institutionsData.filter(x => x.Name?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Type?.toLowerCase().startsWith(filter.toLowerCase())));
            }
        }
    }, [filter]);

    useEffect(() => {
        if (deleteInstituitionData === 'Success') {
            refetch();
        }
    }, [deleteInstituitionData]);

    // conteúdo da tabela
    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            headerAlign: 'left',
            flex: 1,
        },
        {
            field: 'Type',
            headerName: 'Type',
            headerAlign: 'left',
            flex: 1,
        },
        {
            field: 'Options',
            headerName: 'Options',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <Box className='options-buttons'>
                    <Tooltip title='Edit institution metadata' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' onClick={() => handleOpenEdit(params.row)}>
                            <EditNoteIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Delete institution' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='error' className='delete-icon' onClick={() => handleDelete(params.row.Name, params.row.Token)}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    const getRowId = (row: IInstitution) => row.Token;

    return (
        <Fragment>
            <Box css={MainTableCss}>
                <Box className='table-header'>
                    <Typography className='table-header-title'>Institutions</Typography>
                    <Box className='search'>
                        <TextField label='Search institution' variant='outlined' size='small' color='primary' value={filter} onChange={(event) => setFilter(event.target.value)} />
                        <Button variant='contained' color='primary' className='add-icon' onClick={() => handleOpenModal()}>
                            <AddIcon />
                        </Button>
                    </Box>
                </Box>
                <GeopostTable<IInstitution> filteredData={filteredData} loading={institutionsDataLoading || loading} columns={columns} getRowId={getRowId} />
            </Box>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openModal}
                onChangeOpen={handleCloseModal}
                resizable={false}
                width={560}
                minHeight={260}
                height={260}
                maxHeight={500}
                title={modalData ? `Edit ${modalData.Name}` : 'Add Institution'}
                padding={'16px'}
            >
                <InstitutionsForm setOpenModal={setOpenModal} refetchTableData={refetch} setLoading={setLoading} formData={modalData} />
            </GeopostPopup>
        </Fragment>
    );
}