import { LineType } from '../enums/LineType';
import { SeismicCalculator } from './SeismicCalculator';
import { ISeismicCalculatorLineType } from '../interfaces/ISeismicCalculatorLineType';
import { SeismicCalculator3DIndexed } from './SeismicCalculator3DIndexed';
import { SeismicCalculatorInline } from './SeismicCalculatorInline';
import { SeismicCalculator3DUnindexed } from './SeismicCalculator3DUnindexed';
import { SeismicCalculatorXline } from './SeismicCalculatorXline';
import { SurveyType } from '../enums/SurveyType';
import { SeismicCalculator2DIndexed } from './SeismicCalculator2DIndexed';
import { SeismicCalculator2DUnIndexed } from './SeismicCalculator2DUnIndexed';
import { SeismicCalculatorZSlice } from './SeismicCalculatorZSlice';

export class SeismicCalculatorFactory{
    static buildCalculator(isIndexed: boolean, lineType: LineType, surveyType: SurveyType){
        let calculator:SeismicCalculator;

        if (lineType === LineType.ZSlice) {
            calculator = new SeismicCalculatorZSlice(new SeismicCalculatorInline(), new SeismicCalculatorXline());
        }
        else if (surveyType === SurveyType.Seismic3D){
            if (isIndexed){
                calculator = new SeismicCalculator3DIndexed();
            }
            else {
                calculator = new SeismicCalculator3DUnindexed();
            }
        }
        else {
            if (isIndexed){
                calculator = new SeismicCalculator2DIndexed();
            }
            else {
                calculator = new SeismicCalculator2DUnIndexed();
            }
        }

        SeismicCalculatorFactory.updateLineTypeCalculator(calculator, lineType);

        return calculator;
    }

    static updateLineTypeCalculator(calculator: SeismicCalculator, lineType: LineType){
        let calculatorLineType: ISeismicCalculatorLineType;

        if (lineType === LineType.Inline){
            calculatorLineType = new SeismicCalculatorInline();
        }
        else {
            calculatorLineType = new SeismicCalculatorXline();
        }

        calculator.setLineTypeCalculator(calculatorLineType);
    }
}