import { ReactNode } from 'react';
import { Alert, Box, Collapse, Slider, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import React from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';
import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { LineSlider } from './Lineslider';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { VolumeType } from 'features/seismic-3d/models/enums/VolumeType';
import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { LineType } from 'features/seismic/models/enums/LineType';
import { SampleIntervalUnity } from 'features/seismic/models/enums/SampleIntervalUnity';
import { useFeatureFlags } from 'features/seismic/hooks/useFeatureFlags';
import { use3DFeatureFlags } from 'features/seismic-3d/hooks/useSeismic3DFeatureFlags';

export const GridSlider = () => {
    const {
        selectedSeismicInfo,
        totalInlineNumbers,
        totalXlineNumbers,
        totalZsliceNumbers,
        selectedInlineNumber,
        selectedXlineNumber,
        selectedZsliceNumber,
        selectedSeismic3DInfo,
        isInlineSearchLoading,
        isXlineSearchLoading,
        isZsliceSearchLoading,
        setSearchedInlineNumber,
        setSearchedXlineNumber,
        setSearchedZsliceNumber,
        setIsInlineVisible,
        setIsXlineVisible,
        setIsZsliceVisible
    } = use3DGridStore(state => ({
        selectedSeismicInfo: state.gridSelectedSeismic,
        totalInlineNumbers: state.totalInlineNumbers,
        totalXlineNumbers: state.totalXlineNumbers,
        totalZsliceNumbers: state.totalZsliceNumbers,
        selectedInlineNumber: state.selectedInlineNumber,
        selectedXlineNumber: state.selectedXlineNumber,
        selectedZsliceNumber: state.selectedZsliceNumber,
        selectedSeismic3DInfo: state.selectedSeismic3DInfo,
        isInlineSearchLoading: state.isInlineSearchLoading,
        isXlineSearchLoading: state.isXlineSearchLoading,
        setSearchedInlineNumber: state.setSearchedInlineNumber,
        setSearchedXlineNumber: state.setSearchedXlineNumber,
        setSearchedZsliceNumber: state.setSearchedZsliceNumber,
        setIsInlineVisible: state.setIsInlineVisible,
        setIsXlineVisible: state.setIsXlineVisible,
        setIsZsliceVisible: state.setIsZsliceVisible,
        isZsliceSearchLoading: state.isZsliceSearchLoading,
    }), shallow);

    const volumeToken = use3DViewerStore(state => state.mainFeatureVolumeToken);

    const tenantToken = useSessionStore(state => state.tenantConfiguration?.tenantToken);

    const volumeDomain = use3DGridStore(state => state.volumeDomain);

    const scene = use3DSceneStore(state => state.scene);

    const [ showErrorAlert, setShowErrorAlert ] = useState<boolean>(false);

    const [ gridOpacity, setGridOpacity ] = useState<number>(1);

    const lineDetailsRoute = useMemo(() => `/seismic?forceDisableCache=false&volumeToken=${volumeToken}&tenantToken=${tenantToken}`, [volumeToken, tenantToken]);

    const featureFlags = use3DFeatureFlags();

    const sampleInterval = useMemo(() => {
        if (selectedSeismicInfo) {
            if (selectedSeismicInfo.sampleIntervalUnit === SampleIntervalUnity.Microsecond){
                return selectedSeismicInfo.sampleInterval/1000;
            } else {
                return selectedSeismicInfo.sampleInterval;
            }
        } else {
            return 0;
        }
    }, [selectedSeismicInfo]);

    const inlineDetailsRoute = useMemo(() => `${lineDetailsRoute}&lineNumber=${selectedInlineNumber}&lineType=${LineType.Inline}`, [lineDetailsRoute, selectedInlineNumber]);
    const xlineDetailsRoute = useMemo(() => `${lineDetailsRoute}&lineNumber=${selectedXlineNumber}&lineType=${LineType.Xline}`, [lineDetailsRoute, selectedXlineNumber]);
    const zsliceDetailsRoute = useMemo(() => `${lineDetailsRoute}&lineNumber=${selectedZsliceNumber}&lineType=${LineType.ZSlice}`, [lineDetailsRoute, selectedZsliceNumber]);

    const onInlineSliderChange = useCallback((event: Event, value: number | number[]) => {
        const selectedInline = totalInlineNumbers[value as number];
        setSearchedInlineNumber(selectedInline);
    }, [setSearchedInlineNumber, totalInlineNumbers]);

    const onXlineSliderChange = useCallback((event: Event, value: number | number[]) => {
        const selectedXline = totalXlineNumbers[value as number];
        setSearchedXlineNumber(selectedXline);
    }, [setSearchedXlineNumber, totalXlineNumbers]);

    const onZsliceSliderChange = useCallback((event: Event, value: number | number[]) => {
        const selectedZslice = totalZsliceNumbers[value as number];
        setSearchedZsliceNumber(selectedZslice);
    }, [setSearchedZsliceNumber, totalZsliceNumbers]);

    const onInlineInputSubmit = (value: number) => {
        if (isNaN(value) || isInlineSearchLoading) {
            return;
        }
        if (value < totalInlineNumbers[0] || value > totalInlineNumbers[totalInlineNumbers.length - 1]) {
            setShowErrorAlert(true);
        } else {
            setSearchedInlineNumber(value);
        }
    };

    const onXlineInputSubmit = (value: number) => {
        if (isNaN(value) || isXlineSearchLoading) {
            return;
        }

        if (value < totalXlineNumbers[0] || value > totalXlineNumbers[totalXlineNumbers.length - 1]) {
            setShowErrorAlert(true);
        } else {
            setSearchedXlineNumber(value);
        }
    };

    const onZsliceInputSubmit = (value: number) => {
        if (isNaN(value) || isXlineSearchLoading) {
            return;
        }

        if (value < totalZsliceNumbers[0] || value > totalZsliceNumbers[totalZsliceNumbers.length - 1]) {
            setShowErrorAlert(true);
        } else {
            setSearchedZsliceNumber(value);
        }
    };

    useEffect(() => {
        scene?.changeGridOpacity(gridOpacity);
    }, [gridOpacity, scene]);

    useEffect(() => {
        if (showErrorAlert) {
            setTimeout(() => setShowErrorAlert(false), 3500);
        }
    }, [showErrorAlert]);

    return (
        <React.Fragment>
            <Collapse in={showErrorAlert}>
                <Alert severity='error' onClose={() => setShowErrorAlert(false)}>Line out of range! Please select a line between the seismic range.</Alert>
            </Collapse>
            <Box sx={{backgroundColor: 'white', padding: '10px 20px 10px 20px'}} id='seismic-3d-lines-popup-content'>
                <Stack spacing={2}>
                    <Stack>
                        <Typography>Grid Opacity: {(gridOpacity * 100).toFixed() + '%'}</Typography>
                        <Slider
                            sx={{
                                width: '130px'
                            }}
                            id='grid-op-slider'
                            value={gridOpacity}
                            step={0.05}
                            min={0}
                            max={1}
                            onChange={(event, value) => setGridOpacity(value as number)}
                            valueLabelDisplay='off'
                        />
                    </Stack>
                    <Stack paddingRight={'10px'} spacing={2}>
                        <LineSlider
                            label='Selected Inline'
                            onLineInputSubmit={onInlineInputSubmit}
                            onSliderChange={onInlineSliderChange}
                            isSearchLoading={isInlineSearchLoading}
                            lineIncrement={selectedSeismic3DInfo?.InlineIncrement ?? 0}
                            selectedLineNumber={selectedInlineNumber}
                            totalLineNumbers={totalInlineNumbers}
                            detailsRoute={inlineDetailsRoute}
                            onVisibilityInputChange={setIsInlineVisible}
                        />
                        <LineSlider
                            label='Selected Xline'
                            onLineInputSubmit={onXlineInputSubmit}
                            onSliderChange={onXlineSliderChange}
                            isSearchLoading={isXlineSearchLoading}
                            lineIncrement={selectedSeismic3DInfo?.XlineIncrement ?? 0}
                            selectedLineNumber={selectedXlineNumber}
                            totalLineNumbers={totalXlineNumbers}
                            detailsRoute={xlineDetailsRoute}
                            onVisibilityInputChange={setIsXlineVisible}
                        />
                        {
                            featureFlags?.enableZslice
                            &&
                            <LineSlider
                                label={`Selected ${volumeDomain === AmplitudeDomain.Depth ? 'Depth' : 'Time'}`}
                                onLineInputSubmit={onZsliceInputSubmit}
                                onSliderChange={onZsliceSliderChange}
                                totalLineNumbers={totalZsliceNumbers}
                                onVisibilityInputChange={setIsZsliceVisible}
                                selectedLineNumber={selectedZsliceNumber}
                                detailsRoute={zsliceDetailsRoute}
                                isSearchLoading={isZsliceSearchLoading}
                                lineIncrement={sampleInterval}
                            />
                        }
                    </Stack>
                </Stack>
            </Box>
        </React.Fragment>
    );
};

type LineInfoTextProps = {
    children: ReactNode
};

const LineInfoText = ({ children } : LineInfoTextProps) => (<Typography variant='caption'  color='white'>{children}</Typography>);