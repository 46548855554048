import { Box, Checkbox, CircularProgress, IconButton, MenuItem, Popover, Typography, css } from '@mui/material';
import { useEffect, useState, ReactNode } from 'react';
import { CloudDownload, Settings, Create, Delete, Remove, Check, Error } from '@mui/icons-material';
import alertify from 'alertifyjs';
import { useTranslation } from 'react-i18next';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

import { useDelete, useExport } from '../api/useInterpretationController';
import { IInterpretation } from '../models/interfaces/IInterpretation';
import { InterpretationMode } from '../models/enums/InterpretationMode';
import { IUseInterpretation } from '../models/interfaces/IUseInterpretation';
import { InterpretationExportMode } from '../models/enums/InterpretationExportMode';
import { useExportInterpretation } from '../hooks/useExportInterpretation';
import { useSessionStore } from 'session/useSessionStore';

const colorEditMode = '#4caf50';
const colorEraseMode = '#FF5722';

const styles = css({
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',

    '& .container-icons': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    },
    '& .label': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 270
    },
    '& .item-icon-error': {
        marginRight: 5
    }
});

export function SeismicItemInterpretation({ interpretation, onEdit , refresh, interpretationSettings}: Props) {
    const { t } = useTranslation(['common', 'seismic']);

    const mode = interpretationSettings.informations[interpretation.Token]?.mode ?? InterpretationMode.view;

    const { mutateAsync:deleteMutation, isLoading: isLoadingDelete } = useDelete();

    const sessionTenantToken = useSessionStore(state => state.tenantConfiguration?.tenantToken);

    const { exportInterpretation: exportInterpretationHook } = useExportInterpretation();

    const [anchorPopoverExport, setAnchorPopoverExport] = useState<HTMLButtonElement | null>(null);

    const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorPopoverExport(event.currentTarget);
    };

    const handleExportClose = () => {
        setAnchorPopoverExport(null);
    };

    const deleteInterpretation = (interpretation: IInterpretation) => {
        let message = `${t('seismic:interpretation.confirmDelete')} '${interpretation.Name}'?`;
        alertify.confirm(message, async () => {
            const response = await deleteMutation(interpretation.Token);

            if (response.ok){
                if (interpretationSettings.remove){
                    interpretationSettings.remove(interpretation.Token);
                }
                refresh();
            }
            else if (response.permissionError) {
                alertify.alert(t('seismic:interpretation.deletePermissionError'));
            }
        });
    };

    const exportInterpretation = async (mode: InterpretationExportMode) => {
        try {
            alertify.notify(t('seismic:interpretation.export.init'), 'warning');

            await exportInterpretationHook(mode, interpretation.Token);

            alertify.notify(t('seismic:interpretation.export.finished'), 'success');
        }
        catch (exception){
            console.error(exception);
            alertify.notify(t('seismic:interpretation.export.error'), 'error');
        }
    };

    const updateCheck = (checked: boolean) => {
        if (checked){
            interpretationSettings.check(interpretation.Token);
        }
        else {
            interpretationSettings.uncheck(interpretation.Token);
        }
    };

    if (interpretationSettings.informations[interpretation.Token]?.isLoading || isLoadingDelete) {
        return <CircularProgress size={30} style={{ margin: '0 5px' }} />;
    }

    const backgroundColor = (mode === InterpretationMode.view) ? 'initial' : (mode === InterpretationMode.edit) ? colorEditMode : colorEraseMode;
    const displayCheckbox = (mode === InterpretationMode.view);

    const openExport = Boolean(anchorPopoverExport);
    const messageError = interpretationSettings.informations[interpretation.Token]?.errorMessage;

    //const hideDownloadButton = (sessionTenantToken === 'ab81b361-561e-4c0b-b515-2b085c46bcee');

    const allowEdit = interpretation.IsOwner;

    return <Box css={styles} sx={{ backgroundColor }}>
        <Box width={15} height={15} style={{ background: interpretation.Color }} sx={{ border: '1px solid #EEE' }} />
        <Checkbox
            disabled={!displayCheckbox}
            checked={interpretationSettings.informations[interpretation.Token]?.checked ?? false}
            size='small'
            style={{ width: 17, height: 17, margin: '0 5px', opacity: displayCheckbox ? 1 : 0, pointerEvents: displayCheckbox ? 'initial' : 'none' }}
            onChange={(event) => updateCheck(event.target.checked)} />
        { messageError && <Error className='item-icon-error' color='error' titleAccess={messageError}/>}
        <Typography title={interpretation.Name} className='label' fontSize={14}>{interpretation.Name}</Typography>
        {mode === InterpretationMode.view ? <Box className='container-icons'>
            { interpretationSettings.customIcons && interpretationSettings.customIcons.map(x=> (
                <IconButton key={x.key} onClick={() => x.click(interpretation.Token)} title={x.title}>
                    { x.element(interpretation.Token) }
                </IconButton>
            ))}
            <IconButton size='small' onClick={handleExportClick} title={t('common:export')}>
                <CloudDownload fontSize='small' />
            </IconButton>
            { allowEdit && <IconButton size='small' onClick={() => { onEdit(interpretation.Token) }} title={t('seismic:interpretation.editInfo')}>
                <Settings fontSize='small' />
            </IconButton>}
            {allowEdit && interpretationSettings.setMode && <IconButton size='small'
                title={t('seismic:interpretation.interpretate')}
                onClick={() => interpretationSettings.setMode!(interpretation.Token, InterpretationMode.edit)}>
                <Create fontSize='small' />
            </IconButton>}
            {allowEdit && <IconButton size='small'
                title={t('common:delete')}
                onClick={() => { deleteInterpretation(interpretation) }}>
                <Delete fontSize='small' />
            </IconButton>}
        </Box> : <Box className='container-icons'>
            <IconButton size='small' onClick={() => interpretationSettings.setMode!(interpretation.Token, mode === InterpretationMode.erase ? InterpretationMode.edit : InterpretationMode.erase)}>
                { mode === InterpretationMode.edit ? <Remove fontSize='small' />:<Create fontSize='small' /> }
            </IconButton>
            <IconButton size='small' onClick={() => interpretationSettings.setMode!(interpretation.Token, InterpretationMode.view)} >
                <Check fontSize='small' />
            </IconButton>
        </Box>}
        <Popover
            open={openExport}
            anchorEl={anchorPopoverExport}
            onClose={handleExportClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={() => { exportInterpretation(InterpretationExportMode.OnlySeismic) }}>{t('seismic:currentSeismic')}</MenuItem>
            <MenuItem onClick={() => { exportInterpretation(InterpretationExportMode.OnlySurvey) }}>{t('seismic:currentVolume')}</MenuItem>
        </Popover>
    </Box>;
}

interface Props {
    interpretation: IInterpretation;
    onEdit: (token: string) => void;
    refresh: () => void;
    interpretationSettings: IUseInterpretation;
}