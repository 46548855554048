import { Extent } from 'ol/extent';

import { IRangeTrace, IScale } from 'features/seismic/stores/useSeismicStore';
import { SeismicCalculator } from './SeismicCalculator';
import { ISeismicCalculatorLineType } from '../interfaces/ISeismicCalculatorLineType';
import { ISurveyMetadata } from '../interfaces/ISurveyMetadata';
import { LineType } from '../enums/LineType';

export class SeismicCalculator3DIndexed extends SeismicCalculator {
    private calculatorLineType: ISeismicCalculatorLineType | null = null;

    calculateExtent (surveyMetadata: ISurveyMetadata, scale: IScale, rangeTrace: IRangeTrace) {
        const minTrace = this.calculatorLineType!.getMinTrace(surveyMetadata);
        const totalTraces = this.calculatorLineType!.getTotalTraces(surveyMetadata);
        const minX = minTrace;
        const minY = (surveyMetadata.Header?.SamplesPerTrace ?? 0) * -1 * scale.y;
        const maxX = minTrace + (totalTraces * scale.x / this.getLineIncrement(surveyMetadata));
        const maxY = 0;
        return [minX, minY, maxX, maxY];
    };

    calculateCenter(extent: Extent, surveyMetadata: ISurveyMetadata, scale: IScale, rangeTrace: IRangeTrace) {
        // const minTrace = this.calculatorLineType!.getMinTrace(surveyMetadata);
        const x = super.calculateCenterX(extent);
        const y = super.calculateCenterY((surveyMetadata.Header?.SamplesPerTrace ?? 0), scale.y);

        return [x, y];
    }

    setLineTypeCalculator (calculator: ISeismicCalculatorLineType) {
        this.calculatorLineType = calculator;
    }

    calculateLineStart (surveyMetadata: ISurveyMetadata) {
        const metadataDefaultLineNumber = this.calculatorLineType!.getLineType(surveyMetadata) === LineType.Xline ? surveyMetadata.Survey3DInfo.DefaultXlineNumber : surveyMetadata.Survey3DInfo.DefaultInlineNumber;

        if (metadataDefaultLineNumber) {
            return metadataDefaultLineNumber;
        }

        const min = this.calculatorLineType!.getMinLine(surveyMetadata);
        const max = this.calculatorLineType!.getMaxLine(surveyMetadata);
        const lineIncrement = this.calculatorLineType!.getLineIncrement(surveyMetadata);

        const middle = Math.round((min + max) / 2);
        let startRange = middle - min;
        const mod = startRange % lineIncrement;
        startRange += mod;

        let lineToSelect = startRange + min;

        if (!surveyMetadata.Survey3DInfo){
            return 0;
        }

        if (surveyMetadata.Survey3DInfo.EntitledInlines && surveyMetadata.Survey3DInfo.EntitledInlines.length > 0) {
            if (this.calculatorLineType!.getLineType(surveyMetadata) === LineType.Inline) {
                if (surveyMetadata.Survey3DInfo.EntitledInlines.indexOf(lineToSelect) === -1) {
                    const middleIdx = parseInt((surveyMetadata.Survey3DInfo.EntitledInlines.length / 2).toString());
                    if (middleIdx < surveyMetadata.Survey3DInfo.EntitledInlines.length && surveyMetadata.Survey3DInfo.EntitledInlines.length > 2) {
                        lineToSelect = surveyMetadata.Survey3DInfo.EntitledInlines[middleIdx];
                    }
                    else {
                        lineToSelect = surveyMetadata.Survey3DInfo.EntitledInlines[0];
                    }
                }
            }
        }

        if (surveyMetadata.Survey3DInfo.EntitledXlines && surveyMetadata.Survey3DInfo.EntitledXlines.length > 0) {
            if (this.calculatorLineType!.getLineType(surveyMetadata) === LineType.Xline) {
                if (surveyMetadata.Survey3DInfo.EntitledXlines.indexOf(lineToSelect) === -1 && surveyMetadata.Survey3DInfo.EntitledXlines.length > 2) {
                    const middleIdx = parseInt((surveyMetadata.Survey3DInfo.EntitledXlines.length / 2).toString());
                    if (middleIdx < surveyMetadata.Survey3DInfo.EntitledXlines.length) {
                        lineToSelect = surveyMetadata.Survey3DInfo.EntitledXlines[middleIdx];
                    }
                    else {
                        lineToSelect = surveyMetadata.Survey3DInfo.EntitledXlines[0];
                    }
                }
            }
        }

        return lineToSelect;
    }

    getMinTrace (surveyMetadata: ISurveyMetadata) {
        return this.calculatorLineType!.getMinTrace(surveyMetadata);
    }

    getMaxTrace (surveyMetadata: ISurveyMetadata) {
        return this.calculatorLineType!.getMaxTrace(surveyMetadata);
    }

    getLineIncrement (surveyMetadata: ISurveyMetadata) {
        return this.calculatorLineType!.getLineIncrement(surveyMetadata);
    }

    getSkipTrace (surveyMetadata: ISurveyMetadata) {
        return this.calculatorLineType!.getSkipTrace(surveyMetadata);
    }

    getTotalTraces(surveyMetadata: ISurveyMetadata) {
        if (!this.calculatorLineType) {
            return 0;
        }
        return this.calculatorLineType.getTotalTraces(surveyMetadata);
    }
}