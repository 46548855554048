import { Box, Collapse, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useDebouncedCallback } from 'use-debounce';

import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { useGeneralStats } from './hooks/useGeneralStats';
import { PerformanceGridToolbar } from './PerformanceGridToolbar';
import { IPerformanceMonitoringStore, usePerformanceMonitoringStore } from 'features/seismic/stores/usePerformanceMonitoringStore';
import { LineType } from 'features/seismic/models/enums/LineType';

export type TilesPerformancePanelProps = {
    surveyType: SurveyType;
};

const GeneralGridToolbar = () => (<PerformanceGridToolbar fileName='tiles_general_data'/>);

const DetailedGridToolbar = () => (<PerformanceGridToolbar fileName='tiles_detailed_data'/>);

export const TilesPerformancePanel = ({ surveyType } : TilesPerformancePanelProps) => {

    const [ generalStatsCollapsed, setGeneralStatsCollapsed ] = useState(false);
    const [ detailedStatsCollapsed, setDetailedStatsCollapsed ] = useState(false);
    const [ errorStatsCollapsed, setErrorStatsCollapsed ] = useState(false);

    const [stateRef, setStateRef] = useState(usePerformanceMonitoringStore.getState());

    const debounce = useDebouncedCallback((value: IPerformanceMonitoringStore) => {
        setStateRef(value);
    }, 1000);

    useEffect(() => usePerformanceMonitoringStore.subscribe(
        state => {
            debounce(state);
        }
    ), []);

    const tilesPerformanceData = stateRef.tilesPerformanceData;

    const tilesErrorData = stateRef.tilesWithError;

    const { min: minRequestNetworkTime, max: maxRequestNetworkTime, average: requestNetworkTimeAverage, standardDeviation: requestNetworkTimeStandardDeviation } = useGeneralStats(tilesPerformanceData.map(x => x.requestNetworkTime));

    const { min: minResponseNetworkTime, max: maxResponseNetworkTime, average: responseNetworkTimeAverage, standardDeviation: responseNetworkTimeStandardDeviation } = useGeneralStats(tilesPerformanceData.map(x => x.responseNetworkTime));

    const { min: minTotalTime, max: maxTotalTime, average: totalTimeAverage, standardDeviation: totalTimeStandardDeviation } = useGeneralStats(tilesPerformanceData.map(x => x.totalTime));

    const { min: minApiTime, max: maxApiTime, average: apiTimeAverage, standardDeviation: apiTimeStandardDeviation } = useGeneralStats(tilesPerformanceData.map(x => x.apiTime));

    const { min: minProcessingTime, max: maxProcessingTime, average: processingTimeAverage, standardDeviation: processingTimeStandardDeviation } = useGeneralStats(tilesPerformanceData.map(x => x.serverProcessingTime));

    const { min: minPlotTime, max: maxPlotTime, average: plotTimeAverage, standardDeviation: plotTimeStandardDeviation } = useGeneralStats(tilesPerformanceData.map(x => x.plotTime));

    const orderedPerformanceData = useMemo(() => tilesPerformanceData.sort((a, b) => a.startTrace - b.startTrace), [tilesPerformanceData]);

    const generalGridColumns : GridColDef[] = [
        {
            field: 'operation',
            headerName: 'Operation',
            width: 150
        },
        {
            field: 'totalTime',
            headerName: 'Total time lapsed (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'apiQueryTime',
            headerName: 'Api query time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'serverProcessingTime',
            headerName: 'Server processing time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'plotProcessingTime',
            headerName: 'Plot processing time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'requestNetworkTime',
            headerName: 'Request network time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'responseNetworkTime',
            headerName: 'Response network time (sec.)',
            width: 250,
            type: 'number'
        }
    ];

    const averageRow = useMemo(() => ({
        id: 0,
        operation: 'Average',
        totalTime: totalTimeAverage.toFixed(3),
        apiQueryTime: apiTimeAverage.toFixed(3),
        serverProcessingTime: processingTimeAverage.toFixed(3),
        plotProcessingTime: plotTimeAverage.toFixed(3),
        requestNetworkTime: requestNetworkTimeAverage.toFixed(3),
        responseNetworkTime: responseNetworkTimeAverage.toFixed(3)
    }), [apiTimeAverage, plotTimeAverage, processingTimeAverage, requestNetworkTimeAverage, responseNetworkTimeAverage, totalTimeAverage]);

    const maxRow = useMemo(() => ({
        id: 1,
        operation: 'Max',
        totalTime: maxTotalTime.toFixed(3),
        apiQueryTime: maxApiTime.toFixed(3),
        serverProcessingTime: maxProcessingTime.toFixed(3),
        plotProcessingTime: maxPlotTime.toFixed(3),
        requestNetworkTime: maxRequestNetworkTime.toFixed(3),
        responseNetworkTime: maxResponseNetworkTime.toFixed(3)
    }), [maxApiTime, maxPlotTime, maxProcessingTime, maxRequestNetworkTime, maxResponseNetworkTime, maxTotalTime]);

    const minRow = useMemo(() => ({
        id: 2,
        operation: 'Min',
        totalTime: minTotalTime.toFixed(3),
        apiQueryTime: minApiTime.toFixed(3),
        serverProcessingTime: minProcessingTime.toFixed(3),
        plotProcessingTime: minPlotTime.toFixed(3),
        requestNetworkTime: minRequestNetworkTime.toFixed(3),
        responseNetworkTime: minResponseNetworkTime.toFixed(3)
    }), [minApiTime, minPlotTime, minProcessingTime, minRequestNetworkTime, minResponseNetworkTime, minTotalTime]);

    const standardDeviationRow = useMemo(() => ({
        id: 3,
        operation: 'Standard deviation',
        totalTime: totalTimeStandardDeviation.toFixed(3),
        apiQueryTime: apiTimeStandardDeviation.toFixed(3),
        serverProcessingTime: processingTimeStandardDeviation.toFixed(3),
        plotProcessingTime: plotTimeStandardDeviation.toFixed(3),
        requestNetworkTime: requestNetworkTimeStandardDeviation.toFixed(3),
        responseNetworkTime: responseNetworkTimeStandardDeviation.toFixed(3)
    }), [apiTimeStandardDeviation, plotTimeStandardDeviation, processingTimeStandardDeviation, requestNetworkTimeStandardDeviation, responseNetworkTimeStandardDeviation, totalTimeStandardDeviation]);

    const generalGridRows = useMemo(() => [
        averageRow,
        maxRow,
        minRow,
        standardDeviationRow,
    ], [averageRow, maxRow, minRow, standardDeviationRow]);

    const detailedGridColumns : GridColDef[] = [
        {
            field: 'startSample',
            headerName: 'Start sample',
            width: 150,
            type: 'number'
        },
        {
            field: 'endSample',
            headerName: 'End sample',
            width: 150,
            type: 'number'
        },
        {
            field: 'startTrace',
            headerName: 'Start trace',
            width: 150,
            type: 'number'
        },
        {
            field: 'endTrace',
            headerName: 'End trace',
            width: 150,
            type: 'number'
        },
        {
            field: 'tileWidth',
            headerName: 'Tile width',
            width: 150,
            type: 'number'
        },
        {
            field: 'totalTime',
            headerName: 'Total time lapsed (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'apiQueryTime',
            headerName: 'Api query time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'serverProcessingTime',
            headerName: 'Server processing time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'plotProcessingTime',
            headerName: 'Plot processing time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'requestNetworkTime',
            headerName: 'Request network time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'responseNetworkTime',
            headerName: 'Response network time (sec.)',
            width: 250,
            type: 'number'
        },
        {
            field: 'lineTypeText',
            headerName: 'Type',
            width: 100,
            type: 'string'
        },
        {
            field: 'lineNumber',
            headerName: 'Number',
            width: 150,
            type: 'string'
        },
        {
            field: 'source',
            headerName: 'Source',
            width: 170,
            type: 'string'
        },
        {
            field: 'traceId',
            headerName: 'Trace Id',
            width: 200,
            type: 'string'
        }
    ];

    const detailedGridColumns3d : GridColDef[] = [
        {
            field: 'lineNumber',
            headerName: 'Line number',
            width: 150
        },
        {
            field: 'lineType',
            headerName: 'Line type',
            width: 150
        },
        ...detailedGridColumns
    ];

    const detailedGridRows = useMemo(() => orderedPerformanceData.map((tilePerformanceData, index) => ({
        traceId: tilePerformanceData.traceId,
        id: index,//tilePerformanceData.startTrace + tilePerformanceData.lineNumber + tilePerformanceData.lineType,
        lineNumber: tilePerformanceData.lineNumber,
        lineType: tilePerformanceData.lineType,
        lineTypeText: tilePerformanceData.lineType === LineType.Inline ? 'Inline': 'Xline',
        startSample: tilePerformanceData.startSample,
        endSample: tilePerformanceData.endSample,
        startTrace: tilePerformanceData.startTrace,
        endTrace: tilePerformanceData.endTrace,
        tileWitdh: tilePerformanceData.tileWidth,
        serverProcessingTime: tilePerformanceData.serverProcessingTime,
        plotProcessingTime: tilePerformanceData.plotTime,
        apiQueryTime: tilePerformanceData.apiTime,
        totalTime: tilePerformanceData.totalTime,
        requestNetworkTime: tilePerformanceData.requestNetworkTime,
        responseNetworkTime: tilePerformanceData.requestNetworkTime,
        source: tilePerformanceData.source
    })), [orderedPerformanceData]);

    return (
        <Stack spacing={2}>
            <Stack>
                <Stack direction='row' sx={{ borderBottom: !generalStatsCollapsed ? '1px solid #cccccc' : 'none' }} alignItems={'center'} justifyContent={'center'}>
                    <Typography fontSize='20px'>General</Typography>
                    <IconButton onClick={() => setGeneralStatsCollapsed(!generalStatsCollapsed)}>
                        {
                            generalStatsCollapsed ?
                                <ArrowDropUp/>
                                :
                                <ArrowDropDown/>
                        }
                    </IconButton>
                </Stack>
                <Collapse in={generalStatsCollapsed}>
                    <Box hidden={isNaN(totalTimeAverage)}>
                        <DataGrid
                            disableRowSelectionOnClick
                            columns={generalGridColumns}
                            rows={generalGridRows}
                            slots={{ toolbar: GeneralGridToolbar }}
                        />
                    </Box>
                </Collapse>

            </Stack>
            <Stack>
                <Stack direction='row' sx={{ borderBottom: !detailedStatsCollapsed ? '1px solid #cccccc' : 'none' }} alignItems={'center'} justifyContent={'center'}>
                    <Typography fontSize='20px'>Detailed</Typography>
                    <IconButton onClick={() => setDetailedStatsCollapsed(!detailedStatsCollapsed)}>
                        {
                            detailedStatsCollapsed ?
                                <ArrowDropUp/>
                                :
                                <ArrowDropDown/>
                        }
                    </IconButton>
                </Stack>
                <Collapse in={detailedStatsCollapsed}>
                    <Box hidden={tilesPerformanceData.length === 0} sx={{height: 500, with: '100%'}}>
                        <DataGrid
                            disableRowSelectionOnClick
                            columns={detailedGridColumns}
                            rows={detailedGridRows}
                            slots={{toolbar: DetailedGridToolbar}}
                        />
                    </Box>
                </Collapse>
            </Stack>
            <Stack>
                <Stack direction='row' sx={{ borderBottom: !errorStatsCollapsed ? '1px solid #cccccc' : 'none' }} alignItems={'center'} justifyContent={'center'}>
                    <Typography fontSize='20px'>Errors</Typography>
                    <IconButton onClick={() => setErrorStatsCollapsed(!errorStatsCollapsed)}>
                        {
                            errorStatsCollapsed ?
                                <ArrowDropUp/>
                                :
                                <ArrowDropDown/>
                        }
                    </IconButton>
                </Stack>
                <Collapse in={errorStatsCollapsed}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    surveyType === SurveyType.Seismic3D &&
                                    <React.Fragment>
                                        <TableCell>
                                            Line number
                                        </TableCell>
                                        <TableCell>
                                            Line type
                                        </TableCell>
                                    </React.Fragment>
                                }
                                <TableCell>
                                    Tile width
                                </TableCell>
                                <TableCell>
                                    Start trace
                                </TableCell>
                                <TableCell>
                                    End trace
                                </TableCell>
                                <TableCell>
                                    Error status
                                </TableCell>
                                <TableCell>
                                    Error message
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                tilesErrorData.map((tileErrorData, index) => (
                                    <TableRow key={index}>
                                        {
                                            surveyType === SurveyType.Seismic3D
                                            &&
                                            <React.Fragment>
                                                <TableCell>
                                                    {tileErrorData.lineNumber}
                                                </TableCell>
                                                <TableCell>
                                                    {tileErrorData.lineType}
                                                </TableCell>
                                            </React.Fragment>
                                        }
                                        <TableCell>
                                            {tileErrorData.tileWidth}
                                        </TableCell>
                                        <TableCell>
                                            {tileErrorData.startTrace}
                                        </TableCell>
                                        <TableCell>
                                            {tileErrorData.endTrace}
                                        </TableCell>
                                        <TableCell>
                                            {tileErrorData.status}
                                        </TableCell>
                                        <TableCell title={tileErrorData.fullErrorMessage}>
                                            {tileErrorData.errorMessage}
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Collapse>
            </Stack>
        </Stack>
    );
};