import { useQuery } from 'react-query';

import { axios } from 'configurations/axios';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { IContract, IContractFiles } from './interfaces/IContract';
import { useSessionStore } from 'session/useSessionStore';
import { TenantConfig } from 'features/tenant/model/TenantConfig';

export function useGetAllContracts() {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllContracts', async () => {
        const response = await axios.request<IResponseAPI<IContract[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Contracts/GetAll',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useGetContractByToken(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getContractByToken' + token, async () => {
        const response = await axios.request<IResponseAPI<IContract>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Contracts/GetByToken',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useGetContractFilesByToken(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getContractFilesByToken' + token, async () => {
        const response = await axios.request<IResponseAPI<IContractFiles>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Contracts/GetFilesByToken',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

// ainda não está sendo usado
export function useGetContractsByInstitution(institutionToken: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getContractsByInstitution' + institutionToken, async () => {
        const response = await axios.request<IResponseAPI<IContract>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Contracts/GetByInstitution',
            params: {
                institutionToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: institutionToken !== null && institutionToken !== undefined && institutionToken !== '',
        cacheTime: 0,
    });
}

async function fetchAttachmentByToken(token: string, tenantConfig: TenantConfig | undefined, jwtToken: string) {
    const response = await fetch(`${tenantConfig?.endpoints.fileIndexing}/Contracts/OpenAttachmentByToken?token=${token}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const newUrl = window.URL.createObjectURL(blob);
    return newUrl;
}

export function useOpenAttachmentByPath(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('openAttachmentByPath' + token, async () =>
        fetchAttachmentByToken(token, tenantConfig, jwtToken), {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useRemoveAttachment(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('removeAttachment' + token, async () => {
        const response = await axios.request<IResponseAPI<boolean>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/Contracts/RemoveAttachment',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
    });
}

export function useSaveContract(contract: IContract | null) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveContract' + contract, async () => {
        let formData = new FormData();
        if (contract !== null) {
            // if (contract.Files) {
            //     for (let file of contract.Files) {
            //         formData.append('attachmentFiles', file);
            //     }
            // }

            formData.append('[contractViewModel][Token]', contract.Token);
            formData.append('[contractViewModel][CompanyToken]', contract.CompanyToken);
            formData.append('[contractViewModel][Supplements]', contract.Supplements);
            formData.append('[contractViewModel][SurveyType]', contract.SurveyType);
            formData.append('[contractViewModel][ContractedKm]', contract.ContractedKm.toString());
            formData.append('[contractViewModel][EquivalentKm]', contract.EquivalentKm.toString());
            formData.append('[contractViewModel][SapCode]', contract.SapCode);
            formData.append('[contractViewModel][SigningDate]', contract.SigningDate.toString());
            formData.append('[contractViewModel][ContractValue]', contract.ContractValue.toString());
            formData.append('[contractViewModel][ContractGoal]', contract.ContractGoal);
            formData.append('[contractViewModel][Block]', contract.Block);
            formData.append('[contractViewModel][GeneralNotes]', contract.GeneralNotes);
            formData.append('[contractViewModel][Year]', contract.Year.toString());
            formData.append('[contractViewModel][ContractObject]', contract.ContractObject);
            formData.append('[contractViewModel][StorageSummary]', contract.StorageSummary);
        }

        const response = await axios.request<IResponseAPI<number>>({
            method: 'post',
            data: formData,
            url: tenantConfig?.endpoints.fileIndexing + '/Contracts/Save',
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: contract !== null,
        cacheTime: 0,
    });
}

export function useSaveContractFiles (contractFiles: IContractFiles | null) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveContract' + contractFiles, async () => {
        let formData = new FormData();
        if (contractFiles !== null) {
            if (contractFiles.Files) {
                for (let file of contractFiles.Files) {
                    formData.append('attachmentFiles', file);
                }
            }

            formData.append('token', contractFiles.Token);
        }

        const response = await axios.request<IResponseAPI<number>>({
            method: 'post',
            data: formData,
            url: tenantConfig?.endpoints.fileIndexing + '/Contracts/SaveFiles',
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: contractFiles !== null,
        cacheTime: 0,
    });
}

export function useDeleteContract(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteContract' + token, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            params: {
                token: token
            },
            url: tenantConfig?.endpoints.fileIndexing + '/Contracts/Delete',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}