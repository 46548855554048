import { useState, Fragment, useRef } from 'react';
import { OutlinedInput, IconButton, Box, css } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { Eye, Reset } from 'components/icons/components';
import { ListInterpretationMode } from '../models/enums/ListInterpretationMode';
import { SeismicInterpretationList, SeismicInterpretationListRef } from './SeismicInterpretationList';
import { SeismicInterpretationHiddenList, SeismicInterpretationHiddenListRef } from './SeismicInterpretationHiddenList';
import { IUseInterpretation } from '../models/interfaces/IUseInterpretation';

const styles = css({
    flexGrow: 1,
    '& .container-search': {
        padding: 5
    },
    '& .txt-search': {
        fontSize: 14
    }
});

export function SeismicInterpretationPopup({ opened, setOpened, interpretationSettings }: Props) {
    const { t } = useTranslation(['common', 'seismic']);

    const [termFilter, setTermFilter] = useState('');
    const [mode, setMode] = useState(ListInterpretationMode.List);
    const listRef = useRef<SeismicInterpretationListRef>(null);
    const hiddenListRef = useRef<SeismicInterpretationHiddenListRef>(null);

    const refresh = () => {
        if (mode === ListInterpretationMode.List){
            if (listRef.current){
                listRef.current.refresh();
            }
        }
        else if (mode === ListInterpretationMode.Hidden){
            if (hiddenListRef.current){
                hiddenListRef.current.refresh();
            }
        }
    };

    const changeMode = () => {
        setMode((state) => state === ListInterpretationMode.Hidden ? ListInterpretationMode.List:ListInterpretationMode.Hidden);
    };

    const buildIconHeaders = () => [
        <IconButton disabled={interpretationSettings.isLocked} key='interpretation-popup-visibility' color='inherit' size='small' onClick={changeMode} title={t('seismic:interpretation.changeVisibility')} style={{backgroundColor: mode === ListInterpretationMode.List ? 'transparent': '#ffffff61'}}>
            <Eye />
        </IconButton >,
        <IconButton key='interpretation-popup-reset' color='inherit' size='small' onClick={refresh} title={t('common:refresh')}>
            <Reset />
        </IconButton >
    ];

    return (
        <Fragment>
            <GeopostPopup
                temporaryIgnoreFlexInContainer={false}
                open={opened}
                title={t('seismic:interpretation.tools')}
                onChangeOpen={value => setOpened(value)}
                iconHeaders={buildIconHeaders()}
                overlay={false}
                height={400}
                width={500}
                resizable={false}>
                <div css={styles}>
                    <Box className='container-search'>
                        <OutlinedInput
                            className='txt-search'
                            placeholder={t('common:search')}
                            value={termFilter}
                            onChange={(event) => setTermFilter(event.target.value)}
                            fullWidth/>
                    </Box>
                    {mode === ListInterpretationMode.List ? <SeismicInterpretationList ref={listRef} termFilter={termFilter} interpretationSettings={interpretationSettings}/>:<SeismicInterpretationHiddenList ref={hiddenListRef} termFilter={termFilter} interpretationSettings={interpretationSettings}/> }
                </div>
            </GeopostPopup>
        </Fragment>
    );
}

interface Props {
    opened: boolean;
    setOpened: (value: boolean) => void;

    interpretationSettings: IUseInterpretation;
}