import { Style } from 'ol/style';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, CircularProgress, Stack, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField } from '@mui/material';

import { IntersectingLayer } from 'features/seismic-3d/models/types/IntersectingLayer';
import { useGetLayersIntersectingInGeom } from '../../projections/api/use3DFeatureInfoController';
import { FeatureGeom } from 'features/seismic-3d/models/classes/FeatureGeom';
import { GeopostCheckbox } from 'components/geopostCheckbox/GeopostCheckbox';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { LayersList } from '../LayersList';
import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';
import { getThreeJsYFactor } from 'features/seismic-3d/utils/TileUtils';
import { LayersThreeJsFacade } from 'features/seismic-3d/threejs/facade/LayersThreeJsFacade';

type Props = {
    mainFeatureGeomWkt: string,
    mainFeatureGeomSrid: number
};

export function IntersectingLayersList({mainFeatureGeomWkt, mainFeatureGeomSrid} : Props) {

    const [layerTitleToFilter, setLayerTitleToFilter] = useState<string>('');
    const {data: layersFound, mutate: refechIntersectingLayers, isLoading: isFoundLayersLoading} = useGetLayersIntersectingInGeom(mainFeatureGeomWkt, mainFeatureGeomSrid);
    const [filteredFoundLayers, setFilteredFoundLayers] = useState<IntersectingLayer[]>([]);
    const selectedLayers = use3DViewerStore((state) => state.selectedLayers);
    const heightPixelFactor = use3DViewerStore(state => state.heightPixelFactor);
    const geopostScene = use3DSceneStore((state) => state.scene);
    const gridSelectedSeismic =  use3DGridStore(state => state.gridSelectedSeismic);
    const minProjectionHeight = useMemo(() => -(getThreeJsYFactor(heightPixelFactor)), [heightPixelFactor]);

    useEffect(() => {
        if (layersFound){
            const regex = new RegExp( layerTitleToFilter, 'i');
            const newFilteredFoundLayers  = layersFound.filter((layer) => layerTitleToFilter === '' || regex.test(layer.Title));
            setFilteredFoundLayers(newFilteredFoundLayers);
        };
    }, [layerTitleToFilter, layersFound]);

    const layersThreeJsFacade = useMemo(() => !!geopostScene ? new LayersThreeJsFacade(geopostScene) : null, [geopostScene]);

    const addSelectedLayerStore = use3DViewerStore(state => state.addIntersectingLayer);
    const removeSelectedLayerStore = use3DViewerStore(state => state.removeIntersectingLayer);
    const mainFeatureCentroidX = use3DViewerStore(state => state.featureCentroidX);
    const mainFeatureCentroidY = use3DViewerStore(state => state.featureCentroidY);

    const addSelectedLayer = (layerData: IntersectingLayer, layerGeomData : FeatureGeom) => {
        if (!layersThreeJsFacade) {
            return;
        }
        layersThreeJsFacade.addLayerMesh(layerData.Id, layerGeomData, layerData.FillColor, layerData.FillOpacity, layerData.StrokeColor, [mainFeatureCentroidX, mainFeatureCentroidY]);
        addSelectedLayerStore(layerData);
    };

    const removeSelectedLayer = async (layerData: IntersectingLayer) => {
        if (!geopostScene) {
            return;
        }
        await geopostScene.removeLayerMesh(layerData.Id);
        await removeSelectedLayerStore(layerData.Id);
    };

    const [selectedLayerIds, setSelectedLayerIds] = useState<number[]>([]);

    useEffect(() => {
        setSelectedLayerIds(selectedLayers.map(layer => layer.Id));
    }, [selectedLayers]);

    useEffect(() => refechIntersectingLayers(), []);

    useEffect(() => {
        console.log('a');
    });

    return (
        <Fragment>

            <Box
                component='form'
                noValidate sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                autoComplete="off"
            >
                <Stack
                    direction='row'
                    spacing={2}
                >
                    <TextField
                        size='small'
                        id='title-input'
                        value={layerTitleToFilter}
                        onChange={(event) => {setLayerTitleToFilter(event.target.value)}}
                        label='Title'
                        variant='outlined'/>
                    {
                        isFoundLayersLoading
                        &&
                        <CircularProgress size={25}/>
                    }
                </Stack>
            </Box>
            {
                layersThreeJsFacade
                &&
                <LayersList
                    onSelectCallback={addSelectedLayer}
                    onUnselectCallback={removeSelectedLayer}
                    isCheckedCallback={(layer) => selectedLayerIds.includes(layer.Id)}
                    layers={filteredFoundLayers}
                    minProjectionHeight={minProjectionHeight}
                    onChangeProjectionHeight={layersThreeJsFacade.setLayerMeshHeight}
                    onChangeProjectionColor={layersThreeJsFacade.changeLayerColor}
                    onChangeProjectionOpacity={layersThreeJsFacade.changeLayerFillOpacity}
                />
            }

        </Fragment>
    );
}