import { IconButton } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from 'session/useSessionStore';
import { Metadata } from 'components/icons/components';

export function SeismicMetadataNavbar({ opened, setOpened }:Props) {
    const { t } = useTranslation();
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));

    const className = (opened) ? 'active' : '';

    return (
        <Fragment>
            <IconButton
                id='metadata'
                style={{color:tenantConfig?.main.palette.primary.contrastText, fontSize: '22px'}}
                className={className}
                onClick={() => setOpened(!opened)}
                title={t('metadata')}>
                <Metadata />
            </IconButton>
        </Fragment>
    );
}

interface Props {
    opened: boolean;
    setOpened: (value: boolean) => void
}