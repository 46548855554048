import { Box, css, FormControl, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { IRelatedData } from 'features/seismic/models/interfaces/IRelatedData';
import { IRelatedDataImages, useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useMapStore } from 'features/seismic/stores/useMapStore';
import { createOnlyText, createTextFeature } from 'features/seismic/utils/wellsUtils';

const containerStyle = css({
    display: 'flex',
    flexDirection: 'column',
    padding: '7px',
    zIndex: '2',
    '& .search-box': {
        width: '97%',
        height: '20px',
        alignSelf: 'center',
        outline: 'none',
        border: '1px solid #DEDEDE',
        borderRadius: '2px',
        padding: '7px',
        fontSize: '14px',
        marginBottom: '10px',
        '&:focus': {
            outline: '1px solid rgb(14, 149, 196)'
        },
    },
    '& .selection-boxes-space': {
        marginBottom: '15px',
    },
    '& .selection-box': {
        width: '320px',
    },
    '& .MuiNativeSelect-select': {
        minHeight: 'auto',
        padding: '5px 32px 5px 6px',
        borderRadius: '2px',
        fontSize: '14px',
        fontFamily: 'Arial, Verdana, sans-serif',
        '& option': {
            padding: '5px',
            minHeight: '0',
            fontSize: '14px',
            fontFamily: 'Arial, Verdana, sans-serif',
        },
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
        padding: '6px',
        color: 'rgb(255, 255, 255)',
        fontSize: '14px',
        fontFamily: 'Arial, Verdana, sans-serif',
        fontWeight: '100',
        backgroundColor: 'rgb(34, 92, 125)'
    },
    '& .MuiTableRow-root': {
        cursor: 'pointer'
    },
    '& .MuiTableCell-root': {
        padding: '6px',
        fontSize: '12px',
        fontFamily: 'Arial, Verdana, sans-serif',
        fontWeight: '100',
        borderRight: '1px solid rgb(225, 225, 225)'
    },
    '& .row': {
        display: 'flex',
        flexDirection: 'row'
    },
    '& .column': {
        display: 'flex',
        flexDirection: 'column'
    },
    '& .right-margin': {
        marginRight: '10px'
    },
    '& .link': {
        cursor: 'pointer',
        textDecoration: 'underline'
    }
});

export function SeismicWellRelatedDataContent({ data, wellId }: Props) {
    const { t } = useTranslation();

    const { map } = useMapStore(state => ({
        map: state.map
    }));

    const { wellRelatedData, setWellRelatedData, wellRelatedDataTextLayers, setWellRelatedDataTextLayers } = useSeismicStore(state => ({
        wellRelatedData: state.wellRelatedData,
        setWellRelatedData: state.setWellRelatedData,
        wellRelatedDataTextLayers: state.wellRelatedDataTextLayers,
        setWellRelatedDataTextLayers: state.setWellRelatedDataTextLayers
    }));

    const [searchText, setSearchText] = useState<string>('');
    const [groupValue, setGroupValue] = useState<string>('Mostrar todos');
    const [categoryValue, setCategoryValue] = useState<string>('Mostrar todos');

    const updateImageOpen = (id: string) => {
        let updateImage = data.images[id];
        updateImage.open = true;

        setWellRelatedData(wellId, { layer: data.layer, relatedData: data.relatedData, opened: data.opened, images: { ...data.images, [id]: updateImage } });
    };

    const createLineSelector = () => {
        let allLines = {};

        for (let i = 0; i < data.relatedData.pickers.length; i++) {
            allLines = { ...allLines, [data.relatedData.pickers[i].Id]: false };
        }

        return allLines;
    };

    const [lineSelector, setLineSelector] = useState<{ [key: string]: boolean }>(createLineSelector());

    const changeLineSelection = (id: string, start: string, end: string) => {
        setLineSelector({ ...lineSelector, [id]: !lineSelector[id] });

        addOrRemoveText(id, start, end, !lineSelector[id]);
    };

    const centralizeView = (id: string) => {
        map?.getView().setCenter(wellRelatedData[wellId].images[id].coordinate);
    };

    // const [textLayers, setTextLayers] = useState<{ [wellId: string]: { [lineId: string]: VectorLayer<VectorSource<Point>> } }>({});

    const createText = (id: string, start: string, end: string) => {
        let textFeature;
        if (start === end) {
            textFeature = createTextFeature(wellRelatedData[wellId].images[id].coordinate[0], wellRelatedData[wellId].images[id].coordinate[1], start, '#FFFFFF', 'left', -20);
        }
        else {
            textFeature = createTextFeature(wellRelatedData[wellId].images[id].coordinate[0], wellRelatedData[wellId].images[id].coordinate[1], `${start}-${end}`, '#FFFFFF', 'left', -20);
        }

        let textLayer = new VectorLayer({ source: new VectorSource({ features: [textFeature] }) });
        textLayer.setZIndex(99999995);

        map?.addLayer(textLayer);

        setWellRelatedDataTextLayers(wellId, { [id]: textLayer });
    };

    const addOrRemoveText = (id: string, start: string, end: string, checked: boolean) => {
        if (!wellRelatedDataTextLayers[wellId]) {
            createText(id, start, end);
        }
        else if (!Object.keys(wellRelatedDataTextLayers[wellId]).includes(id)) {
            createText(id, start, end);
        }
        else {
            if (!lineSelector[id]) {
                map?.addLayer(wellRelatedDataTextLayers[wellId][id]);
            }
            else {
                map?.removeLayer(wellRelatedDataTextLayers[wellId][id]);
            }
        }
    };

    const filterRelatedData = (itemName: string, itemGroup: string, itemCategory: string) => {
        const isGroupMatch = groupValue === 'Mostrar todos' || groupValue === itemGroup;
        const isCategoryMatch = categoryValue === 'Mostrar todos' || categoryValue === itemCategory;
        const isSearchTextMatch = searchText === '' || itemName.toLowerCase().includes(searchText.toLowerCase());

        if (isGroupMatch && isCategoryMatch && isSearchTextMatch) {
            return true;
        }
        return false;
    };

    return (
        <Box css={containerStyle}>
            <input className='search-box' placeholder={t('search')} value={searchText} onChange={(event) => setSearchText(event.target.value)}></input>
            <Box className='row selection-boxes-space'>
                <Box className='column selection-box right-margin'>
                    <Typography>{t('group')}</Typography>
                    <FormControl>
                        <Select
                            native
                            value={groupValue}
                            onChange={(event) => setGroupValue(event.target.value)}
                        >
                            <option key={1} label='Mostrar todos' value='Mostrar todos'>Mostrar todos</option>
                            {Object.values(data.relatedData.groups).map((item, index) => (
                                <option key={index + 2} label={item.Title} value={item.Title}>item</option>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box className='column selection-box'>
                    <Typography>{t('category')}</Typography>
                    <FormControl>
                        <Select
                            native
                            value={categoryValue}
                            onChange={(event) => setCategoryValue(event.target.value)}
                        >
                            <option key={1} label='Mostrar todos' value='Mostrar todos'>Mostrar todos</option>
                            {Object.values(data.relatedData.subGroups).map((item, index) => (
                                <option key={index + 2} label={item.Title} value={item.Title}>item</option>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <TableContainer component={Paper} className='table'>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('group')}</TableCell>
                            <TableCell>{t('category')}</TableCell>
                            <TableCell>{t('archiveName')}</TableCell>
                            <TableCell>{t('start')}</TableCell>
                            <TableCell>{t('final')}</TableCell>
                            <TableCell>{t('goTo')}</TableCell>
                            <TableCell>{t('link')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.relatedData.pickers.map((item, index) => {
                            let filter = filterRelatedData(item.FileName, data.relatedData.groups[item.Group]?.Title, data.relatedData.subGroups[item.SubGroup]?.Title);
                            return (
                                <TableRow
                                    key={index}
                                    style={{ backgroundColor: lineSelector[item.Id] ? 'rgb(127, 208, 99)' : 'rgb(255, 255, 255)', display: filter ? 'table-row' : 'none' }}
                                >
                                    <TableCell onClick={() => { changeLineSelection(item.Id, (item.Start).toString(), (item.End).toString()) }}>{data.relatedData.groups[item.Group]?.Title}</TableCell>
                                    <TableCell onClick={() => { changeLineSelection(item.Id, (item.Start).toString(), (item.End).toString()) }}>{data.relatedData.subGroups[item.SubGroup]?.Title}</TableCell>
                                    <TableCell onClick={() => { changeLineSelection(item.Id, (item.Start).toString(), (item.End).toString()) }}>{item.FileName}</TableCell>
                                    <TableCell onClick={() => { changeLineSelection(item.Id, (item.Start).toString(), (item.End).toString()) }}>{item.Start}</TableCell>
                                    <TableCell onClick={() => { changeLineSelection(item.Id, (item.Start).toString(), (item.End).toString()) }}>{item.Start === item.End ? '-' : item.End}</TableCell>
                                    <TableCell className='link' onClick={() => { centralizeView(item.Id) }}>{t('click')}</TableCell>
                                    <TableCell className='link' onClick={() => { updateImageOpen(item.Id) }}>Link</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

interface Props {
    data: { layer: VectorLayer<VectorSource>; relatedData: IRelatedData; opened: boolean; images: IRelatedDataImages; };
    wellId: string;
};
