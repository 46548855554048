import { Box, Button, createTheme, IconButton, Popover, Popper, ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGeopostThemeAppBar } from 'theme';

export type GeopostPopoverProps = {
    anchorEl: HTMLElement | undefined | null;
    children: ReactNode;
    onClose: () => void
};

export const GeopostPopover = ({ anchorEl, children, onClose } : GeopostPopoverProps) => {

    const theme = useGeopostThemeAppBar();

    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{zIndex: 99}}
        >
            <ThemeProvider theme={theme}>
                <Box padding={'15px'} sx={{ backgroundColor: '#294059', zIndex: 0}}>
                    {children}
                </Box>
            </ThemeProvider>
        </Popover>
    );
};