import { Box, Checkbox, CircularProgress, css, FormControlLabel, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

import { SurveyType } from 'features/seismic/models/enums/SurveyType';

const containerStyle = css({
    zIndex: '20',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#192F45',
    position: 'sticky',
    marginLeft: '-25px',
    bottom: '0',
    width: '180px',
    alignItems: 'flex-start',
    padding: '6px 6px 6px 10px',
    overflow: 'hidden',
    '& .MuiTypography-root': {
        fontSize: '12px',
        fontFamily: 'Arial, Verdana, sans-serif',
        color: 'rgb(255, 255, 255) !important'
    },
    '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            marginLeft: '-9px',
            marginRight: '3px',
        }
    }
});

export function AnnotationAuthors({ authors, handleCheckbox, open, surveyType = SurveyType.Seismic2D}: Props) {
    const visible = open ? 'flex' : 'none';

    return (
        <Box style={{ display: visible, marginBottom: `-${12 + (23 * (authors.authors.length))}px` }} css={containerStyle}>
            {authors.loading && open ?
                (<CircularProgress style={{ color: 'white', width: '20px', height: '20px', alignSelf: 'center' }} />) :
                (authors.authors.map((item, index) => {
                    if (item.permission) {
                        return (
                            <FormControlLabel
                                key={index}
                                control={<Checkbox defaultChecked={true} onChange={() => handleCheckbox(index)} name='Author' />}
                                label={<Typography>{item.name}</Typography>}
                            />
                        );
                    }
                    else {
                        return (
                            <Box key={index} className='row'>
                                <LockIcon />
                                <Typography>{item.name}</Typography>
                            </Box>
                        );
                    }
                }
                ))
            }
        </Box>
    );
}

interface Props {
    authors: { authors: Author[]; loading: boolean; };
    handleCheckbox: (index: number) => void;
    open: boolean;
    surveyType?: SurveyType
}

export type AuthorList = {
    authors: Author[];
    loading: boolean;
};

export type Author = {
     name: string; selected: boolean; permission: boolean;
};