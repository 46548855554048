import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from 'session/useSessionStore';
import { TraceRangeSelector } from './TraceRangeSelector';
import { Range } from 'components/icons/components';

export function SeismicTraceRangeNavbar(){
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [ isPanelVisible, setIsPanelVisible ] = useState(false);
    const handleClose = () => setIsPanelVisible(false);
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));

    const { t } = useTranslation();
    const iconButtonRef = useRef(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsPanelVisible(true);
        setAnchorEl(iconButtonRef.current);
    };

    const classNameLabelColorbar = (isPanelVisible) ? 'active': '';

    //onClick={() => /*setOpenedLabelColorbar(!openedLabelColorbar)*/ null}
    return  <Fragment>
        <IconButton title={'Trace Range'} className={classNameLabelColorbar} ref={iconButtonRef} onClick={handleContextMenu} style={{color:tenantConfig?.main.palette.primary.contrastText}}>
            <Range fontSize={40}/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={isPanelVisible}
            onClose={handleClose}
        >
            <MenuItem style={{backgroundColor: 'grey', margin:0}}>
                <Typography>{'Trace Range'}</Typography>
            </MenuItem>
            <MenuItem>
                <TraceRangeSelector />
            </MenuItem>
        </Menu>
    </Fragment>;
}