import { Box, CircularProgress, css } from '@mui/material';
import { Fragment } from 'react';

const styleLoading = css({
    backgroundColor: '#ffffffA0',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '100'
});

export const GeopostLoading = ({ loading }: Props) => {
    return (
        <Fragment>
            {loading &&
                <Box css={styleLoading}>
                    <CircularProgress />
                </Box>
            }
        </Fragment>
    );
};

interface Props {
    loading: boolean;
}