import { useMutation, useQuery } from 'react-query';
import { shallow } from 'zustand/shallow';

import { axios } from 'configurations/axios';
import { useSessionStore } from 'session/useSessionStore';
import { IWellDirectionalData } from 'features/seismic/models/interfaces/IWellDirectionalData';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';

export const useGetDirectionalDataByWell = (wellId: number, enabled: boolean) => {
    const { apiUrl, jwtToken } = useSessionStore(state => ({
        apiUrl: state.tenantConfiguration?.endpoints.wells,
        jwtToken: state.jwtToken
    }));

    return useQuery(
        ['GetDirectionalDataByWell', wellId],
        async () => {
            const response = await axios.get<IResponseAPI<IWellDirectionalData>>(
                apiUrl + '/Wells/DirectionalData/GetDirectionalDataByWell',
                {
                    params: {
                        wellId: wellId
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + jwtToken
                    }
                }
            );
            return response.data.Result;
        },
        {
            enabled: enabled
        }
    );
};

export const useGetMultipleDirectionalDataByWell = () => {
    const { apiHost, jwt } = useSessionStore(state => ({
        apiHost: state.tenantConfiguration?.endpoints.wells,
        jwt: state.jwtToken
    }), shallow);

    return useMutation(async (wellId: number) => {
        const response = await axios.get<IResponseAPI<IWellDirectionalData>>(
            apiHost + '/Wells/DirectionalData/GetDirectionalDataByWell',
            {
                params: {
                    wellId: wellId
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + jwt
                }
            }
        );
        return response.data.Result;
    });
};