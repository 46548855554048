import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { MutableRefObject, useEffect, useRef } from 'react';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import alertify from 'alertifyjs';
import { t } from 'i18next';

import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { ITimeDepth } from 'features/seismic/models/interfaces/ITimeDepth';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { useAPI } from 'hooks/useAPI';
import { ApiType } from 'models/enums/ApiType';
import { FetchType } from 'models/enums/FetchType';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useMapStore } from 'features/seismic/stores/useMapStore';
import { createPointFeature, getXValue, getYValue } from 'features/seismic/utils/wellsUtils';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { LineType } from 'features/seismic/models/enums/LineType';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { getXByTrace } from 'features/seismic/utils/calculateScreenValuesUtils';

export function useGetTimeDepth(data: IWell, domain: AmplitudeDomain, datum: number, dataGeometryLine: ILineString) {
    const { calculator, surveyMetadata, scale, wellLayers, setWellLayers } = useSeismicStore(state => ({
        calculator: state.calculator,
        scale: state.scale,
        surveyMetadata: state.surveyMetadata,
        wellLayers: state.wellsLayers,
        setWellLayers: state.setWellsLayers,
    }));

    const { map } = useMapStore(state => ({
        map: state.map
    }));

    const { lineType } = useLine3DNavigationStore(state => ({
        lineType: state.lineType
    }));

    const { execute: executeTimeDepth } = useAPI<ITimeDepth>('/Wells/TransformToDomain/GetTimeDepthActive');
    const alreadyCreated = useRef<boolean>(false);

    const verifyScale = useRef<{ x: number, y: number }>({x: scale.x, y: scale.y});

    useEffect(() => {
        verifyScale.current = { x: scale.x, y: scale.y };
    }, [scale]);

    const fetch = async (loading: MutableRefObject<boolean>, onError: () => void) => {
        loading.current = true;
        const dataTimeDepthResponse = await executeTimeDepth({
            type: FetchType.Post,
            apiType: ApiType.WebApi,
            body: {
                wellId: data.Id,
                trace: data.Trace,
                domain: domain,
                datum: datum
            }
        });

        if (!dataTimeDepthResponse) {
            alertify.alert(t('timeDepthError'));
            onError();
            loading.current = false;
        }

        if (dataTimeDepthResponse && verifyScale.current.x === scale.x && verifyScale.current.y === scale.y) {
            alreadyCreated.current = true;
            let timeDepthFeatures: Feature<Point>[] = [];

            for (let i = 0; i < dataTimeDepthResponse.items.length; i++) {
                // possivelmente irá mudar
                const token = `timeDepth-${data.Id}-${dataTimeDepthResponse.items[i].Id}`;
                let dateConverted = new Date(dataTimeDepthResponse.items[i].Date);
                let date = `${dateConverted.getDate()}-${dateConverted.getMonth() + 1}-${dateConverted.getFullYear()}`;

                if (dataTimeDepthResponse.items[i].YPosition[0] > 0 || dataTimeDepthResponse.items[i].YPosition[1] > 0) {
                    dataTimeDepthResponse.items[i].YPosition[0] *= -1;
                    dataTimeDepthResponse.items[i].YPosition[1] *= -1;
                }

                const increment = lineType === LineType.Inline ? surveyMetadata!.Survey3DInfo.XlineIncrement : surveyMetadata!.Survey3DInfo.InlineIncrement;
                const start = calculator!.getMinTrace(surveyMetadata!);
                const timeDepthX =  getXByTrace(dataGeometryLine.Min + data.Trace * increment, scale.x, start, increment) + 1;
                //const timeDepthX = getXValue(dataTimeDepthResponse.items[i].XPosition, scale.x, dataGeometryLine.Min, start, increment);
                const timeDepthY = getYValue(dataTimeDepthResponse.items[i].YPosition[0], scale.y, calculator!.calculateSampleInterval(surveyMetadata!));

                let feature = createPointFeature(timeDepthX, timeDepthY, dataTimeDepthResponse.items[i].Labels[0], 'timeDepth', token, Math.round(dataTimeDepthResponse.items[i].Depths[0]), data.MesaRotativa, dataTimeDepthResponse.items[i].Id, date);

                timeDepthFeatures.push(feature);
            }

            const timeDepthLayer = new VectorLayer({ source: new VectorSource({ features: timeDepthFeatures }) });
            timeDepthLayer.setZIndex(99999991);

            map?.addLayer(timeDepthLayer);

            setWellLayers((data.Id).toString(), { timeDepth: { layer: timeDepthLayer, checked: true } });
        }

        loading.current = false;
    };

    const getTimeDepth = async (checked: boolean, loading: MutableRefObject<boolean>, onError: () => void, recreate: boolean = false) => {
        if ((domain !== null || domain !== undefined) && checked && surveyMetadata) {
            if (!wellLayers[(data.Id).toString()]) {
                fetch(loading, onError);
            }
            else if (!wellLayers[(data.Id).toString()]['timeDepth'] || recreate) {
                fetch(loading, onError);
            }
            else {
                let layer = wellLayers[(data.Id).toString()]['timeDepth'].layer;
                map?.addLayer(layer);
                setWellLayers((data.Id).toString(), { timeDepth: { layer: layer, checked: true } });
            }
        }
        else if ((domain !== null || domain !== undefined) && !checked && surveyMetadata) {
            let layer = wellLayers[(data.Id).toString()]['timeDepth'].layer;
            map?.removeLayer(layer);
            setWellLayers((data.Id).toString(), { timeDepth: { layer: layer, checked: false } });
        }
    };

    return { getTimeDepth: getTimeDepth };
}