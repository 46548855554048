import { useMutation, useQuery } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { AlineWeight } from '../models/IAlineWeight';
import { IRunAlineRequest } from '../models/IRunAlineRequest';
import { axios } from 'configurations/axios';

export function useGetAllAlineWeights() {
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));

    return useQuery(`alineWeightsGetAll`, async () => {
        const response = await axios.request<IResponseAPI<AlineWeight[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.customUrls['aline'] + '/ProjectCustomization/Eneva/GetAlineWeights',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    });
}
export function useExecuteAline(){
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    return useMutation(`runAline`, async (params: IRunAlineRequest)=>{
        const response = await axios.request<IResponseAPI<IRunAlineRequest>>({
            method: 'post',
            url: tenantConfig?.endpoints.customUrls['aline'] + '/ProjectCustomization/Eneva/ExecuteAline',
            params: params,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response;
    });
}

