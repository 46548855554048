import { useQuery } from 'react-query';
import { Coordinate } from 'ol/coordinate';

import { useSessionStore } from 'session/useSessionStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { axios } from 'configurations/axios';

interface paramsUpdateSeismic {
    point: Coordinate | null,
    lineString: string,
    lineLeft: number[] | Coordinate[] | null,
    lineRight: number[] | Coordinate[] | null
}

export function useUpdateMap(executeRequest: boolean, param: paramsUpdateSeismic | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    let enabledRequest = executeRequest;
    let url = tenantConfig?.endpoints?.signalR;

    if (url === null) {
        enabledRequest = false;
    }

    let queryKey = 'updateSeismic';

    if (param?.point) {
        queryKey = `updateSeismic ${param.point[0]} ${param.point[1]}`;
    }

    return useQuery(queryKey, () => {
        axios.request<IResponseAPI<string>>({
            method: 'post',
            url: url + '/SignalR/Notify',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                JWTToken: jwtToken,
                Method: 'updateSeismic',
                Params: param
            }
        });
    }, {
        refetchOnWindowFocus: false,
        enabled: enabledRequest,
        cacheTime: 0,
    });
};

export function useGetSurveyGeometry(volumeToken: string, volumeIdentifier: string | null, srid: number, enabled: boolean) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    const urlParams = getParamsFromUrl();

    return useQuery('getSurveyGeometry' + volumeToken + srid, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.lineGeometry + '/SeismicLayers/GetSurveyGeometry',
            data: {
                VolumeToken: urlParams.volumeToken, //TEMP - Como eu troco no TGS o volumeToken, após obter ele se torna o path novamente. ideia aqui é usar o original mesmo
                Srid: srid,
                volumeIdentifier: volumeIdentifier,
                sourceApi: urlParams.sourceApi
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    }, {
        enabled: (enabled && !!volumeToken && !!volumeIdentifier),
        staleTime: Infinity
    });
}