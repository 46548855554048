import { css, IconButton, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeEventHandler, FocusEventHandler, useEffect, useState } from 'react';

import { Reset } from 'components/icons/components';
import { WIDTH_CONNECTION_LANE } from '../consts/layotsConsts';
import { useCrossSectionConnectionStore } from '../stores/useCrossSectionConnectionStore';

const style = css({
    '& input': {
        width: 40
    }
});

export function CrossSectionConnectionChangeWidth(){
    const { connectionWidth, setConnectionWidth } = useCrossSectionConnectionStore(state => ({
        connectionWidth: state.connectionWidth,
        setConnectionWidth: state.setConnectionWidth
    }));

    const [value, setValue] = useState(connectionWidth);

    const { t } = useTranslation('crossSection');

    useEffect(() => {
        setValue(connectionWidth);
    }, [connectionWidth]);

    const onChangeInput:ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue(parseFloat(event.target.value));
    };

    const reset = () =>{
        setConnectionWidth(WIDTH_CONNECTION_LANE);
    };

    const onBlurInput:FocusEventHandler<HTMLInputElement> = (event) => {
        setConnectionWidth(parseFloat(event.target.value));
    };

    return <div css={style}>
        <fieldset>
            <legend>{t('connectionWidth')}</legend>
            <TextField
                value={value}
                onBlur={onBlurInput}
                onChange={onChangeInput}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton size='small' onClick={reset} color='primary'>
                            <Reset />
                        </IconButton>
                    </InputAdornment>
                }
                }
            />

        </fieldset>
    </div>;
}