import * as THREE from 'three';
import { Geometry, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'ol/geom';
import { BufferGeometry, CircleGeometry, CurvePath, LineBasicMaterial, Mesh, MeshBasicMaterial, ShapeGeometry } from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';

import { getOlGeometryFromWKT } from 'features/seismic-3d/components/projections/featureGeomPolygon';
import { FeatureGeom } from 'features/seismic-3d/models/classes/FeatureGeom';
import { GeopostScene } from '../scene/GeopostScene';
import { getThreeJsPointsFromOlGeometry, scaleGeometry } from '../utils/ScaleUtils';

export class LayersThreeJsFacade {
    scene: GeopostScene;

    constructor(scene: GeopostScene) {
        this.scene = scene;
    }

    private getShapeGeometryFromPoints = (points: THREE.Vector3[]) => {
        const shape = new THREE.Shape();
        shape.moveTo(points[0].x, points[0].z);
        points.splice(0, 1);
        points.forEach(point => shape.lineTo(point.x, point.z));
        const geometry = new THREE.ShapeGeometry(shape);
        return geometry;
    };

    private addPointLayerMesh = (olGeometry: MultiPoint, layerId: number, color: string) => {
        const coordinates = olGeometry.getCoordinates();
        const pointCloudGeometry = new THREE.BufferGeometry();
        const positions = new Float32Array(coordinates.length * 3);
        let i = 0;
        coordinates.forEach(coordinate => {
            positions[3 * i] = coordinate[0];
            positions[3 * i + 1] = 0;
            positions[3 * i + 2] = coordinate[1];
        });
        pointCloudGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

        const geometry = new CircleGeometry(0.05);
        geometry.rotateX(Math.PI / 2);
        geometry.center();
        const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide, transparent: true });
        const layerMesh = new THREE.InstancedMesh(geometry, material, coordinates.length);

        const auxObject = new THREE.Object3D();
        coordinates.forEach((coordinate, index) => {
            auxObject.position.set(coordinate[0], 0, coordinate[1]);
            auxObject.updateMatrix();
            layerMesh.setMatrixAt(index, auxObject.matrix);
        });
        layerMesh.instanceMatrix.needsUpdate = true;
        layerMesh.name = 'fill';
        const layerGroup = new THREE.Group();
        layerGroup.name = `layer-${layerId}`;
        layerGroup.add(layerMesh);

        this.scene.add(layerGroup);
    };

    public addLayerMesh = (layerId: number, geomData: FeatureGeom, fillColor: string, fillOpacity: number, strokeColor: string, mainFeatureCentroid: [number, number]) => {
        const olGeometry = getOlGeometryFromWKT(geomData.GeomWKT);
        scaleGeometry(olGeometry, mainFeatureCentroid);
        let fillGeometry: THREE.BufferGeometry | null = null;
        let contourGeometry: THREE.BufferGeometry | null = null;

        const layerGroup = new THREE.Group();
        layerGroup.name = `layer-${layerId}`;
        const contourGroup = new THREE.Group();
        contourGroup.name = 'contour';
        const fillGroup = new THREE.Group();
        fillGroup.name = 'fill';
        if (olGeometry instanceof MultiPolygon || olGeometry instanceof MultiLineString) {

            const contourMaterial = new THREE.LineBasicMaterial({ color: strokeColor, transparent: true});
            const fillMaterial = new THREE.MeshBasicMaterial({ color: fillColor, opacity: fillOpacity, transparent: true, side: THREE.DoubleSide });
            const fillMeshes: THREE.Mesh[] = [];
            const contourMeshses: THREE.Line[] = [];

            if (olGeometry instanceof MultiLineString) {

                olGeometry.getLineStrings().forEach(lineString => {
                    const points = getThreeJsPointsFromOlGeometry(lineString);
                    const geometry = new THREE.BufferGeometry().setFromPoints(points);
                    contourMeshses.push(new THREE.Line(geometry, contourMaterial));
                });
            }
            else {
                const polygons = olGeometry.getPolygons();

                polygons.forEach(polygon => {
                    const points = getThreeJsPointsFromOlGeometry(polygon);

                    const shape = new THREE.Shape();
                    shape.moveTo(points[0].x, points[0].z);
                    points.splice(0, 1);
                    points.forEach(point => shape.lineTo(point.x, point.z));
                    const fillGeometry = new ShapeGeometry(shape);
                    const fillMesh = new THREE.Mesh(fillGeometry, fillMaterial);
                    fillMesh.rotateX(Math.PI / 2);
                    fillMeshes.push(fillMesh);

                    const contourGeometry = new BufferGeometry().setFromPoints(points);
                    contourMeshses.push(new THREE.Line(contourGeometry, contourMaterial));
                });
            }

            fillGroup.add(...fillMeshes);
            contourGroup.add(...contourMeshses);
        }
        else if (olGeometry instanceof MultiPoint) {
            this.addPointLayerMesh(olGeometry, layerId, fillColor);
            return;
        }
        else {
            if (olGeometry instanceof LineString) {
                const points = getThreeJsPointsFromOlGeometry(olGeometry);
                contourGeometry = new THREE.BufferGeometry().setFromPoints(points);
            }
            else {
                const points = getThreeJsPointsFromOlGeometry(olGeometry);
                fillGeometry = this.getShapeGeometryFromPoints(points);
                contourGeometry = new THREE.BufferGeometry().setFromPoints(points);
            }

            if (!!fillGeometry) {
                const fillMaterial = new THREE.MeshBasicMaterial({ color: fillColor, opacity: fillOpacity, transparent: true, side: THREE.DoubleSide });
                const fillMesh = new THREE.Mesh(fillGeometry, fillMaterial);
                fillMesh.rotateX(1 / 2 * Math.PI);
                fillMesh.name = 'fill';
                fillGroup.add(fillMesh);
            }

            if (!!contourGeometry) {
                const contourMaterial = new THREE.LineBasicMaterial({ color: strokeColor, transparent: true });
                const contourMesh = olGeometry instanceof MultiPolygon ? new THREE.LineSegments(contourGeometry, contourMaterial) : new THREE.Line(contourGeometry, contourMaterial);
                contourMesh.name = 'contour';
                contourGroup.add(contourMesh);
            }
        }
        layerGroup.add(fillGroup, contourGroup);
        this.scene.add(layerGroup);
    };

    public removeLayerMesh = async (layerId: number) => {
        this.scene.removeMesh(`layer-${layerId}`);
    };

    public changeLayerColor = (layerId: number, color: string) => {
        const layerGroup = this.scene.getObjectByName(`layer-${layerId}`);
        if (layerGroup) {
            const layerFill = layerGroup.getObjectByName('fill');
            if (layerFill) {
                layerFill.traverse(node => {
                    const material = (node as THREE.Mesh).material;
                    if (material) {
                        (material as MeshBasicMaterial).color = new THREE.Color(color);
                    }
                });
            } else {
                const contour = layerGroup.getObjectByName('contour');
                contour?.traverse(node => {
                    const material = (node as THREE.Mesh).material;
                    if (material) {
                        (material as LineBasicMaterial).color = new THREE.Color(color);
                    }
                });
            }
        }
    };

    public changeLayerFillOpacity = (layerId: number, opacity: number) => {
        const layerGroup = this.scene.getObjectByName(`layer-${layerId}`);
        if (layerGroup) {
            const fill = layerGroup.getObjectByName('fill');
            if (!fill) {
                return;
            }
            if (fill.children.length > 0) {
                fill.traverse(node => {
                    const material = (node as THREE.Mesh).material;
                    if (material) {
                        (material as THREE.MeshBasicMaterial).opacity = opacity;
                    }
                });
            }
            else {
                const contour = layerGroup.getObjectByName('contour');
                if (contour) {
                    contour.traverse(node => {
                        const material = (node as THREE.Mesh).material;
                        if (material) {
                            (material as THREE.LineBasicMaterial).opacity = opacity;
                        }
                    });
                }
            }
        }
    };

    public setLayerMeshHeight = (layerId: number, height: number) => {
        const layerMesh = this.scene.getObjectByName(`layer-${layerId}`);
        if (layerMesh) {
            layerMesh.position.y = height;
        }
    };

}

