import React from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import { useGetLineGeometry, useGetTiles } from '../api/useSeismic3DInfoController';
import { useSessionStore } from 'session/useSessionStore';
import { LineType } from '../models/enums/LineType';
import { VolumeType } from '../models/enums/VolumeType';
import { lineMetadata } from '../models/types/LineMetadata';
import { Metadata3D } from '../models/types/Metadata3D';
import { GeopostScene } from '../threejs/scene/GeopostScene';
import { IColorbar } from 'features/seismic/models/interfaces/IColorbar';

export type Line2DProps = {
    seismicMetadata: Metadata3D;
    srid: number;
    scene: GeopostScene,
    onLoadEnd: () => void,
    colorbar: IColorbar
};

export const Line2D = ({ seismicMetadata, srid, scene, onLoadEnd, colorbar } : Line2DProps) => {
/*
    const seismic3DDefaults = useSessionStore(state => state.tenantConfiguration?.viewer3DDefaults);

    const seismic3DInfo = useMemo(() => seismicMetadata.Survey3DInfo, [seismicMetadata]);

    const seismicHeader = seismicMetadata?.Header;

    const [ samplesPerTrace, sampleInterval, byteSize ] = [
        seismicHeader.SamplesPerTrace,
        seismicHeader.SampleInterval,
        seismicHeader.ByteSize
    ];

    const [ traceLineStart, traceLineEnd, tracesIncrement ] = [
        seismic3DInfo.XlineStart,
        seismic3DInfo.XlineEnd,
        seismic3DInfo.XlineIncrement,
    ];

    const [ seismicVolumeToken, seismicVolumePath ] = [ seismicMetadata.VolumeToken ?? '', seismicMetadata.VolumePath ?? '' ];
    const [ seismicMaxSampleValue, seismicMinSampleValue ] = [ seismicMetadata.MaxSampleValue ?? 0, seismicMetadata.MinSampleValue ?? 0 ];

    const lineGeometryRequest = useMemo(() => ({
        lineNumber: 0,
        lineType: LineType.Inline,
        srid: srid,
        volumeType: VolumeType.Seismic2D,
        volumeToken: seismicMetadata.VolumeToken,
        dimensionName: ''
    }), [seismicMetadata]);

    const {data: lineGeometry } = useGetLineGeometry(lineGeometryRequest);

    const tileWidth = useMemo(() => seismic3DDefaults?.tile_width ? parseInt(seismic3DDefaults.tile_width) : GeopostThreeJsConstants.xDivisionFactor, [seismic3DDefaults]);

    const getTileRequests = useCallback((line: lineMetadata) => {
        const tileHeight = seismic3DDefaults?.tile_height ? parseFloat(seismic3DDefaults?.tile_height) : undefined;

        return getTileRequestsFromLine(SurveyType.Seismic2D, samplesPerTrace, sampleInterval, byteSize, tracesIncrement, traceLineStart,
            traceLineEnd, seismicVolumeToken, seismicVolumePath, seismicMinSampleValue, seismicMaxSampleValue,
            line.lineNumber, line.lineType, line.geometryMetadata,
            seismic3DInfo?.InlineStart ? seismic3DInfo?.InlineStart : 0,
            seismic3DInfo?.InlineEnd ? seismic3DInfo.InlineEnd : 0,
            seismic3DInfo?.XlineStart ? seismic3DInfo?.XlineStart : 0,
            seismic3DInfo?.XlineEnd ? seismic3DInfo?.XlineEnd : 0,
            seismic3DInfo?.InlineIncrement ? seismic3DInfo?.InlineIncrement : 0,
            seismic3DInfo?.XlineIncrement ? seismic3DInfo?.XlineIncrement : 0,
            colorbar.Name ?? 'binary', tileWidth, tileHeight);
    }, [seismic3DInfo]);

    const lineMetadata = useMemo<lineMetadata | null>(() => lineGeometry ? ({
        geometryMetadata: lineGeometry,
        lineNumber: 0,
        lineType: LineType.Inline
    }) : null, [lineGeometry]);

    const tileRequests = useMemo(() =>
        !!lineMetadata ?
            getTileRequests(lineMetadata)
            :
            []
    , [lineMetadata]);

    const tileResults = useGetTiles(tileRequests);

    const loadedTileResults = useMemo(() => tileResults.filter(result => !!result), [tileResults]);

    const addLineTiles = useCallback((line : lineMetadata) => {
        const [firstLineCoordinate, lastLineCoordinate] = getFirstAndLastCoordinatesFromGeomWkt(line.geometryMetadata.Line);
        const totalTiles = getTotalOfTilesFromLine(line.geometryMetadata.TotalTraces, tileWidth);
        scene.addLineTiles(firstLineCoordinate, lastLineCoordinate, totalTiles, line.lineNumber, line.lineType, getThreeJsYFactor(samplesPerTrace));
    }, [samplesPerTrace]);

    useEffect(() => {
        if (lineMetadata) {
            scene.addGridSection([lineMetadata], seismicMetadata.VolumeToken);
            addLineTiles(lineMetadata);
            scene.markLineAsLoading(lineMetadata.lineNumber, LineType.Inline);
            scene.showLineTiles(lineMetadata.lineNumber, LineType.Inline);
        }
    }, [lineMetadata]);

    useEffect(() => {
        if (loadedTileResults.length === tileResults.length && tileResults.length > 0) {
            loadedTileResults.forEach((loadedTileResult, index) => {
                const associatedTileRequest = tileRequests[index];
                if (!!loadedTileResult?.file && !loadedTileResult.error && associatedTileRequest) {
                    scene.addTileTexture(index, associatedTileRequest.lineNumber, associatedTileRequest.lineType, loadedTileResult.file);
                    onLoadEnd();
                    scene.markLineAsLoaded(associatedTileRequest.lineNumber, LineType.Inline);
                }
            });
        }
    }, [loadedTileResults]);*/

    return (<React.Fragment></React.Fragment>);
};