import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useExport } from '../api/useInterpretationController';
import { InterpretationExportMode } from '../models/enums/InterpretationExportMode';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';

export function useExportInterpretation(){
    const { mutateAsync: exportMutate } = useExport();

    const { volumeToken } = useSeismicStore(state => ({
        volumeToken: state.volumeToken
    }));

    const { lineNumber, lineType } = useLine3DNavigationStore(state => ({
        lineNumber: state.lineNumber,
        lineType: state.lineType
    }));

    const exportInterpretation = async (mode: InterpretationExportMode, interpretationToken: string) => {
        if (!volumeToken){
            return;
        }

        await exportMutate({
            mode: mode,
            lineNumber: lineNumber,
            orientation: lineType,
            sourceApi: 'geopost',
            volumeToken: volumeToken,
            token: interpretationToken
        });
    };

    return {
        exportInterpretation
    };
}