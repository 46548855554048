import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useEffect, useRef, useState } from 'react';
import { Feature, Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { DragBox, Draw, Modify } from 'ol/interaction';
import { LineString, LinearRing, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'ol/geom';
import { useDebouncedCallback } from 'use-debounce';
import { OverlayOp } from 'jsts/org/locationtech/jts/operation/overlay';
import OL3Parser from 'jsts/org/locationtech/jts/io/OL3Parser';
import { useTranslation } from 'react-i18next';
import alertify from 'alertifyjs';
import { EventsKey } from 'ol/events';
import { unByKey } from 'ol/Observable';
import { LineCoordType } from 'ol/interaction/Draw';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

import { IGetCoordinatesResponse } from '../models/interfaces/IGetCoordinatesResponse';
import { useMapStore } from 'features/seismic/stores/useMapStore';
import { InterpretationMode } from '../models/enums/InterpretationMode';
import { IUseInterpretation } from '../models/interfaces/IUseInterpretation';
import { useGetCross, useMutationGetCoordinates, useSaveAllCoordinates } from '../api/useInterpretationController';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { buildCoordinatesToSave, buildLayerStyle, removeInteractions, buildLayer as buildOLLayer, buildCrossLayerStyle } from '../utils/interpretationLayerUtils';
import { ICoordinateString } from 'features/seismic/models/interfaces/ICoordinate';
import { getTrace, getXByScale, getXByTrace, getYByAmplitude, getYByScale } from 'features/seismic/utils/calculateScreenValuesUtils';
import { GeopostMap } from 'features/seismic/models/classes/GeopostMap';
import { IGetCrossItem, IGetCrossResponse } from '../models/interfaces/IGetCrossResponse';
import { createOverlay } from '../utils/interpretationOverlayCrossUtils';
import { hasCrossLayerVisible, hasLayerVisible } from '../utils/interpretationUtils';
import { IInterpretationInformation } from '../models/interfaces/IInterpretationInformation';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';

const jstsParser = new OL3Parser();
jstsParser.inject(Point,
    LineString,
    LinearRing,
    Polygon,
    MultiPoint,
    MultiLineString,
    MultiPolygon);

const sourceApi = 'geopost';
const delayInMsToSaveAllCoordinates = 2000;

const crossSettings: ICrossSettings = {
    delayToClose: 500,
    featureId: '',
    featureType: 'INTERPRETATION_CROSS',
    mouseInsideOverlay: false,
};

export function useInterpretation2DSeismicViewer(): IUseInterpretation {
    //hooks
    const { t } = useTranslation();
    const interpretations = useRef<{ [token: string]: IInterpretationDictionary }>({});

    const modifyCoordinatesLastSaved = useRef<Feature[] | null>(null);

    const abortSaveAllCoordinates = useRef<AbortController | null>(null);

    const [informations, setInformations] = useState<{ [token: string]: IInterpretationInformation }>({});

    const [interpretationEditing, setInterpretationEditing] = useState('');

    const { mutateAsync: mutateGetCoordinates } = useMutationGetCoordinates();

    const { mutateAsync: mutateSaveAllCoordinates } = useSaveAllCoordinates();

    const { mutateAsync: mutateGetCross } = useGetCross();

    const { calculator, surveyMetadata, geometryLine, map, volumeToken, lineNumber, lineType, scaleX, scaleY, lineIdentifier } = useGetStore();

    const saveAllCoordinatesDebounce = useDebouncedCallback(async (token: string, coordinates: Array<Array<ICoordinateString>>) => {
        if (!volumeToken) {
            return;
        }

        if (abortSaveAllCoordinates.current) {
            abortSaveAllCoordinates.current.abort();
        }

        abortSaveAllCoordinates.current = new AbortController();

        try {
            await mutateSaveAllCoordinates({
                interpretationToken: token,
                orientation: lineType,
                sourceApi: sourceApi,
                lineNumber,
                volumeToken,
                coordinates,
                signal: abortSaveAllCoordinates.current.signal
            });

            abortSaveAllCoordinates.current = null;
        }
        catch (exception) {
            console.error(exception);
        }

    }, delayInMsToSaveAllCoordinates);

    useEffect(() => {
        if (lineIdentifier) {
            refresh();
        }
    }, [lineIdentifier, scaleX, scaleY]);

    //endhooks

    //helpers
    const buildLayer = async (token: string, mode: InterpretationMode) => {
        if (interpretations.current[token] && interpretations.current[token].layer) {
            interpretations.current[token].mode = mode;
            interpretations.current[token].layer?.setVisible(true);
            return interpretations.current[token].layer;
        }

        const layer = buildOLLayer();

        if (interpretations.current[token]){
            interpretations.current[token].layer = layer;
            interpretations.current[token].checked = true;
            interpretations.current[token].mode = mode;
        }
        else {
            interpretations.current[token] = {
                checked: true,
                crossChecked: false,
                layer: layer,
                mode: mode
            };
        }

        map?.addLayer(layer);

        await getCoordinates(token, mode);

        return layer;
    };

    const getCoordinates = async (token: string, mode: InterpretationMode) => {
        if (!volumeToken) {
            console.error('Volume Token nulo para obter coordenadas');
            return;
        }

        setInformations(state => ({
            ...state,
            [token]: {
                ...state[token],
                mode: mode,
                isLoading: true
            }
        }));

        const result = await mutateGetCoordinates({
            lineNumber: lineNumber,
            orientation: lineType,
            volumeToken: volumeToken,
            horizonToken: token,
            sourceApi
        });

        const response = result.data.Result;

        interpretations.current[token].response = response;

        insertFeatureInLayer(token, mode, response);

        setInformations(state => ({
            ...state,
            [token]: {
                ...state[token],
                isLoading: false
            }
        }));
    };

    const getItem = (token: string) => interpretations.current[token];

    const insertFeatureInLayer = (token: string, mode: InterpretationMode, response: IGetCoordinatesResponse) => {
        if (!interpretations.current[token]?.layer || !calculator || !surveyMetadata) {
            return;
        }

        const layer = interpretations.current[token].layer!;

        buildLayerStyle(layer, response.data, mode);

        const featuresOpenLayers: Array<Feature> = [];

        const minTrace = calculator.getMinTrace(surveyMetadata);

        for (let feature of response.features) {
            const coordinates: Array<Coordinate> = [];
            for (let coordinate of feature.Coordinates) {
                const x = getXByScale(coordinate.X, scaleX, minTrace);
                const y = getYByScale(coordinate.Y, scaleY);

                coordinates.push([x, y]);
            }

            if (coordinates.length === 0) {
                continue;
            }

            const featureOpenLayers = new Feature({
                geometry: new LineString(coordinates)
            });

            featuresOpenLayers.push(featureOpenLayers);
        }

        layer.getSource()!.addFeatures(featuresOpenLayers);
    };

    const conditionToDraw = (x: number) => {
        if (!surveyMetadata){
            return false;
        }

        if (surveyMetadata.Type === SurveyType.Seismic3D){
            return conditionToDraw3D(x);
        }

        return conditionToDraw2D(x);
    };

    const alertToDrawInsideSeismic = () => {}; //alertify.notify(t('interpretation.drawInsideExtent'), 'error');

    const conditionToDraw2D = (x: number) => {
        if (!map){
            return false;
        }

        const extent = map.getOriginalExtent();

        if (!extent || extent.length === 0){
            return false;
        }

        const [minX,,maxX] = extent;

        if (x < minX || x > maxX) {
            alertToDrawInsideSeismic();
            return false;
        }

        return true;
    };

    const conditionToDraw3D = (x: number) => {
        if (!calculator || !geometryLine || !surveyMetadata) {
            return false;
        }

        const increment = calculator.getLineIncrement(surveyMetadata);
        const start = calculator.getMinTrace(surveyMetadata);

        const trace = getTrace(x, scaleX, start, increment);

        if (trace < geometryLine.Min || trace > geometryLine.Max) {
            alertToDrawInsideSeismic();
            return false;
        }

        return true;
    };

    const startEdit = async (token: string, map: GeopostMap, scaleX: number, scaleY: number) => {
        setInterpretationEditing(token);
        const layer = await buildLayer(token, InterpretationMode.edit);
        removeEventPointerMoveCross();

        const item = getItem(token);

        if (layer && item && map) {
            item.mode = InterpretationMode.edit;
            buildLayerStyle(layer, item.response!.data, InterpretationMode.edit);

            const source = layer.getSource();

            if (source) {
                item.modify = new Modify({
                    source: source
                });

                item.draw = new Draw({
                    type: 'LineString',
                    source: source,
                    condition: (event) => conditionToDraw(event.coordinate[0]),
                    geometryFunction: (coordinates, geom) => {
                        if (!geom) {
                            geom = new LineString([]);
                        }

                        const featureCoordinates = coordinates as LineCoordType;
                        const newCoordinates:Array<Coordinate> = [];

                        for (let i=0; i < featureCoordinates.length; i++) {
                            const condition = conditionToDraw(featureCoordinates[i][0]);

                            if (condition){
                                newCoordinates.push(featureCoordinates[i]);
                            }
                        }

                        geom.setCoordinates(newCoordinates);

                        return geom;
                    }
                });

                map.addInteraction(item.modify);
                map.addInteraction(item.draw);

                item.modify.on('modifystart', (event) => {
                    const features = layer.getSource()!.getFeatures();

                    modifyCoordinatesLastSaved.current = features.map(x => x.clone());

                    stopSaveRequest();
                });

                item.draw.on('drawstart', stopSaveRequest);

                item.modify.on('modifyend', (event) => {
                    const feature = event.features.getArray()[0];
                    const lineString = feature.getGeometry() as LineString;
                    const featureCoordinates = lineString.getCoordinates();

                    let needToBackFeature = false;

                    for (let coordinate of featureCoordinates) {
                        const condition = conditionToDraw(coordinate[0]);

                        if (!condition) {
                            needToBackFeature = true;
                            break;
                        }
                    }

                    if (needToBackFeature) {
                        const source = layer.getSource()!;
                        if (modifyCoordinatesLastSaved.current) {
                            source.clear();
                            source.addFeatures(modifyCoordinatesLastSaved.current);
                        }

                        modifyCoordinatesLastSaved.current = null;
                    }
                    else {
                        const coordinates = buildCoordinatesToSave(layer.getSource()!.getFeatures(), scaleX, scaleY, calculator?.getMinTrace(surveyMetadata!) ?? 1);
                        saveAllCoordinatesDebounce(token, coordinates);
                    }
                });

                item.draw.on('drawend', (event) => {
                    const features = [...layer.getSource()!.getFeatures(), event.feature];
                    const coordinates = buildCoordinatesToSave(features, scaleX, scaleY, calculator?.getMinTrace(surveyMetadata!) ?? 1);
                    saveAllCoordinatesDebounce(token, coordinates);
                });
            }
        }
    };

    const startErase = (token: string, item: IInterpretationDictionary, map: GeopostMap) => {
        const layer = item?.layer;

        if (!item) {
            return;
        }

        item.mode = InterpretationMode.erase;

        item.dragbox = new DragBox({
            className: 'seismic-interpretation-delete'
        });

        map.addInteraction(item.dragbox);

        item.dragbox.on('boxdrag', (event) => {
            if (!layer || !map) {
                return;
            }

            const geometry = event.target.getGeometry() as Polygon;
            const extentMap = map.getView().calculateExtent(map.getSize());
            const geometryClone = geometry.clone();
            const deleteDragExtent = geometry.getExtent();
            geometryClone.setCoordinates([[[deleteDragExtent[0], extentMap[3]], [deleteDragExtent[2], extentMap[3]], [deleteDragExtent[2], extentMap[1]], [deleteDragExtent[0], extentMap[1]], [deleteDragExtent[0], extentMap[3]]]]);
            const jstsPolygonDragbox = jstsParser.read(geometryClone);
            const source = layer.getSource();
            const features = source?.getFeatures();

            if (!features || !source) {
                return;
            }

            for (const feature of features) {
                const jstsLineStringFeature = jstsParser.read(feature.getGeometry());
                const overlayOperation = new OverlayOp(jstsLineStringFeature, jstsPolygonDragbox);
                const coordinateIntersection = overlayOperation.getResultGeometry(OverlayOp.INTERSECTION).getCoordinates();

                if (coordinateIntersection.length > 0) {
                    const difference = overlayOperation.getResultGeometry(OverlayOp.DIFFERENCE);
                    const numGeom = difference.getNumGeometries() / 2;
                    source.removeFeature(feature);

                    for (let i = 0; i < numGeom; i++) {
                        const geom = difference.getGeometryN(i);
                        const newGeom = jstsParser.write(geom);
                        if (newGeom.getCoordinates().length > 0) {
                            const feature = new Feature({
                                geometry: newGeom
                            });

                            source.addFeature(feature);
                        }
                    }
                }
            }

            const coordinates = buildCoordinatesToSave(source.getFeatures(), scaleX, scaleY, calculator?.getMinTrace(surveyMetadata!) ?? 1);
            saveAllCoordinatesDebounce(token, coordinates);
        });
    };

    const startView = (token: string, item?: IInterpretationDictionary, map?: GeopostMap | null) => {
        if (interpretationEditing === token) {
            setInterpretationEditing('');
        }

        if (item) {
            item.mode = InterpretationMode.view;

            if (item.layer) {
                item.layer.setVisible(true);
                buildLayerStyle(item.layer, item.response!.data, InterpretationMode.view);
                return;
            }
        }

        buildLayer(token, InterpretationMode.view);
    };

    const stopSaveRequest = () => {
        if (abortSaveAllCoordinates.current) {
            abortSaveAllCoordinates.current.abort();
        }

        abortSaveAllCoordinates.current = null;
    };

    const updateChecked = (token: string, checked: boolean, property: 'checked' | 'crossChecked') => {
        setInformations(state => ({
            ...state,
            [token]: {
                ...state[token],
                [property]: checked
            }
        }));

        const item = getItem(token);

        if (item) {
            item[property] = checked;
        }
    };
    //endHelpers

    //cross
    const clickShowCross = (token: string) => {
        if (!interpretations.current[token]){
            interpretations.current[token] = {
                checked: true,
                crossChecked: false
            };
        }

        if (!interpretations.current[token].crossChecked){
            updateChecked(token, true, 'crossChecked');
            showCross(token, interpretations.current[token]);
        }
        else {
            updateChecked(token, false, 'crossChecked');
            hideCross(interpretations.current[token]);
        }
    };

    const getCross = async (token: string, mode: InterpretationMode) => {
        if (!volumeToken) {
            console.error('Volume Token nulo para obter cross');
            return;
        }

        if (!map || !surveyMetadata || !calculator) {
            return;
        }

        try {
            setInformations(state => ({
                ...state,
                [token]: {
                    ...state[token],
                    mode: mode,
                    isLoading: true
                }
            }));

            const crossApiResult = await mutateGetCross({
                interpretationToken: token,
                lineNumber: lineNumber,
                orientation: lineType,
                volumeToken: volumeToken
            });

            const crossResponse = crossApiResult.data.Result;

            const layer = buildOLLayer(true, 1);
            layer.setStyle(buildCrossLayerStyle(crossResponse.Data.Color));
            interpretations.current[token].crossLayer = layer;

            map.addLayer(layer);

            const increment = calculator.getLineIncrement(surveyMetadata);
            const start = calculator.getMinTrace(surveyMetadata);
            const sampleInterval = calculator.calculateSampleInterval(surveyMetadata);

            for (let crossItem of crossResponse.Crosses) {
                if (crossItem.InterpretationToken === token) {
                    continue;
                }

                const x = getXByTrace(crossItem.Trace, scaleX, start, increment);
                const y = getYByAmplitude(crossItem.Z, scaleY, sampleInterval);
                const feature = new Feature({
                    geometry: new Point([x, y]),
                });

                feature.set('type', crossSettings.featureType);
                feature.set('crossItem', crossItem);

                layer.getSource()!.addFeature(feature);
            }

            if (mode === InterpretationMode.view) {
                startEventPointerMoveCross(map, t('openSeismic'));
            }

            setInformations(state => ({
                ...state,
                [token]: {
                    ...state[token],
                    mode: mode,
                    isLoading: false
                }
            }));
        }
        catch (exception) {
            console.error(exception);

            setInformations(state => ({
                ...state,
                [token]: {
                    ...state[token],
                    isLoading: false,
                    errorMessage: t('interpretation.warningGetCross')
                }
            }));
        }
    };

    const hideCross = (item: IInterpretationDictionary) =>{
        if (item.crossLayer) {
            item.crossLayer.setVisible(false);
        }

        if (!hasCrossLayerVisible(interpretations.current)) {
            removeEventPointerMoveCross();
        }
    };

    const showCross = (token: string, item: IInterpretationDictionary) =>{
        if (item.crossLayer) {
            item.crossLayer.setVisible(true);

            if (map) {
                startEventPointerMoveCross(map, t('openSeismic'));
            }

            return;
        }

        getCross(token, InterpretationMode.view);
    };

    const displayCrossIcon = (token: string) => {
        let color:'primary'|'warning' = 'primary';
        if (interpretations.current[token] && interpretations.current[token].crossChecked){
            color = 'warning';
        }

        return <AutoGraphIcon color={color}/>;
    };
    //fim do cross

    //items to export
    const check = (token: string) => {
        updateChecked(token, true, 'checked');

        startView(token, getItem(token), map);
    };

    const remove = (token: string) => {
        const item = getItem(token);

        if (item) {
            delete interpretations.current[token];

            if (map && item.layer) {
                map.removeLayer(item.layer);
                removeInteractions(item, map);
            }
        }

        setInformations(state => ({
            ...state,
            [token]: {
                checked: false,
                crossChecked: false,
                mode: InterpretationMode.view,
                isLoading: false
            }
        }));
    };

    const reset = () => {
        //usado quando fecha janela
        for (let token in interpretations.current) {
            let item = interpretations.current[token];

            if (map) {
                if (item.layer) {
                    map.removeLayer(item.layer);
                }

                if (item.crossLayer) {
                    map.removeLayer(item.crossLayer);
                }

                removeInteractions(item, map);
            }

            delete interpretations.current[token];
        }

        setInformations({});
    };

    const refresh = (token?: string) => {
        if (!map) {
            return;
        }

        let tokens = (token) ? [token] : Object.keys(interpretations.current);

        for (let token of tokens) {
            const item = interpretations.current[token];

            delete item.response;

            if (item.crossLayer) {
                map.removeLayer(item.crossLayer);
                delete item.crossLayer;

                if (item.crossChecked){
                    showCross(token, item);
                }
            }

            if (item.layer) {
                map.removeLayer(item.layer);
                delete item.layer;

                if (item.checked) {
                    //debugger;
                    if (item.mode === InterpretationMode.erase){
                        item.mode = InterpretationMode.edit;
                    }
                    setMode(token, item.mode ?? InterpretationMode.view);
                    //buildLayer(token, item.mode ?? InterpretationMode.view);
                }
            }
        }
    };

    const uncheck = (token: string) => {
        updateChecked(token, false, 'checked');

        if (interpretations.current[token]?.layer) {
            interpretations.current[token].layer?.setVisible(false);
        }
    };

    const setMode = (token: string, mode: InterpretationMode) => {
        if (!map || (mode === InterpretationMode.edit && interpretationEditing && interpretationEditing !== token)) {
            //essa segunda condição impede que dois elementos sejam habilitados pra edição ao mesmo tempo
            //ele tem um tratamento pra questão de qnd entrar em modo de deleção, pois ai ele volta pro modo de edição
            return;
        }

        const item = interpretations.current[token];
        removeInteractions(item, map);

        setInformations(state => ({
            ...state,
            [token]: {
                ...state[token],
                checked: true,
                mode: mode
            }
        }));

        if (mode === InterpretationMode.edit) {
            startEdit(token, map, scaleX, scaleY);
        }
        else if (mode === InterpretationMode.erase) {
            startErase(token, item, map);
        }
        else if (mode === InterpretationMode.view) {
            startView(token, item, map);
        }
    };

    //end items to export

    return {
        customIcons: [{
            element: displayCrossIcon,
            click: clickShowCross,
            key: 'cross',
            title: 'Check crossing points',
        }],
        check,
        remove,
        refresh,
        uncheck,
        informations,
        setMode,
        reset,
        isLocked: !!(interpretationEditing)
    };
}

function useGetStore() {
    const { map } = useMapStore(state => ({
        map: state.map
    }));

    const { calculator, geometryLine, volumeToken, scale, surveyMetadata } = useSeismicStore(state => ({
        calculator: state.calculator,
        geometryLine: state.geometryLine,
        scale: state.scale,
        surveyMetadata: state.surveyMetadata,
        volumeToken: state.volumeToken
    }));

    const { lineNumber, lineType, lineIdentifier } = useLine3DNavigationStore(state => ({
        lineIdentifier: state.lineIdentifier,
        lineNumber: state.lineNumber,
        lineType: state.lineType
    }));

    const { x: scaleX, y: scaleY } = scale;

    return {
        calculator,
        geometryLine,
        lineIdentifier,
        lineNumber,
        lineType,
        map,
        scaleX,
        scaleY,
        surveyMetadata,
        volumeToken
    };
}

function mouseEnterCrossCallback() {
    crossSettings.mouseInsideOverlay = true;
}

function mouseOutCrossCallback(map: GeopostMap) {
    crossSettings.mouseInsideOverlay = false;
}

function removeEventPointerMoveCross() {
    if (crossSettings.pointerMoveEventKey) {
        unByKey(crossSettings.pointerMoveEventKey);
    }
}

function startEventPointerMoveCross(map: GeopostMap, openLabel: string) {
    removeEventPointerMoveCross();

    crossSettings.pointerMoveEventKey = map.on('pointermove', event => {
        console.log('pointer move');
        if (crossSettings.mouseInsideOverlay) {
            return;
        }
        const features = map.getFeaturesAtPixel(event.pixel);

        let hasFeatures = false;

        for (let feature of features) {
            if (feature.get('type') === crossSettings.featureType) {
                clearInterval(crossSettings.timeOutCloseId);
                crossSettings.timeOutCloseId = undefined;

                const crossItem = feature.get('crossItem') as IGetCrossItem;

                hasFeatures = true;

                if (crossSettings.featureId === crossItem.InterpretationToken) {
                    break;
                }

                crossSettings.featureId = crossItem.InterpretationToken;

                if (crossSettings.overlay) {
                    map.removeOverlay(crossSettings.overlay);
                }

                crossSettings.overlay = createOverlay(event.coordinate, {
                    openLabel: openLabel,
                    lineNumber: crossItem.LineNumber,
                    lineOrientation: crossItem.Orientation,
                    volumeToken: crossItem.VolumeToken,
                    seismicName: crossItem.VolumeName,
                    volumeType: crossItem.Type
                }, mouseEnterCrossCallback, mouseOutCrossCallback.bind(null, map));

                map.addOverlay(crossSettings.overlay);

                break;
            }
        }

        if (!hasFeatures && !crossSettings.timeOutCloseId && crossSettings.overlay) {
            crossSettings.featureId = '';

            crossSettings.timeOutCloseId = setTimeout(() => {
                crossSettings.timeOutCloseId = undefined;
                if (crossSettings.overlay && !crossSettings.mouseInsideOverlay) {
                    map.removeOverlay(crossSettings.overlay);
                    crossSettings.overlay = undefined;
                }
            }, crossSettings.delayToClose);
        }
    });
}

export interface IInterpretationDictionary {
    checked: boolean;
    crossChecked: boolean;
    crossLayer?: VectorLayer<VectorSource>;
    layer?: VectorLayer<VectorSource>;
    mode?: InterpretationMode;
    draw?: Draw;
    modify?: Modify;
    dragbox?: DragBox;
    response?: IGetCoordinatesResponse;
}

interface ICrossSettings {
    delayToClose: number;
    featureId: string;
    featureType: string;
    mouseInsideOverlay: boolean;
    pointerMoveEventKey?: EventsKey;
    overlay?: Overlay;
    timeOutCloseId?: ReturnType<typeof setTimeout>;
}