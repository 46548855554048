import { css } from '@emotion/react';
import { Box, Button, Popper, TextField, Tooltip, Typography, Zoom } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PublishIcon from '@mui/icons-material/Publish';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import { IAttachmentsMetaData, IContractFiles } from 'fileIndexing/contracts/interfaces/IContract';
import { GeopostLoading } from 'fileIndexing/otherComponents/GeopostLoading';
import alertify from 'alertifyjs';

import { useGetContractByToken, useGetContractFilesByToken, useOpenAttachmentByPath, useRemoveAttachment, useSaveContract, useSaveContractFiles } from '../useContracts';

const style = css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    '& .dropzone': {
        marginLeft: '20px',
        height: '100%',
    },
    '& .files-search': {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: '20px',
    },
    '& .search': {
        width: '100%',
    },
    '& .publish-button': {
        height: '100%',
        backgroundColor: '#041F3C',
        color: '#ffffff',
        width: '64px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        // padding: '6px 16px',
        borderRadius: '4px',
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
    },
    '& .files': {
        padding: '10px',
        width: 'auto',
        height: '100%',
        backgroundColor: '#80808040',
        borderRadius: '5px',
        overflowY: 'auto',
        '& ul': {
            margin: '0px',
        }
    },
    '& .file-row': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    '& .delete-icon': {
        margin: '0 0 0 1rem',
    },
});

export const ContractsFiles = ({ token }: Props) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [sendData, setSendData] = useState<IContractFiles | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [attachmentToken, setAttachmentToken] = useState<string>('');
    const [deleteToken, setDeleteToken] = useState<string>('');

    const [filteredData, setFilteredData] = useState<IAttachmentsMetaData[] | undefined>([]);
    const [filter, setFilter] = useState<string>('');

    const { data: removeAttachment } = useRemoveAttachment(deleteToken);
    const { data: openAttachment } = useOpenAttachmentByPath(attachmentToken);
    const { data: saveContractsData } = useSaveContractFiles(sendData);
    const { data: contractFilesGetByToken, isLoading, refetch } = useGetContractFilesByToken(token);

    const openFile = (token: string) => {
        setAttachmentToken(token);
    };

    const deleteFile = (name: string, token: string) => {
        alertify.confirm(`Do you really wanna delete the file "${name}"?`, async () => {
            setDeleteToken(token);
        });
    };

    useEffect(() => {
        if (contractFilesGetByToken) {
            setFilteredData(contractFilesGetByToken.AttachmentsMetaData);
        }
    }, [contractFilesGetByToken]);

    // realiza o filtro
    useEffect(() => {
        if (contractFilesGetByToken) {
            if (filter === '') {
                setFilteredData(contractFilesGetByToken.AttachmentsMetaData);
            }
            else {
                setFilteredData(contractFilesGetByToken.AttachmentsMetaData.filter(x => x.FileName?.toLowerCase().includes(filter.toLowerCase())));
            }
        }
    }, [filter]);

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            let newData = contractFilesGetByToken;
            if (newData) {
                if (!newData.Files) {
                    newData.Files = [];
                }

                for (let i = 0; i < acceptedFiles.length; i++) {
                    newData.Files.push(acceptedFiles[i]);
                }
                setSendData(newData);
            }
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (openAttachment) {
            const newWindow = window.open(openAttachment, '_blank');
            if (newWindow) {
                newWindow.focus();
            }
        }
    }, [openAttachment]);

    function wait(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function reloadData(): Promise<void> {
        refetch();
        setLoading(true);
        await wait(2000);
        setLoading(false);
    }

    useEffect(() => {
        if (saveContractsData) {
            if (saveContractsData === 200) {
                reloadData();
            }
            else {
                alertify.notify('An error occurred sending the new Contract File');
            }
        }
    }, [saveContractsData]);

    useEffect(() => {
        if (removeAttachment) {
            if (removeAttachment) {
                reloadData();
            }
            else {
                alertify.notify('An error occurred deleting the new Contract File');
            }
        }
    }, [removeAttachment]);

    return (
        <Fragment>
            <Box css={style}>
                <GeopostLoading loading={isLoading || loading} />
                <Box className='files-search'>
                    <Box className='search'>
                        <TextField label='Search file' variant='outlined' size='small' color='primary' fullWidth value={filter} onChange={(event) => setFilter(event.target.value)} />
                    </Box>
                    <Box {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <Tooltip
                            title="Drag and drop files here, or click to select files"
                            placement='top'
                            arrow
                            TransitionComponent={Zoom}
                            slots={{
                                popper: (props) => <Popper {...props} disablePortal={true} sx={{ zIndex: 2, width: '100px', textAlign: 'justify' }} />
                            }}
                        >
                            <Box /* variant='contained' color='primary' */ className='publish-button'>
                                <PublishIcon />
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
                <Box className='files'>
                    <Typography>Files</Typography>
                    <ul>
                        {filteredData?.map((file) => (
                            <li key={file.Token}>
                                <Box className='file-row'>
                                    <Typography>{file.FileName}</Typography>
                                    <Box className='icons-row'>
                                        <Button variant='contained' color='primary' onClick={() => openFile(file.Token)}>
                                            <FileOpenIcon />
                                        </Button>
                                        <Button variant='contained' color='error' className='delete-icon' onClick={() => deleteFile(file.FileName, file.Token)}>
                                            <DeleteIcon />
                                        </Button>
                                    </Box>
                                </Box>
                            </li>
                        ))}
                    </ul>
                </Box>
            </Box>
        </Fragment>
    );
};

interface Props {
    token: string;
}