import { CacheSystem } from 'features/seismic/models/enums/CacheSystem';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { LineType } from '../models/enums/LineType';
import { lineMetadata } from '../models/types/LineMetadata';
import { LineStringResponse } from '../models/types/LineStringResponse';
import { Metadata3D } from '../models/types/Metadata3D';
import { Metadata3DHeader } from '../models/types/Metadata3DHeader';
import { Survey3DInfo } from '../models/types/Survey3DInfo';
import { TileRequest } from '../models/types/TileRequest';
import { GeopostThreeJsConstants } from '../threejs/utils/GeopostThreeJsConstants';
import { getUrlParams } from './Seismic3DUrlUtils';

//const divisionXFactor = 125;
//const divisionXFactor = 875;

export const useGetSeismic3DParamsXLine = 0;

export const getTotalOfTilesFromLine = (totalTraces: number, tileWidth: number) => {
    const threeJsXFactor = Math.abs(totalTraces / tileWidth);
    const threeXTotalTiles = Math.ceil(threeJsXFactor);
    return threeXTotalTiles;
};

export const getThreeJsYFactor = (samplesPerTrace: number) => {
    return Math.abs(samplesPerTrace / GeopostThreeJsConstants.yDivisionFactor);
};

export const getRelativeLinesToLoadInFirstIteration = (initialRange: number, totalLines: number) => {
    const linesToLoad : number[] = [];
    const lineSkipFactor = Math.floor(totalLines / initialRange);
    linesToLoad.push(0);
    for (let i = 1; i < initialRange; i++){
        const relativeLineToLoad = i * lineSkipFactor;
        //const lineToLoad = lineStart + lineIncrement * relativeLineToLoad;
        linesToLoad.push(relativeLineToLoad);
    }
    linesToLoad.push(totalLines - 1);
    return linesToLoad;
};

export const getDeeperRelativeLinesToLoad = (loadedLines: number[]) => {
    const updatedLoadedLines = [...loadedLines];
    for (let index = 1; index < updatedLoadedLines.length; index++){
        const previousLine = updatedLoadedLines[index - 1];
        const line = updatedLoadedLines[index];
        let lineNumberToLoad = Math.floor((line - previousLine) / 2) + previousLine;
        if (lineNumberToLoad === previousLine){
            lineNumberToLoad++;
        }
        if (lineNumberToLoad === line){
            lineNumberToLoad--;
        }
        if (lineNumberToLoad !== previousLine && lineNumberToLoad !== line){
            updatedLoadedLines.splice(index, 0, lineNumberToLoad);
            index += 1;
        }
    }
    return updatedLoadedLines;
};

export const getRealLineValue = (relativeValue: number, lineStart: number, lineIncrement: number, offset: number = 0) => {
    return lineStart + lineIncrement * relativeValue + Math.round(offset) ;
};

export const sortLinesFromTheMiddle = (lines: lineMetadata[]) => {
    lines.sort((a, b) => a.lineNumber - b.lineNumber);
    const middleIndex = Math.round(lines.length / 2);
    const pivot = lines[middleIndex];
    lines.sort((a, b) => Math.abs(a.lineNumber - pivot.lineNumber) - Math.abs(b.lineNumber - pivot.lineNumber) || b.lineNumber - a.lineNumber);
};

export const sortLineNumbersFromTheMiddle = (lineNumbers: number[]) => {
    lineNumbers.sort((a, b) => a - b);
    const middleIndex = Math.round(lineNumbers.length / 2);
    const pivot = lineNumbers[middleIndex];
    lineNumbers.sort((a, b) => Math.abs(a - pivot) - Math.abs(b - pivot) || b - a);
};