import * as THREE from 'three';
import { CatmullRomCurve3 } from 'three/src/Three';

import { getScaledDepth } from '../utils/ScaleUtils';

export class GeopostLithologyMesh extends THREE.Mesh {
    constructor(wellPoints: THREE.Vector3[], topYPosition: number, bottomYPosition: number, heightFactor: number ) {
        const scaledTopYPosition = getScaledDepth(topYPosition, heightFactor);
        const scaledBottomYPosition = getScaledDepth(bottomYPosition, heightFactor);

        const [ closestPointToTopIndex, closestPointToBottomIndex ] = wellPoints.reduce<[number, number]>((actualResult, current, currentIndex) => {
            const newResult = actualResult;

            if (Math.abs(Math.abs(scaledTopYPosition) - Math.abs(current.y)) < Math.abs(Math.abs(scaledTopYPosition) - Math.abs(wellPoints[actualResult[0]].y))) {
                newResult[0] = currentIndex;
            }
            if (Math.abs(Math.abs(scaledBottomYPosition) - Math.abs(current.y)) < Math.abs(Math.abs(scaledBottomYPosition) - Math.abs(wellPoints[actualResult[1]].y))){
                newResult[1] = currentIndex;
            }

            return newResult;
        }, [0, 0]);

        const relevantCrossingPoints = wellPoints.length > 3 ? wellPoints.slice(closestPointToTopIndex, closestPointToBottomIndex) : [];
        const closestPointToTop = wellPoints[closestPointToTopIndex];
        const closestPointToBottom = wellPoints[closestPointToBottomIndex];

        const firstPoint = new THREE.Vector3(closestPointToTop.x, scaledTopYPosition, closestPointToTop.z);
        const lastPoint = new THREE.Vector3(closestPointToBottom.x, scaledBottomYPosition, closestPointToBottom.z);

        const lithologyPoints = [firstPoint, ...relevantCrossingPoints, lastPoint];

        const curve = new THREE.CatmullRomCurve3(lithologyPoints);
        const geometry = new THREE.TubeGeometry(curve, 35, 0.051, 20, false);
        const material = new THREE.MeshBasicMaterial();
        super(geometry, material);
    }

    public addTexture = (lithologyImage: File) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(lithologyImage);
        fileReader.onload = () => {
            if (fileReader.result) {
                const imageTexture = new THREE.TextureLoader().load(fileReader.result.toString());
                imageTexture.colorSpace = THREE.SRGBColorSpace;
                imageTexture.wrapT = THREE.ClampToEdgeWrapping;
                imageTexture.wrapS = THREE.RepeatWrapping;
                imageTexture.rotation = Math.PI / 2;
                imageTexture.center.set(0.5, 0.5);
                imageTexture.anisotropy = 2;
                this.material = new THREE.MeshBasicMaterial({map: imageTexture});
            }
        };
    };
}