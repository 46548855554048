import shallow from 'zustand/shallow';
import PendingIcon from '@mui/icons-material/PendingOutlined';
import React from 'react';

import { useLoadingTileStore } from '../stores/useLoadingTilesStore';

export type SeismicNavbarLoadingIconProps = {
    show: boolean
};

export const SeismicNavbarLoadingIcon = ({show} : SeismicNavbarLoadingIconProps) => {

    const { loadingTiles } = useLoadingTileStore(state => ({
        loadingTiles: state.loading
    }));

    return (
        <React.Fragment>
            {
                (loadingTiles > 0 || show)
                &&
                <div className='container-loading'>
                    <PendingIcon />
                </div>
            }
        </React.Fragment>
    );
};