import React, { createRef, useEffect, useRef, useState } from 'react';
import { Button, FormControl, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Checkbox, SelectChangeEvent, Box, styled, CircularProgress, Select, InputLabel, MenuItem} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import Item from '@mui/material/Stack';
import './IntersectingSeismicsList.css';
import { StringLiteral } from 'typescript';

import { SeismicFormSelect, Option } from '../../utils/seismicFormSelect';
import { Seismic3DData } from '../../../models/classes/Seismic3DData';
import { useGetSeismicsDataByGeom } from '../../../api/useSeismic3DInfoController';
import { SeismicSearchDTO } from 'features/seismic-3d/models/types/SeismicsSearchDTO';
import { GeopostCheckbox } from 'components/geopostCheckbox/GeopostCheckbox';
import { FeatureGeom } from 'features/seismic-3d/models/classes/FeatureGeom';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';
import { SeismicsList } from '../SeismicsList';
import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';

type Props = {
    geomWKT : string;
    geomSrid: string;
};

export function IntersectingSeismicsList({geomWKT, geomSrid }: Props){
    const surveyTypeOptions : Option[] = [{label: '2D Seismic', value: '0'}, {label: '3D Seismic', value: '1'}];
    const seismicDomainOptions: Option[] = [{label: 'Time', value: '1'}, {label: 'Depth', value: '0'}];

    //const [seismicsData, setSeismicsData] = useState<Seismic3DData[]>();
    const [formData, setFormData] = useState<SeismicSearchDTO>({
        geomWKT: geomWKT,
        geomSrid: geomSrid,
        surveyType: 0,
        seismicDomain: 0
    });
    const [seismicsData, setSeismicsData] = useState<Seismic3DData[]>();
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

    const geopostScene = use3DSceneStore(state => state.scene);
    const selectedSeismics = use3DViewerStore(state => state.selectedSeismics);
    const addSelectedSeismicStore = use3DViewerStore(state => state.addSelectedSeismic);
    const removeSelectedSeismicStore = use3DViewerStore(state => state.removeSelectedSeismic);

    const [loadingSeismicTokens, setLoadingSeismicTokens] = useState<string[]>([]);

    //const seismicsGeomData = useMultipleGetGeomByVolumeToken(selectedVolumeTokens);

    const { mutate } = useGetSeismicsDataByGeom((seismics3DData: Seismic3DData[]) => {
        setIsTableLoading(false);
        setSeismicsData(seismics3DData);
    });

    const fillSeismicsList = () => {
        setIsTableLoading(true);
        mutate({...formData, surveyType: surveyType, seismicDomain: surveyDomain});
    };

    const [surveyDomain, setSurveyDomain] = useState<AmplitudeDomain>(AmplitudeDomain.Depth);
    const [surveyType, setSurveyType] = useState<SurveyType>(SurveyType.Seismic3D);

    const handleOnSurveyDomainChange = (event: SelectChangeEvent) => setSurveyDomain(parseInt(event.target.value));
    const handleOnSurveyTypeChange = (event: SelectChangeEvent) => setSurveyType(parseInt(event.target.value));

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            border: '1px solid #d9d9d9',
            whiteSpace: 'nowrap'
        },
        [`&.${tableCellClasses.body}`]: {
            border: '1px solid #d9d9d9'
        },
    }));

    const addSelectedSeismic = async (seismic3DData: Seismic3DData, seismicGeom: FeatureGeom) => {
        addSelectedSeismicStore(seismic3DData);
        geopostScene?.addSeismicMesh(seismic3DData.volumeToken, seismicGeom);
    };

    const removeSelectedSeismic = async (seismic3DData: Seismic3DData) => {
        removeSelectedSeismicStore(seismic3DData);
        await geopostScene?.removeSeismicMesh(seismic3DData.volumeToken);
    };

    return (
        <>
            <Box padding={'10px 15px'}>
                <Box
                    component='form'
                    padding={0}
                    autoComplete="off"
                >
                    <Stack padding={0} direction="row" spacing={2}>
                        <FormControl size="small" sx={{minWidth: 150, padding: 0}}>
                            <InputLabel>Domain</InputLabel>
                            <Select
                                value={surveyDomain.toString()}
                                label="Domain"
                                onChange={handleOnSurveyDomainChange}
                            >
                                <MenuItem value={AmplitudeDomain.Depth}>Depth</MenuItem>
                                <MenuItem value={AmplitudeDomain.Time}>Time</MenuItem>
                            </Select>
                        </FormControl>
                        <Item>
                            <FormControl id="surevey-type-select" size="small" sx={{minWidth: 150}}>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    value={surveyType.toString()}
                                    label='Type'
                                    onChange={handleOnSurveyTypeChange}
                                >
                                    <MenuItem value={SurveyType.Seismic3D}>3D</MenuItem>
                                    <MenuItem value={SurveyType.Seismic2D}>2D</MenuItem>
                                </Select>
                            </FormControl>
                        </Item>
                        <Item>
                            <FormControl size="small" sx={{minWidth: 150, minHeight: 40}}>
                                <Button size='small' id='seismic-list-btn' onClick={fillSeismicsList} sx={{width: '100%', height: '100%'}}> Buscar </Button>
                            </FormControl>
                        </Item>
                    </Stack>
                </Box>
                {
                    isTableLoading
                    &&
                    <CircularProgress sx={{height: '40px', width: '40px'}} />
                }
                {
                    seismicsData
                    &&
                    <SeismicsList
                        onSelectSeismicCallback={addSelectedSeismic}
                        onUnselectSeismicCallback={removeSelectedSeismic}
                        seismics={seismicsData}
                        selectedSeismics={selectedSeismics}
                        isLoadingCallback={(seismicData) => loadingSeismicTokens.includes(seismicData.volumeToken)}
                    />
                }
            </Box>
        </>
    );
}