import { Box } from '@mui/material';
import { SelectElement } from 'react-hook-form-mui';
import { css } from '@mui/material';

import { GeopostLoading } from './GeopostLoading';

const style = css({
    width: '100%',
    position: 'relative',
});

export const GeopostDropdown = ({ fieldName, label, items, loading = false, required = true, disabled = false }: Props) => {

    return (
        <Box css={style}>
            <GeopostLoading loading={loading} />
            <SelectElement
                fullWidth
                label={label}
                name={fieldName}
                options={items}
                color='primary'
                required={required}
                disabled={disabled}
            />
        </Box>
    );
};

interface Props {
    fieldName: string;
    label: string;
    items: object[] | undefined;
    loading?: boolean;
    required?: boolean;
    disabled?: boolean;
}