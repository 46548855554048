import { Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton, Select, SelectChangeEvent, Typography } from '@mui/material';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import ListAlt from '@mui/icons-material/ListAlt';
import { useTranslation } from 'react-i18next';

import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { IIntersections } from 'features/seismic/models/interfaces/IIntersection';
import { useGetSeismicAttributes } from './api/useSeismicAttributesController';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { SeismicAttribute } from 'features/seismic/models/interfaces/ISurveyMetadata';

export function SeismicAttributesSelect({isClearable, title, attributes, allAttributes, selectedDefault, afterGetCallback, setOpenedPopup, baseProductName, setBaseProductName, setSelectedAttributes}:
    {isClearable: boolean, title:string, attributes: SeismicAttribute[]|undefined, allAttributes: SeismicAttribute[]|undefined, selectedDefault: string, afterGetCallback: (selectedToken: string) => void,
        setOpenedPopup: (value: boolean) => void, baseProductName: string, setBaseProductName: (value: string) => void,
        setSelectedAttributes: (value: {
            baseProductName: string,
            all: SeismicAttribute[]
        } | undefined) => void;}){

    const { t } = useTranslation();
    const [selectedAttrToken, setSelectedAttrToken] = useState('-');
    const handleChange = ( event: SelectChangeEvent<string>) => {
        if (!isClearable && allAttributes !== undefined && allAttributes !== null) {
            let productName = allAttributes?.find((x) => x.VolumeToken === event.target.value);
            if (productName) {
                setBaseProductName(productName.Product);
                setSelectedAttributes({baseProductName: baseProductName, all: allAttributes});
            }
            else {
                setBaseProductName('');
                setSelectedAttributes({baseProductName: '', all: allAttributes});
            }
        }
        setSelectedAttrToken(event.target.value);
        afterGetCallback(event.target.value);
    };

    useEffect(() => {
        if (!isClearable) {
            let productName = attributes?.find((x) => x.VolumeToken.toString() === selectedDefault);
            if (productName) {
                setBaseProductName(productName.Product);
            }
            else {
                setBaseProductName('');
            }
        }
        setSelectedAttrToken(selectedDefault);
    }
    ,[]);

    const openPopup = () => {
        if (allAttributes !== undefined && allAttributes !== null) {
            setOpenedPopup(true);
            setSelectedAttributes({baseProductName: baseProductName, all: allAttributes});
        }
    };

    return  (
        <Fragment>
            <Typography style={{color: '#ffffff'}}>{title}</Typography>
            <Box style={{display: 'flex', flexDirection: 'row'}}>
                {
                    !!attributes && attributes.length > 0 ?
                        <Fragment>
                            <Select style={{fontSize: '14px', color:'#ffffff', padding:'3px', marginRight: '5px'}} value={selectedAttrToken} native onChange={handleChange}>
                                {
                                    isClearable ?
                                        <option style={{padding: '10px', color: '#000000'}} key={'product-' + title + '-' + 'no-overlay'} value='-'>{'No ' + title}</option>
                                        : null
                                }
                                {
                                    attributes.map(el => <option style={{padding: '10px', color: '#000000'}} key={'product-' + title + '-' + el.VolumeToken} value={el.VolumeToken}>{el.Product}</option>)
                                }
                            </Select>
                            {/* <Box style={{backgroundColor: '#ffffff', borderRadius: '5px', cursor: 'pointer'}} onClick={() => openPopup()} title={t('checkGrid')}> */}
                            <IconButton
                                id='attributes'
                                color='primary'
                                onClick={() => openPopup()} title={t('checkGrid')}
                            >
                                <ListAlt />
                            </IconButton>
                        </Fragment>
                        :
                        <Typography padding={'10px'} color='white' variant='caption'>No items available.</Typography>
                }

                {/* </Box> */}
            </Box>
        </Fragment>
    );
}

