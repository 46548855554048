import { useTranslation } from 'react-i18next';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { ISurveyMetadata } from 'features/seismic/models/interfaces/ISurveyMetadata';
import { SeismicMetadataContent } from './SeismicMetadataContent';

export function SeismicMetadataPopup ({ opened, setOpened, surveyMetadata }:Props){
    const { t } = useTranslation();

    return (
        <GeopostPopup
            temporaryIgnoreFlexInContainer
            open={opened}
            title={t('metadataTitle')}
            onChangeOpen={value => setOpened(value)}
            overlay={false}
            height={650}
            width={650}
            padding={'20px'}
        >
            <SeismicMetadataContent surveyMetadata={surveyMetadata} />
        </GeopostPopup>
    );
}

interface Props {
    opened: boolean;
    setOpened: (value: boolean) => void;
    surveyMetadata: ISurveyMetadata | null;
}