import { seismic3DColorBar } from 'features/seismic-3d/components/utils/colorUtils';
import { I3DItemShadingData } from 'features/seismic-3d/models/interfaces/I3DItemShadingData';
import { ISeismicViewer3DMap } from 'features/seismic-3d/models/interfaces/ISeismicViewer3DMap';
import { IValuedGeopostRgbaColor } from 'features/seismic-3d/models/interfaces/IValuedGeopostRgbaColor';
import { prepareColorBar } from './GeopostMaterialUtils';

export const prepareGridColorBar = (grid: ISeismicViewer3DMap) => {
    const defaultColorBar = seismic3DColorBar;
    return prepareColorBar(grid.Min, grid.Max, defaultColorBar.colors);
};

export const getShadedImageData = (elevationImage: ImageData, itemShadingData: I3DItemShadingData<ISeismicViewer3DMap>) => {
    const width = elevationImage.width;
    const height = elevationImage.height;
    const elevationData = elevationImage.data;
    const colors = itemShadingData.finalColors;
    const rangeValue = Math.abs(itemShadingData.item.Min - itemShadingData.item.Max);

    const shadeData = new Uint8ClampedArray(elevationData.length);
    const dp = itemShadingData.resolution * 2;
    const [maxX, maxY] = [width - 1, height - 1];
    const [twoPi, halfPi] = [2 * Math.PI, Math.PI / 2];
    const [sunElevation, sunAzimuth] = [Math.PI * itemShadingData.sunElevation / 180, Math.PI * itemShadingData.sunAzimuth / 180];
    const [sunElevationCos, sunElevationSin] = [Math.cos(sunElevation), Math.sin(sunElevation)];
    const verticalExageration = itemShadingData.verticalExageration;

    const totalColors = 255;
    const firstColor = colors[0];
    const lastColor = colors[colors.length - 1];

    for (let pixelY = maxY; pixelY >= 0; pixelY--) {
        const y0 = pixelY === 0 ? 0 : pixelY - 1;
        const y1 = pixelY === maxY ? maxY : pixelY + 1;
        for (let pixelX = maxX; pixelX >= 0; pixelX-- ) {
            const x0 = pixelX === 0 ? 0 : pixelX - 1;
            const x1 = pixelX === maxX? maxX : pixelX + 1;
            let offset = (pixelY * width + x0) * 4;
            const alpha = elevationData[offset + 3];
            if (alpha === 0) {
                continue;
            }

            const [red, green, blue] = [elevationData[offset], elevationData[offset + 1], elevationData[offset + 2]];
            if (red >= 200 && blue <= 50 && green <= 50) {
                shadeData[offset + 3] = 0;
                continue;
            }

            const imageGrayEquivalence = itemShadingData.item.Min + ((red / totalColors) * rangeValue);
            if (imageGrayEquivalence <= itemShadingData.userMin || imageGrayEquivalence >= itemShadingData.userMax) {
                shadeData[offset + 3] = 0;
                continue;
            }

            if (imageGrayEquivalence < firstColor.value || imageGrayEquivalence > lastColor.value) {
                shadeData[offset + 3] = 0;
                continue;
            }

            let z0;
            let z1;

            // determine elevation for (x0, pixelY)
            const x0PixelYOffset = offset;
            z0 = setElevationByOffset(x0PixelYOffset, elevationData, verticalExageration);

            // determine elevation for (x1, pixelY)
            const x1PixelYOffset = (pixelY * width + x1) * 4;
            z1 = setElevationByOffset(x1PixelYOffset, elevationData, verticalExageration);

            const dzdx = (z1 - z0) / dp;

            // determine elevation for (pixelX, y0)
            const y0PixelXOffset = (y0 * width + pixelX) * 4;
            z0 = setElevationByOffset(y0PixelXOffset, elevationData, verticalExageration);

            const y1pixelXOffset = (y1 * width + pixelX) * 4;
            z1 = setElevationByOffset(y1pixelXOffset, elevationData, verticalExageration);

            const dzdy = (z1 - z0) / dp;

            const slope = Math.atan(Math.sqrt(dzdx ** 2 + dzdy ** 2));
            let aspect = Math.atan2(dzdy, -dzdx);

            if (aspect < 0) {
                aspect = halfPi - aspect;
            } else if (aspect > halfPi) {
                aspect = twoPi - aspect + halfPi;
            } else {
                aspect = halfPi - aspect;
            }

            const cosIncidence = sunElevationSin * Math.cos(slope) + sunElevationCos * Math.sin(slope) * Math.cos(sunAzimuth - aspect);

            const shadeDataOffset =  (pixelY * width + pixelX) * 4;
            const scaled = 255 * cosIncidence;
            shadeData[shadeDataOffset] = scaled;
            shadeData[shadeDataOffset + 1] = scaled;
            shadeData[shadeDataOffset + 2] = scaled;
            shadeData[shadeDataOffset + 3] = 255;
        }
    }
    return new ImageData(shadeData, width, height);
};

const setElevationByOffset = (offset : number, elevationData: Uint8ClampedArray, verticalExageration: number) => {
    const pixel = [0, 0, 0, 0];
    pixel[0] = elevationData[offset];
    pixel[1] = elevationData[offset + 1];
    pixel[2] = elevationData[offset + 2];
    pixel[3] = elevationData[offset + 3];
    return verticalExageration * (pixel[0] + pixel[1] * 2 + pixel[2] * 3);
};

export const getColoredImageData = (elevationImage: ImageData, itemShadingData: I3DItemShadingData<ISeismicViewer3DMap>) => {
    const [ width, height ] = [ elevationImage.width, elevationImage.height ];
    const [ maxX, maxY ] = [ width - 1, height - 1 ];
    const elevationData = elevationImage.data;

    const coloredImageData = new Uint8ClampedArray(elevationData.length);

    const minColor = 0;
    const maxColor = 255;
    const totalColors = 255;

    const rangeValue = Math.abs(itemShadingData.item.Min - itemShadingData.item.Max);
    const metterByPixel = (rangeValue) / (maxColor - minColor);

    const colors = itemShadingData.finalColors;
    const firstColor = colors[1];
    let refRangeInit : IValuedGeopostRgbaColor = colors[1];
    let refRangeEnd : IValuedGeopostRgbaColor = colors[2];
    let initGrayRangeColor = 0;
    let endGrayRangeColor = 0;

    for (let pixelY = maxY; pixelY--;) {
        for (let pixelX = maxX; pixelX--;) {
            const offset = (pixelY * width + pixelX) * 4;

            const alpha = elevationData[offset + 3];
            if (alpha === 0) {
                continue;
            }

            const red = elevationData[offset];
            const green = elevationData[offset + 1];
            const blue = elevationData[offset + 2];

            if (red >= 200 && blue <= 50 && green <= 50) {
                coloredImageData[offset + 3] = 0;
                continue;
            }

            const gray = ((red + green + blue) / 3);
            const imageGrayEquivalence = itemShadingData.item.Min + ((gray / totalColors) * rangeValue);

            if (imageGrayEquivalence < itemShadingData.userMin || imageGrayEquivalence > itemShadingData.userMax) {
                coloredImageData[offset + 3] = 0;
                continue;
            }

            if ((itemShadingData.userMin - imageGrayEquivalence < 1) && (itemShadingData.userMin - imageGrayEquivalence > -1)) {
                coloredImageData[offset] = firstColor.color.red;
                coloredImageData[offset + 1] = firstColor.color.green;
                coloredImageData[offset + 2] = firstColor.color.blue;
                coloredImageData[offset + 3] = firstColor.color.opacity * 255;
                continue;
            }

            if (imageGrayEquivalence < refRangeInit?.value || imageGrayEquivalence > refRangeEnd?.value) {
                let newRefRangeInit = null;
                let newRefRangeEnd = null;
                for (let x = 0; x < colors.length; x++) {
                    const currentValue = colors[x].value;
                    let nextValue = 0;
                    if (x < (colors.length - 1)) {
                        nextValue = colors[x + 1].value;
                    }
                    else {
                        nextValue = currentValue;
                    }

                    if (imageGrayEquivalence >= currentValue && imageGrayEquivalence <= nextValue) {
                        newRefRangeInit = colors[x];
                        if (x < (colors.length - 1)){
                            newRefRangeEnd = colors[x + 1];
                        }
                        else {
                            newRefRangeEnd = colors[x];
                        }

                        if (!!newRefRangeInit) {
                            refRangeInit = newRefRangeInit;
                        }
                        if (!!newRefRangeEnd) {
                            refRangeEnd = newRefRangeEnd;
                        }

                        break;
                    }
                }

                if (!newRefRangeInit) {
                    if (imageGrayEquivalence <= colors[0].value) {
                        newRefRangeInit = colors[0];
                        newRefRangeEnd = colors[0];
                    } else {
                        if (imageGrayEquivalence >= colors[colors.length - 1].value) {
                            newRefRangeInit = colors[colors.length - 1];
                            newRefRangeEnd = colors[colors.length - 1];
                        }
                    }
                }

                if (!!newRefRangeInit) {
                    refRangeInit = newRefRangeInit;
                }
                if (!!newRefRangeEnd) {
                    refRangeEnd = newRefRangeEnd;
                }

                const difMinToRangeInit = Math.abs(itemShadingData.item.Min - refRangeInit.value);

                const difMinToRangeEnd = Math.abs(itemShadingData.item.Min - refRangeEnd.value);

                initGrayRangeColor = (difMinToRangeInit) / metterByPixel;
                endGrayRangeColor = (difMinToRangeEnd) / metterByPixel;
            }

            coloredImageData[offset] = refRangeInit.color.red + (((gray) - initGrayRangeColor) * ((refRangeEnd.color.red - refRangeInit.color.red) / (endGrayRangeColor - initGrayRangeColor)));
            coloredImageData[offset + 1] = refRangeInit.color.green + (((gray) - initGrayRangeColor) * ((refRangeEnd.color.green - refRangeInit.color.green) / (endGrayRangeColor - initGrayRangeColor)));
            coloredImageData[offset + 2] = refRangeInit.color.blue + (((gray) - initGrayRangeColor) * ((refRangeEnd.color.blue - refRangeInit.color.blue) / (endGrayRangeColor - initGrayRangeColor)));
            coloredImageData[offset + 3] = 255;
        }
    }
    console.log('FIM APPLY COLOR: MIN ' + itemShadingData.userMin + ' MAX: ' + itemShadingData.userMax + ' ==> ' + new Date().toTimeString());
    return new ImageData(coloredImageData, width, height);
};

export const getGridShaddingData = (grid : ISeismicViewer3DMap) : I3DItemShadingData<ISeismicViewer3DMap> => {
    return {
        userMin: grid.Min,
        userMax: grid.Max,
        resolution: 1,
        sunAzimuth: 315,
        sunElevation: 45,
        verticalExageration: 0.2,
        finalColors: prepareGridColorBar(grid),
        item: grid
    };
};

/*export const getDisplacementScale = (grid: ISeismicViewer3DMap, countTimeDepth: number, heightPixel: number) => {
    const heightFactor = countTimeDepth / heightPixel;
    const depthToPixel = Math.abs(grid.Max) / heightFactor;
    const yPosition = -(depthToPixel / yDivisionFactor);
    const depthToPixelMin = Math.abs(grid.Min) / heightFactor;
    const minYPosition = -(depthToPixelMin / yDivisionFactor);
    const displacementScale = Math.abs(minYPosition - yPosition);
    return displacementScale;
}*/