import { RotateDirection } from '@react-pdf-viewer/core';
import { MoreActionsPopover } from '@react-pdf-viewer/toolbar';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';
import { ReactElement } from 'react';

export const PDFToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
        {(slots: ToolbarSlot) => {
            const {
                CurrentPageInput,
                EnterFullScreen,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                Rotate,
                Zoom,
                ZoomIn,
                ZoomOut,
            } = slots;
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div style={{ padding: '0px 2px' }}>
                        <GoToPreviousPage />
                    </div>
                    <div style={{ padding: '0px 2px', width: '4rem' }}>
                        <CurrentPageInput />
                    </div>
                    <div style={{ padding: '0px 2px', marginLeft: '7px', color: 'rgb(255, 255, 255)' }}>
                        {/* &nbsp; */} of <NumberOfPages />
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <GoToNextPage />
                    </div>

                    <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                        <ZoomOut />
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <Zoom />
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <ZoomIn />
                    </div>

                    <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                        <EnterFullScreen />
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <Rotate direction={RotateDirection.Forward} />
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <Rotate direction={RotateDirection.Backward} />
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <MoreActionsPopover toolbarSlot={slots} />
                    </div>
                </div>
            );
        }}
    </Toolbar>
);