import { useTranslation } from 'react-i18next';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { Position } from 'components/geopostPopup/models/enums/Position';
import { SeismicWellRelatedDataContent } from './SeismicWellRelatedDataContent';
import { IRelatedData } from 'features/seismic/models/interfaces/IRelatedData';
import { IRelatedDataImages, useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useMapStore } from 'features/seismic/stores/useMapStore';

export function SeismicWellRelatedDataPopup ({ myKey, data }:Props){
    const { t } = useTranslation();

    const { map } = useMapStore(state => ({
        map: state.map
    }));

    const { wellRelatedData, setWellRelatedData, wellRelatedDataTextLayers } = useSeismicStore(state => ({
        wellRelatedData: state.wellRelatedData,
        setWellRelatedData: state.setWellRelatedData,
        wellRelatedDataTextLayers: state.wellRelatedDataTextLayers,
    }));

    const updateRelatedData = () => {
        if (!data.opened) {
            for (let i = 0; i < wellRelatedData[myKey].relatedData.pickers.length; i++) {
                map?.addLayer(wellRelatedData[myKey].layer);
            }
        }
        else {
            for (let i = 0; i < wellRelatedData[myKey].relatedData.pickers.length; i++) {
                map?.removeLayer(wellRelatedData[myKey].layer);
            }
            for (let key in wellRelatedDataTextLayers[myKey]){
                map?.removeLayer(wellRelatedDataTextLayers[myKey][key]);
            }
        }
        setWellRelatedData(myKey, {layer: wellRelatedData[myKey].layer, relatedData: data.relatedData, opened: !data.opened, images: data.images});
    };

    return (
        <GeopostPopup
            temporaryIgnoreFlexInContainer
            open={data.opened}
            title={t('relatedData')}
            onChangeOpen={updateRelatedData}
            overlay={false}
            width={670}
            height={562}
            position={Position.center}
            resizable={false}
        >
            <SeismicWellRelatedDataContent data={data} wellId={myKey} />
        </GeopostPopup>
    );
}

interface Props {
    myKey: string;
    data: { layer: VectorLayer<VectorSource>; relatedData: IRelatedData; opened: boolean; images: IRelatedDataImages; };
};