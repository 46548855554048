import { create } from 'zustand';

import { LineType } from '../models/enums/LineType';
import { LineGeometryPerformanceData } from '../models/types/LineGeometryPerformanceData';
import { MetadataPerformanceData } from '../models/types/MetadataPerformanceData';
import { SurveyGeometryPerformanceData } from '../models/types/SurveyGeometryPerformanceData';
import { TileErrorData } from '../models/types/TileErrorData';
import { TilePerformanceData } from '../models/types/TilePerformanceData';
import { PrepareRenderPerformanceData } from '../models/types/PrepareRenderPerformanceData';

export interface IPerformanceMonitoringStore {
    metadataPerformanceData: MetadataPerformanceData | null,
    setMetadataPerformanceData: (data: MetadataPerformanceData) => void,

    tilesPerformanceData: TilePerformanceData[],
    setTilesPerformanceData: (data: TilePerformanceData[]) => void,
    addTilePerformanceData: (data: TilePerformanceData) => void,

    tilesWithError: TileErrorData[],
    addTilesWithError: (data: TileErrorData) => void,

    lineGeometriesPerformanceData: LineGeometryPerformanceData[],
    setLineGeometriesPerformanceData: (data: LineGeometryPerformanceData[]) => void,
    addLineGeometryPerformanceData: (data: LineGeometryPerformanceData) => void,

    surveyGeometriesPerformanceData: SurveyGeometryPerformanceData[],
    setSurveyGeometriesPerformanceData: (data: SurveyGeometryPerformanceData[]) => void,
    addSurveyGeometryPerformanceData: (data: SurveyGeometryPerformanceData) => void,

    prepareRenderPerformanceData: PrepareRenderPerformanceData[];
    addPrepareRenderPerformanceData: (value: PrepareRenderPerformanceData) => void;
    setPrepareRenderPerformanceData: (value: PrepareRenderPerformanceData[]) => void;
}

export const usePerformanceMonitoringStore = create<IPerformanceMonitoringStore>()( (set) => ({
    metadataPerformanceData: null,
    setMetadataPerformanceData: (data) => set({ metadataPerformanceData: data }),

    tilesPerformanceData: [],
    setTilesPerformanceData: (data) => set({ tilesPerformanceData: data }),
    addTilePerformanceData: (tilePerformanceData) => set(state => ({ tilesPerformanceData: [...state.tilesPerformanceData, tilePerformanceData]})),

    tilesWithError: [],
    addTilesWithError: (data) => set(state => ({ tilesWithError: [...state.tilesWithError, data]})),

    lineGeometriesPerformanceData: [],
    setLineGeometriesPerformanceData: (dataset) => set({ lineGeometriesPerformanceData: dataset }),
    addLineGeometryPerformanceData: (data) => set(state => ({ lineGeometriesPerformanceData: [...state.lineGeometriesPerformanceData, data] })),

    surveyGeometriesPerformanceData: [],
    setSurveyGeometriesPerformanceData: (dataset) => set({ surveyGeometriesPerformanceData: dataset }),
    addSurveyGeometryPerformanceData: (data) => set(state => ({ surveyGeometriesPerformanceData: [...state.surveyGeometriesPerformanceData, data] })),

    prepareRenderPerformanceData: [],
    addPrepareRenderPerformanceData: (value: PrepareRenderPerformanceData) => set(state => ({ prepareRenderPerformanceData: [...state.prepareRenderPerformanceData, value]})),
    setPrepareRenderPerformanceData: (value: PrepareRenderPerformanceData[]) => set({ prepareRenderPerformanceData: value})
}));