import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Box, Popper } from '@mui/material';

import DatePickerElement from 'components/dataPickerElement/DatePickerElement';

dayjs.extend(localizedFormat);
dayjs.locale('pt-br');

export const GeopostDateInput = ({ fieldName, label, dateType, required = true }: Props) => {
    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePickerElement
                    label={label}
                    name={fieldName}
                    required={required}
                    format={dateType}
                    slots={{
                        popper: (props) => <Popper {...props} disablePortal={true} sx={{ zIndex: 2 }} />
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};

interface Props {
    fieldName: string;
    label: string;
    dateType: string;
    required?: boolean;
}