import { Box, Button, css, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, Zoom } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Fragment, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import alertify from 'alertifyjs';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';
import { MainTableCss } from 'fileIndexing/envVar/envVar';
import { SignalR } from 'fileIndexing/signalR/signalR';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { useDeleteTag, useGetAllTags, useGetTagByToken, useReindexAllFilesByTag } from '../useTags';
import { ITag } from '../interfaces/ITag';
import { TagGroup } from './TagGroup';
import { TagsForm } from './TagsForm';
import { useSessionStore } from 'session/useSessionStore';
import { ReindexTagPopup } from './ReindexTagPopup';

export function TagsTable() {
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }));

    const [tagGroupSelected, setTagGroupSelected] = useState<string>('');

    const [openLogModal, setOpenLogModal] = useState<boolean>(false);

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<ITag | null>(null);

    const [editToken, setEditToken] = useState<string>('');
    const [deleteToken, setDeleteToken] = useState<string>('');

    const [filteredData, setFilteredData] = useState<ITag[] | undefined>([]);
    const [filter, setFilter] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);
    const [reindexTagToken, setReindexTagToken] = useState<string>('');

    const { data: tagsData, isLoading: tagsDataLoading, refetch } = useGetAllTags(tagGroupSelected);
    const { data: tagGetByToken } = useGetTagByToken(editToken);
    const { data: deleteTagData } = useDeleteTag(deleteToken);

    const handleOpenLogModal = () => setOpenLogModal(true);
    const handleCloseLogModal = () => setOpenLogModal(false);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleOpenEdit = (rowData: ITag) => {
        setEditToken(rowData.Token);
    };

    const handleDelete = (name: string, token: string) => {
        alertify.confirm(`Do you really wanna delete ${name}?`, async () => {
            setDeleteToken(token);
        });
    };

    const handleOpenFiles = (tagToken: string) => {
        window.open(`/FileIndexing/files?tenantToken=${tenantConfig?.tenantToken}&tagToken=${tagToken}`, '_blank');
    };

    const handleOpenReindex = (tagToken: string) => {
        setReindexTagToken(tagToken);
        handleOpenLogModal();
    };

    // reseta as informações preenchidas no modal
    useEffect(() => {
        if (!openModal) {
            setModalData(null);
        }
    }, [openModal]);

    // seta as informações para edit
    useEffect(() => {
        if (tagGetByToken) {
            setModalData(tagGetByToken);
            handleOpenModal();
            setEditToken('');
        }
    }, [tagGetByToken]);

    // salva as informações de todas as linhas recebidas
    useEffect(() => {
        if (tagsData) {
            setFilteredData(tagsData);
        }
    }, [tagsData]);

    // realiza o filtro
    useEffect(() => {
        if (tagsData) {
            if (filter === '') {
                setFilteredData(tagsData);
            }
            else {
                setFilteredData(tagsData.filter(x => x.Name?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Regex?.toLowerCase().startsWith(filter.toLowerCase())));
            }
        }
    }, [filter]);

    useEffect(() => {
        if (deleteTagData === 'Success') {
            refetch();
        }
    }, [deleteTagData]);

    // conteúdo da tabela
    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            headerAlign: 'left',
            flex: 1,
        },
        {
            field: 'Regex',
            headerName: 'Regex',
            headerAlign: 'left',
            flex: 1,
        },
        {
            field: 'Options',
            headerName: 'Options',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <Box className='options-buttons'>
                    <Tooltip title='Force reindex for this tag' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' onClick={() => handleOpenReindex(params.row.Token)}>
                            <DoneAllIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Edit Tag metadata' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' className='edit-icon' onClick={() => handleOpenEdit(params.row)}>
                            <EditNoteIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Delete Tag' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='error' className='delete-icon' onClick={() => handleDelete(params.row.Name, params.row.Token)}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='View files associated to this tag' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' className='link-icon' onClick={() => handleOpenFiles(params.row.Token)}>
                            <LinkIcon />
                        </Button>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    const getRowId = (row: ITag) => row.Token;

    return (
        <Fragment>
            <Box css={[MainTableCss]}>
                <TagGroup tagGroupSelected={tagGroupSelected} setTagGroupSelected={setTagGroupSelected} />
                <Box className='table-header'>
                    <Typography className='table-header-title'>Tags</Typography>
                    <Box className='search'>
                        <TextField label='Search Tag' variant='outlined' size='small' color='primary' value={filter} onChange={(event) => setFilter(event.target.value)} />
                        <Button disabled={tagGroupSelected === ''} variant='contained' color='primary' className='add-icon' onClick={() => handleOpenModal()}>
                            <AddIcon />
                        </Button>
                    </Box>
                </Box>
                <GeopostTable<ITag> filteredData={filteredData} loading={tagsDataLoading || loading} columns={columns} getRowId={getRowId} />
            </Box>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openModal}
                onChangeOpen={handleCloseModal}
                resizable={false}
                width={560}
                minHeight={260}
                height={260}
                maxHeight={500}
                title={modalData ? `Edit ${modalData.Name}` : 'Add Tag'}
                padding={'16px'}
            >
                <TagsForm setOpenModal={setOpenModal} refetchTableData={refetch} setLoading={setLoading} formData={modalData} tagGroupToken={tagGroupSelected} />
            </GeopostPopup>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openLogModal}
                onChangeOpen={handleCloseLogModal}
                resizable={false}
                width={560}
                minHeight={260}
                height={260}
                maxHeight={500}
                title={'Reindex Tag'}
                padding={'16px'}
            >
                <ReindexTagPopup reindexTagToken={reindexTagToken} />
            </GeopostPopup>
        </Fragment>
    );
}