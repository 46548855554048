import { useGetAllDataSource } from 'fileIndexing/dataSources/useDataSource';
import { useEffect, useState } from 'react';

import { GeopostDropdown } from './GeopostDropdown';

export function GeopostDropdownDataSource({ fieldName, label, noneItem = false, required = false }: Props) {
    const [dataSourceItems, setDataSourceItems] = useState<{ id: string; label: string; }[]>([]);

    const { data: dataSource, isLoading } = useGetAllDataSource();

    useEffect(() => {
        if (dataSource) {
            let createDataSourceItems: {id: string, label: string}[] = [];

            if (noneItem) {
                createDataSourceItems.push({
                    id: '',
                    label: 'None'
                });
            }

            let getAllDataSources = dataSource.map(dataSource => ({
                id: dataSource.Token,
                label: dataSource.Name
            }));

            getAllDataSources.forEach((item) => createDataSourceItems.push(item));

            setDataSourceItems(createDataSourceItems);
        }
    }, [dataSource]);

    return (
        <GeopostDropdown fieldName={fieldName} label={label} items={dataSourceItems} loading={isLoading} required={required} />
    );
}

interface Props {
    fieldName: string;
    label: string;
    noneItem?: boolean;
    required?: boolean;
}