import { useGetByTerm } from 'fileIndexing/entities/useEntities';
import { IEntityTypeFilter } from 'fileIndexing/files/interfaces/IFiles';
import { useGetAllDataGroups } from 'fileIndexing/taxonomies/useTaxonomy';
import { useEffect, useState } from 'react';

import { GeopostAutocomplete } from './GeopostAutocomplete';

export function GeopostAutocompleteEntities({ fieldName, label, selectedValue, required = false }: Props) {
    const [dataGroupItems, setDataGroupItems] = useState<{ id: string; label: string; }[]>([]);
    const [inputValue, setInputValue] = useState<string>('');

    const { data: dataEntities, isLoading } = useGetByTerm(inputValue);

    useEffect(() => {
        if (dataEntities) {
            let getAllDataGroups = dataEntities.map(dataEntities => ({
                id: dataEntities.Token,
                label: dataEntities.Name
            }));

            setDataGroupItems(getAllDataGroups);
        }
    }, [dataEntities]);

    useEffect(() => {
        if (selectedValue.label !== '') {
            setInputValue(selectedValue.label);
        }
    }, [selectedValue]);

    return (
        <GeopostAutocomplete fieldName={fieldName} label={label} items={dataGroupItems} selectedValue={selectedValue} setInputValue={setInputValue} loading={isLoading} required={required} />
    );
}

interface Props {
    fieldName: string;
    label: string;
    selectedValue: IEntityTypeFilter;
    required?: boolean;
}