import { useMutation, useQuery } from 'react-query';

import { axios } from 'configurations/axios';
import { useSessionStore } from 'session/useSessionStore';
import { IProjection } from 'features/seismic/models/interfaces/IProjection';
import { IIntersections } from 'features/seismic/models/interfaces/IIntersection';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { ISeismicRequirement } from 'features/seismic/models/interfaces/ISeismicRequirement';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { LineType } from 'features/seismic-3d/models/enums/LineType';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';

export function useGetAllLayersSetupProjection(enabled: boolean = true) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllLayersSetupProjection', async () => {
        const response = await axios.request<IResponseAPI<IProjection[]>>({
            method: 'get',
            url: tenantConfig?.endpoints?.projections + '/SeismicLayers/GetAllLayersSetupProjection',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    },{
        refetchOnWindowFocus: false,
        enabled: enabled
    });
}

export function useGetIntersection(layerToken: string, geometryLine: string | undefined, srid:number){
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getIntersection' + layerToken + '_' + geometryLine?.substring(0,100), async () => {
        const response = await axios.request<IResponseAPI<number>>({
            method: 'post',
            url: tenantConfig?.endpoints?.projections + '/SeismicLayers/GetIntersection',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                layerToken: layerToken,
                geometryLine: geometryLine,
                srid: srid
            }
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: (geometryLine) ? true: false
    });
}

export function useGetFeaturesIntersectionsByGeometry(layerToken: string, seimsicGeometry: string | undefined, numberOfTraces: number | undefined, srid: number){
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getFeaturesIntersectionsByGeometry' + layerToken + '_' + seimsicGeometry?.substring(0,100), async () => {
        const response = await axios.request<IResponseAPI<IIntersections[]>>({
            method: 'post',
            url: tenantConfig?.endpoints?.projections + '/SeismicLayers/GetFeaturesIntersectionsByGeometry',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                layerToken: layerToken,
                seismicGeometry: seimsicGeometry,
                numberOfTraces: numberOfTraces,
                srid: srid
            }
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: (layerToken !== '')
    });
}

export function useGetFeatureFlagsByProject(tenantToken: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getFeatureFlagsByProject' + tenantToken ?? tenantConfig?.tenantToken, async () => {
        const response = await axios.request<IResponseAPI<string[]>>({
            method: 'get',
            url: tenantConfig?.endpoints?.featureFlags + '/FeatureFlag/CheckForSeismic2D?tenantToken=' + tenantToken ?? tenantConfig?.tenantToken,
            headers: {
                'Content-Type': 'application/json'
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: (tenantToken) ? true : false
    });
}

export function useGetRequirements() {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useMutation('getRequirements', async (params: IGetRequirementParams) => {
        const response = await axios.request<IResponseAPI<ISeismicRequirement>>({
            method: 'get',
            url: tenantConfig?.endpoints?.featureFlags + '/Seimics/SeismicViewer/GetRequirement',
            params: {
                surveyToken: params.surveyToken,
                tenantToken: params.tenantToken,
                volumeToken: params.volumeToken

            },
            headers: {
                'Content-Type': 'application/json'
            },
        });

        return response.data.Result;
    });
}

export function useGetLineString(volumeToken: string | undefined, lineType: LineType, lineNumber: number, volumeType: SurveyType | undefined, srid: number, hasErrorInMetadata = false, dimensionName : string | null | undefined, forceEnabled: boolean) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    const urlParams = getParamsFromUrl();

    return useQuery(`getLineString-${volumeToken}-${lineType}-${lineNumber}-`, async () => {
        const response = await axios.request<IResponseAPI<ILineString>>({
            method: 'get',
            url: tenantConfig?.endpoints?.lineGeometry /*'http://localhost:5000'*/ + '/Seismic/Volume/GetGeometryOfSeismicLine',
            params: {
                volumeToken: volumeToken,
                volumeType: volumeType,
                forceDisableCache: urlParams.forceDisableImageCache,
                lineType: lineType,
                lineNumber: lineNumber,
                srid: urlParams.sourceApi === 'pgs' ? srid : srid,
                dimensionName: dimensionName
            },
            headers: {
                'Content-Type': 'application/json',
                'x-gp-debug-active': urlParams.performanceMonitoring,
                sourceapi: urlParams.sourceApi
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: ((!!volumeToken && !!lineType && !!lineNumber && !!volumeType && !hasErrorInMetadata && !!dimensionName) || (forceEnabled))
    });
}

interface IGetRequirementParams {
    tenantToken: string;
    volumeToken: string;
    surveyToken?: string;
}