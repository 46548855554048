import { css } from '@emotion/react';
import { InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, KeyboardEvent, useMemo, useCallback, ChangeEvent } from 'react';
import { FormControl } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { LineType } from 'features/seismic/models/enums/LineType';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { InputNumberValueSelector } from 'components/inputs/InputNumberValueSelector';
import { useLine3DNavigationStore } from '../stores/useLine3DNavigationStore';
import { LoadingBlock } from 'components/LoadingBlock';
import { calculateDisable, changeLineNumber } from '../utils/line3DNavigationUtils';
import { useMapStore } from '../stores/useMapStore';
import { getParamsFromUrl } from '../utils/seismicUrlUtils';
import { useZSliceStore } from '../stores/useZSliceStore';
import { AmplitudeDomain } from '../models/enums/AmplitudeDomain';
import { useSessionStore } from 'session/useSessionStore';
import { useFeatureFlags } from '../hooks/useFeatureFlags';

const style = css({
    display: 'flex',
    position: 'relative',
    '& button': {
        background: '#0e0e0e63',
        borderRadius: 0,
        maxWidth: 40,
        minWidth: 40,
        '&:hover': {
            background: '#ffffff63'
        }
    },
    '& .line-selector': {
        '& .css-ihdtdm': {
            width: '60px',
        }
    },
    '& .MuiInputBase-root': {
        borderRadius: 0,
        minWidth: 95,
    },
    '& .label-inline-number': {
        width: 95,
        textAlign: 'center',
        '& .MuiInputBase-input': {
            fontSize: '12px',
            borderRadius: '5px',
        },
        '& .MuiFormLabel-root': {
            transform: 'translate(0px, -14px) scale(0.75)',
            fontSize: '16px',
        },
        '& .MuiInputBase-root': {
            fontSize: '12px',
            borderRadius: '5px',
        },
    },'& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            borderWidth: '1px',
            borderRadius: '5px'
        }
    }, '& .MuiFormControl-root': {
        '& .MuiOutlinedInput-notchedOutline': {
            '& legend': {
                width: '0px',
            }
        }
    },
});

export function Line3DSelector({ featureFlags }: Props) {
    const { t } = useTranslation();
    const project2DDefaults = useSessionStore(state => state.tenantConfiguration?.viewer2DDefaults);
    const sliceFeatureFlag = useFeatureFlags().featureFlags?.seismic2D.TimeSlice;
    const { lineNumber, lineType, setLineType, loading, setLineNumber, currentIncrement, setCurrentIncrement } = useLine3DNavigationStore(state => ({
        setLineNumber: state.setLineNumber,
        currentIncrement: state.currentIncrement,
        setCurrentIncrement: state.setCurrentIncrement,
        setLineType: state.setLineType,
        lineNumber: state.lineNumber,
        lineType: state.lineType,
        loading: state.loading,
    }));

    const { surveyMetadata, restrict, seismicCalculator } = useSeismicStore(state => ({
        restrict: state.restrict,
        surveyMetadata: state.surveyMetadata,
        seismicCalculator: state.calculator
    }));

    const defaultDebounceTime = useMapStore(state => state.defaultDebounceTime);

    const [lineNumberValue, setLineNumberValue] = useState(lineNumber);

    const sampleInterval = useMemo<number>(() => (!!surveyMetadata ? seismicCalculator?.calculateSampleInterval(surveyMetadata) ?? 1 : 1), [surveyMetadata, seismicCalculator]);
    useEffect(() => {
        setLineNumberValue(lineNumber);
    }, [lineNumber]);

    const handleChangeType = async (evt: SelectChangeEvent) => {
        const newLineType = parseInt(evt.target.value) as LineType;
        setLineType(newLineType);
    };

    //const onUpClick = () => increase(increment);
    //const onDownClick = () => decrease(increment);

    const onUpClick = () => onChangeClick(currentIncrement);

    const onDownClick = () => onChangeClick(-currentIncrement);

    const onChangeClick = (value: number) => {
        const skip = (restrict) ? restrict.SeismicSkipSize3D : 0;
        const lineNumber = changeLineNumber(surveyMetadata, lineNumberValue, lineType, value, skip);
        setLineNumberValue(lineNumber);
        lineNumberDebounced(lineNumber);
    };

    const lineNumberDebounced = useDebouncedCallback((value) => {
        setLineNumber(value);
    }, defaultDebounceTime);

    const disableOptions = calculateDisable(surveyMetadata, lineNumberValue, lineType);

    const handleLineNumber = (key: string) => {
        if (key === 'Enter' && surveyMetadata) {
            let lineStart = lineType === LineType.Inline || lineType === LineType.Line2D ?
                surveyMetadata.Entitlement3DLimits.InlineStart ?? surveyMetadata.Survey3DInfo.InlineStart
                :
                lineType === LineType.Xline ?
                    surveyMetadata.Entitlement3DLimits.XlineStart ?? surveyMetadata.Survey3DInfo.XlineStart
                    :
                    0;
            let lineEnd = lineType === LineType.Inline || lineType === LineType.Line2D ?
                surveyMetadata.Entitlement3DLimits.InlineEnd ?? surveyMetadata.Survey3DInfo.InlineEnd
                :
                lineType === LineType.Xline ?
                    surveyMetadata.Entitlement3DLimits.XlineEnd ?? surveyMetadata.Survey3DInfo.XlineEnd
                    :
                    (surveyMetadata.Header.SamplesPerTrace - 1) * sampleInterval;

            if (lineNumberValue > lineEnd) {
                setLineNumber(lineEnd);
                setLineNumberValue(lineEnd);
            }
            else if (lineNumberValue < lineStart) {
                setLineNumber(lineStart);
                setLineNumberValue(lineStart);
            }
            else {
                let increment = lineType === LineType.Inline || lineType === LineType.Line2D ?
                    surveyMetadata.Survey3DInfo.InlineIncrement
                    :
                    lineType === LineType.Xline ?
                        surveyMetadata.Survey3DInfo.XlineIncrement
                        :
                        sampleInterval;
                let remainder = (lineNumberValue - lineStart) % increment;

                if (remainder === 0) {
                    setLineNumber(lineNumberValue);
                    setLineNumberValue(lineNumberValue);
                }
                else {
                    setLineNumber(lineNumberValue + (increment - remainder));
                    setLineNumberValue(lineNumberValue + (increment - remainder));
                }
            }
        }
    };

    const handleLineNumberInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        let intValue = parseInt(value);
        intValue = isNaN(intValue) ? 0 : intValue;
        setLineNumberValue(intValue);
    };

    return <div css={style}>
        <LoadingBlock open={loading} />
        {featureFlags?.LineType &&
            <FormControl className='line-selector'>
                <InputLabel style={{transform: 'translate(0px, -14px) scale(0.75)',}} id='3d-line-type-selector-label'>{t('lineType')}</InputLabel>
                <Select
                    MenuProps={{
                        sx: {
                            '&& .Mui-selected': {
                                backgroundColor: '#0077b3'
                            }
                        }
                    }}
                    labelId='3d-line-type-selector-label'
                    id='line-selector-type'
                    style={{ fontSize: '12px', width: '100px', padding: '2px', borderRadius: '5px', marginRight: '10px', height: '34px'}}
                    value={lineType.toString()}
                    onChange={handleChangeType}
                >
                    <MenuItem value={LineType.Inline} style={{ color: '#ffffff', fontSize: '12px' }}>Inline</MenuItem>
                    <MenuItem value={LineType.Xline} style={{ color: '#ffffff', fontSize: '12px' }}>Xline</MenuItem>
                    {
                        sliceFeatureFlag
                        &&
                        <MenuItem value={LineType.ZSlice} style={{ color: '#ffffff', fontSize: '12px' }}>Zslice</MenuItem>
                    }
                </Select>
            </FormControl>
        }
        <InputNumberValueSelector
            id='line-selector-3d-skip'
            value={currentIncrement}
            onChange={value => setCurrentIncrement(value)}
            label={t('skip')}
            labelIncrease='>'
            labelDecrease='<'
            width={120}
            staticComponent={true}
            onUpClick={onUpClick}
            onDownClick={onDownClick}
            disableDecrease={disableOptions.disableDecrease}
            disableIncrease={disableOptions.disableIncrease}
            type='number'
        />
        <TextField
            className='label-inline-number'
            label={lineType !== LineType.ZSlice ? t('lineNumber') : surveyMetadata?.Domain === AmplitudeDomain.Depth ? 'Depth' : 'Time'}
            value={lineNumberValue}
            onKeyDown={(event) => handleLineNumber(event.key)}
            onChange={handleLineNumberInputChange}
            onBlur={() => handleLineNumber('Enter')}
            style={{borderRadius: '5px!important', marginRight: '10px'}}
            sx={{
                input: { textAlign: 'center', borderRadius: '5px!important' },
            }}>
        </TextField>
    </div>;
}

interface Props {
    featureFlags: {
        ColorBar: boolean;
        Gain: boolean;
        Projections: boolean;
        Attributes: boolean;
        TraceHeader: boolean;
        Minimap: boolean;
        EnableWells: boolean;
        Interpretation: boolean;
        WebTour: boolean;
        Graticule: boolean;
        LineType: boolean;
        Depth: boolean;
        TimeSlice: boolean;
    } | undefined
}