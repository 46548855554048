import { useEffect, useRef } from 'react';
import { Map, Feature } from 'ol';
import { LineString } from 'ol/geom';
import { Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { IWellAnnotation } from 'features/cross-section/models/interfaces/IWellAnnotation';
import { useCrossSectionStore } from 'features/cross-section/stores/useCrossSectionStore';
import { IWellGenericCut } from 'features/cross-section/models/interfaces/IWellGenericCut';
import { buildExtent } from 'features/cross-section/utils/wellLaneMapUtils';
import { useAPI } from 'hooks/useAPI';
import { ApiType } from 'models/enums/ApiType';
import { useCrossSectionAnnotationsStore } from 'features/cross-section/stores/useCrossSectionAnnotationsStore';
import { buildStyle, createGroupKey } from 'features/cross-section/utils/wellCrossSectionAnnotationsUtils';
import { IAnnotationVisibleSettings } from 'features/cross-section/models/interfaces/IAnnotationVisibleSettings';

export function useAnnotations(selectedGenericCut: IWellGenericCut | null, map: Map | null){
    const {isLoading, execute, data:annotations} = useAPI<IWellAnnotation[]>('/Wells/WellAnnotationItem/GetAllForCrossSection');

    const { factorVertical, laneWidth, jwtToken, view, displacementVertical } = useCrossSectionStore(state => ({
        displacementVertical: state.displacementVertical,
        factorVertical: state.factorVertical,
        jwtToken: state.jwtToken,
        laneWidth: state.laneWidth,
        view: state.view
    }));

    const { setAnnotations, visibleSettings } = useCrossSectionAnnotationsStore(state => ({
        visibleSettings: state.visibleSettings,
        setAnnotations: state.setAnnotations
    }));

    let layer = useRef<VectorLayer<VectorSource> | null>();

    useEffect(() => {
        if (selectedGenericCut && selectedGenericCut.GenericCutType !== 'log' && !annotations && selectedGenericCut.CompositeProfileToken){
            execute({
                apiType: ApiType.WebApi,
                jwtToken: jwtToken,
                params: {
                    compositeProfileToken: selectedGenericCut.CompositeProfileToken
                }
            });
        }
    }, [selectedGenericCut, annotations]);

    useEffect(() => {
        if (annotations && selectedGenericCut){
            setAnnotations(selectedGenericCut.WellId, annotations);
        }
    }, [annotations]);

    const buildLabel = (feature: Feature, settings: IAnnotationVisibleSettings | undefined) => {
        const depth = (settings)?settings.depth:true;
        const name = (settings)?settings.name:true;
        const label:string[] = feature.get('label');

        let finalLabel = '';

        if (name){
            finalLabel = label[0];
        }

        if (depth){
            if (name){
                finalLabel += ' | ';
            }

            finalLabel += label[1];
        }

        return finalLabel;
    };

    const updateStyles = () => {
        if (Object.keys(visibleSettings).length > 0 && layer.current){
            const features = layer.current.getSource()?.getFeatures();

            if (!features || features.length < 0){
                return;
            }

            for (let groupKey in visibleSettings){
                const featuresFiltered = features.filter(x => x.get('groupKey') === groupKey);
                const toVisible = visibleSettings[groupKey].visible;

                if (featuresFiltered && featuresFiltered.length > 0){
                    for (let feature of featuresFiltered){
                        if (toVisible){
                            const label = buildLabel(feature, visibleSettings[groupKey]);
                            feature.setStyle(buildStyle(label));
                        }
                        else {
                            feature.setStyle(new Style());
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        updateStyles();
    }, [visibleSettings]);

    useEffect(() => {
        if (annotations && map && selectedGenericCut){
            const features:Feature[] = [];

            const extent = buildExtent(laneWidth, selectedGenericCut, factorVertical);

            annotations.forEach((annotation:IWellAnnotation) => {
                // let annotationDepth = annotation.Depths[0] * factorVertical;
                let start = extent[3] * -1;
                let annotationDepth =start + (annotation.Depths[0] - start) * factorVertical;
                console.log(annotation);
                //map.getView().calculateExtent(map.getSize())[2]

                let displacementYValue = 0;

                if (displacementVertical[selectedGenericCut.WellId]){
                    displacementYValue = displacementVertical[selectedGenericCut.WellId];
                }
                console.log(displacementVertical);
                const startLine = [extent[0], (annotationDepth * -1) - displacementYValue];
                const endLine = [laneWidth || 0 , (annotationDepth * -1) - displacementYValue];

                const feature = new Feature<LineString>({
                    geometry: new LineString([startLine,endLine])
                });
                feature.set('label', annotation.Labels);
                feature.set('name', annotation.Name);
                feature.set('groupKey', createGroupKey(annotation));
                feature.set('annotation', annotation);

                let style = buildStyle(annotation.Labels[0] + ' | ' + annotation.Labels[1]);
                feature.setStyle(style);

                features.push(feature);
            });

            if (layer.current && layer.current.getSource()){
                const source = layer.current.getSource()!;
                source.clear();
                source.addFeatures(features);

                updateStyles();
            }
            else {
                layer.current = new VectorLayer({
                    visible: true,
                    source: new VectorSource({
                        features:features
                    }),
                });

                map.addLayer(layer.current);
            }
        }
    }, [annotations, factorVertical, selectedGenericCut, laneWidth, displacementVertical]);

    return {
        isLoading: isLoading
    };
}