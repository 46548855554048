import React from 'react';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { LoginCallback } from '@okta/okta-react';

import { GeopostLogo } from 'components/geopostLogo/GeopostLogo';

export const LoginLoader = () => {
    return (
        <>
            <Box sx={{backgroundColor:'black'}} height={'100%'} width={'100%'} display='flex' alignItems='center' justifyContent='center'>
                <Stack spacing={2} width='300px'>
                    <Stack display='flex' alignItems='center'>
                        <GeopostLogo maxHeight={128} maxWidth={400}/>
                    </Stack>
                    <Stack spacing={1}>
                        <LinearProgress color='primary'/>
                    </Stack>
                </Stack>
            </Box>
            <LoginCallback/>
        </>
    );
};