import { FormControl } from '@mui/base';
import { Check, CheckBox, SettingsInputSvideoSharp } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, Collapse, createTheme, css, FormControlLabel, IconButton, InputLabel, Popper, Stack, styled, TextField, ThemeProvider, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ExposureIcon from '@mui/icons-material/Exposure';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGeopostThemeAppBar } from 'theme';

import { InputNumberValueSelector } from 'components/inputs/InputNumberValueSelector';
import { IAbsoluteGain } from '../stores/useSeismicStore';
import { calculateGain } from '../utils/gainUtils';

const inputStyle = css({
    '& .MuiFormControl-root': {
        '& .MuiOutlinedInput-notchedOutline': {
            '& legend': {
                width: '64px',
                borderWidth: '1px'
            }
        }
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            borderWidth: '1px',
            borderRadius: '5px'
        }
    }
});

export const AbsoluteGainMenu = ({ absoluteGain, onChange, defaultAbsoluteGain } : AbsoluteGainMenuProps) => {

    const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: 12,
            opacity: 1,
            zIndex: 99999999999
        },
    }));

    const [resetPopperAnchor, setResetPopperAnchor] = useState<HTMLElement | null>(null);
    const [applyPopperAnchor, setApplyPopperAnchor] = useState<HTMLElement | null>(null);

    const [maxGainInput, setMaxGainInput] = useState<string>(absoluteGain.max.toString());
    const maxGain = useMemo(() => parseFloat(maxGainInput), [maxGainInput]);

    const [minGainInput, setMinGainInput] = useState<string>(absoluteGain.min.toString());
    const minGain = useMemo(() => parseFloat(minGainInput), [minGainInput]);

    const [ gainStep, setGainStep ] = useState(10);

    const [isSymmetric, setIsSymmetric] = useState(false);

    const [ showLimitError, setShowLimitError ] = useState(false);

    const onApplyGain = (event: FormEvent) => {
        event.preventDefault();

        if (maxGain === 0 && minGain === 0) {
            setShowLimitError(true);
        } else {
            onChange({
                max: maxGain,
                min: minGain
            });
        }
    };

    const handleGainFactorChange = useCallback((factor: number) => {
        const value = calculateGain(factor, maxGain, minGain);
        if (!value) {
            return;
        }
        setMaxGainInput(value.max.toString());
        setMinGainInput(value.min.toString());
    }, [maxGain, minGain]);

    const handleGainFactorDecrease = () => handleGainFactorChange(gainStep);

    const handleReset = () => {
        setMaxGainInput(defaultAbsoluteGain.max.toString());
        setMinGainInput(defaultAbsoluteGain.min.toString());
    };

    const handleGainFactorIncrease = () => {
        const factor = gainStep * -1;
        if (factor < -99) {
            setShowLimitError(true);
        } else {
            handleGainFactorChange(factor);
        }
    };

    useEffect(() => {
        if (isSymmetric) {
            setMinGainInput((maxGain * -1).toString());
        }
    }, [isSymmetric]);

    useEffect(() => {
        if (absoluteGain.max !== maxGain) {
            setMaxGainInput(absoluteGain.max.toString());
        }
        if (absoluteGain.min !== minGain) {
            setMinGainInput(absoluteGain.min.toString());
        }
    }, [absoluteGain]);

    useEffect(() => {
        const symmetricMin = maxGain * -1;
        if (isSymmetric && symmetricMin !== minGain) {
            setMinGainInput(symmetricMin.toString());
        }
    }, [maxGain]);

    useEffect(() => {
        const symmetricMax = minGain * -1;
        if (isSymmetric && symmetricMax !== maxGain) {
            setMaxGainInput(symmetricMax.toString());
        }
    }, [minGain]);

    useEffect(() => {
        if (showLimitError) {
            setTimeout(() => setShowLimitError(false), 2000);
        }
    }, [showLimitError]);

    return (
        <Box css={inputStyle} width='160px' zIndex={0}>
            <form noValidate onSubmit={onApplyGain}>
                <Collapse in={showLimitError}>
                    <Alert variant='filled' severity='error' sx={{marginBottom: '20px'}}>
                        You can not change the contrast level to zero!
                    </Alert>
                </Collapse>
                <Stack spacing={1} justifyContent='center' alignItems='center'>
                    <Box paddingLeft={'10px'}>
                        <InputNumberValueSelector width={150} label={<Typography sx={{whiteSpace: 'nowrap', textAlign: 'start'}}>% Factor</Typography>} value={gainStep} onChange={(value) => setGainStep(value ?? 0)} onUpClick={handleGainFactorIncrease} onDownClick={handleGainFactorDecrease} stepIncrement={gainStep} staticComponent type='number'/>
                    </Box>
                    <FormControlLabel label={<Typography color='white'>Is symmetric</Typography>} control={
                        <Checkbox checked={isSymmetric} onChange={(event, checked) => setIsSymmetric(checked)}/>
                    }/>
                    <FormControl>
                        <TextField value={minGainInput} onChange={(event) => setMinGainInput(event.target.value)} label={<Typography>Min Value</Typography>}type='number' />
                    </FormControl>
                    <FormControl>
                        <TextField sx={ {outlineColor: '#000000'}} value={maxGainInput} onChange={(event) => setMaxGainInput(event.currentTarget.value)} label={<Typography>Max Value</Typography>} type='number'/>
                    </FormControl>
                    <FormControl>
                        <Stack sx={{marginTop: '0', marginBottom: '0'}} direction='row' justifyContent='space-between' spacing={2}>

                            <IconButton onClick={handleReset} onMouseEnter={(event) => setResetPopperAnchor(event.currentTarget)} onMouseLeave={() => setResetPopperAnchor(null)}>
                                <RestartAltIcon style={{color: 'white'}}></RestartAltIcon>
                            </IconButton>
                            <Popper open={!!resetPopperAnchor} anchorEl={resetPopperAnchor} sx={{zIndex: 9999999999999, backgroundColor: 'rgba(0,0,0,0.75)', borderRadius: '5px', padding: '5px 10px'}}>
                                <Typography fontWeight='bold' fontSize={'11px'} color='white'>Reset gain changes</Typography>
                            </Popper>
                            <IconButton type='submit' onMouseEnter={(event) => setApplyPopperAnchor(event.currentTarget)} onMouseLeave={() => setApplyPopperAnchor(null)}>
                                <OfflinePinIcon style={{color: 'white'}}></OfflinePinIcon>
                            </IconButton>
                            <Popper open={!!applyPopperAnchor} anchorEl={applyPopperAnchor} sx={{zIndex: 9999999999999, backgroundColor: 'rgba(0,0,0,0.75)', borderRadius: '5px', padding: '5px 10px'}}>
                                <Typography fontWeight='bold' fontSize={'11px'} color='white'>Apply gain</Typography>
                            </Popper>
                        </Stack>
                    </FormControl>
                </Stack>
            </form>
        </Box>
    );
};

export type AbsoluteGainMenuProps = {
    defaultAbsoluteGain: IAbsoluteGain;
    absoluteGain: IAbsoluteGain;
    onChange: (absoluteGain: IAbsoluteGain) => void;
};