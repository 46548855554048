import { RefObject, useEffect } from 'react';
import Map from 'ol/Map';

import { useCrossSectionStore } from 'features/cross-section/stores/useCrossSectionStore';

export function useUpdatedSizeMap(containerMap: RefObject<HTMLDivElement>, map: Map | null){
    const { setHeightMap } = useCrossSectionStore(state => ({
        setHeightMap: state.setHeightMap
    }));

    useEffect(() => {
        if (map && containerMap.current){
            setHeightMap(containerMap.current.offsetHeight);
        }
    }, [map]);

    useEffect(() => {
        window.addEventListener('resize', (event) => {
            if (containerMap.current){
                setHeightMap(containerMap.current.offsetHeight);
            }
        });
    }, []);
}