import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJoyride, { CallBackProps, EVENTS, Step } from 'react-joyride';

export const Well3DWebtour = ({ run, isWellsLoading } : Well3DWebtourProps) => {
    const { t } = useTranslation('seismic');
    const { current: steps } = useRef<Step[]>([
        {
            target: '#toggle-well-filters-button',
            content: t('webTour3D.wells.dataTypeFilterAcordion'),
            disableBeacon: true,
            hideFooter: true
        },
        {
            target: '.wells-filter-checkbox',
            content: t('webTour3D.wells.filterCheckbox'),
            disableBeacon: true,
            hideFooter: true
        },
        {
            target: '.troggle-well-filters-button',
            content: t('webTour3D.wells.closeDataTypeFilterAcordion'),
            disableBeacon: true,
            hideFooter: true
        },
        {
            target: '#wells-crossing-point-checkbox',
            content: t('webTour3D.wells.crossingPoint'),
            disableBeacon: true,
            hideFooter: true
        },
        {
            target: '#wells-directional-path-checkbox',
            content: t('webTour3D.wells.directionalPath'),
            disableBeacon: true,
            hideFooter: true
        },        {
            target: '#wells-time-depth-checkbox',
            content: t('webTour3D.wells.timeDepth'),
            disableBeacon: true,
            hideFooter: true
        },
        {
            target: '#wells-hydrocarbon-checkbox',
            content: t('webTour3D.wells.hydroCarbon'),
            disableBeacon: true,
            hideFooter: true
        },
        {
            target: '#wells-formation-top-checkbox',
            content: t('webTour3D.wells.formationTop'),
            disableBeacon: true,
            hideFooter: true
        },
        {
            target: '#wells-formation-test-checkbox',
            content: t('webTour3D.wells.formationTest'),
            disableBeacon: true,
            hideFooter: true
        },
        {
            target: '#wells-lithology-checkbox',
            content: t('webTour3D.wells.lithology'),
            disableBeacon: true,
            hideFooter: true
        },
        {
            target: '#toggle-well-select-all-button',
            content: t('webTour3D.wells.selectAllAccordion'),
            disableBeacon: true,
            hideFooter: true
        },
        {
            target: '.select-all-checkbox',
            content: t('webTour3D.wells.selectAllCheckbox'),
            disableBeacon: true,
            hideFooter: true
        }
    ]);

    const [ currentStepIndex, setCurrentStepIndex ] = useState(0);

    const { current: increaseStepIndex } = useRef(() => setCurrentStepIndex(index => index + 1));

    const handleWellJoyride = (data: CallBackProps) => {
        const target = data.step.target;

        if (typeof target === 'string') {
            const targetElement = document.querySelector(target as string);
            if (targetElement instanceof HTMLElement) {
                //targetElement.removeEventListener('click', clickListener);
                targetElement.addEventListener('click', increaseStepIndex, { once: true });
            }
        }
    };

    return (
        <ReactJoyride
            run={run && !isWellsLoading}
            steps={steps}
            continuous
            styles={{
                options: {
                    arrowColor: 'rgb(4, 31, 60)',
                    primaryColor: '#000',
                    width: 'fit-content',
                    zIndex: 99999999999999999,
                }
            }}
            locale={{
                back: t('webTour.back'),
                last: t('webTour.last'),
                next: t('webTour.next'),
                skip: t('webTour.skip')
            }}
            callback={handleWellJoyride}
            stepIndex={currentStepIndex}
            hideCloseButton
            spotlightClicks
            disableScrolling={true}
            disableOverlayClose
        />
    );
};

export type Well3DWebtourProps = {
    run: boolean,
    isWellsLoading: boolean,
};