import { point, lineSlice, lineString } from '@turf/turf';
import { Coordinate } from 'ol/coordinate';
import { LineString } from 'ol/geom';

export function splitLineByLine(lineStringOpenLayers: LineString, firstCoordinate: Coordinate, lastCoordinate: Coordinate) {
    const lineStringTurf = lineString(lineStringOpenLayers.getCoordinates());

    const split = lineSlice(point(firstCoordinate), point(lastCoordinate), lineStringTurf);
    //const turfCoordinates = split.geometry.coordinates.map(x=> x.geometry.coordinates).flat();
    //console.log(turfCoordinates);
    return new LineString(split.geometry.coordinates);
}