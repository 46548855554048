
import { css } from '@emotion/react';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';

import { useSessionStore } from 'session/useSessionStore';
import {useStartMap} from '../hooks/useStartMap';
import {useUpdateLayer} from '../hooks/useUpdateLayer';
import {useSeismicStore} from '../stores/useSeismicStore';
import {TraceHeaderTable} from './TraceHeaderTable';
import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';
import { useGetGeometryProjections } from './projections/hooks/useGetGeometryProjections';
import { GeopostSnackbar } from 'components/geopostSnackbar/GeopostSnackbar';
import { SeismicKeyboard } from './keyboard/components/SeismicKeyboard';

const mapStyle = css({
    width: '100%',
    flexGrow: 1,
});

const loadingStyle = css({
    position: 'absolute',
    background: 'rgb(0,0,0,.9)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
});

export function SeismicPanel() {
    const { colorbar, isLoading,showTraceHeaderTable } = useSeismicStore(state => ({
        colorbar: state.colorbar,
        isLoading: state.isLoading,
        showTraceHeaderTable: state.showTraceHeaderTable
    }));

    const { data: geometryLine } = useGetGeometryProjections();

    const ref = useRef<HTMLDivElement>(null);

    const seismic2DDefaults = useSessionStore(state => state.tenantConfiguration?.viewer2DDefaults);

    const { error } = useStartMap(ref);
    useUpdateLayer();
    //useInterpretations();
    //useMousePointerGeomUpdate(geometryLine);
    return (
        <Fragment>
            <div id='map' css={mapStyle} ref={ref} style={{backgroundColor: colorbar.Background}}></div>
            {isLoading && <div css={loadingStyle}>
                <CircularProgress size={60} style={{ color: '#fff' }} />
            </div>}
            {showTraceHeaderTable &&<TraceHeaderTable></TraceHeaderTable>}

            <GeopostSnackbar />

            <Snackbar open={!!error} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert variant='filled' severity="error">{error}</Alert>
            </Snackbar>
        </Fragment>
    );
}