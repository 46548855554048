import { Box, Button, css, Modal, TextField, Tooltip, Typography, Zoom } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { GridColDef } from '@mui/x-data-grid';
import { createRef, Fragment, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LinkIcon from '@mui/icons-material/Link';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import alertify from 'alertifyjs';
import { MainTableCss } from 'fileIndexing/envVar/envVar';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';
import { GeopostLoading } from 'fileIndexing/otherComponents/GeopostLoading';

import { IDataGroup, ISubgroup } from '../interfaces/ITaxonomy';
import { useDeleteDataSubGroup, useGetAllDataGroups, useGetDataSubGroupAndChildGroupsByDataGroupToken, useGetDataSubRuleBySubGroupToken, useGetOneDataSubGroup } from '../useTaxonomy';
import { TaxonomyForm } from './TaxonomyForm';
import { RegexRuleTable } from './RegexRuleTable';
import { ReindexTable } from './ReindexTable';
import { TestRulesTable } from './TestRulesTable';
import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { useSessionStore } from 'session/useSessionStore';
import { GroupsTreeView } from './GroupsTreeView';
import { EndpointsTable } from './EndpointsTable';

const style = css({
    margin: '0rem auto !important',
    '& .table-row': {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    '& .table-tree-view': {
        width: '20%',
        height: '100%',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
    },
    '& .table-header': {
        boxShadow: 'none !important'
    },
    '& .table-subgroups': {
        width: '79%'
    },
    '& .header-button': {
        marginBottom: '2rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    '& .manage-icon': {
        backgroundColor: '#1b5e20',
    }
});

export function TaxonomyTable() {
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }));

    const [openTestRulesModal, setOpenTestRulesModal] = useState<boolean>(false);
    const [openReindexModal, setOpenReindexModal] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openRuleModal, setOpenRuleModal] = useState<boolean>(false);
    const [openEndpointModal, setOpenEndpointModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<ISubgroup | null>(null);

    const [reindexToken, setReindexToken] = useState<string>('');
    const [editToken, setEditToken] = useState<string>('');
    const [deleteToken, setDeleteToken] = useState<string>('');
    const [subRuleToken, setSubRuleToken] = useState<string>('');
    const [subGroupToken, setSubGroupToken] = useState<string>('');

    const [dataGroupName, setDataGroupName] = useState<string>('');
    const [dataSubGroupName, setDataSubGroupName] = useState<string>('');

    const [subRuleTitle, setSubRuleTitle] = useState<string>('');

    const [filteredData, setFilteredData] = useState<ISubgroup[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [dataGroupSelected, setDataGroupSelected] = useState<string>('');

    const { data: subGroupAndGroupData, isLoading: subGroupAndGroupDataLoading, refetch, isRefetching: isRefetchingSubGroup } = useGetDataSubGroupAndChildGroupsByDataGroupToken(dataGroupSelected);
    const { data: allDataGroups, isLoading: allDataGroupsLoading, refetch: refetchDataGroup, isRefetching: dataGroupRefetchingLoading } = useGetAllDataGroups();
    const { data: subGroupGetByToken } = useGetOneDataSubGroup(editToken);
    const { data: deleteSubgroupData } = useDeleteDataSubGroup(deleteToken);

    const handleOpenReindex = (token: string, dataGroupName: string, dataSubgroupName: string) => {
        setReindexToken(token);
        setDataGroupName(dataGroupName);
        setDataSubGroupName(dataSubgroupName);
        setOpenReindexModal(true);
    };

    const handleCloseReindex = () => {
        setReindexToken('');
        setOpenReindexModal(false);
    };

    const handleOpenTestRules = () => setOpenTestRulesModal(true);
    const handleCloseTestRules = () => setOpenTestRulesModal(false);

    const findGroup = (groups: IDataGroup[], token: string): IDataGroup | undefined => {
        for (let group of groups) {
            if (group.Token === token) {
                return group;
            }
            if (group.Children) {
                const found = findGroup(group.Children, token);
                if (found) {
                    return found;
                }
            }
        }
        return undefined;
    };

    const handleOpenAddModal = () => {
        if (dataGroupSelected && allDataGroups) {
            let data = findGroup(allDataGroups, dataGroupSelected);
            if (data) {
                let newData: ISubgroup = {
                    CopyTransmittalFilesToInternalPath: false,
                    DataGroupName: data.Name,
                    DataGroupToken: data.Token,
                    DataSubRulesTokens: [''],
                    SubGroupEndpointTokens: [''],
                    InternalPath: '',
                    Name: '',
                    RegexPathExtractRule: '',
                    Token: '',
                    TransmittalInternalPath: ''
                };

                setOpenModal(true);
                setModalData(newData);
            }
        }
    };

    const handleCloseModal = () => setOpenModal(false);

    const handleOpenEndpointModal = (subGroupToken: string, dataSubgroupName: string) => {
        setOpenEndpointModal(true);
        setDataSubGroupName(dataSubgroupName);
        setSubGroupToken(subGroupToken);
    };

    const handleCloseEndpointModal = () => setOpenEndpointModal(false);

    const handleOpenEdit = (rowData: ISubgroup) => {
        setEditToken(rowData.Token);
    };

    const handleDelete = (name: string, token: string) => {
        alertify.confirm(`Do you really wanna delete ${name}?`, async () => {
            setDeleteToken(token);
        });
    };

    const handleOpenRules = (token: string, groupName: string) => {
        setSubRuleToken(token);
        setSubRuleTitle(groupName);
        setOpenRuleModal(true);
    };

    const handleCloseRules = () => {
        setSubRuleToken('');
        setOpenRuleModal(false);
    };

    const handleOpenFiles = (groupToken: string, subGroupToken: string) => {
        window.open(`/FileIndexing/files?tenantToken=${tenantConfig?.tenantToken}&groupToken=${groupToken}&subGroupToken=${subGroupToken}`, '_blank');
    };

    // reseta as informações preenchidas no modal
    useEffect(() => {
        if (!openModal) {
            setModalData(null);
        }
    }, [openModal]);

    // seta as informações para edit
    useEffect(() => {
        if (subGroupGetByToken) {
            setModalData(subGroupGetByToken);
            setOpenModal(true);
            setEditToken('');
        }
    }, [subGroupGetByToken]);

    // salva as informações de todas as linhas recebidas
    useEffect(() => {
        if (subGroupAndGroupData) {
            setFilteredData(subGroupAndGroupData);
        }
    }, [subGroupAndGroupData]);

    // realiza o filtro
    useEffect(() => {
        if (subGroupAndGroupData) {
            if (filter === '') {
                setFilteredData(subGroupAndGroupData);
            }
            else {
                setFilteredData(subGroupAndGroupData.filter(x => x.DataGroupName?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Name?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.DataSubRulesTokens?.length.toString() === filter.toLowerCase() ||
                    x.InternalPath?.toString().toLowerCase().startsWith(filter.toLowerCase())));
            }
        }
    }, [filter]);

    // deleta o contract
    useEffect(() => {
        if (deleteSubgroupData === 'Success') {
            refetch();
        }
    }, [deleteSubgroupData]);

    // conteúdo da tabela
    const columns: GridColDef[] = [
        {
            field: 'DataGroupName',
            headerAlign: 'left',
            headerName: 'Group',
            flex: 0.5,
        },
        {
            field: 'Name',
            headerAlign: 'left',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'DataSubRulesTokens',
            headerAlign: 'left',
            headerName: 'Total Rules',
            flex:  0.5,
            valueGetter: (params) => {
                return params.row.DataSubRulesTokens.length;
            },
        },
        {
            field: 'SubGroupEndpointTokens',
            headerAlign: 'left',
            headerName: 'Total Endpoints',
            flex: 0.5,
            valueGetter: (params) => {
                return params.row.SubGroupEndpointTokens.length;
            },
        },
        {
            field: 'InternalPath',
            headerAlign: 'left',
            headerName: 'Path',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'Options',
            headerName: 'Options',
            headerAlign: 'center',
            sortable: false,
            minWidth: 180,
            flex: 2,
            renderCell: (params) => (
                <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Tooltip title='Reindex' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' onClick={() => handleOpenReindex(params.row.Token, params.row.DataGroupName, params.row.Name)}>
                            <DoneAllIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Edit subgroup name and internal path' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' className='edit-icon' onClick={() => handleOpenEdit(params.row)}>
                            <EditNoteIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Manage Endpoints' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' className='endpoint-icon' onClick={() => handleOpenEndpointModal(params.row.Token, params.row.Name)}>
                            <AccountTreeIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Manage subgroup regex rules' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' className='edit-icon' onClick={() => handleOpenRules(params.row.Token, params.row.Name)}>
                            <LockOpenIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Delete subgroup' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='error' className='delete-icon' onClick={() => handleDelete(params.row.Name, params.row.Token)}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='View files from this subgroup' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' className='files-icon' onClick={() => handleOpenFiles(params.row.DataGroupToken, params.row.Token)}>
                            <LinkIcon />
                        </Button>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    const getRowId = (row: ISubgroup) => row.Token;

    return (
        <Fragment>
            <Box id='table-taxonomy' css={[style, MainTableCss]}>
                <Box className='header-button'>
                    <Button color='primary' variant='contained' style={{ width: '150px' }} onClick={() => handleOpenTestRules()}>Test Rules</Button>
                </Box>
                <Box className='table-row'>
                    <Box className='table-tree-view'>
                        <GroupsTreeView refetch={refetchDataGroup} allDataGroups={allDataGroups} allDataGroupsLoading={allDataGroupsLoading || dataGroupRefetchingLoading} setDataGroupSelected={setDataGroupSelected} />
                    </Box>
                    <Box className='table-subgroups'>
                        <Box className='table-header'>
                            <Typography className='table-header-title'>Subgroups</Typography>
                            <Box className='search'>
                                <TextField label='Type to filter subgroup table' variant='outlined' size='small' color='primary' value={filter} onChange={(event) => setFilter(event.target.value)} />
                                <Button variant='contained' color='primary' className='add-icon' disabled={dataGroupSelected === '' ? true : false} onClick={() => {handleOpenAddModal()}}>
                                    <AddIcon />
                                </Button>
                            </Box>
                        </Box>
                        <GeopostTable<ISubgroup> filteredData={filteredData} loading={subGroupAndGroupDataLoading || isRefetchingSubGroup} columns={columns} getRowId={getRowId} disableShadow={true} height={285} />
                    </Box>
                </Box>
            </Box>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openTestRulesModal}
                onChangeOpen={handleCloseTestRules}
                resizable={false}
                width={1400}
                height={410}
                title={'Check Rules'}
                padding={'16px'}
            >
                <TestRulesTable />
            </GeopostPopup>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openModal}
                onChangeOpen={handleCloseModal}
                resizable={false}
                width={610}
                height={530}
                title={modalData ? `Edit ${modalData.Name}` : 'Add a new Subgroup'}
                padding={'16px'}
            >
                <TaxonomyForm setOpenModal={setOpenModal} refetchTableData={refetch} formData={modalData} />
            </GeopostPopup>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openReindexModal}
                onChangeOpen={handleCloseReindex}
                resizable={false}
                width={1800}
                height={415}
                title={'Reindex'}
                padding={'16px'}
            >
                <ReindexTable setOpenModal={setOpenReindexModal} groupName={dataGroupName} subGroupName={dataSubGroupName} reindexToken={reindexToken} />
            </GeopostPopup>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openRuleModal}
                onChangeOpen={handleCloseRules}
                resizable={false}
                width={770}
                height={760}
                title={`${subRuleTitle} Rules`}
                padding={'16px'}
            >
                <RegexRuleTable setOpenModal={setOpenRuleModal} refetchTableData={refetch} headerTitle={subRuleTitle} dataToken={subRuleToken} />
            </GeopostPopup>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openEndpointModal}
                onChangeOpen={handleCloseEndpointModal}
                resizable={false}
                width={1500}
                height={760}
                title={`${dataSubGroupName} Endpoints`}
                padding={'16px'}
            >
                <EndpointsTable setOpenModal={setOpenEndpointModal} subGroupName={dataSubGroupName} subGroupToken={subGroupToken} />
            </GeopostPopup>
        </Fragment>
    );
}