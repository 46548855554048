import View from 'ol/View';
import { create } from 'zustand';

import { WIDTH_CONNECTION_LANE } from '../consts/layotsConsts';

interface ICrossSectionConnectionStore {
    connectionWidth: number;
    connectionViews: {[key: string]:View};
    connectionStartDestination: { [key: string]:{start:number,
        destination:number,WellId:number},};
    setConnectionView: (id: string, view: View) => void;
    setConnectionWidth: (value: number) => void;
    setConnectionStartDestination:(value:{}) => void;
}

export const useCrossSectionConnectionStore = create<ICrossSectionConnectionStore>()(
    (set,get) => ({
        connectionWidth: WIDTH_CONNECTION_LANE,
        connectionViews: {},
        connectionStartDestination : {},
        setConnectionWidth:(value :number) => set({
            connectionWidth: value
        }),
        setConnectionStartDestination: (value:{})=>{
            set({connectionStartDestination:value});
        },
        setConnectionView: (id: string, view: View) => {
            const views = get().connectionViews;

            views[id] = view;

            set({
                connectionViews: views
            });
        }
    }),
);