import { createTheme } from '@mui/material/styles';

import { useSessionStore } from 'session/useSessionStore';
import { createThemeAppBar } from 'themes/createThemeAppBar';

// export const useGeopostTheme = () => {
//     const {tenantConfig} = useSessionStore(state => ({
//         tenantConfig: state.tenantConfiguration
//     }));
//     const theme = createTheme(tenantConfig?.main);
//     return theme;
// };

export const useGeopostThemeAppBar = () => {
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    const theme = createThemeAppBar(tenantConfig?.appBar ? tenantConfig?.appBar : {
        displayNamePosition: 'left',
        checkbox: {
            color: tenantConfig?.appBar?.checkbox ? tenantConfig?.appBar?.checkbox?.color : '#000'
        },
        input: {
            background: tenantConfig?.appBar?.input ? tenantConfig?.appBar?.input?.background : '#ffffff22',
            color: tenantConfig?.appBar?.input ? tenantConfig?.appBar?.input?.color : '#ccc',
            size: tenantConfig?.appBar?.input ? tenantConfig?.appBar?.input?.size : 'small',
        },
        inputLabel: {
            color: tenantConfig?.appBar?.inputLabel ? tenantConfig?.appBar?.inputLabel?.color : '#ccc',
        },
        menu:{
            bgColor: tenantConfig?.appBar?.menu ? tenantConfig?.appBar?.menu?.bgColor : '#ccc',
            color: tenantConfig?.appBar?.menu ? tenantConfig?.appBar?.menu?.color : '#ccc',
        }
    });
    return theme;
};