import * as THREE from 'three';

import { interpolate } from 'features/seismic-3d/components/projections/featureGeomPolygon';
import { GeopostGeometry } from '../geometry/GeopostGeometry';
import { GeopostTileGeometry } from '../geometry/GeopostTileGeometry';

export class GeopostTileMesh extends THREE.Mesh {
    public constructor (firstLineCoordinate: number[], lastLineCoordinate: number[], tilesTotal: number, tileIndex: number, imageBottomY : number, imageTopY: number) {
        const firstCoordinate = interpolate(firstLineCoordinate, lastLineCoordinate, tileIndex / tilesTotal);
        const nextTileFirstCoordinate = interpolate(firstLineCoordinate, lastLineCoordinate, (tileIndex + 1) / tilesTotal);
        const tileGeometry = new GeopostTileGeometry(firstCoordinate, nextTileFirstCoordinate, imageTopY, imageBottomY);
        const  material = new THREE.MeshPhongMaterial({color: '#cccccc', side: THREE.DoubleSide, transparent: true, opacity: 0.4});
        super(tileGeometry, material);
    };
}

/*export class GeopostTileMesh2 extends THREE.Mesh {
    public constructor (minLineCoordinate: THREE.Vector3, maxLineCoordinate: THREE.Vector3, tileColumns: number, tileRows: number, tileColumn: number, tileRow: number, imageBottomY : number, imageTopY: number) {
        const widthPerTile = Math.abs(Math.abs(maxLineCoordinate[0]) - Math.abs(minLineCoordinate[0])) / tileColumns;
        const heightPerTile =  Math.abs(Math.abs(maxLineCoordinate[1]) - Math.abs(minLineCoordinate[1])) / tileRows;

        const minCoordinate = [minLineCoordinate[0] + tileColumn * widthPerTile, minLineCoordinate[1] + tileRow * heightPerTile];
        const maxCoordinate = [minCoordinate[0] + widthPerTile, minCoordinate[0] + heightPerTile];

        const points = [
            new THREE.Vector3(minLineCoordinate[0], )
        ]
        const tileGeometry = new GeopostTileGeometry(firstCoordinate, nextTileFirstCoordinate, imageTopY, imageBottomY);
        const  material = new THREE.MeshPhongMaterial({color: '#cccccc', side: THREE.DoubleSide, transparent: true, opacity: 0.4});
        super(tileGeometry, material);
    };
}*/