import { LineType } from '../enums/LineType';
import { ISeismicCalculatorLineType } from '../interfaces/ISeismicCalculatorLineType';
import { ISurveyMetadata } from '../interfaces/ISurveyMetadata';

export class SeismicCalculatorXline implements ISeismicCalculatorLineType {
    getLineType(surveyMetadata: ISurveyMetadata) {
        return LineType.Xline;
    }

    getMinTrace(surveyMetadata: ISurveyMetadata) {
        return surveyMetadata.Survey3DInfo?.InlineStart ?? 0;
    }

    getMaxTrace(surveyMetadata: ISurveyMetadata) {
        if (!surveyMetadata.Survey3DInfo){
            return 0;
        }
        return surveyMetadata.Survey3DInfo.InlineEnd;
    }

    getTotalTraces (surveyMetadata: ISurveyMetadata) {
        if (!surveyMetadata.Survey3DInfo){
            return 0;
        }
        return surveyMetadata.Survey3DInfo.InlineEnd - surveyMetadata.Survey3DInfo.InlineStart;
    }

    getMaxLine(surveyMetadata: ISurveyMetadata) {
        if (!surveyMetadata.Survey3DInfo){
            return 0;
        }
        return surveyMetadata.Survey3DInfo.XlineEnd;
    }

    getMinLine(surveyMetadata: ISurveyMetadata) {
        if (!surveyMetadata.Survey3DInfo){
            return 0;
        }
        return surveyMetadata.Survey3DInfo.XlineStart;
    }

    getLineIncrement(surveyMetadata: ISurveyMetadata){
        if (!surveyMetadata.Survey3DInfo){
            return 0;
        }
        return surveyMetadata.Survey3DInfo.InlineIncrement;
    }

    getSkipTrace(surveyMetadata: ISurveyMetadata){
        if (!surveyMetadata.Survey3DInfo){
            return 0;
        }
        return surveyMetadata.Survey3DInfo.XlineIncrement;
    }
}