import { Button, Grid } from '@mui/material';
import { forwardRef, Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import alertify from 'alertifyjs';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { GeopostDropdown } from 'fileIndexing/otherComponents/GeopostDropdown';
import { FormContainer } from 'react-hook-form-mui';

import { IInstitution } from '../interfaces/IInstitution';
import { useSaveInstitution } from '../useInstitution';

export const InstitutionsForm = ({ setOpenModal, refetchTableData, setLoading, formData }: Props) => {
    const { control } = useForm<IInstitution>({ defaultValues: formData || undefined });

    const [data, setData] = useState<IInstitution | null>(null);

    const { data: saveInstitutionsData, isLoading } = useSaveInstitution(data);

    const handleData = (data: IInstitution) => {
        let newData = data;
        if (formData) {
            newData.Token = formData.Token;
        }
        else {
            newData.Token = '';
        }
        setData(newData);
    };

    // AVISO: talvez seja retirada essa parte
    function wait(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function executeSave(): Promise<void> {
        setOpenModal(false);
        setLoading(true);
        await wait(2000);
        setLoading(false);
        refetchTableData();
    }

    useEffect(() => {
        if (saveInstitutionsData) {
            if (saveInstitutionsData === 200) {
                executeSave();
            }
            else {
                alertify.notify('Ocorreu um erro criando uma nova Instituição');
            }
        }
    }, [saveInstitutionsData]);

    const geopostDropdownItems = [
        {
            id: 'Gov',
            label: 'Gov'
        },
        {
            id: 'EAD',
            label: 'EAD'
        },
        {
            id: 'Partners',
            label: 'Partners'
        },
    ];

    return (
        <Fragment>
            <FormContainer<IInstitution> defaultValues={formData ?? {}} onSuccess={(data) => handleData(data)} FormProps={{ style:{ width: '100%' } }}>
                <Grid spacing={2} container>
                    <Grid spacing={2} container item>
                        <Grid xs={12} item>
                            <GeopostTextField fieldName={'Name'} label={'Institution Name'} />
                        </Grid>
                        <Grid xs={12} item>
                            <GeopostDropdown fieldName={'Type'} label={'Institution Type'} items={geopostDropdownItems} />
                        </Grid>
                    </Grid>
                    <Grid container item direction="row-reverse">
                        <Button variant='contained' color='primary' type='submit'>SAVE</Button>
                    </Grid>
                </Grid>
            </FormContainer>
        </Fragment >
    );
};

interface Props {
    setOpenModal: (value: boolean) => void;
    refetchTableData: () => void;
    setLoading: (value: boolean) => void;
    formData?: IInstitution | null;
}