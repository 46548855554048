import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import React from 'react';

import { useSessionStore } from 'session/useSessionStore';

type LogoutButtonProps = {
    onLogout: () => void
};

const OktaLogoutButton = ( { onLogout } : LogoutButtonProps ) => {

    const { oktaAuth } = useOktaAuth();
    const { current: cookies } = useRef(new Cookies());
    const tenantToken = useSessionStore(state => state.tenantConfiguration?.tenantToken);

    const logout = useCallback(async () => {
        const authCookieName = 'auth_spa_' + tenantToken;
        cookies.remove(authCookieName);
        const logoutUrl = window.location.protocol + '//' + window.location.host + '/logout';

        await oktaAuth.signOut({
            postLogoutRedirectUri: logoutUrl,
            clearTokensBeforeRedirect: true
        });
    }, [cookies, oktaAuth, tenantToken]);

    return (<IconButton color='inherit' title='Click here to logout from the app' onClick={() => logout().then(onLogout)}><LogoutIcon color='inherit'/></IconButton>);
};

const GeopostLogoutButton = ( { onLogout } : LogoutButtonProps ) => {
    const { current: cookies } = useRef(new Cookies());
    const tenantToken = useSessionStore(state => state.tenantConfiguration?.tenantToken);

    const logout = useCallback(async () => {
        const authCookieName = 'auth_spa_' + tenantToken;
        cookies.remove(authCookieName);
    }, [tenantToken, cookies]);

    return (<IconButton color='inherit' onClick={() => logout().then(onLogout)} title='Click here to logout from the application' ><LogoutIcon color='inherit'/></IconButton>);
};

export const LogoutButton = () => {

    const oktaAuthEnabled = useSessionStore(state => !!state.tenantConfiguration?.oktaAuthConfiguration);
    const redirectionEndpoint = useSessionStore(state => state.tenantConfiguration?.endpoints.logoutRedirection);

    const navigate = useNavigate();

    const onLogout = () => {
        if (!!redirectionEndpoint) {
            //window.location.assign(redirectionEndpoint);
        } else {
            //navigate('/logout');
        }
    };

    return (
        <React.Fragment>
            {
                oktaAuthEnabled ?
                    <OktaLogoutButton onLogout={onLogout}/>
                    :
                    <GeopostLogoutButton onLogout={onLogout}/>
            }
        </React.Fragment>
    );
};