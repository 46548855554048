import { ISurveyMetadata } from '../models/interfaces/ISurveyMetadata';

export function getTrace(x: number, scaleX: number, min: number, increment: number) {
    return min + Math.round((parseInt(x.toString()) - min) / scaleX * increment);
}

export function getAmplitude(y: number, scaleY: number, sampleInterval: number){
    const valueY = Math.round(y * -1 * sampleInterval / scaleY);
    return Math.round(valueY).toString();
}

export const getSurveyExtentDistance = (surveyMetadata: ISurveyMetadata) => {
    const a = (surveyMetadata.Survey3DInfo.InlineEnd - surveyMetadata.Survey3DInfo.InlineStart) / surveyMetadata.Survey3DInfo.InlineIncrement;
    const b = (surveyMetadata.Survey3DInfo.XlineEnd - surveyMetadata.Survey3DInfo.XlineStart) / surveyMetadata.Survey3DInfo.XlineIncrement;
    return Math.round(Math.sqrt(a * a + b * b));
};

export function getZSliceY(y: number, scaleY: number, xlineIncrement: number, xlineStart: number) {
    return Math.round(y * -1 * xlineIncrement / scaleY) + xlineStart;
}

export function getXByTrace(trace: number, scaleX: number, min: number, increment: number) {
    return min + ((trace - min) / increment) * scaleX;
}

export function getYByAmplitude(amplitude: number, scaleY: number, sampleInterval: number){
    return (amplitude * scaleY) / sampleInterval * -1;
}

export function getXByScale(x: number, scaleX: number, min: number) {
    //usada na interpretação. quando eu salvo o x no banco e quero esse mesmo x com o valor de escala
    return min + ((x - min) * scaleX);
}

export function getYByScale(y: number, scaleY: number){
    //usada na interpretação. quando eu salvo o x no banco e quero esse mesmo x com o valor de escala
    return y * scaleY;
}

export function getOriginalXByScale(x: number, scaleX: number, min: number) {
    //usada na interpretação. quando eu salvo o x no banco e quero esse mesmo x com o valor de escala
    return min + ((x - min) / scaleX);
}

export function getOriginalYByScale(y: number, scaleY: number){
    //usada na interpretação. quando eu salvo o x no banco e quero esse mesmo x com o valor de escala
    return y / scaleY;
}