import { css } from '@emotion/react';

import { useSessionStore } from 'session/useSessionStore';

const style = css({
    maxWidth: 130,
    maxHeight: 32,
    marginTop: '-8px !important',
    marginLeft: '10px !important',
});

export function SeismicLogoNavbar() {
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    const logoPath = tenantConfig?.layout.logoPath;

    if (!logoPath){
        return null;
    }

    return (<img css={style} src={logoPath} />);
}