import { Button, Grid } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { GeopostDropdownInstitutions } from 'fileIndexing/otherComponents/GeopostDropdownInstitutions';
import alertify from 'alertifyjs';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { GeopostDateInput } from 'fileIndexing/otherComponents/GeopostDateInput';
import { GeopostDropdown } from 'fileIndexing/otherComponents/GeopostDropdown';
import { FormContainer } from 'react-hook-form-mui';
import dayjs from 'dayjs';

import { IContract } from '../interfaces/IContract';
import { useSaveContract } from '../useContracts';

export const ContractsForm = ({ setOpenModal, refetchTableData, setLoading, formData }: Props) => {
    const [data, setData] = useState<IContract | null>(null);

    const { data: saveContractsData } = useSaveContract(data);

    const handleData = (data: IContract) => {
        let newData = data;
        if (formData) {
            newData.Token = formData.Token;
        }
        else {
            newData.Token = '';
        }
        debugger;

        newData.SigningDate = dayjs(newData.SigningDate).format('DD/MM/YYYY');
        newData.Year = Number(dayjs(newData.Year).format('YYYY'));

        setData(newData);
    };

    // AVISO: talvez seja retirada essa parte
    function wait(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function executeSave(): Promise<void> {
        setOpenModal(false);
        setLoading(true);
        await wait(2000);
        setLoading(false);
        refetchTableData();
    }

    useEffect(() => {
        if (saveContractsData) {
            if (saveContractsData === 200) {
                executeSave();
            }
            else {
                alertify.notify('An error occurred creating the new Contract');
            }
        }
    }, [saveContractsData]);

    const geopostDropdownItems = [
        {
            id: 'Seismic2D',
            label: 'Seismic 2D',
        },
        {
            id: 'Seismic3D',
            label: 'Seismic 3D',
        },
        {
            id: 'NotSeismic',
            label: 'Not Seismic',
        },
    ];

    return (
        <Fragment>
            <FormContainer<IContract> defaultValues={formData ?? {}} onSuccess={(data) => handleData(data)}>
                <Grid spacing={2} container direction="column">
                    <Grid spacing={2} container item direction="row">
                        <Grid xs={6} item>
                            <GeopostDropdownInstitutions fieldName={'CompanyToken'} label={'Company'} />
                        </Grid>
                        <Grid xs={6} item>
                            <GeopostDropdown fieldName={'SurveyType'} label={'Survey Type'} items={geopostDropdownItems} />
                        </Grid>
                    </Grid>

                    <Grid container item direction="row">
                        <GeopostTextField fieldName={'ContractObject'} label={'Contract object'} />
                    </Grid>

                    <Grid container item direction="row">
                        <GeopostTextField fieldName={'SapCode'} label={'Sap code'} />
                    </Grid>

                    <Grid spacing={2} container item direction="row">
                        <Grid xs={6} item>
                            <GeopostTextField fieldName={'Block'} label={'Block'} />
                        </Grid>
                        <Grid xs={6} item>
                            <GeopostTextField fieldName={'StorageSummary'} label={'Storage summary'} />
                        </Grid>
                    </Grid>

                    <Grid spacing={2} container item direction="row">
                        <Grid xs={6} item>
                            <GeopostTextField fieldName={'ContractedKm'} label={'Contracted Km'} textFieldProps={{ type: 'number' }} />
                        </Grid>
                        <Grid xs={6} item>
                            <GeopostTextField fieldName={'EquivalentKm'} label={'Equivalent Km'} textFieldProps={{ type: 'number' }} />
                        </Grid>
                    </Grid>

                    <Grid spacing={2} container item direction="row">
                        <Grid xs={4} item>
                            <GeopostDateInput fieldName={'SigningDate'} label={'Order Date'} dateType={'DD/MM/YYYY'} />
                        </Grid>
                        <Grid xs={4} item>
                            <GeopostDateInput fieldName={'Year'} label={'Year'} dateType={'YYYY'} />
                        </Grid>
                        <Grid xs={4} item>
                            <GeopostTextField fieldName={'ContractValue'} label={'Contract value (R$)'} textFieldProps={{ type: 'number' }} />
                        </Grid>
                    </Grid>
                    <Grid spacing={2} container item direction="row">
                        <Grid xs={12} item>
                            <GeopostTextField fieldName={'ContractGoal'} label={'Contract goal'} />
                        </Grid>
                    </Grid>
                    <Grid spacing={2} container item direction="row">
                        <Grid xs={12} item>
                            <GeopostTextField fieldName={'GeneralNotes'} label={'General notes'} />
                        </Grid>
                    </Grid>
                    <Grid spacing={2} container item direction="row">
                        <Grid xs={12} item>
                            <GeopostTextField fieldName={'Supplements'} label={'Supplements'} />
                        </Grid>
                    </Grid>
                    <Grid container item direction="row-reverse">
                        <Button variant='contained' color='primary' type='submit' style={{ marginBottom: '16px' }}>SAVE</Button>
                    </Grid>
                </Grid>
            </FormContainer>
        </Fragment>
    );
};

interface Props {
    setOpenModal: (value: boolean) => void;
    refetchTableData: () => void;
    setLoading: (value: boolean) => void;
    formData?: IContract | null;
}