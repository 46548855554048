import { Box, Button, css, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import alertify from 'alertifyjs';
import { GeopostLoading } from 'fileIndexing/otherComponents/GeopostLoading';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { TagGroupsForm } from './TagGroupsForm';
import { ITagGroup } from '../interfaces/ITag';
import { useDeleteTagGroup, useGetAllTagGroups } from '../useTags';

let style = css({
    marginBottom: '32px',
    '& .tag-groups-row': {
        display: 'flex',
        flexDirection: 'row',
    },
    '& .tag-group-dropdown-and-loading': {
        width: '200px',
        position: 'relative',
    },
    '& .tag-group-dropdown': {
        width: '200px',
    }
});

export function TagGroup({ tagGroupSelected, setTagGroupSelected }: Props) {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<ITagGroup | null>(null);

    const [deleteToken, setDeleteToken] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    const { data: tagGroupsData, isLoading: isLoadingTagGroup, refetch: refetch } = useGetAllTagGroups();
    const { data: deleteTagGroupData } = useDeleteTagGroup(deleteToken);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    // seta as informações para edit
    const handleOpenEdit = () => {
        if (tagGroupSelected && tagGroupsData) {
            let tagGroup = tagGroupsData.find(x => x.Token === tagGroupSelected);
            if (tagGroup) {
                setModalData(tagGroup);
                setOpenModal(true);
            }
        }
    };

    const handleDelete = () => {
        alertify.confirm(`Do you really wanna delete ${tagGroupsData?.find(x => x.Token === tagGroupSelected)?.Name}?`, async () => {
            setDeleteToken(tagGroupSelected);
        });
    };

    // reseta as informações preenchidas no modal de tagGroup
    useEffect(() => {
        if (!openModal) {
            setModalData(null);
        }
    }, [openModal]);

    // delete o tagGroup selecionado
    useEffect(() => {
        if (deleteTagGroupData === 'Success') {
            refetch();
        }
    }, [deleteTagGroupData]);

    return (
        <Fragment>
            <Box css={style}>
                <Box className='tag-groups-row'>
                    <Box className='tag-group-dropdown-and-loading'>
                        <GeopostLoading loading={isLoadingTagGroup} />
                        <FormControl disabled={isLoadingTagGroup || loading} className='tag-group-dropdown' size='small' >
                            <InputLabel color='primary'>Tag Groups</InputLabel>
                            <Select
                                value={tagGroupSelected}
                                onChange={(event) => { setTagGroupSelected(event.target.value) }}
                                label='Data Group Filter'
                                color='primary'
                            >
                                <MenuItem key={'None'} value={''}>None</MenuItem>
                                {tagGroupsData?.map((tagGroupData) => (
                                    <MenuItem key={tagGroupData.Token} value={tagGroupData.Token}>{tagGroupData.Name}</MenuItem>
                                ))};
                            </Select>
                        </FormControl>
                    </Box>
                    <Button variant='contained' color='primary' className='add-icon' onClick={() => handleOpenModal()}>
                        <AddIcon />
                    </Button>
                    <Button disabled={tagGroupSelected === ''} variant='contained' color='primary' className='add-icon' onClick={() => handleOpenEdit()}>
                        <EditNoteIcon />
                    </Button>
                    <Button disabled={tagGroupSelected === ''} variant='contained' color='error' className='delete-icon' onClick={() => handleDelete()}>
                        <DeleteIcon />
                    </Button>
                </Box>

                <GeopostPopup
                    temporaryIgnoreFlexInContainer
                    open={openModal}
                    onChangeOpen={handleCloseModal}
                    resizable={false}
                    width={560}
                    minHeight={260}
                    height={260}
                    maxHeight={500}
                    title={modalData ? `Edit ${modalData.Name}` : 'Add Tag Group'}
                    padding={'16px'}
                >
                    <TagGroupsForm setOpenModal={setOpenModal} refetchTableData={refetch} setLoading={setLoading} formData={modalData} />
                </GeopostPopup>
            </Box>
        </Fragment>
    );
}

interface Props {
    tagGroupSelected: string;
    setTagGroupSelected: (value: string) => void;
}