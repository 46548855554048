import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { usePerformanceMonitoringStore } from 'features/seismic/stores/usePerformanceMonitoringStore';
import { MetadataPerformanceData } from 'features/seismic/models/types/MetadataPerformanceData';

export type MetadataPerformanceDataProps = {
    performanceData: MetadataPerformanceData
};

export const MetadataPerformancePanel = () => {
    const performanceData = usePerformanceMonitoringStore(state => state.metadataPerformanceData);

    if (performanceData === null){
        return null;
    }

    return (
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Volume token
                    </TableCell>
                    <TableCell>
                        Total time lapsed (sec.)
                    </TableCell>
                    <TableCell>
                        Api query time (sec.)
                    </TableCell>
                    <TableCell>
                        Server processing time (sec.)
                    </TableCell>
                    <TableCell>
                        Request network time (sec.)
                    </TableCell>
                    <TableCell>
                        Response network time (sec.)
                    </TableCell>
                    <TableCell>
                        Error
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>
                        { performanceData.volumeToken }
                    </TableCell>
                    <TableCell>
                        { performanceData.totalTime.toFixed(3) ?? '?' }
                    </TableCell>
                    <TableCell>
                        { performanceData.apiTime?.toFixed(3) ?? '?' }
                    </TableCell>
                    <TableCell>
                        { performanceData.serverProcessingTime?.toFixed(3) ?? '?' }
                    </TableCell>
                    <TableCell>
                        { performanceData.requestNetworkTime?.toFixed(3) ?? '?' }
                    </TableCell>
                    <TableCell>
                        { performanceData.responseNetworkTime?.toFixed(3) ?? '?' }
                    </TableCell>
                    <TableCell>
                        { performanceData.error }
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};