import { useQuery } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { WmsRequest } from '../models/types/WmsRequest';
import { getUrlParams } from '../utils/Seismic3DUrlUtils';
import { axios } from 'configurations/axios';

export const useWMS = (requestData: WmsRequest | undefined) => {
    const apiUrl = useSessionStore(state => state.tenantConfiguration?.endpoints.geoServerProxy);
    return useQuery(
        [requestData],
        async () => {
            if (!requestData) {
                return;
            }
            const response = await axios.get<File>(
                apiUrl + '/MapProxy/wms',
                {
                    params: {
                        ...getWmsParams(requestData)
                    },
                    headers: {
                        Authorization: 'Bearer ' + getUrlParams().jwtToken,
                        'Content-Type': 'application/json'
                    },
                    responseType: 'blob'
                }
            );
            return response.data;
        },
        {
            enabled: !!requestData
        }
    );
};

const getWmsParams = (requestData: WmsRequest) => ({
    host: requestData.hostServer + '/wms',
    service: 'wms',
    layers: requestData.workspace + ':' + requestData.layerName,
    version: '1.3.0',
    request: 'GetMap',
    format: requestData.imageFormat,
    transparent: true,
    format_optitons: 'antialias:none',
    styles: undefined,
    crs: 'EPSG:' + requestData.srid,
    width: requestData.width,
    height: requestData.height,
    sld: requestData.sldHost + '/SLD/GetSLD?token=' + requestData.sldToken,
    bbox: `${requestData.extent[0]},${requestData.extent[1]},${requestData.extent[2]},${requestData.extent[3]}`,
    bgColor: requestData.backgroundColor
});