import { useQuery } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { axios } from 'configurations/axios';

export function useCheckForSeismic3D(tenantToken : string) {
    const [apiUrl, jwtToken, sessionTenantToken] = useSessionStore(state => [state.tenantConfiguration?.endpoints.featureFlags, state.jwtToken, state.tenantConfiguration?.tenantToken]);
    const requestTenantToken = tenantToken !== '' ? tenantToken : sessionTenantToken;

    return useQuery(
        ['CheckForSeismic3D', requestTenantToken],
        async () => {
            const response = await axios.get<IResponseAPI<string[]>>(
                apiUrl + '/FeatureFlag/CheckForSeismic3D',
                {
                    params: {
                        tenantToken: requestTenantToken
                    },
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            return response.data.Result;
        }
    );
}