import { ISendFilter } from 'fileIndexing/files/interfaces/IFiles';
import { useGetDataSubGroupByDataGroupTokenDropdown } from 'fileIndexing/taxonomies/useTaxonomy';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { GeopostDropdown } from './GeopostDropdown';

export function GeopostDropdownDataSubGroup({ fieldName, label, dataGroupToken, noneItem = false, required = false, disabled = false }: Props) {
    const [dataGroupItems, setDataGroupItems] = useState<{ id: string; label: string; }[]>([]);

    const { data: dataSubGroups, isLoading } = useGetDataSubGroupByDataGroupTokenDropdown(dataGroupToken);

    useEffect(() => {
        if (dataSubGroups && dataGroupToken) {
            let createDataSubGroupItems: {id: string, label: string}[] = [];

            if (noneItem) {
                createDataSubGroupItems.push({
                    id: '',
                    label: 'None'
                });
            }

            let getAllDataSubGroups = dataSubGroups.map(dataSubGroup => ({
                id: dataSubGroup.Token,
                label: dataSubGroup.Name
            }));

            getAllDataSubGroups.forEach((item) => createDataSubGroupItems.push(item));

            setDataGroupItems(createDataSubGroupItems);
        }
    }, [dataSubGroups, dataGroupToken]);

    return (
        <GeopostDropdown fieldName={fieldName} label={label} items={dataGroupItems} loading={isLoading} required={required} disabled={disabled} />
    );
}

interface Props {
    fieldName: string;
    label: string;
    dataGroupToken: string;
    noneItem?: boolean;
    required?: boolean;
    disabled?: boolean;
}