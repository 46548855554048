import Cookies from 'universal-cookie';
import queryString from 'query-string';

export function getParamsFromUrl(){
    const cookies = new Cookies();
    const element = queryString.parse(window.location.search, {});
    const volumeToken = (element.volumeToken)?element.volumeToken as string:'';
    const filePath = (element.filePath)?element.filePath as string:'';

    const debug = (window.location.href.indexOf('debug=true') > -1);
    const forceDisableCache = (window.location.href.indexOf('forceDisableCache=true') > -1);

    const tenantToken = (element.tenantToken)?element.tenantToken as string:'';
    let jwtToken = '';

    try {
        const cookieName = 'auth_spa_' + tenantToken;
        let cookieValue = cookies.get(cookieName);
        jwtToken = cookieValue;
    } catch (error) {
        console.log('Error on getting cookie spa: geopost auth');
    }

    if (!jwtToken){
        jwtToken = (element.sessionJwt)?element.sessionJwt as string:'';
    }

    const params: IParamsUrl = {
        debug: debug,
        filePath: filePath,
        volumeToken: volumeToken,
        jwtToken: jwtToken,
        forceDisableCache: forceDisableCache
    };
    return params;
}
export interface IParamsUrl {
    debug: boolean;
    filePath: string;
    volumeToken: string;
    jwtToken: string;
    forceDisableCache: boolean;
}