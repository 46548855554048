import axios from 'axios';
import { useQuery } from 'react-query';

import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { Survey2DLines } from 'features/seismic/models/interfaces/I2DLines';
import { useSessionStore } from 'session/useSessionStore';
import { SeismicProduct } from 'features/seismic/models/interfaces/IProducts';

export function useGetAll2DLines(volumeToken: string | null, srid: number) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAll2DLines', async () => {
        const response = await axios.request<IResponseAPI<Survey2DLines[]>>({
            method: 'get',
            url: tenantConfig?.endpoints?.render /*'http://localhost:5000'*/ + '/Seismic/Lines/get2DLinesFromSurvey?volumeToken=' + volumeToken + '&srid=' + srid,
            headers: {
                'Content-Type': 'application/json',
                Authorization:`Bearer ${jwtToken}`
            }
        });

        return response.data.Result;
    },{
        enabled: (volumeToken !== ''),
        refetchOnWindowFocus: false,
    });
}

export function useGetAllProductsBySurvey(surveyId: number | undefined) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllProductsBySurvey-' + surveyId, async () => {
        const response = await axios.request<IResponseAPI<SeismicProduct>>({
            method: 'get',
            url: tenantConfig?.endpoints?.render + '/seismic/product/getProductsForSurvey?surveyId=' + surveyId,
            headers: {
                'Content-Type': 'application/json',
                Authorization:`Bearer ${jwtToken}`
            }
        });

        return response.data.Result;
    },{
        enabled: (surveyId !== undefined && surveyId !== 0),
        refetchOnWindowFocus: false,
    });
}