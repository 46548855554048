import { Extent } from 'ol/extent';

import { IRangeTrace, IScale } from 'features/seismic/stores/useSeismicStore';
import { ISeismicCalculatorLineType } from '../interfaces/ISeismicCalculatorLineType';
import { ISurveyMetadata } from '../interfaces/ISurveyMetadata';
import { SeismicCalculator } from './SeismicCalculator';

export class SeismicCalculatorZSlice extends SeismicCalculator{
    private inlineCalculator: ISeismicCalculatorLineType;
    private xlineCalculator: ISeismicCalculatorLineType;

    public calculateExtent = (surveyMetadata: ISurveyMetadata, scale: IScale, rangeTrace: IRangeTrace) => {
        const minX = this.xlineCalculator.getMinTrace(surveyMetadata);
        const maxX = minX + this.xlineCalculator.getTotalTraces(surveyMetadata) * scale.x / this.xlineCalculator.getLineIncrement(surveyMetadata);

        const maxY = 0;
        const minY = ( this.inlineCalculator.getTotalTraces(surveyMetadata) * scale.y / this.inlineCalculator.getLineIncrement(surveyMetadata) ) * -1;

        return [minX, minY, maxX, maxY];
    };

    constructor (inlineCalculator: ISeismicCalculatorLineType, xlineCalculator: ISeismicCalculatorLineType) {
        super();
        this.inlineCalculator = inlineCalculator;
        this.xlineCalculator = xlineCalculator;
    };

    calculateCenter(extent: Extent, surveyMetadata: ISurveyMetadata, scale: IScale, rangeTrace: IRangeTrace) {
        // const minTrace = this.calculatorLineType!.getMinTrace(surveyMetadata);
        const x = super.calculateCenterX(extent);

        const totalXLineTraces = this.xlineCalculator.getTotalTraces(surveyMetadata) / this.xlineCalculator.getLineIncrement(surveyMetadata);
        const y = this.calculateCenterYSlice(extent);

        return [x, y];
    }

    calculateCenterYSlice(extent: Extent) {
        return (extent[1] + extent[3]) / 2 ;
    }

    public calculateLineStart(surveyMetadata: ISurveyMetadata)  {
        const sampleInterval = this.calculateSampleInterval(surveyMetadata);
        const middleNumber = Math.ceil((surveyMetadata.Header.SamplesPerTrace - 1) * sampleInterval / 2);
        return middleNumber + (middleNumber % sampleInterval);
    }

    public calculateLineEnd(surveyMetadata: ISurveyMetadata) {
        const sampleInterval = this.calculateSampleInterval(surveyMetadata);
        return (surveyMetadata.Header.SamplesPerTrace - 1) * sampleInterval;
    }

    public calculateLineIncrement(surveyMetadata: ISurveyMetadata) {
        return 1;
    }
}