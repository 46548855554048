import { Fragment, useEffect } from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { WellViewerNavbar } from 'features/well-viewer/components/WellViewerNavbar';
import { WellViewerPanel } from 'features/well-viewer/components/WellViewerPanel';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';

export function WellViewer(){
    const {setJwtToken} = useSessionStore(state => ({
        setJwtToken: state.setJwtToken,
    }));
    const params = getParamsFromUrl();

    useEffect(() => {
        const params = getParamsFromUrl();
        console.log(params.jwtToken);
        document.title = 'Well Viewer';
        setJwtToken(params.jwtToken);

    },[]);
    return (
        <Fragment>
            <WellViewerNavbar/>
            <WellViewerPanel></WellViewerPanel>
        </Fragment>);
}