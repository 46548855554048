import { Box, Button, css, TextField, Tooltip, Zoom } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Fragment, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import alertify from 'alertifyjs';
import { MainTableCss } from 'fileIndexing/envVar/envVar';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';

import { eSubGroupEndpointType, IEndpoint } from '../interfaces/ITaxonomy';
import { useDeleteEndpoint, useGetEndpointByToken, useGetEndpointsBySubgroup } from '../useTaxonomy';
import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { EndpointsForm } from './EndpointsForm';

const style = css({
    width: '100% !important',
    margin: '0 !important',
    '& .table-header': {
        flexDirection: 'column',
        marginBottom: '16px',
        alignItems: 'flex-start !important',
        boxShadow: 'none !important',
        border: 'none !important',
        padding: '0px !important',
    },
    '& .search': {
        height: '40px',
        padding: '0px !important',
    },
});

export const EndpointsTable = ({ setOpenModal, subGroupName, subGroupToken }: Props) => {
    const [openEndpointModal, setOpenEndpointModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<IEndpoint | null>(null);

    const [editToken, setEditToken] = useState<string>('');
    const [deleteToken, setDeleteToken] = useState<string>('');

    const [filteredData, setFilteredData] = useState<IEndpoint[]>([]);
    const [filter, setFilter] = useState<string>('');

    const [pagination] = useState<{ pageSize: number, page: number; }>({
        pageSize: 10,
        page: 0,
    });

    const { data: endpointsData, isLoading: endpointsLoading, refetch, isRefetching } = useGetEndpointsBySubgroup(subGroupToken);
    const { data: endpointGetByToken } = useGetEndpointByToken(editToken);
    const { data: deleteEndpoint } = useDeleteEndpoint(deleteToken);

    const handleOpenModal = () => setOpenEndpointModal(true);
    const handleCloseModal = () => setOpenEndpointModal(false);

    const handleOpenEdit = (rowData: IEndpoint) => {
        setEditToken(rowData.EndpointToken);
    };

    const handleDelete = (name: string, token: string) => {
        alertify.confirm(`Do you really wanna delete ${name}?`, async () => {
            setDeleteToken(token);
        });
    };

    const refetchTableData = () => {
        refetch();
    };

    // reseta as informações preenchidas no modal
    useEffect(() => {
        if (!openEndpointModal) {
            setModalData(null);
        }
    }, [openEndpointModal]);

    // seta as informações para edit
    useEffect(() => {
        if (endpointGetByToken) {
            setModalData(endpointGetByToken);
            handleOpenModal();
            setEditToken('');
        }
    }, [endpointGetByToken]);

    // salva as informações de todas as linhas recebidas
    useEffect(() => {
        if (endpointsData) {
            setFilteredData(endpointsData);
        }
    }, [endpointsData]);

    // realiza o filtro
    useEffect(() => {
        if (endpointsData) {
            if (filter === '') {
                setFilteredData(endpointsData);
            }
            else {
                setFilteredData(endpointsData.filter(x => x.Url?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Label?.toString().startsWith(filter.toLowerCase()) ||
                    x.RestrictedExtensions?.toString().startsWith(filter.toLowerCase())));
            }
        }
    }, [filter]);

    // deleta o contract
    useEffect(() => {
        if (deleteEndpoint === 'Success') {
            refetch();
        }
    }, [deleteEndpoint]);

    // conteúdo da tabela
    const columns: GridColDef[] = [
        {
            field: 'Label',
            headerAlign: 'left',
            headerName: 'Label',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'RestrictedExtensions',
            headerAlign: 'left',
            headerName: 'Restricted Extensions',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'EndpointType',
            headerAlign: 'left',
            headerName: 'Endpoint Type',
            minWidth: 120,
            flex: 1,
            valueGetter: (params) => {
                switch (params.row.EndpointType) {
                case eSubGroupEndpointType.Viewing:
                    return 'Viewing';
                case eSubGroupEndpointType.Indexing:
                    return 'Indexing';
                default:
                    return '';
                }
            },
        },
        {
            field: 'Url',
            headerAlign: 'left',
            headerName: 'Url',
            minWidth: 120,
            flex: 2,
        },
        {
            field: 'Options',
            headerName: 'Options',
            headerAlign: 'center',
            sortable: false,
            minWidth: 180,
            flex: 1,
            renderCell: (params) => (
                <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Tooltip title='Edit rule' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' className='edit-icon' onClick={() => handleOpenEdit(params.row)}>
                            <EditNoteIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Delete rule' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='error' className='delete-icon' onClick={() => handleDelete(params.row.Label, params.row.EndpointToken)}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    const getRowId = (row: IEndpoint) => row.EndpointToken;

    return (
        <Fragment>
            <Box css={[style, MainTableCss]}>
                <Box className='table-header' style={{ flexDirection: 'column' }}>
                    <Box className='search'>
                        <TextField label='Search Endpoint' variant='outlined' size='small' color='primary' value={filter} onChange={(event) => setFilter(event.target.value)} />
                        <Button variant='contained' color='primary' className='add-icon' onClick={() => { handleOpenModal() }}>
                            <AddIcon />
                        </Button>
                    </Box>
                </Box>
                <GeopostTable<IEndpoint> filteredData={filteredData} loading={endpointsLoading || isRefetching} columns={columns} getRowId={getRowId} pagination={pagination} />
            </Box>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openEndpointModal}
                onChangeOpen={handleCloseModal}
                resizable={false}
                width={410}
                height={395}
                padding={'16px'}
                title={modalData ? `Edit Endpoint` : 'Add a new Endpoint'}
            >
                <EndpointsForm setOpenModal={setOpenEndpointModal} refetchTableData={refetchTableData} formData={modalData} subGroupToken={subGroupToken} />
            </GeopostPopup>
        </Fragment>
    );
};

interface Props {
    setOpenModal: (value: boolean) => void;
    subGroupName: string;
    subGroupToken: string;
}