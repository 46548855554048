import { useQuery } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { ISeismicViewer3DMap } from '../models/interfaces/ISeismicViewer3DMap';
import { ISeismicViewerDetailed3DGrid } from '../models/interfaces/iSeismicViewerDetailed3DGrid';
import { axios } from 'configurations/axios';

export function useGetGridsIntersectingInGeom(geomWkt: string | undefined, geomSrid: number, resultSrid: number) {
    let { apiUrl, jwtToken } = useSessionStore(state => ({
        apiUrl: state.tenantConfiguration?.endpoints.seismic3DGrid,
        jwtToken: state.jwtToken
    }));

    console.log(useSessionStore(state => state.tenantConfiguration?.endpoints));
    return useQuery<ISeismicViewer3DMap[]>(
        ['GetGrids', geomWkt],
        async () => {
            const response = await axios.post<IResponseAPI<ISeismicViewer3DMap[]>>(
                apiUrl + '/Seismic3DGrids/GetGridsIntersectingInGeom',
                {
                    geomWkt: geomWkt,
                    geomSrid: geomSrid,
                    resultSrid: resultSrid
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data.Result;
        },
        {
            enabled: !!geomWkt
        }
    );
}

export const useGetGridByToken = (token: string | undefined, desiredSrid: number) => {
    let { apiHost, jwt } = useSessionStore(state => ({
        apiHost: state.tenantConfiguration?.endpoints.seismic3DGrid,
        jwt: state.jwtToken
    }));

    return useQuery<ISeismicViewerDetailed3DGrid>(
        ['GetGrids', token],
        async () => {
            const response = await axios.get<IResponseAPI<ISeismicViewerDetailed3DGrid>>(
                apiHost + '/Seismic3DGrids/GetGridByToken',
                {
                    params: {
                        token: token,
                        desiredSrid: desiredSrid
                    },
                    headers: {
                        Authorization: 'Bearer ' + jwt,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data.Result;
        },
        {
            enabled: !!token
        }
    );
} ;