import { Feature } from 'ol';
import * as THREE from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import WKT from 'ol/format/WKT';
import { BoxGeometry, BufferAttribute, BufferGeometry, Color, DoubleSide, GreaterDepth, GreaterEqualDepth, GreaterEqualStencilFunc, Group, LessEqualDepth, Line, LineBasicMaterial, Material, Mesh, MeshBasicMaterial, MeshLambertMaterial, MeshPhongMaterial, Object3D, PlaneGeometry, Scene, SRGBColorSpace, TextureLoader, Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { Point } from 'ol/geom';

import { IntersectingLayer } from 'features/seismic-3d/models/types/IntersectingLayer';
import { buildMainMeshByGeomWKT, buildMergedMesh, buildMeshByGeomWKT, getOlGeometryFromWKT } from '../../components/projections/featureGeomPolygon';
import { FeatureGeom } from '../../models/classes/FeatureGeom';
import { MeshData } from '../../models/types/MeshData';
import { GridLine } from 'features/seismic-3d/models/types/GridLine';
import { LineStringResponse } from 'features/seismic-3d/models/types/LineStringResponse';
import { GeopostMap } from 'features/seismic/models/classes/GeopostMap';
import { GeopostMesh } from '../mesh/GeopostMesh';
import { LineType } from 'features/seismic/models/enums/LineType';
import { lineMetadata } from 'features/seismic-3d/models/types/LineMetadata';
import { GeopostTileMesh } from '../mesh/GeopostTileMesh';
import { GeopostLineMesh } from '../mesh/GeopostLineMesh';
import { sortLineNumbersFromTheMiddle } from 'features/seismic-3d/utils/TileUtils';

export const lineRenderOrder = 2;
export const inlineTilesRenderOrder = 1;
export const xlineTilesRenderOrder = 0;

export abstract class GeopostScene extends THREE.Scene {
    renderer : THREE.WebGLRenderer | null = null;
    labelsRenderer : CSS2DRenderer | null = null;
    camera : THREE.PerspectiveCamera | null = null;
    controls: OrbitControls | null = null;
    grid: THREE.Group | null = null;
    light: THREE.Light | null = null;
    raycaster: THREE.Raycaster | null = null;
    intersectionEvents: ((raycaster: THREE.Raycaster) => void)[] = [];
    pointer: THREE.Vector2 = new THREE.Vector2();
    height: number = 0;
    width: number = 0;
    mainMesh: THREE.Object3D | null = null;
    outerScene: THREE.Scene = new THREE.Scene();
    outerSceneCamera: THREE.PerspectiveCamera | null = null;
    mainFeatureCentroid: [number, number] = [0, 0];
    initialCameraPosition: THREE.Vector3 = new THREE.Vector3();

    public setInitialCameraPosition = () => {
        this.initialCameraPosition.y = 5.458748370408388;
        this.initialCameraPosition.x = -0.5610421592451198;
        this.initialCameraPosition.z = 20.832339717114873;
    };

    public setRenderer = (canvas : HTMLElement, canvasWidth: number, canvasHeight: number) => {
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(2 * canvasWidth, 2 * canvasHeight);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setClearColor(0x000000);
        canvas.addEventListener('pointermove', this.updatePointerCoordinates);
        canvas.appendChild(this.renderer.domElement);
        this.height = canvasHeight;
        this.width = canvasWidth;
        //canvas.addEventListener('resize', () => this.onCanvasResize(canvas));
    };
    public updatePointerCoordinates = (event: MouseEvent) => {
        this.pointer.x = ( event.pageX / this.width ) * 2 - 1;
        this.pointer.y = - ( event.pageY / this.height ) * 2 + 1;
    };

    public setLabelsRenderer(canvas: HTMLElement, width: number, height: number) {
        this.labelsRenderer = new CSS2DRenderer();
        this.labelsRenderer.setSize(width, height);
        this.labelsRenderer.domElement.style.position = 'absolute';
        this.labelsRenderer.domElement.style.top = '0px';
        canvas.appendChild(this.labelsRenderer.domElement);
    }

    public updateDimensions(width: number, height: number) {
        if (this.camera && this.renderer) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();

            this.renderer.setSize( width, height );
        }
        if (this.labelsRenderer) {
            this.labelsRenderer.setSize(width, height);
        }
    }

    public setCamera(canvasWidth: number, canvasHeight: number){
        this.camera = new THREE.PerspectiveCamera(75, canvasWidth / canvasHeight, 0.05, 500);
        //this.camera.rotateY(6);
        this.resetCamera();
        //this.camera.zoom = 50;
    }

    public setRaycaster() {
        this.raycaster = new THREE.Raycaster();
        //this.raycaster.params.Line!.threshold = 0.01;
        this.raycaster.params.Points!.threshold = 0.0075;
    }

    public setControls(){
        if (this.camera && this.renderer) {
            this.controls = new OrbitControls(this.camera, this.labelsRenderer!.domElement);
            //this.controls.target = new THREE.Vector3(0, 0, 0);
        }
    }

    public setLight() {
        const ambientLight =  new THREE.AmbientLight(0x404040, 33);
        this.light = ambientLight;
        super.add(this.light);
    }

    public setBloomLayer(){
        //this.bloomLayer = new THREE.Layers();
        //this.bloomLayer.set(this.bloomLayerNumber);

        const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
        bloomPass.threshold = 0;
        bloomPass.strength = 1;
        bloomPass.radius = 0.5;

        const renderPass = new RenderPass(this, this.camera!);

        const bloomComposer = new EffectComposer(this.renderer!);
        bloomComposer.renderToScreen = false;

        bloomComposer.addPass(renderPass);
        bloomComposer.addPass(bloomPass);
    }

    public setupAndRun(canvas: HTMLElement, canvasWidth: number, canvasHeight: number){
        this.setRenderer(canvas, canvasWidth, canvasHeight);
        this.setLabelsRenderer(canvas, canvasWidth, canvasHeight);
        this.setInitialCameraPosition();
        this.setCamera(canvasWidth, canvasHeight);
        this.setLight();
        this.setControls();
        this.setRaycaster();
        //window.addEventListener('resize', this.onWindowResize);
        this.animate();
    }

    public resetCamera = () => {
        this.camera?.position.set(this.initialCameraPosition.x, this.initialCameraPosition.y, this.initialCameraPosition.z);
        this.controls?.target.set(0, 0, 0);
    };

    public abstract addGridSection(linesData: lineMetadata[], volumeToken: string) : void;

    public changeGridOpacity = (opacity: number) => {
        const mainMesh = this.getObjectByName('main-mesh');
        if (mainMesh) {
            ((mainMesh as Mesh).material as Material).opacity = opacity;
        }
        this.grid?.traverse(node => {
            if (node.name === 'line-mesh') {
                ((node as Mesh).material as Material).opacity = opacity;
            }
        });
    };

    protected getTileObjectName = (tileXIndex : number, lineNumber: number, lineType: LineType, tileYIndex: number) => {
        return `line-${lineNumber}-${lineType}-tile-${tileXIndex}-sample-${tileYIndex}`;
    };

    protected getLineGroupName = (lineNumber: number, lineType: LineType) => {
        return `line-${lineType}-group-${lineNumber}`;
    };

    public changeTiledDimensionVisibility = (lineNumber : number, lineType: LineType, visible: boolean) => {
        const group = super.getObjectByName(this.getTileGroupName(lineNumber, lineType));
        if (!!group) {
            group.visible = visible;
        }
    };

    protected addTileGroup = (lineNumber: number, lineType: LineType, totalOfTiles: number, lineFirstCoordinate: [number, number], lineLastCoordinate: [number, number], tileHeight: number) => {
        const lineGroup = this.getObjectByName(this.getLineGroupName(lineNumber, lineType));
        const tileGroup = new Group();
        tileGroup.name = this.getTileGroupName(lineNumber, lineType);
        tileGroup.visible = false;
        //AQUI CALCULA
        for (let tileXIndex = 0; tileXIndex < totalOfTiles; tileXIndex++) {
            const tileObject = new GeopostTileMesh(lineFirstCoordinate, lineLastCoordinate, totalOfTiles, tileXIndex, tileHeight, 0);
            tileObject.name = this.getTileObjectName(tileXIndex, lineNumber, lineType, 0);
            tileObject.geometry.computeBoundingBox();
            tileObject.userData.isFilled = false;
            //lineType === LineType.Inline ? inlineTilesRenderOrder : xlineTilesRenderOrder;

            tileGroup.add(tileObject);
        }
        if (lineGroup) {
            lineGroup.add(tileGroup);
        }
    };

    protected addTileGroup2 = (lineNumber: number, lineType: LineType, totalOfTiles: number, lineFirstCoordinate: [number, number], lineLastCoordinate: [number, number], tileHeight: number) => {
        const lineGroup = this.getObjectByName(this.getLineGroupName(lineNumber, lineType));
        const tileGroup = new Group();
        tileGroup.name = this.getTileGroupName(lineNumber, lineType);
        tileGroup.visible = false;
    };

    public abstract addLineTiles(line: lineMetadata, samplesPerTrace: number, tileWidth: number) : void;

    public changeHeightScale = (scale: number) => {
        this.scale.setY(scale);
    };
    public addLineTexture = (lineNumber : number, lineType: LineType, tileDataUrl: string) => {
        const tileMesh = super.getObjectByName(this.getTileGroupName(lineNumber, lineType)) as Mesh;
        const imageTexture = new TextureLoader().load(tileDataUrl);
        imageTexture.minFilter = THREE.LinearMipMapLinearFilter;
        imageTexture.anisotropy = 2;
        imageTexture.colorSpace = SRGBColorSpace;
        tileMesh.material = new MeshPhongMaterial({map: imageTexture, side: DoubleSide, transparent: false});
        tileMesh.userData.isFilled = true;
    };

    public addTileTexture2 = (tileXIndex : number, lineNumber : number, lineType: LineType, tileSrc: string) => {
        const object = super.getObjectByName(this.getTileObjectName(tileXIndex, lineNumber, lineType, 0));
        const anisotropy = (this.renderer?.capabilities.getMaxAnisotropy() ?? 2) / 2;
        if (object instanceof Mesh) {
            const imageTexture = new TextureLoader().load(tileSrc);
            imageTexture.colorSpace = SRGBColorSpace;
            imageTexture.minFilter = THREE.LinearMipMapLinearFilter;
            imageTexture.anisotropy = 2;
            //imageTexture.anisotropy = anisotropy;
            object.material = new THREE.MeshPhongMaterial({map: imageTexture, side: DoubleSide, transparent: false});
            object.userData.isFilled = true;
        };
    };

    public addTileTexture = (tileXIndex : number, tileSampleStart: number, lineNumber : number, lineType: LineType, tileImage: Blob) => {
        if (lineNumber === 12154) {
            debugger;
        }
        const object = super.getObjectByName(this.getTileObjectName(tileXIndex, lineNumber, lineType, tileSampleStart));
        const anisotropy = (this.renderer?.capabilities.getMaxAnisotropy() ?? 2) / 2;
        if (object instanceof Mesh) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(tileImage);
            fileReader.onload = () => {
                if (fileReader.result){
                    const imageTexture = new TextureLoader().load(fileReader.result.toString());
                    imageTexture.colorSpace = SRGBColorSpace;
                    imageTexture.minFilter = THREE.LinearMipMapLinearFilter;
                    //imageTexture.anisotropy = 2;
                    //imageTexture.anisotropy = anisotropy;
                    object.material = new THREE.MeshPhongMaterial({map: imageTexture, side: DoubleSide, transparent: false});
                    object.userData.isFilled = true;
                }
            };
        };
    };

    public markLineAsLoading = (lineNumber: number, lineType: LineType) => {
        const lineGroup = this.getObjectByName(this.getLineGroupName(lineNumber, lineType));
        const lineMesh = lineGroup?.getObjectByName('line-mesh');
        if (lineMesh && lineMesh instanceof Line && lineMesh.material instanceof LineBasicMaterial) {
            lineMesh.material.color = new Color(0xffff66);
        }
    };

    public markLineAsLoaded = (lineNumber: number, lineType: LineType) => {
        const lineGroup = this.getObjectByName(this.getLineGroupName(lineNumber, lineType));
        const lineMesh = lineGroup?.getObjectByName('line-mesh');
        if (lineMesh && lineMesh instanceof Line && lineMesh.material instanceof LineBasicMaterial) {
            lineMesh.material.color = new Color(0x66ff66);
        }
    };

    public addGrid = (volumeToken : string) => {
        if (this.grid) {
            this.removeGrid();
        }
        const grid = new THREE.Group();
        grid.name = `grid-${volumeToken}`;
        super.add(grid);
        this.grid = grid;
        return grid;
    };

    public removeGrid = () => {
        if (this.grid) {
            super.remove(this.grid);
            this.grid = null;
        }
    };

    public abstract addMainMesh ({geomWKT, centroidWKT, meshColor, meshToken} : MeshData) : THREE.Line;

    public addMesh = async ({geomWKT, centroidWKT, meshColor, meshToken} : MeshData) => {
        const existingMesh = super.getObjectByName(meshToken);
        if (!existingMesh) {
            const mesh = buildMeshByGeomWKT(geomWKT, centroidWKT, meshColor);
            mesh.name = meshToken;
            super.add(mesh);
        }
    };

    public removeMesh = async (meshToken : string) => {
        const mesh = super.getObjectByName(meshToken);
        if (mesh){
            super.remove(mesh);
        }
    };

    public meshExistsOnScene = (meshToken: string) => {
        return Boolean(super.getObjectByName(meshToken));
    };

    protected animate = () => {
        requestAnimationFrame(this.animate.bind(this));
        if (this.controls){
            this.controls.update();
        }
        if (this.renderer && this.camera){
            //this.renderer.clear();
            this.renderer.render(this, this.camera);
            //this.renderer.clearDepth();
            //this.renderer.render(this.outerScene, this.camera);
            this.labelsRenderer!.render(this, this.camera);
            //console.log(this.camera.position);
        }
        if (!!this.raycaster && !!this.camera) {
            this.raycaster.setFromCamera(this.pointer, this.camera);
            this.intersectionEvents.forEach(intersectionEvent => intersectionEvent(this.raycaster!));
        }
    };

    public setLayerMeshHeight = (layerId: number, height: number) => {
        const layerMesh = this.getObjectByName(`layer-${layerId}`);
        if (layerMesh) {
            layerMesh.position.y = height;
        }
    };

    public addLayerMesh = (layerId : number, geomData: FeatureGeom) => {
        const meshData : MeshData = {
            meshToken:`layer-${layerId}`,
            meshColor: '#ffffff',
            geomWKT: geomData.GeomWKT,
            centroidWKT: geomData.CentroidWKT
        };
        this.addMesh(meshData);
    };

    public removeLayerMesh = async (layerId: number) => {
        this.removeMesh(`layer-${layerId}`);
    };

    public addSeismicMesh = async (volumeToken: string, geomData: FeatureGeom) => {
        const meshData : MeshData = {
            meshToken:`seismic-${volumeToken}`,
            meshColor: '#ffffff',
            geomWKT: geomData.GeomWKT,
            centroidWKT: geomData.CentroidWKT
        };
        this.addMesh(meshData);
    };

    public removeSeismicMesh = async (volumeToken: string) => {
        this.removeMesh(`seismic-${volumeToken}`);
    };

    public isTileFilled = (lineNumber: number, lineType: LineType, tileXIndex: number, tileYIndex: number = 0) => {
        const tileName = this.getTileObjectName(tileXIndex, lineNumber, lineType, tileYIndex);
        const tileObject = this.getObjectByName(tileName);
        if (!tileObject) {
            return false;
        }
        return tileObject.userData.isFilled;
    };

    protected getTileGroupName = (lineNumber: number, lineType: LineType) => {
        return `line-${lineType}-${lineNumber}-tile-group`;
    };

    public addToOuterScene = (...objects: THREE.Object3D[]) => {
        this.outerScene.add(...objects);
    };

    public setLightIntensity = (intensityPercentage: number) => {
        if (this.light) {
            this.light.intensity = 50 * intensityPercentage / 100;
        }
    };

    public abstract addZSliceTilesByDepth(totalInlines: number, totalXlines: number, inlineStart: number, xlineStart: number, depth: number, sampleInterval: number,
        tileWidth: number, tileHeight: number) : void;
}