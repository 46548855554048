import { Fragment, useState } from 'react';
import { Alert, AlertTitle, Button, css, FormLabel, InputBase, InputLabel, NativeSelect, styled, TextField } from '@mui/material';
import queryString from 'query-string';

import { SurveyType } from 'features/seismic/models/enums/SurveyType';
// colocar textos no i18n

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(1),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));
const popupDiv = css({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    margin:6
});
const popupBtnDiv = css({
    margin:6
});

interface Props{
    openPopup(open:boolean):void;
    onAfterSave():void;
}

export function StartIndexPopup(props: Props){
    const element = queryString.parse(window.location.search, {});
    const apiKey = (element.apiKey)?element.apiKey as string:'';

    const [volumeType, setvolumeType] = useState(1);
    const [path,setPath] = useState('');
    const [srid,setSrid] = useState(0);
    const [inlineByte,setInlineByte] = useState(213);
    const [xlineByte,setXlineByte] = useState(217);
    const [coordinateX,setCoordinateX] = useState(201);
    const [coordinateY,setCoordinateY] = useState(205);
    const [coordinateScalar,setCoordinateScalar] = useState(1);
    const [error,setError] = useState(false);
    // const mutation = useMutation((startIndexDTO:StartIndexDTO) => {
    //     //return useSaveSeismicVolume(startIndexDTO);
    //     console.log('error');
    // });
    const SaveVolume = ()=>{
        if (path && srid !== 0){
            // mutation.mutate({
            //     basePath:path,
            //     srid:srid,
            //     volumeType:volumeType,
            //     inlineByte:inlineByte,
            //     xlineByte:xlineByte,
            //     coordinateX:coordinateX,
            //     coordinateY:coordinateY,
            //     coordinateScalar:coordinateScalar,
            //     apiKey:apiKey});
            props.onAfterSave();
            props.openPopup(false);
        } else {
            console.log('error');
            setError(true);
        }
    };
    // if (mutation.isSuccess){
    //     console.log('success');
    // }
    // if (mutation.error) {
    //     console.log('error');
    // }
    // if (mutation.isLoading){
    //     console.log('loading');
    // }
    // console.log(mutation.isLoading);
    const resetPopup = ()=>{
        setvolumeType(0);
        setPath('');
        setSrid(0);
    };

    return (
        <div>
            {error &&<Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <strong>Invalid Values SRID must be greater than 0 and path must not be blank </strong>
            </Alert>}
            <div css={popupDiv}>

                <TextField style={{marginBottom:6}} value={path} onChange={(pathField)=>setPath(pathField.target.value.trim())} placeholder='ex.: gs://bucket_name/file_to_index.sgy' label='Full file path'></TextField>
                <TextField inputProps={{ inputMode: 'numeric'}} style={{marginBottom:6}} value={srid} onChange={(sridField)=>setSrid(Number(sridField.target.value))} label='SRID (only numbers)' placeholder='ex.: 31983'></TextField>
                <InputLabel htmlFor="select">Volume Type</InputLabel>
                <NativeSelect id="select" value={volumeType} onChange={(volumeTypeField)=>setvolumeType(Number(volumeTypeField.target.value))} input={<BootstrapInput />}>
                    <option value={SurveyType.Seismic2D}>2D</option>
                    <option value={SurveyType.Seismic3D}>3D</option>
                </NativeSelect>

                {volumeType === SurveyType.Seismic3D ?
                    <Fragment>
                        <TextField  style={{marginBottom:6}} value={inlineByte} onChange={(evt)=>setInlineByte(parseInt(evt.target.value.trim()))} label='Inline Byte'></TextField>
                        <TextField style={{marginBottom:6}} value={xlineByte} onChange={(evt)=>setXlineByte(parseInt(evt.target.value.trim()))} label='Xline Byte'></TextField>
                    </Fragment>
                    : null
                }

                <TextField style={{marginBottom:6}} value={coordinateX} onChange={(evt)=>setCoordinateX(parseInt(evt.target.value.trim()))} label='X Coordinate Byte'></TextField>
                <TextField style={{marginBottom:6}} value={coordinateY} onChange={(evt)=>setCoordinateY(parseInt(evt.target.value.trim()))} label='Y Coordinate Byte'></TextField>

                <FormLabel>Coordinate Scalar</FormLabel>
                <NativeSelect id="select" value={coordinateScalar} onChange={(evt)=>setCoordinateScalar(parseInt(evt.target.value))} input={<BootstrapInput />}>
                    <option value={1}>1</option>
                    <option value={10}>10</option>
                    <option value={100}>100</option>
                    <option value={1000}>1000</option>
                </NativeSelect>
            </div>
            <div css={popupBtnDiv}>
                <Button variant='contained' onClick={() => SaveVolume()}>Save</Button>
            </div>
        </div>   );

}