import { Box, Button, IconButton, Tooltip, Typography, Zoom } from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { GeopostLoading } from 'fileIndexing/otherComponents/GeopostLoading';
import { Fragment, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import alertify from 'alertifyjs';

import { IDataGroup } from '../interfaces/ITaxonomy';
import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { GroupsForm } from './GroupsForm';
import { useDeleteDataGroup, useGetOneDataGroup } from '../useTaxonomy';

export function GroupsTreeView({ allDataGroups, allDataGroupsLoading, refetch, setDataGroupSelected }: Props) {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<IDataGroup | null>(null);

    const [editToken, setEditToken] = useState<string>('');
    const [deleteToken, setDeleteToken] = useState<string>('');

    const [parentGroupItems, setParentGroupItems] = useState<boolean>(false);

    const [expandedItems, setExpandedItems] = useState<string[]>(['group']);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const { data: dataGroupByToken } = useGetOneDataGroup(editToken);
    const { data: deleteGroupData } = useDeleteDataGroup(deleteToken);

    const findGroup = (groups: IDataGroup[], token: string): IDataGroup | undefined => {
        for (let group of groups) {
            if (group.Token === token) {
                return group;
            }
            if (group.Children) {
                const found = findGroup(group.Children, token);
                if (found) {
                    return found;
                }
            }
        }
        return undefined;
    };

    const handleAddNewGroup = (groupName: string, groupToken: string) => {
        handleOpenModal();

        if (groupName !== 'Group' && allDataGroups) {
            let data = findGroup(allDataGroups, groupToken);
            if (data) {
                let newData: IDataGroup = {
                    Children: [],
                    DataSubGroupsTokens: [],
                    Label: '',
                    Name: '',
                    ParentGroupName: '',
                    ParentGroupToken: data.Token,
                    Token: ''
                };

                setModalData(newData);
            }
        }
    };

    const handleOpenEdit = (groupToken: string) => {
        setEditToken(groupToken);
    };

    const handleDelete = (name: string, token: string) => {
        alertify.confirm(`Do you really wanna delete ${name}?`, async () => {
            setDeleteToken(token);
        });
    };

    const renderTree = (nodes: IDataGroup[]) => {
        return nodes.map((node) => {
            return (
                <TreeItem key={node.Token} itemId={node.Token} label={groupActions(node.Label, node.Token)} onClick={() => setDataGroupSelected(node.Token)}>
                    {node.Children.length > 0 && renderTree(node.Children)}
                </TreeItem>
            );
        });
    };

    const handleExpandedItemsChange = (
        event: React.SyntheticEvent,
        itemIds: string[],
    ) => {
        setExpandedItems(itemIds);
    };

    const groupActions = (groupName: string, groupToken: string) => {
        return (
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>{groupName}</Typography>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={event => event.stopPropagation()}>
                    <Tooltip title='Create a new group' placement='top' arrow TransitionComponent={Zoom}>
                        <IconButton style={{ width: '15px', height: '15px', marginLeft: '5px' }} color='primary' onClick={() => { handleAddNewGroup(groupName, groupToken) }}>
                            <AddIcon style={{ width: '15px', height: '15px' }} />
                        </IconButton>
                    </Tooltip>
                    {groupName !== 'Group' &&
                        <Fragment>
                            <Tooltip title='Edit group' placement='top' arrow TransitionComponent={Zoom}>
                                <IconButton style={{ width: '15px', height: '15px', marginLeft: '5px' }} color='primary' onClick={() => handleOpenEdit(groupToken)}>
                                    <EditNoteIcon style={{ width: '15px', height: '15px' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Delete subgroup' placement='top' arrow TransitionComponent={Zoom}>
                                <IconButton style={{ width: '15px', height: '15px', marginLeft: '5px' }} color='error' onClick={() => handleDelete(groupName, groupToken)}>
                                    <DeleteIcon style={{ width: '15px', height: '15px' }} />
                                </IconButton>
                            </Tooltip>
                        </Fragment>
                    }
                </Box>
            </Box>
        );
    };

    // reseta as informações preenchidas no modal
    useEffect(() => {
        if (!openModal) {
            setModalData(null);
        }
    }, [openModal]);

    // seta as informações para edit
    useEffect(() => {
        if (dataGroupByToken) {
            setModalData(dataGroupByToken);
            handleOpenModal();
            setEditToken('');
        }
    }, [dataGroupByToken]);

    // delete o group
    useEffect(() => {
        if (deleteGroupData === 'Success') {
            refetch();
        }
    }, [deleteGroupData]);

    return (
        <Fragment>
            <SimpleTreeView expandedItems={expandedItems} onExpandedItemsChange={handleExpandedItemsChange}>
                <GeopostLoading loading={allDataGroupsLoading} />
                <TreeItem itemId='group' label={groupActions('Group', '')}>
                    {allDataGroups && renderTree(allDataGroups)}
                </TreeItem>
            </SimpleTreeView>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openModal}
                onChangeOpen={handleCloseModal}
                resizable={false}
                width={300}
                height={260}
                title={modalData ? `Edit ${modalData.Name}` : 'Add a new Group'}
                padding={'16px'}
            >
                <GroupsForm refetchTableData={refetch} setOpenModal={setOpenModal} formData={modalData} allDataGroups={allDataGroups} />
            </GeopostPopup>
        </Fragment>
    );
}

interface Props {
    allDataGroups: IDataGroup[] | undefined;
    allDataGroupsLoading: boolean;
    refetch: () => void;
    setDataGroupSelected: (value: string) => void;
}