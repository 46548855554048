import { Fragment, useEffect, useRef } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAPI } from 'hooks/useAPI';
import { FetchType } from 'models/enums/FetchType';
import { ApiType } from 'models/enums/ApiType';
import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { IInfoIconMenu } from 'features/seismic/models/interfaces/IInfoIconMenu';
import { IWell } from 'features/seismic/models/interfaces/IWell';

export function InfoIconMenu ({ menuOpen, data, domain, datum }: Props) {
    const { t } = useTranslation();

    const {isLoading, execute: executeInfo} = useAPI<IInfoIconMenu>('/Wells/SeismicWells/GetInfoWell');
    const alreadyLoaded = useRef<boolean>(false);
    const rotativeDesk = useRef<number>(0);
    const timeDepthCreator = useRef<string>('');
    const timeDepthName = useRef<string>('');

    useEffect(() => {
        if (menuOpen && !alreadyLoaded.current) {
            alreadyLoaded.current = true;
            const fetch = async () => {
                const dataInfo = await executeInfo({
                    type: FetchType.Post,
                    apiType: ApiType.WebApi,
                    body: {
                        wellId: data.Id,
                        domain: domain,
                    }
                });

                if (dataInfo) {
                    rotativeDesk.current = Math.floor(dataInfo.RotativeDeskTime);
                    timeDepthCreator.current = dataInfo.TimeDepthCreator;
                    timeDepthName.current = dataInfo.TimeDepthName;
                }
            };
            fetch();
        }
    }, [menuOpen]);

    const circular = () => {
        return <CircularProgress style={{ color: 'white', width: '10px', height: '10px'}} />;
    };

    return (
        <Fragment>
            <Typography>-{t('rotativeDesk')}: {data.MesaRotativa}m {isLoading ? circular() : (rotativeDesk.current !== 0 ? `(${rotativeDesk.current}ms)` : '')}</Typography>
            <Typography>-Datum: {datum}</Typography>
            <Typography>-MD: {Math.floor(data.Distance)}m ({Math.floor(data.Distance) - data.MesaRotativa}m)</Typography>
            <Typography>-TVD: {data.ProfundidadeVertical}m ({data.ProfundidadeVertical - data.MesaRotativa}m)</Typography>
            <Typography>-{t('ratio')} TVD/MD: {(data.ProfundidadeVertical/Math.floor(data.Distance)).toFixed(3)}m</Typography>
            <Typography>-{t('timeDepth')}: {isLoading ? circular() : (timeDepthName.current !== null ? (timeDepthName.current !== '' ? `${timeDepthName.current} (${timeDepthCreator.current})` : '-') : 'Not Exists')}</Typography>
        </Fragment>
    );
}

interface Props {
    menuOpen: boolean;
    data: IWell;
    domain: AmplitudeDomain;
    datum: number;
}