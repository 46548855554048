import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { usePerformanceMonitoringStore } from 'features/seismic/stores/usePerformanceMonitoringStore';

export const SurveyShapePerformancePanel = () => {

    const surveyGeometriesPerformanceData = usePerformanceMonitoringStore(state => state.surveyGeometriesPerformanceData);

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Volume token
                        </TableCell>
                        <TableCell>
                            Total time lapsed (sec.)
                        </TableCell>
                        <TableCell>
                            Api query time (sec.)
                        </TableCell>
                        <TableCell>
                            Geom processing time (sec.)
                        </TableCell>
                        <TableCell>
                            Total server time
                        </TableCell>
                        <TableCell>
                            Request network time (sec.)
                        </TableCell>
                        <TableCell>
                            Response network time (sec.)
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        surveyGeometriesPerformanceData.map(surveyGeometryPerformanceData => (
                            <TableRow>
                                <TableCell>
                                    { surveyGeometryPerformanceData.volumeToken }
                                </TableCell>
                                <TableCell>
                                    { surveyGeometryPerformanceData.totalTime }
                                </TableCell>
                                <TableCell>
                                    { surveyGeometryPerformanceData.apiQueryTime }
                                </TableCell>
                                <TableCell>
                                    { surveyGeometryPerformanceData.geomProcessingTime }
                                </TableCell>
                                <TableCell>
                                    { surveyGeometryPerformanceData.serverProcessingTime }
                                </TableCell>
                                <TableCell>
                                    { surveyGeometryPerformanceData.requestNetworkTime }
                                </TableCell>
                                <TableCell>
                                    { surveyGeometryPerformanceData.responseNetworkTime }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Box>
    );
};