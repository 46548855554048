import { MutableRefObject, useEffect } from 'react';
import Map from 'ol/Map';
import { MousePosition } from 'ol/control';
import { CoordinateFormat } from 'ol/coordinate';

import { useSessionStore } from 'session/useSessionStore';
import { IWellGenericCut } from 'features/cross-section/models/interfaces/IWellGenericCut';
import { buildExtent, buildMousePosition, buildStaticSource } from '../../../utils/wellLaneMapUtils';
import { useCrossSectionStore } from 'features/cross-section/stores/useCrossSectionStore';
import { IDictionaryWellLayers } from 'features/cross-section/models/interfaces/IDictionaryWellLayers';
import { ICrossSectionItem } from 'features/cross-section/models/interfaces/ICrossSectionItem';
import { IMneumonic } from 'features/cross-section/models/interfaces/INeumonic';

export function useUpdatedMap(
    map: Map | null,
    selectedGenericCut: IWellGenericCut | null,
    dictionaryWellLayers: IDictionaryWellLayers,
    mousePosition: MutableRefObject<MousePosition | null>,
    crossSectionItem: ICrossSectionItem | undefined,
    coordinateFormat: CoordinateFormat,
    isLayerLoadingCallback: (value: boolean) => void){

    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));

    const { view, factorVertical, laneWidth, displacementVertical } = useCrossSectionStore(state => ({
        displacementVertical: state.displacementVertical,
        view: state.view,
        laneWidth: state.laneWidth,
        factorVertical: state.factorVertical,
    }));

    useEffect(() => {
        if (!selectedGenericCut){
            return;
        }

        const extent = buildExtent(laneWidth, selectedGenericCut, factorVertical);

        if (crossSectionItem?.WellId && displacementVertical[crossSectionItem.WellId]){
            extent[1] -= displacementVertical[crossSectionItem.WellId];
            extent[3] -= displacementVertical[crossSectionItem.WellId];
        }
        let url = '';
        if (selectedGenericCut.GenericCutType === 'log'){
            let n = crossSectionItem as unknown as IMneumonic;
            let frame = selectedGenericCut.Frame;
            if (frame) {
                if (frame.indexOf('\[') === -1){
                    if (frame.indexOf('[') === -1) {
                        frame = frame.replace('[','\[');
                    }
                }

                if (frame.indexOf('\]') === -1) {
                    if (frame.indexOf(']') === -1) {
                        frame = frame.replace(']','\]');
                    }
                }
            }

            url = `${tenantConfig?.endpoints.render}/well/PlotCurvesByNeumonic?neumonic=${selectedGenericCut.GenericCutName}&logical_file=${selectedGenericCut.LogicalFile}&frame=${frame}&color=red&width=${extent[2] - extent[0] - 25}&height=${(selectedGenericCut.Height - 75) / factorVertical }&start_depth=${selectedGenericCut.StartDepth}&end_depth=${selectedGenericCut.EndDepth}`;
        }
        const source = buildStaticSource(selectedGenericCut, extent, isLayerLoadingCallback,url);

        if (dictionaryWellLayers[selectedGenericCut.GenericCutPathToken]){
            dictionaryWellLayers[selectedGenericCut.GenericCutPathToken].setSource(source);
        }

        if (map){
            const mousePositionControl = map.getControls().getArray().filter(x=> x instanceof MousePosition);

            for (let mousePosition of mousePositionControl){
                map.removeControl(mousePosition);
            }
        }
        if (selectedGenericCut.GenericCutType !== 'log'){
            mousePosition.current = buildMousePosition(crossSectionItem?.Token, coordinateFormat);
            map?.addControl(mousePosition.current);
        }

    }, [factorVertical, view, displacementVertical]);
}