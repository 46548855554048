import { format, isValid } from 'date-fns';

export function getDateLabel(date: string){
    const parseredDate = new Date(date);

    /*if (date.indexOf('0001') > -1) {
        return '-';
    }*/

    //if (parseredDate.ge)
    return format(parseredDate, 'MM-dd-yyyy HH:mm');
}

export function getTraceValue(x: number, scaleX: number, min: number){
    //return (min + Math.round((x - min) / scaleX)).toString();
    return (min + Math.round((x - min) / scaleX));
}

