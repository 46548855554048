import { Box, Button, Checkbox, css, FormControl, FormControlLabel, Grid, keyframes, Typography } from '@mui/material';
import { SignalR } from 'fileIndexing/signalR/signalR';
import { Fragment, useEffect, useState } from 'react';
import { GeopostLoading } from 'fileIndexing/otherComponents/GeopostLoading';
import alertify from 'alertifyjs';

import { ISendFilter } from '../interfaces/IFiles';
import { useGetIndexedFiles, useReIndexFiles } from '../useFiles';
import { useSessionStore } from 'session/useSessionStore';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const style = css({
    width: '100%',
    '& .index-box': {
        border: '1px solid #00000020',
        marginBottom: '16px',
    },
    '& .index-box-group-subgroup, .index-box-group-entity, .index-box-total': {
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    '& .index-box-group-entity': {
        borderTop: '1px solid #00000040',
    },
    '& .index-box-total': {
        borderTop: '1px solid #00000040',
        background: '#035518',
        color: '#ffffff',
    },
    '& .index-box-subgroup, .index-box-entity, .index-box-total': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    '& .index-text': {
        fontFamily: '"Roboto", "-apple-system", "Helvetica Neue", Helvetica, Arial, sans-serif !important',
        fontSize: '14px !important',
    },
    // '& .index-buttons-row': {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    // },
    // '& .index-button': {
    //     width: '230px',
    // },
    '& .fade-text': {
        animation: `${blink} 4s infinite`,
    },
});

export const IndexFiltered = ({ sendFilters }: Props) => {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    const [forceReindex, setForceReindex] = useState<boolean>(false);
    const [reindexType, setReindexType] = useState<number>(0);
    const [indexEntity, setIndexEntity] = useState<boolean>(false);
    const [indexSubgroup, setIndexSubgroup] = useState<boolean>(false);
    const [indexTag, setIndexTag] = useState<boolean>(false);
    const [logMessage, setLogMessage] = useState<string>('');

    const [indexProcess, setIndexProcess] = useState<boolean>(false);

    const signalR = new SignalR(tenantConfig?.endpoints.signalR + '/ConnectionHub', jwtToken);

    const { data: indexData, isLoading: indexLoading, refetch, isRefetching } = useGetIndexedFiles(sendFilters);
    const { data: reIndexData } = useReIndexFiles(reindexType, sendFilters, forceReindex, indexEntity || indexSubgroup || indexTag);

    const handleIndexEntity = async () => {
        setReindexType(2);
        setIndexEntity(true);
        setIndexProcess(true);
        refetch();
    };

    const handleIndexSubgroup = async () => {
        setReindexType(3);
        setIndexSubgroup(true);
        setIndexProcess(true);
        refetch();
    };

    const handleIndexTag = async () => {
        setReindexType(1);
        setIndexTag(true);
        setIndexProcess(true);
        refetch();
    };

    signalR.listen('reindexing', (object: { log: string, indexProcessResponse: boolean }) => {
        // alertify.alert(msg);
        setLogMessage(object.log);

        if (object.indexProcessResponse) {
            setIndexProcess(false);
        }
    });

    useEffect(() => {
        if (reIndexData) {
            setIndexEntity(false);
            setIndexSubgroup(false);
            setIndexTag(false);
        }
    }, [reIndexData]);

    return (
        <Fragment>
            <Box css={style}>
                <GeopostLoading loading={indexLoading || isRefetching} />
                <Box className='index-box'>
                    <Box className='index-box-group-subgroup'>
                        <Box className='index-box-subgroup'>
                            <Typography className='index-text'>With Subgroup</Typography>
                            <Typography className='index-text'>{indexData ? indexData.WithSubgroup : 0}</Typography>
                        </Box>
                        <Box className='index-box-subgroup'>
                            <Typography className='index-text'>With no Subgroup</Typography>
                            <Typography className='index-text'>{indexData ? indexData.WithNoSubgroup : 0}</Typography>
                        </Box>
                    </Box>
                    <Box className='index-box-group-entity'>
                        <Box className='index-box-entity'>
                            <Typography className='index-text'>With Entity</Typography>
                            <Typography className='index-text'>{indexData ? indexData.WithEntity : 0}</Typography>
                        </Box>
                        <Box className='index-box-entity'>
                            <Typography className='index-text'>With no Entity</Typography>
                            <Typography className='index-text'>{indexData ? indexData.WithNoEntity : 0}</Typography>
                        </Box>
                    </Box>
                    <Box className='index-box-total'>
                        <Typography className='index-text'>Total of read files</Typography>
                        <Typography className='index-text'>{indexData ? (indexData.WithSubgroup + indexData.WithNoSubgroup) : 0}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Box style={{ marginBottom: '16px', height: '72px' }}>
                        {(logMessage !== '') &&
                            <Fragment>
                                <Typography>{logMessage}</Typography>
                                {indexProcess ?
                                    (<Typography className='fade-text'>The import process keep going, do not close or reload this screen</Typography>)
                                    :
                                    (<Typography>The import process finished</Typography>)
                                }
                            </Fragment>
                        }
                    </Box>
                </Box>
                <Grid spacing={2} container direction="row">
                    <Grid xs={2.4} item>
                        <Button fullWidth disabled={indexProcess} className='index-button' variant='contained' color='primary' onClick={() => handleIndexEntity()}>
                            INDEX ENTITY
                        </Button>
                    </Grid>
                    <Grid xs={2.4} item>
                        <Button fullWidth disabled={indexProcess} className='index-button' variant='contained' color='primary' onClick={() => handleIndexSubgroup()}>
                            INDEX SUBGROUP
                        </Button>
                    </Grid>
                    <Grid xs={2.4} item>
                        <Button fullWidth disabled={indexProcess} className='index-button' variant='contained' color='primary' onClick={() => handleIndexTag()}>
                            INDEX TAG
                        </Button>
                    </Grid>
                    <Grid xs={2.4} item>
                        <FormControl fullWidth disabled={indexProcess} className='search-select' size="small" >
                            <FormControlLabel control={
                                <Checkbox value={forceReindex} onChange={() => setForceReindex(!forceReindex)} color='primary' />
                            } label="Without Entities" />
                        </FormControl>
                    </Grid>
                    <Grid xs={2.4} item>
                        <Button fullWidth disabled={indexProcess} className='index-button' variant='contained' color='primary' onClick={() => refetch()}>
                            REFRESH
                        </Button>
                    </Grid>
                </Grid>
                {/* <Box className='index-buttons-row'> */}
                {/* </Box> */}
            </Box>
        </Fragment>
    );
};

interface Props {
    sendFilters: ISendFilter;
}