import * as THREE from 'three';

import { GeopostThreeJsConstants } from 'features/seismic-3d/threejs/utils/GeopostThreeJsConstants';

export const getPointInWellPathByDepth = (pointDepth: number, wellPoints: THREE.Vector3[], heightFactor: number) => {
    const depthInPixels = Math.abs(pointDepth) / heightFactor;
    const scaledDepth = -(depthInPixels / GeopostThreeJsConstants.yDivisionFactor);
    let annotationPoint : THREE.Vector3;
    if (wellPoints.length === 2) {
        annotationPoint = getPointInWellPathByY(scaledDepth, wellPoints, heightFactor);
    } else {
        annotationPoint = wellPoints.reduce((final, current, index) => {
            if (Math.abs(Math.abs(current.y) - Math.abs(scaledDepth)) < Math.abs(Math.abs(final.y) - Math.abs(scaledDepth))) {
                return current;
            } else {
                return final;
            }
        });
    }

    return annotationPoint;
};

export const getPointInWellPathByY = (y: number, wellPoints: THREE.Vector3[], heightFactor: number) => {
    //Usando semelhança entre triângulos para descobrir o ponto C, no eixo X e no eixo Z onde a anotação precisa ficar´

    //eixo z
    let a = new THREE.Vector2(wellPoints[0].z, wellPoints[0].y);
    let b = new THREE.Vector2(a.x, y);
    let e = new THREE.Vector2(wellPoints[1].z, wellPoints[1].y);
    let d = new THREE.Vector2(a.x, e.y);

    let ed = Math.abs(e.distanceTo(d));
    let da = Math.abs(d.distanceTo(a));
    let ba = Math.abs(b.distanceTo(a));
    let bc =  (ba * ed) / da;
    let c = new THREE.Vector2(e.x > b.x ? b.x + bc : b.x - bc, y);

    let zPoint = c.x;

    //eixo x
    a = new THREE.Vector2(wellPoints[0].x, wellPoints[0].y);
    b = new THREE.Vector2(a.x, y);
    e = new THREE.Vector2(wellPoints[1].x, wellPoints[1].y);
    d = new THREE.Vector2(a.x, e.y);

    ed = Math.abs(e.distanceTo(d));
    da = Math.abs(d.distanceTo(a));
    ba = Math.abs(b.distanceTo(a));
    bc =  (ba * ed) / da;
    c = new THREE.Vector2(e.x > b.x ? b.x + bc : b.x - bc, y);

    let xPoint = c.x;

    return new THREE.Vector3(xPoint, y, zPoint);
};