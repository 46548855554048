import { Box, Button, css, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Zoom } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { createRef, Fragment, useEffect, useState } from 'react';
import { useGetAllDataGroups, useGetDataSubGroupByDataGroupToken } from 'fileIndexing/taxonomies/useTaxonomy';
import { MainTableCss } from 'fileIndexing/envVar/envVar';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlaceIcon from '@mui/icons-material/Place';
import AddIcon from '@mui/icons-material/Add';
import alertify from 'alertifyjs';
import dayjs from 'dayjs';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { IEntitiesSeismic, IEntitiesTable, IEntitiesWell } from '../interfaces/IEntities';
import { useDeleteSeismicSurvey, useGetCountTable, useGetListTable, useGetSeismicSurveyByToken, useGetWellByToken } from '../useEntities';
import { EntitiesForm } from './EntitiesForm';
import { useSessionStore } from 'session/useSessionStore';

const style = css({
    '& .search-select': {
        width: '210px',
        marginRight: '15px',
    },
});

export function EntitiesTable() {
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }));

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openEditWellModal, setOpenEditWellModal] = useState<boolean>(false);
    const [openEditSeismicModal, setOpenEditSeismicModal] = useState<boolean>(false);

    const [modalWellData, setModalWellData] = useState<IEntitiesWell | null>(null);
    const [modalSeismicData, setModalSeismicData] = useState<IEntitiesSeismic | null>(null);

    const [editWellToken, setEditWellToken] = useState<string>('');
    const [editSeismicToken, setEditSeismicToken] = useState<string>('');
    const [deleteToken, setDeleteToken] = useState<string>('');

    const [filter, setFilter] = useState<string>('');
    const [dataGroupFilter, setDataGroupFilter] = useState<string>('');
    const [dataSubGroupFilter, setDataSubGroupFilter] = useState<string>('');
    const [entityTypeFilter, setEntityTypeFilter] = useState<string>('');

    const [pagination, setPagination] = useState<{ pageSize: number, page: number; }>({
        pageSize: 5,
        page: 1,
    });

    const [sort, setSort] = useState<GridSortModel>([
        {
            field: 'Name',
            sort: 'asc',
        },
    ]);

    const [loading, setLoading] = useState<boolean>(false);

    const { data: resultsCount } = useGetCountTable(filter, dataGroupFilter, dataSubGroupFilter/* , entityTypeFilter */);
    const { data: dataTable, isLoading: dataTableLoading, refetch } = useGetListTable(filter, dataGroupFilter, dataSubGroupFilter, entityTypeFilter, pagination.pageSize, pagination.page, sort);
    const { data: dataGroups } = useGetAllDataGroups();
    const { data: dataSubGroups } = useGetDataSubGroupByDataGroupToken(dataGroupFilter);
    const { data: wellEntitiesGetByToken } = useGetWellByToken(editWellToken);
    const { data: seismicEntitiesGetByToken } = useGetSeismicSurveyByToken(editSeismicToken);
    const { data: deleteEntitiesData } = useDeleteSeismicSurvey(deleteToken);

    const handleOpenNew = () => setOpenModal(true);
    const handleCloseNew = () => setOpenModal(false);

    const handleOpenEditWell = (rowData: IEntitiesTable) => {
        setEditWellToken(rowData.Token);
    };

    const handleOpenEditSeismic = (rowData: IEntitiesTable) => {
        setEditSeismicToken(rowData.Token);
    };

    const handleCloseEditWell = () => {
        setOpenEditWellModal(false);
    };

    const handleCloseEditSeismic = () => {
        setOpenEditSeismicModal(false);
    };

    const handleDelete = (name: string, token: string) => {
        alertify.confirm(`Do you really wanna delete ${name}?`, async () => {
            setDeleteToken(token);
        });
    };

    const handleOpenFiles = (entitiesName: string, entitiesToken: string) => {
        window.open(`/FileIndexing/files?tenantToken=${tenantConfig?.tenantToken}&entitiesTerm=${entitiesName}&entitiesToken=${entitiesToken}`, '_blank');
    };

    // limpa as informações do modal de well
    useEffect(() => {
        if (!openEditWellModal) {
            setModalWellData(null);
        }
    }, [openEditWellModal]);

    // limpa as informações do modal de seismic
    useEffect(() => {
        if (!openEditSeismicModal) {
            setModalSeismicData(null);
        }
    }, [openEditSeismicModal]);

    // seta as informações para edit da well
    useEffect(() => {
        if (wellEntitiesGetByToken) {
            const wellData = wellEntitiesGetByToken;
            wellData.StartDrillingDate = dayjs(wellData.StartDrillingDate, 'DD/MM/YYYY');
            wellData.EndDrillingDate = dayjs(wellData.EndDrillingDate, 'DD/MM/YYYY');

            setModalWellData(wellData);
            setOpenEditWellModal(true);
            setEditWellToken('');
        }
    }, [wellEntitiesGetByToken]);

    // seta as informações para edit da seismic
    useEffect(() => {
        if (seismicEntitiesGetByToken) {
            const seimicData = seismicEntitiesGetByToken;
            seimicData.OrderDate = dayjs(seimicData.OrderDate, 'DD/MM/YYYY');

            setModalSeismicData(seismicEntitiesGetByToken);
            setOpenEditSeismicModal(true);
            setEditSeismicToken('');
        }
    }, [seismicEntitiesGetByToken]);

    // reexecuta a tabela
    useEffect(() => {
        refetch();
    }, [dataGroupFilter]);

    useEffect(() => {
        if (deleteEntitiesData === 'Success') {
            refetch();
        }
    }, [deleteEntitiesData]);

    // conteúdo da tabela
    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerAlign: 'left',
            headerName: 'Name',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'ANPName',
            headerAlign: 'left',
            headerName: 'ANP Name',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'Nickname',
            headerAlign: 'left',
            headerName: 'Nickname',
            sortable: false,
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'EntityType',
            headerAlign: 'left',
            headerName: 'Type',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'SubGroups',
            headerAlign: 'left',
            headerName: 'Sub Groups',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'Options',
            headerName: 'Options',
            headerAlign: 'center',
            sortable: false,
            minWidth: 180,
            flex: 2,
            renderCell: (params) => (
                <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    {params.row.EntityType === 'Non Seismic Survey' || params.row.EntityType === 'Seismic Survey' ?
                        (<Fragment>
                            <Tooltip title='Edit contract metadata' placement='top' arrow TransitionComponent={Zoom}>
                                <Button variant='contained' color='primary' onClick={() => alertify.notify('ainda não implementado')}>
                                    <PlaceIcon />
                                </Button>
                            </Tooltip>
                            {/* <Tooltip title='Edit entity metadata' placement='top' arrow TransitionComponent={Zoom}>
                                <Button variant='contained' color='primary' className='edit-icon' onClick={() => handleOpenEditSeismic(params.row)}>
                                    <EditNoteIcon />
                                </Button>
                            </Tooltip> */}
                            <Tooltip title='View files associated to this well/seismic' placement='top' arrow TransitionComponent={Zoom}>
                                <Button variant='contained' color='primary' className='link-icon' onClick={() => handleOpenFiles(params.row.Name, params.row.Token)}>
                                    <LinkIcon />
                                </Button>
                            </Tooltip>
                            {/* <Tooltip title='Delete Survey' placement='top' arrow TransitionComponent={Zoom}>
                                <Button variant='contained' color='error' className='delete-icon' onClick={() => handleDelete(params.row.Name, params.row.Token)}>
                                    <DeleteIcon />
                                </Button>
                            </Tooltip> */}
                        </Fragment>) :
                        (<Fragment>
                            {/* <Tooltip title='Edit entity metadata' placement='top' arrow TransitionComponent={Zoom}>
                                <Button variant='contained' color='primary' onClick={() => handleOpenEditWell(params.row)}>
                                    <EditNoteIcon />
                                </Button>
                            </Tooltip> */}
                            <Tooltip title='View files associated to this well/seismic' placement='top' arrow TransitionComponent={Zoom}>
                                <Button variant='contained' color='primary' className='link-icon' onClick={() => handleOpenFiles(params.row.Name, params.row.Token)}>
                                    <LinkIcon />
                                </Button>
                            </Tooltip>
                        </Fragment>)
                    }
                </Box>
            ),
        },
    ];

    const getRowId = (row: IEntitiesTable) => row.Token;

    return (
        <Fragment>
            <Box id='table-entities' css={[style, MainTableCss]}>
                <Box className='table-header'>
                    <Box className='search'>
                        <FormControl className='search-select' size="small" >
                            <InputLabel color='primary'>Data Group Filter</InputLabel>
                            <Select
                                value={dataGroupFilter}
                                onChange={(event) => { setDataGroupFilter(event.target.value) }}
                                label='Data Group Filter'
                                color='primary'
                            >
                                <MenuItem value=''>None</MenuItem>
                                {dataGroups?.map((item) => (
                                    <MenuItem key={item.Token} value={item.Token}>{item.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className='search-select' size="small" >
                            <InputLabel color='primary'>Data SubGroup Filter</InputLabel>
                            <Select
                                value={dataSubGroupFilter}
                                onChange={(event) => setDataSubGroupFilter(event.target.value)}
                                label='Data SubGroup Filter'
                                color='primary'
                            >
                                <MenuItem value=''>None</MenuItem>
                                {dataSubGroups?.map((item) => (
                                    <MenuItem key={item.Token} value={item.Token}>{item.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className='search'>
                        <FormControl className='search-select' size="small" >
                            <InputLabel color='primary'>Filter the entity type (Seismic / Well / NonSeismic)</InputLabel>
                            <Select
                                value={entityTypeFilter}
                                onChange={(event) => setEntityTypeFilter(event.target.value)}
                                label='Filter the entity type (Seismic / Well / NonSeismic)'
                                color='primary'
                            >
                                <MenuItem value=''>None</MenuItem>
                                <MenuItem value='Well'>Well</MenuItem>
                                <MenuItem value='Seismic Survey'>Seismic Survey</MenuItem>
                                <MenuItem value='Non Seismic Survey'>Non Seismic Survey</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label='Type the entity name to filter on the table' variant='outlined' size='small' value={filter} onChange={(event) => setFilter(event.target.value)} />
                        {/* <Button variant='contained' color='primary' className='add-icon' onClick={() => handleOpenNew()}>
                            <AddIcon />
                        </Button> */}
                    </Box>
                </Box>
                <GeopostTable<IEntitiesTable> filteredData={dataTable} loading={dataTableLoading || loading} columns={columns} getRowId={getRowId} resultsCount={resultsCount} pagination={pagination} setPagination={setPagination} setSort={setSort} />
            </Box>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openModal}
                onChangeOpen={handleCloseNew}
                resizable={false}
                width={1200}
                minHeight={365}
                height={460}
                title={'Add Well or Seismic'}
                padding={'16px'}
            >
                <EntitiesForm setOpenModal={setOpenModal} refetchTableData={refetch} setLoading={setLoading} />
            </GeopostPopup>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openEditWellModal}
                onChangeOpen={handleCloseEditWell}
                resizable={false}
                width={1200}
                height={520}
                title={'Edit Well'}
                padding={'16px'}
            >
                <EntitiesForm setOpenModal={setOpenEditWellModal} refetchTableData={refetch} setLoading={setLoading} formDataWell={modalWellData} formDataType={'well'} />
            </GeopostPopup>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openEditSeismicModal}
                onChangeOpen={handleCloseEditSeismic}
                resizable={false}
                width={1200}
                height={520}
                title={'Edit Seismic'}
                padding={'16px'}
            >
                <EntitiesForm setOpenModal={setOpenEditSeismicModal} refetchTableData={refetch} setLoading={setLoading} formDataSeismic={modalSeismicData} formDataType={'seismic'} />
            </GeopostPopup>
        </Fragment>
    );
}