import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FlipIcon from '@mui/icons-material/Flip';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SeismicChangeScale } from './SeismicChangeScale';
import { useSeismicStore } from '../stores/useSeismicStore';

export function SeismicScaleNavbar () {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [ isPanelVisible, setIsPanelVisible ] = useState(false);
    const handleClose = () => setIsPanelVisible(false);
    const [isInverted, setIsInverted] = useSeismicStore(state => [state.isInverted, state.setIsInverted]);

    const { t } = useTranslation();
    const iconButtonRef = useRef(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsPanelVisible(true);
        setAnchorEl(iconButtonRef.current);
    };

    const classNameLabelColorbar = (isPanelVisible) ? 'active': '';
    const classNameInverted = (isInverted) ? 'active': '';

    //onClick={() => /*setOpenedLabelColorbar(!openedLabelColorbar)*/ null}
    return  <Fragment>
        <IconButton id='scalebar' title={t('scale.change')} size='medium' className={classNameLabelColorbar} ref={iconButtonRef} onClick={handleContextMenu} color='inherit'>
            <AspectRatioIcon color='inherit'/>
        </IconButton>
        <IconButton style={{marginRight: '0px'}} id='invert_direction' title={'Click to flip the seismic direction'} size='medium' className={classNameInverted} onClick={() => setIsInverted(!isInverted)} >
            <FlipIcon style={{color: 'white'}}></FlipIcon>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={isPanelVisible}
            onClose={handleClose}
        >
            {/*
            <MenuItem style={{backgroundColor: 'grey', margin:0}}>
                <Typography>{t('scale.change')}</Typography>
            </MenuItem>
            */}
            <SeismicChangeScale />
        </Menu>
    </Fragment>;
}