import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { IDataGroup } from 'fileIndexing/taxonomies/interfaces/ITaxonomy';
import { useGetAllDataGroups } from 'fileIndexing/taxonomies/useTaxonomy';
import { useEffect } from 'react';
import { FieldValues, Path, PathValue, useForm, UseFormReturn } from 'react-hook-form';

import { GeopostLoading } from './GeopostLoading';
import { GeopostTreeViewDropdows } from './GeopostTreeViewDropdows';

const style = css({
    width: '100%',
    position: 'relative',
});

const toPath = <T extends FieldValues>(key: keyof T): Path<T> => key as Path<T>;

export function GeopostDropdownDataGroup<T extends FieldValues>({ formPropData, fieldName, label, noneItem = false, required = false }: Props<T>) {
    const propData = useForm<IDataGroup>({
        defaultValues: {
            Token: '',
            Name: '',
            DataSubGroupsTokens: [],
            Children: [],
            ParentGroupName: '',
            ParentGroupToken: '',
            Label: ''
        }
    });

    const { data: dataGroup, isLoading } = useGetAllDataGroups();

    const fieldValue = formPropData.watch(toPath<T>(fieldName));
    const tagToken = propData.watch('Token');

    useEffect(() => {
        if (tagToken) {
            formPropData.setValue(toPath<T>(fieldName), tagToken as PathValue<T, Path<T>>);
        }
    }, [tagToken]);

    useEffect(() => {
        if (fieldValue && dataGroup) {
            const findTagInChildren = (groups: IDataGroup[], token: string): IDataGroup | undefined => {
                for (const group of groups) {
                    if (group.Token === token) {
                        return group;
                    }
                    if (group.Children && group.Children.length > 0) {
                        const foundInChildren = findTagInChildren(group.Children, token);
                        if (foundInChildren) {
                            return foundInChildren;
                        }
                    }
                }
                return undefined;
            };

            const tagSelected = findTagInChildren(dataGroup, fieldValue);

            if (tagSelected) {
                propData.setValue('Name', tagSelected.Name);
                propData.setValue('Token', tagSelected.Token);
            }
        }
    }, [fieldValue, dataGroup]);

    return (
        <Box css={style}>
            <GeopostLoading loading={isLoading} />
            <GeopostTreeViewDropdows<IDataGroup> label={label} control={propData.control} name={'Token'} data={dataGroup} noneItem={noneItem} childrenField={'Children'} required={required} />
        </Box>
    );
}

interface Props<T extends FieldValues> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formPropData: UseFormReturn<T>;
    fieldName: Path<T>;
    label: string;
    noneItem?: boolean;
    required?: boolean;
}