import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Fragment } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function GeopostTreeViewDropdows<T extends FieldValues & { Token: string; Name: string;[key: string]: any }>({
    label, control, name, data, childrenField, allowParentSelection = true, noneItem = true, required = false
}: Props<T>) {
    const renderMenuItems = (groups: T[], level: number = 0): JSX.Element[] => {
        return groups.reduce<JSX.Element[]>((acc, group) => {
            const hasChildren = group[childrenField] && group[childrenField].length > 0;

            // Determina se o item pai deve estar desabilitado
            const disabled = group[childrenField] && !allowParentSelection ? true : false;

            acc.push(
                <MenuItem key={group.Token} value={group.Token} disabled={disabled}>
                    <Box ml={level * 2}>{group.Name}</Box>
                </MenuItem>
            );
            if (hasChildren) {
                acc.push(...renderMenuItems(group[childrenField], level + 1));
            }
            return acc;
        }, []);
    };

    return (
        <Fragment>
            <FormControl required={required} fullWidth>
                <InputLabel>{label}</InputLabel>
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <Select
                            required={required}
                            fullWidth
                            value={field.value}
                            onChange={(event: SelectChangeEvent<string>) => {
                                field.onChange(event.target.value);
                            }}
                            label={label}
                        >
                            {noneItem && <MenuItem value=''>None</MenuItem>}
                            {data && renderMenuItems(data)}
                        </Select>
                    )}
                />
            </FormControl>
        </Fragment>
    );
}

interface Props<T extends FieldValues> {
    label: string;
    control: Control<T>;
    name: Path<T>;
    data: T[] | undefined;
    childrenField: keyof T;
    allowParentSelection?: boolean;
    noneItem?: boolean;
    required?: boolean;
}