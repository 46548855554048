import { Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';

import { LineType } from 'features/seismic/models/enums/LineType';
import { updateQueryString } from 'features/seismic/utils/urlUtils';
import { VolumeType } from 'features/seismic-3d/models/enums/VolumeType';

const PARAGRAPH_STYLE = 'style="margin: 0 2px;"';

export const createOverlay = (coordinate: Coordinate, options: IOptions, mouseEnterCallback: () => void, mouseOutCallback: () => void) => {
    const openSeismicUrl = updateQueryString(window.location.href, {
        volumeToken: options.volumeToken
    });

    const element = document.createElement('div');
    element.innerHTML = createPopup(options, openSeismicUrl);

    element.addEventListener('mouseover', mouseEnterCallback);

    element.addEventListener('mouseout', mouseOutCallback);

    const popup = new Overlay({
        element: element,
        position: coordinate
    });
    return popup;
};

const createPopupStyle = () => `background-color: #0b0b0b94; border-radius: 5px; color: #fff; padding: 10px; border: 2px solid #fff`;

const createLinkStyle = () => `color: #ffffff; margin-top: 6px; display: inline-block; font-size: 20px;`;

const createPopup = (options: IOptions, openSeismicUrl: string) => `<div style='${createPopupStyle()}'>
    <p ${PARAGRAPH_STYLE}><b>${options.seismicName}</b></p>
    ${createLineNumberParagraph(options.volumeType, options.lineOrientation, options.lineNumber)}
    <p ${PARAGRAPH_STYLE}><a style='${createLinkStyle()}' href='${openSeismicUrl}' target="_blank">${options.openLabel}</a></p>
</div>`;

const createLineNumberParagraph = (volumeType: VolumeType, lineOrientation: LineType, lineNumber: number) => {
    if (volumeType === VolumeType.Seismic2D){
        return '';
    }
    else {
        return `<p ${PARAGRAPH_STYLE}>${(lineOrientation === LineType.Inline ? 'Inline':'Xline')} ${lineNumber}</p>`;
    }
};

interface IOptions {
    openLabel: string;
    seismicName: string;
    lineNumber: number;
    lineOrientation: LineType;
    volumeToken: string;
    volumeType: VolumeType;
}