import { Checkbox, CircularProgress, FormControlLabel, Stack, Typography } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import ImageLayer from 'ol/layer/Image';
import ImageStatic from 'ol/source/ImageStatic';
import alertify from 'alertifyjs';

import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { useMapStore } from 'features/seismic/stores/useMapStore';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useGetGenericCutsForSeismic } from './api/useSeismicWellController';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { getXValue, getYValue } from 'features/seismic/utils/wellsUtils';
import { LineType } from 'features/seismic/models/enums/LineType';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { useGetImageFromAWSS3 } from './api/useProcessing';
import { GeopostCheckbox } from 'components/geopostCheckbox/GeopostCheckbox';
import { useSessionStore } from 'session/useSessionStore';
import { getXByTrace } from 'features/seismic/utils/calculateScreenValuesUtils';

export function GenericCutsCheckbox({
    wellId,
    trace,
    item,
    domain,
    datum,
    dataGeometryLine,
    genericCutsToRecreate,
    checked,
    surveyType,
    onChange = () => {},
    isGenericCutLoading = false,
    error = false,
}: Props) {
    const { calculator, surveyMetadata, scale, wellsLayers, setWellLayers, genericCutsWithoutPermission, setGenericCutsWithoutPermission } = useSeismicStore(state => ({
        calculator: state.calculator,
        scale: state.scale,
        surveyMetadata: state.surveyMetadata,
        wellsLayers: state.wellsLayers,
        setWellLayers: state.setWellsLayers,
        genericCutsWithoutPermission: state.genericCutsWithoutPermission,
        setGenericCutsWithoutPermission: state.setGenericCutsWithoutPermission
    }));

    const { map } = useMapStore(state => ({
        map: state.map
    }));

    const { lineType } = useLine3DNavigationStore(state => ({
        lineType: state.lineType
    }));

    const data = item.split('|');

    const [checkbox, setCheckbox] = useState<boolean>(false);
    const disabled = useRef<boolean>(false);
    const layer = useRef<ImageLayer<ImageStatic>>();
    // const createGenericCutsImagem = useRef<boolean>(true);

    const { data: dataGenericCuts, isLoading: isLoadingAttributes } = useGetGenericCutsForSeismic(checkbox, wellId, data[1], trace, domain, datum, surveyType);

    const s3ImageEndpoint = useSessionStore(state => state.tenantConfiguration?.endpoints.s3Image);

    const genericType = () => {
        let type = data[2];

        if (data[2] === '1') {
            type = 'Lithology';
        }
        else if (data[2] === '2') {
            type = 'Log Curve';
        }
        else if (data[2] === '3') {
            type = 'Other';
        }

        return type;
    };

    const activateGenericType = (checked: boolean, addOrRemove: boolean) => {
        setCheckbox(checked);
        if (surveyType === SurveyType.Seismic2D) {
            if (addOrRemove) {
                showLayer(checked);
            }
        } else {
            onChange(checked, item);
        }
    };

    const loadingGenericCuts = (checked: boolean) => {
        if (checked && surveyMetadata && dataGenericCuts) {
            // if (createGenericCutsImagem.current) {
            if (dataGenericCuts.YPosition[0] > 0 || dataGenericCuts.YPosition[1] > 0) {
                dataGenericCuts.YPosition[0] *= -1;
                dataGenericCuts.YPosition[1] *= -1;
            }

            const increment = lineType === LineType.Inline ? surveyMetadata.Survey3DInfo.XlineIncrement : surveyMetadata.Survey3DInfo.InlineIncrement;
            const start = calculator!.getMinTrace(surveyMetadata!);
            const genericCutsX = getXByTrace(dataGeometryLine.Min + dataGenericCuts.XPosition * increment, scale.x, start, increment);
            const genericCutsY = [getYValue(dataGenericCuts.YPosition[0], scale.y, calculator!.calculateSampleInterval(surveyMetadata)), getYValue(dataGenericCuts.YPosition[1], scale.y, calculator!.calculateSampleInterval(surveyMetadata))];

            let imageLayer = new ImageLayer({
                source: new ImageStatic({
                    url: `${s3ImageEndpoint}${dataGenericCuts.UrlPartActionTracking}`,
                    imageSize: [dataGenericCuts.WidthImage, dataGenericCuts.HeightImage],
                    imageExtent: [genericCutsX, genericCutsY[0], genericCutsX + 50, genericCutsY[1]]
                }),
            });

            layer.current = imageLayer;

            // createGenericCutsImagem.current = false;
            // }
        }
    };

    const showLayer = (checked: boolean) => {
        if (layer.current && /* !createGenericCutsImagem.current &&*/ dataGenericCuts) {
            if (checked) {
                map?.addLayer(layer.current);
                setWellLayers((wellId).toString(), { [`${genericType()}_${data[1]}`]: { layer: layer.current, checked: true } });
            }
            else {
                map?.removeLayer(layer.current);
                setWellLayers((wellId).toString(), { [`${genericType()}_${data[1]}`]: { layer: layer.current, checked: false } });
            }
        }
    };

    const errorAlert = () => {
        if (dataGenericCuts?.errorType === 'permission') {
            alertify.alert(`Este tracking pertence ao usuário ${dataGenericCuts?.errorMessage} e não está compartilhado com você.`);
        }
        else {
            alertify.alert(`Ocorreu um erro inesperado`);
        }

        setCheckbox(false);
        disabled.current = true;

        let notPermited = genericCutsWithoutPermission[wellId.toString()] === undefined ? [] : genericCutsWithoutPermission[wellId.toString()];
        notPermited.push(data[1]);
        setGenericCutsWithoutPermission({ [wellId.toString()]: notPermited });
    };

    useEffect(() => {
        if (dataGenericCuts !== null && dataGenericCuts !== undefined) {
            if (dataGenericCuts.errorType !== null && dataGenericCuts.errorType !== undefined) {
                if (!genericCutsWithoutPermission[wellId.toString()]) {
                    errorAlert();
                }
                else if (!genericCutsWithoutPermission[wellId.toString()].includes(data[1])) {
                    errorAlert();
                }
            }
            else {
                loadingGenericCuts(checkbox);
                showLayer(checkbox);
            }
        }
    }, [dataGenericCuts]);

    useEffect(() => {
        if (genericCutsToRecreate.includes((data[1]).toString())) {
            if (layer.current) {
                map?.removeLayer(layer.current);
            }
            loadingGenericCuts(true);
            activateGenericType(true, dataGenericCuts ? true : false);
        }

        if (genericCutsWithoutPermission[wellId.toString()]) {
            if (genericCutsWithoutPermission[wellId.toString()].includes(data[1])) {
                setCheckbox(false);
                disabled.current = true;
            }
        }
    }, [genericCutsToRecreate]);

    useEffect(() => {
        console.log(isGenericCutLoading);
    }, [isGenericCutLoading]);

    useEffect(() => {
        if (error) {
            console.log('caiu ERROR -=------------>', error);
        }
    } , [error]);

    return (
        <Stack direction='row'>
            <FormControlLabel
                control={
                    <GeopostCheckbox
                        id={'wells-' + genericType().toLowerCase() + '-checkbox'}
                        loading={isGenericCutLoading || isLoadingAttributes}
                        disabled={disabled.current}
                        checked={checked ?? checkbox}
                        onChange={checked => {
                            activateGenericType(checked, dataGenericCuts ? true : false);
                        }}
                        error={error}
                    />
                }
                label={<Typography>{genericType()} - {data[0]}</Typography>}
            />
        </Stack>
    );
};

interface Props {
    wellId: number,
    trace: number,
    item: string;
    domain: AmplitudeDomain;
    datum: number;
    dataGeometryLine: ILineString;
    genericCutsToRecreate: string[];
    surveyType: SurveyType;
    onChange?: (checked: boolean, elementToken: string) => void;
    checked?: boolean;
    isGenericCutLoading?: boolean;
    error?: boolean;
}

interface ResponseData {
    Error: boolean;
    Result: { errorMessage: String; errorType: String; }
    Status: number;
}