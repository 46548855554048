import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import Backend from 'i18next-http-backend';

i18next.use(Backend).use(initReactI18next).use(HttpApi).init({
    lng: 'en',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'seismic',
    ns: ['seismic', 'common'],
    backend: {
        loadPath: `./locales/{{lng}}/{{ns}}.json`,
    }
});

export { i18next };