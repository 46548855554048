import { Button, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import alertify from 'alertifyjs';
import { FormContainer } from 'react-hook-form-mui';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { GeopostDropdownDataGroup } from 'fileIndexing/otherComponents/GeopostDropdownDataGroup';

import { IDataGroup, ISubgroup } from '../interfaces/ITaxonomy';
import { useGetAllDataGroups, useSaveDataSubGroup } from '../useTaxonomy';

export const TaxonomyForm = ({ setOpenModal, refetchTableData, formData }: Props) => {
    const formDataProps = useForm<ISubgroup>({
        defaultValues: formData || undefined
    });
    const [data, setData] = useState<ISubgroup | null>(null);

    const internalPathEnable = formDataProps.watch('CopyTransmittalFilesToInternalPath');

    const { data: dataGroups } = useGetAllDataGroups();
    const { data: saveDataSubGroup } = useSaveDataSubGroup(data);

    const findToken = (dataGroups: IDataGroup[], token: string): IDataGroup | null => {
        for (let entity of dataGroups) {
            if (entity.Token === token) {
                return entity;
            }

            if (entity.Children.length > 0) {
                const result = findToken(entity.Children, token);
                if (result) {
                    return result;
                }
            }
        }

        return null;
    };

    const handleData = (data: ISubgroup) => {
        let newData = data;
        if (formData) {
            newData.Token = formData.Token;
        }
        else {
            newData.Token = '';
        }

        const dataGroupToken = formDataProps.getValues('DataGroupToken');

        if (dataGroupToken === null) {
            alertify.notify('Selecione um dataGroup');
            return;
        }

        const selectedItem = findToken(dataGroups!, dataGroupToken);
        if (selectedItem) {
            newData.DataGroupToken = selectedItem.Token;
            newData.DataGroupName = selectedItem.Name;
        }

        if (!newData.CopyTransmittalFilesToInternalPath) {
            newData.CopyTransmittalFilesToInternalPath = false;
            newData.TransmittalInternalPath = '';
        }

        newData.DataSubRulesTokens = [];

        setData(newData);
    };

    useEffect(() => {
        if (saveDataSubGroup) {
            if (saveDataSubGroup === 'Success') {
                setOpenModal(false);
                refetchTableData();
            }
            else {
                alertify.notify('Ocorreu um erro criando um novo subgroup');
            }
        }
    }, [saveDataSubGroup]);

    return (
        <Fragment>
            <FormContainer<ISubgroup> defaultValues={formData ?? {}} onSuccess={(data) => handleData(data)} FormProps={{ style: { width: '100%' } }}>
                <Grid spacing={2} container direction='column'>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'Name'} label={'Subgroup Name'} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'InternalPath'} label={'Internal Path'} required={false} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostDropdownDataGroup<ISubgroup> formPropData={formDataProps} fieldName={'DataGroupToken'} label={'Parent Group'} required={true} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'RegexPathExtractRule'} label={'Regex Extract Rule Path'} required={false} />
                    </Grid>
                    <Grid xs={12} item>
                        <FormGroup className='space-bottom'>
                            <FormControlLabel control={
                                <Checkbox value={internalPathEnable} onChange={() => formDataProps.setValue('CopyTransmittalFilesToInternalPath', !internalPathEnable)} color='primary' />
                            } label={'Copy Transmittal Files to Internal Path'} />
                        </FormGroup>
                    </Grid>
                    {internalPathEnable &&
                        <Grid xs={12} item>
                            <GeopostTextField fieldName={'TransmittalInternalPath'} label={'Transmittal Internal Path'} />
                        </Grid>
                    }
                    <Grid container item direction='row-reverse'>
                        <Button variant='contained' color='primary' type='submit' style={{ marginBottom: '16px' }}>SAVE</Button>
                    </Grid>
                </Grid>
            </FormContainer>
        </Fragment>
    );
};

interface Props {
    setOpenModal: (value: boolean) => void;
    refetchTableData: () => void;
    formData?: ISubgroup | null;
}