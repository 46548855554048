import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';

import { GeopostWellCrossingPointMesh } from 'features/seismic-3d/threejs/group/GeopostWellCrossingPointMesh';
import { GeopostWellGroup } from 'features/seismic-3d/threejs/group/GeopostWellGroup';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { useGetDirectionalDataByWell, useGetMultipleDirectionalDataByWell } from '../api/useWellDirectionalDataController';
import { GeopostThreeJsConstants } from 'features/seismic-3d/threejs/utils/GeopostThreeJsConstants';
import { getScaled2DCoordinate } from 'features/seismic-3d/threejs/utils/ScaleUtils';
import { IWellDirectionalData } from 'features/seismic/models/interfaces/IWellDirectionalData';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { RaycasterEventFactory } from 'features/seismic-3d/threejs/utils/RaycasterEventFactory';

export const use3DCrossingPoints = (wellsWithVisibleCrossingPoint: IWell[], onAddWellGroup: (well: IWell) => void, color: string, mainFeatureCentroidX: number, mainFeatureCentroidY: number) => {

    const lastWellsWithVisibleCrossingPointRef = useRef<IWell[]>([]);

    const wellsToAddCrossingPoint = useMemo(() => wellsWithVisibleCrossingPoint.filter(well => !lastWellsWithVisibleCrossingPointRef.current.includes(well)), [wellsWithVisibleCrossingPoint]);

    const wellsToRemoveCrossingPoint = useMemo(() => lastWellsWithVisibleCrossingPointRef.current.filter(well => !wellsWithVisibleCrossingPoint.includes(well)), [wellsWithVisibleCrossingPoint]);

    const scene = use3DSceneStore(state => state.scene);

    const surveyHeightInPixels = use3DViewerStore(state => state.heightPixelFactor);

    const addCrossingPoint = useCallback((well: IWell) => {
        if (!scene) {
            return;
        }
        const platformPosition = [well.PlatformXPosition, 0, well.PlatformYPosition];

        const wellDepth = well.Depth > 0 ? well.Depth : well.ProfundidadeVertical;
        const points = [
            [well.BaseXPosition, well.WaterDepth, well.BaseYPosition],
            [well.BottomXPosition, wellDepth, well.BottomYPosition]
        ];

        let wellGroup : GeopostWellGroup;
        do {
            wellGroup = scene.getObjectByName(GeopostWellGroup.getWellGroupName(well.Id)) as GeopostWellGroup;
            if (!wellGroup) {
                onAddWellGroup(well);
            }
        } while (!wellGroup);

        const vectorPoints: THREE.Vector3[] = [];

        points.forEach(wellPoint => {
            const pixelsY = Math.abs(wellPoint[1]) / wellGroup.heightFactor;
            const scaledY = - (pixelsY / GeopostThreeJsConstants.yDivisionFactor);
            const [scaledX, scaledZ] = getScaled2DCoordinate([wellPoint[0], wellPoint[2]], mainFeatureCentroidX, mainFeatureCentroidY);
            const scaledPoint = new THREE.Vector3(scaledX, scaledY, scaledZ);
            vectorPoints.push(scaledPoint);
        });

        wellGroup.wellPoints = vectorPoints;
        const crossingPointMesh = new GeopostWellCrossingPointMesh(well.Name, wellGroup.heightFactor, vectorPoints, platformPosition, color, mainFeatureCentroidX, mainFeatureCentroidY);
        crossingPointMesh.name = 'crossing-point';
        wellGroup.add(crossingPointMesh);

        const raycasterEventFactory = new RaycasterEventFactory<GeopostWellCrossingPointMesh>();
        const originalLabelPosition = new THREE.Vector3(crossingPointMesh.label.position.x, crossingPointMesh.label.position.y, crossingPointMesh.label.position.z);
        const intersectionEvent = raycasterEventFactory.addIntersectionEventOnGroup(
            crossingPointMesh,
            (mesh) => {
                if (!mesh.visible) {
                    return;
                }
                const camera = scene.camera!;
                camera.updateMatrix();
                camera.updateMatrixWorld();
                const frustum = new THREE.Frustum();
                frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse));
                mesh.label.position.set(originalLabelPosition.x, originalLabelPosition.y, originalLabelPosition.z);
                if (!frustum.containsPoint(mesh.label.position)) {
                    for (let i = 0; i < mesh.points.length; i++) {
                        const point = mesh.points[i];
                        if (frustum.containsPoint(point)) {
                            mesh.label.position.set(point.x, point.y, point.z);
                        }
                    }
                };
                mesh.label.visible = true;
            },
            (mesh) => {
                if (!mesh.visible) {
                    return;
                }
                mesh.label.visible = false;
            }
        );
        scene.intersectionEvents.push(intersectionEvent);

    }, [color, mainFeatureCentroidX, mainFeatureCentroidY, onAddWellGroup, scene]);

    const removeCrossingPoint = useCallback((wellId: number) => {
        if (!scene) {
            return;
        }
        const wellGroup = (scene.getObjectByName(GeopostWellGroup.getWellGroupName(wellId)) as GeopostWellGroup | undefined);
        const crossingPointMesh = wellGroup?.getObjectByName('crossing-point');
        if (crossingPointMesh) {
            wellGroup!.remove(crossingPointMesh);
        }
    }, [scene]);

    useEffect(() => {
        wellsToAddCrossingPoint.forEach(well => {
            lastWellsWithVisibleCrossingPointRef.current.push(well);
            addCrossingPoint(well);
        });
    }, [wellsToAddCrossingPoint]);

    useEffect(() => {
        wellsToRemoveCrossingPoint.forEach(well => {
            removeCrossingPoint(well.Id);
            lastWellsWithVisibleCrossingPointRef.current.splice(lastWellsWithVisibleCrossingPointRef.current.indexOf(well), 1);
        });
    }, [wellsToRemoveCrossingPoint]);

    useEffect(() => {
        if (surveyHeightInPixels !== 0) {
            lastWellsWithVisibleCrossingPointRef.current.forEach(well => {
                removeCrossingPoint(well.Id);
                addCrossingPoint(well);
            });
        }
    }, [surveyHeightInPixels]);
};