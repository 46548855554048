import { create } from 'zustand';

import { FeatureGeom } from '../models/classes/FeatureGeom';
import { Seismic3DData } from '../models/classes/Seismic3DData';
import { IntersectingLayer } from '../models/types/IntersectingLayer';

export interface I3DViewerStore {
    mainFeatureGeomData: FeatureGeom | undefined;
    mainFeatureVolumeToken: string | undefined;
    mainFeatureSrid: number;
    selectedSeismics: Seismic3DData[];
    selectedLayers: IntersectingLayer[];
    viewCenter: number[];
    intersectingSeismicsPopupOpened: boolean;
    intersectingLayersPopupOpened: boolean;
    selectedSeismicsPopupOpened: boolean;
    selectedLayersPopupOpened: boolean;
    featureCentroidX: number,
    featureCentroidY: number,
    yFactor: number,
    countTimeDepth: number,
    heightPixelFactor: number,
    errorMessage: string | null,
    setFeatureCentroidX: (centroidX: number) => void,
    setFeatureCentroidY: (centroidY: number) => void
    setMainFeatureGeomData: (mainFeatureGeomData: FeatureGeom | undefined) => void;
    setMainFeatureVolumeToken: (volumeToken: string | undefined) => void;
    setMainFeatureSrid: (srid : number) => void;
    setIntersectingSeismics: (selectedSeismics: Seismic3DData[]) => void;
    setIntersectingLayers: (selectedLayers: IntersectingLayer[]) => void;
    addIntersectingLayer: (intersectingLayerData: IntersectingLayer) => void;
    removeIntersectingLayer: (layerId: number) => void;
    addSelectedSeismic: (seismicData: Seismic3DData) => void;
    removeSelectedSeismic: (seismicData: Seismic3DData) => void;
    setViewCenter: (viewCenter: number[]) => void;
    setIntersectingSeismicsPopupOpened: (value: boolean) => void;
    setIntersectingLayersPopupOpened: (value: boolean) => void;
    changeSelectedLayersPopupOpened: () => void;
    changeSelectedSeismicsPopupOpened: () => void;
    setYFactor: (value: number) => void;
    setCountTimeDepth: (value: number) => void;
    setHeightPixelFactor: (value: number) => void;
    setErrorMessage: (value: string | null) => void;
}

export const use3DViewerStore = create<I3DViewerStore>()((set) => ({
    mainFeatureGeomData: undefined,

    mainFeatureVolumeToken: undefined,

    mainFeatureSrid: 3857,

    selectedSeismics: [],

    selectedLayers: [],

    gridSelectedSeismic: undefined,

    viewCenter: [0, 0],

    intersectingSeismicsPopupOpened: false,

    intersectingLayersPopupOpened: false,

    selectedLayersPopupOpened: true,

    selectedSeismicsPopupOpened: false,

    featureCentroidX: 0,

    featureCentroidY: 0,

    yFactor: 200,

    countTimeDepth: 0,

    heightPixelFactor: 0,

    errorMessage: null,

    setMainFeatureGeomData: (mainFeatureGeomData: FeatureGeom | undefined) => set({
        mainFeatureGeomData: mainFeatureGeomData
    }),

    setMainFeatureVolumeToken: (volumeToken) => set({ mainFeatureVolumeToken: volumeToken }),

    setMainFeatureSrid: (srid) => set({ mainFeatureSrid: srid }),

    setIntersectingSeismics: (selectedSeismics: Seismic3DData[]) => set({
        selectedSeismics: selectedSeismics
    }),
    setIntersectingLayers: (selectedLayers: IntersectingLayer[]) => set({
        selectedLayers: selectedLayers
    }),

    addIntersectingLayer: (intersectingLayerData: IntersectingLayer) => set((state) => ({
        selectedLayers: [...state.selectedLayers, intersectingLayerData]
    })),

    removeIntersectingLayer: (layerId: number) => set((state) => {
        const newState = state.selectedLayers;
        const indexToSplice = state.selectedLayers.findIndex((selectedLayer) => selectedLayer.Id === layerId);
        newState.splice(indexToSplice, 1);
        return { selectedLayers: [...newState] };
    }),

    addSelectedSeismic: (seismicData) => set((state) => ({ selectedSeismics: [...state.selectedSeismics, seismicData] })),

    removeSelectedSeismic: (seismicData) =>
        set((state) => {
            const newState = [...state.selectedSeismics];
            const indexToSplice = newState.findIndex((selectedSeismic) => selectedSeismic.volumeToken === seismicData.volumeToken);
            newState.splice(indexToSplice, 1);
            console.log(newState);
            return { selectedSeismics: [...newState] };
        }),

    setViewCenter: (viewCenter: number[]) => set({
        viewCenter: viewCenter
    }),

    setIntersectingSeismicsPopupOpened: (value: boolean) => set({ intersectingSeismicsPopupOpened: value }),

    setIntersectingLayersPopupOpened: (value: boolean) => set({ intersectingLayersPopupOpened: value }),

    changeSelectedLayersPopupOpened: () => set(state => ({ selectedLayersPopupOpened: !state.selectedLayersPopupOpened })),

    changeSelectedSeismicsPopupOpened: () => set(state => ({ selectedSeismicsPopupOpened: !state.selectedSeismicsPopupOpened })),

    setFeatureCentroidX: (centroidX) => set({ featureCentroidX: centroidX }),

    setFeatureCentroidY: (centroidY) => set({ featureCentroidY: centroidY }),

    setYFactor: (value: number) => set({ yFactor: value }),

    setCountTimeDepth: (value: number) => set({ countTimeDepth: value }),

    setHeightPixelFactor: (value: number) => set({ heightPixelFactor: value }),

    setErrorMessage: (value) => set({ errorMessage: value }),
}));

