import { create } from 'zustand';

import { GeopostMap } from '../models/classes/GeopostMap';
import { GeopostSeismicLayer } from '../models/classes/GeopostSeismicLayer';
import { GeopostSeismicDebugLayer } from '../models/classes/GeopostSeismicDebugLayer';
import { NotificationSubject } from 'models/observers';
import { PointerMoveSeismicPointData } from '../models/classes/PointerMoveSeismicPointData';

export interface IMapStore {
    backgroundColor: string,
    debugLayer: GeopostSeismicDebugLayer | null,
    defaultDebounceTime: number,
    dpi: number;
    getAllTracesPerRequest: boolean;
    initialZoom: number;
    isSequencialPosition: boolean;
    jpgQuality: number;
    mainSeismicLayer: GeopostSeismicLayer | null;
    overlaySeismic: GeopostSeismicLayer | null;
    swipeSeismic: GeopostSeismicLayer | null;
    map: GeopostMap | null;
    mapSize: {
        height: number;
        width: number;
    },
    maxTilesLoading: number;
    pixelRatio: number;
    preparedTraceLimit: number;
    pointerMoveObserver: NotificationSubject<PointerMoveSeismicPointData>;
    resolutions: number[];
    setBackgroundColor: (color: string) => void;
    setLayers: (layer: GeopostSeismicLayer, debugLayer: GeopostSeismicDebugLayer | null, overlay: GeopostSeismicLayer, swipe: GeopostSeismicLayer) => void;
    setMap: (map: GeopostMap, width: number, height: number) => void;
    setResolutions: (resolutions: number[]) => void;
    defaultTileSize: {
        width: number,
        height: number
    };
    totalParallelProcess: number;
    zoomFactor: number;
    setPreparedTraceLimit: (value: number) => void
}

export const useMapStore = create<IMapStore>()(
    (set, get) => ({
        backgroundColor: '#7f7f7f',
        debugLayer: null,
        defaultDebounceTime: 1000,
        dpi: 1,
        getAllTracesPerRequest: false,
        initialZoom: 0,
        isSequencialPosition: false,
        jpgQuality: 100,
        mainSeismicLayer: null,
        overlaySeismic: null,
        swipeSeismic: null,
        map: null,
        maxTilesLoading: 32,
        pixelRatio: 1,
        resolutions: [],
        mapSize: {
            height: 0,
            width: 0
        },
        pointerMoveObserver: new NotificationSubject(),
        preparedTraceLimit: 0,
        setBackgroundColor: (color: string) => set({
            backgroundColor: color
        }),
        setLayers: (layer: GeopostSeismicLayer, debugLayer: GeopostSeismicDebugLayer | null, overlay: GeopostSeismicLayer, swipe: GeopostSeismicLayer) => set({
            mainSeismicLayer: layer,
            debugLayer: debugLayer,
            overlaySeismic: overlay,
            swipeSeismic: swipe
        }),
        setResolutions: (resolutions: number[]) => set({
            resolutions: resolutions
        }),
        setMap: (map: GeopostMap, width: number, height: number) => set({
            map: map, mapSize: {
                width: width,
                height: height
            }
        }),
        defaultTileSize: {
            /* TGS */
            //height: 1200,
            //width: 700
            height: 1080,
            width: 100
        },
        totalParallelProcess: 20,
        zoomFactor: 1,
        setPreparedTraceLimit: (value) => set({preparedTraceLimit: value})
    })
);