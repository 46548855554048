import { isLabelWithInternallyDisabledControl } from '@testing-library/user-event/dist/utils';
import { pointerMove } from 'ol/events/condition';
import * as THREE from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

import { GeopostThreeJsConstants } from '../utils/GeopostThreeJsConstants';

export class GeopostAnnotationGroup extends THREE.Group {
    public label: CSS2DObject;
    public ring: THREE.Mesh;

    public constructor(annotationText: string, origin: number, xPosition: number, zPosition: number, rotationReference: THREE.Vector3 | null, heightFactor: number, color: string) {
        super();
        const newXPosition = xPosition;
        const newZPosition = zPosition;

        const labelDiv = document.createElement('div');
        labelDiv.className = '';
        labelDiv.style.backgroundColor = 'transparent';
        labelDiv.style.color = 'white';
        labelDiv.style.fontSize = '20px';
        //labelDiv.style.marginLeft = '120px';
        labelDiv.style.textShadow = '2px 2px 3px black';
        labelDiv.textContent = annotationText.trim();

        const label = new CSS2DObject(labelDiv);
        label.position.set(newXPosition, origin, newZPosition);
        this.add(label);
        label.visible = false;
        this.label = label;

        const wordsNumber = annotationText.split(' ').length;
        if ( wordsNumber === 1) {
            label.center.set(-0.4, 0.5);
        } else if (wordsNumber === 2) {
            label.center.set(-0.25, 0.5);
        } else {
            label.center.set(-0.15, 0.5);
        }

        const ringGeometry = new THREE.TorusGeometry(0.04, 0.02, 10, 50);
        const ringMaterial = new THREE.MeshBasicMaterial({color: color});

        const ring = new THREE.Mesh(ringGeometry, ringMaterial);
        ring.position.set(newXPosition, origin, newZPosition);

        this.add(ring);
        this.ring = ring;
        ring.rotateX(Math.PI / 2);
        if (!!rotationReference) {
            ring.rotateOnAxis(this.ring.position, (ring.position.angleTo(rotationReference)));
        }

        /*const labelPointGeometry = new THREE.BufferGeometry();
        labelPointGeometry.setAttribute('position', new THREE.Float32BufferAttribute([newXPosition, originPosition, newZPosition], 3));
        const labelPointMaterial = new THREE.PointsMaterial({ size: 3.5, sizeAttenuation: false});
        const labelPoint = new THREE.Points(labelPointGeometry, labelPointMaterial);
        this.add(labelPoint);*/
        //this.point = labelPoint;
    }

    public changeLabelVisibility(visible: boolean) {
        (this.ring.material as THREE.MeshBasicMaterial).visible = visible;
    }
};