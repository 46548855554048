import { CircularProgress, IconButton } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import FlareIcon from '@mui/icons-material/Flare';

import { ILineString } from 'features/seismic/models/interfaces/ILineString';

export function SeismicWell({opened, setOpened, dataGeometryLine}: Props) {
    const { t } = useTranslation();

    if (!dataGeometryLine) {
        return <CircularProgress />;
    }

    return (
        <Fragment>
            <IconButton
                id="wells"
                color='primary'
                onClick={() => setOpened(!opened)}
                title={t('wells')}>
                <FlareIcon />
            </IconButton>
        </Fragment>
    );
}

interface Props {
    opened: boolean;
    setOpened: (value: boolean) => void;
    dataGeometryLine: ILineString | null;
}