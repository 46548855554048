import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { forwardRef, Fragment, useEffect, useState } from 'react';
import alertify from 'alertifyjs';
import { GeopostDropdownInstitutions } from 'fileIndexing/otherComponents/GeopostDropdownInstitutions';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { GeopostDropdown } from 'fileIndexing/otherComponents/GeopostDropdown';
import { GeopostDateInput } from 'fileIndexing/otherComponents/GeopostDateInput';
import { FormContainer } from 'react-hook-form-mui';
import dayjs from 'dayjs';

import { IEntitiesSeismic, IEntitiesWell } from '../interfaces/IEntities';
import { useSaveSeismic, useSaveWell } from '../useEntities';

export const EntitiesForm = ({ setOpenModal, refetchTableData, setLoading, formDataWell, formDataSeismic, formDataType }: Props) => {
    const [dataWell, setDataWell] = useState<IEntitiesWell | null>(null);
    const [dataSeismic, setDataSeismic] = useState<IEntitiesSeismic | null>(null);
    const [file] = useState<File[]>([]);
    const [entityType, setEntityType] = useState<string>(formDataType ? formDataType : 'well');

    const { data: saveWellsData } = useSaveWell(dataWell, file);
    const { data: saveSeismicData } = useSaveSeismic(dataSeismic, file);

    const handleWellData = (data: IEntitiesWell) => {
        let newData = data;
        if (formDataWell) {
            newData.Token = formDataWell.Token;
        }
        else if (formDataSeismic) {
            newData.Token = formDataSeismic.Token;
        }
        else {
            newData.Token = '';
        }

        newData.StartDrillingDate = dayjs(newData.StartDrillingDate).format('DD/MM/YYYY');
        newData.EndDrillingDate = dayjs(newData.EndDrillingDate).format('DD/MM/YYYY');

        setDataWell(newData);
    };

    const handleSeismicData = (data: IEntitiesSeismic) => {
        let newData = data;
        if (formDataWell) {
            newData.Token = formDataWell.Token;
        }
        else if (formDataSeismic) {
            newData.Token = formDataSeismic.Token;
        }
        else {
            newData.Token = '';
        }

        newData.OrderDate = dayjs(newData.OrderDate).format('DD/MM/YYYY');

        setDataSeismic(newData);
    };

    // AVISO: talvez seja retirada essa parte
    function wait(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function executeSave(): Promise<void> {
        setOpenModal(false);
        setLoading(true);
        await wait(2000);
        setLoading(false);
        refetchTableData();
    }

    useEffect(() => {
        if (saveWellsData) {
            if (saveWellsData === '200') {
                executeSave();
            }
            else {
                alertify.notify('Ocorreu um erro criando uma nova Instituição');
            }
        }
    }, [saveWellsData]);

    useEffect(() => {
        if (saveSeismicData) {
            if (saveSeismicData === '200') {
                executeSave();
            }
            else {
                alertify.notify('Ocorreu um erro criando uma nova Instituição');
            }
        }
    }, [saveSeismicData]);

    const geopostDropdownItems = [
        {
            id: 1,
            label: 'Seismic 2D',
        },
        {
            id: 2,
            label: 'Seismic 3D',
        },
        {
            id: 3,
            label: 'Not Seismic',
        },
    ];

    return (
        <Fragment>
            <Box>
                {(!formDataWell && !formDataSeismic) &&
                    <FormControl style={{ marginBottom: '16px'}}>
                        <RadioGroup
                            value={entityType}
                            onChange={(event) => setEntityType(event.target.value)}
                            row
                        >
                            <FormControlLabel value='well' control={<Radio />} label='Well' />
                            <FormControlLabel value='seismic' control={<Radio />} label='Seismic' />
                        </RadioGroup>
                    </FormControl>
                }
                {entityType === 'well' &&
                    <FormContainer<IEntitiesWell> defaultValues={formDataWell ?? {}} onSuccess={(data) => handleWellData(data)}>
                        <Grid spacing={5} container>
                            <Grid spacing={2} container item direction="row">
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'Name'} label={'Name'} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'ANPName'} label={'Anp Name'} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'NickName'} label={'Nickname'} />
                                </Grid>
                            </Grid>
                            <Grid spacing={2} container item direction="row">
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'UWI'} label={'Uwi'} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostDateInput fieldName={'StartDrillingDate'} label={'Start drilling date'} dateType={'DD/MM/YYYY'} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostDateInput fieldName={'EndDrillingDate'} label={'End drilling date'} dateType={'DD/MM/YYYY'} />
                                </Grid>
                            </Grid>
                            <Grid spacing={2} container item direction="row">
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'Classification'} label={'Classification'} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'OperatorName'} label={'Operator'} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'BasinName'} label={'Basin'} />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row-reverse">
                                <Button variant='contained' color='primary' type='submit'>SAVE</Button>
                            </Grid>
                        </Grid>
                    </FormContainer>
                }
                {(entityType === 'seismic' || entityType === 'nonSeismic') &&
                    <FormContainer<IEntitiesSeismic> defaultValues={formDataSeismic ?? {}} onSuccess={(data) => handleSeismicData(data)}>
                        <Grid spacing={2} container>
                            <Grid spacing={2} container item direction="row">
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'Name'} label={'Name'} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'ANPName'} label={'Anp Name'} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'NickName'} label={'Nickname'} />
                                </Grid>
                            </Grid>
                            <Grid spacing={2} container item direction="row">
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'Classification'} label={'Classification'} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'OperatorName'} label={'Operator'} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'BasinName'} label={'Basin'} />
                                </Grid>
                            </Grid>
                            <Grid spacing={2} container item direction="row">
                                <Grid xs={3} item>
                                    <GeopostDateInput fieldName={'OrderDate'} label={'Order Date'} dateType={'DD/MM/YYYY'} />
                                </Grid>
                                <Grid xs={3} item>
                                    <GeopostTextField fieldName={'Contract'} label={'Contract'} />
                                </Grid>
                                <Grid xs={3} item>
                                    <GeopostTextField fieldName={'Responsable'} label={'Responsable'} />
                                </Grid>
                                <Grid xs={3} item>
                                    <GeopostTextField fieldName={'SAPCode'} label={'Sap Code'} />
                                </Grid>
                            </Grid>
                            <Grid spacing={2} container item direction="row">
                                <Grid xs={4} item>
                                    <GeopostTextField fieldName={'ProductName'} label={'Product Name'} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostDropdown fieldName={'SurveyType'} label={'Survey Type'} items={geopostDropdownItems} />
                                </Grid>
                                <Grid xs={4} item>
                                    <GeopostDropdownInstitutions fieldName={'InstitutionToken'} label={'Institution'} />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row-reverse">
                                <Button variant='contained' color='primary' type='submit'>SAVE</Button>
                            </Grid>
                        </Grid>
                    </FormContainer>
                }
            </Box >
        </Fragment >
    );
};

interface Props {
    setOpenModal: (value: boolean) => void;
    refetchTableData: () => void;
    setLoading: (value: boolean) => void;
    formDataWell?: IEntitiesWell | null;
    formDataSeismic?: IEntitiesSeismic | null;
    formDataType?: string | null;
}