import React, { useEffect, useState } from 'react';

import { GeopostCheckbox } from 'components/geopostCheckbox/GeopostCheckbox';
import { FeatureGeom } from 'features/seismic-3d/models/classes/FeatureGeom';
import { Seismic3DData } from 'features/seismic-3d/models/classes/Seismic3DData';
import { useGetGeomByVolumeToken } from '../../../api/useSeismic3DInfoController';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';

interface IIntersectingSeismicCheckboxProps {
    seismicData: Seismic3DData,
    checked: boolean,
    selectCallback: (seismicData: Seismic3DData, seismicGeom : FeatureGeom) => void,
    unselectCallback : () => void
}

export const IntersectingSeismicCheckbox = ({ seismicData, checked, selectCallback, unselectCallback } : IIntersectingSeismicCheckboxProps) => {
    const [volumeTokenToSearch, setVolumeTokenToSearch] = useState<string>();
    //const [isLoading, setIsLoading] = useState<boolean>(false);
    const { data: seismicGeomData, isLoading} = useGetGeomByVolumeToken(volumeTokenToSearch);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const selectedSeismics = use3DViewerStore(state => state.selectedSeismics);

    useEffect(() => {
        setIsChecked(selectedSeismics.some(selectedSeismic => selectedSeismic.volumeToken === seismicData.volumeToken));
    }, selectedSeismics);

    useEffect(() => {
        if (seismicGeomData) {
            selectCallback(seismicData, seismicGeomData);
        }
    }, [seismicGeomData]);

    return (
        <>
            <GeopostCheckbox
                checked={checked}
                loading={isLoading}
                onSelect={() => setVolumeTokenToSearch(seismicData.volumeToken)}
                onUnselect={() => {
                    unselectCallback();
                    setVolumeTokenToSearch(undefined);
                }}
            />
        </>
    );
};