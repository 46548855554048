import { Button, Grid, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import alertify from 'alertifyjs';
import { FormContainer } from 'react-hook-form-mui';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { GeopostDropdown } from 'fileIndexing/otherComponents/GeopostDropdown';
import { GeopostDropdownInstitutions } from 'fileIndexing/otherComponents/GeopostDropdownInstitutions';

import { ISubRule } from '../interfaces/ITaxonomy';
import { useSaveDataSubRule } from '../useTaxonomy';

export const RegexRuleForm = ({ setOpenModal, refetchTableData, dataSubGroupToken, formData }: Props) => {
    const [data, setData] = useState<ISubRule | null>(null);

    const [min, setMin] = useState<string>('');
    const [max, setMax] = useState<string>('');

    const { data: saveDataSubRule } = useSaveDataSubRule(data);

    const handleData = (data: ISubRule) => {
        let newData = data;
        if (formData) {
            newData.Token = formData.Token;
        }
        else {
            newData.Token = '';
        }

        newData.DataSubGroupToken = dataSubGroupToken;

        setData(newData);
    };

    // salva as informações do popup
    useEffect(() => {
        if (saveDataSubRule) {
            if (saveDataSubRule === 'Success') {
                setOpenModal(false);
                refetchTableData();
            }
            else {
                alertify.notify('Ocorreu um erro criando uma nova Instituição');
            }
        }
    }, [saveDataSubRule]);

    const geopostDropdownItems = [
        {
            id: 1,
            label: 'Folder',
        },
        {
            id: 2,
            label: 'File Name',
        },
        {
            id: 3,
            label: 'File Extension',
        },
    ];

    const context = useForm<ISubRule>({ defaultValues: formData || null || undefined });

    const minFileSize: number | null = context.watch('MinFileSize');
    const maxFileSize: number | null = context.watch('MaxFileSize');

    const formatFileSize = (sizeInBytes: number | null) => {
        if (sizeInBytes && sizeInBytes >= 0) {
            let fileSize = sizeInBytes / (1024 ** 3);
            let extent = ' GB';

            if (fileSize < 1) {
                fileSize = sizeInBytes / (1024 ** 2);
                extent = ' MB';
            }

            return fileSize.toFixed(2) + extent;
        }
        return '';
    };

    useEffect(() => {
        setMin(formatFileSize(minFileSize));
    }, [minFileSize]);

    useEffect(() => {
        setMax(formatFileSize(maxFileSize));
    }, [maxFileSize]);

    return (
        <Fragment>
            <FormContainer<ISubRule> defaultValues={formData ?? {}} formContext={context} onSuccess={(data) => handleData(data)} FormProps={{ style:{ width: '100%' } }}>
                <Grid spacing={2} container direction='column'>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'Rule'} label={'Rule Regex'} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'ExclusionRegex'} label={'Exclusion Regex'} required={false} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'Priority'} label={'Rule Priority'} textFieldProps={{ type: 'number' }} />
                    </Grid>
                    <Grid container item alignItems={'center'} spacing={2}>
                        <Grid xs={8} item>
                            <GeopostTextField fieldName={'MinFileSize'} label={'Min File Size'} textFieldProps={{ type: 'number' }} />
                        </Grid>
                        <Grid xs={4} item>
                            <Typography>{min}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item alignItems={'center'} spacing={2}>
                        <Grid xs={8} item>
                            <GeopostTextField fieldName={'MaxFileSize'} label={'Max File Size'} textFieldProps={{ type: 'number' }} />
                        </Grid>
                        <Grid xs={4} item>
                            <Typography>{max}</Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostDropdown fieldName={'RuleType'} label={'Rule Type'} items={geopostDropdownItems} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostDropdownInstitutions fieldName={'InstitutionToken'} label={'Institutions'} required={false} />
                    </Grid>
                    <Grid container item direction='row-reverse'>
                        <Button variant='contained' color='primary' type='submit'>SAVE</Button>
                    </Grid>
                </Grid>
            </FormContainer>
        </Fragment>
    );
};

interface Props {
    setOpenModal: (value: boolean) => void;
    refetchTableData: () => void;
    dataSubGroupToken: string;
    formData?: ISubRule | null;
}