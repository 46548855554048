import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { Fragment, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import alertify from 'alertifyjs';
import { GeopostDropdown } from 'fileIndexing/otherComponents/GeopostDropdown';

import { IEndpoint } from '../interfaces/ITaxonomy';
import { useSaveEndpoints } from '../useTaxonomy';

export function EndpointsForm({ setOpenModal, refetchTableData, subGroupToken, formData }: Props) {
    const formDataProps = useForm<IEndpoint>({
        defaultValues: formData || undefined
    });

    const [data, setData] = useState<IEndpoint | null>(null);

    const { data: saveEndpoint } = useSaveEndpoints(data);

    const handleData = (data: IEndpoint) => {
        let newData = data;
        if (formData) {
            newData.EndpointToken = formData.EndpointToken;
        } else {
            newData.EndpointToken = '';
        }

        newData.SubGroupToken = subGroupToken;

        setData(newData);
    };

    async function executeSave() {
        setOpenModal(false);
        refetchTableData();
    }

    useEffect(() => {
        if (saveEndpoint) {
            if (saveEndpoint === 'Success') {
                executeSave();
            } else {
                alertify.notify('An error occurred when creating a new Endpoint');
            }
        }
    }, [saveEndpoint]);

    const endpointTypeItems = [
        {
            id: 1,
            label: 'Viewing'
        },
        {
            id: 2,
            label: 'Indexing'
        }
    ];

    return (
        <Fragment>
            <FormContainer<IEndpoint> defaultValues={formData ?? {}} onSuccess={(data) => handleData(data)} FormProps={{ style: { width: '100%' } }}>
                <Grid spacing={2} container direction='column'>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'Url'} label={'Url'} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'Label'} label={'Label'} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'RestrictedExtensions'} label={'Restricted Extensions'} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostDropdown fieldName={'EndpointType'} label={'Endpoint Type'} items={endpointTypeItems} />
                    </Grid>
                    <Grid container item direction='row-reverse'>
                        <Button variant='contained' color='primary' type='submit' style={{ marginBottom: '16px' }}>SAVE</Button>
                    </Grid>
                </Grid>
            </FormContainer>
        </Fragment>
    );
}

interface Props {
    setOpenModal: (value: boolean) => void;
    refetchTableData: () => void;
    subGroupToken: string;
    formData?: IEndpoint | null;
}