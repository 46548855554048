import { Fragment, useEffect } from 'react';

import { SeismicNavbar } from 'features/seismic/components/SeismicNavbar';
import { SeismicPanel } from 'features/seismic/components/SeismicPanel';
import './SeismicViewer.css';

export function SeismicViewer() {
    return (
        <Fragment>
            <SeismicNavbar/>
            <SeismicPanel />
        </Fragment>
    );
}