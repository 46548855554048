import { Fragment, useEffect, useRef, useState } from 'react';
import { Box, Paper, Popover, useTheme } from '@mui/material';
import Draggable from 'react-draggable';

import { IGeopostMuiPopupProps } from './interfaces/IGeopostMuiPopupProps';
import { GeopostPopupHeader } from 'components/geopostPopup/components/GeopostPopupHeader';

export function GeopostMuiPopup({id, open, onClose, children, height, width, title = '', top = 0, left = 0} : IGeopostMuiPopupProps) {
    const popoverAnchorRef = useRef<Element>(null);
    const theme = useTheme();
    const [ isDragging, setIsDragging ] = useState<boolean>(false);

    return (
        <Fragment>
            {
                isDragging
                &&
                <div style={{position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, zIndex: 9999}}></div>
            }
            {
                open
                &&
                <Draggable handle='#mui-popup-draggable-handler' cancel='.no-drag' onStart={() => setIsDragging(true)} onStop={() => setIsDragging(false)}>
                    <Box
                        id={id}
                        sx={{
                            overflow: 'hidden',
                            position: 'absolute',
                            top: top,
                            left: left,
                            backgroundColor: '#FFF',
                            borderRadius: '5px',
                            zIndex:999
                        }}
                    >
                        <Box padding='1px'>
                            <Box id='mui-popup-draggable-handler'>
                                <GeopostPopupHeader title={title} onChangeOpen={onClose} />
                            </Box>
                        </Box>
                        <Paper sx={{
                            height: height,
                            width: width,
                            boxSizing: 'border-box',
                            overflow: 'auto',
                            padding: '5px',
                            backgroundColor: '#FFF'
                        }}>
                            {children}
                        </Paper>
                    </Box>
                </Draggable>
            }
        </Fragment>
    );
}