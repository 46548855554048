import * as THREE from 'three';
import { BufferAttribute } from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { BufferGeometry, PointsMaterial, TubeGeometry } from 'three/src/Three';

import { GeopostThreeJsConstants } from '../utils/GeopostThreeJsConstants';
import { getScaled2DCoordinate } from '../utils/ScaleUtils';
import { getPointInWellPathByDepth, getPointInWellPathByY } from 'features/seismic-3d/components/utils/AnnotationUtils';

class CrossingPointThreeJsCurve extends THREE.CurvePath<THREE.Vector3> {
    public constructor(curves: THREE.Curve<THREE.Vector3>[]){
        super();
        curves.forEach(curve => this.add(curve));
    }
}

export class GeopostWellCrossingPointMesh extends THREE.Group {
    public label: CSS2DObject;
    public points: THREE.Vector3[];

    public constructor(wellName: string, heightFactor: number, points: THREE.Vector3[], platformPoint: number[], color: string, mainFeatureCentroidX: number, mainFeatureCentroidY: number ) {

        super();

        const material = new THREE.MeshBasicMaterial({ color: color} );

        const wellPathMeshes : THREE.Mesh[] = [];

        if (points.length === 2) {

            const curve = new THREE.LineCurve3(points[0], points[1]);
            const geometry = new THREE.TubeGeometry(curve, 20, 0.007, 100, false);
            wellPathMeshes.push(new THREE.Mesh(geometry, material));
            this.points = curve.getPoints(20);
        } else {
            const curve = new THREE.CatmullRomCurve3(points);
            const geometry = new THREE.TubeGeometry(curve, 400, 0.007, 100, false);
            wellPathMeshes.push(new THREE.Mesh(geometry, material));
            this.points = curve.getPoints(20);
        }

        const [scaledPlatformX, scaledPlatformZ] = getScaled2DCoordinate([platformPoint[0], platformPoint[2]], mainFeatureCentroidX, mainFeatureCentroidY);
        const platformLine = new THREE.LineCurve3(new THREE.Vector3(scaledPlatformX, 0, scaledPlatformZ), points[0]);
        const platformPathGeometry = new THREE.TubeGeometry(platformLine, 20, 0.007, 100, false);
        const platformPathMesh = new THREE.Mesh(platformPathGeometry, material);

        this.add(...wellPathMeshes, platformPathMesh);
        this.name = GeopostWellCrossingPointMesh.getWellCrossingPointObjectName();

        const labelDiv = document.createElement('div');
        labelDiv.className = '';
        labelDiv.style.backgroundColor = 'transparent';
        labelDiv.style.color = 'white';
        labelDiv.style.fontSize = '20px';
        //labelDiv.style.marginLeft = '120px';
        labelDiv.style.textShadow = '2px 2px 3px black';
        labelDiv.textContent = wellName.trim();

        const label = new CSS2DObject(labelDiv);

        const labelDepth = points[points.length - 1].y / 2;
        const labelPoint = getPointInWellPathByY(labelDepth, points, heightFactor);

        label.position.set(labelPoint.x, labelPoint.y, labelPoint.z);
        this.add(label);
        label.visible = false;
        this.label = label;

    }

    private static getWellCrossingPointObjectName = () => {
        return `crossing-point`;
    };

    public changeCrossingPointVisibility = (visibility: boolean) => {
        const mesh = this.getObjectByName('crossing-point');
        if (!!mesh) {
            mesh.visible = visibility;
        }
    };
}
