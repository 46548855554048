import { Box, css } from '@mui/material';
import { CheckboxElement } from 'react-hook-form-mui';

const style = css({
    width: '100%',
    position: 'relative',
});

export const GeopostCheckbox = ({ fieldName, label }: Props) => {
    return (
        <Box css={style}>
            <CheckboxElement
                label={label}
                name={fieldName}
            />
        </Box>
    );
};

interface Props {
    fieldName: string;
    label: string;
}