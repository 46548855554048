import { IconButton } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from 'session/useSessionStore';
import { Interpretation } from 'components/icons/components';

export function SeismicInterpretationNavbar({ opened, setOpened }:Props) {
    const { t } = useTranslation();
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    const className = (opened) ? 'active' : '';

    return (
        <Fragment>
            <IconButton
                id='interpretation'
                style={{color:tenantConfig?.main.palette.primary.contrastText}}
                className={className}
                onClick={() => setOpened(!opened)}
                title={t('interpretation.tools')}>
                <Interpretation />
            </IconButton>
        </Fragment>
    );
}

interface Props {
    opened: boolean;
    setOpened: (value: boolean) => void
}