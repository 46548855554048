import { create } from 'zustand';

import { TenantConfig } from 'features/tenant/model/TenantConfig';

export interface ISessionStore{
    jwtToken:string;
    setJwtToken:(value:string) => void;
    tenantConfiguration:TenantConfig|undefined;
    setTenantConfiguration:(value:TenantConfig|undefined) => void;
}
export const useSessionStore = create<ISessionStore>()(
    (set,get) => ({
        tenantConfiguration: undefined,
        setTenantConfiguration: (value :TenantConfig|undefined) => set({
            tenantConfiguration: value
        }),
        jwtToken: '',
        setJwtToken: (value :string) => set({
            jwtToken: value
        })
    })
);
