
import { fromExtent } from 'ol/geom/Polygon';
import * as THREE from 'three';
import WKT from 'ol/format/WKT';

import { ISeismicViewer3DMap } from 'features/seismic-3d/models/interfaces/ISeismicViewer3DMap';
import { getGridScaledExtent } from '../utils/ScaleUtils';

export class GeopostGridGeometry extends THREE.PlaneGeometry {
    public transformedExtent: number[];

    public constructor(map: ISeismicViewer3DMap, mainFeatureCentroidX : number, mainFeatureCentroidY: number) {
        const transformedExtent = getGridScaledExtent(map.GeomWkt, mainFeatureCentroidX, mainFeatureCentroidY);
        const width = transformedExtent[2] - transformedExtent[0];
        const height = transformedExtent[3] - transformedExtent[1];

        super(width, height, 500, 500);
        this.transformedExtent = transformedExtent;
    };
}