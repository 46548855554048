import React, { useState } from 'react';
import { Box, Button, Collapse, FormControlLabel, IconButton, styled, Switch, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { GeopostCheckbox } from 'components/geopostCheckbox/GeopostCheckbox';
import { FeatureGeom } from 'features/seismic-3d/models/classes/FeatureGeom';
import { Seismic3DData } from 'features/seismic-3d/models/classes/Seismic3DData';
import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';
import { IntersectingSeismicCheckbox } from './IntersectingSeismicsList/IntersectingSeismicCheckbox';

export type SeismicsListRowProps = {
    seismic: Seismic3DData,
    onSelect: (seismicData: Seismic3DData, seismicGeom: FeatureGeom) => void,
    onUnselect: () => void,
    checked: boolean,
    gridChecked: boolean,
    onGridCheckedChange: (checked: boolean) => void
};

export const SeismicsListRow = ({ seismic, checked, onSelect, onUnselect, gridChecked, onGridCheckedChange } : SeismicsListRowProps) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    return (
        <React.Fragment>
            <TableRow
                hover
                sx={{ '& > *': { borderBottom: 'unset' } }}
            >
                <TableCell size='small'>
                    <IconButton
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        {collapsed ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell align='center' padding='checkbox'>
                    <IntersectingSeismicCheckbox
                        checked ={ checked }
                        seismicData={ seismic }
                        selectCallback={ onSelect }
                        unselectCallback={ onUnselect }
                    />
                </TableCell>
                <TableCell size='small' title={seismic.surveyName}>{seismic.surveyName.length > 25 ? seismic.surveyName.substring(0, 25) + '...' : seismic.surveyName}</TableCell>
                <TableCell size='small'>
                    <Switch
                        checked={gridChecked}
                        onChange={(event, checked) => onGridCheckedChange(checked)}
                    />
                </TableCell>
            </TableRow>
            <TableRow >
                <TableCell  style={{paddingBottom: 0, paddingTop: 0}} colSpan={4} >
                    <Collapse in={collapsed}>
                        <Box paddingBottom={'5px'}>
                            <Table size='small'>
                                <TableHead>
                                    <TableCell size='small'>Traces number</TableCell>
                                    <TableCell size='small'>Samples per trace</TableCell>
                                    <TableCell size='small'>Sample Interval</TableCell>
                                </TableHead>
                                <TableBody>
                                    <TableCell size='small'>{seismic.tracesNumber}</TableCell>
                                    <TableCell size='small'>{seismic.samplesPerTrace}</TableCell>
                                    <TableCell size='small'>{seismic.sampleInterval}</TableCell>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
} ;