import { useCallback, useEffect, useRef } from 'react';

import { axios } from 'configurations/axios';
import { useSessionStore } from 'session/useSessionStore';
import { useLine3DNavigationStore} from '../stores/useLine3DNavigationStore';
import { useMapStore} from '../stores/useMapStore';
import {IRangeTrace, IScale, useSeismicStore} from '../stores/useSeismicStore';
import {LineType} from '../models/enums/LineType';
import {IResponseAPI} from 'models/interfaces/IResponseAPI';
import { SeismicCalculatorFactory } from '../models/classes/SeismicCalculatorFactory';
import { getParamsFromUrl } from '../utils/seismicUrlUtils';
import { useTileSize } from './useTileSize';
import { usePrepareRender } from './usePrepareRender';
import { SurveyType } from '../models/enums/SurveyType';
import { IGeopostSeismicLayerParams } from '../models/interfaces/IGeopostSeismicLayerParams';
import { ZSliceTileSourceStrategy } from '../models/classes/TileSourceStrategies/ZSliceTileSourceStrategy';
import { LineTileSourceStrategy } from '../models/classes/TileSourceStrategies/LineTileSourceStrategy';
import { useGetSliceRotation } from './useGetSliceRotation';
import { ISurveyMetadata } from '../models/interfaces/ISurveyMetadata';
import { useZSliceStore } from '../stores/useZSliceStore';
import { getSurveyExtentDistance } from '../utils/calculateScreenValuesUtils';
import { IResolutionsResponse } from '../models/interfaces/IResolutionsResponse';

export function useUpdateLayer(){
    const urlParams = getParamsFromUrl();
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));

    const jwt = useSessionStore(state => state.jwtToken);
    const flipedByDefaultRef = useRef(false);

    const { lineNumber, lineType, setLineNumber, setLineIdentifier } = useLine3DNavigationStore(state => ({
        lineNumber: state.lineNumber,
        lineType: state.lineType,
        setLineIdentifier: state.setLineIdentifier,
        setLineNumber: state.setLineNumber
    }));

    const { mainSeismicLayer, swipeSeismic: swipeSeismicLayer, overlaySeismic: overlaySeismicLayer, mapSize, map, resolutions, debugLayer, prepareRenderLimit } = useMapStore(state => ({
        debugLayer: state.debugLayer,
        mainSeismicLayer: state.mainSeismicLayer,
        map: state.map,
        mapSize: state.mapSize,
        resolutions: state.resolutions,
        overlaySeismic: state.overlaySeismic,
        swipeSeismic: state.swipeSeismic,
        prepareRenderLimit: state.preparedTraceLimit
    }));

    const getSliceRotation = useGetSliceRotation();

    const tileSize = useTileSize();

    const { volumeToken, calculator,  volumeTokenSwipe, swipeValue, volumeTokenOverlay, surveyMetadataOverlay, isInverted, isAntialiasApplied,
        surveyMetadata, scale, colorbar, /*gain,*/ absoluteGain, rangeTrace, surveyMetadataSwipe, setIsSeismicLoading, setCalculator, setIsVerticallyInverted } = useSeismicStore(state => ({
        calculator: state.calculator,
        isInverted: state.isInverted,
        isAntialiasApplied: state.isAntialiasApplied,
        colorbar: state.colorbar,
        volumeToken: state.volumeToken,
        volumeTokenOverlay: state.volumeTokenOverlay,
        volumeTokenSwipe: state.volumeTokenSwipe,
        //gain: state.gain,
        rangeTrace: state.rangeTrace,
        scale: state.scale,
        surveyMetadata: state.surveyMetadata,
        absoluteGain: state.absoluteGain,
        surveyMetadataOverlay: state.surveyMetadataOverlay,
        surveyMetadataSwipe: state.surveyMetadataSwipe,
        swipeValue: state.swipeValue,
        setIsSeismicLoading: state.setIsLoading,
        setCalculator: state.setCalculator,
        setIsVerticallyInverted: state.setIsVerticallyInverted
    }));

    const prepareRender = usePrepareRender();

    const lastLineTypeRef = useRef<LineType | null>(null);
    const lastLineNumberRef = useRef<number | null>(null);

    const lineTypeFirstUpdatedRef = useRef(false);

    const updateMainSeismicLayer = useCallback(async (doRenderPreparing: boolean = true) => {
        //map?.updateSize();
        if (mainSeismicLayer){
            let newAbsoluteGain = absoluteGain;
            const mainSeismicLayerParams : IGeopostSeismicLayerParams = {
                ...mainSeismicLayer.getParams(),
                colorbar: colorbar?.Name,
                isInverted: flipedByDefaultRef.current !== isInverted /* <---- OR Exclusivo */,
                isAntialiasApplied: isAntialiasApplied,
                lineNumber: lineNumber,
                surveyInlineStart: surveyMetadata?.Survey3DInfo.InlineStart!,
                surveyInlineEnd: surveyMetadata?.Survey3DInfo.InlineEnd!,
                surveyXlineStart: surveyMetadata?.Survey3DInfo.XlineStart!,
                surveyXlineEnd: surveyMetadata?.Survey3DInfo.XlineEnd!,
                surveyInlineIncrement: surveyMetadata?.Survey3DInfo.InlineIncrement!,
                surveyXlineIncrement: surveyMetadata?.Survey3DInfo.XlineIncrement!,
                numberOfTraces: surveyMetadata?.Header.NumberOfTraces,
                absoluteGain: newAbsoluteGain,
                volumeToken: volumeToken ? volumeToken : '',
            };
            if (doRenderPreparing) {
                mainSeismicLayerParams.renderLimits = [];
            }
            if (urlParams.forceDisableSamplesCache || !tenantConfig?.useSamplesMatrixCache) {
                mainSeismicLayerParams.renderLimits = [[-Infinity, +Infinity]];
            }

            mainSeismicLayer.updateParams(mainSeismicLayerParams).reload();
            if (doRenderPreparing && tenantConfig?.useSamplesMatrixCache && !urlParams.forceDisableSamplesCache) {
                //setIsSeismicLoading(true);

                mainSeismicLayer.updateParams({
                    renderLimits: []
                }, false);
                prepareRender(mainSeismicLayer, {
                    ...mainSeismicLayerParams,
                    lineNumber,
                    volumeToken: volumeToken ?? surveyMetadata?.VolumeToken!,
                }, mainSeismicLayerParams.loadingLayer!, false, lineType);
            }

        }
    }, [mainSeismicLayer, absoluteGain, colorbar?.Name, isInverted, isAntialiasApplied, lineNumber, surveyMetadata?.Survey3DInfo.InlineStart, surveyMetadata?.Survey3DInfo.InlineEnd, surveyMetadata?.Survey3DInfo.XlineStart, surveyMetadata?.Survey3DInfo.XlineEnd, surveyMetadata?.Survey3DInfo.InlineIncrement, surveyMetadata?.Survey3DInfo.XlineIncrement, surveyMetadata?.VolumeToken, volumeToken, urlParams.forceDisableSamplesCache, tenantConfig?.useSamplesMatrixCache, prepareRender, lineType]);

    useEffect(() => {
        updateMainSeismicLayer(false);
    }, [colorbar?.Name, /*gain,*/ absoluteGain, isInverted, isAntialiasApplied]);

    useEffect(() => {
        if (!!surveyMetadata && !!lineNumber) {
            if (!lastLineNumberRef.current) {
                lastLineNumberRef.current = lineNumber;
                return;
            }
            updateMainSeismicLayer();
        }
    }, [surveyMetadata, lineNumber]);

    useEffect(() => {
        if (overlaySeismicLayer && volumeTokenOverlay !== '-') {
            overlaySeismicLayer.setOpacity(0.25);
            overlaySeismicLayer.updateParams({
                colorbar: 'jet',
                lineNumber: lineNumber,
                isInverted: isInverted,
                isAntialiasApplied: isAntialiasApplied,
                //absoluteGain: gain,
                absoluteGain: {
                    min: surveyMetadataOverlay?.MinSampleValue ?? 1500,
                    max: surveyMetadataOverlay?.MaxSampleValue ?? 5000,
                },
                volumeToken: surveyMetadataOverlay?.VolumeToken ? surveyMetadataOverlay?.VolumeToken : '',
                sampleInterval:  surveyMetadataOverlay?.Header.SampleInterval,
                surveyInlineStart: surveyMetadataOverlay?.Survey3DInfo.InlineStart,
                surveyInlineEnd: surveyMetadataOverlay?.Survey3DInfo.InlineEnd,
                surveyXlineStart: surveyMetadataOverlay?.Survey3DInfo.XlineStart,
                surveyXlineEnd: surveyMetadataOverlay?.Survey3DInfo.XlineEnd,
                surveyInlineIncrement: surveyMetadataOverlay?.Survey3DInfo.InlineIncrement,
                surveyXlineIncrement: surveyMetadataOverlay?.Survey3DInfo.XlineIncrement,
                numberOfTraces: surveyMetadataOverlay?.Header.NumberOfTraces,
            }).reload();
            overlaySeismicLayer.setVisible(true);
        } else if (overlaySeismicLayer) {
            overlaySeismicLayer.setVisible(false);
        }
    }, [surveyMetadataOverlay, lineNumber, volumeTokenOverlay]);

    useEffect(() => {
        setLineIdentifier(lineType, lineNumber);

        if (swipeSeismicLayer && volumeTokenSwipe !== '-') {
            swipeSeismicLayer.setVisible(true);
            swipeSeismicLayer.updateParams({
                isSwiped: true,
                lineNumber: lineNumber,
                isInverted: isInverted,
                isAntialiasApplied: isAntialiasApplied,
                volumeToken: surveyMetadataSwipe?.VolumeToken ? surveyMetadataSwipe?.VolumeToken : '',
                sampleInterval:  surveyMetadataSwipe?.Header.SampleInterval,
                surveyInlineStart: surveyMetadataSwipe?.Survey3DInfo.InlineStart,
                surveyInlineEnd: surveyMetadataSwipe?.Survey3DInfo.InlineEnd,
                surveyXlineStart: surveyMetadataSwipe?.Survey3DInfo.XlineStart,
                surveyXlineEnd: surveyMetadataSwipe?.Survey3DInfo.XlineEnd,
                surveyInlineIncrement: surveyMetadataSwipe?.Survey3DInfo.InlineIncrement,
                surveyXlineIncrement: surveyMetadataSwipe?.Survey3DInfo.XlineIncrement,
                minSampleValue: surveyMetadataSwipe?.MinSampleValue,
                maxSampleValue: surveyMetadataSwipe?.MaxSampleValue,
                numberOfTraces: surveyMetadataSwipe?.Header.NumberOfTraces,
                absoluteGain: {
                    min: surveyMetadataSwipe?.MinSampleValue ?? -100,
                    max: (surveyMetadataSwipe?.MinSampleValue ?? -100) * -1,
                },
            }).reload();
        } else if (swipeSeismicLayer) {
            swipeSeismicLayer.setVisible(false);
        }
    }, [surveyMetadataSwipe, lineNumber, volumeTokenSwipe, isInverted, isAntialiasApplied]);

    /*
    useEffect(() => {
        if (swipeSeismic && volumeTokenSwipe !== '-') {
            swipeSeismic.updateParams({
                swipeValue: swipeValue
            });
            swipeSeismic
        }
    }, [swipeValue]);
    */

    useEffect(() => {
        if (mainSeismicLayer && map && surveyMetadata && calculator){
            const extent = calculator.calculateExtent(surveyMetadata, scale, rangeTrace);
            const center = calculator.calculateCenter(extent, surveyMetadata, scale, rangeTrace);

            getSliceRotation(lineType, surveyMetadata).then(
                ({ needsFlip, rotation, reference: rotationReference, needsVerticalFlip }) => {
                    map.updateParams({
                        center: center,
                        extent: extent
                    }).rebuildView();

                    mainSeismicLayer.updateParams({
                        scaleX: scale.x,
                        scaleY: scale.y,
                        extent: extent,
                        traceRangeEnd: rangeTrace.end,
                        traceRangeStart: rangeTrace.initial,
                        verticallyInverted: needsVerticalFlip,
                    }).rebuildSource();

                    overlaySeismicLayer?.updateParams({
                        scaleX: scale.x,
                        scaleY: scale.y,
                        extent: extent,
                        traceRangeEnd: rangeTrace.end,
                        traceRangeStart: rangeTrace.initial,
                        verticallyInverted: needsVerticalFlip,
                    }).rebuildSource();

                    swipeSeismicLayer?.updateParams({
                        scaleX: scale.x,
                        scaleY: scale.y,
                        extent: extent,
                        traceRangeEnd: rangeTrace.end,
                        traceRangeStart: rangeTrace.initial,
                        verticallyInverted: needsVerticalFlip,
                    }).rebuildSource();

                    map.getView().adjustRotation(rotation, rotationReference);
                    map.getView().setCenter(center);

                    if (debugLayer){
                        debugLayer.updateTileGrid(mainSeismicLayer.tileGrid!);
                    }
                }
            );
        }
    }, [scale.x, scale.y, rangeTrace]);

    useEffect(() => {

        if (!surveyMetadata || !mainSeismicLayer || !map || lineType === LineType.Line2D){
            return;
        }
        //return;

        const newCalculator = SeismicCalculatorFactory.buildCalculator(surveyMetadata.IsIndexed, lineType, surveyMetadata.Type);

        const traceSkip = newCalculator?.getSkipTrace(surveyMetadata);

        SeismicCalculatorFactory.updateLineTypeCalculator(newCalculator!, lineType);

        let rotated = false;
        setIsSeismicLoading(true);
        getSliceRotation(lineType, surveyMetadata).then(
            ({ needsFlip, rotation, reference: rotationReference, needsVerticalFlip }) => {
                flipedByDefaultRef.current = false;
                if (needsFlip) {
                    flipedByDefaultRef.current = true;
                }
                rotated = rotation % Math.PI !== 0;

                mainSeismicLayer.rebuildSource();

                axios.get<IResponseAPI<IResolutionsResponse>>(`${tenantConfig?.endpoints.metadata}/Seismic/SeismicResolution/Get`, {
                    params: {
                        tileWidth: tileSize.width,
                        screenHeight: mapSize.height,
                        totalSamples: lineType !== LineType.ZSlice ? surveyMetadata.Header.SamplesPerTrace : rotated ? getSurveyExtentDistance(surveyMetadata) : surveyMetadata.Survey3DInfo.TotalXlines,
                        scaleY: scale.y,
                        traceSkip: traceSkip,
                    }
                }).then(async response => {
                    const resolutions = response.data.Result.Resolutions;
                    const tileDimensionsPerResolution = response.data.Result.TileDimensions;
                    const { lineNumber: lineNumberParam, lineType: lineTypeParam } = getParamsFromUrl();
                    const lineNumber = !!lineNumberParam && lineTypeParam === lineType ? lineNumberParam : newCalculator!.calculateLineStart(surveyMetadata);
                    const extent = newCalculator!.calculateExtent(surveyMetadata, scale, rangeTrace);
                    const center = newCalculator!.calculateCenter(extent, surveyMetadata, scale, rangeTrace);
                    const lineIncrement = newCalculator!.getLineIncrement(surveyMetadata);
                    if (lineType === LineType.ZSlice) {
                        const zSliceTileSourceStrategy = new ZSliceTileSourceStrategy(tenantConfig!.endpoints.render, surveyMetadata.Survey3DInfo.XlineStart!, surveyMetadata.Survey3DInfo.XlineIncrement!);
                        zSliceTileSourceStrategy.onSettleTileRequest = mainSeismicLayer.tileSourceStrategy.onSettleTileRequest;
                        mainSeismicLayer.setTileSourceStrategy(zSliceTileSourceStrategy);
                        overlaySeismicLayer?.setTileSourceStrategy(zSliceTileSourceStrategy);
                        swipeSeismicLayer?.setTileSourceStrategy(zSliceTileSourceStrategy);
                    } else {
                        const lineTileSourceStrategy = new LineTileSourceStrategy(lineType, tenantConfig!.endpoints.render);
                        lineTileSourceStrategy.onSettleTileRequest = mainSeismicLayer.tileSourceStrategy.onSettleTileRequest;
                        mainSeismicLayer.setTileSourceStrategy(lineTileSourceStrategy);
                        overlaySeismicLayer?.setTileSourceStrategy(lineTileSourceStrategy);
                        swipeSeismicLayer?.setTileSourceStrategy(lineTileSourceStrategy);
                    }

                    map.updateParams({
                        center: center,
                        extent: extent,
                        resolutions: resolutions
                    }).rebuildView();

                    const surveyLineStart = lineType === LineType.Xline ? surveyMetadata.Survey3DInfo.InlineStart : surveyMetadata.Survey3DInfo.XlineStart;
                    const surveyLineEnd = lineType === LineType.Xline ? surveyMetadata.Survey3DInfo.InlineEnd : surveyMetadata.Survey3DInfo.XlineEnd;

                    const mainSeismicLayerParams = {
                        ...mainSeismicLayer.getParams(),
                        extent: extent,
                        isInverted: lineType === LineType.ZSlice ? true : isInverted,
                        isAntialiasApplied: isAntialiasApplied,
                        lineType: lineType,
                        lineNumber: lineNumber,
                        sourceApi: urlParams.sourceApi,
                        volumeToken: volumeToken ? volumeToken : '',
                        resolutions: resolutions,
                        tileDimensionsPerResolution: tileDimensionsPerResolution,
                        lineIncrement: lineIncrement,
                        surveyLineStart: surveyLineStart,
                        surveyLineEnd: surveyLineEnd,
                        sampleInterval: surveyMetadata.Header.SampleInterval,
                        numberOfTraces: surveyMetadata.Header.NumberOfTraces,
                        lineStartLimit: surveyLineStart,
                        lineEndLimit: surveyLineStart,
                        verticallyInverted: needsVerticalFlip,
                    };

                    mainSeismicLayer.updateParams(mainSeismicLayerParams).rebuildSource();

                    if (overlaySeismicLayer && surveyMetadataOverlay) {
                        overlaySeismicLayer?.updateParams({
                            extent: extent,
                            isInverted: isInverted,
                            isAntialiasApplied: isAntialiasApplied,
                            lineNumber: lineNumber,
                            numberOfTraces: surveyMetadataOverlay.Header.NumberOfTraces,
                            sourceApi: urlParams.sourceApi,
                            volumeToken: volumeToken ? volumeToken : '',
                            resolutions: resolutions,
                            sampleInterval: surveyMetadataOverlay?.Header.SampleInterval,
                        }).rebuildSource();
                    }

                    if (swipeSeismicLayer && surveyMetadataSwipe) {
                        swipeSeismicLayer?.updateParams({
                            extent: extent,
                            isInverted: isInverted,
                            isAntialiasApplied: isAntialiasApplied,
                            lineNumber: lineNumber,
                            numberOfTraces: surveyMetadataSwipe.Header.NumberOfTraces,
                            sourceApi: urlParams.sourceApi,
                            volumeToken: volumeToken ? volumeToken : '',
                            resolutions: resolutions,
                            sampleInterval: surveyMetadataSwipe?.Header.SampleInterval,
                        }).rebuildSource();
                    }

                    if (debugLayer){
                        debugLayer.updateTileGrid(mainSeismicLayer.tileGrid!);
                    }

                    lastLineTypeRef.current = lineType;
                    lastLineNumberRef.current = lineNumber;

                    setLineNumber(lineNumber);
                    setLineIdentifier(lineType, lineNumber);
                    setCalculator(newCalculator);

                    map.getView().setRotation(rotation);
                    map.getView().setCenter(center);
                    setIsSeismicLoading(false);
                    setIsVerticallyInverted(needsVerticalFlip);

                });
            }
        );
    }, [lineType]);

    useEffect(() => {
        mainSeismicLayer?.updateParams({
            accessToken: jwt
        });
        swipeSeismicLayer?.updateParams({
            accessToken: jwt
        });
        overlaySeismicLayer?.updateParams({
            accessToken: jwt
        });
    }, [jwt]);

    useEffect(() => {
        if (lineType === LineType.ZSlice && !!map) {
            mainSeismicLayer?.rebuildSource();
            const currentRotation = map.getView().getRotation();
            if (isInverted) {
                map?.getView().setRotation(currentRotation * -1);
            }
            else {
                if (currentRotation > 0) {
                    map.getView().setRotation(currentRotation * -1);
                }
            }
        }
    }, [isInverted]);
}
