import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';

import { IWellGenericCut } from '../models/interfaces/IWellGenericCut';
import { useAPI } from 'hooks/useAPI';
import { useCrossSectionStore } from '../stores/useCrossSectionStore';
import { ApiType } from 'models/enums/ApiType';
import { FetchType } from 'models/enums/FetchType';
import { LoadingBlock } from 'components/LoadingBlock';
import { useCrossSectionConnectionStore } from '../stores/useCrossSectionConnectionStore';

export function WellTrackingSelect ({ onChange, crossSectionItemToken }:IProps) {
    const [selectedTracking, setSelectedTracking] = useState<IWellGenericCut>();
    const {isLoading, execute, data} = useAPI<IWellGenericCut[]>('/Wells/WellCrossSection/GetAllTracking');
    const {jwtToken} = useCrossSectionStore(state => ({
        jwtToken: state.jwtToken
    }));
    const {connectionStartDestination,setConnectionStartDestination} = useCrossSectionConnectionStore(state =>({
        connectionStartDestination:state.connectionStartDestination,
        setConnectionStartDestination:state.setConnectionStartDestination,
    }));

    useEffect(() => {
        if (!crossSectionItemToken || !jwtToken){
            return;
        }

        execute({
            type: FetchType.Get,
            apiType: ApiType.WebApi,
            jwtToken: jwtToken,
            params: {
                wellCrossSectionItemToken: crossSectionItemToken
            }
        });
    }, [crossSectionItemToken, jwtToken]);

    useEffect(() => {
        if (data){
            console.log(data);
            const defaultItem = data.find((el) => el.IsDefault);
            if (defaultItem){
                //setDefaultTracking(defaultList);
                setSelectedTracking(defaultItem);
            }
        }
    }, [data]);

    useEffect(() => {
        if (selectedTracking){
            onChange(selectedTracking);
        }
    }, [selectedTracking]);

    const handleChange = (event: SelectChangeEvent) => {
        if (event.target.value && data){
            const corte = data.slice().find((x) => x.GenericCutToken === event.target.value);
            console.log(` Corte :::::::`);
            console.log(corte);
            console.log(connectionStartDestination);
            let newConnectionStartDestination = Object.assign({},connectionStartDestination);
            for (const key in connectionStartDestination){
                const con = connectionStartDestination[key];
                if (con.WellId === corte?.WellId){
                    newConnectionStartDestination[key] = {start:con.start,destination:corte.WellStartDepth,WellId:con.WellId};
                }
            }
            console.log(newConnectionStartDestination);
            setConnectionStartDestination(newConnectionStartDestination);
            if (corte){
                setSelectedTracking(corte);
            }
        }
    };

    return (
        <FormControl fullWidth>
            <LoadingBlock open={isLoading}/>
            <Select onChange={handleChange} value={selectedTracking?.GenericCutToken ?? ''} size='small'>
                {
                    data?.map((el) => (
                        <MenuItem
                            value={el.GenericCutToken}
                            key={el.GenericCutToken}>
                            {el.GenericCutType}{(el.GenericCutName) ? ` - ${el.GenericCutName}`:''}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );

}

interface IProps{
    onChange(selectedTracking:IWellGenericCut):void;
    crossSectionItemToken: string | undefined;
};