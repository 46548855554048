import { IObserver } from './IObserver';

export class NotificationSubject<T>  {
    public observers: IObserver<T>[] = [];

    public observersV: ((param: T) => void)[] = [];

    public subscribe(observer: (param: T) => void): void {
        const isExist = this.observersV.includes(observer);
        if (isExist) {
            //console.log("Subject: Observer has been attached already.")
            return;
        }

        //console.log("Subject: Attached an observer.");
        this.observersV.push(observer);
    }

    public unSubcribe(observer: (param: T) => void): void {
        const observerIndex = this.observersV.indexOf(observer);
        if (observerIndex === -1) {
            //console.log("Subject: Nonexistent observer.")
            return;
        }

        this.observersV.splice(observerIndex, 1);
        //console.log("Subject: Detached an observer.");
    }

    public notify(param: T): void {
        this.observersV.forEach((observer) => {
            observer(param);
        });
    }
}