import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { Fragment, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import alertify from 'alertifyjs';
import { GeopostTreeViewDropdows } from 'fileIndexing/otherComponents/GeopostTreeViewDropdows';

import { IDataGroup } from '../interfaces/ITaxonomy';
import { useSaveDataGroup } from '../useTaxonomy';

export function GroupsForm({ setOpenModal, refetchTableData, allDataGroups, formData }: Props) {
    const formDataProps = useForm<IDataGroup>({
        defaultValues: formData || undefined
    });

    const [data, setData] = useState<IDataGroup | null>(null);

    const { data: saveDataGroup } = useSaveDataGroup(data);

    const handleData = (data: IDataGroup) => {
        let newData = data;
        if (formData) {
            newData.Token = formData.Token;
        } else {
            newData.Token = '';
            newData.DataSubGroupsTokens = [];
        }
        newData.ParentGroupToken = formDataProps.getValues('ParentGroupToken');

        setData(newData);
    };

    async function executeSave() {
        setOpenModal(false);
        refetchTableData();
    }

    useEffect(() => {
        if (saveDataGroup) {
            if (saveDataGroup === 'Success') {
                executeSave();
            } else {
                alertify.notify('An error occurred when creating a new Group');
            }
        }
    }, [saveDataGroup]);

    return (
        <Fragment>
            <FormContainer<IDataGroup> defaultValues={formData ?? {}} onSuccess={(data) => handleData(data)} FormProps={{ style: { width: '100%' } }}>
                <Grid spacing={2} container direction='column'>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'Name'} label={'Data Group'} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostTreeViewDropdows<IDataGroup> label={'Parent Group'} control={formDataProps.control} name={'ParentGroupToken'} data={allDataGroups} childrenField={'Children'} />
                    </Grid>
                    <Grid container item direction='row-reverse'>
                        <Button variant='contained' color='primary' type='submit' style={{ marginBottom: '16px' }}>SAVE</Button>
                    </Grid>
                </Grid>
            </FormContainer>
        </Fragment>
    );
}

interface Props {
    setOpenModal: (value: boolean) => void;
    refetchTableData: () => void;
    allDataGroups: IDataGroup[] | undefined;
    formData?: IDataGroup | null;
}