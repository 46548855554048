import { Box, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, Tooltip, css } from '@mui/material';
import { useEffect, useState } from 'react';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';

import { ScaleTypes } from '../models/interfaces/INeumonic';
interface IProps{
    onChange(scaleType:ScaleTypes):void;
    value:ScaleTypes
};

const options = [
    {name:'normal', value:0},
    {name:'Log', value:1},
    {name:'Reverse', value:2}
];
const styles = css({
    margin:'0',
    padding:'0'
});
export function WellScaleSelect({value,onChange}:IProps){
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selectedScale, setselectedScale] = useState<ScaleTypes>(value);

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        console.log(event);
        if (event.target){
            const li = event.target as HTMLLIElement;
            let option = options.filter(el => el.value === Number(li.value))[0];
            setselectedScale(option);
        }

        setAnchorEl(null);
        console.log(selectedScale);
    };
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event){
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };
    useEffect(() => {
        console.log(selectedScale);
        if (selectedScale){
            onChange(selectedScale);
        }
    }, [selectedScale]);
    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Click to select the scale type (normal/reverse/log)">
                    <IconButton
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        id="composition-button"
                        onClick={handleClick}
                        sx={{color:'white'}}
                    >
                        <PermDataSettingIcon></PermDataSettingIcon>
                    </IconButton>
                </Tooltip>
            </Box>

            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
            // IconComponent={PermDataSettingIcon}
            // onChange={handleChange}
            // id="demo-simple-select"
            // value={selectedScale ? selectedScale.value.toString() : '0'}
            // size='small'
            // variant='standard'
            // css={{styles}}
            >

                {options.map((el:ScaleTypes,idx) => <MenuItem key={idx} onClick={handleClose} value={el.value}>{el.name}</MenuItem>)}
                {/* <MenuItem value={'select'}>Select a Mnemonic</MenuItem> */}
                {/* {data && data.Result.sort((a:INeumonic,b:INeumonic) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0)).map((el:INeumonic,idx:number) => <MenuItem key={`${el.Name}${el.Curves.LogicalFile}${el.Curves.FrameName}`} value={`${el.Name} ${el.Curves.LogicalFile} ${el.Curves.FrameName}`}>{`${el.Name} ${el.Curves.LogicalFile.toUpperCase().replace('LOGICALFILE','')}`}</MenuItem>)} */}
            </Menu>
        </div>
    );
}