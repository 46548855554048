import * as THREE from 'three';

import { build3DPointsFromCoordinates } from 'features/seismic-3d/components/projections/featureGeomPolygon';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { getScaled2DCoordinate } from '../utils/ScaleUtils';
import { GeopostWellCrossingPointMesh } from './GeopostWellCrossingPointMesh';
import { GeopostThreeJsConstants } from '../utils/GeopostThreeJsConstants';

export class GeopostWellGroup extends THREE.Group {
    public heightFactor: number;
    public wellPoints: THREE.Vector3[];
    public crossingPointMesh: GeopostWellCrossingPointMesh | null = null;

    public constructor(countTimeDepth: number, surveyHeightInPixels: number, well: IWell, mainFeatureCentroidX: number, mainFeatureCentroidY: number) {
        super();
        const heightFactor = countTimeDepth / surveyHeightInPixels;
        this.heightFactor = heightFactor;
        const waterDepthInPixels = Math.abs(well.WaterDepth) / heightFactor;
        const fullDepthInPixels = Math.abs((well.Depth > 0 ? well.Depth : well.ProfundidadeVertical )) / heightFactor;

        const bladePosition = -(waterDepthInPixels / GeopostThreeJsConstants.yDivisionFactor);
        const finalDepthPosition = -(fullDepthInPixels / GeopostThreeJsConstants.yDivisionFactor);

        const scaledPlatform2DCoordinate = getScaled2DCoordinate([well.PlatformXPosition, well.PlatformYPosition], mainFeatureCentroidX, mainFeatureCentroidY);
        const scaledBase2DCoordinate = getScaled2DCoordinate([well.BaseXPosition, well.BaseYPosition], mainFeatureCentroidX, mainFeatureCentroidY);
        const scaledBottom2DCoordinate = getScaled2DCoordinate([well.BottomXPosition, well.BottomYPosition], mainFeatureCentroidX, mainFeatureCentroidY);

        this.name = 'well-group-' + well.Id;
        this.wellPoints = build3DPointsFromCoordinates([
            [...scaledBase2DCoordinate, bladePosition],
            [...scaledBottom2DCoordinate, finalDepthPosition]
        ]);

    }

    public updateHeightFactor = (countTimeDepth: number, surveyHeightInPixels: number) => {
        const newHeightFactor = countTimeDepth / surveyHeightInPixels;
        this.wellPoints.forEach(wellPoint => {
            const currentY = wellPoint.y * GeopostThreeJsConstants.yDivisionFactor * this.heightFactor;

            const newY = - (currentY / newHeightFactor / GeopostThreeJsConstants.yDivisionFactor);
            wellPoint.setY(newY);
        });
        this.heightFactor = newHeightFactor;
    };

    public static getWellGroupName = (wellId: number) => {
        return 'well-group-' + wellId;
    };

    public addCrossingPoint = (mesh: GeopostWellCrossingPointMesh) => {
        mesh.name = 'crossing-point';
        this.add(mesh);
        this.crossingPointMesh = mesh;
    };

}