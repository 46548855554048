import { SurveyType } from '../models/enums/SurveyType';
import { useSeismicStore } from '../stores/useSeismicStore';
import { Line3DSelector } from './Line3DSelector';
import { SeismicTraceRangeNavbar } from './SeismicTraceRangeNavbar';
import { TraceRangeSelector } from './TraceRangeSelector';

export function SeismicSelectorLine({ featureFlags }: Props) {
    const { surveyMetadata } = useSeismicStore(state => ({
        surveyMetadata: state.surveyMetadata
    }));

    let lineSelector = null;

    if (surveyMetadata) {
        if (surveyMetadata.Type === SurveyType.Seismic3D) {
            if (surveyMetadata.IsIndexed) {
                lineSelector = <Line3DSelector featureFlags={featureFlags}/>;
            }
            else {
                lineSelector = <SeismicTraceRangeNavbar />;
            }
        } else {
            if (!surveyMetadata.IsIndexed) {
                lineSelector = <SeismicTraceRangeNavbar />;
            }
        }
    }

    return lineSelector;
}

interface Props {
    featureFlags: {
        ColorBar: boolean;
        Gain: boolean;
        Projections: boolean;
        Attributes: boolean;
        TraceHeader: boolean;
        Minimap: boolean;
        EnableWells: boolean;
        Interpretation: boolean;
        WebTour: boolean;
        Graticule: boolean;
        LineType: boolean;
        Depth: boolean;
        TimeSlice: boolean;
    } | undefined
}