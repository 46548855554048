import { create } from 'zustand';

type SnackbarType = 'error' | 'info' | 'success' | 'warning';

interface ISnackbarStore {
    message: string | null,
    type: SnackbarType,

    hideSnackbar: () => void,
    showSnackbar: (type: SnackbarType, message: string | null) => void
}

export const useSnackbarStore = create<ISnackbarStore>()(
    (set, get) => ({
        message: null,
        type: 'error',

        hideSnackbar: () => set({ message: null}),
        showSnackbar: (type: SnackbarType, message: string | null) => set({
            message,
            type
        }),
    })
);