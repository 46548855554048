import { css } from '@emotion/react';
import { IconButton, useTheme } from '@mui/material';

import { Close} from 'components/icons/components';
import { IGeopostPopupHeaderProps } from '../models/interfaces/IGeopostPopupHeaderProps';

export function GeopostPopupHeader({ title = '', onChangeOpen = undefined, icons}: IGeopostPopupHeaderProps){
    const theme = useTheme();

    const styles = css({
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: '4px 0px 4px 4px',
        display: 'flex',
        borderRadius: 5,
        fontSize: 16,
        fontWeight: 600,
        alignItems: 'center',

        '& .title': {
            flexGrow: 1
        }
    });

    const handleSetOpen = () => {
        if (onChangeOpen){
            onChangeOpen(false);
        }
    };

    return <div className='header' css={styles} style={{cursor: 'move'}}>
        <span className='title'>{title}</span>
        <div className='icons no-drag'>
            {icons && icons.map(icon => icon)}
            <IconButton sx={{zIndex: 99999999}} color='inherit' size='small' onClick={handleSetOpen}>
                <Close />
            </IconButton >
        </div>
    </div>;
}