import { createTheme, ThemeOptions } from '@mui/material/styles';

import { AppBarConfig } from 'features/tenant/model/TenantConfig';

export function createThemeAppBar(props:AppBarConfig, customTheme?: ThemeOptions) {
    return createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: 10,
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        marginTop: 5
                    },
                    list: {
                        backgroundColor: props.menu.bgColor,
                        color:  props.menu.color,
                        paddingTop: 0,
                        opacity: 0.85
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            color: '#464646 !important',
                            border: '1px solid #636060 !important',
                        },
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: props?.input.background ?? 'red',
                        color: props?.input.color ?? '#red'
                    },
                    sizeSmall:{
                        fontSize: 10,
                    },
                },
                defaultProps: {
                    size: props.input.size === 'small' ? 'small' : 'medium'
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: props.inputLabel.color
                    }
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: props.checkbox.color + ' !important'
                    },
                }
            }
        },
        palette: {
            primary: {
                main: '#fff'
            },
            secondary: {
                main: '#000'
            }
        },
        ...customTheme
    });
}