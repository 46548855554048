import { numberInputClasses } from '@mui/base';
import { create } from 'zustand';

import { GeopostScene } from '../threejs/scene/GeopostScene';

export interface IUse3DSceneStore {
    scene: GeopostScene | null,
    setScene: (scene: GeopostScene) => void,

    lightIntensityPercentage: number,
    setLightIntensityPercentage: (value: number) => void,
    heightScale: number,
    setHeightScale: (value: number) => void,
}

export const use3DSceneStore = create<IUse3DSceneStore>((set) => ({
    scene: null,
    setScene: (scene) => {
        set({scene: scene});
    },

    lightIntensityPercentage: 50,
    setLightIntensityPercentage: (value) => set({ lightIntensityPercentage: value }),
    heightScale: 1,
    setHeightScale: (value) => set({ heightScale: value })
}));