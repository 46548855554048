import { Table, TableBody, TableContainer, TableHead, TableRow, TextField,TableCell, Paper, Button, css, CircularProgress, Alert, AlertTitle } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import queryString from 'query-string';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { StartIndexPopup } from './StartIndexPopup';
import { StartIndexDTO } from '../model/IStartIndexDTO';
import { IIndexingVolume } from '../model/IIndexingVolume';
import { EVolumeType } from '../model/EVolumeType';
import { useGetIndexingVolumes, useStartIndexation } from '../api/seismicIndexApi';

const searchAndAddBar = css({

    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    margin:6
});

const loadingStyle = css({
    position: 'absolute',
    background: 'rgb(0,0,0,.9)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
});

export function IndexTable(){
    document.title = 'Seismic Indexing';
    const element = queryString.parse(window.location.search, {});
    const apiKey = (element.apiKey)?element.apiKey as string:'';

    const [ openPopup, setopenPopup ] = useState(false);
    const [recentStartedIndexing, setRecentStartedIndexing] = useState<Array<string>>([]);
    const [searched, setSearched] = useState<string>('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isError, setIsError ] = useState(false);
    const [ data, setData] = useState<IIndexingVolume[]>([]);
    //const { isLoading, status, isError, data, error, refetch } = useQuery('indexing' , async () => {return await useGetIndexingVolumes(apiKey)}, {select: (rows : IIndexingVolume[]) => rows.filter((row) => row.Path.includes(searched) ), refetchInterval: 30000}, );
    /*const mutation = useMutation(function(startIndexDTO:StartIndexDTO ) {
        return useStartIndexation(startIndexDTO);
    });*/
    const [alertErrorOpen , setAlertErrorOpen] = useState<boolean>(isError);

    const refetch = () => {
        console.log('Refetch');
    };

    const requestSearch = (searchedVal: string) => {
        setSearched(searchedVal);
    };
    const togglePopup= () =>{
        if (openPopup){
            setopenPopup(false);
        } else {
            setopenPopup(true);
        }
    };

    const StartIndex = (row:IIndexingVolume)=>{
        let volumeType;
        if (row.VolumeType.trim().toLowerCase() === '3d seismic'){
            volumeType = EVolumeType.Seismic3D;
        } else {
            volumeType = EVolumeType.Seismic2D;
        }
        /*
        mutation.mutate({
            basePath: row.Path,
            srid:row.SRID,
            volumeType:volumeType,
            inlineByte:0,
            xlineByte:0,
            coordinateX:0,
            coordinateY:0,
            coordinateScalar:0,
            apiKey: apiKey
        });
        */
        let started = recentStartedIndexing.slice();
        started.push(row.Token);
        setRecentStartedIndexing(started);
    };
    return (<div>
        {alertErrorOpen &&  <div>
            <Alert onClose={() => {setAlertErrorOpen(false)}} severity="error">
                <AlertTitle>Error</AlertTitle>
            An error occurred! — <strong>check it out!</strong>
            </Alert></div>}
        {isLoading && <div css={loadingStyle}>
            <CircularProgress size={60} style={{ color: '#fff' }} />
        </div>}
        <div css={searchAndAddBar}>
            <TextField
                value={searched}
                label='Search'
                onChange={(searchVal) => requestSearch(searchVal.target.value)}
            />
            <Button variant='contained' onClick={togglePopup}>+</Button>
        </div>
        <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 900,minWidth: 300 }}>
                    <Table stickyHeader size='small' aria-label="indexing volume table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                {/* <TableCell>Preview</TableCell> */}
                                <TableCell>Token</TableCell>
                                <TableCell>Path</TableCell>
                                <TableCell align="right">Volume Type</TableCell>
                                <TableCell align="right">File Size (GB)</TableCell>
                                <TableCell align="right">Lenght (KM)</TableCell>
                                <TableCell align="right">Indexing Status</TableCell>
                                <TableCell align="right">SRID</TableCell>
                                <TableCell align="right">Number of traces</TableCell>
                                <TableCell align="right">Indexed Traces</TableCell>
                                <TableCell align="right">Percentual Indexed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row : IIndexingVolume) => (
                                <TableRow
                                    key={row.Token}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>
                                        {
                                            row.PercentualIndexed === 0 && recentStartedIndexing.filter(x => x === row.Token).length === 0?
                                                <Button variant='contained' onClick={() => StartIndex(row)}>Start</Button> :
                                                null
                                        }
                                        {
                                            row.PercentualIndexed === 0 && recentStartedIndexing.filter(x => x === row.Token).length > 0?
                                                <Button disabled variant='contained' onClick={() => StartIndex(row)}>Preparing...</Button> :
                                                null
                                        }
                                        {
                                            row.PercentualIndexed >= 100 ?
                                                <Button disabled variant='contained'>Finished</Button> :
                                                null
                                        }
                                        {
                                            row.PercentualIndexed > 0 && row.PercentualIndexed < 100 && row.IsPaused && recentStartedIndexing.filter(x => x === row.Token).length === 0 ?
                                                <Button variant='contained' onClick={() => StartIndex(row)}>Resume</Button> :
                                                null
                                        }
                                        {
                                            row.PercentualIndexed > 0 && row.PercentualIndexed < 100 && row.IsPaused && recentStartedIndexing.filter(x => x === row.Token).length > 0 ?
                                                <Button variant='contained' onClick={() => StartIndex(row)}>Resuming...</Button> :
                                                null
                                        }
                                        {
                                            row.PercentualIndexed > 0 && row.PercentualIndexed < 100 && row.IsRunning ?
                                                <Button disabled variant='contained'>Executing...</Button> :
                                                null
                                        }
                                    </TableCell>
                                    {/* <TableCell>
                                        {
                                            row.PercentualIndexed === 100 ?
                                                <Button variant='contained' onClick={() => window.open(row.PreviewLink)}>Preview</Button> :
                                                <Button disabled variant='contained' onClick={() => window.open(row.PreviewLink)}>Preview</Button>
                                        }
                                    </TableCell> */}
                                    <TableCell >
                                        {row.Token}
                                    </TableCell>
                                    <TableCell >
                                        {row.Path}
                                    </TableCell>
                                    <TableCell align="right">{row.VolumeType}</TableCell>
                                    <TableCell align="right">{row.FileSize}</TableCell>
                                    <TableCell align="right">{row.Length.toFixed(0)}</TableCell>
                                    <TableCell align="right">{row.IndexingStatus}</TableCell>
                                    <TableCell align="right">{row.SRID}</TableCell>
                                    <TableCell align="right">{row.NumberOfTraces}</TableCell>
                                    <TableCell align="right">{row.IndexedTraces}</TableCell>
                                    <TableCell align="right">{row.PercentualIndexed.toFixed(2)}%</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
        <GeopostPopup
            temporaryIgnoreFlexInContainer
            open={openPopup}
            title={t('Index params')}
            onChangeOpen={value => setopenPopup(value)}
            overlay={false}
            height={650}
            width={500}>
            <StartIndexPopup openPopup = {setopenPopup} onAfterSave={() => refetch()} />
        </GeopostPopup>
    </div>);
}