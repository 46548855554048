import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from 'session/useSessionStore';
import { SeismicTraceHeader } from 'components/icons/components';
import { useSeismicStore } from '../stores/useSeismicStore';

export function SeismicNavbarTraceHeader(){
    const { showTraceHeaderTable,setShowTraceHeaderTable } = useSeismicStore(state => ({
        setShowTraceHeaderTable:state.setShowTraceHeaderTable,
        showTraceHeaderTable: state.showTraceHeaderTable
    }));

    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));

    const className = (showTraceHeaderTable) ? 'active' : '';

    const { t } = useTranslation();

    const toggle = () => {
        setShowTraceHeaderTable(!showTraceHeaderTable);
    };

    return (
        <IconButton
            id='trace-header'
            style={{color:tenantConfig?.main.palette.primary.contrastText}}
            onClick={toggle}
            className={className}
            title={t('traceHeader')}
        >
            <SeismicTraceHeader />
        </IconButton>

    );
}