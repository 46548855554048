
import { useRef, useEffect, ReactNode } from 'react';
import { Button, css, TextField } from '@mui/material';

const style = css({
    display: 'flex',

    '& input': {
        textAlign: 'center',
    },
    '& button': {
        background: '#0e0e0e63',
        //background: '#FFF',
        borderRadius: 0,
        maxWidth: 40,
        minWidth: 30,
        height: 34,
        width: 30,
        '&:hover': {
            background: '#ffffff63'
        }
    },
    '& .MuiFormLabel-root': {
        transform: 'translate(0px, -14px) scale(0.75)',
        fontSize: '16px',
    },
    '& .MuiInputBase-root': {
        borderRadius: 0,
        width: '100%',
        minWidth: '100%',
        fontSize: '12px',
    },
    '& .MuiButtonBase-root[disabled]': {
        color: '#666'
    },
    '& .MuiFormControl-root': {
        '& .MuiOutlinedInput-notchedOutline': {
            '& legend': {
                width: '0px',
            }
        }
    },
    /*'& label.Mui-focused': {
        color: 'red'
    },*/
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            borderWidth: '1px',
            borderRadius: '5px'
        }
    }
    // '& .MuiInputBase-input': {
    //     height: '14px',
    //     fontSize: '10px',
    // },
});

export function InputNumberValueSelector ({onChange, id, value, staticComponent = false, onUpClick = null, onDownClick = null, width = 140, stepIncrement = 1, allowNegative = false, label = '', labelIncrease = '+', labelDecrease = '-', disableDecrease = false, disableIncrease = false, type = 'text', onFocus = () => {}, onBlur = () => {}}: IProps){
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current){
            inputRef.current.value = value.toString();
        }

    }, [value]);

    const changeValueByEvent = () => {
        if (!inputRef.current){
            return;
        }

        let value = parseFloat(inputRef.current?.value);

        if (isNaN(value)){
            value = 0;
        }

        onChange(value);
    };

    const buttonChangeValue = (valueToChange: number) => {
        let valueNumber = value + valueToChange;
        changeValue(valueNumber);
        if (!allowNegative && valueNumber < 0){
            valueNumber = 0;
        }
        return valueNumber;
    };

    const increaseValue = () => {
        const newValue = buttonChangeValue(stepIncrement);

        if (onUpClick){
            onUpClick(newValue);
        }
    };

    const decreaseValue = () => {
        const newValue = buttonChangeValue(-stepIncrement);

        if (onDownClick){
            onDownClick(newValue);
        }
    };

    const changeValue = (value: string | number) => {
        if (staticComponent){
            return;
        }
        let valueNumber: number;

        if (typeof(value) === 'string'){
            valueNumber = parseFloat(value);
        }
        else {
            valueNumber = value;
        }

        if (!allowNegative && valueNumber < 0){
            valueNumber = 0;
        }

        onChange(valueNumber);
    };

    return (<div css={style} style={{maxWidth: width, marginRight: '10px'}} id={id}>
        <Button  style={{fontSize: '14px', borderRadius: '5px'}} onClick={decreaseValue} disabled={disableDecrease}>{labelDecrease}</Button>
        <TextField label={label} style={{borderRadius: '5px'}} inputRef={inputRef} onBlur={() => { changeValueByEvent(); onBlur()}} type={type} onFocus={onFocus} ></TextField>
        <Button style={{fontSize: '14px', borderRadius: '5px'}} onClick={increaseValue} disabled={disableIncrease}>{labelIncrease}</Button>
    </div>);
}
interface IProps{
    value: number;
    onChange: (value: number) => void;
    onUpClick?: {(value: number):void} | null;
    onDownClick?: {(value: number):void} | null;
    allowNegative?: boolean;
    stepIncrement?: number;
    label?: ReactNode;
    width?: number;
    labelDecrease?: string;
    labelIncrease?: string;
    staticComponent?: boolean;
    disableDecrease?: boolean;
    disableIncrease?: boolean;
    id?: string;
    type?: string;
    onFocus?: () => void;
    onBlur?: () => void;
}
