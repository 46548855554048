import { useEffect, useRef, useState } from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { useCheckForSeismic3D } from '../api/useFeatureFlagController';
import { ISeismic3DFlags } from '../models/interfaces/ISeismic3DFlags';
import { getUrlParams } from '../utils/Seismic3DUrlUtils';

export const use3DFeatureFlags = () => {
    const tenantToken = getUrlParams().tenantToken;
    const { data: flagNames } = useCheckForSeismic3D(tenantToken);
    const [seismic3DFeatureFlags, setSeismic3DFeatureFlags] = useState<ISeismic3DFlags | null>(null);

    const environmentFeatureFlagList = useSessionStore(state => state.tenantConfiguration?.environmentFeatureFlags.Features3DViewer);

    useEffect(() => {
        if (flagNames && environmentFeatureFlagList) {
            const validatedFlagNames = flagNames.filter(flag => environmentFeatureFlagList.includes(flag));
            const featureFlagsObject = {
                enableProjections: false,
                enableSurveys: false,
                enableGrids: false,
                enableWells: false,
                enableParentAppLink: false,
                enablePerformanceMonitoring: false,
                enableZslice: false,
            };
            validatedFlagNames.forEach(flagName => enableFlag(flagName, featureFlagsObject));
            setSeismic3DFeatureFlags(featureFlagsObject);
        }
    }, [flagNames, environmentFeatureFlagList]);

    const enableFlag = (flagName: string, seismic3DFeatureFlags : ISeismic3DFlags) => {
        switch (flagName) {
        case 'SEISMIC_3D_ENABLE_PROJECTIONS':
            seismic3DFeatureFlags.enableProjections = true;
            break;
        case 'SEISMIC_3D_ENABLE_SURVEYS':
            seismic3DFeatureFlags.enableSurveys = true;
            break;
        case 'SEISMIC_3D_ENABLE_GRIDS':
            seismic3DFeatureFlags.enableGrids = true;
            break;
        case 'SEISMIC_3D_ENABLE_WELLS':
            seismic3DFeatureFlags.enableWells = true;
            break;
        case 'SEISMIC_3D_ENABLE_PARENT_APP_LINK':
            seismic3DFeatureFlags.enableParentAppLink = true;
            break;
        case 'SEISMIC_3D_ENABLE_PERFORMANCE_MONITORING':
            seismic3DFeatureFlags.enablePerformanceMonitoring = true;
            break;
        case 'SEISMIC_3D_ENABLE_Z_SLICE':
            seismic3DFeatureFlags.enableZslice = true;
        };
    };
    return seismic3DFeatureFlags;
};

