export interface IDataSource {
    Token: string;
    Name: string;
    RootAddress: string;
    FilterPath: string;
    FilterExtensions: string;
    FilterFileSizeMaximum: number;
    PathType: ePathType;
    ProjectToken: string;
    AccessInformation: string;
}

export enum ePathType {
    None = 0,
    InternalNetwork = 1,
    ExternalMidia = 2,
    AzureStorage = 3,
    AWSS3Bucket = 4,
    Dump = 5,
}