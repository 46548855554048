import axios from 'axios';
import { useMutation } from 'react-query';

import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { useSessionStore } from 'session/useSessionStore';
import { IResolutionsResponse } from '../models/interfaces/IResolutionsResponse';

export const useGetResolutionsImperatively = () => {
    const host = useSessionStore(state => state.tenantConfiguration?.endpoints.resolutions);
    const jwt = useSessionStore(state => state.jwtToken);
    return useMutation({
        mutationFn: async ( params : {
            tileWidth: number,
            screenHeight: number,
            totalSamples: number,
            scaleY: number,
            traceSkip: number
        } ) => {
            const response = await axios.get<IResponseAPI<IResolutionsResponse>>(host + '/Seismic/SeismicResolution/Get', {
                params: params,
                headers: {
                    Authorization: 'Bearer ' + jwt
                }
            });
            return response.data.Result;
        }
    });
};