import { Outlet } from 'react-router-dom';

import { useSessionStore } from 'session/useSessionStore';
import { OktaRestrictedRoute } from './OktaRestrictedRoute';

export const RestrictedRoute = () => {

    const oktaAuthEnabled = !!(useSessionStore(state => state.tenantConfiguration?.oktaAuthConfiguration));

    return (
        oktaAuthEnabled ?
            (
                <OktaRestrictedRoute/>
            )
            :
            <Outlet/>
    );
};