import { Fragment, useEffect } from 'react';
import { Navbar } from 'fileIndexing/otherComponents/Navbar';
import { NavigationMenu } from 'fileIndexing/otherComponents/NavigationMenu';

import { FilesTable } from '../components/FilesTable';

export function FilesMainPage() {
    useEffect(() => {
        document.title = 'File Indexing - Files';
    }, []);

    return (
        <Fragment>
            <Navbar />
            <NavigationMenu />
            <FilesTable />
        </Fragment>
    );
}