import { AppBar, ThemeProvider, Toolbar, css } from '@mui/material';
import { useGeopostThemeAppBar } from 'theme';

import { SeismicLogoNavbar } from 'features/seismic/components/SeismicLogoNavbar';

const styles = css({
    '& .toolbar > *': {
        marginRight: 15
    },
    '& .title': {
        marginLeft: 'auto'
    },
    '& fieldset': {
        padding: '0px 7px',
        color: '#fff',
        border: 'none',
        background: 'rgb(255 255 255 / 17%)',
        borderRadius: 3
    },
    '& fieldset legend': {
        fontSize: 12
    },
    '& fieldset input': {
        paddingTop: 3,
        paddingBottom: 3
    },
    '& fieldset .MuiInputBase-root': {
        background: 'none'
    },
    '& fieldset .MuiInputBase-root fieldset': {
        background: 'none'
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight: 0
    },
    '& .MuiInputBase-adornedStart': {
        paddingLeft: 0
    },
    '& .add-lane':{
        marginTop:'8%'
    }
});

export function WellViewerNavbar() {
    const themeAppBar = useGeopostThemeAppBar();

    return <AppBar position='static' css={styles}>
        <Toolbar className='toolbar'  style={{paddingLeft: '0px', paddingRight: '0px'}}>
            <ThemeProvider theme={themeAppBar}>
                <SeismicLogoNavbar />
            </ThemeProvider>
        </Toolbar>
    </AppBar>;
}

