import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { GeopostDropdown } from 'fileIndexing/otherComponents/GeopostDropdown';
import { GeopostDropdownDataGroup } from 'fileIndexing/otherComponents/GeopostDropdownDataGroup';
import { GeopostDropdownDataSubGroup } from 'fileIndexing/otherComponents/GeopostDropdownDataSubGroup';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { TestRulesTable } from 'fileIndexing/taxonomies/components/TestRulesTable';
import { Fragment, useEffect, useState } from 'react';
import { FormContainer, useForm, useWatch } from 'react-hook-form-mui';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { IFile } from '../interfaces/IFiles';
import { useSave } from '../useFiles';

export const FilesEdit = ({ refetchTableData, setOpenModal, formData }: Props) => {
    const formPropData = useForm<IFile>({ defaultValues: formData || undefined });
    const { control } = formPropData;
    const dataGroupToken: string = useWatch({ control, name: 'DataGroupToken' });

    const [checkSubgroupsModal, setCheckSubgroupsModal] = useState<boolean>(false);
    const [dataFile, setDataFile] = useState<IFile | null>(null);

    const { data: saveFilesData } = useSave(dataFile);

    const handleCloseModal = () => setCheckSubgroupsModal(false);

    const handleData = (data: IFile) => {
        setDataFile(data);
    };

    const checkSubgroups = () => {
        setCheckSubgroupsModal(true);
    };

    const transmittalItems = [
        {
            id: '',
            label: 'None',
        }
    ];

    useEffect(() => {
        if (saveFilesData) {
            if (saveFilesData === 'Success') {
                setOpenModal(false);
                refetchTableData();
            }
            else {
                alertify.notify('An error occurred saving file information');
            }
        }
    }, [saveFilesData]);

    return (
        <Fragment>
            <FormContainer<IFile> defaultValues={formData ?? {}} onSuccess={(data) => handleData(data)}>
                <Grid spacing={2} container>
                    <Grid spacing={2} container item direction="row">
                        <Grid xs={6} item>
                            <GeopostTextField fieldName={'Name'} label={'Name'} textFieldProps={{ disabled: true }} />
                        </Grid>
                        <Grid xs={6} item>
                            <GeopostTextField fieldName={'Size'} label={'Size(Gb)'} textFieldProps={{ disabled: true }} />
                        </Grid>
                    </Grid>
                    <Grid spacing={2} container item direction="row">
                        <Grid xs={12} item>
                            <GeopostTextField fieldName={'InternalPath'} label={'Internal path'} textFieldProps={{ disabled: true }} />
                        </Grid>
                    </Grid>
                    <Grid spacing={2} container item direction="row">
                        <Grid xs={4} item>
                            <GeopostDropdownDataGroup<IFile> formPropData={formPropData} fieldName={'DataGroupToken'} label={'Data Groups'} noneItem={true} />
                        </Grid>
                        <Grid xs={4} item>
                            <GeopostDropdownDataSubGroup fieldName={'SubgroupToken'} label={'SubGroups'} disabled={dataGroupToken === '' ? true : false} dataGroupToken={dataGroupToken} noneItem={true}  />
                        </Grid>
                        <Grid xs={4} item>
                            <GeopostDropdown fieldName={'TransmittalToken'} label={'Transmittals'} items={transmittalItems} required={false} />
                        </Grid>
                    </Grid>
                    <Grid container item direction="row-reverse">
                        <Button variant='contained' color='primary' onClick={() => checkSubgroups()}>CHECK SUBGROUPS</Button>
                    </Grid>
                    <Grid container item direction="row-reverse">
                        <Button variant='contained' color='primary' type='submit'>SAVE</Button>
                    </Grid>
                </Grid>
            </FormContainer>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={checkSubgroupsModal}
                onChangeOpen={handleCloseModal}
                resizable={false}
                width={1200}
                minHeight={365}
                height={460}
                title={'Add Well or Seismic'}
                padding={'16px'}
            >
                <TestRulesTable filterText={formData ? formData.InternalPath : ''} />
            </GeopostPopup>
        </Fragment>
    );
};

interface Props {
    refetchTableData: () => void;
    setOpenModal: (value: boolean) => void;
    formData: IFile | null;
}