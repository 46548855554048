import React, { useMemo } from 'react';
import { useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { use3DSceneStore } from '../stores/use3DSceneStore';
import { use3DViewerStore } from '../stores/use3DViewerStore';
import { GeopostGeomBasedScene } from '../threejs/scene/GeopostGeomBasedScene';
import { GeopostTraceBasedScene } from '../threejs/scene/GeopostTraceBasedScene';
import { GeopostScene } from '../threejs/scene/GeopostScene';
import { use3DGridStore } from '../stores/use3DGridStore';
import { useSessionStore } from 'session/useSessionStore';
import { Viewer3DStrategy } from '../models/enums/Viewer3DStrategy';

export const GeopostSceneContainer = ({ height, width } : GeopostSceneContainerProps) => {
    const sceneIframeRef = useRef<HTMLIFrameElement>(null);

    const { scene, lightIntensityPercentage, heightScale, setScene  } = use3DSceneStore(state => ({
        scene: state.scene,
        lightIntensityPercentage: state.lightIntensityPercentage,
        heightScale: state.heightScale,
        setScene: state.setScene
    }), shallow);

    const onCanvasResize : React.ReactEventHandler<HTMLIFrameElement> = (event) => {
        //scene.updateDimensions(parseFloat(event.currentTarget.width), parseFloat(event.currentTarget.height));
        console.log(event.currentTarget.width + ' -- ' + event.currentTarget.height);
    };

    const seismic3DInfo = use3DGridStore(state => state.selectedSeismic3DInfo);

    const strategy = useSessionStore(state => state.tenantConfiguration?.viewer3DStrategy);

    const geopostScene = useMemo(() => {
        if (strategy === Viewer3DStrategy.GeomBased) {
            return new GeopostGeomBasedScene();
        } else if (!!seismic3DInfo) {
            return new GeopostTraceBasedScene(seismic3DInfo);
        }
    }, [seismic3DInfo, strategy]);

    useEffect(() => {
        if (!!geopostScene) {
            const sceneIframeDocument = sceneIframeRef.current!.contentDocument ?? (sceneIframeRef.current!.contentWindow?.document);
            //const sceneIframeDocument = (document.getElementById('abcdefg') as HTMLIFrameElement).contentDocument;
            sceneIframeDocument?.open();
            const renderersContainer = sceneIframeDocument!.createElement('div');
            sceneIframeDocument!.appendChild(renderersContainer);
            setScene(geopostScene);
            geopostScene.setupAndRun(renderersContainer, sceneIframeRef!.current!.contentWindow!.innerWidth, sceneIframeRef!.current!.contentWindow!.innerHeight);
            //sceneIframeDocument!.body.appendChild(div);
        }
    }, [geopostScene]);

    useEffect(() => {
        scene?.updateDimensions(width, height);
    }, [height, width, scene]);

    useEffect(() => {
        scene?.setLightIntensity(lightIntensityPercentage);
    }, [lightIntensityPercentage, scene]);

    useEffect(() => {
        scene?.changeHeightScale(heightScale);
    }, [heightScale, scene]);

    useEffect(() => {
        console.log('scene container updat');
    });

    return (
        <>
            <iframe
                style={{margin: 0, border: 0}}
                title='threeJsSceneIframe'
                ref={sceneIframeRef}
                src='/scene.html'
                height={height}
                width={width}
            />
        </>
    );
};

export type GeopostSceneContainerProps = {
    height: number;
    width: number;
};