import { ISendFilter } from 'fileIndexing/files/interfaces/IFiles';
import { useGetOptGroup } from 'fileIndexing/tags/useTags';
import { Fragment, useEffect, useState } from 'react';
import { Box, css } from '@mui/material';
import { Control, useForm, UseFormReturn } from 'react-hook-form';
import { Path } from 'react-router-dom';
import { ICondensedTagsViewModel, ITagGroup, ITagGroupDropdown } from 'fileIndexing/tags/interfaces/ITag';

import { GeopostDropdown } from './GeopostDropdown';
import { GeopostTreeViewDropdows } from './GeopostTreeViewDropdows';
import { GeopostLoading } from './GeopostLoading';

const style = css({
    width: '100%',
    position: 'relative',
});

export function GeopostDropdownTag({ formPropData, fieldName, label, required = true }: Props) {
    const propData = useForm<ITagGroupDropdown>({
        defaultValues: {
            Name: '',
            Token: '',
            CondensedTagsViewModel: [],
        }
    });

    const { data: tagsGroupAndTagData, isLoading } = useGetOptGroup();

    const fieldValue = formPropData.watch(fieldName);
    const tagToken = propData.watch('Token');

    useEffect(() => {
        if (tagToken) {
            formPropData.setValue(fieldName, tagToken);
        }
    }, [tagToken]);

    useEffect(() => {
        if (fieldValue && tagsGroupAndTagData) {
            let tagSelected: ICondensedTagsViewModel | undefined = undefined;

            for (let i = 0; i < tagsGroupAndTagData.length; i++) {
                for (let j = 0; j < tagsGroupAndTagData[i].CondensedTagsViewModel.length; j++) {
                    if (tagsGroupAndTagData[i].CondensedTagsViewModel[j].Token === fieldValue) {
                        tagSelected = tagsGroupAndTagData[i].CondensedTagsViewModel[j];
                        break;
                    }
                }

                if (tagSelected) {
                    break;
                }
            }

            if (tagSelected) {
                propData.setValue('Name', tagSelected.Name);
                propData.setValue('Token', tagSelected.Token);
            }
        }
    }, [fieldValue, tagsGroupAndTagData]);

    return (
        <Box css={style}>
            <GeopostLoading loading={isLoading} />
            <GeopostTreeViewDropdows<ITagGroupDropdown> label={label} control={propData.control} name={'Token'} data={tagsGroupAndTagData} allowParentSelection={false} childrenField={'CondensedTagsViewModel'} />
        </Box>
    );
}

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formPropData: UseFormReturn<ISendFilter, any, undefined>;
    fieldName: keyof ISendFilter;
    label: string;
    required?: boolean;
}