export class RaycasterEventFactory<ObjectType extends THREE.Object3D = THREE.Object3D> {
    private lastIntersectedObject: ObjectType | null = null;

    public addIntersectionEventOnObject = (object: ObjectType, callbackStart: (object: ObjectType) => void, callbackEnd: (object: ObjectType) => void) => {
        const intersectionEvent = (raycaster: THREE.Raycaster) => {
            const intersections = raycaster.intersectObject(object, true);
            //console.log('listener running');
            if (intersections.length > 0) {
                if (intersections[0].object === object) {
                    console.log('intersec occured');
                    callbackStart(object);
                }
            } else {
                callbackEnd(object);
            }
        };

        return intersectionEvent;
    };

    public addIntersectionEventOnOneOfGroup = (objects: ObjectType[], callbackStart: (object: ObjectType) => void, callbackEnd: (object: ObjectType) => void) => {
        const intersectionEvent = (raycaster: THREE.Raycaster) => {
            const intersections = raycaster.intersectObjects(objects, true);
            if (intersections.length > 0) {
                if (this.lastIntersectedObject !== intersections[intersections.length - 1].object) {
                    if (!!this.lastIntersectedObject) {
                        callbackEnd(this.lastIntersectedObject);
                    }
                    const intersectedObject = intersections[intersections.length - 1].object as ObjectType;
                    this.lastIntersectedObject = intersectedObject;
                    callbackStart(intersectedObject);
                }
            } else {
                if (!!this.lastIntersectedObject) {
                    callbackEnd(this.lastIntersectedObject);
                    this.lastIntersectedObject = null;
                }
            }
        };

        return intersectionEvent;
    };

    public addIntersectionEventOnGroup = (group: ObjectType, callbackStart: (object: ObjectType) => void, callbackEnd: (object: ObjectType) => void ) => {
        const intersectionEvent = (raycaster: THREE.Raycaster) => {
            const intersections = raycaster.intersectObjects(group.children, true);
            if (intersections.length > 0) {
                callbackStart(group);
            } else {
                callbackEnd(group);
            }
        };
        return intersectionEvent;
    };
}