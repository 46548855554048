import { Box, Button, CircularProgress, IconButton, Popover, Stack } from '@mui/material';
import { Fragment, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import alertify from 'alertifyjs';

import { useAPI } from 'hooks/useAPI';
import { ISurveyMetadata } from '../../models/interfaces/ISurveyMetadata';
import { SeismicLineSelect } from './SeismicLineSelect';
import { useGetAll2DLines } from './api/useSeismicLineController';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { SeismicProductSelect } from './SeismicProductSelect';
import { useTileSize } from 'features/seismic/hooks/useTileSize';
import { Survey2DLines } from 'features/seismic/models/interfaces/I2DLines';
import { Product } from 'features/seismic/models/interfaces/IProducts';

interface IMetadataResponse {
    Metadata: ISurveyMetadata;
    Resolutions: number[];
}

export function SeismicLine2DSelector() {
    const { t } = useTranslation();

    const [opened, setOpened] = useState<boolean>(false);
    const [selected2DLine, setSelected2DLine] = useState<Survey2DLines | undefined>();
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

    const iconButtonElementRef = useRef<HTMLButtonElement | null>(null);

    const handleClickButton: MouseEventHandler<HTMLButtonElement> = (event) => {
        setOpened((value) => !value);
    };

    const { setSurveyMetadata, setVolumeToken, volumeToken, isReLoadingLineGeoemtry, setIsReLoadingLineGeoemtry, setLines2D } = useSeismicStore(state => ({
        volumeToken: state.volumeToken,
        setVolumeToken: state.setVolumeToken,
        setSurveyMetadata: state.setSurveyMetadata,
        isReLoadingLineGeoemtry: state.isReLoadingLineGeometry,
        setIsReLoadingLineGeoemtry: state.setIsReLoadingLineGeoemtry,
        setLines2D: state.setLines2D
    }));

    const { isLoading: loadingLines, data: dataLines } = useGetAll2DLines(volumeToken, 4326);

    const { width: tileWidthDefault } = useTileSize();
    const urlApi = '/Seismic/Volume/Metadata';
    const { execute: executeMainMetadata, isLoading: isLoading } = useAPI<IMetadataResponse>(urlApi, (error) => {
        if (error.message.indexOf('Request failed with status code 401') > -1) {
            alertify.alert('Authentication error');
        }
        else if (error.message.indexOf('Network Error') > -1) {
            alertify.alert('Network error');
        }
        else {
            alertify.alert('An error ocurred');
        }
    });

    useEffect(() => {
        if (dataLines) {
            setLines2D(dataLines);
        }
    }, [dataLines]);

    return (
        <Box>
            {loadingLines ? (
                <Stack direction={'row'}>
                    <Box marginRight={2}>
                        <CircularProgress style={{ color: 'white' }} />
                    </Box>
                </Stack>
            ) :
                (<Fragment>
                    <IconButton
                        id='2dLines'
                        color='primary'
                        ref={iconButtonElementRef}
                        onClick={handleClickButton}
                        title={t('2dLines')}>
                        <AlignVerticalCenterIcon />
                    </IconButton>
                </Fragment>)
            }

            <Popover
                anchorEl={iconButtonElementRef.current}
                open={opened}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                onClose={() => setOpened(false)}
            >
                <Stack style={{ backgroundColor: '#041f3b' }} padding={1} direction='column' justifyContent='flex-start' visibility={opened ? 'visible' : 'hidden'}>
                    <Box>
                        <SeismicLineSelect attributes={dataLines} selected={setSelected2DLine} />
                        <SeismicProductSelect selected2DLine={selected2DLine} selected={setSelectedProduct} />
                        {selectedProduct ?
                            (
                                <Button style={{ backgroundColor: '#ffffff', color: '#041f3b', marginTop: '10px', fontWeight: 'bold' }} onClick={async () => {
                                    if (selectedProduct) {
                                        if (Number(selectedProduct.SeismicId) !== Number(volumeToken)) {
                                            setIsReLoadingLineGeoemtry(true);
                                            const response = await executeMainMetadata({
                                                params: {
                                                    filePath: '',
                                                    volumeToken: selectedProduct.SeismicId,
                                                    tileWidth: tileWidthDefault,
                                                    screenHeight: 893
                                                }
                                            });

                                            if (response) {
                                                const metadata = response.Metadata;
                                                metadata.VolumeToken = selectedProduct.SeismicId;
                                                setSurveyMetadata(metadata);
                                                setVolumeToken(selectedProduct.SeismicId);
                                            }
                                            setIsReLoadingLineGeoemtry(false);
                                        }
                                    }
                                }}>
                                    Select Line
                                </Button>
                            ) : (
                                <Stack direction={'row'}>
                                    <Box marginRight={2}>
                                        <CircularProgress style={{ color: 'white' }} />
                                    </Box>
                                </Stack>
                            )
                        }
                    </Box>
                </Stack>
            </Popover>
        </Box>
    );
}