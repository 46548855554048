import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Button, Checkbox, CircularProgress, FormControl, IconButton, InputLabel, LinearProgress, Select, SelectChangeEvent, TextField, Tooltip, css, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddchartIcon from '@mui/icons-material/Addchart';
import DownloadIcon from '@mui/icons-material/Download';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { GetCatalog, GetUnits } from '../API/useLogViewerController';
import { IWellCatalog } from 'features/cross-section/models/interfaces/IWellCatalog';
import { IWellGenericCut } from 'features/cross-section/models/interfaces/IWellGenericCut';
import { ICrossSection } from 'features/cross-section/models/interfaces/ICrossSection';
import { useCrossSectionStore } from 'features/cross-section/stores/useCrossSectionStore';
import { ICrossSectionItem } from 'features/cross-section/models/interfaces/ICrossSectionItem';
import { getUrlParams } from 'features/seismic-3d/utils/Seismic3DUrlUtils';
import { useAPI } from 'hooks/useAPI';
import { ApiType } from 'models/enums/ApiType';
import { useSessionStore } from 'session/useSessionStore';

const style = css({
    width:'100%',
    // marginTop:'5%',
    // paddingTop:'5%',
    '& .filter-container':{
        display:'flex'
    },'& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
    },'& .toggle-popup-btn':{
        width:'100%',
    }

});

export function WellCurveCatalog({wellId = '' , pathFs = '', onChange , crossSection,crossSectionItem,globalSelect,isJustTextButton} : IProps){
    const [curveColor, setCurveColor] = useState('#FF0000');
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }));
    const {jwtToken} = useCrossSectionStore(state =>({
        jwtToken:state.jwtToken
    }));
    const theme = useTheme();

    const { t } = useTranslation('crossSection');
    const [ openPopup, setopenPopup ] = useState(false);
    const [ filterType, setFilterType ] = useState('1');
    const [ unit, setUnit ] = useState('');
    const [ filterTerm , setFilterTerm] = useState('');
    const urlParams = getUrlParams();
    const units = GetUnits();
    const catalog = GetCatalog(wellId  , pathFs, urlParams.tenantToken);
    const [rows, setRows] = useState<IWellCatalog[]>([]);
    const [rowSelectionModel, setRowSelectionModel] = useState<string[]>([]);
    const [selectedRows, setSelectedRows] = useState<IWellCatalog[]>([]);
    const handleChangeFilterType = (event : SelectChangeEvent) => setFilterType(event.target.value);
    const {laneWidth} =  useCrossSectionStore(state => ({
        laneWidth:state.laneWidth
    }));

    const { isLoading, execute, data } = useAPI<ICurveInfo>('/well/GetCruveInfo', (error) => {
        if (error.message.indexOf('Request failed with status code 401') > -1){
            alertify.alert(t('error.authentication'));
        }
        else if (error.message.indexOf('Network Error') > -1){
            alertify.alert(t('error.connectService'));
        }
        else {
            alertify.alert(t('error.thereIsAError'));
        }

    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checkIfSelected = (params:GridRenderCellParams<any, any, any, GridTreeNodeWithRender>)=>{
        let checked = false;
        let token = params.id;
        checked =  selectedRows.some((obj)=>obj.Token === token);
        return checked;
    };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeChecked = (params:GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) =>{
        let allSelectedRows = [...selectedRows];
        let token = params.id;

        if (checkIfSelected(params)){
            allSelectedRows = allSelectedRows.filter(function( row ) {
                return row.Token !== token;
            });
            setSelectedRows(allSelectedRows);
        } else {
            if (catalog){
                let newRow = rows.filter(function( row ) {
                    return row.Token === token;
                });
                allSelectedRows.push(newRow[0]);

                setSelectedRows(allSelectedRows);
            }

        }
    };
    const downloadCurveInfo = async (rowInfo:IRowInfow)=>{
        let logicalFile = rowInfo.LogicalFile;
        let frame = rowInfo.FrameName;

        const fileName = rowInfo.PathFileS3.split('/')[rowInfo.PathFileS3.split('/').length-1].split('.')[0];
        if (logicalFile.toLowerCase().indexOf('logicalfile(') === -1 ){
            logicalFile= `LogicalFile(${logicalFile})`;
        }
        if (frame.indexOf('\\[') === -1){
            if (frame.indexOf('[') > -1) {
                frame = frame.replace('[','\\[');
            }
        }

        if (frame.indexOf('\\]') === -1) {
            if (frame.indexOf(']') > -1) {
                frame = frame.replace(']','\\]');
            }
        }
        const response = await execute({apiType:ApiType.Render,params:{
            neumonic:rowInfo.Curve,
            frame:rowInfo.FrameName,
            LogicalFile:logicalFile,
            paths:rowInfo.ViewerPath},
        jwtToken:jwtToken});
        if (response){
            let mergedTabedArray = response?.index.map((el,idx)=>`${el}\t${response.curve[idx]}`);
            const textOutput = mergedTabedArray?.join('\n');
            const file = new Blob([textOutput], {type: 'text/plain'});
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.download = `${fileName}.txt`;
            link.href = url;
            link.click();
        }

    };
    const columns: GridColDef[] = [
        {
            field: 'selection',
            headerName: '',
            width: 50,
            sortable: false,
            renderCell: (params) => {
                return <Checkbox
                    sx={{color:'black!important' }}
                    checked={checkIfSelected(params)}
                    onChange={(event) =>{handleChangeChecked(params)}}
                />;
            }
        },
        {
            field: 'Curve',
            headerName: 'Curve',
            width: 100,
            editable: false,
        },
        {
            field: 'Description',
            headerName: 'Description',
            width: 150,
            editable: false,
        },
        {
            field: 'Units',
            headerName: 'Units',
            type: 'number',
            width: 110,
            editable: false,
        },
        {
            field: 'MinDepth',
            headerName: 'Min Depth',
            type: 'number',
            width: 110,
            editable: false,
        },
        {
            field: 'MaxDepth',
            headerName: 'Max Depth',
            type: 'number',
            width: 110,
            editable: false,
        },
        {
            field: 'MinCurve',
            headerName: 'Min Curve',
            type: 'number',
            width: 110,
            editable: false,
        },
        {
            field: 'MaxCurve',
            headerName: 'Max Curve',
            type: 'number',
            width: 110,
            editable: false,
        },
        {
            field: 'pathFileS3',
            headerName: 'Path File S3',
            width: 110,
            editable: false,
        },
        {
            field: 'CurveHeaderToken',
            headerName: 'Curve Header Token',
            width: 110,
            editable: false,
        },
        {
            field: 'downloadButton',
            headerName: '',
            sortable: false,
            width: 50,
            renderCell: (params) => {
                return <IconButton onClick={()=>{
                    console.log(params);
                    const rowInfo = {
                        ChannelName:params.row.ChannelName,
                        Curve:params.row.ChannelName,
                        Description:params.row.Description,
                        FrameName:params.row.FrameName,
                        IndexUnit:params.row.IndexUnit,
                        LogicalFile:params.row.LogicalFile,
                        MaxCurve:params.row.MaxCurve,
                        MinCurve:params.row.MinCurve,
                        MinDepth:params.row.MinDepth,
                        MaxDepth:params.row.MaxDepth,
                        Units:params.row.Units,
                        PathFileS3:params.row.pathFileS3,
                        ViewerPath:params.row.PathViewer
                    };
                    if (crossSection){
                        downloadCurveInfo(rowInfo);
                    }}}><DownloadIcon></DownloadIcon></IconButton>;
            }
        },
    ];
    const handleChangeUnit = (event : SelectChangeEvent) => {
        const unit = event.target.value;
        setUnit(unit);

    };
    const filter = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>{
        const term = event.target.value;
        setFilterTerm(term);
    };
    const resetFiltes = () =>{
        setFilterTerm('');
        setUnit('');
    };
    const selectAllCurves = () =>{
        let cut:IWellGenericCut;
        let rows = [];
        if (catalog.data){
            selectedRows.forEach(curve => {
                if (crossSection && catalog.data){
                    cut ={
                        CompositeProfileToken: curve.Token ?? '',
                        GenericCutName:curve.Curve,
                        GenericCutPathToken:curve.Curve+crossSection.WellStart+crossSection.WellEnd,
                        GenericCutToken:curve.Token ?? '',
                        GenericCutType:'log',
                        WellStartDepth:crossSection.WellStart,
                        WellEndDepth:crossSection.WellEnd,
                        StartDepth:crossSection.WellStart,
                        EndDepth:crossSection.WellEnd,
                        IsDefault:false,
                        PixelPerMeter:1,
                        Width:laneWidth,
                        Height:1000,
                        WellId:0,
                        LogicalFile:curve.LogicalFile,
                        Frame:curve.FrameName,
                        CurveUnit: curve.Units,
                        ViewerPath:curve.PathViewer,
                    };
                    onChange([curve],cut);
                }
            });
        }
    };
    const getSelected = () =>{
        const selected = selectedRows[0];
        if (crossSection){
            if (selected){
                const cut:IWellGenericCut = {
                    CompositeProfileToken:selected.Token ?? '',
                    GenericCutName:selected.Curve,
                    GenericCutPathToken:selected.Curve+crossSection.WellStart+crossSection.WellEnd,
                    GenericCutToken:selected.Token ?? '',
                    GenericCutType:'log',
                    WellStartDepth:crossSection.WellStart,
                    WellEndDepth:crossSection.WellEnd,
                    StartDepth:crossSection.WellStart,
                    EndDepth:crossSection.WellEnd,
                    IsDefault:false,
                    PixelPerMeter:1,
                    Width:laneWidth,
                    Height:1000,
                    WellId:0,
                    LogicalFile:selected.LogicalFile,
                    Frame:selected.FrameName,
                    CurveUnit: selected.Units,
                    ViewerPath:selected.PathViewer
                };
                onChange(selectedRows,cut);
            } else {
                const cut:IWellGenericCut = {
                    CompositeProfileToken: '',
                    GenericCutName:'',
                    GenericCutPathToken:'',
                    GenericCutToken: '',
                    GenericCutType:'log',
                    WellStartDepth:crossSection.WellStart,
                    WellEndDepth:crossSection.WellEnd,
                    StartDepth:crossSection.WellStart,
                    EndDepth:crossSection.WellEnd,
                    IsDefault:false,
                    PixelPerMeter:1,
                    Width:laneWidth,
                    Height:1000,
                    WellId:0,
                    LogicalFile:'',
                    Frame:'',
                    CurveUnit:'',
                    ViewerPath:''
                };
                onChange([],cut);
            }
        }
    };
    useEffect(()=>{
        if (catalog){
            if (catalog.data && !catalog.error){
                catalog.data = catalog.data.sort((a,b) => (a.Curve > b.Curve) ? 1 : ((b.Curve > a.Curve) ? -1 : 0));
                let newRows = [...catalog.data];
                setRows(catalog.data);
                if (filterTerm !== ''){

                    if (filterType === '1'){
                        newRows = newRows.filter( el => el.Curve.toLowerCase().indexOf(filterTerm.toLocaleLowerCase()) !== -1);
                    } else if (filterType === '2'){
                        newRows = newRows.filter( el => el.Description.toLowerCase().indexOf(filterTerm.toLocaleLowerCase()) !== -1);
                    }
                    else {
                        newRows = newRows.filter( el => el.Description.toLocaleLowerCase().indexOf(filterTerm.toLocaleLowerCase()) !== -1) || catalog.data?.filter((el)=> el.Curve.toLocaleLowerCase().indexOf(filterTerm.toLocaleLowerCase()) !== -1);
                    }
                }
                if (unit !== ''){
                    newRows = newRows.filter((el) => el.Units.toLocaleLowerCase() === (unit.toLocaleLowerCase()));
                }
                setRows(newRows);
            }};
        if (crossSectionItem && crossSectionItem.Curves){
            let allSelectedRows = [...selectedRows];
            crossSectionItem.Curves.forEach((curve) => {
                if (selectedRows.indexOf(allSelectedRows[0])=== -1){allSelectedRows.push(curve)}

                setSelectedRows(allSelectedRows);});
        }
    },
    [catalog.data,unit,filterTerm]);
    useEffect(()=>{

        // let curves:IWellCatalog[] = selectedRows;
        let curves:IWellCatalog[] = [];
        if (catalog.data){
            rowSelectionModel.forEach((row) =>{
                const curve = catalog.data.filter(el => el.Token === row)[0];
                // const inAr = curves.filter(val => val.Token === curve.Token).length === 0;
                // if (inAr){
                curves.push(curve);
                // }
            });
            setSelectedRows(curves);
        }

    },[rowSelectionModel]);

    return (
        <Box  css={style}>
            {!isJustTextButton &&
            <Tooltip title="Click to select which curves will be displayed in the panel" >
                <IconButton sx={{color:'white'}} className='toggle-popup-btn' size="small" onClick={ () => {setopenPopup(true); resetFiltes()}}>
                    <AddchartIcon></AddchartIcon>
                </IconButton>
            </Tooltip>
            }
            {isJustTextButton &&
            <Tooltip title="Click to select which curves will be displayed in the panel" >
                <Button className='toggle-popup-btn' style={{backgroundColor:'white'}} sx={{marginTop:'6%'}} variant="outlined"  size="small" onClick={ () => {setopenPopup(true); resetFiltes()}}>{t('selectCurves')}</Button>
            </Tooltip>
            }

            <div className ='popup'>
                <GeopostPopup
                    temporaryIgnoreFlexInContainer
                    open={openPopup}
                    title={t('catalog')}
                    onChangeOpen={value => setopenPopup(value) }
                    overlay={false}
                    height={750}
                    width={1150}>
                    <div >
                        <div className='filter-container'>
                            <FormControl sx={{display:'inline-flex'}}>
                                <TextField sx={{ m: 1, minWidth: 400 ,height:'100'}} id="search-nmonic-desc" label={t('search')} variant="outlined" value={filterTerm} onChange={filter}/>
                            </FormControl>
                            <FormControl  sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="select-filter-type-label">{t('filterType')}</InputLabel>
                                <Select
                                    native
                                    labelId="select-filter-type-label"
                                    id="select-filter-type"
                                    value={filterType.toString()}
                                    onChange={handleChangeFilterType}
                                >

                                    <option value={1}>Nmonic</option>
                                    <option value={2}>Description</option>
                                    <option value={3}>Nmonic and Description</option>
                                </Select>
                            </FormControl>

                            {units.isLoading && <CircularProgress />}

                            {units &&!units.isLoading && units.data &&
                        <FormControl  sx={{ m: 1}}>
                            <Select
                                native
                                labelId="select-unit-label"
                                id="select-unit"
                                value={unit}
                                onChange={handleChangeUnit}
                            >
                                <option value={''}>{t('unit')}</option>
                                {units?.data?.sort().map(el =><option  key={el} value={el}>{el}</option>)}
                            </Select>
                        </FormControl>
                            }
                            {globalSelect && <FormControl>
                                <Button style={{color:'white', backgroundColor: tenantConfig?.main.palette.secondary.contrastText,marginTop: '8%'}}  onClick={selectAllCurves}> Add {selectedRows.length} curves to plot </Button>
                            </FormControl>
                            }
                            {!globalSelect && <FormControl >
                                <Button style={{marginTop: '14%',color:'white',backgroundColor: tenantConfig?.main.palette.secondary.contrastText}}  onClick={getSelected}>{t('selectedData')}</Button>
                            </FormControl>}
                            {globalSelect && <FormControl>
                                <Button onClick={()=>setSelectedRows([])} style={{color:'white', backgroundColor: tenantConfig?.main.palette.secondary.contrastText,marginTop: '22%', marginLeft: '10%'}}>Clear</Button>
                            </FormControl>}

                        </div>
                        <div style={{height:'100%'}}>
                            {/* {catalog.isLoading && <CircularProgress />} */}
                            {!catalog.isError && !catalog.isLoading && catalog.data &&
                        <DataGrid
                            getRowId={(row) => row.Token}
                            autoHeight
                            rows={rows}
                            columns={columns}
                            slots={{
                                loadingOverlay: LinearProgress,
                            }}
                            loading={catalog.isLoading}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            //checkboxSelection
                            // onRowSelectionModelChange={(newRowSelectionModel) => {
                            //     setRowSelectionModel(newRowSelectionModel as string[]);
                            // }}
                            // rowSelectionModel={rowSelectionModel}
                        />
                            }
                        </div>
                    </div>
                </GeopostPopup>
            </div>
        </Box>
    );

}
interface IProps{
    wellId?: string;
    pathFs?: string;
    crossSection?:ICrossSection;
    crossSectionItem?:ICrossSectionItem;
    globalSelect?: Boolean;
    isJustTextButton? :Boolean;
    onChange(neumonic:IWellCatalog[],cut:IWellGenericCut):void;
};
interface IRowInfow{
        ChannelName: string;
        Curve: string;
        Description: string;
        FrameName: string;
        IndexUnit: string;
        LogicalFile: string;
        MaxCurve: Number;
        MinCurve: Number;
        MinDepth: string;
        MaxDepth: string;
        Units: string;
        PathFileS3:string;
        ViewerPath:string;
}
interface ICurveInfo{
    index:Number[],
    curve:Number[],
    Unit:string,
    spacing:string
}