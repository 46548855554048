import { useMemo } from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { useMapStore } from '../stores/useMapStore';

export const useTileSize = () => {
    const defaultTileSize = useMapStore(state => state.defaultTileSize);

    const project2DDefaults = useSessionStore(state => state.tenantConfiguration?.viewer2DDefaults);

    const tileSize = useMemo(() => {
        const tileWidth = project2DDefaults?.tile_width ? parseFloat(project2DDefaults.tile_width) : defaultTileSize.width;
        const tileHeight = project2DDefaults?.tile_height ? parseFloat(project2DDefaults.tile_height) : defaultTileSize.height;

        return ({
            width: tileWidth,
            height: tileHeight
        });
    }, [defaultTileSize, project2DDefaults]);

    return tileSize;
};