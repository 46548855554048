import { Box, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs } from '@mui/material';
import React from 'react';
import { useState } from 'react';

import { usePerformanceMonitoringStore } from '../../stores/usePerformanceMonitoringStore';
import { MetadataPerformancePanel } from './MetadataPerformancePanel';
import { TilesPerformancePanel } from './TilesPerformancePanel';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { LinesPerformancePanel } from './LinesPerformancePanel';
import { SurveyShapePerformancePanel } from './SurveyShapePerformancePanel';
import { PrepareRenderPanel } from './PrepareRenderPanel';

export type PerformancePanelProps = {
    surveyType : SurveyType
};

export const PerformancePanel = ({ surveyType } : PerformancePanelProps) => {

    const [ selectedIndex, setSelectedIndex ] = useState<number>(0);

    return (
        <Box>
            <Tabs value={selectedIndex} onChange={(event, index) => setSelectedIndex(index)}>
                <Tab label='Metadata'/>
                <Tab label='Tiles'/>
                <Tab label='Lines'/>
                <Tab label='Prepare Render'/>

                {
                    surveyType === SurveyType.Seismic3D
                    &&
                    <Tab label='Survey shape'/>
                }
            </Tabs>
            <Box hidden={selectedIndex !== 0}>
                {
                    selectedIndex === 0 &&
                    <MetadataPerformancePanel/>
                }
            </Box>
            <Box hidden={selectedIndex !== 1}>
                {
                    selectedIndex === 1 &&
                    <TilesPerformancePanel surveyType={surveyType}/>
                }
            </Box>
            <Box hidden={selectedIndex !== 2}>
                {
                    selectedIndex === 2 &&
                    <LinesPerformancePanel linesErrorData={[]} surveyType={surveyType}/>
                }
            </Box>
            <Box hidden={selectedIndex !== 3}>
                {
                    selectedIndex === 3 &&
                    <PrepareRenderPanel/>
                }
            </Box>
            <Box hidden={selectedIndex !== 4}>
                {
                    selectedIndex === 4 &&
                    <SurveyShapePerformancePanel/>
                }
            </Box>
        </Box>

    );
};