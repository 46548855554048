import * as React from 'react';
import { SVGProps } from 'react';
const SvgGrid = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 700 700"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        {...props}
    >
        <path d="M233.33 416V276H116.67v-46.668h116.66v-116.66h46.668v116.66h140v-116.66h46.668v116.66h116.66V276h-116.66v140h116.66v46.668h-116.66v116.66h-46.668v-116.66h-140v116.66H233.33v-116.66H116.67V416zm46.668 0h140V276h-140zm-210-326.65c0-12.895 10.441-23.348 23.348-23.348h513.3c12.895 0 23.348 10.441 23.348 23.348v513.3c0 12.895-10.441 23.348-23.348 23.348h-513.3c-12.895 0-23.348-10.441-23.348-23.348zm46.668 489.98h466.66V112.67h-466.66z" />
    </svg>
);
export default SvgGrid;
