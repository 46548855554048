import { Box, Button, CircularProgress, css, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Fragment, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import alertify from 'alertifyjs';
import { MainTableCss } from 'fileIndexing/envVar/envVar';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';

import { IPreviewFile } from '../interfaces/ITaxonomy';
import { usePreviewFilesReindexChange, useReindexSubgroup } from '../useTaxonomy';

const style = css({
    margin: '0 !important',
    width: '100% !important',
    // display: 'flex',
    '& .title': {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '16px',
    },
    '& .table': {
        display: 'flex',
        flexDirection: 'column',
    },
    '& .header-button': {
        marginBottom: '2rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    '& .apply-reindex': {
        marginTop: '16px',
        width: '150px',
        alignSelf: 'flex-end',
    }
});

export const ReindexTable = ({ setOpenModal, groupName, subGroupName, reindexToken }: Props) => {
    const [filteredData, setFilteredData] = useState<IPreviewFile[]>([]);
    const [filter, setFilter] = useState<string>('');

    const [reindex, setReindex] = useState<boolean>(false);

    const { data: fileReindexData, isLoading: fileReindexLoading } = usePreviewFilesReindexChange(reindexToken);
    const { data: reindexSubgroupData } = useReindexSubgroup(reindexToken, reindex);

    const applyReindex = () => {
        setReindex(true);
    };

    // realiza o filtro
    useEffect(() => {
        if (fileReindexData) {
            if (filter === '') {
                setFilteredData(fileReindexData);
            }
            else {
                setFilteredData(fileReindexData.filter(x => x.Name?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.OriginalPath?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.SubGroupName?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.NewSubGroupName?.toLowerCase().startsWith(filter.toLowerCase())));
            }
        }
    }, [filter]);

    useEffect(() => {
        if (fileReindexData) {
            setFilteredData(fileReindexData);
        }
    }, [fileReindexData]);

    useEffect(() => {
        if (reindexSubgroupData) {
            if (!reindexSubgroupData.error) {
                alertify.alert('Reindex Successful');
            }
            setReindex(false);
        }
    }, [reindexSubgroupData]);

    // conteúdo da tabela
    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerAlign: 'left',
            headerName: 'Name',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'OriginalPath',
            headerAlign: 'left',
            headerName: 'Original Path',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'SubGroupName',
            headerAlign: 'left',
            headerName: 'Current Subgroup Name',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'NewSubGroupName',
            headerAlign: 'left',
            headerName: 'New Subgroup Name',
            minWidth: 120,
            flex: 1,
        },
    ];

    const getRowId = (row: IPreviewFile) => row.Token;

    return (
        <Fragment>
            <Box css={[style, MainTableCss]}>
                <Box className='table'>
                    <Box className='table-header'>
                        <Box>
                            <Typography>Group: {groupName}</Typography>
                            <Typography>Subgroup: {subGroupName}</Typography>
                        </Box>
                        <Box className='search'>
                            <TextField label='Type to quick filter filename' variant='outlined' size='small' color='primary' value={filter} onChange={(event) => setFilter(event.target.value)} />
                        </Box>
                    </Box>
                    <GeopostTable<IPreviewFile> filteredData={filteredData} loading={fileReindexLoading} columns={columns} getRowId={getRowId} />
                    <Button variant='contained' color='primary' type='submit' className='apply-reindex' onClick={() => {applyReindex()}}>APPLY REINDEX</Button>
                </Box>
            </Box>
        </Fragment>
    );
};

interface Props {
    setOpenModal: (value: boolean) => void;
    groupName: string;
    subGroupName: string;
    reindexToken: string;
}