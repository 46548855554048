import React from 'react';
import { Button, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';

import { GeopostCheckbox } from 'components/geopostCheckbox/GeopostCheckbox';
import { FeatureGeom } from 'features/seismic-3d/models/classes/FeatureGeom';
import { Seismic3DData } from 'features/seismic-3d/models/classes/Seismic3DData';
import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';
import { IntersectingSeismicCheckbox } from './IntersectingSeismicsList/IntersectingSeismicCheckbox';
import { SeismicsListRow } from './SeismicsListRow';

type Props = {
    onSelectSeismicCallback: (seismicData: Seismic3DData, seismicGeom: FeatureGeom) => void,
    onUnselectSeismicCallback: (seismic3DData: Seismic3DData) => void,
    seismics: Seismic3DData[],
    selectedSeismics: Seismic3DData[],
    isLoadingCallback: (Seismic3DData: Seismic3DData) => boolean,
};

export const SeismicsList = ({ onSelectSeismicCallback, onUnselectSeismicCallback, seismics, selectedSeismics, isLoadingCallback } : Props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            border: '1px solid #d9d9d9',
            whiteSpace: 'nowrap'
        },
        [`&.${tableCellClasses.body}`]: {
            border: '1px solid #d9d9d9'
        },
    }));

    const gridSelectedSeismic = use3DGridStore(state => state.gridSelectedSeismic);
    const setGridSelectedSeismic = use3DGridStore(state => state.newGridSelectedSeismic);
    const removeGridsSelectedSeismic = use3DGridStore(state => state.removeGridSelectedSeismic);

    return (
        <TableContainer sx={{mt: '20px', maxWidth: '800px', '& > *': { borderBottom: 'unset' }}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell size='small'>
                            Details
                        </TableCell>
                        <TableCell size='small'>
                            Select
                        </TableCell>
                        <TableCell size='small'>
                            Title
                        </TableCell>
                        <TableCell size='small' sx={{whiteSpace: 'nowrap'}}>
                            Select for grid
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {seismics && seismics.map((seismic, index) => (
                        <SeismicsListRow
                            seismic={seismic}
                            key={index}
                            checked={!!selectedSeismics.find(selectedSeismic => selectedSeismic.volumeToken === seismic.volumeToken)}
                            onSelect={onSelectSeismicCallback}
                            onUnselect={() => onUnselectSeismicCallback(seismic)}
                            onGridCheckedChange={checked => checked ? setGridSelectedSeismic(seismic) : removeGridsSelectedSeismic()}
                            gridChecked={gridSelectedSeismic === seismic}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};