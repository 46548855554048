export interface IDataGroup {
    Token: string,
    Name: string,
    DataSubGroupsTokens: string[],
    Children: IDataGroup[],
    ParentGroupName: string,
    ParentGroupToken: string,
    Label: string
}

export interface IDataSubGroup {
    dataSubGroups: ISubgroup[]
}

export interface ISubgroup {
    CopyTransmittalFilesToInternalPath: boolean,
    DataGroupName: string,
    DataGroupToken: string,
    DataSubRulesTokens: string[],
    SubGroupEndpointTokens: string [],
    InternalPath: string,
    Name: string,
    RegexPathExtractRule: string,
    Token: string,
    TransmittalInternalPath: string
}

export interface ISubRule {
    DataSubGroupToken: string,
    InstitutionToken: string,
    Priority: number,
    ProjectToken: string,
    Rule: string,
    RuleType: number,
    MinFileSize: number,
    MaxFileSize: number,
    ExclusionRegex: string,
    Token: string,
}

export interface IPreviewFile {
    Name: string,
    Token: string,
    OriginalPath: string,
    SubGroupName: string,
    SubGroupToken: string,
    NewSubGroupName: string,
    NewSubGroupToken: string,
    InstitutionToken: string,
    DataEntityToken: string,
    Tags: string[],
}

export interface IRule {
    Token: number,
    Group: string,
    Subgroup: string,
    Rule: string,
    Priority: number,
    SubgroupToken: string,
    EntityName: string,
    Tags: string,
    PreviewPath: string,
}

export interface IEndpoint {
    SubGroupToken: string,
    EndpointToken: string,
    EndpointType: eSubGroupEndpointType,
    Url: string,
    Label: string,
    RestrictedExtensions: string,
}

export enum eSubGroupEndpointType {
    Viewing = 1,
    Indexing = 2,
}