import { Fragment, useEffect, useState } from 'react';
import { Box, CircularProgress, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';

import { Survey2DLines } from 'features/seismic/models/interfaces/I2DLines';
import { Product, SeismicProduct } from 'features/seismic/models/interfaces/IProducts';
import { useGetAllProductsBySurvey } from './api/useSeismicLineController';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';

export function SeismicProductSelect({ selected2DLine, selected }: Props) {
    const [products, setProducts] = useState<Product[] | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<string>('');

    // const { isLoading: loadingProducts, data: dataProducts } = useGetAllProductsBySeismicIds(seismicIds);
    const { isLoading: loadingProducts, data: dataProducts } = useGetAllProductsBySurvey(selected2DLine?.SurveyId);

    const { volumeToken } = useSeismicStore(state => ({
        volumeToken: state.volumeToken
    }));

    const handleChange = (seismicId: string) => {
        if (dataProducts) {
            const selectedProduct = dataProducts.products.find(el => Number(el.SeismicId) === Number(seismicId));
            if (selectedProduct) {
                setSelectedProduct(selectedProduct.SeismicId);
                selected(selectedProduct);
            }
        }
    };

    useEffect(() => {
        if (dataProducts) {
            let products = dataProducts.products.sort((a, b) => {
                const nameA = a.ProductName.toUpperCase();
                const nameB = b.ProductName.toUpperCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });

            setProducts(products);
            let selectedProduct = products.find(el => Number(el.SeismicId) === Number(volumeToken));

            if (selectedProduct) {
                setSelectedProduct(selectedProduct.SeismicId);
                selected(selectedProduct);
            }
            else {
                setSelectedProduct(products[0].SeismicId);
                selected(products[0]);
            }
        }
    }, [dataProducts]);

    useEffect(() => {
        if (loadingProducts) {
            selected(null);
        }
    }, [loadingProducts]);

    return (
        <Fragment>
            <Typography style={{color: '#ffffff'}}>Products</Typography>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                {
                    loadingProducts ?
                        (
                            <Stack direction={'row'}>
                                <Box marginRight={2}>
                                    <CircularProgress style={{ color: '#ffffff' }} />
                                </Box>
                            </Stack>
                        )
                        :
                        (!!products && products.length > 0) ?
                            (
                                <Select style={{ fontSize: '14px', color:'#ffffff', padding: '3px', marginRight: '5px' }} value={selectedProduct} native onChange={(event) => handleChange(event.target.value)}>

                                    {
                                        (
                                            products.map(el => <option style={{ padding: '10px', color: '#000000' }} key={'product-' + el.SeismicId} value={el.SeismicId}>{el.ProductName}</option>)
                                        )
                                    }
                                </Select>
                            )
                            :
                            <Typography color='white' padding='10px' variant='caption'>No products available.</Typography>
                }
            </Box>
        </Fragment>
    );
}

interface Props {
    selected2DLine: Survey2DLines | undefined;
    selected: (value: Product | null) => void;
}