import { Extent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

import { IRangeTrace, IScale } from 'features/seismic/stores/useSeismicStore';
import { ISeismicCalculatorLineType } from '../interfaces/ISeismicCalculatorLineType';
import { ISurveyMetadata } from '../interfaces/ISurveyMetadata';
import { SampleIntervalUnity } from '../enums/SampleIntervalUnity';

export abstract class SeismicCalculator {
    abstract calculateExtent(surveyMetadata: ISurveyMetadata, scale: IScale, rangeTrace: IRangeTrace | null): Extent;
    abstract calculateCenter(extent: Extent, surveyMetadata: ISurveyMetadata, scale: IScale, rangeTrace: IRangeTrace | null): Coordinate;

    getInitialRangeTrace(surveyMetadata: ISurveyMetadata):IRangeTrace | null {
        return null;
    }

    setLineTypeCalculator (calculator: ISeismicCalculatorLineType) {};

    calculateLineStart (surveyMetadata: ISurveyMetadata) {
        return 0;
    }

    getMinTrace (surveyMetadata: ISurveyMetadata) {
        return 1;
    }

    getMaxTrace (surveyMetadata: ISurveyMetadata) {
        return 1;
    }

    getLineIncrement (surveyMetadata: ISurveyMetadata) {
        return 1;
    }

    getSkipTrace (surveyMetadata: ISurveyMetadata) {
        return 1;
    }

    calculateCenterX(extent: Extent) {
        return (extent[0] + extent[2]) / 2;
        // return minTrace + (mapContainerWidth * resolutions[0] * scaleX / 2);
    }

    calculateCenterY(totalSamples: number, scaleY: number) {
        return (totalSamples * scaleY / 2 * -1);
    }

    calculateSampleInterval(surveyMetadata: ISurveyMetadata){
        if (surveyMetadata.Header.SampleIntervalUnit === SampleIntervalUnity.Microsecond){
            return surveyMetadata.Header.SampleInterval/1000;
        }

        return surveyMetadata.Header.SampleInterval;
    }

    getTotalTraces(surveyMetadata: ISurveyMetadata) {
        return 0;
    }
}