import { Button, Grid } from '@mui/material';
import { forwardRef, Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import alertify from 'alertifyjs';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { GeopostDropdown } from 'fileIndexing/otherComponents/GeopostDropdown';
import { FormContainer } from 'react-hook-form-mui';
import { useGetAllDataSubGroups } from 'fileIndexing/taxonomies/useTaxonomy';

import { ITagGroup } from '../interfaces/ITag';
import { useSaveTagGroup } from '../useTags';
// import { useSaveTagGroup } from '../useTagGroup';

export const TagGroupsForm = ({ setOpenModal, refetchTableData, setLoading, formData }: Props) => {
    const { control } = useForm<ITagGroup>({ defaultValues: formData || undefined });

    const [data, setData] = useState<ITagGroup | null>(null);
    const [dataSubGroupsItems, setDataSubGroupsItems] = useState<{id: string, label: string}[]>([]);

    const { data: saveTagGroupsData, isLoading } = useSaveTagGroup(data);
    const { data: dataSubGroups, isLoading: dataSubGroupLoading } = useGetAllDataSubGroups();

    const handleData = (data: ITagGroup) => {
        let newData = data;
        if (formData) {
            newData.Token = formData.Token;
        }
        else {
            newData.Token = '';
        }
        setData(newData);
    };

    // AVISO: talvez seja retirada essa parte
    function wait(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function executeSave(): Promise<void> {
        setOpenModal(false);
        setLoading(true);
        await wait(2000);
        setLoading(false);
        refetchTableData();
    }

    useEffect(() => {
        if (saveTagGroupsData) {
            if (saveTagGroupsData === 'Success') {
                executeSave();
            }
            else {
                alertify.notify('Ocorreu um erro criando uma nova Instituição');
            }
        }
    }, [saveTagGroupsData]);

    useEffect(() => {
        if (dataSubGroups) {
            let createDataSubGroupItems: {id: string, label: string}[] = [];

            createDataSubGroupItems.push({
                id: '',
                label: 'None'
            });

            let allDataSubGroups = dataSubGroups.map(dataSubGroup => ({
                id: dataSubGroup.Token,
                label: dataSubGroup.Name
            }));

            allDataSubGroups.forEach((item) => createDataSubGroupItems.push(item));

            setDataSubGroupsItems(createDataSubGroupItems);
        }
    }, [dataSubGroups]);

    return (
        <Fragment>
            <FormContainer<ITagGroup> defaultValues={formData ?? {}} onSuccess={(data) => handleData(data)} FormProps={{ style:{ width: '100%' } }}>
                <Grid spacing={2} container>
                    <Grid spacing={2} container item>
                        <Grid xs={12} item>
                            <GeopostTextField fieldName={'Name'} label={'Tag Group Name'} />
                        </Grid>
                        <Grid xs={12} item>
                            <GeopostDropdown fieldName={'DataSubgroupToken'} label={'Sub Groups'} required={false} items={dataSubGroupsItems} loading={dataSubGroupLoading} />
                        </Grid>
                    </Grid>
                    <Grid container item direction="row-reverse">
                        <Button disabled={dataSubGroupLoading} variant='contained' color='primary' type='submit'>SAVE</Button>
                    </Grid>
                </Grid>
            </FormContainer>
        </Fragment >
    );
};

interface Props {
    setOpenModal: (value: boolean) => void;
    refetchTableData: () => void;
    setLoading: (value: boolean) => void;
    formData?: ITagGroup | null;
}