import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { LineType } from '../../enums/LineType';
import { IGeopostSeismicLayerParams } from '../../interfaces/IGeopostSeismicLayerParams';

export abstract class TileSourceStrategy {

    protected apiUrl: string;

    constructor(apiUrl: string, onTileRequestError: (xhr: XMLHttpRequest, lineNumber: number, tileWidth: number, startTrace: number, endTrace: number) => void = () => {},
        onSettleTileRequest: (xhr: XMLHttpRequest, lineNumber: number,  tileWidth: number, requestSendAt: Date) => void = () => {}) {
        this.apiUrl = apiUrl;
        this.onSettleTileRequest = onSettleTileRequest;
        this.onTileRequestError = onTileRequestError;
    }
    protected addParamToUrl(param: string) {
        return `&${param}=[${param}]`;
    }

    protected addParamValueToUrl(param: string, value: string) {
        return `&${param}=${value}`;
    }

    public getTileUrl(params: IGeopostSeismicLayerParams) {
        return `${this.apiUrl}?render=geopost` +
        this.addParamValueToUrl('volumeToken', params.volumeToken!) +
        this.addParamValueToUrl('forceDisableCache', String(params.forceDisableCache)) +
        this.addParamValueToUrl('filePath', params.filePath!) +
        this.addParamValueToUrl('colorbar', params.colorbar!) +
        this.addParamValueToUrl('skipTraceCount', params.skipTraceCount!.toString()) +
        this.addParamValueToUrl('byteSize', params.byteSize!.toString()) +
        this.addParamValueToUrl('parallelProcesses', params.parallelProcesses!.toString()) +
        this.addParamValueToUrl('tileWidth', '[tileWidth]') +
        this.addParamValueToUrl('tileHeight', '[tileHeight]') +
        this.addParamValueToUrl('dpi', params.dpi!.toString()) +
        this.addParamValueToUrl('jpgQuality', params.jpgQuality!.toString()) +
        this.addParamValueToUrl('isIndexedVolume', String(params.isIndexedVolume)) +
        this.addParamValueToUrl('traceRangeStart', params.traceRangeStart!.toString()) +
        this.addParamValueToUrl('traceRangeEnd', params.traceRangeEnd!.toString()) +
        this.addParamValueToUrl('sampleInterval', params.sampleInterval!.toString()) +
        this.addParamValueToUrl('sourceApi', params.sourceApi!) +
        this.addParamValueToUrl('applyWatermark', params.applyWatermark!.toString()) +
        this.addParamValueToUrl('surveyInlineStart', params.surveyInlineStart!.toString()) +
        this.addParamValueToUrl('surveyInlineEnd', params.surveyInlineEnd!.toString()) +
        this.addParamValueToUrl('surveyXlineStart', params.surveyXlineStart!.toString()) +
        this.addParamValueToUrl('surveyXlineEnd', params.surveyXlineEnd!.toString()) +
        this.addParamValueToUrl('surveyInlineIncrement', params.surveyInlineIncrement!.toString()) +
        this.addParamValueToUrl('surveyXlineIncrement', params.surveyXlineIncrement!.toString()) +
        this.addParamValueToUrl('tenantToken', params.tenantToken!) +
        this.addParamValueToUrl('inlineDimensionName', params.inlineDimensionName!) +
        this.addParamValueToUrl('sampleDimensionName', params.sampleDimensionName!)+
        this.addParamValueToUrl('xlineDimensionName', params.xlineDimensionName!) +
        this.addParamValueToUrl('cdpDimensionName', params.cdpDimensionName!) +
        this.addParamValueToUrl('imageCacheSystem', params.imageCacheSystem!.toString()) +
        this.addParamValueToUrl('samplesCacheSystem', params.samplesCacheSystem!.toString()) +
        this.addParamValueToUrl('forceDisableSamplesCache', getParamsFromUrl().forceDisableSamplesCache.toString()) +
        this.addParamValueToUrl('volumeSamplesPerTrace', params.volumeSamplesPerTrace!.toString()) +
        this.addParamValueToUrl('inverted', params.isInverted ? 'true' : 'false') +
        this.addParamValueToUrl('antialias', params.isAntialiasApplied ? 'true' : 'false');
    };

    public abstract getFullUrl: (src: string, xStart: number, xEnd: number, yStart: number, yEnd: number, minGain: number, maxGain: number) => string;

    public abstract getEndpoint: () => string;

    public onTileRequestError: (xhr: XMLHttpRequest, lineNumber: number, tileWitdth: number, startTrace: number, endTrace: number) => void;

    public onSettleTileRequest: (xhr: XMLHttpRequest, lineNumber: number, tileWidth: number, requestSendAt: Date) => void;

    public abstract getSurveyLineDimensions : (params: IGeopostSeismicLayerParams) => [number, number, number];

    public abstract getTileLoadingKey: (fullUrl: string) => string;

    protected extractUrlValue = (url: string, key: string) =>
    {
        const match = url.match('[?&]' + key + '=([^&]+)');
        return match ? match[1] : null;
    };
}