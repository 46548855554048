import { Extent } from 'ol/extent';

import { IRangeTrace, IScale } from 'features/seismic/stores/useSeismicStore';
import { SeismicCalculator } from './SeismicCalculator';
import { ISurveyMetadata } from '../interfaces/ISurveyMetadata';

export class SeismicCalculator2DIndexed extends SeismicCalculator {
    calculateExtent (surveyMetadata: ISurveyMetadata, scale: IScale, rangeTrace: IRangeTrace) {
        const minTrace = rangeTrace.initial;
        const totalTraces = surveyMetadata.Header.NumberOfTraces;

        const minX = minTrace;
        const minY = surveyMetadata.Header.SamplesPerTrace * -1 * scale.y;
        const maxX = minTrace + (totalTraces * scale.x);
        const maxY = 0;

        console.log(minTrace, totalTraces);

        const extent = [minX, minY, maxX, maxY];
        return extent;
    };

    getMaxTrace (surveyMetadata: ISurveyMetadata) {
        return surveyMetadata?.Header?.NumberOfTraces;
    }

    calculateCenter(extent: Extent, surveyMetadata: ISurveyMetadata, scale: IScale, rangeTrace: IRangeTrace) {
        const x = super.calculateCenterX(extent);
        const y = super.calculateCenterY(surveyMetadata.Header.SamplesPerTrace, scale.y);

        return [x, y];
    }

    getInitialRangeTrace(surveyMetadata: ISurveyMetadata):IRangeTrace {
        return {
            initial: 1,
            end: surveyMetadata.Header.NumberOfTraces
        };
    }
}