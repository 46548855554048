import { Box, CircularProgress, Collapse, Grid, IconButton, Slider, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { GeopostCheckbox } from 'components/geopostCheckbox/GeopostCheckbox';
import { ISeismicViewer3DMap } from 'features/seismic-3d/models/interfaces/ISeismicViewer3DMap';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { Grid3D } from '../Grid3D';
import { GridXyz3D } from '../GridXyz3D';

export type IGridsListRowProps = {
    grid: ISeismicViewer3DMap
};

export const GridsListRow = ({ grid } : IGridsListRowProps) => {
    const {
        mainFeatureSrid
    } = use3DViewerStore(state => ({
        mainFeatureSrid: state.mainFeatureSrid
    }));

    const [ isGridCreationLoading, setIsGridCreationLoading ] = useState<boolean>(false);

    const [ isCollapsed, setIsCollapsed ] = useState<boolean>(false);

    const [ gridOpacity, setGridOpacity ] = useState<number>(1);

    const [ visible, setVisible ] = useState<boolean>(false);

    const labelFormatter = (value: number) => {
        return `${value * 10}%`;
    };

    const handleOpacitySliderChange = (event: Event, value: number | number[]) => {
        //const opacity = (value as number) / 10;
        setGridOpacity(value as number);
    };

    const gridNameToShow = useMemo(() => {
        const firstWord = grid.Name.split(' ')[0];
        if (firstWord.length > 30) {
            return firstWord.slice(0, 28) + '...';
        } else {
            return grid.Name;
        }
    }, [grid]);

    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    <GeopostCheckbox
                        onSelect={() => setVisible(true)}
                        onUnselect={() => setVisible(false)}
                        checked={visible}
                        loading={isGridCreationLoading}
                    />

                </TableCell>
                <TableCell>
                    <IconButton
                        size='small'
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        {isCollapsed ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Typography title={grid.Name} fontSize={'14px'}>
                        {gridNameToShow}
                    </Typography>

                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell colSpan={2}>
                    <Collapse in={isCollapsed}>
                        <Box paddingRight='10px'>
                            <Stack>
                                <Typography variant='body1'> Opacity </Typography>
                            </Stack>
                            <Stack>
                                <Slider
                                    disabled={isGridCreationLoading || !visible}
                                    min={0}
                                    max={1}
                                    step={0.1}
                                    defaultValue={1}
                                    size='small'
                                    valueLabelFormat='auto'
                                    onChange={handleOpacitySliderChange}
                                />
                            </Stack>
                        </Box>
                    </Collapse>
                </TableCell>
                <TableCell>
                </TableCell>
            </TableRow>
            <GridXyz3D
                grid={grid}
                mainFeatureSrid={mainFeatureSrid}
                opacity={gridOpacity}
                visible={visible}
                onLoadStart={() => setIsGridCreationLoading(true)}
                onLoadEnd={() => setIsGridCreationLoading(false)}
            />
        </Fragment>
    );
};