import { Fragment, useEffect, useMemo, useState, forwardRef, Ref, useImperativeHandle } from 'react';
import { Box, CircularProgress, IconButton, Stack, css } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SeismicEditInterpretation } from '../SeismicEditInterpretation';
import { InterpretationType } from '../models/enums/InterpretationType';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useGetAll } from '../api/useInterpretationController';
import { useInterpretationStore } from '../stores/useInterpretationStore';
import { IInterpretation } from '../models/interfaces/IInterpretation';
import { SeismicInterpretationAccordion } from './SeismicInterpretationAccordion';
import { SeismicItemInterpretation } from './SeismicItemInterpretation';
import { handleFilter, splitInterpretationsByType } from '../utils/interpretationUtils';
import { Plus } from 'components/icons/components';
import { IUseInterpretation } from '../models/interfaces/IUseInterpretation';

const styles = css({
    '& .container-loading': {
        display: 'flex',
        marginTop: 25,
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .container-item': {
        padding: '9px 8px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #ccc'
    }
});

const buildItemAccordeon = (type: InterpretationType, plusTitle: string, handleOpen: (value: boolean) => void, open: boolean, label: string, interpretations: IInterpretation[],
    editInterpretation: (token: string) => void, newInterpretation: (type: InterpretationType) => void, refresh: () => void,
    interpretationSettings: IUseInterpretation) => (
    <SeismicInterpretationAccordion
        handleOpen={handleOpen}
        open={open}
        label={label}
        headerElement={<IconButton title={plusTitle} disabled={interpretationSettings.isLocked} onClick={(event) => {
            event.stopPropagation();
            newInterpretation(type);
        }}>
            <Plus fontSize={16}/>
        </IconButton>}
        items={interpretations.map(item => <Box className='container-item' key={item.Token}>
            <SeismicItemInterpretation
                interpretationSettings={interpretationSettings}
                interpretation={item}
                onEdit={editInterpretation}
                refresh={refresh}
            />
        </Box>)}
    />
);

export interface SeismicInterpretationListRef {
    refresh: () => void;
}

export const SeismicInterpretationList = forwardRef(({ termFilter, interpretationSettings }: Props, ref: Ref<SeismicInterpretationListRef>) => {
    const { t } = useTranslation(['common', 'seismic']);

    const [popupInterpretationOpened, setPopupinterpretationOpened] = useState(false);
    const [interpretationEditToken, setInterpretationEditToken] = useState('');
    const [interpretationEditType, setInterpretationEditType] = useState(InterpretationType.Horizon);
    const [accordionHorizonOpen, setAccordionHorizonOpen] = useState(true);
    const [accordionFaultOpen, setAccordionFaultOpen] = useState(true);
    const [horizons, setHorizons] = useState<IInterpretation[]>([]);
    const [faults, setFaults] = useState<IInterpretation[]>([]);

    const horizonsFiltered = useMemo(() => {
        return handleFilter(horizons, termFilter);
    }, [termFilter, horizons]);

    const faultsFiltered = useMemo(() => {
        return handleFilter(faults, termFilter);
    }, [termFilter, faults]);

    const { surveyMetadata, volumeToken } = useSeismicStore(state => ({
        surveyMetadata: state.surveyMetadata,
        volumeToken: state.volumeToken
    }));

    const { data, isLoading, refetch, isRefetching } = useGetAll(surveyMetadata?.Domain);

    useImperativeHandle(ref, () => ({ refresh }));

    function refresh() {
        refetch();

        if (interpretationSettings.refresh){
            interpretationSettings.refresh();
        }
    }

    const { setSharePermissions } = useInterpretationStore(state => ({
        setSharePermissions: state.setSharePermissions
    }));

    useEffect(() => {
        const interpretationsSplitted = splitInterpretationsByType(data?.Interpretations);
        setHorizons(interpretationsSplitted.horizons);
        setFaults(interpretationsSplitted.faults);
        setSharePermissions(true, true, true);

    }, [data]);

    const editInterpretation = (token: string) => {
        setInterpretationEditToken(token);
        setPopupinterpretationOpened(true);
    };

    const newInterpretation = (type: InterpretationType) => {
        setInterpretationEditToken('');
        setInterpretationEditType(type);
        setPopupinterpretationOpened(true);
    };

    const reload = () => {
        refetch();

        if (interpretationSettings.refresh){
            interpretationSettings.refresh(interpretationEditToken);
        }
    };

    const isSomethingLoading = isLoading || isRefetching || !volumeToken;

    const horizonsData = horizonsFiltered ?? horizons;
    const faultsData = faultsFiltered ?? faults;

    return <Fragment>
        <Box css={styles}>
            <Stack >
                { isSomethingLoading ? <Box className='container-loading'><CircularProgress /></Box>:
                    <Box >
                        {buildItemAccordeon(InterpretationType.Horizon, t('seismic:interpretation.newHorizon'), setAccordionHorizonOpen, accordionHorizonOpen, t('seismic:interpretation.horizons'),
                            horizonsData, editInterpretation, newInterpretation, refresh, interpretationSettings)}
                        {buildItemAccordeon(InterpretationType.Fault, t('seismic:interpretation.newFault'), setAccordionFaultOpen, accordionFaultOpen, t('seismic:interpretation.faults'),
                            faultsData, editInterpretation, newInterpretation, refresh, interpretationSettings)}
                    </Box>
                }
            </Stack>
        </Box>
        <SeismicEditInterpretation
            onCancel={() => setPopupinterpretationOpened(false)}
            token={interpretationEditToken}
            type={interpretationEditType}
            onReload={reload}
            onChangeOpen={value => setPopupinterpretationOpened(value)}
            open={popupInterpretationOpened}
        />
    </Fragment>;
});

interface Props {
    termFilter: string;
    interpretationSettings: IUseInterpretation;
}