import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { InterpretationType } from '../models/enums/InterpretationType';
import { IGetAllHiddenResponse } from '../models/interfaces/IGetAllHiddenResponse';
import { IInterpretation } from '../models/interfaces/IInterpretation';
import { IInterpretationDictionary } from '../hooks/useInterpretation2DSeismicViewer';

export function splitInterpretationsByType<T extends IInterpretation | IGetAllHiddenResponse>(interpretations?: T[]){
    const horizons: T[] = [];
    const faults: T[] = [];

    if (interpretations) {
        interpretations.forEach((item) => {
            if (item.Type === InterpretationType.Fault) {
                faults.push(item);
            }
            else {
                horizons.push(item);
            }
        });
    }

    return {
        horizons,
        faults
    };
}

export function handleFilter<T extends IInterpretation | IGetAllHiddenResponse> (data: T[], term: string) {
    if (!term || !term.trim()){
        return null;
    }

    term = term.toLowerCase();

    return data.filter(x=> x.Name.toLowerCase().indexOf(term) > -1);
};

export const hasLayerVisible = (value: {[token: string]: IInterpretationDictionary}) => {
    let isAllHidden = true;
    for (let token in value){
        if (value[token].layer && value[token].layer?.getVisible()){
            isAllHidden = false;
            break;
        }
    }

    return !isAllHidden;
};

export const hasCrossLayerVisible = (value: {[token: string]: IInterpretationDictionary}) => {
    let isAllHidden = true;
    for (let token in value){
        if (value[token].crossLayer && value[token].crossLayer?.getVisible()){
            isAllHidden = false;
            break;
        }
    }

    return !isAllHidden;
};