import { useState, Fragment, useEffect, MouseEventHandler, useRef } from 'react';
import { CircularProgress, FormControlLabel, Typography, Box, IconButton, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LayersIcon from '@mui/icons-material/Layers';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { Stack } from '@mui/system';

import { useSessionStore } from 'session/useSessionStore';
import { useSeismicStore } from '../../stores/useSeismicStore';
import { useLine3DNavigationStore } from '../../stores/useLine3DNavigationStore';
import { IIntersections } from '../../models/interfaces/IIntersection';
import { useMapStore } from '../../stores/useMapStore';
import { createStyleProjections } from '../../utils/seismicProjectionsUtils';
import { useGetAllLayersSetupProjection } from './api/useSeismicController';
import { SeismicCheckbox } from './SeismicCheckbox';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { useFeatureFlags } from 'features/seismic/hooks/useFeatureFlags';
import { LineType } from 'features/seismic/models/enums/LineType';
import { useGetSurveyGeometry } from '../worldMap/api/useWorldMapController';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { useGetGeometryProjections } from './hooks/useGetGeometryProjections';

export function SeismicProjections() {
    const { t } = useTranslation();

    const { map } = useMapStore(state => ({
        map: state.map
    }));

    const urlParams = getParamsFromUrl();
    const srid = 4326;

    const { surveyMetadata, scale, geometryLine:  dataGeometryLine, volumeToken, volumeIdentifier} = useSeismicStore(state => ({
        geometryLine: state.geometryLine,
        surveyMetadata: state.surveyMetadata,
        scale: state.scale,
        volumeToken: state.volumeToken,
        volumeIdentifier: state.volumeIdentifier,
    }));

    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }));

    const { lineNumber, lineType } = useLine3DNavigationStore(state => ({
        lineNumber: state.lineNumber,
        lineType: state.lineType
    }));

    const { featureFlags } = useFeatureFlags();

    const { isLoading: loadingProjections, data: dataProjections } = useGetAllLayersSetupProjection(featureFlags?.seismic2D.Projections ?? false);

    const [opened, setOpened] = useState(false);

    const [layers, setLayers] = useState<{ [token: string]: VectorLayer<VectorSource> }>({});

    const [intersections, setIntersections] = useState<{ [token: string]: IIntersections[] }>({});

    const iconButtonElementRef = useRef<HTMLButtonElement | null>(null);

    const { data: geometryLine } = useGetGeometryProjections(srid, lineType === LineType.Line2D);

    useEffect(() => {
        if (layers) {
            for (let token in layers) {
                map?.removeLayer(layers[token]);
            }

            setLayers({});
        }
    }, [lineNumber, scale, lineType]);

    const drawProjection = (intersectionInformation: IIntersections[], token: string) => {
        setIntersections({
            ...intersections,
            [token]: intersectionInformation
        });

        if (intersectionInformation && dataGeometryLine && surveyMetadata) {
            let layer = layers[token];

            if (!layer){
                layer = new VectorLayer({ source: new VectorSource() });

                map?.addLayer(layer);
            }

            layer.getSource()?.clear();

            let item = dataProjections?.find((item) => item.token === token);

            intersectionInformation.forEach(element => {
                //const startTrace = (element.StartTrace + dataGeometryLine.Min) * scale.x;
                //const endTrace = (element.EndTrace + dataGeometryLine.Min) * scale.x;

                let startTrace = 0;
                let endTrace = 0;

                if (surveyMetadata.Type === SurveyType.Seismic2D){
                    startTrace = (element.StartTrace) * scale.x;
                    endTrace = (element.EndTrace) * scale.x;
                }
                else {
                    startTrace = (element.StartTrace + dataGeometryLine.Min) * scale.x;
                    endTrace = (element.EndTrace + dataGeometryLine.Min) * scale.x;
                }

                const middle = (endTrace - startTrace) / 2 + startTrace;

                const height = surveyMetadata.Header.SamplesPerTrace * -1 * scale.y;
                const leftTop: Coordinate = [startTrace, 0];
                const leftBottom: Coordinate = [startTrace, height];
                const rightBottom: Coordinate = [endTrace, height];
                const rightTop: Coordinate = [endTrace, 0];
                const middleBottom: Coordinate = [middle, height];

                const coordinates: Coordinate[] = [middleBottom, leftBottom, leftTop, rightTop, rightBottom, middleBottom];

                const geometry = new LineString(coordinates);
                const feature = new Feature({ geometry: geometry });
                feature.set('featureType', 'dataLayerProjection');
                feature.set('itemConfig', item);
                feature.set('featureElement', element);
                feature.set('featureGeometry', geometry);
                layer.getSource()?.addFeature(feature);

                feature.setStyle(createStyleProjections(item!, element.FeatureName, geometry));

                setLayers({ ...layers, [token]: layer });
            });
        }
    };

    const handleClickButton:MouseEventHandler<HTMLButtonElement> = (event) => {
        setOpened((value) => !value);
    };

    if (loadingProjections || !dataGeometryLine ) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Fragment>
                <IconButton
                    id='projections'
                    color='primary'
                    ref={iconButtonElementRef}
                    onClick={handleClickButton}
                    title={t('projection')}>
                    <LayersIcon />
                </IconButton>
            </Fragment>

            <Popover
                anchorEl={iconButtonElementRef.current}
                open={opened}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                onClose={() => setOpened(false)}
            >
                <Stack direction='column' justifyContent='flex-start' visibility={opened ? 'visible': 'hidden'} >
                    {dataProjections?.map((item, index) => (
                        <Box key={'container-projection-' + item.token}
                            style={{
                                opacity: 0.9,
                                backgroundColor: tenantConfig?.appBar.menu.bgColor,
                                color: tenantConfig?.appBar.menu.color,
                                padding: '5px'
                            }}
                        >
                            <FormControlLabel key={index}
                                style={{
                                    color: tenantConfig?.appBar.menu.color,
                                }}
                                control={
                                    <SeismicCheckbox
                                        afterGetCallback={drawProjection}
                                        dataGeometryLine={dataGeometryLine}
                                        srid={srid}
                                        token={item.token}
                                        key={item.token}
                                    />}
                                label={<Typography color={'white'}>{item.title}</Typography>} />
                        </Box>
                    ))}
                </Stack>
            </Popover>
        </Box>
    );
};