import { Box, Button, FormControl, InputLabel, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useState } from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { useGetAllByLine } from 'features/seismic/components/interpretation/api/useInterpretationController';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useGetAllAlineWeights, useExecuteAline } from '../API/useAlineController';
import { AlineWeight } from '../models/IAlineWeight';
import { IRunAlineRequest } from '../models/IRunAlineRequest';
import { IInterpretation } from 'features/seismic/components/interpretation/models/interfaces/IInterpretation';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { LineType } from 'features/seismic-3d/models/enums/LineType';

export function AlinePopup(){

    const { volumeToken } = useSeismicStore(state => ({
        volumeToken: state.volumeToken,
    }));
    const [openedAlineWorflow, setOpenedAlineWorflow] = useState(false);
    const [selectedWeight , setSelectedWeight] = useState<AlineWeight>();
    const [topHorizon , setTopHorizon] = useState<IInterpretation>();
    const [bottonHorizon , setBottonHorizon] = useState<IInterpretation>();
    const alineWeights = useGetAllAlineWeights();
    const { lineNumber, lineType } = useLine3DNavigationStore(state => ({
        lineNumber: state.lineNumber,
        lineType: state.lineType,
        setLineType: state.setLineType,
    }));
    const { surveyMetadata } = useSeismicStore(state => ({
        surveyMetadata: state.surveyMetadata,
    }));

    const interpretations = useGetAllByLine(volumeToken,surveyMetadata?.Type === SurveyType.Seismic2D ? LineType.Line2D : lineType,lineNumber,surveyMetadata?.Domain);

    const handleChangeWeight = (event : SelectChangeEvent) => {
        const selected = alineWeights.data?.filter(weight => weight.Token === event.target.value);
        if (selected)
        {
            setSelectedWeight(selected[0]);
        }
    };
    const handleChangeTopHorizon = (event : SelectChangeEvent) =>{
        const selected = interpretations.data?.Interpretations.filter(interpretation => interpretation.Token === event.target.value);
        if (selected){
            setTopHorizon(selected[0]);
        }
    };
    const handleChangeBottonHorizon = (event : SelectChangeEvent) =>{
        const selected = interpretations.data?.Interpretations.filter(interpretation => interpretation.Token === event.target.value);
        if (selected){
            setBottonHorizon(selected[0]);
        }
    };

    const { mutateAsync, isLoading } = useExecuteAline();
    const runAline = async () =>{
        const oarams:IRunAlineRequest ={
            volumeToken:volumeToken ?? '',
            topHorizonToken:topHorizon?.Token?? '',
            bottomHorizonToken:bottonHorizon?.Token?? '',
            weightToken:selectedWeight?.Token?? '',
            lineNumber: lineNumber,
            lineOrientation: lineType
        };

        if (volumeToken && topHorizon && bottonHorizon && selectedWeight){
            await mutateAsync(oarams);
        }
    };
    return <Box>

        <Button sx={{color: 'white',borderColor:'white'}} variant='outlined' onClick={() => setOpenedAlineWorflow(true)}>Workflow</Button>
        <div>
            <GeopostPopup
                temporaryIgnoreFlexInContainer={false}
                open={openedAlineWorflow}
                title={'Worflow'}
                onChangeOpen={value => setOpenedAlineWorflow(value)}
                overlay={false}
                height={430}
                width={400} >
                <Box sx={{ display: 'flex',flexDirection:'column' }}>
                    <FormControl  sx={{ m: 1, minWidth: 160 }}>
                        <InputLabel id="select-models-label">{'Models'}</InputLabel>

                        <Select
                            native
                            id="select-aline-model"
                            labelId="select-models-label"
                            onChange={handleChangeWeight}
                            value={selectedWeight?.Token}
                        >
                            <option value={0}>Models</option>
                            {alineWeights.data?.map(weight => <option key={weight.Token} value={weight.Token}>{weight.Name}</option>)}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 160 }}>
                        <TextField
                            style={{textAlign: 'left'}}
                            multiline
                            rows={3}
                            label={'Description'}
                            value={selectedWeight?.Description ?? ''}
                        />
                    </FormControl>
                    <FormControl  sx={{ m: 1, minWidth: 160 }}>
                        <InputLabel id="select-horizon-base-label">{'Horizon Top'}</InputLabel>
                        <Select
                            native
                            labelId="select-horizon-base-label"
                            id="select-horizon-base"
                            value={topHorizon?.Token}
                            onChange={handleChangeTopHorizon}
                        >
                            <option value={0}>Select</option>
                            {interpretations.data?.Interpretations.map(interpretation =><option key={interpretation.Token} value={interpretation.Token}>{interpretation.Name}</option>)}

                        </Select>
                    </FormControl>
                    <FormControl  sx={{ m: 1, minWidth: 160 }}>
                        <InputLabel id="select-horizon-fundo-label">{'Horizon Bottom'}</InputLabel>
                        <Select
                            native
                            labelId="select-horizon-fundo-label"
                            id="select-horizon-fundo"
                            value={bottonHorizon?.Token}
                            onChange={handleChangeBottonHorizon}
                        >
                            <option value={0}>Select</option>
                            {interpretations.data?.Interpretations.map(interpretation =><option key={interpretation.Token} value={interpretation.Token}>{interpretation.Name}</option>)}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 160 }}>
                        <Button  variant='outlined' onClick={runAline}>start</Button>
                    </FormControl>
                </Box>
            </GeopostPopup>
        </div>
    </Box>;
}