import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJoyride, { CallBackProps, Step } from 'react-joyride';

export const WebTour3D = ({ run } : WebTour3DProps) => {
    const { t } = useTranslation();

    const lineSliderSteps: Step[] = [
        {
            target: '#grid-opacity-slider',
            content: t('webTour3D.lineSlider.gridOpacity')
        },
        {
            target: '#lines-slider-container',
            content: t('webTour3D.lineSlider.lines')
        },
    ];

    const projectionSteps: Step[] = [
        {
            target: '#open-projections-button',
            content: t('webTour3D.projections.open')
        },
        {
            target: '.projection-checkbox',
            content: t('webTour3D.projections.selectLayer')
        },
        {
            target: '.open-projection-details-button',
            content: t('webTour3D.projections.projectionDetails')
        },
        {
            target: '.projection-height-slider',
            content: t('webTour3D.projections.heightSlider')
        },
        {
            target: '.projection-opacity-slider',
            content: t('webTour3D.projections.fillOpacitySlider')
        },
        {
            target: '.projection-color-selector',
            content: t('webTour3D.projections.fillColor')
        },
        {
            target: '#projections-popup .close-popup-button',
            content: t('webTour3D.projections.close')
        },
    ];

    const gridXyzSteps : Step[] = [
        {
            target: '#open-grid-popup-button',
            content: t('webTour3D.grids.open')
        },
        {
            target: '.select-grid-button',
            content: t('webTour3D.grids.select')
        },
        {
            target: '.grid-opacity-slider',
            content: t('webTour3D.grids.opacitySlider')
        }
    ];

    const steps : Step[] = [
        {
            target: '#seismic-3d-canvas-container',
            content: t('webTour3D.navbar.viewer'),
            disableBeacon: true
        },
        {
            target: '#seismic-3d-lines-popup',
            content: t('webTour3D.navbar.lines'),
            disableBeacon: true
        },
        {
            target: '#open-wells-popup-button',
            content: t('webTour3D.navbar.wells'),
            disableBeacon: true
        },
        {
            target: '#open-grid-popup-button',
            content: t('webTour3D.navbar.gridsXyz'),
            disableBeacon: true
        },
        {
            target: '#open-projections-popup-button',
            content: t('webTour3D.navbar.gridsXyz'),
            disableBeacon: true
        },
        {
            target: '#open-seismics-popup-button',
            content: t('webTour3D.navbar.seismics'),
            disableBeacon: true
        }
    ];

    const [ currentStepIndex, setCurrentStepIndex ] = useState(0);

    const { current: clickListener } = useRef(function() { setTimeout(() => setCurrentStepIndex(index => index + 1), 250)});

    return (
        <ReactJoyride
            run={run}
            steps={steps}
            continuous
            styles={{
                options: {
                    arrowColor: 'rgb(4, 31, 60)',
                    primaryColor: '#000',
                    width: 'auto',
                    zIndex: 99999999999999999,
                }
            }}
            locale={{
                back: t('webTour.back'),
                last: t('webTour.last'),
                next: t('webTour.next'),
                skip: t('webTour.skip')
            }}
            hideCloseButton
            spotlightClicks
            disableScrolling={true}
        />
    );
};

export type WebTour3DProps = {
    run: boolean;
};