import { create } from 'zustand';
import Map from 'ol/Map';
import View from 'ol/View';
import PerfectScrollbar from 'perfect-scrollbar';

import { WIDTH_CONNECTION_LANE, WIDTH_LANE } from '../consts/layotsConsts';
import { ICrossSection } from '../models/interfaces/ICrossSection';

export interface IDepthRange{
    minDepth: number;
    maxDepth: number;
}

export interface ICrossSectionStore{
    crossSectionData: ICrossSection | null,
    displacementVertical: {[wellId: number]:number};
    factorVertical: number;
    heightMap: number;
    laneWidth: number;
    view: View | null;
    map: Map | null;
    perfectScrollbar: PerfectScrollbar | null,
    depthRange:IDepthRange;
    dataDepthRange:IDepthRange;
    jwtToken:string;
    baseRelationPxMeter: number;
    setView:(value:View) => void;
    setMap:(value:Map) => void;
    setCrossSectionData: (value: ICrossSection) => void;
    setDepthRange:(value:IDepthRange) => void;
    setDataDepthRange:(value:IDepthRange) => void;
    setJwtToken:(value:string) => void;
    setBaseRelationPxMeter: (value: number) => void;
    setDisplacementVertical: (value: {[wellId: number]:number}) => void;
    setFactorVertical: (value: number) => void;
    setLaneWidth: (value: number) => void;
    setHeightMap: (value: number) => void;
    setPerfectScrollbar: (value: PerfectScrollbar) => void;
};

export const useCrossSectionStore = create<ICrossSectionStore>()(
    (set,get) => ({
        connectionWidth: WIDTH_CONNECTION_LANE,
        connectionViews: {},
        crossSectionData: null,
        displacementVertical: {},
        factorVertical: 1,
        heightMap: 0,
        laneWidth: WIDTH_LANE,
        perfectScrollbar: null,
        setFactorVertical: (value: number) => set({ factorVertical: value }),
        setLaneWidth: (value: number) => set({ laneWidth: value}),
        view: null,
        map: null,
        depthRange: {
            minDepth: 1000000,
            maxDepth: -1000000
        },
        dataDepthRange: {
            minDepth: 1000000,
            maxDepth: -1000000
        },
        baseRelationPxMeter: 5,
        jwtToken: '',
        setView: (value: View | null) => set({
            view: value
        }),
        setMap: (value :Map | null) => set({
            map: value
        }),
        setCrossSectionData: (value: ICrossSection) => set({
            crossSectionData: value
        }),
        setDisplacementVertical: (value: {[wellId: number]:number}) => set({
            displacementVertical: value
        }),
        setDepthRange: (value :IDepthRange) => set({
            depthRange: value
        }),
        setDataDepthRange: (value :IDepthRange) => set({
            dataDepthRange: value
        }),
        setJwtToken: (value :string) => set({
            jwtToken: value
        }),
        setBaseRelationPxMeter: (value :number) => set({
            baseRelationPxMeter: value
        }),
        setHeightMap:(value :number) => set({
            heightMap: value
        }),
        setPerfectScrollbar:(value :PerfectScrollbar) => set({
            perfectScrollbar: value
        }),
    })
);