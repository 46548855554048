import { styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import { AutoSizer, List } from 'react-virtualized';

import { GeopostCheckbox } from 'components/geopostCheckbox/GeopostCheckbox';
import { FeatureGeom } from 'features/seismic-3d/models/classes/FeatureGeom';
import { IntersectingLayer } from 'features/seismic-3d/models/types/IntersectingLayer';
import { IntersectingLayerCheckbox } from './IntersectingLayerCheckbox';
import { LayersListRow } from './LayersListRow';

type Props = {
    onSelectCallback : (layerData: IntersectingLayer, layerGeomData: FeatureGeom) => void,
    onUnselectCallback: (layerData: IntersectingLayer) => void,
    layers: IntersectingLayer[],
    isCheckedCallback: (layer: IntersectingLayer) => boolean,
    minProjectionHeight: number,
    onChangeProjectionHeight: (layerId: number, height: number) => void,
    onChangeProjectionColor : (layerId: number, color: string) => void,
    onChangeProjectionOpacity: (layerId: number, opacity: number) => void,
};

export const LayersList = ({ onSelectCallback, onUnselectCallback, layers, isCheckedCallback, minProjectionHeight, onChangeProjectionHeight, onChangeProjectionColor, onChangeProjectionOpacity } : Props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            border: '1px solid #d9d9d9',
            whiteSpace: 'nowrap'
        },
        [`&.${tableCellClasses.body}`]: {
            border: '1px solid #d9d9d9'
        },
    }));

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell size='small'>
                            Options
                        </TableCell>
                        <TableCell size='small'>
                            Select
                        </TableCell>
                        <TableCell size='small'>
                            Title
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        layers
                        &&
                        layers.slice(0, 9).map((layer: IntersectingLayer) => (
                            <LayersListRow
                                key={layer.Id}
                                layer={layer}
                                checked={isCheckedCallback(layer)}
                                onSelectCallback={onSelectCallback}
                                onUnselectCallback={onUnselectCallback}
                                minProjectionHeight={minProjectionHeight}
                                onChangeProjectionHeight={onChangeProjectionHeight}
                                onChangeProjectionColor={onChangeProjectionColor}
                                onChangeProjectionOpacity={onChangeProjectionOpacity}
                                projectionOpacity= {layer.FillOpacity}
                            />
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};