import { Fragment, useEffect, useState } from 'react';
import { css } from '@mui/material';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import alertify from 'alertifyjs';
import { AxiosError, AxiosResponse } from 'axios';

import { FetchType } from 'models/enums/FetchType';
import { useAPI } from 'hooks/useAPI';
import { ApiType } from 'models/enums/ApiType';
import { ICrossSection } from '../../features/cross-section/models/interfaces/ICrossSection';
import { CrossSectionPanel } from 'features/cross-section/components/CrossSectionPanel';
import { useCrossSectionStore } from 'features/cross-section/stores/useCrossSectionStore';
import { LoadingBlock } from 'components/LoadingBlock';
import { WellCrossSectionNavbar } from 'features/cross-section/components/WellCrossSectionNavbar';
import { useCrossSectionConnectionStore } from 'features/cross-section/stores/useCrossSectionConnectionStore';
import { LaneType } from 'features/cross-section/models/enums/LaneType';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';

const styles = css({
    marginLeft: 0,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
});
interface wellConnectionStartDestination {
    [key: string]:{start:number,
        destination:number,
        WellId:number},
}
export function CrossSectionViewer(){
    const { t } = useTranslation('crossSection');
    const type = window.location.pathname.toLowerCase().indexOf('cross') > -1 ? LaneType.CrossSection : LaneType.LogCurve;
    const element = queryString.parse(window.location.search, {});
    const wellId = element.wellId?element.wellId as string:'';
    let projectToken = element.wellId ? element.tenantToken as string : '77fb6644-96fc-45d5-a73f-bcf504c73a81';
    let url = type === LaneType.LogCurve ? '/well/GetMetadataForFile' : '/Wells/WellCrossSection/GetForViewer';
    console.log(element.wellId,element.tenantToken,projectToken);
    if (type === LaneType.LogCurve && wellId){
        if (! element.tenantToken){
            projectToken = '77fb6644-96fc-45d5-a73f-bcf504c73a81';
        }
        url += `?wellId=${wellId}&projectToken=${projectToken}`;
    }
    const { isLoading, execute, data } = useAPI<ICrossSection>(url, (error) => {
        if (error.message.indexOf('Request failed with status code 401') > -1){
            alertify.alert(t('error.authentication'));
        }
        else if (error.message.indexOf('Network Error') > -1){
            alertify.alert(t('error.connectService'));
        }
        else {
            if ((error as AxiosError).response) {
                if ((error as AxiosError).response?.data) {
                    if (((error as AxiosError).response as AxiosResponse<string>).data.indexOf('Unsupported file format no front') > -1) {
                        alertify.alert('Unsupported file format');
                        return;
                    }
                }
            }
            alertify.alert(t('error.thereIsAError'));
        }
    });

    const {setJwtToken, setCrossSectionData,setWidth,setVerticalScale,factorVertical} = useCrossSectionStore(state => ({
        setCrossSectionData: state.setCrossSectionData,
        setJwtToken: state.setJwtToken,
        setWidth: state.setLaneWidth,
        setVerticalScale:state.setFactorVertical,
        factorVertical:state.factorVertical
    }));
    const [dataEdited,setDataEdited] = useState<ICrossSection|null>(null);
    const {setConnectionWidth,setConnectionStartDestination,connectionStartDestination} = useCrossSectionConnectionStore(state =>({
        setConnectionWidth: state.setConnectionWidth,
        setConnectionStartDestination:state.setConnectionStartDestination,
        connectionStartDestination:state.connectionStartDestination,
    }));

    useEffect(() => {
        document.title = 'Cross Section Viewer';
        const element = queryString.parse(window.location.search, {});
        let crossSectionToken = (element.crossSectionToken)?element.crossSectionToken as string:'';
        if (crossSectionToken === '') {
            crossSectionToken = (element.token)?element.token as string:'';
        }

        const params = getParamsFromUrl();
        //const jwtToken = (element.sessionJwt)?element.sessionJwt as string:'';
        if (params.jwtToken !== '') {
            setJwtToken(params.jwtToken);
        }

        if (type === LaneType.CrossSection){
            execute({
                type: FetchType.Get,
                apiType: ApiType.WebApi,
                jwtToken: params.jwtToken,
                params: {
                    wellCrossSectionToken: crossSectionToken
                }
            }).then((data)=>{
                if (data){
                    let start = 0;
                    let destination = 0;
                    let wellConnectionStartDestination : wellConnectionStartDestination = {} ;
                    console.log(data);
                    data.Itens?.forEach((item,idx) =>{
                        if (item.nextWellConnection){
                            if (item.nextWellConnection.ConnectionItens && item.nextWellConnection.ConnectionItens.length > 0){
                                start = item.nextWellConnection.ConnectionItens[0].SourceFormationStartDepth;
                                destination = item.nextWellConnection.ConnectionItens[0].DestinationFormationStartDepth;
                            }
                            // if (item.WellStart){
                            //     //item.nextWellConnection.WellStartSource = 2174;
                            //     start =item.nextWellConnection.WellStartSource;
                            // }

                            // if (item.WellEnd){
                            //     //item.nextWellConnection.WellStartDestination = 2131;
                            //     destination = item.nextWellConnection.WellStartDestination;
                            // }
                        }
                        if (item.WellName){
                            wellConnectionStartDestination[item.WellName.toString()] = {start: 0, destination:0,WellId:0};
                            wellConnectionStartDestination[item.WellName.toString()].start = start;
                            wellConnectionStartDestination[item.WellName.toString()].destination = destination;
                            if (item.WellId){
                                wellConnectionStartDestination[item.WellName.toString()].WellId = item.WellId;
                            }
                        }
                    });
                    setConnectionStartDestination({...wellConnectionStartDestination});
                    setDataEdited(data);
                    if (data.Settings){

                        setVerticalScale(data.Settings.VerticalScale);
                        setWidth(data.Settings.Width);
                        setConnectionWidth(data.Settings.ConnectionSize);
                    }
                }
            });
        } else {
            document.title = 'Log Viewer';
            const jwtToken = (element.token)?element.token as string:'';
            console.log(params);
            setJwtToken('');
            execute({
                type: FetchType.Get,
                apiType: ApiType.Render,
                jwtToken: jwtToken,
            }).then((data)=>{setDataEdited(data ? data : null)});
        }
    }, []);
    useEffect(() => {
        if (data) {
            let cross = localStorage.getItem(wellId);
            if (cross){
                cross = JSON.parse(cross);
            }
            if (cross === null){
                if (type === LaneType.LogCurve) {
                    data.Itens = [];
                    data?.Itens?.push({WellName:'Track 1',WellStart: data.WellStart,Token:`Track ${data.Itens.length+1}`,
                        WellEnd:data.WellEnd});
                    data.LaneType = type;
                } else {
                    data.LaneType = LaneType.CrossSection;
                }
                setCrossSectionData(data);
            }
            else {
                console.log(cross);
                setVerticalScale((cross as unknown as ICrossSection).FactorVertical as number);
                setCrossSectionData(cross as unknown as ICrossSection);
                setDataEdited(cross as unknown as ICrossSection);

            }

        }
    }, [data]);
    const addItem = ()=>{
        console.log(data);
        if (data){
            data.Itens?.push({WellName:`Track ${data.Itens.length+1}`,WellStart: data.WellStart,Token:`Track ${data.Itens.length+1}`,
                WellEnd:data.WellEnd});
            setCrossSectionData(data);
        }
    };
    return (
        <Fragment>
            <LoadingBlock open={isLoading}/>
            <div css={styles}>
                <WellCrossSectionNavbar crossSection={dataEdited}/>
                <CrossSectionPanel crossSection={dataEdited}></CrossSectionPanel>
            </div>

        </Fragment>
    );
}