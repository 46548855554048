import { RefObject, useEffect, useState } from 'react';

import { Position } from '../models/enums/Position';

interface PositionScreen {
    x: number;
    y: number;
}

const calculateCenterX = (screenWidth: number, popupWidth: number) => (screenWidth / 2) - (popupWidth / 2);

const calculateCenterY = (screenHeight: number, popupHeight: number) => (screenHeight / 2) - (popupHeight / 2);

function calculatePosition(position: Position, popupWidth: number, popupHeight: number, screenWidth: number, screenHeight: number, customXPosition: number | undefined, customYPosition: number | undefined){
    let positionScreen: PositionScreen = {
        x: 0,
        y: 0
    };

    switch (position){
    case Position.center:
        positionScreen = {
            x: calculateCenterX(screenWidth, popupWidth),
            y: calculateCenterY(screenHeight, popupHeight)
        };
        break;
    // case adicionado para o posicionamento do minimap
    case Position.bottomRight:
        positionScreen = {
            x: screenWidth - popupWidth,
            y: screenHeight - popupHeight
        };
        break;
    case Position.topRight: {
        // Feito para funcionar com o pop-up dos poços
        positionScreen = {
            x: screenWidth - popupWidth,
            y: customYPosition ?? 0
        };
        break;
    }
    case Position.topLeft:
        positionScreen = {
            x: 0,
            y: 0
        };
        break;
    case Position.middleLeft:
        positionScreen = {
            x: 0,
            y: calculateCenterY(screenHeight, popupHeight)
        };
        break;
    case Position.bottomLeft:
        positionScreen = {
            x: 0,
            y: screenHeight - popupHeight
        };
        break;
    case Position.topCenter:
        positionScreen = {
            x: calculateCenterX(screenWidth, popupWidth),
            y: 0
        };
        break;
    case Position.bottomCenter:
        positionScreen = {
            x: calculateCenterX(screenWidth, popupWidth),
            y: screenHeight - popupHeight
        };
        break;
    case Position.topRight:
        positionScreen = {
            x: screenWidth - popupWidth,
            y: 0,
        };
        break;
    case Position.middleRight:
        positionScreen = {
            x: screenWidth - popupWidth,
            y: calculateCenterY(screenHeight, popupHeight),
        };
        break;
    case Position.bottomRight:
        positionScreen = {
            x: screenWidth - popupWidth,
            y: screenHeight - popupHeight,
        };
        break;
    }

    return positionScreen;
}

export function useCalculatePosition(position: Position, open:boolean, popupRef: RefObject<HTMLDivElement>, customXPosition: number | undefined, customYPosition: number | undefined){
    const [positionValue, setPositionValue]=useState<PositionScreen>({
        x: 0,
        y: 0
    });

    const [finishedCalculation, setFinishedCalculation] = useState(false);

    useEffect(() => {
        if (open && popupRef.current){
            const popup = popupRef.current;
            const { width: popupWidth, height: popupHeight } = popup.getBoundingClientRect();
            const { offsetWidth: screenWidth, offsetHeight: screenHeight} = window.document.documentElement;

            const newPosition = calculatePosition(position, popupWidth, popupHeight, screenWidth, screenHeight, customXPosition, customYPosition);
            setPositionValue(newPosition);
            setFinishedCalculation(true);
        }
        else if (!open){
            setFinishedCalculation(false);
        }
    }, [open, position]);

    return {
        finishedCalculation: finishedCalculation,
        position: positionValue,
        setPosition: setPositionValue
    };
}