import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { LinearProgress } from '@mui/material';

import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { changeLineNumber } from 'features/seismic/utils/line3DNavigationUtils';
import { useSessionStore } from 'session/useSessionStore';
import { LineType } from 'features/seismic-3d/models/enums/LineType';
import { calculateGain } from 'features/seismic/utils/gainUtils';
import { useLoadingTileStore } from 'features/seismic/stores/useLoadingTilesStore';
import { useFeatureFlags } from 'features/seismic/hooks/useFeatureFlags';
import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';

const styles = css({
    position: 'fixed',
    zIndex: 9999999999,
    bottom: 50,
    right: 50,
    borderRadius: 5,
    background: '#ccc',

    '& .content': {
        padding: 20,
    },
    '& .hint': {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 0
    },
    '& .hint-enter': {
        marginTop: 7
    },
    '& .loading': {
        background: '#9a9ac9',
        height: 6
    },
    '& .loading-content': {
        height: '100%',
        background: '#2f2f62'
    },
});

const KEYARROWRIGHT = 'ArrowRight';
const KEYARROWLEFT = 'ArrowLeft';
const KEYARROWUP = 'ArrowUp';
const KEYARROWDOWN = 'ArrowDown';
const KEYPLUS = '+';
const KEYSUBTRACT = '-';
const KEYESCAPE = 'Escape';
const KEYENTER = 'Enter';

enum KeyboardNavigationType {
    number,
    type
}

//interface ISeismicKeyboardNavig

interface ISeismicKeyboardControl {
    navigation: {
        enabled: boolean,
        type: KeyboardNavigationType,
        label: string
    },
    gain: {
        enabled: boolean,
        label: string
    },
}

function buildDefaultControl():ISeismicKeyboardControl{
    return {
        navigation: {
            enabled: false,
            type: KeyboardNavigationType.number,
            label: ''
        },
        gain: {
            enabled: false,
            label: ''
        }
    };
}

export function SeismicKeyboard(){
    const { surveyMetadata, restrict, absoluteGain, setAbsoluteGain, setIsLoading } = useSeismicStore(state => ({
        absoluteGain: state.absoluteGain,
        restrict: state.restrict,
        surveyMetadata: state.surveyMetadata,
        setAbsoluteGain: state.setAbsoluteGain,
        setIsLoading: state.setNavbarLoading
    }));

    const { loading } = useLoadingTileStore(state => ({
        loading: state.loading > 0
    }));

    const { tenantConfiguration } = useSessionStore(state => ({
        tenantConfiguration: state.tenantConfiguration
    }));

    const { lineNumber, lineType, currentIncrement, setLineNumber, setLineType } = useLine3DNavigationStore(state => ({
        currentIncrement: state.currentIncrement,
        lineNumber: state.lineNumber,
        lineType: state.lineType,
        setLineNumber: state.setLineNumber,
        setLineType: state.setLineType
    }));

    const loadingContent = useRef<HTMLDivElement>(null);

    const [enabled, setEnabled] = useState(false);
    const [timer, setTimer] = useState(0);
    const [control, setControl] = useState<ISeismicKeyboardControl>(buildDefaultControl());

    const debounceTime = tenantConfiguration?.keyboardDebounceTime ?? 1500;

    const intervalId = useRef<NodeJS.Timeout | null>(null);
    const currentLineNumberRef = useRef(0);
    const currentLineTypeRef = useRef(LineType.Undefined);
    const currentGainLevelRef = useRef(0);
    const gainFactor = 10;

    const featureFlags = useFeatureFlags();
    const zSliceEnabled = useMemo(() => featureFlags.featureFlags?.seismic2D.TimeSlice, [featureFlags]);

    const handleKeyDown = useCallback((e:KeyboardEvent) => {
        const { key } = e;

        //limpando o foco do elemento ativo para impedir que o uso do keyboard gere interação com ele
        const activeElement = document.activeElement;
        if (!!activeElement) {
            if (activeElement instanceof HTMLInputElement) {
                return;
            }
            if (activeElement instanceof HTMLElement) {
                (activeElement as HTMLElement).blur();
            }
        }

        if (loading){
            return;
        }

        if (key === KEYARROWRIGHT || key === KEYARROWLEFT || key === KEYARROWDOWN || key === KEYARROWUP || key === KEYPLUS || key === KEYSUBTRACT){
            if (key === KEYARROWRIGHT || key === KEYARROWLEFT){
                if (lineType === LineType.Line2D) {
                    return;
                }

                currentLineTypeRef.current = LineType.Undefined;
                if (currentLineNumberRef.current === 0){
                    currentLineNumberRef.current = lineNumber;
                }

                const skip = (restrict) ? restrict.SeismicSkipSize3D : 0;
                const increment = (key === KEYARROWRIGHT)?currentIncrement:-currentIncrement;
                currentLineNumberRef.current = changeLineNumber(surveyMetadata, currentLineNumberRef.current, lineType, increment, skip);
                setControl(state => ({
                    ...state,
                    navigation: {
                        enabled: true,
                        label: `Changing to ${lineType === LineType.ZSlice ? surveyMetadata?.Domain === AmplitudeDomain.Time ? 'time' : 'depth' : 'line'} ` + currentLineNumberRef.current,
                        type: KeyboardNavigationType.number
                    }
                }));
            }
            else if (key === KEYARROWDOWN || key === KEYARROWUP){
                if (lineType === LineType.Line2D) {
                    return;
                }

                currentLineNumberRef.current = 0;

                if (currentLineTypeRef.current === LineType.Undefined){
                    currentLineTypeRef.current = lineType;
                }

                if (key === KEYARROWDOWN) {
                    switch (currentLineTypeRef.current) {
                    case LineType.Inline:
                        currentLineTypeRef.current = LineType.Xline;
                        break;
                    case LineType.Xline:
                        if (zSliceEnabled) {
                            currentLineTypeRef.current = LineType.ZSlice;
                        } else {
                            currentLineTypeRef.current = LineType.Inline;
                        }
                        break;
                    case LineType.ZSlice:
                        currentLineTypeRef.current = LineType.Inline;
                        break;
                    };
                } else {
                    switch (currentLineTypeRef.current) {
                    case LineType.Inline:
                        if (zSliceEnabled) {
                            currentLineTypeRef.current = LineType.ZSlice;
                        } else {
                            currentLineTypeRef.current = LineType.Xline;
                        }
                        break;
                    case LineType.Xline:
                        currentLineTypeRef.current = LineType.Inline;
                        break;
                    case LineType.ZSlice:
                        currentLineTypeRef.current = LineType.Xline;
                        break;
                    };
                }

                const label = (currentLineTypeRef.current === LineType.Inline) ?'inline': (currentLineTypeRef.current === LineType.Xline) ? 'xline' : 'zslice';
                setControl(state => ({
                    ...state,
                    navigation: {
                        enabled: true,
                        label: 'Changing to ' + label,
                        type: KeyboardNavigationType.type
                    }
                }));
            }
            else if (key === KEYPLUS || key === KEYSUBTRACT){
                if (key === KEYPLUS){
                    currentGainLevelRef.current = currentGainLevelRef.current + 1;
                }
                else {
                    currentGainLevelRef.current = currentGainLevelRef.current - 1;
                }

                setControl(state => ({
                    ...state,
                    gain: {
                        enabled: true,
                        label: 'Gain increasing by ' + currentGainLevelRef.current
                    }
                }));
            }

            setEnabled(true);
            setIsLoading(true);

            const animationSteps = [
                { width: '100%' },
                { width: '0%' },
            ];

            loadingContent.current?.animate(animationSteps, {
                duration: debounceTime,
                iterations: 1
            }).play();

            if (intervalId.current){
                clearInterval(intervalId.current);
            }

            setTimer(debounceTime);
            intervalId.current = setInterval(() => {
                setTimer((state) => state - 1000);
            }, 1000);
        }
        else if (key === KEYESCAPE){
            currentLineTypeRef.current = LineType.Undefined;
            currentLineNumberRef.current = 0;
            currentGainLevelRef.current = 0;
            setControl(buildDefaultControl());
            setEnabled(false);
            setTimer(0);
            setIsLoading(false);
        }
        else if (key === KEYENTER){
            if (intervalId.current){
                clearInterval(intervalId.current);
            }
            setTimer(0);
        }
        console.log(e.key); //ArrowRight ArrowLeft
    }, [currentIncrement, debounceTime, lineNumber, lineType, loading, restrict, setIsLoading, surveyMetadata, tenantConfiguration]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (timer === 0 && enabled){

            if (control.navigation.enabled){
                if (control.navigation.type === KeyboardNavigationType.number){
                    setLineNumber(currentLineNumberRef.current);
                }
                if (control.navigation.type === KeyboardNavigationType.type){
                    setLineType(currentLineTypeRef.current);

                }
            }
            if (control.gain.enabled){
                if (gainFactor > 0){
                    let gain = { min: absoluteGain.min, max: absoluteGain.max };
                    const valueToApply = (currentGainLevelRef.current > 1)? -1:1;
                    for (let i=0; i < Math.abs(currentGainLevelRef.current); i++){
                        gain = calculateGain(gainFactor * valueToApply, gain.max, gain.min)!;
                    }
                    if (gain){
                        setAbsoluteGain({
                            max: gain.max,
                            min: gain.min
                        });
                    }
                }
            }

            setControl(buildDefaultControl());
            setEnabled(false);
            setIsLoading(false);

            currentLineNumberRef.current = 0;
            currentGainLevelRef.current = 0;
            currentLineTypeRef.current = LineType.Undefined;
        }
    }, [timer, control]);

    return <div css={styles} style={{display: (enabled) ? 'block': 'none'}}>
        <div className='content'>
            {control.navigation.enabled && <div>{control.navigation.label}</div>}
            {control.gain.enabled && <div>{control.gain.label}</div>}
            <div className='hint hint-enter'>Press Enter to confirm</div>
            <div className='hint'>Press ESC to cancel</div>
        </div>
        <div className='loading'>
            <div className='loading-content' ref={loadingContent}></div>
        </div>
    </div>;
}