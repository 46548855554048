import { Fragment, useEffect } from 'react';
import { Navbar } from 'fileIndexing/otherComponents/Navbar';
import { NavigationMenu } from 'fileIndexing/otherComponents/NavigationMenu';

import { ContractsTable } from '../components/ContractsTable';

export function ContractsMainPage() {
    useEffect(() => {
        document.title = 'File Indexing - Contracts';
    }, []);

    return (
        <Fragment>
            <Navbar />
            <NavigationMenu />
            <ContractsTable />
        </Fragment>
    );
}