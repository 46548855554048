import { useMutation } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { getUrlParams } from '../utils/Seismic3DUrlUtils';
import { axios } from 'configurations/axios';

export const useSaveSLD = () => {
    const apiHost = useSessionStore(state => state.tenantConfiguration?.endpoints)?.sld;
    return useMutation(
        async (sldXml: string) => {
            const response = await axios.post<IResponseAPI<string>>(
                apiHost + '/SLD/SaveSLD',
                {
                    sldXml
                },
                {
                    headers: {
                        Authorization: 'Bearer ' +  getUrlParams().jwtToken,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data.Result;
        }
    );
};

//export const useGetSLDEndpoint()