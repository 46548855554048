import { create } from 'zustand';

import { AnnotationsSettingsVisibleType } from '../models/enums/AnnotationsSettingsVisibleType';
import { IAnnotationVisibleSettings } from '../models/interfaces/IAnnotationVisibleSettings';
import { IWellAnnotation } from '../models/interfaces/IWellAnnotation';

interface ICrossSectionAnnotationsStore{
    annotations: {[wellId: number]: IWellAnnotation[]};
    changeVisibleSettings: (name: string, visible: boolean, type: AnnotationsSettingsVisibleType) => void;
    setVisibleSettings: (value:{[name:string]: IAnnotationVisibleSettings}) => void;
    visibleSettings: {[name:string]: IAnnotationVisibleSettings};

    flatAnnotation: string;
    popupOpened: boolean;
    setAnnotations: (id: number, value: IWellAnnotation[]) => void;
    setPopupOpened: (value: boolean) => void;
    setFlatAnnotation: (value: string) => void;
};

function getPropertyVisibleNameByType(type: AnnotationsSettingsVisibleType){
    switch (type){
    case AnnotationsSettingsVisibleType.name:
        return 'name';
    case AnnotationsSettingsVisibleType.depth:
        return 'depth';
    default:
    case AnnotationsSettingsVisibleType.visible:
        return 'visible';
    }
}

export const useCrossSectionAnnotationsStore = create<ICrossSectionAnnotationsStore>()(
    (set,get) => ({
        annotations: {},
        changeVisibleSettings: (name: string, visible: boolean, type: AnnotationsSettingsVisibleType) => {
            const property = getPropertyVisibleNameByType(type);

            const currentVisibleSettings = get().visibleSettings;
            const currentItemToChange = currentVisibleSettings[name];

            set({
                visibleSettings: {
                    ...currentVisibleSettings,
                    [name]: {
                        ...currentItemToChange,
                        [property]: visible
                    }
                },
            });
        },
        visibleSettings: {},
        flatAnnotation: '',
        popupOpened: false,
        setAnnotations: (key: number, values: IWellAnnotation[]) => {
            const annotations = get().annotations;

            annotations[key] = values;

            set({
                annotations: {
                    ...annotations
                }
            });
        },
        setVisibleSettings: (value:{[name:string]: IAnnotationVisibleSettings}) => set({
            visibleSettings: value
        }),
        setFlatAnnotation: (value: string) => set({
            flatAnnotation: value
        }),
        setPopupOpened: (value: boolean) => set({
            popupOpened: value
        })
    })
);