import { Fragment, useEffect } from 'react';
import { Navbar } from 'fileIndexing/otherComponents/Navbar';
import { NavigationMenu } from 'fileIndexing/otherComponents/NavigationMenu';

import { InstituitionsTable } from '../components/InstitutionsTable';

export function InstitutionMainPage () {
    useEffect(() => {
        document.title = 'File Indexing - Institutions';
    }, []);

    return (
        <Fragment>
            <Navbar />
            <NavigationMenu />
            <InstituitionsTable />
        </Fragment>
    );
}