import { LineType } from 'features/seismic-3d/models/enums/LineType';
import { IGeopostSeismicLayerParams } from '../../interfaces/IGeopostSeismicLayerParams';
import { TileSourceStrategy } from './TileSourceStrategy';

export class LineTileSourceStrategy extends TileSourceStrategy {
    private lineType: LineType;

    constructor(lineType: LineType, apiUrl: string, onTileRequestError: (xhr: XMLHttpRequest, lineNumber: number, tileWidth: number, startTrace: number, endTrace: number) => void = () => {},
        onSettleTileRequest: (xhr: XMLHttpRequest, lineNumber: number, tileWidth: number, requestSendAt: Date) => void = () => {}) {
        super(apiUrl, onTileRequestError, onSettleTileRequest);
        this.lineType = lineType;
    }

    public getTileUrl = (params: IGeopostSeismicLayerParams) => {
        const [surveyLineStart, surveyLineEnd, surveyLineIncrement] = this.getSurveyLineDimensions(params);
        return super.getTileUrl(params) +
        this.addParamValueToUrl('lineType', this.lineType.toString()) +
        this.addParamValueToUrl('lineNumber', params.lineNumber!.toString()) +
        this.addParamValueToUrl('surveyLineStart', surveyLineStart.toString()) +
        this.addParamValueToUrl('surveyLineEnd', surveyLineEnd.toString()) +
        this.addParamValueToUrl('lineIncrement', surveyLineIncrement.toString()) +
        this.addParamToUrl('minSampleValue') +
        this.addParamToUrl('maxSampleValue') +
        this.addParamToUrl('lineStart') +
        this.addParamToUrl('lineEnd') +
        this.addParamToUrl('lineStartSample') +
        this.addParamToUrl('lineEndSample');
    };

    public getFullUrl = (src: string, xStart: number, xEnd: number, yStart: number, yEnd: number, minGain: number, maxGain: number) => {
        let fullUrl = src.replace('[lineStart]', xStart.toString())
            .replace('[lineEnd]', xEnd.toString())
            .replace('[lineStartSample]', yStart.toString())
            .replace('[lineEndSample]', yEnd.toString())
            .replace('[minSampleValue]', minGain.toString())
            .replace('[maxSampleValue]', maxGain.toString());
        return fullUrl;
    };

    public getEndpoint = () => `${this.apiUrl}/seismic/render/tile`;

    public getSurveyLineDimensions: (params: IGeopostSeismicLayerParams) => [number, number, number] = (params) => {
        if (this.lineType === LineType.Inline || this.lineType === LineType.Xline) {
            const surveyLineStart = this.lineType === LineType.Xline ? params.surveyInlineStart! : params.surveyXlineStart!;
            const surveyLineEnd = this.lineType === LineType.Xline ? params.surveyInlineEnd! : params.surveyXlineEnd!;
            const surveyLineIncrement = this.lineType === LineType.Xline ? params.surveyInlineIncrement! : params.surveyXlineIncrement!;
            return [surveyLineStart, surveyLineEnd, surveyLineIncrement];
        } else {
            const surveyLineStart = 1;
            const surveyLineEnd = params.numberOfTraces ?? 0;
            const surveyLineIncrement = 1;
            return [surveyLineStart, surveyLineEnd, surveyLineIncrement];
        }
    };

    public getTileLoadingKey = (fullUrl: string) => {
        const startTrace = this.extractUrlValue(fullUrl, 'lineStart');
        const endTrace = this.extractUrlValue(fullUrl, 'lineEnd');
        const lineStartSample = this.extractUrlValue(fullUrl, 'lineStartSample');
        const lineEndSample = this.extractUrlValue(fullUrl, 'lineEndSample');

        return `${startTrace}-${endTrace}-${lineStartSample}-${lineEndSample}`;
    };
};