import { useCallback, useEffect, useRef } from 'react';

import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { GeopostWellGroup } from 'features/seismic-3d/threejs/group/GeopostWellGroup';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';

export const use3DWellGroups = (countTimeDepth: number, surveyHeightInPixels: number, mainFeatureCentroidX: number, mainFeatureCentroidY: number) => {
    const scene = use3DSceneStore(state => state.scene);

    const gridSelectedSeismic = use3DGridStore(state => state.gridSelectedSeismic);

    const { current: wellGroups } = useRef<GeopostWellGroup[]>([]);

    const addWellGroup = useCallback((well: IWell) => {
        if (!scene) {
            return;
        }
        let wellGroup = scene.getObjectByName(GeopostWellGroup.getWellGroupName(well.Id)) as GeopostWellGroup;
        if (!wellGroup) {
            wellGroup = new GeopostWellGroup(countTimeDepth, surveyHeightInPixels, well, mainFeatureCentroidX, mainFeatureCentroidY);
            scene.add(wellGroup);
        }
        wellGroups.push(wellGroup);
        wellGroup.userData.wellId = well.Id;

    }, [countTimeDepth, surveyHeightInPixels, mainFeatureCentroidX, mainFeatureCentroidY]);

    useEffect(() => {
        if (surveyHeightInPixels !== 0) {
            wellGroups.forEach(wellGroup => {
                wellGroup.updateHeightFactor(countTimeDepth, surveyHeightInPixels);
            });
        }
    }, [surveyHeightInPixels]);

    return { wellGroups, addWellGroup };
};
