import { Box, CircularProgress, css } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState, forwardRef, Ref, useImperativeHandle } from 'react';

import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useGetAllHidden } from '../api/useInterpretationController';
import { SeismicInterpretationAccordion } from './SeismicInterpretationAccordion';
import { handleFilter, splitInterpretationsByType } from '../utils/interpretationUtils';
import { IGetAllHiddenResponse } from '../models/interfaces/IGetAllHiddenResponse';
import { SeismicInterpretationsHiddenItem } from './SeismicInterpretationsHiddenItem';
import { IUseInterpretation } from '../models/interfaces/IUseInterpretation';

const styles = css({
    '& .container-loading': {
        display: 'flex',
        marginTop: 25,
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .container-item': {
        padding: '9px 8px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #ccc',

        '& .label': {
            marginLeft: 7
        }
    }
});

export interface SeismicInterpretationHiddenListRef {
    refresh: () => void;
}

export const SeismicInterpretationHiddenList = forwardRef(({ termFilter, interpretationSettings }: Props, ref: Ref<SeismicInterpretationHiddenListRef>) => {
    const { t } = useTranslation(['common', 'seismic']);
    const [accordionHorizonOpen, setAccordionHorizonOpen] = useState(true);
    const [accordionFaultOpen, setAccordionFaultOpen] = useState(true);
    const [horizons, setHorizons] = useState<IGetAllHiddenResponse[]>([]);
    const [faults, setFaults] = useState<IGetAllHiddenResponse[]>([]);

    const { surveyMetadata } = useSeismicStore(state => ({
        surveyMetadata: state.surveyMetadata,
        volumeToken: state.volumeToken
    }));

    const { data, isLoading, refetch, isRefetching } = useGetAllHidden(surveyMetadata?.Domain);

    const horizonsFiltered = useMemo(() => {
        return handleFilter(horizons, termFilter);
    }, [termFilter, horizons]);

    const faultsFiltered = useMemo(() => {
        return handleFilter(faults, termFilter);
    }, [termFilter, faults]);

    useImperativeHandle(ref, () => ({ refresh }));

    function refresh() {
        refetch();
    }

    useEffect(() => {
        const interpretationsSplitted = splitInterpretationsByType(data);
        setHorizons(interpretationsSplitted.horizons);
        setFaults(interpretationsSplitted.faults);
    }, [data]);

    const isSomethingLoading = isLoading || isRefetching;

    const horizonsData = horizonsFiltered ?? horizons;
    const faultsData = faultsFiltered ?? faults;

    return <Box css={styles}>
        {isSomethingLoading ? <Box className='container-loading'><CircularProgress /></Box> :
            <Box>
                <SeismicInterpretationAccordion
                    handleOpen={setAccordionHorizonOpen}
                    open={accordionHorizonOpen}
                    label={t('seismic:interpretation.horizons')}
                    items={horizonsData.map(item => <SeismicInterpretationsHiddenItem key={item.Token} item={item} onRemove={interpretationSettings.remove}/>)}
                />
                <SeismicInterpretationAccordion
                    handleOpen={setAccordionFaultOpen}
                    open={accordionFaultOpen}
                    label={t('seismic:interpretation.faults')}
                    items={faultsData.map(item => <SeismicInterpretationsHiddenItem key={item.Token} item={item} onRemove={interpretationSettings.remove}/>)}
                />
            </Box>}
    </Box>;
});

interface Props {
    termFilter: string;
    interpretationSettings: IUseInterpretation;
}