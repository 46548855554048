import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Collapse, IconButton, Paper, Stack, Switch, TableCell, TableRow, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Seismic3DData } from 'features/seismic-3d/models/classes/Seismic3DData';
import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';

type Props = {
    seismicData: Seismic3DData
};

export const SelectedSeismicsListRow = ({ seismicData } : Props) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const { gridSelectedSeismic, newGridSelectedSeismic } = use3DGridStore(state => ({
        gridSelectedSeismic: state.gridSelectedSeismic,
        newGridSelectedSeismic: state.newGridSelectedSeismic
    }));
    const [isGridSelectedSeismic, setIsGridSelectedSeismic] = useState<boolean>(false);

    useEffect(() => {
        setIsGridSelectedSeismic(seismicData.volumeToken === gridSelectedSeismic?.volumeToken);
    }, [gridSelectedSeismic]);

    const handleSwitchChange = () => {
        newGridSelectedSeismic(seismicData);
    };

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell size='small'>
                    <IconButton
                        aria-label='expand-row'
                        size='small'
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        { collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                    </IconButton>
                </TableCell>
                <TableCell>
                    {seismicData.surveyName.length > 15 ? seismicData.surveyName.substring(0, 15) + '...' : seismicData.surveyName}
                </TableCell>
                <TableCell>
                    <Switch checked={isGridSelectedSeismic} onChange={handleSwitchChange}/>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3}>
                    <Collapse in={collapsed} timeout='auto' unmountOnExit >
                        <Box>
                            <Stack spacing={0.5}>
                                <Typography variant='body2'><strong>Number of traces:</strong> {' ' + seismicData.tracesNumber}</Typography>
                                <Typography variant='body2'><strong>Samples per trace:</strong> {' ' + seismicData.samplesPerTrace}</Typography>
                                <Typography variant='body2'><strong>Samples interval:</strong> {' ' + seismicData.sampleInterval}</Typography>
                            </Stack>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};