import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { PermissionType } from 'features/seismic/models/enums/PermissionType';
import { PermissionTable } from './PermissionTable';
import { IPermissionModel } from '../models/interfaces/IPermissionModel';

export function Permission({ canShareToPublic, canShareToCompanies, canShareToUserGroup, onChange, value, showLabel = false }: Props) {
    const { t } = useTranslation('common');

    useEffect(() => {
        if (value.permission === PermissionType.InCompany || value.permission === PermissionType.Private || value.permission === PermissionType.Public) {
            onChange({
                ...value,
                tokens: []
            });
        }
    }, [value.permission]);

    const changeTokens = (tokens:string[]) => {
        onChange({
            ...value,
            tokens: tokens
        });
    };

    const changePermission = (permission: PermissionType) => {
        onChange({
            tokens: [],
            permission: permission
        });
    };

    return <Box>
        <FormControl fullWidth>
            {showLabel && <InputLabel>{t('permission.permission')}</InputLabel>}
            <Select
                size='small'
                value={value.permission}
                native
                onChange={(event) => changePermission(parseInt(event.target.value as string) as PermissionType)}
            >
                <option value={PermissionType.Private}>{t('permission.private')}</option>
                {canShareToPublic && <option value={PermissionType.Public}>{t('permission.public')}</option>}
                <option value={PermissionType.InCompany}>{t('permission.myCompany')}</option>
                {canShareToCompanies && <option value={PermissionType.SharedCompanies}>{t('permission.companies')}</option>}
                <option value={PermissionType.SharedUsers}>{t('permission.users')}</option>
                {canShareToUserGroup && <option value={PermissionType.SharedUserGroups}>{t('permission.userGroups')}</option>}
            </Select>
        </FormControl>

        <PermissionTable
            permission={value.permission}
            tokens={value.tokens}
            onChange={changeTokens}
        />
    </Box>;
};

interface Props {
    canShareToPublic: boolean;
    canShareToCompanies: boolean;
    canShareToUserGroup: boolean;
    showLabel?: boolean;
    onChange: (permission: IPermissionModel) => void;
    value: IPermissionModel;
}
/*
        { permission === PermissionType.SharedCompanies && <PermissionSharedCompanies />}
        { permission === PermissionType.SharedUserGroups && <PermissionSharedUserGroups />}
        { permission === PermissionType.SharedUsers && <PermissionSharedUsers />}
*/