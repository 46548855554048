import * as THREE from 'three';

import { GeopostGridGeometry } from '../geometry/GeopostGridGeometry';
import { GeopostGridMaterial } from '../material/GeopostGridMaterial';
import { ISeismicViewer3DMap } from 'features/seismic-3d/models/interfaces/ISeismicViewer3DMap';
import { GeopostThreeJsConstants } from '../utils/GeopostThreeJsConstants';

export class GeopostGridMesh extends THREE.Mesh {

    public constructor(geometry: GeopostGridGeometry, material: THREE.MeshPhongMaterial) {
        super(geometry, material);
        this.scale.x = -1;
    }

    public adjustPosition = (gridMax: number, countTimeDepth: number, surveyHeightPixel: number) => {
        this.rotation.x = 90 * Math.PI / 180;

        const [minX, minY, maxX, maxY] = (this.geometry as GeopostGridGeometry).transformedExtent;
        const xDifference = Math.abs(maxX - minX);
        const yDifference = Math.abs(maxY - minY);
        const xPosition = maxX - (xDifference / 2);
        const zPosition = maxY - (yDifference / 2);

        const heightFactor = (countTimeDepth / surveyHeightPixel);
        const depthToPixel = Math.abs(gridMax) / heightFactor;
        const yPosition = -(depthToPixel / GeopostThreeJsConstants.yDivisionFactor);

        this.rotation.x = 90 * Math.PI / 180;
        this.position.set(xPosition, yPosition, zPosition);
    };
}