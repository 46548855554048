import { useMutation } from 'react-query';

import { axios } from 'configurations/axios';
import { useSessionStore } from 'session/useSessionStore';
import { getUrlParams } from 'features/seismic-3d/utils/Seismic3DUrlUtils';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { ITimeDepth } from 'features/seismic/models/interfaces/ITimeDepth';
import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';

export const useGetTimeDepthActiveForSeismic = () => {
    const endpoint = useSessionStore(state => state.tenantConfiguration?.endpoints.wells);
    const jwtToken = useSessionStore(state => state.jwtToken);
    return useMutation({
        mutationFn: async ({ volumeToken, wellId, trace, domain } : GetTimeDepthActiveForSeismicRequest) => {
            const response = await axios.post<IResponseAPI<ITimeDepth>>(`${endpoint}/Wells/TransformToDomain/GetTimeDepthActive`,
                {
                    volumeToken: volumeToken,
                    wellId: wellId,
                    trace: trace,
                    domain: domain
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken !== '' ? jwtToken : getUrlParams().jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data.Result;
        },
    });
};

export type GetTimeDepthActiveForSeismicRequest = {
    volumeToken: string,
    wellId: number,
    trace: number
    domain: AmplitudeDomain
};