
import { LineType } from '../../enums/LineType';
import { IGeopostSeismicLayerParams } from '../../interfaces/IGeopostSeismicLayerParams';
import { TileSourceStrategy } from './TileSourceStrategy';

export class ZSliceTileSourceStrategy extends TileSourceStrategy {
    private xlineStart: number;
    private xlineIncrement: number;

    constructor(apiUrl: string, surveyXlineStart: number, surveyXlineIncrement: number,
        onTileRequestError: (xhr: XMLHttpRequest, lineNumber: number, tileWidth: number, startTrace: number, endTrace: number) => void = () => {},
        onSettleTileRequest: (xhr: XMLHttpRequest, lineNumber: number, tileWidth: number, requestSendAt: Date) => void = () => {}) {
        super(apiUrl, onTileRequestError, onSettleTileRequest);
        this.xlineStart = surveyXlineStart;
        this.xlineIncrement = surveyXlineIncrement;
    }

    public getTileUrl = (params: IGeopostSeismicLayerParams) => {
        return super.getTileUrl(params) +
        this.addParamValueToUrl('depthNumber', params.lineNumber!.toString()) +
        this.addParamValueToUrl('verticallyInverted', params.verticallyInverted!.toString()) +
        this.addParamToUrl('minSampleValue') +
        this.addParamToUrl('maxSampleValue') +
        this.addParamToUrl('xlineStart') +
        this.addParamToUrl('xlineEnd') +
        this.addParamToUrl('inlineStart') +
        this.addParamToUrl('inlineEnd');
    };

    public getFullUrl = (src: string, xStart: number, xEnd: number, yStart: number, yEnd: number, minGain: number, maxGain: number) => {
        let fullUrl = src.replace('[inlineStart]', Math.round(xStart).toString())
            .replace('[inlineEnd]', Math.round(xEnd).toString())
            .replace('[xlineStart]', Math.round(this.xlineStart + this.xlineIncrement * yStart).toString())
            .replace('[xlineEnd]', Math.round(this.xlineStart + this.xlineIncrement * yEnd).toString())
            .replace('[minSampleValue]', minGain.toString())
            .replace('[maxSampleValue]', maxGain.toString());
        return fullUrl;
    };

    public getEndpoint = () => `${this.apiUrl}/seismic/render/zslice`;

    public onTileRequestError = (xhr: XMLHttpRequest, lineNumber: number, tileWitdth: number, startTrace: number, endTrace: number) => {};

    public getSurveyLineDimensions: (params: IGeopostSeismicLayerParams) => [number, number, number] = (params) => {
        return [params.surveyInlineStart!, params.surveyInlineEnd!, params.surveyInlineIncrement!];
    };

    public getTileLoadingKey = (fullUrl: string) => {
        const inlineStart = this.extractUrlValue(fullUrl, 'inlineStart');
        const inlineEnd = this.extractUrlValue(fullUrl, 'inlineEnd');
        const xlineStart = this.extractUrlValue(fullUrl, 'xlineStart');
        const xlineEnd = this.extractUrlValue(fullUrl, 'xlineEnd');

        return `${inlineStart}-${inlineEnd}-${xlineStart}-${xlineEnd}`;
    };
}