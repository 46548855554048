import { useState, useCallback, useEffect } from 'react';
import { AxiosResponse } from 'axios';

import { useSessionStore } from 'session/useSessionStore';
import { FetchType } from 'models/enums/FetchType';
import { ApiType } from 'models/enums/ApiType';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { TenantConfig } from 'features/tenant/model/TenantConfig';
import { axios } from 'configurations/axios';

export async function callApi<T>(path: string, options: IUseApiProps, tenantConfig? : TenantConfig, jwtToken?: string, onSettled?: (response: AxiosResponse<IResponseAPI<T>, unknown>) => void) {
    if (typeof(options.type) === 'undefined'){
        options.type = FetchType.Get;
    }

    if (typeof(options.apiType) === 'undefined'){
        options.apiType = ApiType.SeismicApi;
    }
    const method = options.type === FetchType.Get ? 'get' : 'post';

    let url = path;

    if (url.substring(0,1) !== '/') {
        url = '/' + url;
    }

    if (options.apiType === ApiType.Geometry){
        const { sourceApi } = getParamsFromUrl();

        if (window.location.href.indexOf('gcp-gpost') > -1 && sourceApi === 'tgs-cloud'){
            url = 'https://tgs-viewer-seismic-api-default.geopost.datalake.tgs.com' + url;
        }
        else {
            url = tenantConfig?.endpoints.surveyOutline + url;
        }

    } else if (options.apiType === ApiType.SeismicApi){
        url = tenantConfig?.endpoints.metadata /*'http://localhost:5000'*/ + url;
    } else if (options.apiType === ApiType.WebApi){
        url = tenantConfig?.endpoints.featureFlags + url;
    } else if (options.apiType === ApiType.Render){
        url = tenantConfig?.endpoints.render /*'http://localhost:5000'*/ + url;
    } else if (options.apiType === ApiType.LayersApi){
        url = tenantConfig?.endpoints.projections + url;
    }
    else  if (options.apiType === ApiType.LineGeometry){
        const { sourceApi } = getParamsFromUrl();

        if (window.location.href.indexOf('gcp-gpost') > -1 && sourceApi === 'tgs-cloud'){
            url = 'https://tgs-viewer-seismic-api-default.geopost.datalake.tgs.com' + url;
        }
        else {
            url = tenantConfig?.endpoints.lineGeometry + url;
        }
    } else if (options.apiType === ApiType.Processing){
        url = 'https://localhost:44357' + url;
    } else if (options.apiType === ApiType.FileIndexing) {
        url = tenantConfig?.endpoints.fileIndexing + url;
    }

    const paramsViewer = getParamsFromUrl();

    let accessToken = paramsViewer.jwtToken;
    let external_sso_access_token = paramsViewer.externalSSOAccessToken;

    if (!accessToken || accessToken === '') {
        accessToken = jwtToken ?? '';
        external_sso_access_token = jwtToken ?? '';
    }

    if (options.jwtToken){
        accessToken = options.jwtToken;
    }

    if (options.params && options.params.jwtToken){
        accessToken = options.params.jwtToken;
    }

    if (tenantConfig?.oktaAuthConfiguration){
        if (tenantConfig?.tenantToken === 'd1b07f73-0c36-449e-bab7-756ed0c07640') {
            if (window.location.href.toLowerCase().indexOf('datalake') > -1){
                external_sso_access_token = accessToken;
            }
        }
    }

    const response = await axios.request<IResponseAPI<T>>({
        method: method,
        url: url,
        headers: {
            sourceApi: getParamsFromUrl().sourceApi,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            external_sso_access_token: `Bearer ${external_sso_access_token}`,
            ...options.headers
        },
        params: options.params,
        data: options.body,
    });

    if (!!onSettled) {
        onSettled(response);
    }

    return response.data.Result;
}

export function useAPI<T>(url: string, onError?: (e: Error) => void) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<T | null>(null);
    //const {authState, oktaAuth} = useOktaAuth();
    const [localJwtToken, setLocalJwtToken] = useState<string>('');
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    useEffect(() => {
        setLocalJwtToken(jwtToken);
    },[jwtToken]);

    const execute = useCallback(async (options: IUseApiProps = {}, onSettled?: (response: AxiosResponse<IResponseAPI<T>, unknown>) => void,
        onNewError?: (e: Error) => void) => {
        try {
            setIsLoading(true);
            const response = await callApi<T>(url, options, tenantConfig, localJwtToken, onSettled);

            setData(response);
            setIsLoading(false);

            return response;
        } catch (e) {
            if (onError) {
                onError(e as Error);
            }

            if (onNewError) {
                onNewError(e as Error);
            }

            setError(e as Error);
            setIsLoading(false);
        }
    }, [tenantConfig, localJwtToken, url, onError]);

    return {
        isLoading,
        error,
        data,
        execute
    };
}

interface IUseApiProps {
	type?: FetchType;
	apiType?: ApiType,
	body?: any; //eslint-disable-line @typescript-eslint/no-explicit-any
	params?: any; //eslint-disable-line @typescript-eslint/no-explicit-any
    jwtToken?: string;
    headers?: any; //eslint-disable-line @typescript-eslint/no-explicit-any
}