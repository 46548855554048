import { Box, Button, css, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, Zoom } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Fragment, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import alertify from 'alertifyjs';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';
import { MainTableCss } from 'fileIndexing/envVar/envVar';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { ePathType, IDataSource } from '../interfaces/IDataSource';
import { useDeleteDataSource, useGetAllDataSource, useGetDataSourceByToken } from '../useDataSource';
import { DataSourceForm } from './dataSourceForm';
import { useSessionStore } from 'session/useSessionStore';

const style = css({
    '& .search-select': {
        width: '210px',
        marginRight: '15px',
    },
});

export function DataSourceTable() {
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }));

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<IDataSource | null>(null);

    const [editToken, setEditToken] = useState<string>('');
    const [deleteToken, setDeleteToken] = useState<string>('');

    const [filteredData, setFilteredData] = useState<IDataSource[] | undefined>([]);
    const [filter, setFilter] = useState<string>('');

    const [dataSourceFilter, setDataSourceFilter] = useState<ePathType>(ePathType.None);
    const [loading, setLoading] = useState<boolean>(false);

    const { data: fileSourceData, isLoading: fileSourceDataLoading, refetch } = useGetAllDataSource();
    const { data: deleteFileSourceData } = useDeleteDataSource(deleteToken);
    const { data: fileSourceDataGetByToken } = useGetDataSourceByToken(editToken);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleOpenEdit = (rowData: IDataSource) => {
        setEditToken(rowData.Token);
    };

    const handleDelete = (name: string, token: string) => {
        alertify.confirm(`Do you really wanna delete ${name}?`, async () => {
            setDeleteToken(token);
        });
    };

    const handleOpenFiles = (dataSourceToken: string) => {
        window.open(`/FileIndexing/files?tenantToken=${tenantConfig?.tenantToken}&dataSourceToken=${dataSourceToken}`, '_blank');
    };

    // reseta as informações preenchidas no modal
    useEffect(() => {
        if (!openModal) {
            setModalData(null);
        }
    }, [openModal]);

    // seta as informações para edit
    useEffect(() => {
        if (fileSourceDataGetByToken) {
            setModalData(fileSourceDataGetByToken);
            handleOpenModal();
            setEditToken('');
        }
    }, [fileSourceDataGetByToken]);

    // salva as informações de todas as linhas recebidas
    useEffect(() => {
        if (fileSourceData) {
            setFilteredData(fileSourceData);
        }
    }, [fileSourceData]);

    // realiza o filtro
    useEffect(() => {
        if (fileSourceData) {
            let filteredData = fileSourceData;

            if (dataSourceFilter !== ePathType.None) {
                filteredData = filteredData.filter(x => x.PathType === dataSourceFilter);
            }

            if (filter !== '') {
                filteredData = filteredData.filter(x =>
                    x.Name?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.RootAddress?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.FilterPath?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.FilterExtensions?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.FilterFileSizeMaximum?.toString().toLowerCase().startsWith(filter)
                );
            }

            setFilteredData(filteredData);
        }
    }, [filter, dataSourceFilter]);

    useEffect(() => {
        if (deleteFileSourceData === 'Success') {
            refetch();
        }
    }, [deleteFileSourceData]);

    // conteúdo da tabela
    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            headerAlign: 'left',
            flex: 1,
        },
        {
            field: 'RootAddress',
            headerName: 'Root Address',
            headerAlign: 'left',
            flex: 1,
        },
        {
            field: 'FilterPath',
            headerName: 'Filter Path',
            headerAlign: 'left',
            flex: 1,
        },
        {
            field: 'FilterExtensions',
            headerName: 'Filter Extensions',
            headerAlign: 'left',
            flex: 1,
        },
        {
            field: 'FilterFileSizeMaximum',
            headerName: 'Filter File Size Maximum',
            headerAlign: 'left',
            flex: 1,
        },
        {
            field: 'PathType',
            headerName: 'Path Type',
            headerAlign: 'left',
            flex: 1,
            valueGetter: (params) => {
                switch (params.row.PathType) {
                case ePathType.InternalNetwork:
                    return 'Internal Network';
                case ePathType.ExternalMidia:
                    return 'External Midia';
                case ePathType.AzureStorage:
                    return 'Azure Storage';
                case ePathType.AWSS3Bucket:
                    return 'AWSS3 Bucket';
                case ePathType.Dump:
                    return 'Dump File';
                default:
                    return '';
                }
            },
        },
        {
            field: 'Options',
            headerName: 'Options',
            headerAlign: 'center',
            sortable: false,
            minWidth: 300,
            flex: 1,
            renderCell: (params) => (
                <Box className='options-buttons'>
                    <Tooltip title='View files associated to this data source' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' onClick={() => handleOpenFiles(params.row.Token)}>
                            <LinkIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Edit institution metadata' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' className='edit-icon' onClick={() => handleOpenEdit(params.row)}>
                            <EditNoteIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Delete institution' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='error' className='delete-icon' onClick={() => handleDelete(params.row.Name, params.row.Token)}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    const getRowId = (row: IDataSource) => row.Token;

    return (
        <Fragment>
            <Box css={[style, MainTableCss]}>
                <Box className='table-header'>
                    <Typography className='table-header-title'>Data Sources</Typography>
                    <Box className='search'>
                        <FormControl className='search-select' size="small" >
                            <InputLabel color='primary'>Data Sources Filter</InputLabel>
                            <Select
                                value={dataSourceFilter}
                                onChange={(event) => { setDataSourceFilter(Number(event.target.value))}}
                                label='Data Group Filter'
                                color='primary'
                            >
                                <MenuItem value={ePathType.None}>None</MenuItem>
                                <MenuItem value={ePathType.InternalNetwork}>Internal Network</MenuItem>
                                <MenuItem value={ePathType.ExternalMidia}>External Midia</MenuItem>
                                <MenuItem value={ePathType.AzureStorage}>Azure Storage</MenuItem>
                                <MenuItem value={ePathType.AWSS3Bucket}>AWSS3 Bucket</MenuItem>
                                <MenuItem value={ePathType.Dump}>Dump File</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label='Search Data Source' variant='outlined' size='small' color='primary' value={filter} onChange={(event) => setFilter(event.target.value)} />
                        <Button variant='contained' color='primary' className='add-icon' onClick={() => handleOpenModal()}>
                            <AddIcon />
                        </Button>
                    </Box>
                </Box>
                <GeopostTable<IDataSource> filteredData={filteredData} loading={fileSourceDataLoading || loading} columns={columns} getRowId={getRowId} />
            </Box>

            <GeopostPopup
                open={openModal}
                onChangeOpen={handleCloseModal}
                resizable={false}
                width={560}
                minHeight={260}
                height={325}
                maxHeight={500}
                title={modalData ? `Edit ${modalData.Name}` : 'Add Data Source'}
                padding={'16px'}
                temporaryIgnoreFlexInContainer
            >
                <DataSourceForm setOpenModal={setOpenModal} refetchTableData={refetch} setLoading={setLoading} formData={modalData} />
            </GeopostPopup>
        </Fragment>
    );
}