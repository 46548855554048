import { useQuery } from 'react-query';

import { axios } from 'configurations/axios';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { useSessionStore } from 'session/useSessionStore';
import { ITag, ITagGroup, ITagGroupDropdown } from './interfaces/ITag';

export function useGetAllTagGroups() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllTagGroups', async () => {
        const response = await axios.request<IResponseAPI<ITagGroup[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/TagGroup/GetAllTagGroups',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useGetAllTags(tagGroupToken: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllTags' + tagGroupToken, async () => {
        const response = await axios.request<IResponseAPI<ITag[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Tag/GetAllTags',
            params: {
                tagGroupToken
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: tagGroupToken !== null && tagGroupToken !== undefined && tagGroupToken !== '',
        cacheTime: 0,
    });
}

export function useDeleteTagGroup(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteTagGroup' + token, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/TagGroup/DeleteTagGroup',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useSaveTagGroup(data: ITagGroup | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveTagGroup' + data?.Name + data?.Token + data?.TagsToken + data?.DataSubgroupToken, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/TagGroup/SaveTagGroup',
            data: {
                Name: data?.Name,
                Token: data?.Token,
                TagsToken: data?.TagsToken,
                DataSubgroupToken: data?.DataSubgroupToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: data !== null,
        cacheTime: 0,
    });
}

export function useSaveTag(data: ITag | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveTag' + data?.Name + data?.Token + data?.TagGroupToken + data?.Regex, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/Tag/SaveTag',
            data: {
                Name: data?.Name,
                Token: data?.Token,
                TagGroupToken: data?.TagGroupToken,
                Regex: data?.Regex,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: data !== null,
        cacheTime: 0,
    });
}

export function useDeleteTag(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteTag' + token, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/Tag/DeleteTag',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useReindexAllFilesByTag(tagToken: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('reindexAllFilesByTag' + tagToken, async () => {
        const response = await axios.request<IResponseAPI<ITag>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/FilesData/ReIndexFilesByTag',
            params: {
                tagToken
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: tagToken !== null && tagToken !== undefined && tagToken !== '',
        cacheTime: 0,
    });
}

export function useGetTagByToken(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getTagByToken' + token, async () => {
        const response = await axios.request<IResponseAPI<ITag>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Tag/GetTag',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useGetOptGroup() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getOptGroup', async () => {
        const response = await axios.request<IResponseAPI<ITagGroupDropdown[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/TagGroup/GetTagGroupsAndTagOptGroup',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
    });
}