import { Box, css, keyframes, Typography } from '@mui/material';
import { SignalR } from 'fileIndexing/signalR/signalR';
import { Fragment, useEffect, useState } from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { useReindexAllFilesByTag } from '../useTags';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const style = css({
    '& .fade-text': {
        animation: `${blink} 4s infinite`,
    },
});

export function ReindexTagPopup({ reindexTagToken }: Props) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken,
    }));

    const signalR = new SignalR(tenantConfig?.endpoints.signalR + '/ConnectionHub', jwtToken);
    const [logMessage, setLogMessage] = useState<string>('');

    const [indexProcess, setIndexProcess] = useState<boolean | null>(true);

    const { data: reindexTagData } = useReindexAllFilesByTag(reindexTagToken);

    signalR.listen('reindexing', (object: { log: string, indexProcessResponse: boolean }) => {
        setLogMessage(object.log);

        if (object.indexProcessResponse) {
            setIndexProcess(false);
        }
    });

    return (
        <Fragment>
            <Box css={style}>
                <Typography>{logMessage}</Typography>
                {indexProcess ?
                    (<Typography className='fade-text'>The import process keep going, do not close or reload this screen</Typography>)
                    :
                    (<Typography>The import process finished</Typography>)
                }
            </Box>
        </Fragment>
    );
}

interface Props {
    reindexTagToken: string;
}