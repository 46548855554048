import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import WKT from 'ol/format/WKT.js';
import { Image as ImageLayer } from 'ol/layer.js';
import { useEffect, useRef, useState } from 'react';
import { Geometry, LineString } from 'ol/geom';
import { Feature } from 'ol';
import { Fill, Stroke, Style } from 'ol/style';
import { Extent } from 'ol/extent';
import { EventsKey } from 'ol/events';
import { unByKey } from 'ol/Observable';
import CircleStyle from 'ol/style/Circle';
import { defaults as defaultControls } from 'ol/control.js';
import OSM from 'ol/source/OSM.js';
import TileLayer from 'ol/layer/Tile';
import 'ol-ext/geom/LineStringSplitAt';
import { Coordinate } from 'ol/coordinate';

import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { GeopostWMSSource } from 'features/seismic/models/classes/layers/GeopostWMSSource';
import { useMapStore } from 'features/seismic/stores/useMapStore';
import { colorWithAlpha } from 'features/seismic/utils/colorWithAlphaUtils';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { getTrace } from 'features/seismic/utils/calculateScreenValuesUtils';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { useGetSurveyGeometry } from '../api/useWorldMapController';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { LineType } from 'features/seismic-3d/models/enums/LineType';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { splitLineByLine } from 'features/seismic/utils/turfUtils';

const format = new WKT();
const zIndexLayerSurvey = 9999990;
const zIndex2DLayersSeismic = 999999;
const zIndexLayerSeismic = 9999992;
const zIndexLayerSeismicOverLine = 9999993;
const zIndexLayerMousePosition = 9999994;
const srid = 4326;
const mapSrid = 4326;

export function useCreateMiniMap(opened: boolean, dataGeometryLine: ILineString | null, allLayers: ImageLayer<GeopostWMSSource>[]) {
    const { calculator, volumeToken, scaleX, volumeIdentifier, surveyMetadata, createWellInMiniMap, setCreateWellInMiniMap, removeWellInMiniMap, restrict, lines2D, isInverted } = useSeismicStore(state => ({
        restrict: state.restrict,
        calculator: state.calculator,
        volumeToken: state.surveyMetadata?.VolumeToken,
        volumeIdentifier: state.volumeIdentifier,
        scaleX: state.scale.x,
        scaleY: state.scale.y,
        surveyMetadata: state.surveyMetadata,
        createWellInMiniMap: state.createWellInMiniMap,
        setCreateWellInMiniMap: state.setCreateWellInMiniMap,
        removeWellInMiniMap: state.removeWellInMiniMap,
        lines2D: state.lines2D,
        setLines2D: state.setLines2D,
        isInverted: state.isInverted
    }));

    const lineType = useLine3DNavigationStore(state => state.lineType);

    const { map, pointerMoveObserver } = useMapStore(state => ({
        map: state.map,
        pointerMoveObserver: state.pointerMoveObserver
    }));

    const urlParams = getParamsFromUrl();

    const { data: surveyGeometry, error:errorOnLoadSurveyGeometry, isLoading } = useGetSurveyGeometry(volumeToken!, volumeIdentifier, urlParams.sourceApi === 'pgs' ? srid : mapSrid, opened);

    const setIsLineGeometryLoading = useSeismicStore(state => state.setIsReLoadingLineGeoemtry);

    const [miniMap, setMiniMap] = useState<Map | null>(null);
    const mapRef = useRef<HTMLDivElement>(null);

    const layerSeismic = useRef<VectorLayer<VectorSource> | null>(null);
    const [geometrySeismic, setGeometrySeismic] = useState<LineString>();
    const [geometrySeismicMap, setGeometrySeismicMap] = useState<LineString>();
    const layerSeismicOverLine = useRef<VectorLayer<VectorSource> | null>(null);
    const changeResolutionEventKey = useRef<EventsKey | null>(null);
    const pointerMoveEventKey = useRef<EventsKey | null>(null);
    const layerMouserPosition = useRef<VectorLayer<VectorSource> | null>(null);

    const surveyOlGeometryRef = useRef<Geometry>();

    const [ errorMessage, setErrorMessage ] = useState<string>();

    const [layerSurvey, setLayerSurvey] = useState<VectorLayer<VectorSource> | null>(null);

    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    useEffect(() => {
        /*if (pointerMoveObserver){
            pointerMoveObserver.subscribe()
        }*/
    }, [pointerMoveObserver]);

    useEffect(() => {
        if (errorOnLoadSurveyGeometry){
            setErrorMessage('Error on load survey geometry');
        }
    }, [errorOnLoadSurveyGeometry]);

    useEffect(() => {
        if (!mapRef.current){
            return;
        }
        const createMiniMap = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            controls: defaultControls({ attribution: false }),
            pixelRatio: 1,
            target: mapRef.current,
            view: new View({
                zoom: 7,
                projection: `EPSG:4326`
            }),
        });

        for (let i = 0; i < allLayers.length; i++) {
            createMiniMap.addLayer(allLayers[i]);
        }

        setMiniMap(createMiniMap);
    }, []);

    useEffect(() => {
        if (!mapRef.current || !surveyGeometry || !miniMap){
            return;
        }

        const featureSurvey = format.readFeature(surveyGeometry, {
            featureProjection: `EPSG:${mapSrid}`,
            dataProjection: `EPSG:${urlParams.sourceApi === 'pgs' ? srid : mapSrid}`,
        });

        featureSurvey.setStyle(new Style({
            fill: new Fill({
                color: colorWithAlpha('#FFFFFF', 0.45),
            }),
            stroke: new Stroke({
                color: '#FF0000',
                width: 2,
            })
        }));

        const layer = new VectorLayer({ source: new VectorSource({ features: [featureSurvey] }) });
        layer.setZIndex(zIndexLayerSurvey);

        if (layer !== layerSurvey && layerSurvey !== null) {
            miniMap.removeLayer(layerSurvey);
        }

        setLayerSurvey(layer);

        const geometry = featureSurvey.getGeometry()?.getExtent() as Extent;
        surveyOlGeometryRef.current = featureSurvey.getGeometry();

        miniMap.addLayer(layer);

        if (firstLoad) {
            miniMap.getView().fit(geometry);

            let zoom = miniMap.getView().getZoom();
            if (zoom) {
                miniMap.getView().setZoom(zoom - 0.5);
            }

            setFirstLoad(false);
        }

    }, [surveyGeometry, miniMap]);

    // Cria as linhas 2D no mapa quando o viewer for 2D
    useEffect(() => {
        if (surveyMetadata?.Type === SurveyType.Seismic2D && lines2D && miniMap) {
            let featureLines = [];

            for (let i = 0; i < lines2D.length; i++) {

                const featureLine = format.readFeature(lines2D[i].Wkt, {
                    featureProjection: `EPSG:${mapSrid}`,
                    dataProjection: `EPSG:${srid}`,
                });

                featureLine.setStyle(new Style({
                    stroke: new Stroke({
                        color: '#FFFF00',
                        width: 1,
                    })
                }));

                featureLines.push(featureLine);
            }
            const layerLine = new VectorLayer({ source: new VectorSource({ features: featureLines }) });
            layerLine.setZIndex(zIndex2DLayersSeismic);
            miniMap.addLayer(layerLine);
        }
    },[lines2D, miniMap, surveyMetadata]);

    // cria a linha sismica
    useEffect(() => {
        if (!dataGeometryLine && !surveyGeometry) {
            return;
        }
        setIsLineGeometryLoading(false);

        if (layerSeismic.current) {
            miniMap?.removeLayer(layerSeismic.current);
        }
        const wkt = lineType === LineType.Line2D ? surveyGeometry : dataGeometryLine?.Line;
        console.log('cria a linha sismica, dataGeometryLine', wkt);
        if (!wkt) {
            setErrorMessage('An error occured while loading the line geometry.');
        }
        else if (!!miniMap) {
            if (lineType === LineType.ZSlice) {
                return;
            }

            if ((!wkt || wkt.toLowerCase() === 'empty')) {
                setErrorMessage('An error occured while loading the line geometry.');
                setGeometrySeismic(undefined);
                setGeometrySeismicMap(undefined);
                return;
            }

            const featureLineOriginal = format.readFeature(wkt, {
                featureProjection: `EPSG:${mapSrid}`,
                dataProjection: `EPSG:${urlParams.sourceApi === 'pgs' ? srid : mapSrid}`,
            });

            const featureLine = format.readFeature(wkt, {
                featureProjection: `EPSG:${mapSrid}`,
                dataProjection: `EPSG:${srid}`,
            });

            setGeometrySeismic(featureLineOriginal.getGeometry() as LineString);
            setGeometrySeismicMap(featureLine.getGeometry() as LineString);
            featureLine.setStyle(new Style({
                stroke: new Stroke({
                    color: '#0511F2',
                    width: 2,
                })
            }));

            console.log(featureLine.getGeometry(), featureLineOriginal.getGeometry());

            const layerLine = new VectorLayer({ source: new VectorSource({ features: [featureLine] }) });
            layerLine.setZIndex(zIndexLayerSeismic);
            miniMap.addLayer(layerLine);

            layerSeismic.current = layerLine;
        }
    }, [dataGeometryLine, calculator, miniMap, surveyGeometry]);

    useEffect(() => {
        if (lineType === LineType.ZSlice && !!miniMap) {
            if (!!layerSeismic.current) {
                miniMap.removeLayer(layerSeismic.current);
            }
            if (!!layerSeismicOverLine.current) {
                miniMap.removeLayer(layerSeismicOverLine.current);
            }
            if (!!layerMouserPosition.current) {
                miniMap.removeLayer(layerMouserPosition.current);
            }
        }
    }, [lineType]);

    // mostra o espaço da tela visivel na linha
    useEffect(() => {
        if (map && scaleX && (!!dataGeometryLine || (lineType === LineType.Line2D && !!surveyGeometry)) && geometrySeismic && geometrySeismicMap && miniMap && restrict) {
            if (!restrict.SeismicDisplayRealTimePositionOnMap){
                return;
            }

            if (changeResolutionEventKey.current) {
                unByKey(changeResolutionEventKey.current);
            }

            if (lineType === LineType.ZSlice) {
                return;
            }

            changeResolutionEventKey.current = map.on('moveend', function () {
                if (!calculator || !surveyMetadata) {
                    return;
                }

                if (layerSeismicOverLine.current){
                    miniMap.removeLayer(layerSeismicOverLine.current);
                }

                const size = map.getSize();

                const extent = map.getView().calculateExtent(size);
                const increment = calculator.getLineIncrement(surveyMetadata);
                const start = calculator.getMinTrace(surveyMetadata);
                const xMin = getTrace(extent[0], scaleX, start, increment);
                const xMax = getTrace(extent[2], scaleX, start, increment);

                const maxTrace = lineType !== LineType.Line2D ? dataGeometryLine!.Max: calculator.getMaxTrace(surveyMetadata);
                const minTrace = lineType !== LineType.Line2D ? dataGeometryLine!.Min: calculator.getMinTrace(surveyMetadata);

                let xMinFrac = (xMin - minTrace) / (maxTrace - minTrace);
                let xMaxFrac = (xMax - minTrace) / (maxTrace - minTrace);

                if (xMinFrac < 0 || xMinFrac === Infinity || xMinFrac > 1) {
                    xMinFrac = 0;
                }

                if (xMaxFrac > 1 || xMaxFrac === Infinity || xMaxFrac < 0) {
                    xMaxFrac = 1;
                }

                const firstCoordinate = geometrySeismic.getCoordinateAt(xMinFrac);
                const lastCoordinate = geometrySeismic.getCoordinateAt(xMaxFrac);

                const turfLineString = splitLineByLine(geometrySeismic, firstCoordinate, lastCoordinate);

                const feature = new Feature({ geometry: turfLineString });

                const styleOverLine = new Style({
                    stroke: new Stroke({
                        color: '#00FE00',
                        width: 2,
                    })
                });

                feature.setStyle(styleOverLine);

                /*const overLineTransformed = format.readFeature('LINESTRING(' + coordinate[0][0] + ' ' + coordinate[0][1] + ',' + coordinate[1][0] + ' ' + coordinate[1][1] + ')', {
                    featureProjection: `EPSG:${srid}`,
                    dataProjection: `EPSG:${mapSrid}`,
                });
                overLineTransformed.setStyle(styleOverLine);
                */

                const layerOverLine = new VectorLayer({ source: new VectorSource({ features: [feature] }) });

                layerOverLine.setZIndex(zIndexLayerSeismicOverLine);

                miniMap?.addLayer(layerOverLine);

                layerSeismicOverLine.current = layerOverLine;
            });

            map.dispatchEvent('moveend');
        }
    }, [map, miniMap, scaleX, dataGeometryLine, geometrySeismic, restrict, geometrySeismicMap, calculator, surveyMetadata]);

    // mostra a movimentação do mouse
    useEffect(() => {
        if (pointerMoveEventKey.current) {
            unByKey(pointerMoveEventKey.current);
        }
        if (map && scaleX && miniMap && (dataGeometryLine || (lineType === LineType.Line2D && !!surveyGeometry))  && restrict) {
            if (!restrict.SeismicDisplayRealTimePositionOnMap){
                return;
            }
            if (lineType === LineType.ZSlice) {
                return;
            }

            pointerMoveEventKey.current = map.on('pointermove', (event) => {
                if (!calculator || !surveyMetadata) {
                    return;
                }

                miniMap?.removeLayer(layerMouserPosition.current!);

                const coordinate = event.coordinate;

                const increment = calculator.getLineIncrement(surveyMetadata);
                const start = calculator.getMinTrace(surveyMetadata);

                let xPosition = getTrace(coordinate[0], scaleX, start, increment);
                let mouseCoordinate = [0, 0];
                if (!!geometrySeismic) {
                    //let linePositionFraction = (surveyMetadata.Type === SurveyType.Seismic3D) ? (xPosition - dataGeometryLine.Min) / (dataGeometryLine.Max - dataGeometryLine.Min): (xPosition) / (dataGeometryLine.TotalTraces);
                    let linePositionFraction = 0;

                    const maxTrace = lineType !== LineType.Line2D ? dataGeometryLine!.Max : calculator.getMaxTrace(surveyMetadata);
                    const minTrace = lineType !== LineType.Line2D ? dataGeometryLine!.Min : calculator.getMinTrace(surveyMetadata);
                    const totalTraces = lineType !== LineType.Line2D ? dataGeometryLine!.TotalTraces : maxTrace - minTrace + 1;

                    if (isInverted) {
                        xPosition = maxTrace - xPosition + minTrace;
                    }

                    if (surveyMetadata.Type === SurveyType.Seismic3D){
                        linePositionFraction = (xPosition - minTrace) / (maxTrace - minTrace);
                    }
                    else {
                        linePositionFraction = (xPosition) / (totalTraces);
                    }

                    //console.log(linePositionFraction);

                    if (linePositionFraction < 0) {
                        linePositionFraction = 0;
                    }
                    else if (linePositionFraction > 1) {
                        linePositionFraction = 1;
                    }

                    mouseCoordinate = geometrySeismic.getCoordinateAt(linePositionFraction);
                }

                //const geometry = new Circle(geometrySeismic.getCoordinateAt(linePositionFraction), 2400);
                //const geometry = new Point(mouseCoordinate);
                ///const circleFeature = new Feature({ geometry: geometry });
                if (isNaN(mouseCoordinate[0]) || isNaN(mouseCoordinate[1])){
                    return;
                }
                const circleFeature = format.readFeature('POINT(' + mouseCoordinate[0] + ' ' + mouseCoordinate[1] + ')', {
                    featureProjection: `EPSG:${mapSrid}`,
                    dataProjection: `EPSG:${urlParams.sourceApi === 'pgs' ? srid : mapSrid}`,
                });

                circleFeature.setStyle(new Style({
                    image: new CircleStyle({
                        radius: 4,
                        fill: new Fill({
                            color: '#ff0000',
                        }),
                        stroke: new Stroke({
                            color: '#000000',
                        }),
                    })
                }),
                );

                const layerCircle = new VectorLayer({ source: new VectorSource({ features: [circleFeature] }) });
                layerCircle.setZIndex(zIndexLayerMousePosition);
                layerMouserPosition.current = layerCircle;
                miniMap?.addLayer(layerCircle);
                /*if (lineType !== LineType.ZSlice) {
                    const layerCircle = new VectorLayer({ source: new VectorSource({ features: [circleFeature] }) });
                    layerCircle.setZIndex(zIndexLayerMousePosition);
                    layerMouserPosition.current = layerCircle;
                    miniMap?.addLayer(layerCircle);
                }*/
            });
        }
    }, [map, scaleX, geometrySeismic, miniMap, dataGeometryLine, restrict, isInverted, lineType, calculator, surveyGeometry]);

    useEffect(() => {
        if (miniMap) {
            if (layerSeismicOverLine.current) {
                miniMap.removeLayer(layerSeismicOverLine.current);
            }
            if (layerMouserPosition.current) {
                miniMap.removeLayer(layerMouserPosition.current);
            }
        }
    }, [dataGeometryLine]);

    useEffect(() => {
        if (createWellInMiniMap.length > 0 && miniMap) {
            for (let i in createWellInMiniMap) {
                miniMap.addLayer(createWellInMiniMap[i]);
            }
            setCreateWellInMiniMap([]);
        }
    }, [createWellInMiniMap, miniMap]);

    useEffect(() => {
        if (removeWellInMiniMap.length > 0 && miniMap) {
            for (let i in removeWellInMiniMap) {
                miniMap.removeLayer(removeWellInMiniMap[i]);;
            }
        }
    }, [removeWellInMiniMap, miniMap]);

    return { mapRef: mapRef, isLoading: isLoading, errorMessage };
}