import { useState, Fragment, useEffect, MouseEventHandler, useRef } from 'react';
import { CircularProgress, FormControlLabel, Typography, Box, IconButton, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LayersIcon from '@mui/icons-material/Layers';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { Stack } from '@mui/system';
import alertify from 'alertifyjs';

import { useSeismicStore } from '../../stores/useSeismicStore';
import { useLine3DNavigationStore } from '../../stores/useLine3DNavigationStore';
import { IIntersections } from '../../models/interfaces/IIntersection';
import { useMapStore } from '../../stores/useMapStore';
import { createStyleProjections } from '../../utils/seismicProjectionsUtils';
import { useGetSeismicAttributes } from './api/useSeismicAttributesController';
import { SeismicAttributesCheckbox } from './SeismicAttributesCheckbox';
import { ISurveyMetadata, SeismicAttribute } from 'features/seismic/models/interfaces/ISurveyMetadata';
import { useAPI } from 'hooks/useAPI';
import { IColorbar } from 'features/seismic/models/interfaces/IColorbar';
import { SeismicAttributesSelect } from './SeismicAttributesSelect';
import { useTileSize } from 'features/seismic/hooks/useTileSize';
import { LineType } from 'features/seismic-3d/models/enums/LineType';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { SeismicAttributeBehavior } from 'features/seismic/models/enums/SeismicAttributeBehavior';

interface IMetadataResponse {
    Metadata: ISurveyMetadata;
    Resolutions: number[];
    Colorbar: IColorbar
}

export function SeismicAttributes({setOpenedPopup, setSelectedAttributes} : Props) {
    const { t } = useTranslation();

    const urlApi ='/Seismic/Volume/Metadata';

    const { execute: executeMainMetadata, isLoading: isLoading } = useAPI<IMetadataResponse>(urlApi, (error) => {
        if (error.message.indexOf('Request failed with status code 401') > -1){
            alertify.alert('Authentication error');
        }
        else if (error.message.indexOf('Network Error') > -1){
            alertify.alert('Network error');
        }
        else {
            alertify.alert('An error ocurred');
        }
    });

    const { map } = useMapStore(state => ({
        map: state.map
    }));

    const { surveyMetadata, setSurveyMetadata, setSurveyMetadataOverlay, setColorBar, setVolumeTokenOverlay,setVolumeToken ,setVolumeTokenSwipe, setSurveyMetadataSwipe, setAbsoluteGain, volumeToken, scale } = useSeismicStore(state => ({
        surveyMetadata: state.surveyMetadata,
        setColorBar: state.setColorbar,
        scale: state.scale,
        volumeToken: state.volumeToken,
        setVolumeToken: state.setVolumeToken,
        setVolumeTokenOverlay: state.setVolumeTokenOverlay,
        setVolumeTokenSwipe: state.setVolumeTokenSwipe,
        setAbsoluteGain: state.setAbsoluteGain,
        setSurveyMetadata: state.setSurveyMetadata,
        setSurveyMetadataOverlay: state.setSurveyMetadataOverlay,
        setSurveyMetadataSwipe: state.setSurveyMetadataSwipe
    }));

    const {width: tileWidthDefault} = useTileSize();

    const { lineNumber, lineType } = useLine3DNavigationStore(state => ({
        lineNumber: state.lineNumber,
        lineType: state.lineType
    }));
    const { data: dataAttributes, isLoading: isLoadingAttributes } = useGetSeismicAttributes(volumeToken ?? '');
    const [opened, setOpened] = useState(false);
    const [isLoadingAttribute, setIsLoadingAttribute] = useState(false);
    const iconButtonElementRef = useRef<HTMLButtonElement | null>(null);
    const [baseProductName, setBaseProductName] = useState<string>('');

    const drawProjection = (intersectionInformation: IIntersections[], token: string) => {
        if (intersectionInformation && surveyMetadata) {
            //DRAW LAYER OR OVERLAY
        }
    };

    const handleClickButton:MouseEventHandler<HTMLButtonElement> = (event) => {
        setOpened((value) => !value);
    };

    if (isLoadingAttributes ) {
        return <CircularProgress />;
    }

    const adjustDataAttributes = (data: SeismicAttribute[] | undefined) => {
        if (surveyMetadata?.Type === SurveyType.Seismic3D) {
            if (lineType === LineType.Inline) {
                let filter = data?.filter((x) => {
                    return (x.Survey3DInfo?.InlineStart <= lineNumber) &&
                           (x.Survey3DInfo?.InlineEnd >= lineNumber) &&
                           ((x.Survey3DInfo?.InlineEnd - lineNumber) % x.Survey3DInfo?.InlineIncrement === 0);
                });
                return filter;
            }
            else {
                let filter = data?.filter((x) => {
                    return (x.Survey3DInfo?.XlineStart <= lineNumber) &&
                           (x.Survey3DInfo?.XlineEnd >= lineNumber) &&
                           ((x.Survey3DInfo?.XlineEnd - lineNumber) % x.Survey3DInfo?.XlineIncrement === 0);
                });
                return filter;
            }
        }
        else {
            // TODO: CÓDIGO PARA 2D
            return data;
        }
    };

    const getAttributesOverlay = () => {
        let filtered = dataAttributes?.filter((x) => x.Behavior === SeismicAttributeBehavior.Overlay
        ).sort(function (a, b) {
            return a.Product.localeCompare(b.Product);
        });

        return filtered;
    };

    const getAttributesBase = () => {
        let filtered =  dataAttributes?.filter((x) => x.Behavior === SeismicAttributeBehavior.Physical
        ).sort(function (a, b) {
            return a.Product.localeCompare(b.Product);
        });

        if (filtered?.length === 0){
            return dataAttributes;
        }

        return filtered;
    };

    const getAttributesSwipe = () => {
        const currentBaseAttribute = dataAttributes?.find(x => x.VolumeToken === surveyMetadata?.VolumeToken);

        let nonOverlayAttr = dataAttributes?.filter((x) => {
            return (x.Product.toLowerCase().indexOf('vel') === -1) &&
                (x.Product.toLowerCase().indexOf('model') === -1) &&
                (x.Product.toLowerCase().indexOf('vtilt') === -1) &&
                (x.Product.toLowerCase().indexOf('vvert') === -1);
        });

        if (currentBaseAttribute) {
            const isDepth = currentBaseAttribute.Product.toLowerCase().indexOf('depth') > -1 || currentBaseAttribute.Product.toLowerCase().indexOf('psdm') > -1;
            nonOverlayAttr = nonOverlayAttr?.filter((x) => {
                return (
                    isDepth && (x.Product.toLowerCase().indexOf('depth') > -1 || x.Product.toLowerCase().indexOf('psdm') > -1)
                ) || (
                    !isDepth && (x.Product.toLowerCase().indexOf('time') > -1 || x.Product.toLowerCase().indexOf('pstm') > -1)
                );
            });
        }

        if (nonOverlayAttr && nonOverlayAttr.length > 0) {
            nonOverlayAttr = nonOverlayAttr.sort(function (a, b) {
                return a.Product.localeCompare(b.Product);
            });
            return nonOverlayAttr;
        } else {
            return dataAttributes;
        }
    };

    return (
        <Box>
            <Fragment>
                <IconButton
                    id='attributes'
                    color='primary'
                    ref={iconButtonElementRef}
                    onClick={handleClickButton}
                    title={t('attributes')}>
                    <AnalyticsIcon />
                </IconButton>
            </Fragment>

            <Popover
                anchorEl={iconButtonElementRef.current}
                open={opened}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                onClose={() => setOpened(false)}
            >
                <Stack style={{ backgroundColor: '#041f3b'}} padding={1} direction='column' justifyContent='flex-start' visibility={opened ? 'visible': 'hidden'}>
                    {
                        isLoading ?  (
                            <Stack direction={'row'}>
                                <Box marginRight={2}>
                                    <CircularProgress style={{color: 'grey'}}/>
                                </Box>
                                <Box paddingTop={1}>
                                    <strong style={{marginBottom: '7px'}}>Loading attribute information...</strong>
                                </Box>
                            </Stack>
                        )
                            : null
                    }
                    <Box style={{flexDirection: 'row'}}>
                        <SeismicAttributesSelect
                            title={'Base Product'}
                            isClearable={false}
                            selectedDefault={surveyMetadata && surveyMetadata?.VolumeToken ? surveyMetadata?.VolumeToken : '-'}
                            attributes={getAttributesBase()}
                            allAttributes={dataAttributes}
                            afterGetCallback={async function (token: string) {
                                if (token !== '-') {
                                    const response = await executeMainMetadata({ params: {
                                        filePath: '',
                                        volumeToken: token,
                                        tileWidth: tileWidthDefault,
                                        screenHeight: 893
                                    }});

                                    if (response) {
                                        if (token === '-') {
                                            setVolumeToken('-');
                                        } else {
                                            const metadata = response.Metadata;
                                            metadata.VolumeToken = token;
                                            setSurveyMetadata(metadata);
                                            setVolumeToken(token);
                                        }
                                    }
                                    console.log('changed token ' + token);
                                } else {
                                    setVolumeToken('-');
                                }
                            }}
                            baseProductName={baseProductName}
                            setBaseProductName={setBaseProductName}
                            setOpenedPopup={setOpenedPopup}
                            setSelectedAttributes={setSelectedAttributes}
                        />
                    </Box>
                    <Box>
                        <SeismicAttributesSelect
                            title={'Swipe'}
                            isClearable={true}
                            selectedDefault='-'
                            //attributes={dataAttributes?.filter((el) => el.VolumeName.toLowerCase().indexOf('vel') > -1)}
                            attributes={adjustDataAttributes(getAttributesSwipe())}
                            allAttributes={getAttributesSwipe()}
                            afterGetCallback={async function (token: string) {
                                if (token !== '-') {
                                    const response = await executeMainMetadata({ params: {
                                        filePath: '',
                                        volumeToken: token,
                                        tileWidth: tileWidthDefault,
                                        screenHeight: 893
                                    }});

                                    if (response) {
                                        if (token === '-') {
                                            setVolumeTokenSwipe('-');
                                        } else {
                                            const metadata = response.Metadata;
                                            metadata.VolumeToken = token;
                                            setSurveyMetadataSwipe(metadata);
                                            setVolumeTokenSwipe(token);
                                        }
                                    }
                                    console.log('changed token ' + token);
                                } else {
                                    setVolumeTokenSwipe('-');
                                }
                            }}
                            baseProductName={baseProductName}
                            setBaseProductName={setBaseProductName}
                            setOpenedPopup={setOpenedPopup}
                            setSelectedAttributes={setSelectedAttributes}
                        />
                    </Box>
                    <Box>
                        <SeismicAttributesSelect
                            title={'Overlay'}
                            isClearable={true}
                            selectedDefault='-'
                            //attributes={dataAttributes?.filter((el) => el.VolumeName.toLowerCase().indexOf('vel') > -1)}
                            attributes={adjustDataAttributes(getAttributesOverlay())}
                            allAttributes={getAttributesOverlay()}
                            afterGetCallback={async function (token: string) {
                                alert(token);
                                if (token !== '-') {
                                    const response = await executeMainMetadata({ params: {
                                        filePath: '',
                                        volumeToken: token,
                                        tileWidth: tileWidthDefault,
                                        screenHeight: 893
                                    }});

                                    if (response) {
                                        if (token === '-') {
                                            setVolumeTokenOverlay('-');
                                        } else {
                                            const metadata = response.Metadata;
                                            metadata.VolumeToken = token;
                                            setSurveyMetadataOverlay(metadata);
                                            setVolumeTokenOverlay(token);
                                        }
                                    }
                                    console.log('changed token ' + token);
                                } else {
                                    setVolumeTokenOverlay('-');
                                }
                            }}
                            baseProductName={baseProductName}
                            setBaseProductName={setBaseProductName}
                            setOpenedPopup={setOpenedPopup}
                            setSelectedAttributes={setSelectedAttributes}
                        />
                    </Box>
                    {/* {dataAttributes?.map((item:SeismicAttribute, index:number) => (
                        <Box>
                            <SeismicAttributesCheckbox
                                attribute={item}
                                key={item.VolumeToken}
                                afterGetCallback={async function (token: string, name: string) {
                                    const response = await executeMainMetadata({ params: {
                                        filePath: '',
                                        volumeToken: token,
                                        tileWidth: tileWidthDefault,
                                        screenHeight: 893
                                    }});

                                    if (response) {
                                        const metadata = response.Metadata;
                                        metadata.VolumeToken = token;
                                        if (name.toLowerCase().indexOf('vel') > -1 || name.toLowerCase().indexOf('model') > -1) {
                                            setSurveyMetadataOverlay(metadata);
                                            setVolumeTokenOverlay(token);
                                        } else {
                                            setSurveyMetadata(metadata);
                                            setVolumeToken(token);
                                            setAbsoluteGain({
                                                min: metadata.MinSampleValue,
                                                max: metadata.MaxSampleValue
                                            });
                                        }
                                    }
                                    console.log('changed token ' + token);
                                }}
                            />
                        </Box>
                    ))} */}
                </Stack>
            </Popover>
        </Box>
    );
}

interface Props {
    setOpenedPopup: (value: boolean) => void;
    setSelectedAttributes: (value: {
        baseProductName: string,
        all: SeismicAttribute[]
    } | undefined) => void;
}