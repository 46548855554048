import { css } from '@emotion/react';
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useMemo, useState,  } from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { useMapStore } from '../stores/useMapStore';
import { useSeismicStore } from '../stores/useSeismicStore';

const containerDivStyle = css({ display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    //height: '60%',
    '& .container-sample-legend':{
        //height: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .sample-legend-child:not(:last-child)':{
        flexGrow:1
    },'& .sample-legend-label':{
        writingMode: 'vertical-lr',
        transform: 'rotate(-180deg)'
    }
});

export function ColorBarLegend(){
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    const { colorbar,surveyMetadata,gain, absoluteGain } = useSeismicStore(state => ({
        colorbar: state.colorbar,
        surveyMetadata: state.surveyMetadata,
        gain : state.gain,
        absoluteGain: state.absoluteGain
    }));
    const {layer} = useMapStore(state => ({
        layer: state.mainSeismicLayer
    })) ;
    const [loading,setLoading] = useState(false);
    let domain = '';
    if (surveyMetadata){
        domain = surveyMetadata.Domain === 0 ? 'Time' : 'Depth';
    }
    //const samplesLegends = [surveyMetadata?.MaxSampleValue ? surveyMetadata?.MaxSampleValue : 0 , surveyMetadata?.MinSampleValue];
    const samplesLegends = [absoluteGain.max , absoluteGain.min];
    const imageUrl = useMemo(() => {
        if (!!absoluteGain && !!colorbar && !!tenantConfig && !!domain && !!layer) {
            return `${tenantConfig.endpoints.render}/Seismic/Colorbar/Legend?minSampleValue=${layer.getSampleValues().minSampleValue}&maxSampleValue=${layer.getSampleValues().maxSampleValue}&colorbar=${colorbar.Name}&domain=${domain}&jpgQuality=70`;
        }
    }, [absoluteGain, colorbar, domain, layer, tenantConfig]);

    return (
        <div css={containerDivStyle}>
            {/* <div className='container-sample-legend'>
                {samplesLegends.map((val,idx)=> <div className='sample-legend-child' key={idx}>{parseInt(val ? val.toString() : '0' )}</div>)}
            </div>
            <div  style={{border : '1px solid',width:'2rem',height:'100%',background:gradient,padding:0,margin:0}}>
            </div>
            <div className='sample-legend-label'>
                {domain + '(s)'}
            </div> */}

            {loading || !imageUrl ?
                <Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
                    <CircularProgress></CircularProgress>
                </Box>
                :
                <img height={450} src={imageUrl} onLoadStart={()=>setLoading(true)} onLoad={()=>setLoading(false)}  alt="colorbar legend" />
            }
        </div>
    );
}