import { seismic3DColorBar } from 'features/seismic-3d/components/utils/colorUtils';
import { IGeopostColorBar } from 'features/seismic-3d/models/interfaces/IGeopostColorBar';
import { IGeopostRgbaColor } from 'features/seismic-3d/models/interfaces/IGeopostRgbaColor';
import { ISeismicViewer3DMap } from 'features/seismic-3d/models/interfaces/ISeismicViewer3DMap';
import { IValuedGeopostRgbaColor } from 'features/seismic-3d/models/interfaces/IValuedGeopostRgbaColor';
import { colorBars } from 'features/seismic/utils/colorBars';

export const applyShadeAndColorToImageData = (map: ISeismicViewer3DMap, imageData: ImageData, colored: boolean) => {

};

export const prepareColorBar = (userMin: number, userMax: number, defaultColors: IGeopostRgbaColor[]) => {
    const colorBar : IValuedGeopostRgbaColor[] = [];
    let rangeValue = Math.abs(userMin - userMax);

    const initialColor : IValuedGeopostRgbaColor = {
        value: userMin - 5,
        multiple: 0,
        color: {
            ...(defaultColors[0].color),
            opacity: 0
        }
    };
    colorBar.push(initialColor);

    defaultColors.forEach(defaultColor => {
        const color : IValuedGeopostRgbaColor = {
            value: userMin + (rangeValue * defaultColor.multiple),
            multiple: parseFloat(defaultColor.multiple.toFixed(2)),
            color: defaultColor.color
        };
        colorBar.push(color);
    });

    const finalColor : IValuedGeopostRgbaColor = {
        value: userMax + 1,
        multiple: 0,
        color: {
            ...(defaultColors[defaultColors.length - 1].color),
            opacity: 0
        }
    };
    colorBar.push(finalColor);

    return colorBar;
};

