import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { Position } from 'components/geopostPopup/models/enums/Position';
import { SeismicAttributesContent } from './SeismicAttributesContent';
import { SeismicAttribute } from 'features/seismic/models/interfaces/ISurveyMetadata';

export function SeismicAttributesPopup({ opened, setOpened, seismicAttributes } : Props) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [width, setWidth] = useState(screenWidth * 0.95);

    useEffect(() => {
        console.log(screenWidth);
        if (screenWidth <= 1380) {
            setWidth(screenWidth * 0.95);
        }
        else {
            setWidth(1300);
        }
    }, []);

    const { t } = useTranslation();

    const closePopup = () => {
        setOpened(false);
    };

    return (
        <GeopostPopup
            temporaryIgnoreFlexInContainer={false}
            open={opened}
            title={t('seismicAttributes')}
            onChangeOpen={value => setOpened(value)}
            overlay={false}
            height={350}
            width={width}
            position={Position.center}
        >
            <SeismicAttributesContent seismicAttributes={seismicAttributes} closePopup={closePopup}/>
        </GeopostPopup>
    );
}

interface Props {
    opened: boolean;
    setOpened: (value: boolean) => void;
    seismicAttributes: {
        baseProductName: string,
        all: SeismicAttribute[]
    } | undefined;
}