// @ts-ignore
import Graticule, { Options } from 'ol-ext/control/Graticule';
import {transform as ol_proj_transform} from 'ol/proj.js';
import {get as ol_proj_get} from 'ol/proj.js';

export class GeopostSeismicGraticule extends Graticule {
    constructor(options: Options) {
        super(options);

        //geopost
        this.set('stepY', options.stepY);
        this.set('increment', options.increment);
        //fim geopost
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _draw(e: any){
        if (this.get('maxResolution') < e.frameState.viewState.resolution)
        {return};

        // @ts-ignore
        let ctx = this.getContext(e);
        let canvas = ctx.canvas;
        let ratio = e.frameState.pixelRatio;
        let w = canvas.width / ratio;
        let h = canvas.height / ratio;

        let proj = this.get('projection');

        let map = this.getMap();

        if (!map){
            return;
        }

        let bbox = [map!.getCoordinateFromPixel([0, 0]),
            map!.getCoordinateFromPixel([w, 0]),
            map!.getCoordinateFromPixel([w, h]),
            map!.getCoordinateFromPixel([0, h])
        ];
        let increment = this.get('increment');
        let xmax = -Infinity;
        let xmin = Infinity;
        let ymax = -Infinity;
        let ymin = Infinity;
        for (let i = 0, c; c = bbox[i]; i++) {
            bbox[i] = ol_proj_transform(c, map.getView().getProjection(), proj);
            xmax = Math.max(xmax, bbox[i][0]);
            xmin = Math.min(xmin, bbox[i][0]);
            ymax = Math.max(ymax, bbox[i][1]);
            ymin = Math.min(ymin, bbox[i][1]);
        }

        let spacing = this.get('spacing');
        let step = this.get('step');
        //geopost
        let stepY = this.get('stepY') ?? step;
        //fim geopost
        let step2 = this.get('stepCoord');
        let borderWidth = this.get('borderWidth');
        let margin = this.get('margin');

        // Limit max line draw
        let ds = (xmax - xmin) / step * spacing;

        //geopost comment
        /*console.log(step, spacing, xmax, xmin, ds, w, (ds > w));
        if (ds > w) {
            let dt = Math.round((xmax - xmin) / w * spacing / step);
            step *= dt;
            // @ts-ignore

            if (step > this.fac) {
                // @ts-ignore

                step = Math.round(step / this.fac) * this.fac;
            };
        }*/
        //fim geopost comment

        let intervals = this.get('intervals');

        if (Array.isArray(intervals)) {
            let interval = intervals[0];
            for (let i = 0, ii = intervals.length; i < ii; ++i) {
                if (step >= intervals[i]) {
                    break;
                }
                interval = intervals[i];
            }
            step = interval;
        }
        let precision = this.get('precision');
        let calcStep = step;
        if (precision > 0 && step > precision) {
            calcStep = step / Math.ceil(step / precision);
        }

        //geopost
        let calcStepY = stepY;
        /*if (precision > 0 && stepY > precision) {
            calcStepY = stepY / Math.ceil(stepY / precision);
        }*/
        //fim geopost

        console.log('old', xmin, xmax, ymin, ymax);

        xmin = (Math.floor(xmin / step)) * step - step;
        xmax = (Math.floor(xmax / step)) * step + 2 * step;

        //geopost
        ymin = (Math.floor(ymin / stepY)) * stepY - stepY;
        ymax = (Math.floor(ymax / stepY)) * stepY + 4 * stepY;
        //fim geopost
        console.log('new', xmin, xmax, ymin, ymax);

        // @ts-ignore
        let extent = ol_proj_get(proj).getExtent();
        if (extent) {
            if (xmin < extent[0])
            {xmin = extent[0]};
            if (ymin < extent[1])
            {ymin = extent[1]};
            if (xmax > extent[2])
            {xmax = extent[2] + step};
            if (ymax > extent[3])
            {ymax = extent[3] + step};
        }

        let hasLines = this.getStyle().getStroke() && this.get('stroke');
        let hasText = this.getStyle().getText();
        let hasBorder = this.getStyle().getFill();

        ctx.save();
        ctx.scale(ratio, ratio);

        ctx.beginPath();
        ctx.rect(margin, margin, w - 2 * margin, h - 2 * margin);
        ctx.clip();

        ctx.beginPath();

        let txt = { top: [], left: [], bottom: [], right: [] };
        let x; let y; let p; let p0; let p1;
        for (x = xmin; x < xmax; x += step) {
            p0 = ol_proj_transform([x, ymin], proj, map.getView().getProjection());
            p0 = map.getPixelFromCoordinate(p0);

            if (hasLines)
            {ctx.moveTo(p0[0], p0[1])};
            p = p0;
            for (y = ymin + calcStep; y <= ymax; y += calcStep) {
                p1 = ol_proj_transform([x, y], proj, map.getView().getProjection());
                p1 = map.getPixelFromCoordinate(p1);
                if (hasLines)
                {ctx.lineTo(p1[0], p1[1])};
                if (p[1] > 0 && p1[1] < 0)
                {
                    // @ts-ignore
                    txt.top.push([x, p]);
                }
                if (p[1] > h && p1[1] < h)
                {
                    // @ts-ignore
                    txt.bottom.push([x, p]);
                }
                p = p1;
            }
        }
        //geopost
        for (y = ymin; y < ymax; y += stepY) {
            //fim geopost
            p0 = ol_proj_transform([xmin, y], proj, map.getView().getProjection());
            p0 = map.getPixelFromCoordinate(p0);
            if (hasLines)
            {ctx.moveTo(p0[0], p0[1])};
            p = p0;
            //geopost
            for (x = xmin + calcStepY; x <= xmax; x += calcStepY) {
                //fim geopost
                p1 = ol_proj_transform([x, y], proj, map.getView().getProjection());
                p1 = map.getPixelFromCoordinate(p1);
                if (hasLines)
                {ctx.lineTo(p1[0], p1[1])};
                if (p[0] < 0 && p1[0] > 0)
                {
                    // @ts-ignore
                    txt.left.push([y, p]);
                }
                if (p[0] < w && p1[0] > w)
                {
                    // @ts-ignore
                    txt.right.push([y, p]);
                }
                p = p1;
            }
        }

        if (hasLines) {
            ctx.strokeStyle = this.getStyle().getStroke().getColor();
            ctx.lineWidth = this.getStyle().getStroke().getWidth();
            ctx.stroke();
        }

        // Draw text
        if (hasText) {
            ctx.fillStyle = this.getStyle().getText().getFill().getColor();
            ctx.strokeStyle = this.getStyle().getText().getStroke().getColor();
            ctx.lineWidth = this.getStyle().getText().getStroke().getWidth();
            ctx.font = this.getStyle().getText().getFont();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'hanging';
            let t; let tf;
            let offset = (hasBorder ? borderWidth : 0) + margin + 2;
            for (let i = 0; t = txt.top[i]; i++)
            {if (!(Math.round(t[0] / this.get('step')) % step2)) {
                // @ts-ignore
                tf = this.formatCoord(t[0], 'top');
                ctx.strokeText(tf, t[1][0], offset);
                ctx.fillText(tf, t[1][0], offset);
            }}
            ctx.textBaseline = 'alphabetic';
            for (let i = 0; t = txt.bottom[i]; i++)
            {if (!(Math.round(t[0] / this.get('step')) % step2)) {
                // @ts-ignore
                tf = this.formatCoord(t[0], 'bottom');
                ctx.strokeText(tf, t[1][0], h - offset);
                ctx.fillText(tf, t[1][0], h - offset);
            }}
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'left';
            for (let i = 0; t = txt.left[i]; i++)
            {if (!(Math.round(t[0] / this.get('step')) % step2)) {
                // @ts-ignore
                tf = this.formatCoord(t[0], 'left');
                ctx.strokeText(tf, offset, t[1][1]);
                ctx.fillText(tf, offset, t[1][1]);
            }}
            ctx.textAlign = 'right';
            for (let i = 0; t = txt.right[i]; i++)
            {if (!(Math.round(t[0] / this.get('step')) % step2)) {
                // @ts-ignore
                tf = this.formatCoord(t[0], 'right');
                ctx.strokeText(tf, w - offset, t[1][1]);
                ctx.fillText(tf, w - offset, t[1][1]);
            }}
        }

        // Draw border
        if (hasBorder) {
            let fillColor = this.getStyle().getFill().getColor();
            let color; let stroke;
            if (stroke = this.getStyle().getStroke()) {
                color = this.getStyle().getStroke().getColor();
            }

            else {
                color = fillColor;
                fillColor = '#fff';
            }

            ctx.strokeStyle = color;
            ctx.lineWidth = stroke ? stroke.getWidth() : 1;
            //
            for (let i = 1; i < txt.top.length; i++) {
                ctx.beginPath();
                ctx.rect(txt.top[i - 1][1][0], margin, txt.top[i][1][0] - txt.top[i - 1][1][0], borderWidth);
                ctx.fillStyle = Math.round(txt.top[i][0] / step) % 2 ? color : fillColor;
                ctx.fill();
                ctx.stroke();
            }
            for (let i = 1; i < txt.bottom.length; i++) {
                ctx.beginPath();
                ctx.rect(txt.bottom[i - 1][1][0], h - borderWidth - margin, txt.bottom[i][1][0] - txt.bottom[i - 1][1][0], borderWidth);
                ctx.fillStyle = Math.round(txt.bottom[i][0] / step) % 2 ? color : fillColor;
                ctx.fill();
                ctx.stroke();
            }
            for (let i = 1; i < txt.left.length; i++) {
                ctx.beginPath();
                ctx.rect(margin, txt.left[i - 1][1][1], borderWidth, txt.left[i][1][1] - txt.left[i - 1][1][1]);
                ctx.fillStyle = Math.round(txt.left[i][0] / step) % 2 ? color : fillColor;
                ctx.fill();
                ctx.stroke();
            }
            for (let i = 1; i < txt.right.length; i++) {
                ctx.beginPath();
                ctx.rect(w - borderWidth - margin, txt.right[i - 1][1][1], borderWidth, txt.right[i][1][1] - txt.right[i - 1][1][1]);
                ctx.fillStyle = Math.round(txt.right[i][0] / step) % 2 ? color : fillColor;
                ctx.fill();
                ctx.stroke();
            }
            ctx.beginPath();
            ctx.fillStyle = color;
            ctx.rect(margin, margin, borderWidth, borderWidth);
            ctx.rect(margin, h - borderWidth - margin, borderWidth, borderWidth);
            ctx.rect(w - borderWidth - margin, margin, borderWidth, borderWidth);
            ctx.rect(w - borderWidth - margin, h - borderWidth - margin, borderWidth, borderWidth);
            ctx.fill();
        }

        ctx.restore();
    }
}