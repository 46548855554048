import { Accordion, AccordionSummary, Box, Typography, css } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const styles = css({
    '& .header': {
        display: 'flex',
        alignItems: 'center'
    },
    '& .btn-add': {
        marginLeft: 4
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
});

export function SeismicInterpretationAccordion({open, handleOpen, label, headerElement, items}:Props) {
    return <Box css={styles}>
        <Accordion disableGutters square expanded={open}>
            <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: 14 }} />} onClick={() => handleOpen(!open)}>
                <Box className='header'>
                    <Typography>{label} ({items?.length ?? 0})</Typography>
                    { headerElement }
                </Box>
            </AccordionSummary>
            { items?.map(x => x) }
        </Accordion>
    </Box>;
}

interface Props {
    open: boolean;
    handleOpen: (value: boolean) => void;
    label: string;
    headerElement?: JSX.Element;
    items?: JSX.Element[];
}