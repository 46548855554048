export enum ApiType {
    SeismicApi = 1,
    WebApi = 2,
    Render = 3,
    LayersApi = 4,
    Geometry = 5,
    LineGeometry = 6,
    Projections = 7,
    Processing = 8,
    FileIndexing = 9
}