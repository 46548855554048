import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Collapse, IconButton, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useGeneralStats } from './hooks/useGeneralStats';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { PerformanceGridToolbar } from './PerformanceGridToolbar';
import { LineGeometryErrorData } from 'features/seismic/models/types/LineGeometryErrorData';
import { usePerformanceMonitoringStore } from 'features/seismic/stores/usePerformanceMonitoringStore';

export type LinesPerformancePanelProps = {
    surveyType: SurveyType,
    linesErrorData: LineGeometryErrorData[];
};

const GeneralGridToolbar = () => (<PerformanceGridToolbar fileName='lines_general_data'/>);
const DetailedGridToolbar = () => (<PerformanceGridToolbar fileName='detailed_general_data'/>);

export const LinesPerformancePanel = ({ surveyType } : LinesPerformancePanelProps) => {
    const [ generalStatsCollapsed, setGeneralStatsCollapsed ] = useState(false);
    const [ detailedStatsCollapsed, setDetailedStatsCollapsed ] = useState(false);

    const linesPerformanceData = usePerformanceMonitoringStore(state => state.lineGeometriesPerformanceData);

    const { min: minRequestNetworkTime, max: maxRequestNetworkTime, average: requestNetworkTimeAverage, standardDeviation: requestNetworkTimeStandardDeviation } = useGeneralStats(linesPerformanceData.map(x => x.requestNetworkTime));

    const { min: minResponseNetworkTime, max: maxResponseNetworkTime, average: responseNetworkTimeAverage, standardDeviation: responseNetworkTimeStandardDeviation } = useGeneralStats(linesPerformanceData.map(x => x.responseNetworkTime));

    const { min: minTotalTime, max: maxTotalTime, average: totalTimeAverage, standardDeviation: totalTimeStandardDeviation } = useGeneralStats(linesPerformanceData.map(x => x.totalTime));

    const { min: minLineQueryTime, max: maxLineQueryTime, average: lineQueryTimeAverage, standardDeviation: lineQueryTimeStandardDeviation } = useGeneralStats(linesPerformanceData.map(x => x.lineQueryTime));

    const { min: minMetadataQueryTime, max: maxMetadataQueryTime, average: metadataQueryTimeAverage, standardDeviation: metadataQueryTimeStandardDeviation } = useGeneralStats(linesPerformanceData.map(x => x.metadataQueryTime));

    const { min: minLineProcessingTime, max: maxLineProcessingTime, average: lineProcessingTimeAverage, standardDeviation: lineProcessingTimeStandardDeviation } = useGeneralStats(linesPerformanceData.map(x => x.lineProcessingTime));

    const { min: minServerProcessingTime, max: maxServerProcessingTime, average: serverProcessingTimeAverage, standardDeviation: serverProcessingTimeStandardDeviation } = useGeneralStats(linesPerformanceData.map(x => x.serverProcessingTime));

    const columns : GridColDef[] = [
        {
            field: 'totalTime',
            headerName: 'Total time lapsed (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'lineQueryTime',
            headerName: 'Line query time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'metadataQueryTime',
            headerName: 'Metadata query time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'serverProcessingTime',
            headerName: 'Server processing time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'requestNetworkTime',
            headerName: 'Request network time (sec.)',
            width: 200,
            type: 'number'
        },
        {
            field: 'responseNetworkTime',
            headerName: 'Response network time (sec.)',
            width: 250,
            type: 'number'
        }
    ];

    const generalGridColumns = [
        {
            field: 'operation',
            headerName: 'Operation',
            width: 150
        },
        ...columns
    ];

    const averageRow = useMemo(() => ({
        id: 0,
        operation: 'Average',
        totalTime: totalTimeAverage.toFixed(3),
        metadataQueryTime: metadataQueryTimeAverage.toFixed(3),
        lineQueryTime: lineQueryTimeAverage.toFixed(3),
        serverProcessingTime: serverProcessingTimeAverage.toFixed(3),
        lineProcessingTime: lineProcessingTimeAverage.toFixed(3),
        requestNetworkTime: requestNetworkTimeAverage.toFixed(3),
        responseNetworkTime: responseNetworkTimeAverage.toFixed(3)
    }), [lineProcessingTimeAverage, lineQueryTimeAverage, metadataQueryTimeAverage, requestNetworkTimeAverage, responseNetworkTimeAverage, serverProcessingTimeAverage, totalTimeAverage]);

    const minRow = useMemo(() => ({
        id: 1,
        operation: 'Min',
        totalTime: minTotalTime.toFixed(3),
        metadataQueryTime: minMetadataQueryTime.toFixed(3),
        lineQueryTime: minLineQueryTime.toFixed(3),
        serverProcessingTime: minServerProcessingTime.toFixed(3),
        lineProcessingTime: minLineProcessingTime.toFixed(3),
        requestNetworkTime: minRequestNetworkTime.toFixed(3),
        responseNetworkTime: minResponseNetworkTime.toFixed(3)
    }), [minLineProcessingTime, minLineQueryTime, minMetadataQueryTime, minRequestNetworkTime, minResponseNetworkTime, minServerProcessingTime, minTotalTime]);

    const maxRow = useMemo(() => ({
        id: 2,
        operation: 'Max',
        totalTime: maxTotalTime.toFixed(3),
        metadataQueryTime: maxMetadataQueryTime.toFixed(3),
        lineQueryTime: maxLineQueryTime.toFixed(3),
        serverProcessingTime: maxServerProcessingTime.toFixed(3),
        lineProcessingTime: maxLineProcessingTime.toFixed(3),
        requestNetworkTime: maxRequestNetworkTime.toFixed(3),
        responseNetworkTime: maxResponseNetworkTime.toFixed(3)
    }), [maxLineProcessingTime, maxLineQueryTime, maxMetadataQueryTime, maxRequestNetworkTime, maxResponseNetworkTime, maxServerProcessingTime, maxTotalTime]);

    const standardDeviationRow = useMemo(() => ({
        id: 3,
        operation: 'Standard Deviation',
        totalTime: totalTimeStandardDeviation.toFixed(3),
        metadataQueryTime: metadataQueryTimeStandardDeviation.toFixed(3),
        lineQueryTime: lineQueryTimeStandardDeviation.toFixed(3),
        serverProcessingTime: serverProcessingTimeStandardDeviation.toFixed(3),
        lineProcessingTime: lineProcessingTimeStandardDeviation.toFixed(3),
        requestNetworkTime: requestNetworkTimeStandardDeviation.toFixed(3),
        responseNetworkTime: responseNetworkTimeStandardDeviation.toFixed(3)
    }), [lineProcessingTimeStandardDeviation, lineQueryTimeStandardDeviation, metadataQueryTimeStandardDeviation, requestNetworkTimeStandardDeviation, responseNetworkTimeStandardDeviation, serverProcessingTimeStandardDeviation, totalTimeStandardDeviation]);

    const generalGridRows = useMemo(() => [
        averageRow,
        maxRow,
        minRow,
        standardDeviationRow,
    ], [averageRow, maxRow, minRow, standardDeviationRow]);

    const detailedGridColumns = [
        ...columns
    ];

    const detailedGridColumns3d = [
        {
            field: 'lineNumber',
            headerName: 'Line number',
            width: 150
        },
        {
            field: 'lineType',
            headerName: 'Lin e type',
            width: 150
        },
    ];

    const detailedGridRows = useMemo(() => linesPerformanceData.map(linePerformanceData => ({
        id: linePerformanceData.lineNumber + linePerformanceData.lineType,
        lineNumber: linePerformanceData.lineNumber,
        lineType: linePerformanceData.lineType,
        totalTime: linePerformanceData.totalTime.toFixed(3),
        metadataQueryTime: linePerformanceData.metadataQueryTime.toFixed(3),
        lineQueryTime: linePerformanceData.lineQueryTime.toFixed(3),
        serverProcessingTime: linePerformanceData.serverProcessingTime.toFixed(3),
        lineProcessingTime: linePerformanceData.lineProcessingTime.toFixed(3),
        requestNetworkTime: linePerformanceData.requestNetworkTime.toFixed(3),
        responseNetworkTime: linePerformanceData.responseNetworkTime.toFixed(3)
    })), [linesPerformanceData]);

    return (
        <Stack spacing={2}>
            <Stack>
                <Stack direction='row' sx={{ borderBottom: '1px solid #cccccc' }} alignItems={'center'} justifyContent={'center'}>
                    <Typography fontSize='20px'>General</Typography>
                    <IconButton onClick={() => setGeneralStatsCollapsed(!generalStatsCollapsed)}>
                        {
                            generalStatsCollapsed ?
                                <ArrowDropUp/>
                                :
                                <ArrowDropDown/>
                        }
                    </IconButton>
                </Stack>
                <Collapse in={generalStatsCollapsed}>
                    <DataGrid
                        columns={generalGridColumns}
                        rows={generalGridRows}
                        slots={{toolbar: GeneralGridToolbar}}
                    />
                </Collapse>
            </Stack>
            <Stack>
                <Stack direction='row' sx={{ borderBottom: '1px solid #cccccc' }} alignItems={'center'} justifyContent={'center'}>
                    <Typography fontSize='20px'>Detailed</Typography>
                    <IconButton onClick={() => setDetailedStatsCollapsed(!detailedStatsCollapsed)}>
                        {
                            detailedStatsCollapsed ?
                                <ArrowDropUp/>
                                :
                                <ArrowDropDown/>
                        }
                    </IconButton>
                </Stack>
                <Collapse in={detailedStatsCollapsed}>
                    <DataGrid
                        columns={surveyType === SurveyType.Seismic2D ? detailedGridColumns : detailedGridColumns3d}
                        rows={detailedGridRows}
                        slots={{toolbar: DetailedGridToolbar}}
                    />
                </Collapse>
            </Stack>
        </Stack>
    );

};