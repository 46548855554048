import { useQuery } from 'react-query';

import { axios } from 'configurations/axios';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { IInstitution } from './interfaces/IInstitution';
import { useSessionStore } from 'session/useSessionStore';

export function useGetAllInstitutions() {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllInsitutions', async () => {
        const response = await axios.request<IResponseAPI<IInstitution[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Transmittals/GetAllInstitution',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    },{
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useGetInstitutionByToken(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getInstitutionByToken' + token, async () => {
        const response = await axios.request<IResponseAPI<IInstitution>>({
            method: 'get',
            params: {
                token,
            },
            url: tenantConfig?.endpoints.fileIndexing + '/Transmittals/GetInstitutionByToken',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            }
        });

        return response.data.Result;
    },{
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useSaveInstitution(data: IInstitution | null) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveInstitution' + data, async () => {
        const response = await axios.request<IResponseAPI<number>>({
            method: 'post',
            data: data,
            url: tenantConfig?.endpoints.fileIndexing + '/Transmittals/SaveInstitution',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            }
        });

        return response.data.Result;
    },{
        refetchOnWindowFocus: false,
        enabled: data !== null
    });
}

export function useDeleteInstitution(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteInstitution' + token, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            params: {
                token: token
            },
            url: tenantConfig?.endpoints.fileIndexing + '/Transmittals/DeleteInstitution',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            }
        });

        return response.data.Result;
    },{
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
    });
}