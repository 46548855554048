import { css } from '@emotion/react';
import { Box, Button, Grid } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useSessionStore } from 'session/useSessionStore';

export function NavigationMenu() {
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }));

    const primaryColor = tenantConfig ? tenantConfig?.main.palette.primary.main : '';
    const primaryTextColor = tenantConfig ? tenantConfig?.main.palette.primary.contrastText : '';
    const secondaryColor = tenantConfig ? tenantConfig?.main.palette.secondary.main : '';
    const secondaryTextColor = tenantConfig ? tenantConfig?.main.palette.secondary.contrastText : '';

    const location = useLocation();

    const style = css({
        width: '100%',
        margin: '0rem auto 0 auto',
        padding: '1rem 0px',
        background: '#eeeeee',
        '& .menu-buttons': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '32px',
            fontSize: '12.5px',
            color: 'white',
            letterSpacing: '0.05em',
            fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
            borderRadius: '3px',
            boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12)',
        },
        '& .router-link': {
            textDecoration: 'none',
            cursor: 'pointer',
        },
        '& .router-link-active div': {
            backgroundColor: `${secondaryColor} !important`,
            color: primaryColor,
            fontSize: '12.5px',
            fontWeight: 'bold',
        }
    });

    return (
        <Fragment>
            <Box css={style}>
                <Grid spacing={2} container direction='row' paddingLeft={'16px'} paddingRight={'16px'}>
                    <Grid xs={1.7} item>
                        <Button
                            component={Link}
                            to={`${window.location.href.indexOf('app-viewer') > -1 ? '/app-viewer' : ''}/FileIndexing/institutions?tenantToken=${tenantConfig?.tenantToken}`}
                            fullWidth
                            variant='contained'
                            color={location.pathname === '/app-viewer/FileIndexing/institutions' || location.pathname === '/FileIndexing/institutions' ? 'secondary' : 'primary'}
                        >
                            INSTITUTIONS
                        </Button>
                    </Grid>
                    <Grid xs={1.7} item>
                        <Button
                            component={Link}
                            to={`${window.location.href.indexOf('app-viewer') > -1 ? '/app-viewer' : ''}/FileIndexing/contracts?tenantToken=${tenantConfig?.tenantToken}`}
                            fullWidth
                            variant='contained'
                            color={location.pathname === '/app-viewer/FileIndexing/contracts' || location.pathname === '/FileIndexing/contracts' ? 'secondary' : 'primary'}
                        >
                            CONTRACTS
                        </Button>
                    </Grid>
                    <Grid xs={1.7} item>
                        <Button
                            component={Link}
                            to={`${window.location.href.indexOf('app-viewer') > -1 ? '/app-viewer' : ''}/FileIndexing/dataSources?tenantToken=${tenantConfig?.tenantToken}`}
                            fullWidth
                            variant='contained'
                            color={location.pathname === '/app-viewer/FileIndexing/dataSources' || location.pathname === '/FileIndexing/dataSources' ? 'secondary' : 'primary'}
                        >
                            DATA SOURCES
                        </Button>
                    </Grid>
                    <Grid xs={1.7} item>
                        <Button
                            component={Link}
                            to={`${window.location.href.indexOf('app-viewer') > -1 ? '/app-viewer' : ''}/FileIndexing/taxonomy?tenantToken=${tenantConfig?.tenantToken}`}
                            fullWidth
                            variant='contained'
                            color={location.pathname === '/app-viewer/FileIndexing/taxonomy' || location.pathname === '/FileIndexing/taxonomy' ? 'secondary' : 'primary'}
                        >
                            TAXONOMIES
                        </Button>
                    </Grid>
                    <Grid xs={1.7} item>
                        <Button
                            component={Link}
                            to={`${window.location.href.indexOf('app-viewer') > -1 ? '/app-viewer' : ''}/FileIndexing/tags?tenantToken=${tenantConfig?.tenantToken}`}
                            fullWidth
                            variant='contained'
                            color={location.pathname === '/app-viewer/FileIndexing/tags' || location.pathname === '/FileIndexing/tags' ? 'secondary' : 'primary'}
                        >
                            TAGS
                        </Button>
                    </Grid>
                    <Grid xs={1.7} item>
                        <Button
                            component={Link}
                            to={`${window.location.href.indexOf('app-viewer') > -1 ? '/app-viewer' : ''}/FileIndexing/entities?tenantToken=${tenantConfig?.tenantToken}`}
                            fullWidth
                            variant='contained'
                            color={location.pathname === '/app-viewer/FileIndexing/entities' || location.pathname === '/FileIndexing/entities' ? 'secondary' : 'primary'}
                        >
                            ENTITIES
                        </Button>
                    </Grid>
                    <Grid xs={1.7} item>
                        <Button
                            component={Link}
                            to={`${window.location.href.indexOf('app-viewer') > -1 ? '/app-viewer' : ''}/FileIndexing/files?tenantToken=${tenantConfig?.tenantToken}`}
                            fullWidth
                            variant='contained'
                            color={location.pathname === '/app-viewer/FileIndexing/files' || location.pathname === '/FileIndexing/files' ? 'secondary' : 'primary'}
                        >
                            FILES
                        </Button>
                    </Grid>
                    {/* <Grid xs={1.09} item>
                        <Button disabled
                        component={Link}
                            to={`/fileIndexing/instituitions?tenantToken=${tenantConfig?.tenantToken}`} fullWidth variant='contained' color={location.pathname === '/fileIndexing/medias' ? 'secondary' : 'primary'}>

                            MEDIAS

                            </Button>
                    </
                    Grid> */}
                    {/* <Grid xs={1.09} item>
                        <Button disabled
                        componen
                        t={Link}
                            to={`/fileIndexing/instituitions?tenantToken=${tenantConfig?.tenantToken}`} fullWidth variant='contained' color={location.pathname === '/fileIndexing/transmittals' ? 'secondary' : 'primary'}>

                            TRANSMITTALS

                            </Button>
                    </
                    Grid> */}
                    {/* <Grid xs={1.09} item>
                        <Button disabled
                        componen
                        t={Link}
                            to={`/fileIndexing/instituitions?tenantToken=${tenantConfig?.tenantToken}`} fullWidth variant='contained' color={location.pathname === '/fileIndexing/datareceive' ? 'secondary' : 'primary
                            '}>

                            DATA RECEIVE
                        </Button
                        >
                    </Grid> */}
                    {/* <Grid xs={1.09} item>
                        <Butto
                        n disabled
                        component={Link}
                            to={`/fileIndexing/instituitions?tenantToken=${tenantConfig?.tenantToken}`} fullWidth variant='contained' color={location.pathname === '/fileIndexing/users' ? 'secondary' : 'primary'}>

                            USERS

                            </Button>
                    </
                    Grid> */}

                </Grid>
            </Box>
        </Fragment>
    );

}