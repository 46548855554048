import { useQuery } from 'react-query';
import { GridSortModel } from '@mui/x-data-grid';

import { axios } from 'configurations/axios';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { IEntitiesAutocomplete, IEntitiesSeismic, IEntitiesTable, IEntitiesWell } from './interfaces/IEntities';
import { useSessionStore } from 'session/useSessionStore';

export function useGetCountTable(name: string, groupToken: string, subGroupToken: string/* , type: string */) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getCountTable' + name + groupToken + subGroupToken, async () => {
        const response = await axios.request<IResponseAPI<number>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Entities/GetCountTable',
            params: {
                name,
                groupToken,
                subGroupToken/* ,
                type */
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useGetByTerm(name: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getByTerm' + name, async () => {
        const response = await axios.request<IResponseAPI<IEntitiesAutocomplete[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Entities/GetByTerm',
            params: {
                name: name,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useGetListTable(name: string, groupToken: string, subGroupToken: string, type: string, limit: number, page: number, sort: GridSortModel) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    let sortField = sort[0].field;
    let descending = sort[0].sort === 'desc' ? true : false;

    if (sortField === 'ANPName') {
        sortField = 'ANPName';
    }
    else if (sortField === 'SubGroups') {
        sortField = 'SubGroups';
    }
    else if (sortField === 'EntityType') {
        sortField = 'EntityType';
    }
    else {
        sortField = 'Name';
    }

    return useQuery('getListTable' + name + groupToken + subGroupToken + type + limit + page + sortField + descending, async () => {
        const response = await axios.request<IResponseAPI<IEntitiesTable[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Entities/GetListTable',
            params: {
                name,
                groupToken,
                subGroupToken,
                limit,
                page,
                sortField,
                descending,
                // type
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
    });
}

export function useDeleteSeismicSurvey(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteSeismicSurvey' + token, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/Entities/DeleteSeismicSurvey',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
    });
}

export function useSaveWell(data: IEntitiesWell | null, files: File[]) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));
    return useQuery('saveWell' + data?.Token + data?.Name + data?.UWI + data?.ANPName + data?.NickName + data?.Classification + data?.OperatorName + data?.StartDrillingDate + data?.EndDrillingDate + data?.BasinName + files, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/Entities/SaveWell',
            data: {
                Token: data?.Token,
                Name: data?.Name,
                UWI: data?.UWI,
                ANPName: data?.ANPName,
                NickName: data?.NickName,
                Classification: data?.Classification,
                OperatorName: data?.OperatorName,
                StartDrillingDate: data?.StartDrillingDate,
                EndDrillingDate: data?.EndDrillingDate,
                Files: files,
                BasinName: data?.BasinName,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: data !== null,
    });
}

export function useSaveSeismic(data: IEntitiesSeismic | null, files: File[]) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveSeismic' + data?.Token + data?.Name  + data?.ANPName  + data?.NickName  + data?.Classification  + data?.OperatorName  + data?.BasinName  + data?.SurveyType  + data?.OrderDate  + data?.Contract  + data?.Responsable  + data?.SAPCode  + data?.ProductName  + data?.InstitutionToken, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/Entities/SaveSeismic',
            data: {
                Token: data?.Token,
                Name: data?.Name,
                ANPName: data?.ANPName,
                NickName: data?.NickName,
                Classification: data?.Classification,
                OperatorName: data?.OperatorName,
                BasinName: data?.BasinName,
                SurveyType: data?.SurveyType,
                OrderDate: data?.OrderDate,
                Contract: data?.Contract,
                Responsable: data?.Responsable,
                SAPCode: data?.SAPCode,
                ProductName: data?.ProductName,
                InstitutionToken: data?.InstitutionToken,
                Files: files
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: data !== null,
    });
}

export function useGetWellByToken(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getWellByToken' + token, async () => {
        const response = await axios.request<IResponseAPI<IEntitiesWell>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Entities/GetWellByToken',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useGetSeismicSurveyByToken(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getSeismicSurveyByToken' + token, async () => {
        const response = await axios.request<IResponseAPI<IEntitiesSeismic>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Entities/GetSeismicSurveyByToken',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}