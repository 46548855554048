import { Typography, css } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Map from 'ol/Map';
import {defaults as defaultControls} from 'ol/control';
import ImageLayer from 'ol/layer/Image';

import { useSessionStore } from 'session/useSessionStore';
import { LoadingBlock } from 'components/LoadingBlock';
import { useCrossSectionStore } from '../stores/useCrossSectionStore';
import { useUpdatedSizeMap } from './WellSectionLane/hooks/useUpdatedSizeMap';
import { buildExtent, buildStaticSource } from '../utils/wellLaneMapUtils';
import { IWellGenericCut } from '../models/interfaces/IWellGenericCut';
import { ICrossSection } from '../models/interfaces/ICrossSection';
import { HEIGHT_HEADER_LANE, HEIGHT_HEADER_TITLE_LANE } from '../consts/layotsConsts';

const style = css({
    marginRight: '0px',
    borderStyle:'solid',
    borderWidth: 1,
    paddingLeft: 0,
    borderColor: 'rgb(219 225 231)',
    borderRadius: 3,
    paddingTop: 10,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'column',

    '& .well-container': {
        position: 'relative',
        flexGrow: 1,
    },
    '& .well-name-header':{
        height: HEIGHT_HEADER_LANE
    },
    '& .well-lanes':{
        display: 'flex',
        flexDirection: 'row',
    },
    '& .well-name': {
        fontSize:'18px',
        width: '100%',
        backgroundColor: 'rgb(4, 31, 60)',
        color: 'white',
        borderRadius: 4,
        textAlign: 'center',
        height: HEIGHT_HEADER_TITLE_LANE
    },
    '& .well-info': {
        fontSize:'16px',
        padding: '3px',
    },
    '& .mapa': {
        height: '100%',
    },
    '& .label-depth': {
        fontSize: 14,
        marginTop:3,
        marginRight:1,
    },'& .close-lane':{
        float: 'right',
        height: '100%'
    },'& .makeStyles-root-1':{
        width:'100%'
    }
});

export function WellDepthTrack({crossSection}:IProps){
    const [map, setMap] = useState<Map | null>(null);
    const containerMap = useRef<HTMLDivElement>(null);
    const [selectedGenericCut, setSelectedGenericCut] = useState<IWellGenericCut | null>(null);
    const [isLayerLoading, setIsLayerLoading] = useState(false);
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    const currentMap = useRef<Map | null>(null);

    const { view, heightMap, setFactorVertical, depthRange, setDepthRange, dataDepthRange, factorVertical, setDataDepthRange, laneWidth,
        displacementVertical,jwtToken,setCrossSectionData } = useCrossSectionStore(state => ({
        displacementVertical:state.displacementVertical,
        view: state.view,
        depthRange: state.depthRange,
        setDepthRange: state.setDepthRange,
        setDataDepthRange: state.setDataDepthRange,
        dataDepthRange: state.dataDepthRange,
        laneWidth: state.laneWidth,
        factorVertical: state.factorVertical,
        heightMap: state.heightMap,
        setFactorVertical: state.setFactorVertical,
        jwtToken: state.jwtToken,
        setCrossSectionData:state.setCrossSectionData
    }));
    const depthTrackWidth = 100;
    useUpdatedSizeMap(containerMap, map);
    useEffect(() => {
        console.log(crossSection);
        setSelectedGenericCut({
            CompositeProfileToken:'DepthTrack',
            GenericCutName:'Depth',
            GenericCutPathToken:'DepthTrack',
            GenericCutToken:'DepthTrack',
            GenericCutType:'log',
            WellStartDepth:0,
            WellEndDepth:0,
            StartDepth:crossSection.WellStart,
            EndDepth:crossSection.WellEnd,
            IsDefault:false,
            PixelPerMeter:1,
            Width:laneWidth,
            Height:1000,
            WellId:0,
            LogicalFile:'',
            Frame:'',
            Unit:''
        });
        if (selectedGenericCut){

            const extent = buildExtent(depthTrackWidth, selectedGenericCut, factorVertical);
            extent[0] = laneWidth/2 - depthTrackWidth/2;
            extent[2] = laneWidth/2 + depthTrackWidth/2;
            const url = `${tenantConfig?.endpoints.wellRender}/well/GetDepthTrack?width=${depthTrackWidth}&height=${(extent[1] * -1 - extent[3] * -1) }&start_depth=${selectedGenericCut?.StartDepth}&end_depth=${selectedGenericCut?.EndDepth}&token=${jwtToken}&scale_type=0`;
            const depthTrackLayer = new ImageLayer({
                visible: true,
                source: buildStaticSource(selectedGenericCut, extent, setIsLayerLoading,url,depthTrackWidth),
            });

            if (!currentMap.current){

                const newMap = new Map({
                    controls: defaultControls({attribution: false, zoom:false}),
                    target: `mapDepthTrack`
                });
                currentMap.current = newMap;
                setMap(map);
                newMap.addLayer(depthTrackLayer);
            } else {
                currentMap.current.getLayers().removeAt(0);
                currentMap.current.getLayers().insertAt(0, depthTrackLayer);
            }

            if (view && currentMap.current){
                currentMap.current.setView(view);
            }
        }
    }, [selectedGenericCut?.GenericCutToken,laneWidth,factorVertical]);
    useEffect(() => {
        if (view && currentMap.current){
            currentMap.current.setView(view);
        }
    }, [view, currentMap]);
    const depthLabel = `${crossSection.WellStart}${crossSection.IndexUnit} | ${crossSection.WellEnd}${crossSection.IndexUnit}`;
    return (
        <div css={style} key={selectedGenericCut?.GenericCutName} style={{width: depthTrackWidth}}>
            <div className='well-name-header'>
                <div className='well-name'>
                    <span>Depth</span>
                    {/* <button className='close-lane' onClick={closeLane}>X</button> */}
                </div>
                <div className='well-info'>
                    <span>
                        <Typography noWrap className='label-depth'>
                            <strong className='label'>Unit:</strong>
                            {crossSection.IndexUnit?.toLowerCase() === 'm' ? 'Meters' : crossSection.IndexUnit?.toLowerCase() === 'f' ? 'Feet' : crossSection.IndexUnit}
                        </Typography>
                        <Typography noWrap className='label-depth'>
                            <strong className='label'>Start:</strong>
                            {parseInt(crossSection.WellStart.toString())}
                        </Typography>
                        <Typography noWrap className='label-depth'>
                            <strong className='label'>End:</strong>
                            {crossSection.WellEnd.toFixed(0)}
                        </Typography>

                    </span>
                </div>

            </div>
            <div className='well-container'>
                <LoadingBlock open={isLayerLoading}/>

                <div id={`mapDepthTrack`} className='mapa' style={{width: depthTrackWidth}} ref={containerMap}></div>
            </div>
        </div>
    );
}
interface IProps {
    crossSection: ICrossSection,
}