import { Box, Checkbox, CircularProgress, TextField, css } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { IBasicModel } from '../models/interfaces/IBasicModel';
import { useGetInfo } from '../api/usePermissionController';
import { PermissionType } from 'features/seismic/models/enums/PermissionType';

const styles = css({
    '& .search': {
        marginTop: 10,
    },
    '& .item': {
        display: 'flex',
        cursor: 'pointer',
        borderBottom: '1px solid #ccc',

        '&. check': {
            width: 45,
            ackground: 'blue'
        },
        '& .name': {
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1
        }
    }
});

export function PermissionTable({permission, onChange, tokens}:Props){
    const { t } = useTranslation('common');

    const { data, isLoading } = useGetInfo(permission);

    const [ filtered, setFiltered ] = useState<IBasicModel[] | null>(null);

    const debounceFilter = useDebouncedCallback ((term: string)=> {
        let filtered: IBasicModel[] | null = null;

        if (data && term && term.trim()){
            filtered = data.filter(item => item.Name.toLowerCase().indexOf(term.trim()) > -1);
        }

        setFiltered(filtered);
    }, 250);

    useEffect(() => {
        setFiltered(null);
    }, [permission]);

    const handleChange = (token: string) => {
        const index = tokens.findIndex(x => x === token);

        let newTokens:string[] = [];

        if (index > -1){
            newTokens = tokens.filter(x => x !== token);
        }
        else {
            newTokens = [...tokens, token];
        }

        onChange(newTokens);
    };

    if (!data && !isLoading){
        return null;
    }

    if (isLoading){
        return <Box justifyContent='center' sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 7 }}>
            <CircularProgress />
        </Box>;
    }

    const itemsToShow = (filtered)?filtered:data;

    const dictionaryTokens:{[token: string]: boolean} = {};

    for (let token of tokens){
        dictionaryTokens[token] = true;
    }

    return <Box css={styles}>
        <Box>
            <TextField
                fullWidth
                label={t('search')}
                size='small'
                className='search'
                onChange={evt => debounceFilter(evt.target.value)}
                disabled={!data || data.length === 0} />
        </Box>
        <Box>
            {Object.keys(tokens).length} {t('permission.selected')}
        </Box>
        <Box>
            <Box className='item'>
                <Box className='check'></Box>
                <Box className='name'> { t('name')} </Box>
            </Box>
            { itemsToShow?.map(item => (
                <Box key={item.Token} className='item' onClick={() => handleChange(item.Token)}>
                    <Box className='check'>
                        <Checkbox checked={dictionaryTokens[item.Token] || false}/>
                    </Box>
                    <Box className='name'>
                        { item.Name }
                    </Box>
                </Box>))}
        </Box>
    </Box>;
}

interface Props {
    permission: PermissionType;
    onChange: (tokens: string[]) => void;
    tokens: string[]
}