import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import mergeImages from 'merge-images';
import { Alert, AlertTitle, Box, Collapse } from '@mui/material';

import { useSessionStore } from 'session/useSessionStore';
import { useGetLineGeometries, useGetLineGeometry, useGetTile, useGetTiles } from '../api/useSeismic3DInfoController';
import { getFirstAndLastCoordinatesFromGeomWkt } from './projections/featureGeomPolygon';
import { LineType } from '../models/enums/LineType';
import {  IGetLineGeometryRequest } from '../models/interfaces/requests/IGetLineGeometryRequest';
import { lineMetadata } from '../models/types/LineMetadata';
import { Metadata } from '../models/types/Metadata';
import { Metadata3D } from '../models/types/Metadata3D';
import { TileRequest } from '../models/types/TileRequest';
import { GeopostScene } from '../threejs/scene/GeopostScene';
import { VolumeType } from '../models/enums/VolumeType';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { GeopostThreeJsConstants } from '../threejs/utils/GeopostThreeJsConstants';
import { DimensionIndex } from './Dimension3D';
import { LineTileRequest } from '../models/types/LineTileRequest';
import { getUrlParams } from '../utils/Seismic3DUrlUtils';
import { CacheSystem } from 'features/seismic/models/enums/CacheSystem';
import { LineStringResponse } from '../models/types/LineStringResponse';
import { Dimension3D } from './Dimension3D';
export type Use3DLinesProps = {
    lineNumbersToLoad: number[];
    seismicMetadata: Metadata3D;
    lineType: LineType;
    srid: number;
    scene: GeopostScene;
    searchedLineNumber: number | null;
    lineLoadingStep: number,
    setSelectedLineNumber: (value: number) => void,
    setIsPriorityLineLoading: (isLoading: boolean) => void,
    onLoadedLineNumbersChange: (loadedLineNumbers: number[]) => void,
    onTotalImageLoadedLinesChange: (total: number) => void,
    onLoadingLineError: (lineNumbers: number[]) => void,
    isVisible: boolean
};

export const Lines3D2 = ({
    lineNumbersToLoad,
    seismicMetadata,
    lineType,
    srid,
    scene,
    searchedLineNumber,
    setSelectedLineNumber,
    lineLoadingStep,
    setIsPriorityLineLoading,
    onLoadedLineNumbersChange,
    onTotalImageLoadedLinesChange,
    onLoadingLineError,
    isVisible
} : Use3DLinesProps) => {
    const [ loadedLineNumbers, setLoadedLineNumbers ] = useState<number[]>([]);
    const [ totalImageLoadedLines, setTotalImageLoadedLines ] = useState<number>(0);

    const seismic3DDefaults = useSessionStore(state => state.tenantConfiguration?.viewer3DDefaults);

    const seismic3DInfo = seismicMetadata.Survey3DInfo;
    const seismicHeader = seismicMetadata.Header;

    const lineIncrement = useMemo(() => lineType === LineType.Inline ? seismic3DInfo?.XlineIncrement : seismic3DInfo?.InlineIncrement, [lineType, seismic3DInfo]);

    useEffect(() => console.log('BBBBBBBBBBBBBBBBBBBBBBB'), []);

    const [ volumeSamplesPerTrace, sampleInterval, byteSize ] = [
        seismicHeader?.SamplesPerTrace ?? 0,
        seismicHeader?.SampleInterval ?? 0,
        seismicHeader?.ByteSize ?? 0
    ];

    const [ seismicVolumeToken, seismicVolumePath ] = [ seismicMetadata.VolumeToken ?? '', seismicMetadata.VolumePath ?? '' ];
    const [ seismicMaxSampleValue, seismicMinSampleValue ] = [ seismicMetadata.MaxSampleValue ?? 0, seismicMetadata.MinSampleValue ?? 0 ];

    const [ currentLineLoadingImageIndexes, setCurrentLineLoadingImageIndexes ] = useState<number[]>([]);
    const [ linesToLoadImages, setLinesToLoadImages ] = useState<lineMetadata[]>([]);

    const [ totalLines, setTotalLines ] = useState<lineMetadata[]>([]);

    const [ tileRequests, setTileRequests ] = useState<LineTileRequest[]>([]);
    const fetchedTileResults = useGetTiles(tileRequests);

    const [ priorityTileRequests, setPriorityTileRequests ] = useState<LineTileRequest[]>([]);
    const priorityTileResults = useGetTiles(priorityTileRequests);

    const [ lineGeometryRequests, setLineGeometryRequests ] = useState<IGetLineGeometryRequest[]>([]);

    const [ priorityLineNumber, setPriorityLineNumber ] = useState<number>();

    const [ priorityLineGeometryRequest, setPriorityLineGeometryRequest ] = useState<IGetLineGeometryRequest | undefined>();

    const [ errorLineNumbers, setErrorLineNumbers ] = useState<number[]>([]);

    const dimensionNames = useMemo(() => seismicMetadata.DimensionNames, [seismicMetadata]);

    useEffect(() => {
        if (priorityLineNumber) {
            setIsPriorityLineLoading(true);
            setPriorityLineGeometryRequest(({
                lineNumber: priorityLineNumber,
                lineType: lineType,
                srid: srid,
                volumeToken: seismicVolumeToken,
                volumeType: VolumeType.Seismics3D,
                dimensionName: lineType === LineType.Inline ? dimensionNames.Inline : dimensionNames.Xline,
            }));
            priorityLoadedLineNumbersRef.current.push(priorityLineNumber);
        }
    }, [priorityLineNumber]);

    const { data: priorityLineGeometry } = useGetLineGeometry(priorityLineGeometryRequest);

    const lineGeometryResults = useGetLineGeometries(lineGeometryRequests);

    const tileWidth = useMemo(() => 600/*seismic3DDefaults?.tile_width ? parseInt(seismic3DDefaults.tile_width) : GeopostThreeJsConstants.xDivisionFactor*/, [seismic3DDefaults]);

    const updatedLinesRef = useRef<number[]>([]);
    const totalOfLinesToLoadRef = useRef<number>(0);
    const totalOfTilesToLoadRef = useRef<number>(0);
    const [ tilesLoadedTotal, setTilesLoadedTotal ] = useState<number>(0);
    const tilesLoadedForCurrentLineRef = useRef<number[]>([]);
    const priorityLoadedLineNumbersRef = useRef<number[]>([]);
    const previousSelectedLineRelativeNumberRef = useRef<number>(searchedLineNumber ?? 0);

    const { mutateAsync: getTile } = useGetTile(SurveyType.Seismic3D);

    const startLoadingLines = (lineNumbers : number[]) => {
        if (!dimensionNames) {
            return;
        }
        const newLineGeometryRequests : IGetLineGeometryRequest[] = lineNumbers.map(lineNumber => ({
            lineNumber: lineNumber,
            lineType: lineType,
            srid: srid,
            volumeToken: seismicVolumeToken,
            volumeType: VolumeType.Seismics3D,
            dimensionName: lineType === LineType.Inline ? dimensionNames.Inline : dimensionNames.Xline,
        }));
        setLineGeometryRequests(newLineGeometryRequests);
        setLinesToLoadImages([]);
        setErrorLineNumbers([]);
    };

    const tracesXPixelsFactor = useMemo(() => {
        const totalTraces = lineType === LineType.Inline ? seismic3DInfo.TotalXlines : seismic3DInfo.TotalInlines;
        const divisionFactor = 6000;
        const factor = Math.round(totalTraces / divisionFactor);
        return factor > 0 ? factor : 1;
    }, [lineType, seismic3DInfo.TotalInlines, seismic3DInfo.TotalXlines]);

    const tracesTileWidth = useMemo(() => tileWidth * tracesXPixelsFactor, [tracesXPixelsFactor, tileWidth]);

    const addLineTiles = useCallback((line : lineMetadata) => {
        scene.addLineTiles(line, volumeSamplesPerTrace, tracesTileWidth);
    }, [scene, tracesTileWidth, volumeSamplesPerTrace]);

    useEffect(() => {
        setLineGeometryRequests([]);
        setLinesToLoadImages([]);
        setLoadedLineNumbers([]);
        setTotalImageLoadedLines(0);
        setTileRequests([]);
        setTilesLoadedTotal(0);
    }, [seismic3DInfo]);

    useEffect(() => {
        const newLineNumbersToLoad = lineNumbersToLoad.filter(lineNumber => !loadedLineNumbers.includes(lineNumber) && !priorityLoadedLineNumbersRef.current.includes(lineNumber));
        startLoadingLines(newLineNumbersToLoad);
    }, [lineNumbersToLoad]);

    useEffect(() => {
        let linesToAdd : lineMetadata[] = [];
        const newErrorLineNumbers: number[] = [];
        lineGeometryResults.forEach((lineGeometryResult, index) => {
            const relatedLineRequest = lineGeometryRequests[index];
            if (!lineGeometryResult) {
                return;
            }
            else if (!lineGeometryResult.Line || lineGeometryResult.Line === 'EMPTY') {
                if (!errorLineNumbers.includes(relatedLineRequest.lineNumber)) {
                    setErrorLineNumbers(current => [...current, relatedLineRequest.lineNumber]);
                    newErrorLineNumbers.push(relatedLineRequest.lineNumber);
                }
            } else if (linesToLoadImages.findIndex(line => line.lineNumber === relatedLineRequest.lineNumber) < 0) {
                linesToAdd.push({
                    lineNumber: relatedLineRequest.lineNumber,
                    lineType: lineType,
                    geometryMetadata: lineGeometryResult
                });
            }
        });
        if (linesToAdd.length > 0) {
            setLinesToLoadImages(current => {
                const newArray = [...current, ...linesToAdd];
                return newArray;
            });
        }
        if (newErrorLineNumbers.length > 0) {
            onLoadingLineError(newErrorLineNumbers);
        }
    }, [lineGeometryResults]);

    const getTotalOfTracesFromLine = useCallback((lineData: LineStringResponse) => {
        return (lineData.Max - lineData.Min) / lineIncrement;
    }, [lineIncrement]);

    const [ showSearchedLineError, setShowSearchedLineError ] = useState(false);

    useEffect(() => {
        if (!seismic3DInfo) {
            return;
        }
        //console.log(linesToLoadImages, errorLineNumbers, lineGeometryRequests);
        if (linesToLoadImages.length + errorLineNumbers.length >= lineGeometryRequests.length && linesToLoadImages.length > 0 && lineGeometryRequests.length > 0) {
            scene.addGridSection(linesToLoadImages, seismicVolumeToken);
            console.log('========> Add grid Section <=============', linesToLoadImages);
            linesToLoadImages.forEach(line => {
                addLineTiles(line);
            });
            const firstIndexesToLoad = [];
            for (let i = 0; i < lineLoadingStep; i++) {
                firstIndexesToLoad.push(i);
            }
            setCurrentLineLoadingImageIndexes(firstIndexesToLoad);
            setLoadedLineNumbers(current => {
                const newArray = [...current, ...linesToLoadImages.map(x => x.lineNumber), ...priorityLoadedLineNumbersRef.current];
                newArray.sort((a, b) => a - b);
                return newArray;
            });
            const newDimensionIndexes = linesToLoadImages.map(line => ({ indexNumber: line.lineNumber, totalX: getTotalOfTracesFromLine(line.geometryMetadata), totalY: volumeSamplesPerTrace }));
            setDimensionIndexesToLoad(current => [...current, ...newDimensionIndexes]);
        }
    }, [linesToLoadImages, errorLineNumbers]);

    useEffect(() => {
        if (!!priorityLineGeometry && !!priorityLineNumber) {
            if (priorityLineGeometry.Line === null || priorityLineGeometry.Line === 'EMPTY') {
                setShowSearchedLineError(true);
                setIsPriorityLineLoading(false);
                return;
            }
            const priorityLineData = { lineNumber: priorityLineNumber, lineType: lineType,  geometryMetadata: priorityLineGeometry};
            scene.addGridSection([priorityLineData], seismicMetadata.VolumeToken);
            scene.addLineTiles(priorityLineData, volumeSamplesPerTrace, tracesTileWidth);
            setLoadedLineNumbers(current => {
                const newArray = [...current, priorityLineNumber];
                newArray.sort((a, b) => a - b);
                return newArray;
            });
            setPriorityDimensionIndex({ indexNumber: priorityLineNumber, totalX: (priorityLineData.geometryMetadata.Max - priorityLineData.geometryMetadata.Min) / lineIncrement, totalY: volumeSamplesPerTrace });
            setSelectedNumber(priorityLineNumber);
        }
    }, [priorityLineGeometry]);

    const [ priorityDimensionIndex, setPriorityDimensionIndex ] = useState<DimensionIndex | null>(null);
    const [ selectedNumber, setSelectedNumber ] = useState<number | null>(null);
    const [ dimensionIndexesToLoad, setDimensionIndexesToLoad ] = useState<DimensionIndex[]>([]);

    const onLoadTile = useCallback(
        (tile: Blob, lineNumber: number, tileLineStart: number, tileLineEnd: number, tileSampleStart: number, tileSampleEnd: number) =>
            scene.addTileTexture(tileLineStart, tileSampleStart, lineNumber, lineType, tile)
        , [lineType, scene]);

    const loadTile = useCallback(async (lineNumber: number, tileLineStart: number, tileLineEnd: number) => {
        if (!seismic3DInfo || !dimensionNames || !lineIncrement) {
            return null;
        }
        const [ surveyLineStart, surveyLineEnd, surveyLineIncrement ] = lineType === LineType.Inline ?
            [seismic3DInfo.XlineStart, seismic3DInfo.XlineEnd, seismic3DInfo.XlineIncrement]
            :
            [seismic3DInfo.InlineStart, seismic3DInfo.InlineEnd, seismic3DInfo.InlineIncrement];

        const metadata = seismicMetadata;

        const lineInfo = priorityLineGeometry && priorityLineNumber === lineNumber ?
            { lineNumber: priorityLineNumber, lineType: lineType, geometryMetadata: priorityLineGeometry }
            :
            linesToLoadImages.find(line => line.lineNumber === lineNumber);

        if (!lineInfo) {
            return null;
        }

        const traceRangeStart = metadata.Type === SurveyType.Seismic3D ? 0 : 1;
        const traceRangeEnd = metadata.Type === SurveyType.Seismic3D ? 0 : lineInfo.geometryMetadata.TotalTraces;
        /*const params = getSeismic3DParams(samplesPerTrace, sampleInterval, byteSize, surveyLineIncrement, surveyLineStart, surveyLineEnd, metadata.VolumeToken,
            metadata.VolumePath, metadata.MinSampleValue, metadata.MaxSampleValue, lineType, tileLineStart + lineInfo.geometryMetadata.Min, tileLineEnd + lineInfo.geometryMetadata.Min, lineNumber, seismic3DInfo.InlineStart, seismic3DInfo.InlineEnd,
            seismic3DInfo.XlineStart, seismic3DInfo.XlineEnd, seismic3DInfo.InlineIncrement, seismic3DInfo.XlineIncrement, 'binary', traceRangeStart, traceRangeEnd, tileWidth);*/
        const colorBar = seismic3DDefaults?.color_bar_name ?? 'binary';

        const params: LineTileRequest = {
            volumeSamplesPerTrace,
            sampleInterval,
            byteSize,
            surveyLineStart,
            surveyLineEnd,
            lineIncrement,
            surveyInlineStart: seismic3DInfo.InlineStart,
            surveyInlineEnd: seismic3DInfo.InlineEnd,
            surveyInlineIncrement: seismic3DInfo.InlineIncrement,
            surveyXlineStart: seismic3DInfo.XlineStart,
            surveyXlineEnd: seismic3DInfo.XlineEnd,
            surveyXlineIncrement: seismic3DInfo.XlineIncrement,
            lineStartSample: 0,
            lineEndSample: volumeSamplesPerTrace,
            minSampleValue: metadata.MinSampleValue,
            maxSampleValue: metadata.MaxSampleValue,
            applyWatermark: false,
            colorbar: colorBar,
            dpi: 1,
            filePath: metadata.VolumePath,
            volumeToken: metadata.VolumeToken,
            forceDisableCache: getUrlParams().forceDisableCache,
            forceDisableSamplesCache: false,
            imageCacheSystem: CacheSystem.CloudStorage,
            inverted: false,
            isIndexedVolume: true,
            lineNumber: lineNumber,
            lineStart: (tileLineStart * lineIncrement) + lineInfo.geometryMetadata.Min,
            lineEnd: (tileLineEnd * lineIncrement) + lineInfo.geometryMetadata.Min,
            jpgQuality: 70,
            samplesCacheSystem: CacheSystem.CloudStorage,
            lineType: lineType,
            parallelProcesses: 10,
            skipTraceCount: getUrlParams().traceDecimation,
            tileHeight: Math.round(volumeSamplesPerTrace / tracesXPixelsFactor),
            tileWidth: tileWidth,
            render: getUrlParams().sourceApi,
            traceRangeEnd: 0,
            traceRangeStart:0,
            inlineDimensionName: dimensionNames.Inline,
            xlineDimensionName: dimensionNames.Xline,
            sampleDimensionName: dimensionNames.Sample,
        };

        const tile = await getTile(params);

        return tile;
    }, [byteSize, dimensionNames, getTile, lineIncrement, lineType, linesToLoadImages, priorityLineGeometry, priorityLineNumber, sampleInterval, seismic3DDefaults?.color_bar_name, seismic3DInfo, seismicMetadata, tileWidth, volumeSamplesPerTrace]);

    const onLoadLine = (lineNumber: number) => {
        scene.markLineAsLoaded(lineNumber, lineType);
        setTotalImageLoadedLines(current => current + 1);
        if (priorityLineNumber === lineNumber) {
            setIsPriorityLineLoading(false);
        }
    };

    useEffect(() => {
        onLoadedLineNumbersChange(loadedLineNumbers);
    }, [loadedLineNumbers]);

    useEffect(() => {
        onTotalImageLoadedLinesChange(totalImageLoadedLines);
    }, [totalImageLoadedLines]);

    useEffect(() => {
        if (!searchedLineNumber) {
            return;
        }
        if (loadedLineNumbers.includes(searchedLineNumber)) {
            setSelectedNumber(searchedLineNumber);
        } else {
            setPriorityLineNumber(searchedLineNumber);
        }
    }, [searchedLineNumber]);

    useEffect(() => {
        if (showSearchedLineError) {
            setTimeout(() => setShowSearchedLineError(false), 2500);
        }
    }, [showSearchedLineError]);

    return (
        <React.Fragment>
            <Dimension3D
                indexesToLoad={dimensionIndexesToLoad}
                selectedIndexNumber={selectedNumber}
                priorityIndexToLoad={priorityDimensionIndex}
                lineType={lineType}
                loadTile={loadTile}
                onLoadIndex={onLoadLine}
                onLoadTile={onLoadTile}
                onSelectIndex={setSelectedLineNumber}
                tileHeight={volumeSamplesPerTrace}
                tileWidth={tracesTileWidth}
            />
            <Box
                position={'absolute'}
                sx={{top: '95%',
                    left: '50%',
                    transform: 'translate(-50%, -95%)'}}
            >
                <Collapse in={showSearchedLineError}>
                    <Alert
                        severity='error'
                    >
                        <AlertTitle>It wasn't possible to open the searched line</AlertTitle>
                    </Alert>
                </Collapse>
            </Box>
        </React.Fragment>
    );
};