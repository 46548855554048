import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle, LineString, Point } from 'ol/geom';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { Position } from 'components/geopostPopup/models/enums/Position';
import { IRelatedData } from 'features/seismic/models/interfaces/IRelatedData';
import { SeismicImageContent } from './SeismicImageContent';
import { IRelatedDataImages, useSeismicStore } from 'features/seismic/stores/useSeismicStore';

export function SeismicImagePopup ({parentKey, parentData, imageKey}: Props) {
    const { setWellRelatedData } = useSeismicStore(state => ({
        setWellRelatedData: state.setWellRelatedData
    }));

    const changeOpen = () => {
        let images = parentData.images;
        let updateImage = images[imageKey];
        updateImage.open = !updateImage.open;

        // setWellRelatedData(parentKey, parentData.data, parentData.open, parentData.images);
    };

    return (
        <GeopostPopup
            temporaryIgnoreFlexInContainer
            open={parentData.images[imageKey].open}
            title={parentData.images[imageKey].title}
            onChangeOpen={changeOpen}
            overlay={false}
            height={500}
            width={500}
            position={Position.center}
            resizable={false}
        >
            <SeismicImageContent title={parentData.images[imageKey].title} iframe={parentData.images[imageKey].file} />
        </GeopostPopup>
    );
}

interface Props {
    parentKey: string;
    parentData: { layer: VectorLayer<VectorSource>; relatedData: IRelatedData; opened: boolean; images: IRelatedDataImages; };
    imageKey: string;
}