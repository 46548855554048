import { useTranslation } from 'react-i18next';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { SeismicWellContent } from './SeismicWellContent';
import { Position } from 'components/geopostPopup/models/enums/Position';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';

export function SeismicWellPopup ({ opened, setOpened, dataGeometryLine, surveyType, runWebtour = false }:Props){
    const { t } = useTranslation();

    return (
        <GeopostPopup
            temporaryIgnoreFlexInContainer={false}
            open={opened}
            title={t('wells')}
            onChangeOpen={value => setOpened(value)}
            overlay={false}
            height={536}
            width={395}
            position={surveyType === SurveyType.Seismic2D ? Position.bottomRight : Position.bottomLeft}
            resizable={false}
            rebuildOnOpen={false}
            padding={'15px'}
        >
            <SeismicWellContent opened={opened} setOpened={setOpened} dataGeometryLine={dataGeometryLine} surveyType={surveyType} runWebtour={runWebtour}/>
        </GeopostPopup>
    );
}

interface Props {
    opened: boolean;
    setOpened: (value: boolean) => void;
    dataGeometryLine: ILineString | null;
    surveyType: SurveyType,
    runWebtour?: boolean,
}