import { useQuery } from 'react-query';

import { axios } from 'configurations/axios';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { IDataGroup, IDataSubGroup, IEndpoint, IPreviewFile, IRule, ISubgroup, ISubRule } from './interfaces/ITaxonomy';
import { useSessionStore } from 'session/useSessionStore';

// export function getAllDataSubRule() {
//     return request.get("/Taxonomy/GetAllDataSubRule", {});
//   }

export function useGetOneDataSubRule(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getOneDataSubRule' + token, async () => {
        const response = await axios.request<IResponseAPI<ISubRule>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubRuleByToken',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useGetDataSubRuleBySubGroupToken(subGroupToken: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getDataSubRuleBySubGroupToken' + subGroupToken, async () => {
        const response = await axios.request<IResponseAPI<ISubRule[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubRulesByDataSubGroupToken',
            params: {
                subGroupToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: subGroupToken !== null && subGroupToken !== undefined && subGroupToken !== '',
        cacheTime: 0,
    });
}

export function useDeleteDataSubRule(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteDataSubRule' + token, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/DeleteDataSubRule',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: token !== null && token !== undefined && token !== ''
    });
}

export function useSaveDataSubRule(data: ISubRule | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveDataSubRule' + data?.Token + data?.Rule + data?.Priority + data?.RuleType + data?.DataSubGroupToken + data?.InstitutionToken + data?.MinFileSize + data?.MaxFileSize + data?.ExclusionRegex, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/SaveDataSubRule',
            data: {
                Token: data?.Token,
                Rule: data?.Rule,
                Priority: data?.Priority,
                RuleType: data?.RuleType,
                DataSubGroupToken: data?.DataSubGroupToken,
                InstitutionToken: data?.InstitutionToken,
                MinFileSize: data?.MinFileSize,
                MaxFileSize: data?.MaxFileSize,
                ExclusionRegex: data?.ExclusionRegex,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: data !== null && data !== undefined
    });
}

export function useGetAllDataSubGroups() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllDataSubGroups', async () => {
        const response = await axios.request<IResponseAPI<ISubgroup[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetAllDataSubGroup',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
    });
}

export function useGetDataSubGroupByDataGroupToken(dataGroupToken: string | undefined) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getDataSubGroupByDataGroupToken' + dataGroupToken, async () => {
        const response = await axios.request<IResponseAPI<IDataSubGroup>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubGroupByDataGroupToken',
            params: {
                dataGroupToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result.dataSubGroups;
    }, {
        refetchOnWindowFocus: false,
        enabled: dataGroupToken !== null && dataGroupToken !== undefined && dataGroupToken !== '',
    });
}

export function useGetDataSubGroupByDataGroupTokenDropdown(dataGroupToken: string | undefined) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('useGetDataSubGroupByDataGroupTokenDropdown' + dataGroupToken, async () => {
        const response = await axios.request<IResponseAPI<ISubgroup[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubGroupByDataGroupToken',
            params: {
                dataGroupToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: dataGroupToken !== null && dataGroupToken !== undefined && dataGroupToken !== '',
    });
}

export function useGetDataSubGroupAndChildGroupsByDataGroupToken(dataGroupToken: string | undefined) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getDataSubGroupAndChildGroupsByDataGroupToken' + dataGroupToken, async () => {
        const response = await axios.request<IResponseAPI<IDataSubGroup>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubGroupAndChildGroupsByDataGroupToken',
            params: {
                dataGroupToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result.dataSubGroups;
    }, {
        refetchOnWindowFocus: false,
        enabled: dataGroupToken !== null && dataGroupToken !== undefined && dataGroupToken !== '',
        cacheTime: 0,
    });
}

export function useGetOneDataSubGroup(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getOneDataSubGroup' + token, async () => {
        const response = await axios.request<IResponseAPI<ISubgroup>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubGroupByToken',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: token !== null && token !== undefined && token !== '',
    });
}

export function useDeleteDataSubGroup(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteDataSubGroup' + token, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/DeleteSubGroup',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: token !== null && token !== undefined && token !== ''
    });
}

export function useSaveDataSubGroup(data: ISubgroup | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));
    return useQuery('saveDataSubGroup' + data?.Name + data?.Token + data?.DataSubRulesTokens + data?.DataGroupToken + data?.InternalPath + data?.CopyTransmittalFilesToInternalPath + data?.TransmittalInternalPath + data?.DataGroupName + data?.RegexPathExtractRule, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/SaveDataSubGroup',
            data: {
                Name: data?.Name,
                Token: data?.Token,
                DataSubRulesTokens: data?.DataSubRulesTokens,
                DataGroupToken: data?.DataGroupToken,
                InternalPath: data?.InternalPath,
                CopyTransmittalFilesToInternalPath: data?.CopyTransmittalFilesToInternalPath,
                TransmittalInternalPath: data?.TransmittalInternalPath,
                DataGroupName: data?.DataGroupName,
                RegexPathExtractRule: data?.RegexPathExtractRule,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: data !== null && data !== undefined
    });
}

export function useGetAllDataGroups() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllDataGroups', async () => {
        const response = await axios.request<IResponseAPI<IDataGroup[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetAllDataGroups',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useGetOneDataGroup(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getOneDataGroup' + token, async () => {
        const response = await axios.request<IResponseAPI<IDataGroup>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataGroupByToken',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useDeleteDataGroup(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteDataGroup' + token, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/DeleteDataGroup',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useSaveDataGroup(data: IDataGroup | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveDataGroup' + data?.Name + data?.Token + data?.DataSubGroupsTokens + data?.ParentGroupToken, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/SaveDataGroup',
            data: {
                Name: data?.Name,
                Token: data?.Token,
                DataSubGroupsTokens: data?.DataSubGroupsTokens,
                ParentGroupToken: data?.ParentGroupToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: data !== null && data !== undefined,
        cacheTime: 0,
    });
}

export function useTestRules(phrase: string, send: boolean) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('testRules' + phrase + send, async () => {
        const response = await axios.request<IResponseAPI<IRule[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/RulesCheck',
            params: {
                phrase
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: phrase !== null && phrase !== undefined && phrase !== '' && send
    });
}

export function usePreviewFilesReindexChange(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('previewFilesReindexChange' + token, async () => {
        const response = await axios.request<IResponseAPI<IPreviewFile[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/PreviewFilesReindexChange',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: token !== null && token !== undefined && token !== ''
    });
}

export function useReindexSubgroup(subGroupToken: string, send: boolean) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('reindexSubgroup' + subGroupToken, async () => {
        const response = await axios.request<IResponseAPI<{ error: boolean }>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/ReindexSubgroup',
            params: {
                subGroupToken
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: subGroupToken !== null && subGroupToken !== undefined && subGroupToken !== '' && send
    });
}

export function useGetAllEndpoints() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllEndpoints', async () => {
        const response = await axios.request<IResponseAPI<IEndpoint[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetAllEndpoints',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useGetEndpointsBySubgroup(subGroupToken: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getEndpointsBySubgroup' + subGroupToken, async () => {
        const response = await axios.request<IResponseAPI<IEndpoint[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetEndpointsBySubgroup',
            params: {
                subGroupToken
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: subGroupToken !== null && subGroupToken !== undefined && subGroupToken !== ''
    });
}

export function useGetEndpointByToken(endpointToken: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getEndpointsByToken' + endpointToken, async () => {
        const response = await axios.request<IResponseAPI<IEndpoint>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetEndpointByToken',
            params: {
                endpointToken
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: endpointToken !== null && endpointToken !== undefined && endpointToken !== ''
    });
}

export function useSaveEndpoints(endpointInfo: IEndpoint | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveEndpoints' + endpointInfo?.SubGroupToken + endpointInfo?.EndpointToken + endpointInfo?.EndpointType + endpointInfo?.Url + endpointInfo?.Label + endpointInfo?.RestrictedExtensions, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/SaveEndpoints',
            data: {
                SubGroupToken: endpointInfo?.SubGroupToken,
                EndpointToken: endpointInfo?.EndpointToken,
                EndpointType: endpointInfo?.EndpointType,
                Url: endpointInfo?.Url,
                Label: endpointInfo?.Label,
                RestrictedExtensions: endpointInfo?.RestrictedExtensions,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: endpointInfo !== null && endpointInfo !== undefined
    });
}

export function useDeleteEndpoint(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteEndpoint' + token, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/DeleteEndpoint',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: token !== null && token !== undefined && token !== ''
    });
}