import { useEffect, useMemo, useState } from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { useGetFeatureFlagsByProject } from '../components/projections/api/useSeismicController';
import { getParamsFromUrl } from '../utils/seismicUrlUtils';
import { FeatureFlags2D } from 'features/tenant/model/EnvironmentFeatureFlags';

export function useFeatureFlags () {
    const sessionTenantToken = useSessionStore(state => state.tenantConfiguration?.tenantToken);
    const environmentFeatureFlagList = useSessionStore(state => state.tenantConfiguration?.environmentFeatureFlags);

    const tenantToken = useMemo(() => sessionTenantToken ?? getParamsFromUrl().tenantToken, [sessionTenantToken]);

    const { data: dataFeatureFlag } = useGetFeatureFlagsByProject(tenantToken);
    const [featureFlags, setFeatureFlags] = useState<IFeatureFlags>();

    useEffect(() => {
        if (dataFeatureFlag) {
            let projectFeatureFlags = {
                featureFlags: {
                    seismic2D: {
                        ColorBar: false,
                        Gain: false,
                        Projections: false,
                        Attributes: false,
                        TraceHeader: false,
                        Minimap: false,
                        EnableWells: false,
                        Interpretation: false,
                        WebTour: false,
                        Graticule: false,
                        LineType: false,
                        Depth: false,
                        AlineWorkflow: false,
                        EnableParentAppLink: false,
                        PerformanceMonitor: false,
                        LineSelector2D: false,
                        DefaultMiniMapOn: false,
                        Shortcuts: false,
                        TimeSlice: false,
                        Logout: false,
                        Antialias: false,
                        DefaultColorbarLegendOn: false,
                    }
                }
            };

            let environmentFeatureFlags = { featureFlags: { seismic2D : {...projectFeatureFlags.featureFlags.seismic2D} } };

            dataFeatureFlag.forEach((element: string) => {
                enableComponent(element, projectFeatureFlags);
            });

            environmentFeatureFlagList?.Features2DViewer.forEach(flag => {
                enableComponent(flag, environmentFeatureFlags);
            });

            const resultFeatureFlags = getFeatureFlags(environmentFeatureFlags, projectFeatureFlags);

            if (environmentFeatureFlagList?.Features2DViewer.includes('SEISMIC_2D_WEBTOUR')) {
                resultFeatureFlags.featureFlags.seismic2D.WebTour = true;
            }

            if (environmentFeatureFlagList?.Features2DViewer.includes('SEISMIC_2D_ENABLE_TIME_SLICE')) {
                resultFeatureFlags.featureFlags.seismic2D.TimeSlice = true;
            }

            console.log(resultFeatureFlags);
            setFeatureFlags(resultFeatureFlags);
        }
    }, [dataFeatureFlag]);

    const enableComponent = (item: string, mountFeatureFlags: IFeatureFlags) => {
        switch (item) {
        case 'SEISMIC_2D_ENABLE_COLORBAR':
            mountFeatureFlags.featureFlags.seismic2D.ColorBar = true;
            break;
        case 'SEISMIC_2D_ENABLE_GAIN':
            mountFeatureFlags.featureFlags.seismic2D.Gain = true;
            break;
        case 'SEISMIC_2D_ENABLE_PROJECTIONS':
            mountFeatureFlags.featureFlags.seismic2D.Projections = true;
            break;
        case 'SEISMIC_2D_ENABLE_ATTRIBUTES':
            mountFeatureFlags.featureFlags.seismic2D.Attributes = true;
            break;
        case 'SEISMIC_2D_ENABLE_TRACE_HEADER':
            mountFeatureFlags.featureFlags.seismic2D.TraceHeader = true;
            break;
        case 'SEISMIC_2D_ENABLE_MINIMAP':
            mountFeatureFlags.featureFlags.seismic2D.Minimap = true;
            break;
        case 'SEISMIC_2D_ENABLE_WELLS':
            mountFeatureFlags.featureFlags.seismic2D.EnableWells = true;
            break;
        case 'SEISMIC_2D_ENABLE_INTERPRETATION':
            mountFeatureFlags.featureFlags.seismic2D.Interpretation = true;
            break;
        case 'SEISMIC_2D_WEBTOUR':
            mountFeatureFlags.featureFlags.seismic2D.WebTour = true;
            break;
        case 'SEISMIC_2D_GRATICULE':
            mountFeatureFlags.featureFlags.seismic2D.Graticule = true;
            break;
        case 'SEISMIC_2D_LINE_TYPE':
            mountFeatureFlags.featureFlags.seismic2D.LineType = true;
            break;
        case 'SEISMIC_2D_ANTIALIAS':
            mountFeatureFlags.featureFlags.seismic2D.Antialias = true;
            break;
        case 'SEISMIC_2D_DEPTH_DISPLAY':
            mountFeatureFlags.featureFlags.seismic2D.Depth = true;
            break;
        case 'SEISMIC_2D_ENEVA_ENABLE_ALINE_WORKFLOW':
            mountFeatureFlags.featureFlags.seismic2D.AlineWorkflow = true;
            break;
        case 'SEISMIC_2D_ENABLE_PARENT_APP_LINK':
            mountFeatureFlags.featureFlags.seismic2D.EnableParentAppLink = true;
            break;
        case 'SEISMIC_2D_ENABLE_PERFORMANCE_MONITORING':
            mountFeatureFlags.featureFlags.seismic2D.PerformanceMonitor = true;
            break;
        case 'SEISMIC_2D_LINE_SELECTOR':
            mountFeatureFlags.featureFlags.seismic2D.LineSelector2D = true;
            break;
        case 'SEISMIC_2D_DEFAULT_MINIMAP_ON':
            mountFeatureFlags.featureFlags.seismic2D.DefaultMiniMapOn = true;
            break;
        case 'SEISMIC_2D_ENABLE_SHORTCUTS':
            mountFeatureFlags.featureFlags.seismic2D.Shortcuts = true;
            break;
        case 'SEISMIC_2D_ENABLE_TIME_SLICE':
            mountFeatureFlags.featureFlags.seismic2D.TimeSlice = true;
            break;
        case 'SEISMIC_2D_ENABLE_LOGOUT':
            mountFeatureFlags.featureFlags.seismic2D.Logout = true;
            break;
        case 'SEISMIC_2D_DEFAULT_COLORBAR_LEGEND_ON':
            mountFeatureFlags.featureFlags.seismic2D.DefaultColorbarLegendOn = true;
            break;
        }
    };

    const getFeatureFlags = (environmentFeatureFlags: IFeatureFlags, projectFeatureFlags: IFeatureFlags) => {
        const seismic2DEnvFlags = environmentFeatureFlags.featureFlags.seismic2D;
        const seismic2DProjectFlags = projectFeatureFlags.featureFlags.seismic2D;

        const resultFlags = {...projectFeatureFlags};

        for (let key in seismic2DProjectFlags) {
            resultFlags.featureFlags.seismic2D[key as keyof FeatureFlags2D] = seismic2DProjectFlags[key as keyof FeatureFlags2D] && seismic2DEnvFlags[key as keyof FeatureFlags2D];
        }

        return resultFlags;
    };

    return {
        featureFlags: featureFlags?.featureFlags
    };
};

interface IFeatureFlags {
    featureFlags: {
        seismic2D: {
            ColorBar: boolean,
            Gain: boolean,
            Projections: boolean,
            Attributes: boolean,
            TraceHeader: boolean,
            Minimap: boolean,
            EnableWells: boolean,
            Interpretation: boolean,
            WebTour: boolean,
            Graticule: boolean,
            LineType: boolean,
            Antialias: boolean,
            Depth: boolean,
            AlineWorkflow:boolean,
            EnableParentAppLink: boolean,
            PerformanceMonitor: boolean,
            LineSelector2D: boolean,
            DefaultMiniMapOn: boolean,
            Shortcuts: boolean,
            TimeSlice: boolean
            Logout: boolean,
            DefaultColorbarLegendOn: boolean,
        }
    }
};