import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';

import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { GeopostScene } from 'features/seismic-3d/threejs/scene/GeopostScene';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { useGetTimeDepthActiveForSeismic } from '../api/useTransformToDomainController';
import { GeopostWellGroup } from 'features/seismic-3d/threejs/group/GeopostWellGroup';
import { RaycasterEventFactory } from 'features/seismic-3d/threejs/utils/RaycasterEventFactory';
import { GeopostAnnotationGroup } from 'features/seismic-3d/threejs/group/GeopostAnnotationGroup';
import { GeopostThreeJsConstants } from 'features/seismic-3d/threejs/utils/GeopostThreeJsConstants';
import { getScaled2DCoordinate } from 'features/seismic-3d/threejs/utils/ScaleUtils';
import { getPointInWellPathByDepth } from 'features/seismic-3d/components/utils/AnnotationUtils';
import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';

export const use3DTimeDepths = (wellsWithVisibleTimeDepth: IWell[], onAddWellGroup: (well: IWell) => void, volumeToken: string, trace: number, color: string,
    selectedColor: string, domain: AmplitudeDomain, onError: (well: IWell, errorType: string, errorMessage: string) => void) => {

    const { current: lastWellsWithVisibleTimeDepth } = useRef<IWell[]>([]);

    const [ loadingTimeDepthWells, setLoadingTimeDepthWells ] = useState<IWell[]>([]);

    const surveyHeightInPixels = use3DViewerStore(state => state.heightPixelFactor);

    const wellsToAddTimeDepth = useMemo(() => wellsWithVisibleTimeDepth.filter(well => !lastWellsWithVisibleTimeDepth.includes(well)), [wellsWithVisibleTimeDepth]);

    const wellsToRemoveTimeDepth = useMemo(() => lastWellsWithVisibleTimeDepth.filter(well => !wellsWithVisibleTimeDepth.includes(well)), [wellsWithVisibleTimeDepth]);

    const mainFeatureCentroidX = use3DViewerStore(state => state.featureCentroidX);
    const mainFeatureCentroidY = use3DViewerStore(state => state.featureCentroidY);

    const scene = use3DSceneStore(state => state.scene);

    const { mutateAsync: getTimeDepthActiveForSeismic } = useGetTimeDepthActiveForSeismic();

    const addTimeDepth = useCallback((well: IWell) => {
        getTimeDepthActiveForSeismic({
            trace: trace,
            volumeToken: volumeToken,
            wellId: well.Id,
            domain : domain
        })
            .then(timeDepth => {
                if (!scene) {
                    return;
                }
                let wellGroup : GeopostWellGroup;
                do {
                    wellGroup = scene.getObjectByName(GeopostWellGroup.getWellGroupName(well.Id)) as GeopostWellGroup;
                    if (!wellGroup) {
                        onAddWellGroup(well);
                    }
                } while (!wellGroup);
                const wellPoints = wellGroup.wellPoints;
                const groupName = 'time-depth';
                const timeDepthGroup = new THREE.Group();
                timeDepthGroup.name = groupName;
                const annotationMeshes : GeopostAnnotationGroup[] = [];
                const raycasterEventFactory = new RaycasterEventFactory<THREE.Mesh>();

                timeDepth.items.forEach(item => {
                    const itemDepth = item.Origin;
                    const depthInPixels = Math.abs(itemDepth) / wellGroup.heightFactor;
                    const scaledDepth = -(depthInPixels / GeopostThreeJsConstants.yDivisionFactor);
                    const annotationPoint = getPointInWellPathByDepth(itemDepth, wellGroup.wellPoints, wellGroup.heightFactor);
                    const wellPoints = wellGroup.wellPoints;
                    const rotationReference = wellGroup.wellPoints[wellGroup.wellPoints.indexOf(annotationPoint) + 1] ?? wellGroup.wellPoints[wellGroup.wellPoints.indexOf(annotationPoint) - 1];
                    const annotation = new GeopostAnnotationGroup(item.Labels[0], scaledDepth, annotationPoint.x, annotationPoint.z, null, wellGroup.heightFactor, color);
                    timeDepthGroup.add(annotation);
                    annotationMeshes.push(annotation);
                });

                const intersectionEvent = raycasterEventFactory.addIntersectionEventOnOneOfGroup(
                    annotationMeshes.map(x => x.ring),
                    (annotationPoint) => {
                        if (!annotationPoint.parent?.visible) {
                            return;
                        }
                        (annotationPoint.material as THREE.MeshBasicMaterial).color = new THREE.Color(selectedColor);
                        const annotation = (annotationPoint.parent! as GeopostAnnotationGroup);
                        annotation.label.visible = true;
                    },
                    (annotationPoint) => {
                        (annotationPoint.parent! as GeopostAnnotationGroup).label.visible = false;
                        (annotationPoint.material as THREE.MeshBasicMaterial).color = new THREE.Color(color);
                    }
                );
                scene.intersectionEvents.push(intersectionEvent);
                timeDepthGroup.userData.intersectionEvent = intersectionEvent;
                wellGroup.add(timeDepthGroup);
            })
            .catch(() => {
                onError(well, 'server', '');
            })
            .finally(() => {
                setLoadingTimeDepthWells(current => {
                    const newArray = [...current];
                    newArray.splice(newArray.indexOf(well), 1);
                    return newArray;
                });
            });
    }, [getTimeDepthActiveForSeismic, trace, volumeToken, domain, scene, onAddWellGroup, mainFeatureCentroidX, mainFeatureCentroidY, color, selectedColor]);

    const removeTimeDepth = useCallback((well: IWell) => {
        if (!scene) {
            return;
        }
        const wellGroup = scene.getObjectByName(GeopostWellGroup.getWellGroupName(well.Id)) as GeopostWellGroup;
        const annotationGroup = wellGroup?.getObjectByName('time-depth');
        if (!annotationGroup) {
            return;
        }
        const intersectionEvent = annotationGroup.userData.intersectionEvent as (raycaster: THREE.Raycaster) => void;
        scene.intersectionEvents.splice(scene.intersectionEvents.indexOf(intersectionEvent), 1);
        wellGroup.remove(annotationGroup);
    }, [scene]);

    useEffect(() => {
        wellsToAddTimeDepth.forEach(well => {
            lastWellsWithVisibleTimeDepth.push(well);
            setLoadingTimeDepthWells(current => [...current, well]);
            addTimeDepth(well);
        });
    }, [wellsToAddTimeDepth]);

    useEffect(() => {
        wellsToRemoveTimeDepth.forEach(well => {
            lastWellsWithVisibleTimeDepth.splice(lastWellsWithVisibleTimeDepth.indexOf(well), 1);
            removeTimeDepth(well);
        });
    }, [wellsToRemoveTimeDepth]);

    useEffect(() => {
        if (surveyHeightInPixels > 0) {
            lastWellsWithVisibleTimeDepth.forEach(well => {
                removeTimeDepth(well);
                addTimeDepth(well);
            });
        }
    }, [surveyHeightInPixels]);

    return { loadingTimeDepthWells };
};