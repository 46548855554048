import { css, FormControl, TextField } from '@mui/material';
import { useRef, useEffect, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { useSeismicStore } from '../stores/useSeismicStore';

const containerStyle = css({
    display: 'flex',
    maxWidth: 200,
    padding: '10px',
    '& .MuiFormControl-root': {
        margin: '0px',
        '& .MuiOutlinedInput-notchedOutline': {
            '& legend': {
                width: '64px',
            }
        }
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            borderWidth: '1px',
            borderRadius: '5px'
        }
    }
});

export function SeismicChangeScale () {
    const refScaleX = useRef<HTMLInputElement>(null);
    const refScaleY = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const { scale, setScaleX, setScaleY } = useSeismicStore(state => ({
        scale: state.scale,
        setScaleX: state.setScaleX,
        setScaleY: state.setScaleY
    }));

    useEffect(() => {
        if (refScaleX.current){
            refScaleX.current.value = scale.x.toString();
        }

        if (refScaleY.current){
            refScaleY.current.value = scale.y.toString();
        }

    }, [scale?.x, scale?.y]);

    const changeValue = (type: Type) => {
        if (type === Type.x){
            setValue(refScaleX, setScaleX);
        }
        else if (type === Type.y){
            setValue(refScaleY, setScaleY);
        }
    };

    const setValue = (ref: RefObject<HTMLInputElement>, setScale: (value: number) => void) => {
        let value = 1;
        if (ref.current){
            value = parseFloat(ref.current.value);
        }

        setScale(value);
    };

    return (
        <div css={containerStyle}>
            <FormControl fullWidth>
                <TextField inputRef={refScaleX} label={'Horizontal'} variant='outlined' onBlur={() => changeValue(Type.x)} style={{width:'90px', marginRight: '10px'}} />
            </FormControl>
            <FormControl fullWidth>
                <TextField inputRef={refScaleY} label={'Vertical'} variant='outlined' onBlur={() => changeValue(Type.y)}  style={{width:'90px'}}/>
            </FormControl>
        </div>
    );
}

enum Type {
    x = 1,
    y = 2
}