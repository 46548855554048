import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useMemo } from 'react';

import { usePerformanceMonitoringStore } from 'features/seismic/stores/usePerformanceMonitoringStore';

export function PrepareRenderPanel() {
    const prepareRenderPerformanceData = usePerformanceMonitoringStore(state => state.prepareRenderPerformanceData);

    const finalData = useMemo(() => prepareRenderPerformanceData.map(data => ({
        sampleTime: (data.getSampleTime !== 'None' && data.getSampleTime) ? parseFloat(data?.getSampleTime).toFixed(3): '',
        generateCacheTime: (data.getGenerateCacheTime !== 'None' && data.getGenerateCacheTime) ? parseFloat(data?.getGenerateCacheTime).toFixed(3): '',
        notes: data.notes,
        error: data.error
    })), [prepareRenderPerformanceData]);

    return <Table size='small'>
        <TableHead>
            <TableRow>
                <TableCell>
                    Get Sample Time (sec.)
                </TableCell>
                <TableCell>
                    Generate Cache Time (sec.)
                </TableCell>
                <TableCell>
                    Notes
                </TableCell>
                <TableCell>
                    Error
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {finalData?.map(item => (
                <TableRow>
                    <TableCell>
                        {item.sampleTime}
                    </TableCell>
                    <TableCell>
                        {item.generateCacheTime}
                    </TableCell>
                    <TableCell>
                        {item.notes}
                    </TableCell>
                    <TableCell>
                        {item.error}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>;
}