import React, { ChangeEvent, ChangeEventHandler, useCallback } from 'react';
import { Box, Collapse, Grid, IconButton, Slider, Stack, styled, TableCell, tableCellClasses, TableRow, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { t } from 'i18next';

import { FeatureGeom } from 'features/seismic-3d/models/classes/FeatureGeom';
import { IntersectingLayer } from 'features/seismic-3d/models/types/IntersectingLayer';
import { IntersectingLayerCheckbox } from './IntersectingLayerCheckbox';

export const LayersListRow = ({ layer, checked, onSelectCallback, onUnselectCallback, minProjectionHeight, onChangeProjectionHeight, onChangeProjectionColor, onChangeProjectionOpacity, projectionOpacity } : LayersListRowProps) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            border: '1px solid #d9d9d9',
            whiteSpace: 'nowrap'
        },
        [`&.${tableCellClasses.body}`]: {
            border: '1px solid #d9d9d9'
        },
    }));

    const [ collapsed, setCollapsed ] = useState<boolean>(false);

    const handleProjectionHeightChange = useCallback((event: Event, value: number | Array<number>) => onChangeProjectionHeight(layer.Id, value as number), [onChangeProjectionHeight, layer]);
    const handleProjectionOpacityChange = useCallback((event: Event, value: number | number[]) => onChangeProjectionOpacity(layer.Id, value as number), [onChangeProjectionColor, layer]);
    const handleProjectionColorChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => onChangeProjectionColor(layer.Id, event.target.value), [onChangeProjectionColor, layer]);
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell size='small'>
                    <IconButton
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        {collapsed ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell size='small'>
                    <IntersectingLayerCheckbox
                        selectCallback={onSelectCallback}
                        unselectCallback={onUnselectCallback}
                        checked={checked}
                        layerData={layer}
                    />
                </TableCell>
                <TableCell size='small'>
                    {layer.Title}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={3} >
                    <Collapse in={collapsed}>
                        <Box height={'175px'}>
                            <Grid height={'100%'} container spacing={3} sx={{paddingTop: '15px', paddingBottom: '5px', paddingLeft: 0}}>
                                {
                                    minProjectionHeight < 0
                                    &&
                                    <Grid item xs={7}>
                                        <Stack height={'100%'} paddingTop='0' alignItems='center' justifyContent='flex-end'>
                                            <Stack height={1}>
                                                <Slider
                                                    orientation='vertical'
                                                    valueLabelDisplay='off'
                                                    min={minProjectionHeight}
                                                    max={0.01}
                                                    defaultValue={0.01}
                                                    step={0.01}
                                                    onChange={handleProjectionHeightChange}
                                                />
                                            </Stack>
                                            <Typography sx={{marginTop: '5px', marginBottom: '0px'}}>{ t('Projection Height').toString() }</Typography>
                                        </Stack>
                                    </Grid>
                                }
                                <Grid item xs={5} sx={{paddingTop: '-10px', borderLeft: '1px solid', borderColor: 'divider'}}>
                                    <Stack height={'100%'} spacing={2} paddingTop={0}>
                                        <Stack alignItems='start' paddingTop={0}>
                                            <Typography sx={{marginTop: 0}}>
                                                { t('Fill Color').toString() }
                                            </Typography>
                                            <Stack>
                                                <input
                                                    type='color'
                                                    onChange={handleProjectionColorChange}
                                                    defaultValue={layer.FillColor}
                                                />
                                            </Stack>
                                        </Stack>
                                        <Stack>
                                            <Typography>
                                                Fill Opacity
                                            </Typography>
                                            <Slider
                                                min={0}
                                                max={1}
                                                defaultValue={projectionOpacity}
                                                step={0.01}
                                                onChange={handleProjectionOpacityChange}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export type LayersListRowProps = {
    layer: IntersectingLayer,
    checked: boolean,
    onSelectCallback : (layerData: IntersectingLayer, layerGeomData: FeatureGeom) => void,
    onUnselectCallback: (layerData: IntersectingLayer) => void,
    minProjectionHeight: number,
    onChangeProjectionHeight: (layerId: number, height: number) => void,
    onChangeProjectionColor : (layerId: number, color: string) => void,
    onChangeProjectionOpacity: (layerId: number, opacity: number) => void,
    projectionOpacity: number;
};