import { useQuery, useMutation } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { axios } from 'configurations/axios';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { IinterpretationEdit } from 'features/seismic/components/interpretation/models/interfaces/IinterpretationEdit';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { ISaveParams } from 'features/seismic/components/interpretation/models/interfaces/ISaveParams';
import { IGetCoordinatesResponse } from 'features/seismic/components/interpretation/models/interfaces/IGetCoordinatesResponse';
import { LineType } from 'features/seismic/models/enums/LineType';
import { ISaveAllCordinateParams } from '../models/interfaces/ISaveAllCordinateParams';
import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { IGetAllResponse } from '../models/interfaces/IGetAllResponse';
import { IGetAllHiddenResponse } from '../models/interfaces/IGetAllHiddenResponse';
import { IChangeVisibilityParams } from '../models/interfaces/IChangeVisibilityParams';
import { IChangeVisibilityResponse } from '../models/interfaces/IChangeVisibilityResponse';
import { IDeleteResponse } from '../models/interfaces/IDeleteResponse';
import { IGetCoordinatesParams } from '../models/interfaces/IGetCoordinatesParams';
import { IExportParams } from '../models/interfaces/IExportParams';
import { IGetCrossParams } from '../models/interfaces/IGetCrossParams';
import { IGetCrossResponse } from '../models/interfaces/IGetCrossResponse';

export function useGetAll(domain?: AmplitudeDomain) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery(`interpretationsGetAll${domain}`, async () => {
        const response = await axios.request<IResponseAPI<IGetAllResponse>>({
            method: 'get',
            url: tenantConfig?.endpoints.interpretation.MainUrl + '/Interpretations/Interpretations/GetAll',
            params: {
                seismicDomain: domain,
                includeCross: true
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    });
}
export function useGetAllByLine(token:string | null,lineType : LineType, lineNumber:number,domain?: AmplitudeDomain) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery(`interpretationsGetAll${domain}${token}`, async () => {
        const response = await axios.request<IResponseAPI<IGetAllResponse>>({
            method: 'get',
            url: tenantConfig?.endpoints.interpretation.MainUrl + '/Interpretations/Interpretations/GetAllByLine',
            params: {
                seismicDomain: domain,
                volumeToken:token,
                lineType:lineType,
                lineNumber:lineNumber
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    });
}
export function useGet(token: string | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('seismicInterpretationGet' + token, async () => {
        const response = await axios.request<IResponseAPI<IinterpretationEdit>>({
            method: 'get',
            url: tenantConfig?.endpoints.interpretation.MainUrl + '/Interpretations/Interpretations/Get',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response;
    }, {
        enabled: !!(token),
        staleTime: 0
    });
}

export function useChangeVisibility() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useMutation(async (data: IChangeVisibilityParams) => {
        const response = await axios.request<IResponseAPI<IChangeVisibilityResponse>>({
            method: 'get',
            url: tenantConfig?.endpoints?.interpretation.MainUrl + '/Interpretations/Interpretations/ChangeVisibility',
            params: {
                token: data.token,
                visibility: data.visibility
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    });
}

export function useSave() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useMutation(async (dataSave: ISaveParams) => {
        const params = {
            Token: dataSave.token,
            Name: dataSave.name,
            Domain: dataSave.domain,
            Style: {
                Color: dataSave.lineColor,
                Width: dataSave.lineWidth,
                Opacity: dataSave.lineOpacity
            },
            Type: dataSave.type,
            Permission: dataSave.permission,
            SharedTokens: dataSave.sharedTokens
        };

        const response = await axios.request<IResponseAPI<IinterpretationEdit>>({
            method: 'post',
            url: tenantConfig?.endpoints.interpretation.MainUrl + '/Interpretations/Interpretations/Save',
            headers: {
                'Content-Type': 'application/json'
            },
            data: params,
        });

        return response;
    });
}

export function useGetAllHidden(domain?: AmplitudeDomain) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery(`interpretationsGetAllHidden${domain}`, async () => {
        const response = await axios.request<IResponseAPI<IGetAllHiddenResponse[]>>({
            method: 'get',
            url: tenantConfig?.endpoints?.interpretation.MainUrl + '/Interpretations/Interpretations/GetAllHidden',
            params: {
                domain: domain
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    });
}

export function useDelete() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useMutation('delete', async (token: string) => {
        const response = await axios.request<IResponseAPI<IDeleteResponse>>({
            method: 'get',
            url: tenantConfig?.endpoints.interpretation.MainUrl + '/Interpretations/Interpretations/Delete',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    });
}

export function useMutationGetCoordinates() {
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));

    return useMutation(async (params: IGetCoordinatesParams) => {
        const paramsViewer = getParamsFromUrl();
        const accessToken = paramsViewer.jwtToken;

        const response = await axios.get<IResponseAPI<IGetCoordinatesResponse>>(tenantConfig?.endpoints.interpretation.MainUrl + '/Interpretations/Interpretations/GetCoordinates', {
            params: {
                horizonToken: params.horizonToken,
                volumeToken: params.volumeToken,
                orientation: params.orientation,
                lineNumber: params.lineNumber,
                sourceApi: params.sourceApi
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        return response;
    });
}

export function useGetCoordinates(horizonToken: string, volumeToken: string, orientation: LineType, lineNumber: number, sourceApi: string, enabled: boolean) {
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));

    return useMutation('seismicInterpretationGetCoordinates' + horizonToken, async () => {
        const paramsViewer = getParamsFromUrl();
        const accessToken = paramsViewer.jwtToken;

        const response = await axios.get<IResponseAPI<IGetCoordinatesResponse>>(tenantConfig?.endpoints.interpretation.MainUrl + '/Interpretations/Interpretations/GetCoordinates', {
            params: {
                horizonToken,
                volumeToken,
                orientation,
                lineNumber,
                sourceApi
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        return response;
    });
}

export function useSaveAllCoordinates() {
    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));

    const jwtToken = getParamsFromUrl().jwtToken;

    return useMutation(async (params: ISaveAllCordinateParams) => {
        const saveData = {
            coordinates: params.coordinates,
            data: {
                InterpretationToken: params.interpretationToken,
                VolumeToken: params.volumeToken,
                LineOrientation: params.orientation,
                LineNumber: params.lineNumber,
                SourceApi: params.sourceApi,
            }
        };

        const response = await axios.request<IResponseAPI<IinterpretationEdit>>({
            signal: params.signal,
            method: 'post',
            url: tenantConfig?.endpoints.interpretation.MainUrl + '/Interpretations/Interpretations/SaveAllCoordinates',
            headers: {
                'Content-Type': 'application/json'
            },
            data: saveData,
        });

        return response;
    });
}

export function useExport() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useMutation(async (param: IExportParams) => {
        const response = await axios.post<IResponseAPI<{ ok: boolean }>>(tenantConfig?.endpoints?.interpretation.MainUrl + '/Interpretations/Interpretations/Export', {
            mode: param.mode,
            token: param.token,
            volumeToken: param.volumeToken,
            orientation: param.orientation,
            lineNumber: param.lineNumber,
            sourceApi: param.sourceApi
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response;
    });
}

export function useGetCross() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useMutation(async (param: IGetCrossParams) => {
        const response = await axios.post<IResponseAPI<IGetCrossResponse>>(tenantConfig?.endpoints?.interpretation.MainUrl + '/Interpretations/Interpretations/GetCross', param, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response;
    });
}