export function calculateGain(factor: number, max: number, min: number){
    if (factor === 0) {
        return null;
    }
    const percentage = 1 + factor / 100;

    const newMaxGain = max * percentage;
    const newMinGain = min * percentage;

    return {
        min: newMinGain,
        max: newMaxGain
    };
}