import queryString from 'query-string';
import Cookies from 'universal-cookie';
import axios from 'axios';

import { CacheSystem } from '../models/enums/CacheSystem';
import { LineType } from '../models/enums/LineType';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';

export function getParamsFromUrl() : IParamsUrl{
    const cookies = new Cookies();
    const element = queryString.parse(window.location.search, {});
    const sourceApi = (element.sourceApi) ? element.sourceApi as string : '';
    let volumeToken = (element.volumeToken) ? element.volumeToken as string : '';
    let filePath = (element.filePath) ? element.filePath as string : '';
    if (!filePath) {
        filePath = (element.filepath) ? element.filepath as string : '';
    }
    const wellId = (element.wellId) ? element.wellId as string : '';
    const pathS3 = (element.PathS3) ? element.PathS3 as string : '';
    let jwtToken = (element.sessionJwt) ? element.sessionJwt as string : '';
    let applyWatermark = (element.applyWatermark) ? element.applyWatermark as string : '';
    let forceLineSkip = (element.forceLineSkip) ? parseInt(element.forceLineSkip.toString() )  : 1;
    let tenantToken = (element.tenantToken) ? element.tenantToken as string : '';
    let delayTileLoad = (element.tenantToken) ? element.delayTileLoad as string : '';
    let externalSSOAccessToken = (element.externalSSOAccessToken)? element.externalSSOAccessToken as string : '';
    let lineNumber = (element.lineNumber) ? parseInt(element.lineNumber as string) : undefined;
    let lineType = (element.lineType) ? parseInt(element.lineType as string) as LineType : LineType.Inline;
    let performanceMonitoring = (element.performanceMonitoring) ? JSON.parse((element.performanceMonitoring as string).toLowerCase()) : false;
    let prepareRenderStep = (element.prepareRenderStep) ? parseInt(element.prepareRenderStep as string) : 20;
    let forceDisableSamplesCache = (element.forceDisableSamplesCache) ? (element.forceDisableSamplesCache as string).toLowerCase() === 'true' : false;
    let imageCacheSystem = (element.imageCacheSystem) ? parseInt(element.imageCacheSystem as string) as CacheSystem : CacheSystem.Undefined;
    let samplesCacheSystem = (element.samplesCacheSystem) ? parseInt(element.samplesCacheSystem as string) as CacheSystem : CacheSystem.Undefined;
    let parallelChunksNumber = (element.parallelChunksNumber) ? parseInt(element.parallelChunksNumber as string) : 9;
    let access_token = (element.access_token) ? element.access_token as string : '';
    let debug = (window.location.href.indexOf('debug=true') > -1);
    let forceDisableImageCache = (window.location.href.toLowerCase().indexOf('forcedisableimagecache=true') > -1);
    let skipTraceCount = (element.skipTraceCount) ? parseInt(element.skipTraceCount as string) : 1;

    // if (jwtToken === '') {
    //     jwtToken = (element.sessionJwt)?element.sessionJwt as string:'';;
    // }

    if (jwtToken === '') {
        const tenantToken = (element.tenantToken)?element.tenantToken as string:'';
        try {
            const cookieName = 'auth_spa_' + tenantToken;
            let cookieValue = cookies.get(cookieName);
            jwtToken = cookieValue;
        } catch (error) {
            console.log('Error on getting cookie spa: geopost auth');
        }
    }

    let volumeIdentifier = 'volumeToken';

    if (element.volumeId){
        volumeIdentifier = 'volumeId';
        volumeToken = element.volumeId.toString();
    }

    const params: IParamsUrl = {
        debug: debug,
        filePath: filePath,
        volumeToken: volumeToken,
        volumeIdentifier: volumeIdentifier,
        jwtToken: jwtToken,
        forceDisableImageCache: forceDisableImageCache,
        applyWatermark: applyWatermark?.toLowerCase() === 'true',
        forceLineSkip: forceLineSkip,
        sourceApi: sourceApi,
        wellId: wellId,
        pathS3:pathS3,
        tenantToken: tenantToken,
        delayTileLoad: (delayTileLoad) ? parseInt(delayTileLoad) : 0,
        externalSSOAccessToken: externalSSOAccessToken,
        lineNumber: lineNumber,
        lineType: lineType,
        performanceMonitoring: performanceMonitoring,
        prepareRenderStep,
        samplesCacheSystem: samplesCacheSystem,
        imageCacheSystem: imageCacheSystem,
        forceDisableSamplesCache,
        prepareIterationChunks: parallelChunksNumber,
        access_token,
        skipTraceCount
    };

    return params;
}

export interface IParamsUrl {
    debug: boolean;
    filePath: string;
    sourceApi:string;
    volumeToken: string;
    volumeIdentifier: string;
    jwtToken: string;
    forceDisableImageCache: boolean;
    forceDisableSamplesCache: boolean;
    applyWatermark: boolean;
    forceLineSkip: number
    wellId: string;
    pathS3: string;
    tenantToken: string;
    delayTileLoad: number;
    externalSSOAccessToken: string;
    lineNumber?: number;
    lineType: LineType;
    performanceMonitoring: boolean;
    prepareRenderStep: number;
    imageCacheSystem: CacheSystem;
    samplesCacheSystem: CacheSystem;
    prepareIterationChunks: number;
    skipTraceCount: number;
    access_token:string;
}