import { Coordinate } from 'ol/coordinate';
import WKT from 'ol/format/WKT';
import { Geometry, LineString, MultiPolygon, Point, Polygon } from 'ol/geom';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import * as THREE from 'three';

import { GeopostThreeJsConstants } from './GeopostThreeJsConstants';

export const getGridScaledExtent = (gridWkt: string, mainFeatureCentroidX : number, mainFeatureCentroidY: number) => {
    const gridPolygon = new WKT().readGeometry(gridWkt);
    gridPolygon.translate(-mainFeatureCentroidX, -mainFeatureCentroidY);
    const scaleFactor = transformDivisionFactorToScalarFactor(GeopostThreeJsConstants.coordinateDivisionFactor);
    gridPolygon.scale(-scaleFactor, scaleFactor, [0, 0]);

    const transformedExtent = gridPolygon.getExtent();

    return transformedExtent;
};

export const transformDivisionFactorToScalarFactor = (divisionFactor : number) => {
    return 12.5 / divisionFactor;
};

export const getScaled2DCoordinate = (coordinate: Coordinate, mainFeatureCentroidX : number, mainFeatureCentroidY: number) => {
    if (coordinate[0] === 0 && coordinate[1] === 0) {
        return coordinate;
    }

    const point = new Point(coordinate);
    point.translate(-(mainFeatureCentroidX), -(mainFeatureCentroidY));
    const scaleFactor = transformDivisionFactorToScalarFactor(GeopostThreeJsConstants.coordinateDivisionFactor);
    point.scale(-scaleFactor, scaleFactor, [0, 0]);
    return point.getCoordinates();
};

export const getCountTimeDepth = (sampleInterval: number, sampleIntervalUnit: number, samplesPerTrace: number) => {
    const adaptedSampleInterval = sampleIntervalUnit === 1 ? sampleInterval / 1000 : sampleInterval;
    const countTimeDepth = adaptedSampleInterval * samplesPerTrace;
    return countTimeDepth;
};

export const buildPointsFromCoordinates = (coordinates: Coordinate[]) => {
    let points = [];
    for (let i = 0; i < coordinates.length; i++) {
        let x = coordinates[i][0];
        let y = coordinates[i][1];

        points.push(new THREE.Vector3(x, 0, y));
    }
    return points;
};

export const  scaleGeometry = (geometry : Geometry, mainFeatureCentroid: [number, number]) => {
    const scaleFactor = transformDivisionFactorToScalarFactor(GeopostThreeJsConstants.coordinateDivisionFactor);
    geometry.translate(-(mainFeatureCentroid[0]), -(mainFeatureCentroid[1]));
    geometry.scale(-scaleFactor, scaleFactor, [0, 0]);
};

export const getThreeJsPointsFromOlGeometry = (olGeometry: Geometry) => {
    let coordinates : Coordinate[] = [];
    if (olGeometry instanceof Polygon) {
        coordinates = olGeometry.getCoordinates()[0];
    }
    else if (olGeometry instanceof LineString){
        coordinates = olGeometry.getCoordinates();
    }
    else if (olGeometry instanceof Point){
        coordinates = [olGeometry.getCoordinates()];
    }
    const points = buildPointsFromCoordinates(coordinates);
    return points;
};

export const getScaledThreeJsGeometryFromWKT = (geomWkt: string, geomCentroidWkt: string) => {
    const format = new WKT();
    const openLayersGeometry = format.readGeometry(geomWkt, {
        dataProjection: '3857'
    });
    const centroid = format.readGeometry(geomCentroidWkt, {
        dataProjection: '3857'
    });
    const scaleFactor = transformDivisionFactorToScalarFactor(GeopostThreeJsConstants.coordinateDivisionFactor);
    const centroidCoordinates = (centroid instanceof Point) ? centroid.getCoordinates() : [0, 0];
    //const center = getCenter(openLayersGeometry.getExtent());
    openLayersGeometry.translate((-1 * centroidCoordinates[0]), ( -1 * centroidCoordinates[1]));
    openLayersGeometry.scale(scaleFactor, scaleFactor, [0, 0]);

    let threeJsGeometry : THREE.BufferGeometry;

    if (openLayersGeometry instanceof Polygon || openLayersGeometry instanceof LineString || openLayersGeometry instanceof Point){
        let coordinates : Coordinate[] = [];
        if (openLayersGeometry instanceof Polygon) {
            coordinates = openLayersGeometry.getCoordinates()[0];
        }
        else if (openLayersGeometry instanceof LineString){
            coordinates = openLayersGeometry.getCoordinates();
        }
        else if (openLayersGeometry instanceof Point){
            coordinates = [openLayersGeometry.getCoordinates()];
        }
        const points = buildPointsFromCoordinates(coordinates);
        threeJsGeometry = new THREE.BufferGeometry().setFromPoints(points);
    }
    else {
        let multiCoordinates : Coordinate[][][] = [];
        if (openLayersGeometry instanceof MultiPolygon) {
            multiCoordinates = openLayersGeometry.getCoordinates();
        }
        const multiPoints = buildMultiPointsFromMultiCoordinates(multiCoordinates);
        const geometriesToMerge : THREE.BufferGeometry<THREE.NormalBufferAttributes>[] = [];
        multiPoints.forEach((points) => {
            const geometry = new THREE.BufferGeometry().setFromPoints(points);
            geometriesToMerge.push(geometry);
        });
        threeJsGeometry = BufferGeometryUtils.mergeGeometries(geometriesToMerge);
    }

    return threeJsGeometry;
};

const buildMultiPointsFromMultiCoordinates = (multiCoordinates: Coordinate[][][]) => {
    let multipoints : THREE.Vector3[][] = [];
    multiCoordinates.forEach((coordinates) => {
        const points = buildPointsFromCoordinates(coordinates[0]);
        multipoints.push(points);
    });
    return multipoints;
};

export const getScaledDepth = (depth: number, heightFactor: number) => {
    const depthInPixels = Math.abs(depth) / heightFactor;
    const scaledDepth = - (depthInPixels / GeopostThreeJsConstants.yDivisionFactor);
    return scaledDepth;
};