import * as React from 'react';
import { SVGProps } from 'react';
const SvgFitPage = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox="0 0 556 608"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="currentColor"
        {...props}
    >
        <path d="M500 34v46h-45v44h-45V80h45V34h45zM45 79H0V34h45v45zm275 134V79h45v45h45v45h45v44H320zm-141 1H45v-45h45v-45H45V79h45v45h45V79h44v135zm28 32h83v84h-83v-84zm114 116h134v44h-45v45h-45v45h-44V362zm-142 0v134h-44v-45H90v-45H45v-44h134zm276 89v45h45v45h-45v-45h-45v-45h45zm-365 0v44H45v46H0v-46h45v-44h45z" />
    </svg>
);
export default SvgFitPage;
