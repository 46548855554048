import Source, { Options } from 'ol/source/ImageWMS';

export class GeopostWMSSource extends Source {
    constructor(settings: Options, jwtToken: string, proxyUrl: string){
        super({
            ...settings,
            imageLoadFunction: (image, url) => {
                let client = new XMLHttpRequest();
                url += '&host=' + settings.url;
                client.open('GET', url);
                client.responseType = 'arraybuffer';
                client.setRequestHeader('Authorization', 'Bearer ' + jwtToken);
                client.onload = () => {
                    const arrayBufferView = new Uint8Array(client.response);
                    const blob = new Blob([arrayBufferView], { type: 'image/png' });
                    const urlCreator = window.URL || window.webkitURL;
                    const imageUrl = urlCreator.createObjectURL(blob);
                    (image.getImage() as HTMLImageElement).src = imageUrl;
                };
                client.send();
            }
        });

        this.setUrl(proxyUrl + '/mapProxy?');
    }
}