import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

export class SignalR {
    private url: string;
    private connected: boolean;
    private connection: HubConnection;

    constructor(url: string, jwtToken: string) {
        this.connected = false;
        this.url = url;
        this.connection = new HubConnectionBuilder()
            .withUrl(this.url, {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                },
                accessTokenFactory: function() {
                    return jwtToken;
                }
            }).withAutomaticReconnect([1000, 3000, 6000, 9000, 15000, 20000])
            .build();
        this.start();
    }

    async start() {
        await this.connection.start();
        this.connected = true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listen(event: string, callback: (...args: any[]) => void) {
        this.connection.on(event, callback);
    }
}
