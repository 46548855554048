import { Fragment } from 'react';

import { IndexTable } from 'features/index/components/IndexTable';

export function IndexViewer(){
    return (
        <Fragment>
            <IndexTable></IndexTable>
        </Fragment>
    );
}