import { useMutation } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { getUrlParams } from 'features/seismic-3d/utils/Seismic3DUrlUtils';
import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { WellAnnotationType } from 'features/seismic/models/enums/WellAnnotationType';
import { IAnnotation } from 'features/seismic/models/interfaces/IAnnotation';
import { axios } from 'configurations/axios';

export const useGetAllAnnotationForSeismic = () => {
    const apiUrl = useSessionStore(state => state.tenantConfiguration?.endpoints.wells);
    const sessionJwtToken = useSessionStore(state => state.jwtToken);
    const queryStringJwtToken = getUrlParams().jwtToken;

    return useMutation(
        async ({ wellId, annotationType, trace, domain, datum } : GetAllAnnotationForSeismicRequest) => {
            const response = await axios.post<IResponseAPI<IAnnotation>>(
                apiUrl + '/Wells/WellAnnotationItem/GetAllAnnotationForSeismic',
                {
                    wellId: wellId,
                    type: annotationType,
                    trace: trace,
                    domain: domain,
                    datum: datum
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + (sessionJwtToken !== '' ? sessionJwtToken : queryStringJwtToken),
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data.Result;
        }
    );
};

export type GetAllAnnotationForSeismicRequest = {
    wellId: number,
    annotationType: WellAnnotationType,
    trace: number,
    domain: AmplitudeDomain,
    datum: number
};